import React from "react";
import EnvioRetornoAmostra from "../components/Adm/EnvioRetornoAmostra/index";

import style from '../styles/AdmEnvioRetornoAmostra.module.scss';

const AdmEnvioRetornoAmostra = () => {
    return (
        <div className="p-2">
          <h3>Catálogo Amostras - ADM - Envio / Retorno Amostra</h3>
          <EnvioRetornoAmostra />
        </div>
    );
};

export default AdmEnvioRetornoAmostra;