import React, { useEffect, useState } from "react";
import { Button, Table, Form, Modal } from "react-bootstrap";
import MaterialIcon from 'material-icons-react';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import styles from "../../../../styles/SalaAmostras.module.scss";

import { api }  from "../../../../services/api";
import CookiesService from "../../../../services/cookies";
import Select from 'react-select';
import Carregando from "../../../Carregando";
import { toDatetime, toUTCDatetime } from "../../../../utils/dateUtils";

function SalaAmostra() {
  const [salaAmostraList, setSalaAmostraList] = useState([]);
  const [idSalaAmostraToDelete, setIdSalaAmostraToDelete] = useState({});

  const [estoqueList, setLocalizacaoList] = useState([]);
  const [posicaoList, setPosicaoList] = useState([]);
  const [corAraraList, setCorAraraList] = useState([]);
  const [tipoAraraList, setTipoAraraList] = useState([]);
  const [qtdeAndaresList, setQtdeAndaresList] = useState([
    { value: 1, label: 'Um' }, { value: 2, label: 'Dois' }, { value: 3, label: 'Três' }, { value: 4, label: 'Quatro' }
  ]);
  const [qtdeLadosList, setQtdeLadosList] = useState([{ value: 1, label: 'Um' }, { value: 2, label: 'Dois' }]);
  const [objCadastro, setObjCadastro] = useState({});
  const [objEditarCadastro, setObjEditarCadastro] = useState({});
  const [capacidadePorAndar, setCapacidadePorAndar] = useState('0.00');
  const [capacidadeTotal, setCapacidadeTotal] = useState('0.00');
  const [capacidadeEditadaPorAndar, setCapacidadeEditadaPorAndar] = useState('0.00');
  const [capacidadeEditadaTotal, setCapacidadeEditadaTotal] = useState('0.00');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const userLogin = CookiesService.getUserLogin();

  useEffect(() => {
    handleFetchOptions();
  }, []);

  async function handleFetchOptions() {
    try {
      await api.get("PAPecaPiloto/SalaCatalogoListSalaAmostra")
        .then(res => setSalaAmostraList(res.data))
        .catch(e => { throw e });

      await api.get("PAPecaPiloto/SalaCatalogoListLocalizacao")
        .then(res => setLocalizacaoList(res.data))
        .catch(e => { throw e });

      await api.get("PAPecaPiloto/SalaCatalogoListPosicao")
        .then(res => setPosicaoList(res.data))
        .catch(e => { throw e });

      await api.get("PAPecaPiloto/SalaCatalogoListCor")
        .then(res => setCorAraraList(res.data))
        .catch(e => { throw e });

      await api.get("PAPecaPiloto/SalaCatalogoListTipoArara")
        .then(res => setTipoAraraList(res.data))
        .catch(e => { throw e });
    } catch (e) {
      console.error(e);
      toast.error('Ocorreu um erro, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
    }
  }

  const handleCalculoCapacidade = (comprimento, espacoAmostral, qtdeLados, qtdeAndares, tipoCalc) => {
    let capAndar = 0;
    let capTotal = 0;

    if (comprimento !== '0.00' && espacoAmostral !== '0.00') {
      capAndar = (comprimento * 100) / espacoAmostral;
      capAndar = parseFloat(capAndar).toFixed(2);

      capTotal = capAndar * qtdeLados?.value * qtdeAndares?.value;
      capTotal = parseFloat(capTotal).toFixed(2);
    } else { capAndar = '0.00'; capTotal = '0.00' }

    if (tipoCalc === 'cadastrar') {
      setCapacidadePorAndar(isNaN(capAndar) ? '0.00' : capAndar);
      setCapacidadeTotal(isNaN(capTotal) ? '0.00' : capTotal);
    } else if (tipoCalc === 'editar') {
      setCapacidadeEditadaPorAndar(isNaN(capAndar) ? '0.00' : capAndar);
      setCapacidadeEditadaTotal(isNaN(capTotal) ? '0.00' : capTotal);
    }
  }

  useEffect(() => {
    const { comprimento, espacoAmostral, qtdeLados, qtdeAndares } = objCadastro;
    handleCalculoCapacidade(comprimento, espacoAmostral, qtdeLados, qtdeAndares, 'cadastrar');
  }, [objCadastro]);

  useEffect(() => {
    const { comprimento, espacoAmostral, qtdeLados, qtdeAndares } = objEditarCadastro;
    handleCalculoCapacidade(comprimento, espacoAmostral, qtdeLados, qtdeAndares, 'editar');
  }, [objEditarCadastro]);

  function handleSetShowEditModal(sala, index) {
    setShowEditModal(true);
    setObjEditarCadastro({
      salaOld: sala,
      ...sala,
      index,
      estoque: { value: sala.idLocal, label: sala.descricaoLocal },
      posicao: { value: sala.idPosicao, label: sala.descricaoPosicao },
      corArara: { value: sala.idCor, label: sala.descricaoCor },
      tipoArara: { value: sala.idTipo, label: sala.descricaoTipo },
      qtdeLados: { value: sala.qtdeLados, label: qtdeLadosList.filter(lado => lado.value === sala.qtdeLados)[0].label },
      qtdeAndares: { value: sala.qtdeAndares, label: qtdeAndaresList.filter(andar => andar.value === sala.qtdeAndares)[0].label }
    });
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setObjEditarCadastro({});
    setCapacidadeEditadaPorAndar('0.00');
    setCapacidadeEditadaTotal('0.00');
  }

  const handleSetShowDeleteModal = (idSalaAmostra, index) => {
    setShowDeleteModal(true);
    setIdSalaAmostraToDelete({ idSalaAmostra, index });
  }

  async function handleDeleteSalaAmostra() {
    const params = { idSalaAmostra: idSalaAmostraToDelete.idSalaAmostra };

    await api.post(`PAPecaPiloto/SalaCatalogoDeletar`, params)
      .then(res => {
        if (res.data[0].result) {
          toast.success(`${res.data[0].resultMessage}`);
          salaAmostraList.splice(idSalaAmostraToDelete.index, 1);
        } else {
          toast.error(`${res.data[0].resultMessage}`);
        }
        setShowDeleteModal(false);
        setIdSalaAmostraToDelete({});
      })
      .catch(e => {
        console.error(e);
        toast.error('Ocorreu um erro durante o processamento dos dados, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
      });
      
    setLoading(false);
  }

  function handleValidarSalaAmostra(type) {
    let objAValidar; let capacidadeAValidar;
    if (type === 'inserir') { objAValidar = objCadastro; capacidadeAValidar = capacidadeTotal; }
    else if (type === 'alterar') { objAValidar = objEditarCadastro; capacidadeAValidar = capacidadeEditadaTotal; }

    const { estoque, posicao, corArara, tipoArara, qtdeLados, qtdeAndares, comprimento, espacoAmostral } = objAValidar;
    const errs = {}
    if (!estoque) errs.estoque = true;
    if (!posicao) errs.posicao = true;
    if (!corArara) errs.corArara = true;
    if (!tipoArara) errs.tipoArara = true;
    if (!qtdeLados) errs.qtdeLados = true;
    if (!qtdeAndares) errs.qtdeAndares = true;
    if (!comprimento) errs.comprimento = true;
    if (!espacoAmostral) errs.espacoAmostral = true;
    if (parseInt(capacidadeAValidar) > 1000) {
      errs.capacidadeAValidar = true;
      toast.warn('A capacidade total de uma sala não pode exceder 1000 unidades');
    }
    return errs;
  }

  async function handleInserirSalaAmostra() {
    const errs = handleValidarSalaAmostra('inserir');
    if (Object.keys(errs).length > 0) {
      setLoading(false);
      return toast.warn('Atenção, você deve selecionar/inserir todos os campos antes de criar uma nova sala de amostras!');
    }

    const params = {
      idEstoque: objCadastro.estoque.value,
      idMarca: objCadastro.posicao.value,
      idCor: objCadastro.corArara.value,
      idTipoArara: objCadastro.tipoArara.value,
      qtdeLados: objCadastro.qtdeLados.value,
      qtdeAndares: objCadastro.qtdeAndares.value,
      comprimento: objCadastro.comprimento,
      espacoAmostral: objCadastro.espacoAmostral,
      capacidadeAndar: capacidadePorAndar,
      capacidadeTotal: capacidadeTotal,
      idUsuario: userLogin,
      dataRegistro: toDatetime(new Date())
    }

    await api.post(`PAPecaPiloto/SalaCatalogoInserir`, params)
      .then(async res => {
        await api.get("PAPecaPiloto/SalaCatalogoListSalaAmostra", { params: { ultimo: true } })
          .then(res => salaAmostraList.push(res.data[0]))
          .catch(e => console.error(e));
        toast.success("Cadastrado com sucesso!");
        setObjCadastro({});
      }).catch(e => {
        console.error(e);
        toast.error('Ocorreu um erro durante o processamento dos dados, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
      });

    setLoading(false);
  }

  async function handleAlterarSala() {
    const errs = handleValidarSalaAmostra('alterar');
    if (Object.keys(errs).length > 0) {
      setLoading(false);
      return toast.warn('Atenção, você deve selecionar/inserir todos os campos antes de alterar uma sala de amostras!');
    }

    const params = {
      idSalaAmostra: objEditarCadastro.idSalaAmostra,
      idEstoque: objEditarCadastro.estoque.value,
      idMarca: objEditarCadastro.posicao.value,
      idCor: objEditarCadastro.corArara.value,
      idTipoArara: objEditarCadastro.tipoArara.value,
      qtdeLados: objEditarCadastro.qtdeLados.value,
      qtdeAndares: objEditarCadastro.qtdeAndares.value,
      comprimento: objEditarCadastro.comprimento,
      espacoAmostral: objEditarCadastro.espacoAmostral,
      capacidadePorAndar: capacidadeEditadaPorAndar,
      capacidadeTotal: capacidadeEditadaTotal,
      idUsuarioAlteracao: userLogin,
      dataAlteracao: toDatetime(new Date()),
      salaOld: objEditarCadastro.salaOld
    }

    await api.post(`PAPecaPiloto/SalaCatalogoAlterar`, params)
      .then(async res => {
        if (!res.data[0].result) return toast.warn('A sala não pôde ser alterada, por favor cheque se há amostras cadastradas nesta sala e tente novamente');
        const indexAlterado = salaAmostraList.findIndex(sala => { return sala.idSalaAmostra === params.idSalaAmostra });
        salaAmostraList[indexAlterado] = res.data[0];
        setSalaAmostraList([...salaAmostraList]);
        handleCloseEditModal();
        toast.success('Informações alteradas com sucesso');
      }).catch(e => {
        console.error(e);
        toast.error('Ocorreu um erro durante o processamento dos dados, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
      });
  }

  const handleValueMask = (value, field, type) => {
    if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
    if (type === 'create') setObjCadastro({ ...objCadastro, [field]: parseFloat(value / 100).toFixed(2) });
    else setObjEditarCadastro({ ...objEditarCadastro, [field]: parseFloat(value / 100).toFixed(2) });
  }

  return (
    <div>
      <article className={styles.articleInRow}>
        <Form className={styles.blockNoborder} style={{ display: 'flex', flexDirection: 'column' }}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Estoque</Form.Label>
            <Select
              placeholder={'Selecionar'}
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={estoqueList}
              value={objCadastro.estoque || null}
              onChange={e => setObjCadastro({ ...objCadastro, estoque: e })}
            />
          </Form.Group>

          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Qtde Andares</Form.Label>
            <Select
              placeholder={'Selecionar'}
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={qtdeAndaresList}
              value={objCadastro.qtdeAndares || null}
              onChange={e => setObjCadastro({ ...objCadastro, qtdeAndares: e })}
            />
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Posição</Form.Label>
            <Select
              placeholder={'Selecionar'}
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={posicaoList}
              value={objCadastro.posicao || null}
              onChange={e => setObjCadastro({ ...objCadastro, posicao: e })}
            />
          </Form.Group>

          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Comprimento (m)</Form.Label>
            <Form.Control
              type="text"
              value={objCadastro.comprimento || '0.00'}
              onChange={e => handleValueMask(e.target.value, 'comprimento', 'create')}
              placeholder={'0.00'}
              required
            />
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Cor Arara</Form.Label>
            <Select
              placeholder={'Selecionar'}
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={corAraraList}
              value={objCadastro.corArara || null}
              onChange={e => setObjCadastro({ ...objCadastro, corArara: e })}
            />
          </Form.Group>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Group>
              <Form.Label>Espaço Amostral (cm)</Form.Label>
              <Form.Control
                type="text"
                value={objCadastro.espacoAmostral || '0.00'}
                onChange={e => handleValueMask(e.target.value, 'espacoAmostral', 'create')}
                placeholder={'0.00'}
                required
              />
            </Form.Group>
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Tipo Arara</Form.Label>
            <Select
              placeholder={'Selecionar'}
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={tipoAraraList}
              value={objCadastro.tipoArara || null}
              onChange={e => setObjCadastro({ ...objCadastro, tipoArara: e })}
            />
          </Form.Group>
          <Form.Group className={styles.alignColummBottom}>
            <Form.Label> Capacidade por Andar: {capacidadePorAndar}</Form.Label>
            <Form.Label className={styles.displayContent}>Capacidade Total: {capacidadeTotal}</Form.Label>
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Qtde Lados</Form.Label>
            <Select
              placeholder={'Selecionar'}
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={qtdeLadosList}
              value={objCadastro.qtdeLados || null}
              onChange={e => setObjCadastro({ ...objCadastro, qtdeLados: e })}
            />
          </Form.Group>
          <Form.Group className={styles.alignBtn}>
            <Button
              style={{ width: '100%' }}
              className={styles.alignBtn}
              variant="primary"
              onClick={() => { setLoading(true); handleInserirSalaAmostra(); }}
            >
              Inserir
            </Button>
          </Form.Group>
        </Form>
      </article>

      <article className={styles.articleGray}>
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}>ID ARARA</th>
              <th className={styles.blockTitle}>LOCAL</th>
              <th className={styles.blockTitle}>POSIÇÃO</th>
              <th className={styles.blockTitle}>COR</th>
              <th className={styles.blockTitle}>TIPO</th>
              <th className={styles.blockTitle}>LADOS</th>
              <th className={styles.blockTitle}>ANDARES</th>
              <th className={styles.blockTitle}>COMPRIMENTO</th>
              <th className={styles.blockTitle}>ESPAÇO AMOS.</th>
              <th className={styles.blockTitle}>CAP. ANDAR</th>
              <th className={styles.blockTitle}>CAP. TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {salaAmostraList.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={styles.tableLines}>
                    <Button
                      variant="primary"
                      onClick={() => handleSetShowEditModal(item, index)}
                    >
                      <i>
                        <MaterialIcon
                          icon="edit"
                          size={20}
                          color='white'
                        />
                      </i>
                    </Button>
                  </td>
                  <td className={styles.tableLines}>
                    <Button
                      variant="danger"
                      onClick={() => handleSetShowDeleteModal(item.idSalaAmostra, index)}
                    >
                      <i>
                        <MaterialIcon
                          icon="delete"
                          size={20}
                          color='white'
                        />
                      </i>
                    </Button>
                  </td>
                  <td className={styles.tableLines}>{item.idArara}</td>
                  <td className={styles.tableLines}>{item.descricaoLocal}</td>
                  <td className={styles.tableLines}>{item.descricaoPosicao}</td>
                  <td className={styles.tableLines}>{item.descricaoCor}</td>
                  <td className={styles.tableLines}>{item.descricaoTipo}</td>
                  <td className={styles.tableLines}>{item.qtdeLados}</td>
                  <td className={styles.tableLines}>{item.qtdeAndares}</td>
                  <td className={styles.tableLines}>{item.comprimento}</td>
                  <td className={styles.tableLines}>{item.espacoAmostral}</td>
                  <td className={styles.tableLines}>{item.capacidadePorAndar}</td>
                  <td className={styles.tableLines}>{item.capacidadeTotal}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </article>

      <Modal
        centered
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
        style={{ backgroundColor: '#00000090' }}
      >
        <Modal.Header>
          <Modal.Title>
            Editando Arara
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.formEditModal}>
          <Form className={styles.formRowEditModal}>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Estoque</Form.Label>
              <Select
                placeholder={'Selecionar'}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                options={estoqueList}
                value={objEditarCadastro.estoque || null}
                onChange={e => setObjEditarCadastro({ ...objEditarCadastro, estoque: e })}
              />
            </Form.Group>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Posição</Form.Label>
              <Select
                placeholder={'Selecionar'}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                options={posicaoList}
                value={objEditarCadastro.posicao || null}
                onChange={e => setObjEditarCadastro({ ...objEditarCadastro, posicao: e })}
              />
            </Form.Group>
          </Form>

          <Form className={styles.formRowEditModal}>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Cor Arara</Form.Label>
              <Select
                placeholder={'Selecionar'}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                options={corAraraList}
                value={objEditarCadastro.corArara || null}
                onChange={e => setObjEditarCadastro({ ...objEditarCadastro, corArara: e })}
              />
            </Form.Group>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Tipo Arara</Form.Label>
              <Select
                placeholder={'Selecionar'}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                options={tipoAraraList}
                value={objEditarCadastro.tipoArara || null}
                onChange={e => setObjEditarCadastro({ ...objEditarCadastro, tipoArara: e })}
              />
            </Form.Group>
          </Form>

          <Form className={styles.formRowEditModal}>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Qtde Andares</Form.Label>
              <Select
                placeholder={'Selecionar'}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                options={qtdeAndaresList}
                value={objEditarCadastro.qtdeAndares || null}
                onChange={e => setObjEditarCadastro({ ...objEditarCadastro, qtdeAndares: e })}
              />
            </Form.Group>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Qtde Lados</Form.Label>
              <Select
                placeholder={'Selecionar'}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                options={qtdeLadosList}
                value={objEditarCadastro.qtdeLados || null}
                onChange={e => setObjEditarCadastro({ ...objEditarCadastro, qtdeLados: e })}
              />
            </Form.Group>
          </Form>

          <Form className={styles.formRowEditModal}>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Comprimento (m)</Form.Label>
              <Form.Control
                type="text"
                value={objEditarCadastro.comprimento || '0.00'}
                onChange={e => handleValueMask(e.target.value, 'comprimento', 'edit')}
                placeholder={'0.00'}
                required
              />
            </Form.Group>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Espaço Amostral (cm)</Form.Label>
              <Form.Control
                type="text"
                value={objEditarCadastro.espacoAmostral || '0.00'}
                onChange={e => handleValueMask(e.target.value, 'espacoAmostral', 'edit')}
                placeholder={'0.00'}
                required
              />
            </Form.Group>
          </Form>

          <Form className={styles.formRowEditModal}>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Capacidade por andar: <span className={styles.capLabel}>{capacidadeEditadaPorAndar}</span></Form.Label>
            </Form.Group>
            <Form.Group className={styles.formGroupEditModal}>
              <Form.Label>Capacidade total: <span className={styles.capLabel}>{capacidadeEditadaTotal}</span></Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer style={{ padding: '16px' }}>
          <div className={styles.editFooterInfo}>
            Atualizações nas informações de capacidades só serão
            permitidas se a quantidade cadastrada for inferior à nova
            capacidade e se não houverem amostras cadastradas na sala.
          </div>
          <div className={styles.editFooterInfo}>
            <Button
              style={{ width: '50%' }}
              variant="secondary"
              onClick={handleCloseEditModal}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: '50%' }}
              variant="primary"
              onClick={handleAlterarSala}
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={showDeleteModal}
        size={30}
        style={{ backgroundColor: '#00000090' }}
      >
        <Modal.Header>Excluir Arara</Modal.Header>
        <Modal.Body>Essa ação irá excluir esta arara, certifique-se que nenhuma amostra está cadastrada nela anter de prosseguir</Modal.Body>
        <Modal.Footer style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant={'secondary'}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant={'primary'}
            onClick={() => { setLoading(true); handleDeleteSalaAmostra() }}
          >
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={loading}
        size={'sm'}
        style={{ backgroundColor: '#00000090' }}
      >
        <Modal.Body><Carregando></Carregando></Modal.Body>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default SalaAmostra;
