import React, { useState, useEffect } from "react";
import { Button, Form, Table, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import Select from 'react-select';
import CookiesService from "../../../services/cookies";

import "bootstrap/dist/css/bootstrap.min.css";


import styles from ".././../../styles/AprovacaoEstiloAprovarAmostra.module.scss";
import { useLocation } from 'react-router-dom';
import { toDatetime, toUTCDDMMYYYY, toYYYYMMDD } from "../../../utils/dateUtils";
import { api }  from "../../../services/api";
import { toast, ToastContainer } from "react-toastify";

function AprovarAmostraRedirect () {
    const { state } = useLocation();
    const [activeObj, setActiveObj] = useState({});
    const [optionsObj, setOptionsObj] = useState({DestinoLista: [], AprovacaoLista: [], FuncionarioLista: [], PendenciasLista: []});
    const [formObj, setFormObj] = useState({});
    const [formErrs, setFormErrs] = useState({});
    const [processQueue, setProcessQueue] = useState([]);
    const [pendIndex, setPendIndex] = useState(null);
    const userLogin = CookiesService.getUserLogin();
    const today = toYYYYMMDD(new Date());

    const handleFetchOptions = async () => {
        let {DestinoLista, AprovacaoLista, FuncionarioLista, PendenciasLista} = optionsObj;
        const params = {
            Material: state.activeObj.Material,
            CorMaterial: state.activeObj.CorMaterial,
            Empresa: state.activeObj.Empresa
        }
        await api.get('/MPAprovacaoEstilo/GetPendenciasLista', {params})
            .then(res => { if (res.status === 200) PendenciasLista = res.data })
            .catch(e => console.error(`Erro na requisição '/GetPendenciasLista' - ${e}`));
        await api.get('/MPAprovacaoEstilo/GetDestinoLista')
            .then(res => { if (res.status === 200) DestinoLista = res.data })
            .catch(e => console.error(`Erro na requisição '/GetDestinoLista' - ${e}`));
        await api.get('/MPAprovacaoEstilo/GetAprovacaoLista')
            .then(res => { if (res.status === 200) AprovacaoLista = res.data })
            .catch(e => console.error(`Erro na requisição '/GetAprovacaoLista' - ${e}`));
        await api.get('/MPAprovacaoEstilo/GetListaFuncionario', {params})
            .then(res => { if (res.status === 200) FuncionarioLista = res.data })
            .catch(e => console.error(`Erro na requisição '/GetListaFuncionario' - ${e}`));
        if (DestinoLista.length > 0 && AprovacaoLista.length > 0 && FuncionarioLista.length > 0 && PendenciasLista.length > 0) {
            setOptionsObj({DestinoLista, AprovacaoLista, FuncionarioLista, PendenciasLista});
        } else {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        }
    }

    const handleQueueInclusion = () => {
        const {PendenciasLista} = optionsObj;
        const errs = handleValidation();
        if (Object.keys(errs).length > 0) {
            setFormErrs({...errs});
        } else {
            optionsObj.PendenciasLista.forEach(pend => {
                if (formObj.Pendencia.label === pend.label) PendenciasLista[pendIndex].disabled = true;
                setOptionsObj({...optionsObj, PendenciasLista: [...PendenciasLista]});
            })
            setFormObj({});
            setPendIndex(null);
            setProcessQueue([...processQueue, formObj]);
        }
    }

    const handleValidation = () => {
        const {Aprovacao, Destino, Lacre, Pendencia, ResponsavelValidacao, ConfirmaEnvio, RetornoAmostra, DataEnvio} = formObj;
        const errs = {}
        if (!Pendencia) errs.Pendencia = 'Selecione uma pendência';
        if (!Aprovacao) errs.Aprovacao = 'Selecione um status de aprovação';
        if (!Destino) errs.Destino = 'Selecione um destino';
        if (!ResponsavelValidacao) errs.ResponsavelValidacao = 'Selecione um responsável';
        // if (!Consideracoes) errs.Consideracoes = 'Insira uma consideração';
        if (ConfirmaEnvio === undefined) errs.ConfirmaEnvio = 'Selecione uma das opções';
        if (RetornoAmostra === undefined) errs.RetornoAmostra = 'Selecione uma das opções';
        if (ConfirmaEnvio === true && (!Lacre || Lacre === '')) errs.Lacre = 'Insira um lacre válido';
        if (ConfirmaEnvio === true && (!DataEnvio || new Date(DataEnvio).getTime() <= 0 || new Date(DataEnvio) < new Date(today))) errs.DataEnvio = 'Insira uma data de envio válida e maior ou igual a hoje';
        return errs;
    }

    const handleProcessDump = (index) => {
        const {PendenciasLista} = optionsObj;
        PendenciasLista[index].disabled = false;
        setOptionsObj({...optionsObj, PendenciasLista: [...PendenciasLista]});
        processQueue.splice(index, 1);
        setProcessQueue([...processQueue]);
    }

    const clearErrs = (str) => {
        setFormErrs({...formErrs, [str]: null});
    }

    const handleProcessing = () => {
        processQueue.forEach(async proc => {
            const params = {
                ValidaEstilo: 1,
                DataValidacao: toDatetime(new Date()),
                IDUsuarioValidacao: userLogin ? userLogin : 'TESTE.HOMOLOG',

                IDStatusValidacao: proc.Aprovacao.value,
                DestinoValidacao: proc.Destino.value,
                IDResponsavelValidacao: proc.ResponsavelValidacao.value,
                Consideracao: proc.Consideracoes,
                ConfirmaEnvioAmostra: proc.ConfirmaEnvio === '' || proc.ConfirmaEnvio === undefined ? null : proc.ConfirmaEnvio,
                ConfirmaRetornoAmostra: proc.RetornoAmostra,
                LacreRetornoAmostra: proc.Lacre === '' || proc.Lacre === undefined ? null : proc.Lacre,
                DataEnvioAmostraEstilo: proc.DataEnvio === undefined ? null : toDatetime(proc.DataEnvio)
            }

            const validation = {
                TipoEnvio: activeObj.TipoEnvio,
    
                Pedido: activeObj.Pedido,
                Material: activeObj.Material,
                CorMaterial: activeObj.CorMaterial,
                NomeCliFor: activeObj.NomeCliFor,
                DataAlteracaoPedido: activeObj.DataAlteracaoPedido,
                IDAreaAprovacao: activeObj.IDAreaAprovacao,
                
                NFEntrada: activeObj.NFEntrada,
                SerieNF: activeObj.SerieNF,
                QtdeEntrada: activeObj.QtdeEntrada,
                IDMotivoEnvio: proc.Pendencia.value,

                AreaAprovacao: activeObj.AREAAPROVACAO
            }
            await api.post('/MPAprovacaoEstilo/PostProcessAprovacao', {params, validation})
                .then(res => {
                    if (res.status === 200) {
                        toast.success('Os dados foram atualizados com sucesso! Redirecionando para a página anterior...')
                        return setTimeout(() => {
                            window.history.back();
                        }, 6000)
                    }
                })
                .catch(e => {
                    toast.error('Ocorreu um erro ao atualizar os dados, por favor entre em contato com o suporte.');
                    return console.error(`Erro na requisição '/PostProcessAprovacao' - ${e}`);
                });
        });
    }

    const handleValueMask = (value) => {
        if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
        setFormObj({...formObj, Lacre: value});
    }

    useEffect(() => {
        if (!state) return window.location.href='/aprovacaoestilo';
        setActiveObj({...state.activeObj});
        handleFetchOptions();
    }, [state]);

    return (
        <div>
            <article className={styles.articleMargim}>
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0"></Accordion.Item>
                    <Accordion.Header>Dados do Pedido</Accordion.Header>
                    <Accordion.Body className={styles.infoBlockDisplay}>
                        <div className={styles.infoBlock}>
                            <div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Pedido:</span>
                                    <span className={styles.fontBlue}>{activeObj.Pedido}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Qtde. Recebida:</span>
                                    <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    {/* Esse parâmetro parece não existir no banco */}
                                    <span>Qtde. Peças:</span>
                                    <span className={styles.fontBlue}>{'-'}</span> 
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Tipo Compra:</span>
                                    <span className={styles.fontBlue}>{activeObj.TipoCompra}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Grupo:</span>
                                    <span className={styles.fontBlue}>{activeObj.Grupo}</span>
                                </div>
                            </div>
                            <div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Nota fiscal:</span>
                                    <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Cód. Fornecedor:</span>
                                    <span className={styles.fontBlue}>{activeObj.CodigoFornecedor}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Marca:</span>
                                    <span className={styles.fontBlue}>{activeObj.Marca}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Material:</span>
                                    <span className={styles.fontBlue}>{activeObj.Material}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Cor Material:</span>
                                    <span className={styles.fontBlue}>{activeObj.CorMaterial}</span>
                                </div>
                            </div>
                            <div style={{width: '20%'}}>
                                <div className={styles.infoBlockColumm}>
                                    <span>Série NF:</span>
                                    <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Fornecedor:</span>
                                    <span className={styles.fontBlue}>{activeObj.NomeCliFor}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Empresa:</span>
                                    <span className={styles.fontBlue}>{activeObj.Empresa}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Descrição Material:</span>
                                    <span className={styles.fontBlue}>{activeObj.DescricaoMaterial}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Descrição Cor:</span>
                                    <span size="30" className={styles.fontBlue}>{activeObj.DescricaoCor}</span>
                                </div>
                            </div>
                            <div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Data Entrada NF:</span>
                                    <span className={styles.fontBlue}>{toUTCDDMMYYYY(activeObj.Recebimento)}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Data Recebimento:</span>
                                    <span className={styles.fontBlue}>{activeObj.DataRecebimento}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Data Revisão:</span>
                                    <span className={styles.fontBlue}>{activeObj.DataRevisao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Data Lib. Revisão:</span>
                                    <span className={styles.fontBlue}>{activeObj.DataLiberadoRevisao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Prioridade:</span>
                                    <span className={styles.fontBlue}>{activeObj.Prioridade}</span>
                                </div>
                            </div>
                            <div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Requerido por:</span>
                                    <span className={styles.fontBlue}>{activeObj.RequeridoPor}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Responsável Recebimento:</span>
                                    <span className={styles.fontBlue}>{activeObj.FuncionarioRecebimentoAmostra}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Responsável Revisão:</span>
                                    <span className={styles.fontBlue}>{activeObj.ResponsavelRevisao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Responsável Lib. Revisão:</span>
                                    <span className={styles.fontBlue}>{activeObj.FuncionarioLiberacaoRevisao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Coleção:</span>
                                    <span className={styles.fontBlue}>{activeObj.ColecaoCompras}</span>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion>
            </article>

            <article className={styles.articleGray}>
                
                {/* Linha 1 */}
                <div className={styles.articleGrayLine}>
                    <div className={formErrs.Pendencia ? styles.invalidTable : styles.articleGrayTable}>
                        <p className={styles.articleTitleTable}>1. Pendência de aprovação:</p>
                        <Table borderless responsive>
                            <tbody>
                                {optionsObj.PendenciasLista.map((el, index) => {
                                    return (
                                        <tr className={styles.tableLines} key={index}>
                                            <td>
                                                <Form>
                                                    <Form.Check 
                                                        type={'radio'}
                                                        id={el.value}
                                                        value={formObj.Pendencia || false}
                                                        onChange={() => {
                                                            setFormObj({...formObj, Pendencia: el}); 
                                                            setPendIndex(index);
                                                            clearErrs('Pendencia');
                                                        }}
                                                        checked={formObj.Pendencia === el}
                                                        disabled={el.disabled === true}
                                                    />
                                                </Form>
                                            </td>
                                            <td className={el.disabled ? styles.gray : null}>{el.label}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className={formErrs.Aprovacao ? styles.invalidTable : styles.articleGrayTable}>
                        <p className={styles.articleTitleTable}>2. Status de aprovação:</p>
                        <Table borderless responsive>
                            <tbody>
                                {optionsObj.AprovacaoLista.map((el, index) => {
                                    return (
                                        <tr className={styles.tableLines} key={el.value}>
                                            <td>
                                                <Form>
                                                    <Form.Check 
                                                        type={'radio'}
                                                        id={el.value}
                                                        value={formObj.Aprovacao || false}
                                                        onChange={() => {
                                                            setFormObj({...formObj, Aprovacao: el}); 
                                                            clearErrs('Aprovacao');
                                                        }}
                                                        checked={formObj.Aprovacao === el}
                                                    />
                                                </Form>
                                            </td>
                                            <td className={styles.tableLine}>{el.label}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className={formErrs.Destino ? styles.invalidTable : styles.articleGrayTable}>
                        <p className={styles.articleTitleTable}>3. Destino:</p>
                        <Table borderless responsive>
                            <tbody>
                                {optionsObj.DestinoLista.map((el, index) => {
                                    return (
                                        <tr className={styles.tableLines} key={el.value}>
                                            <td>
                                                <Form>
                                                    <Form.Check 
                                                        type={'radio'}
                                                        id={el.value || false}
                                                        onChange={() => {
                                                            setFormObj({...formObj, Destino: el});
                                                            clearErrs('Destino');
                                                        }}
                                                        checked={formObj.Destino === el}
                                                    />
                                                </Form>
                                            </td>
                                            <td className={styles.tableLine}>{el.label}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className={styles.sectionGrayLineColumm}>
                        <Form style={{margin: '0rem 0.5rem 0rem'}}>
                            <Form.Group className={styles.formControl} controlId={'ResponsavelValidacao'}>
                                <Form.Label className={styles.formLabel}>
                                    4. Responsável validação:
                                </Form.Label>
                                <Select 
                                    closeMenuOnSelect={true}
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    value={formObj.ResponsavelValidacao || null}
                                    onChange={e => {
                                        setFormObj({...formObj, ResponsavelValidacao: e});
                                        clearErrs('ResponsavelValidacao');
                                    }}
                                    options={optionsObj.FuncionarioLista}
                                    styles={{
                                        control: (provided) => (
                                            formErrs.ResponsavelValidacao ? {...provided, borderColor: '#dc3545'} : {...provided, borderColor: 'hsl(0, 0%, 80%)'}
                                        )
                                    }}
                                    required
                                />
                                <div className={formErrs.ResponsavelValidacao ? styles.showFeedback : styles.hidden}>{formErrs.ResponsavelValidacao}</div>
                            </Form.Group>
                            <Form.Group className={styles.formControlText}>
                                <Form.Label className={styles.formLabel}>
                                    5. Considerações:
                                    <span className={styles.spanOpcional}>(Opcional)</span>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="6"
                                    value={formObj.Consideracoes || ''}
                                    onChange={e => setFormObj({...formObj, Consideracoes: e.target.value})}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </div>
                </div>

                {/* Linha 2 */}
                <div className={styles.articleGrayLine}>
                    <Form className="m-2">
                        <Form.Group controlId={'ConfirmaEnvio'}>
                            <Form.Label className={styles.formLabel}>
                                6. Confirma envio de amostra?
                            </Form.Label>
                            <div className={styles.displayFlex}>
                                <Form.Check
                                    type='radio'
                                    label={'Sim'}
                                    name={'ConfirmaEnvio'}
                                    id={'SimConfirmaEnvio'}
                                    onChange={() => {
                                        setFormObj({...formObj, ConfirmaEnvio: true});
                                        clearErrs('ConfirmaEnvio')
                                    }}
                                    checked={formObj.ConfirmaEnvio === true}
                                />
                                <Form.Check
                                    type='radio'
                                    label={'Não'}
                                    name={'ConfirmaEnvio'}
                                    id={'NaoConfirmaEnvio'}
                                    onChange={() => {
                                        setFormObj({...formObj, ConfirmaEnvio: false, Lacre: null, DataEnvio: null});
                                        clearErrs('ConfirmaEnvio')
                                    }}
                                    checked={formObj.ConfirmaEnvio === false}
                                    style={{margin: '0 24px'}}
                                />
                            </div>
                        </Form.Group>
                        <div className={formErrs.ConfirmaEnvio ? styles.showFeedback : styles.hidden}>{formErrs.ConfirmaEnvio}</div>
                    </Form>
                    <Form className="m-2">
                        <Form.Group controlId={'RetornoAmostra'}>
                            <Form.Label className={styles.formLabel}>
                                7. Retorno da amostra recebida?
                            </Form.Label>
                            <div className={styles.displayFlex}>
                                <Form.Check
                                    type='radio'
                                    label={'Sim'}
                                    name={'RetornoAmostra'}
                                    id={'SimRetornoAmostra'}
                                    onChange={() => {
                                        setFormObj({...formObj, RetornoAmostra: true});
                                        clearErrs('RetornoAmostra');
                                    }}
                                    checked={formObj.RetornoAmostra === true}
                                />
                                <Form.Check
                                    type='radio'
                                    label={'Não'}
                                    name={'RetornoAmostra'}
                                    id={'NaoRetornoAmostra'}
                                    onChange={() => {
                                        setFormObj({...formObj, RetornoAmostra: false});
                                        clearErrs('RetornoAmostra');
                                    }}
                                    checked={formObj.RetornoAmostra === false}
                                    style={{margin: '0 24px'}}
                                />
                            </div>
							<Form.Control.Feedback type='invalid'>{formErrs.RetornoAmostra}</Form.Control.Feedback>
                        </Form.Group>
                        <div className={formErrs.RetornoAmostra ? styles.showFeedback : styles.hidden}>{formErrs.RetornoAmostra}</div>
                    </Form>
                    <Form className="m-2">
                        <Form.Group className={styles.formControl} controlId={'Lacre'}>
                            <Form.Label className={styles.formLabel}>
                                6.1. Lacre:
                            </Form.Label>
                            <Form.Control 
                                value={formObj.Lacre || ''}
                                onClick={() => clearErrs('Lacre')}
                                onChange={e => handleValueMask(e.target.value)}
								isInvalid={formErrs.Lacre}
                                disabled={formObj.ConfirmaEnvio !== true}
                            >
                            </Form.Control>
							<Form.Control.Feedback type='invalid'>{formErrs.Lacre}</Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    <Form className="m-2">
                        <Form.Group className={styles.formControl} controlId={'DataEnvio'}>
                            <Form.Label className={styles.formLabel}>
                                6.2. Data envio:
                            </Form.Label>
                            <Form.Control
                                type="date"
                                value={formObj.DataEnvio || ''}
                                onClick={() => clearErrs('DataEnvio')}
                                onChange={e => setFormObj({...formObj, DataEnvio: e.target.value})}
                                min={today}
                                max={'3000-01-01'}
								isInvalid={formErrs.DataEnvio}
                                disabled={formObj.ConfirmaEnvio !== true}
                            >
                            </Form.Control>
							<Form.Control.Feedback type='invalid'>{formErrs.DataEnvio}</Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </div>

                {/* Linha 3 */}
                <div className={styles.buttonMarginRight}>
                    <Button 
                        className={styles.buttonMarginSide}
                        variant="success"
                        onClick={handleQueueInclusion}
                    >
                        Incluir Avaliação
                    </Button>
                </div>
            </article> 

            <article className={styles.articleMarginBottom}>
                <div>
                    <h4 className="mt-5">Avaliações</h4>
                    <Table responsive="sm" style={{borderRadius: 4}}>
                        <thead>
                            <tr>
                                <th></th>
                                <th className={styles.blockTitle}>MOTIVO ENVIO</th>
                                <th className={styles.blockTitle}>STATUS</th>   
                                <th className={styles.blockTitle}>DESTINO</th>                         
                                <th className={styles.blockTitle}>RESPONSÁVEL</th>
                                <th className={styles.blockTitle}>CONSIDERAÇÃO</th>
                                <th className={styles.blockTitle}>CONFIRMA ENVIO AMOSTRA</th>
                                <th className={styles.blockTitle}>CONFIRMA RETORNO AMOSTRA</th>
                                <th className={styles.blockTitle}>LACRE</th>
                                <th className={styles.blockTitle}>DATA ENVIO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {processQueue.length > 0 ? 
                            processQueue.map((el, index) => {
                                return (
                                    <tr className={styles.tableLines} key={index}>
                                        <td>
                                            <i onClick={() => handleProcessDump(index)} className={styles.hoverArr}>
                                                <MaterialIcon 
                                                    icon="delete" 
                                                    size={20} 
                                                    color='#BB271A'
                                                />
                                            </i>
                                        </td>
                                        <td className={styles.tableLine}>{el.Pendencia.label}</td>
                                        <td className={styles.tableLine}>{el.Aprovacao.label}</td>
                                        <td className={styles.tableLine}>{el.Destino.label}</td>
                                        <td className={styles.tableLine}>{el.ResponsavelValidacao.label}</td>
                                        <td className={styles.tableLine}>{el.Consideracoes && el.Consideracoes !== '' ? el.Consideracoes : '-'}</td>
                                        <td className={styles.tableLine}>{el.ConfirmaEnvio === true ? 1 : 0}</td>
                                        <td className={styles.tableLine}>{el.RetornoAmostra === true ? 1 : 0}</td>
                                        <td className={styles.tableLine}>{el.Lacre}</td>
                                        <td className={styles.tableLine}>{el.DataEnvio}</td>
                                    </tr>
                                )
                            }) : (
                                <tr className={styles.tableLines}>
                                    <td>
                                        <i>
                                            <MaterialIcon 
                                                icon="delete" 
                                                size={20} 
                                                color='#bb271a60'
                                            />
                                        </i>
                                    </td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                    <td className={styles.tableLine}>{'-'}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>       
                </div>
                <div className={styles.footer}>
                    <Button //Quando processar, salvar os dados no banco e voltar para a página /aprovacaoestilo
                        className={styles.buttonMarginSide}
                        variant="primary"
                        onClick={handleProcessing}
                        disabled={processQueue.length === 0}
                    >
                        Processar 
                    </Button>
                </div>
            </article>
            <ToastContainer
                position="top-center"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
            />
        </div>
    );
}

export default AprovarAmostraRedirect;
 