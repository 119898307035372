import React, { useEffect } from 'react';
import { Modal, Button, Form, FloatingLabel, ListGroup, ListGroupItem } from 'react-bootstrap';
import { usePriorizacaoOps } from '../../../../contexts/Corte/ControleEnfesto/PriorizacaoOpsContext';
import styles from '../../../../styles/Corte/ControleEnfesto/PriorizacaoOps.module.scss';
import MaterialIcon from 'material-icons-react';

const ModalFiltroData = () => {

    const { selectedValues, setSelectedValues, searchTerm, setSearchTerm, filteredValues, setFilteredValues, filtroSelecionado, mainData, showFiltroData, handleCloseFiltroData, applyFiltro, clearFiltro, filtrosAplicados } = usePriorizacaoOps();
    
    const handleToggleValue = (value) => {
        setSelectedValues(prevSelectedValues => {
          if (prevSelectedValues.includes(value)) {
            return prevSelectedValues.filter(item => item !== value);
          } else {
            return [...prevSelectedValues, value];
          }
        });
      };
    
      const handleApplyFilter = () => {
        applyFiltro(filtroSelecionado.atr, selectedValues);
        handleCloseFiltroData();
      };
    
      const handleClearFilter = () => {
        clearFiltro(filtroSelecionado.atr);
        setSelectedValues([]);
      };

      useEffect(() => {
        const uniqueValues = [...new Set(mainData.map(item => item[filtroSelecionado.atr]))];
        setFilteredValues(uniqueValues.filter(value => value?.toString().toLowerCase().includes(searchTerm.toLowerCase())));
      }, [mainData, filtroSelecionado, searchTerm]);

      useEffect(() => {
        setSelectedValues(filtrosAplicados[filtroSelecionado.atr] || []);
      }, [filtrosAplicados, filtroSelecionado]);
    
    return (
        <>
            <Modal
                show={showFiltroData}
                onHide={handleCloseFiltroData}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group
                            className={styles.formGroupFiltro}
                        >
                            <MaterialIcon
                                icon="search"
                                size={30}
                                color='black'
                            />
                            <FloatingLabel
                                controlId="floatingInput"
                                label={filtroSelecionado.name}
                                className='m-3'
                                style={{width: '100%'}}
                            >
                                <Form.Control 
                                    type='text'
                                    value={searchTerm || ''}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </FloatingLabel>
                            <Button
                                variant="outline-warning"
                                className={styles.btnFiltroUnique}
                                onClick={handleClearFilter}
                            >
                                Limpar Filtro
                            </Button>
                        </Form.Group>
                        <ListGroup>
                            {filteredValues.map((value, index) => (
                                <ListGroupItem key={index}>
                                    <Form.Check
                                        type='checkbox'
                                        label={value ? value : '(vazio)'}
                                        value={value}
                                        className='mx-3'
                                        checked={selectedValues.includes(value)}
                                        onChange={() => handleToggleValue(value)}
                                    />
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Form>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseFiltroData}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={handleApplyFilter}
                    >
                        Aplicar Filtro
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalFiltroData;
