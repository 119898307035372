import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAcompanhamentoEnfesto } from '../../../../contexts/Corte/ControleEnfesto/AcompanhamentoEnfestoContext';
import { toUTCDatetimeWithoutMs } from '../../../../utils/dateUtils';


import styles from '../../../../styles/Corte/ControleEnfesto/AcompanhamentoEnfesto.module.scss';

const ModalInfo = () => {

    const { handleCloseInfo, showInfo, linhaSelecionada, handleShowPausas } = useAcompanhamentoEnfesto();

    const temPausas = linhaSelecionada.TotalPausas > 0

    return (
        <>
            <Modal
                show={showInfo}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                <Modal.Title>Informações do Enfesto</Modal.Title>
                </Modal.Header>
                {
                    <Modal.Body>
                        <div>
                            Ordem Serviço: <strong>{linhaSelecionada.OrdemServico}</strong>
                        </div>
                        <div>
                            Ordem Produção: <strong>{linhaSelecionada.OrdemProducao}</strong>
                        </div>
                        <div>
                            Grade: <strong>{linhaSelecionada.Grade}</strong>
                        </div>
                        <div>
                            Material: <strong>{linhaSelecionada.Material}</strong>
                        </div>
                        <div>
                            Descrição Material: <strong>{linhaSelecionada.Desc_Material}</strong>
                        </div>
                        <div>
                            Cor Material: <strong>{linhaSelecionada.CorMaterial}</strong>
                        </div>
                        <div>
                            Descrição Cor: <strong>{linhaSelecionada.Desc_Cor_Material}</strong>
                        </div>
                        <div>
                            Qtde de Folhas: <strong>{linhaSelecionada.QtdeFolhasAlocada}</strong>
                        </div>
                        <div>
                            Tempo Estimado: <strong>{linhaSelecionada.TempoEstimado}</strong>
                        </div>
                        <div>
                            Fim Estimado: <strong>{toUTCDatetimeWithoutMs(linhaSelecionada.FimEstimado)}</strong>
                        </div>
                        <div>
                            Tempo em Processo: <strong>{linhaSelecionada.TempoEmProcesso}</strong>
                        </div>
                        <div>
                            Status: <strong>{linhaSelecionada.Status}</strong>
                        </div>
                        <div>
                            Pausas: <strong>{linhaSelecionada.TotalPausas}</strong>
                        </div>

                    </Modal.Body>

                }

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseInfo}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={temPausas ? "outline-warning" : 'secondary'}
                        className={styles.modalBtn}
                        onClick={handleShowPausas}
                        disabled={!temPausas}
                    >
                        Ver Pausas
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalInfo;
