import React from "react";
import { Accordion } from 'react-bootstrap';
import styles from '../../styles/RevisaoCemPorcentoRetorno.module.scss';

function DadosAccordion({ cabecalho, values }) {
  return (
    <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
        <Accordion.Header>Dados do Pedido</Accordion.Header>
        <Accordion.Body className={styles.infoBlockDisplay}>
            {cabecalho.map((item, index) => {
                return (
                    <div key={index} className={styles.infoBlockColumm}>
                        <span>{item}</span>
                        <span className={styles.fontBlue}>{values[index]}</span>
                    </div>
                )
            })}
        </Accordion.Body>
        </Accordion.Item>
    </Accordion>
  );
}

export default DadosAccordion;