import { Button, Form } from 'react-bootstrap';
import { Table, Accordion } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { toast } from "react-toastify";

import { api } from '../../services/api';
import Select from 'react-select';
import ModalEscolha from './modal-escolha';

import { useMisc } from '../../contexts/MiscContext';
import styles from './PickingMatereais.module.scss';
import Tabela from '../../components/Misc/Tabela/Tabela';
import CookiesService from '../../services/cookies';

const PESO_CANUDO = 0.35;
const DELTA_VARIAVEL_PESAGEM = 1.06;

const PickingMateriais = () => {
  const initialResultadoObj = {
    metragem_calculada: 'N/A',
    dif_metragem_percent: 'N/A',
    dif_metragem_real: 'N/A',
    resultado: 'N/A'
  }

  const pecasColums = [
    { name: 'Material', width: '20%', selector: row => row.material },
    { name: 'Cor', width: '20%', selector: row => row.cor_material },
    { name: 'Descricao', width: '60%', selector: row => row.desc_material }
  ];

  const handlePesoChange = (value) => {
    value = value.replace(/,/g, '.');
    value = value.replace(/[^0-9.]/g, '');

    const dotParts = value.split('.');
    if (dotParts.length > 2) {
      value = dotParts[0] + '.' + dotParts.slice(1).join('');
    }

    let numVal = parseFloat(value);
    if (isNaN(numVal)) numVal = 0;
    setPesoInserido(numVal);
  };

  const handlePesoChangeBlur = (event) => {
    let value = event.target.value;
    value = value.replace(/,/g, '.');

    if (!value.includes('.')) {
      value += '.00';
    } else {
      let parts = value.split('.');
      if (parts[1].length === 0) {
        value += '00';
      } else if (parts[1].length === 1) {
        value += '0';
      } else if (parts[1].length > 2) {
        value = parts[0] + '.' + parts[1].substring(0, 2);
      }
    }

    let numVal = parseFloat(value);
    if (isNaN(numVal)) {
      numVal = 0;
      value = '0.00';
    }
    event.target.value = value;
    setPesoInserido(numVal);
  };

  const resultadoColumns = [
    {
      name: 'Peso', cell: row => (
        <Form.Group>
          <Form.Control
            type={'number'}
            placeholder="0kg"
            id={'peso'}
            value={pesoInserido === 0 ? '' : pesoInserido}
            onChange={e => handlePesoChange(e.target.value)}
            onFocus={e => e.target.select()}
            onBlur={e => handlePesoChangeBlur(e)}
            disabled={dadosPesagem.length === 0}
          />
        </Form.Group>
      )
    },
    { name: 'Metragem', selector: () => { return totalMetragem } },
    { name: 'Metragem Calculada', selector: row => row.metragem_calculada },
    { name: 'Dif. Metragem %', selector: row => row.dif_metragem_percent },
    { name: 'Dif. Metragem Real', selector: row => row.dif_metragem_real },
    { name: 'Resultado', selector: row => row.resultado }
  ]

  const { setShowLoading } = useMisc();
  const [numeroOS, setNumeroOS] = useState('');
  const [dadosOS, setDadosOS] = useState([]);
  const [materialCorOptions, setMaterialCorOptions] = useState([]);
  const [selecionadoMaterialCor, setSelecionadoMaterialCor] = useState(null);
  const [dadosPesagem, setDadosPesagem] = useState([]);
  const [pecasSelecionadas, setPecasSelecionadas] = useState([]);

  const [totalMetragem, setTotalMetragem] = useState(0);
  const [pesoInserido, setPesoInserido] = useState(0);
  const [resultadoObj, setResultadoObj] = useState(initialResultadoObj);

  const [showModal, setShowModal] = useState(false);

  const resetAllVariables = () => {
    setDadosOS([]);
    setSelecionadoMaterialCor(null);
    setMaterialCorOptions([]);
    setDadosPesagem([]);
    setPecasSelecionadas([]);
    setTotalMetragem(0);
    setPesoInserido(0);
  }

  const clearSomeVariables = () => {
    setSelecionadoMaterialCor(null);
    setDadosPesagem([]);
    setPecasSelecionadas([]);
    setTotalMetragem(0);
    setPesoInserido(0);
  }

  const obterDadosOS = async (afterProcessingOS = null) => {
    try {
      if (!numeroOS && !afterProcessingOS) return;
      resetAllVariables();

      setShowLoading(true);
      const result = await api.get(`picking-material/dadosOs/${afterProcessingOS ? afterProcessingOS : numeroOS}`);
      if (result.data.length === 0) toast.warning('OS não encontrada, por favor tente novamente.');
      const dadosOSUnicos = Array.from(new Set(result.data));
      setDadosOS(dadosOSUnicos);

      const getUniqueKey = obj => `${obj.material}-${obj.cor_material.trim() || ''}-${obj.pesagem}`;
      const arrUnicoParaOpts = dadosOSUnicos.reduce((dadoOS, obj, index) => {
        const key = getUniqueKey(obj);
        if (!dadoOS.uniqueKeys.has(key)) {
          dadoOS.uniqueKeys.add(key);
          dadoOS.result.push({
            label: `${obj.material} | ${obj.cor_material.trim() || ''} | ${obj.pesagem ? 'PESAGEM REALIZADA' : 'NÃO REALIZADA'}`,
            value: index,
            data: obj
          });
        }
        return dadoOS;
      }, { uniqueKeys: new Set(), result: [] }).result;
      setMaterialCorOptions(arrUnicoParaOpts);
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar carregar as informações da OS. Por favor tente novamente.')
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setShowLoading(true);
        if (!selecionadoMaterialCor) return;
        const res = await api.get(`picking-material/pecas/${selecionadoMaterialCor.material}/${selecionadoMaterialCor.cor_material}`);
        if (res.data.length === 0) toast.warn('Não há teste de gramatura para esse MATERIAL | COR - Portanto ele não poderá ser processado.');
        setDadosPesagem([...res.data]);
      } catch (e) {
        toast.error('Ocorreu um erro ao selecionar o MATERIAL | COR - por favor tente novamente ou entre em contato com o suporte.');
      } finally {
        setShowLoading(false);
      }
    })();
  }, [selecionadoMaterialCor]);

  const selecionarMaterialCor = (e) => {
    if (!e) {
      setSelecionadoMaterialCor(null);
      setTotalMetragem(0);
      setPecasSelecionadas([]);
    }

    setSelecionadoMaterialCor({ ...e.data });
    let calcTotalMetragem = 0;
    const arrPecas = [];

    dadosOS
      .filter(obj => obj.material === e.data.material && obj.cor_material === e.data.cor_material)
      .forEach(obj => {
        const metragem = parseFloat(obj.metragem_sistema);
        if (!isNaN(metragem)) {
          calcTotalMetragem += metragem;
          arrPecas.push(obj.peca);
        }
      });

    setTotalMetragem(calcTotalMetragem);
    setPecasSelecionadas([...arrPecas]);
  };

  useEffect(() => {
    try {
      if (dadosPesagem.length === 0) return setResultadoObj({ ...initialResultadoObj });

      const resPesagem = dadosPesagem.reduce((total, item) => {
        if (item.calculo !== undefined) return total + item.calculo;
        return total;
      }, 0);

      let metragemCalculada = 0.00;
      if (resPesagem && selecionadoMaterialCor) {
        metragemCalculada = (pesoInserido * DELTA_VARIAVEL_PESAGEM - pecasSelecionadas.length * PESO_CANUDO) / resPesagem;
      }

      const dif_metragem_percent = (((metragemCalculada - totalMetragem) / totalMetragem) * 100).toFixed(2);
      const dif_metragem_real = parseFloat(metragemCalculada - totalMetragem).toFixed(2);
      const resultado = () => {
        if (dif_metragem_percent >= 0) return 'APROVADO';
        if (dif_metragem_percent < 0 && dif_metragem_percent > -2) return 'APROVADO DENTRO DA MARGEM';
        return 'REPROVADO';
      }

      setResultadoObj({
        metragem_calculada: metragemCalculada.toFixed(2),
        dif_metragem_percent: dif_metragem_percent + '%',
        dif_metragem_real: dif_metragem_real,
        resultado: resultado(),
      });
    } catch (e) {
      toast.error('Ocorreu um erro durante o cálculo.');
    }
  }, [dadosPesagem, pesoInserido]);

  const processarPesagem = async (continuarEditandoOS) => {
    try {
      setShowLoading(true);
      const params = {
        user: CookiesService.getUserLogin(),
        numeroOS: selecionadoMaterialCor.ordem_servico,
        material: selecionadoMaterialCor.material,
        cor: selecionadoMaterialCor.cor_material,
        pesoInserido: pesoInserido,
        resultadoObj: resultadoObj,
      }

      await api.post('/picking-material/processar/pesagem', params);
      toast.success('Pesagem processada com sucesso.');
      continuarEditandoOS ? obterDadosOS(params.numeroOS) : resetAllVariables();
      setShowModal(false);
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar registrar a pesagem, por favor tente novamente.');
    } finally {
      setShowLoading(false);
    }
  }

  return (
    <>
      <ModalEscolha
        processarPesagem={processarPesagem}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <div style={{ width: '1000px', margin: '40px auto' }}>
        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          <Form.Group>
            <Form.Label className={styles.label}>Buscar por OS</Form.Label>
            <Form.Control
              placeholder="Número da OS"
              aria-label="Número da OS"
              aria-describedby="search-icon"
              value={numeroOS}
              onChange={(e) => setNumeroOS(e.target.value)}
            />
          </Form.Group>

          <Button
            style={{ alignSelf: 'flex-end' }}
            variant={'outline-primary'}
            onClick={() => obterDadosOS()}
            disabled={!numeroOS || numeroOS === ''}
          >
            Pesquisar
          </Button>
        </div>

        <Accordion defaultActiveKey="0" style={{ border: '1px solid #00000030', borderRadius: '4px' }}>
          <Accordion.Header className={styles.acc_header}>Dados da OS</Accordion.Header>
          <Accordion.Body>
            <div className={styles.flex_container_col}>
              <Form.Label className={styles.label}>OP {dadosOS[0]?.ordem_producao}</Form.Label>

              <div className={styles.flex_container_row}>
                <div className={styles.div_30w}>
                  <Form.Group>
                    <Form.Label className={styles.label}>Selecionar Material | Cor</Form.Label>
                    <Select
                      value={
                        selecionadoMaterialCor ?
                          {
                            value: selecionadoMaterialCor?.cor_produto,
                            label: `${selecionadoMaterialCor?.material} | ${selecionadoMaterialCor?.cor_material.trim() || ''} | ${selecionadoMaterialCor?.pesagem ? 'PESAGEM REALIZADA' : 'NÃO REALIZADA'}`
                          } : null}
                      options={materialCorOptions}
                      placeholder={'Selecione o Material | Cor'}
                      isSearchable={false}
                      onChange={selecionarMaterialCor}
                      styles={{ control: styles => ({ ...styles, height: '50px' }), }}
                      isOptionDisabled={opt => opt?.data?.pesagem}
                    />
                  </Form.Group>
                </div>

                <div className={styles.div_70w}>
                  <Form.Label className={styles.label}>Informações Material | Cor</Form.Label>
                  <Tabela
                    data={selecionadoMaterialCor ? [selecionadoMaterialCor] : []}
                    columns={pecasColums}
                    pagination={false}
                    noTableHead={true}
                  />
                </div>
              </div>

              <div>
                <Form.Label className={styles.label}>Peças | {pecasSelecionadas.length}</Form.Label>
                <div className={styles.pecas_wrapper}>
                  {pecasSelecionadas.length === 0 ?
                    <span className={styles.no_info}>Nenhuma informação disponível</span> :
                    pecasSelecionadas.map((peca, index) => {
                      return <span key={index} className={styles.peca_item}>{peca}</span>
                    })}
                </div>
              </div>

              <Tabela
                data={[resultadoObj]}
                columns={resultadoColumns}
                pagination={false}
              />

              <div className={styles.flex_container_btn_row}>
                <Button
                  variant="outline-danger"
                  className={styles.btn}
                  onClick={clearSomeVariables}
                >
                  Apagar Tudo
                </Button>
                <Button
                  variant="success"
                  className={styles.btn}
                  onClick={() => setShowModal(true)}
                  disabled={!selecionadoMaterialCor || pesoInserido === 0 || dadosPesagem.length === 0}
                >
                  Processar
                </Button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion>
      </div>
    </>
  )
}

export default PickingMateriais;