import React from 'react';
import { Form } from 'react-bootstrap';
import styles from '../../styles/AgendamentoRevisao.module.scss';
const FormInput = ({ label, placeholder, type, value, onChange }) => {

  return (
    <Form.Group className='ms-2'>
      <Form.Label className={styles.formLabel}>{label}</Form.Label>
      <Form.Control type={type} placeholder={placeholder} value={value} onChange={onChange}/>
    </Form.Group>
  );
};

export default FormInput;