import React, { useEffect, useState } from "react";
import { Form, FormGroup, Button, Table, Modal } from "react-bootstrap";
import CookiesService from "../../../../services/cookies";
import { toast, ToastContainer } from "react-toastify";
import Select from 'react-select';
import Carregando from "../../../Carregando";

import ImprimirEtiquetas from "../EtiquetasPA/ImprimirEtiquetaPA";
import { api }  from "../../../../services/api";

import styles from "../../../../styles/CadastroAmostras.module.scss";
import { toDatetime, toUTCDDMMYYYY } from "../../../../utils/dateUtils";

const CadastroAmostras = () => {
  const [material, setMaterial] = useState('');
  const [itemFiltrado, setItemFiltrado] = useState([]);
  const [tipoMaterial, setTipoMaterial] = useState([]);
  const [estoque, setLocalizacao] = useState([]);
  const [arara, setArara] = useState([]);
  const [andar, setAndar] = useState([]);
  const [lado, setLado] = useState([]);
  const [itensCadastrados, setItensCadastrados] = useState([]);
  const [itensCadastradosParaImprimir, setItensCadastradosParaImprimir] = useState([]);
  const [tipoMaterialSelecionado, setTipoMaterialSelecionado] = useState(null);
  const [estoqueSelecionado, setEstoqueSelecionado] = useState(null);
  const [araraSelecionada, setAraraSelecionada] = useState(null);
  const [andarSelecionado, setAndarSelecionado] = useState(null);
  const [ladoSelecionado, setLadoSelecionado] = useState(null);
  const [itensACadastrar, setItensACadastrar] = useState([]);
  const [itensParaImprimir, setItensParaImprimir] = useState([]);
  const [processando, setProcessando] = useState(false);
  const userLogin = CookiesService.getUserLogin();

  const handleResetForm = () => {
    setAraraSelecionada(null);
    setEstoqueSelecionado(null);
    setAndarSelecionado(null);
    setLadoSelecionado(null);
    setTipoMaterialSelecionado(null);
  }

  const handleListarCadastrados = async (params) => {
    await api.get('PAPecaPiloto/CatalogoListarCadastrados', { params })
      .then(res => {
        if (res.data.length > 0) {
          setItensCadastrados(res.data);
          let newItensCadastradosParaImprimir = [];
          for (let i = 0; i < res.data.length; i++) {
            newItensCadastradosParaImprimir.push({ idCadastro: res.data[i].idCadastro });
          }
          setItensCadastradosParaImprimir([...newItensCadastradosParaImprimir]);
        } else {
          setItensCadastrados([]);
          setItensCadastradosParaImprimir([]);
        }
      })
      .catch(e => {
        return console.error(`Erro na requisição: ${e}`);
      });
  }

  const handleSearch = async () => {
    const params = { material }
    if (!material) return toast.warn('Você deve informar o produto!');
    try {
      await api.get('PAPecaPiloto/CatalogoListarDisponiveis', { params })
        .then(res => {
          if (res.data.length === 0) return toast.warning('Nenhuma referência encontrada.');
          handleResetForm();
          setItemFiltrado(res.data[0]);
        })
        .catch(e => {
          return console.error(`Erro na requisição: ${e}`);
        });

      handleListarCadastrados({ material });
    } catch (e) {
      toast.error('Ocorreu um erro, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
      console.error(`Erro na requisição: ${e}`);
    }
  }

  const handleAraraClick = async (event) => {
    if (!event) { return (setAndarSelecionado(null), setLadoSelecionado(null)) }
    const params = { idSalaAmostra: event.idSalaAmostra };
    try {
      await api.get("PAPecaPiloto/CatalogoListarAndar", { params })
        .then(res => {
          setAndar(res.data);
        }).catch(e => console.error(`Erro na requisição: ${e}`));

      await api.get("PAPecaPiloto/CatalogoListarLado", { params })
        .then(res => {
          setLado(res.data);
        }).catch(e => console.error(`Erro na requisição: ${e}`));
    } catch (e) {
      toast.error('Ocorreu um erro, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
      console.error(`Erro na requisição: ${e}`);
    }
    setLadoSelecionado(null);
    setAndarSelecionado(null);
  }

  const handleInputClick = (input = null) => {
    if (input === 'estoque') return (setAraraSelecionada(null), setAndarSelecionado(null), setLadoSelecionado(null));
    return (setEstoqueSelecionado(null), setAraraSelecionada(null), setAndarSelecionado(null), setLadoSelecionado(null));
  }

  function handleInserirItemCadastro() {
    setItensACadastrar([
      ...itensACadastrar,
      {
        idArara: araraSelecionada.value,
        idSalaAmostra: araraSelecionada.idSalaAmostra,
        material: itemFiltrado.material,
        corMaterial: tipoMaterialSelecionado.value === 1 ? '0001' : '0002',
        codClifor: itemFiltrado.codClifor,
        idUsuario: userLogin,
        idTipoMaterial: tipoMaterialSelecionado.value,
        andar: andarSelecionado.value,
        lado: ladoSelecionado.value,
        dataCadastro: toDatetime(new Date()),
        estoque: araraSelecionada.estoque,
      }
    ]);
    handleResetForm();
  }

  const handleProcessar = async () => {
    await api.post(`PAPecaPiloto/ProcessarCadastro`, itensACadastrar)
      .then(res => {
        toast.success('Dados processados com sucesso!');
        setItensParaImprimir([...itensParaImprimir.concat(res.data)]);
        setItensACadastrar([]);
        handleListarCadastrados({ material: itemFiltrado.material });
      })
      .catch(e => {
        toast.error('Ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
        return console.error(e);
      })
      .finally(() => {
        setProcessando(false);
      });
  }

  const handleCheckForTypes = (option) => {
    for (let i = 0; i < itensCadastrados.length; i++) {
      const item = itensCadastrados[i];
      if (item.tipoMaterial === option.value && item.Material === itemFiltrado.material) return true;
    }
    for (let i = 0; i < itensACadastrar.length; i++) {
      const item = itensACadastrar[i];
      if (item.idTipoMaterial === option.value && item.material === itemFiltrado.material) return true;
    }
    return false;
  }

  useEffect(() => {
    (async () => {
      try {
        await api.get('PAPecaPiloto/Catalogotipomaterial')
          .then(res => {
            setTipoMaterial(res.data);
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });

        await api.get('PAPecaPiloto/CatalogoAraras')
          .then(res => {
            setArara(res.data);
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });

        await api.get('PAPecaPiloto/GetDescricaoLocal')
          .then(res => {
            setLocalizacao(res.data);
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });
      } catch (e) {
        return toast.error('Ocorreu um erro no carregamento da interface, por favor recarregue a página.');
      }
    })();
  }, []);

  return (
    <div className="m-3">
      <h3>Cadastro de Amostras PA</h3>

      <article className={styles.articleInRow} style={{marginLeft: '10px'}}>
        <Form className={styles.form}>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Produto</Form.Label>
            <Form.Control
              required
              type="text"
              title="Este campo é obrigatório"
              value={material}
              onChange={(event) => setMaterial(event.target.value)}
            />
          </FormGroup>
          <Button
            className={styles.btn}
            variant="primary"
            type="button"
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </Form>
      </article>

      <article className={styles.articleInRow}>
        <section className={styles.sectionLeft}>

          <h5 className="m-3">Item a ser cadastrado</h5>
          <div className={styles.flexWrapperCol}>
            <div className={styles.flexColumn}>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Produto</label>
                <div>{itemFiltrado.material || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Cor</label>
                <div>{itemFiltrado.corMaterial || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Cod. Fornecedor</label>
                <div>{itemFiltrado.codClifor || ' - '}</div>
              </div>
            </div> 
            <div className={styles.flexColumn}>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Descrição Produto</label>
                <div>{itemFiltrado.descMaterial || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Descrição Cor</label>
                <div>{itemFiltrado.desCorMaterial || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Fornecedor</label>
                <div>{itemFiltrado.fabricante || ' - '}</div>
              </div>
            </div>
            <div className={styles.flexColumn}>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Coleção</label>
                <div>{itemFiltrado.colecao || ' - '}</div>
              </div>
              <div className={styles.flexItem} style={{ visibility: 'hidden' }}>
                <label className={styles.blockTitle}>Placeholder</label>
                <div>Placeholder</div>
              </div>
              <div className={styles.flexItem} style={{ visibility: 'hidden' }}>
                <label className={styles.blockTitle}>Placeholder</label>
                <div>Placeholder</div>
              </div>
            </div>
          </div>

          <div className={styles.flexWrapper}>
            <Form className={styles.formFlex}>
              <div className={styles.flexRow}>
                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Tipo Produto
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={tipoMaterial}
                    value={tipoMaterialSelecionado || null}
                    onChange={e => { setTipoMaterialSelecionado(e); handleInputClick(); }}
                    isOptionDisabled={option => handleCheckForTypes(option)}
                    isDisabled={itemFiltrado?.length === 0}
                  />
                </FormGroup>

                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Estoque
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={estoque}
                    value={estoqueSelecionado || null}
                    onChange={e => { setEstoqueSelecionado(e); handleInputClick('estoque'); }}
                    isDisabled={!itemFiltrado || tipoMaterialSelecionado === '' || !tipoMaterialSelecionado}
                  />
                </FormGroup>
              </div>
              <div className={styles.flexRow}>
                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Arara
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={arara}
                    value={araraSelecionada || null}
                    onChange={e => { setAraraSelecionada(e); handleAraraClick(e); }}
                    filterOption={option => option.data.idLocal === estoqueSelecionado.value}
                    isDisabled={!itemFiltrado || estoqueSelecionado === '' || !estoqueSelecionado}
                  />
                </FormGroup>
                <FormGroup className={styles.formGroup} />
              </div>

              <div className={styles.flexRow}>
                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Andar
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={andar}
                    value={andarSelecionado || null}
                    onChange={e => setAndarSelecionado(e)}
                    isDisabled={!itemFiltrado || araraSelecionada === '' || !araraSelecionada}
                  />
                </FormGroup>

                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Lado
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={lado}
                    value={ladoSelecionado || null}
                    onChange={e => setLadoSelecionado(e)}
                    isDisabled={!itemFiltrado || araraSelecionada === '' || !araraSelecionada}
                  />
                </FormGroup>
              </div>
            </Form>
          </div>

          <div className={styles.itemCentral}>
            <Button
              variant="primary"
              onClick={handleInserirItemCadastro}
              disabled={!tipoMaterialSelecionado || !estoqueSelecionado || !araraSelecionada || !andarSelecionado || !ladoSelecionado}
            >
              Inserir item para cadastro
            </Button>
          </div>

          <h5 className="m-3">Itens a cadastrar</h5>
          <div className={styles.tableGrupoLarge}>
            <Table>
              <thead>
                <tr>
                  <td className={styles.blockTitle}>Arara</td>
                  <td className={styles.blockTitle}>Produto</td>
                  <td className={styles.blockTitle}>Cor</td>
                  <td className={styles.blockTitle}>Cod Forn.</td>
                  <td className={styles.blockTitle}>Tipo</td>
                  <td className={styles.blockTitle}>Estoque</td>
                  <td className={styles.blockTitle}>Andar</td>
                  <td className={styles.blockTitle}>Lado</td>
                </tr>
              </thead>
              <tbody id="itensParaCadastrar">
                {itensACadastrar.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={styles.tableLines}>{item.idArara}</td>
                      <td className={styles.tableLines}>{item.material}</td>
                      <td className={styles.tableLines}>{item.corMaterial}</td>
                      <td className={styles.tableLines}>{item.codClifor}</td>
                      <td className={styles.tableLines}>{item.idTipoMaterial}</td>
                      <td className={styles.tableLines}>{item.estoque}</td>
                      <td className={styles.tableLines}>{item.andar}</td>
                      <td className={styles.tableLines}>{item.lado}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className={styles.itemCentral}>
            <Button
              variant="primary"
              type="button"
              onClick={() => { setProcessando(true); handleProcessar(); }}
              disabled={itensACadastrar.length === 0 || processando === true}
            >
              Processar
            </Button>
          </div>
        </section>

        <section className={styles.partSection}>
          <h5 className="m-3">Itens já cadastrados</h5>
          <div className={styles.tableGray}>
            <Table>
              <thead>
                <tr>
                  <td className={styles.blockTitle}>Produto</td>
                  <td className={styles.blockTitle}>Cor</td>
                  <td className={styles.blockTitle}>Cod Forn.</td>
                  <td className={styles.blockTitle}>Data Cadastro</td>
                  <td className={styles.blockTitle}>ID Usuario</td>
                  <td className={styles.blockTitle}>Tipo Material</td>
                  <td className={styles.blockTitle}>Estoque</td>
                </tr>
              </thead>
              <tbody>
                {itensCadastrados.length > 0 && itensCadastrados.map((itemCadastrado, index) => {
                  return (
                    <tr key={index}>
                      <td className={styles.tableLines}>{itemCadastrado.Material}</td>
                      <td className={styles.tableLines}>{itemCadastrado.CorMaterial}</td>
                      <td className={styles.tableLines}>{itemCadastrado.codClifor}</td>
                      <td className={styles.tableLines}>{toUTCDDMMYYYY(itemCadastrado.dataCadastro)}</td>
                      <td className={styles.tableLines}>{itemCadastrado.idUsuarioCadastro || ' - '}</td>
                      <td className={styles.tableLines}>{itemCadastrado.tipoMaterial}</td>
                      <td className={styles.tableLines}>{itemCadastrado.estoque || ' - '}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className={styles.itemCentral}>
              <ImprimirEtiquetas
                etiquetas={itensCadastradosParaImprimir}
                disabled={itensCadastrados.length === 0}
                btnString={'Imprimir etiquetas - Já cadastrados'}
              />
            </div>
          </div>

          <div style={{display:'flex', alignItems: 'center'}}>
            <h5 className="m-3">Cadastros nesta sessão</h5>
            <Button
              variant="primary"
              type="button"
              onClick={() => { setItensParaImprimir([]) }}
              disabled={itensParaImprimir.length === 0}
              style={{height: 'auto'}}
            >
              Limpar lista de cadastrados
            </Button>
          </div>
          <div className={styles.tableGray}>
            <Table>
              <thead>
                <tr>
                  <td className={styles.blockTitle}>Status</td>
                  <td className={styles.blockTitle}>Produto/Mensagem</td>
                  <td className={styles.blockTitle}>Cor</td>
                  <td className={styles.blockTitle}>Cod Forn.</td>
                  <td className={styles.blockTitle}>Arara-A-L-P</td>
                  <td className={styles.blockTitle}>Estoque</td>
                </tr>
              </thead>
              <tbody>
                {itensParaImprimir.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={styles.tableLines}>
                        <span 
                          style={{
                            backgroundColor: item.result === true ? '#4CBB5098' : '#DC354598',
                            padding: '4px 10px',
                            borderRadius: '10px',
                            color: 'white'
                          }}
                        >
                          {item.result === false ? 'Erro' : 'Sucesso'}
                        </span>
                      </td>
                      <td className={styles.tableLines}>{item.resultMessage}</td>
                      <td className={styles.tableLines}>{item.corMaterial}</td>
                      <td className={styles.tableLines}>{item.codClifor}</td>
                      <td className={styles.tableLines}>{item.idArara}-{item.andar}-{item.lado}-{item.posicao || 'X'}</td>
                      <td className={styles.tableLines}>{item.estoque}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className={styles.itemCentral}>
              <ImprimirEtiquetas
                etiquetas={itensParaImprimir}
                disabled={itensParaImprimir.length === 0}
                filtrarErros={true}
                btnString={'Imprimir etiquetas - Cadastradas na sessão'}
              />
            </div>
          </div>
          <Modal
            show={processando}
            backdrop="static"
            fullscreen={false}
            keyboard={false}
            dialogClassName={styles.widthModal}
            contentClassName={styles.widthModal}
            centered
            style={{ backgroundColor: '#030303020' }}
          >
            <Modal.Body>
              <Carregando></Carregando>
            </Modal.Body>
          </Modal>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      </article>
    </div>
  );
};

export default CadastroAmostras;
