import React from "react";
import AprovacaoEstiloConsulta from "../components/AprovacaoEstilo/Consulta/index";

const AprovacaoEstilo = () => {
  return (
    <div className="m-4">
      <h3>Aprovação Estilo</h3>
      <AprovacaoEstiloConsulta />
    </div>  
  );
};

export default AprovacaoEstilo;
