import React, { useState } from "react";
import { Modal, Button, Table } from 'react-bootstrap';
import styles from '../../../styles/MP/LaboratorioCemPorcentoRetorno.module.scss';

const PecasIncluidas = ({  pecasIncluidas
                         , setPecasIncluidas 
                         , pecas
                         , setPecas
                         , linhaSelecionada
}) => {

    const [pecaDeletada, setPecaDeletada] = useState({})
    const [showModalDelete, setShowModalDelete] = useState(false)

    const handleShowModalDelete = (peca) => {
        setPecaDeletada(peca)
        setShowModalDelete(true)
    }

    const handleCloseModalDelete = () => {
        setPecaDeletada({})
        setShowModalDelete(false)
    }

    const handleDeletePecasIncluidas = () => {

        const peca = {
                        cor_material: linhaSelecionada.cor_material
                      , fornecedor: linhaSelecionada.fornecedor
                      , material: linhaSelecionada.material
                      , nf_entrada: linhaSelecionada.nf_entrada
                      , peca: pecaDeletada.peca
                      , pedido: linhaSelecionada.pedido
                     }

        setPecas([...pecas, peca])
        setPecasIncluidas(pecasIncluidas.filter(pecaIncluida => pecaIncluida.peca !== pecaDeletada.peca))
        setShowModalDelete(false)
    }

    return (

        <article>
            <article className={styles.articleGrayAlt2}>
            <Table responsive>
                <thead>
                <tr>
                    <th className={styles.blockTitle}></th>
                    <th className={styles.blockTitle}>PEÇA</th>
                    <th className={styles.blockTitle}>TESTE</th>
                    <th className={styles.blockTitle}>ÍNDICE</th>
                    <th className={styles.blockTitle}>STATUS</th>
                    <th className={styles.blockTitle}>GRAMATURA ENCONTRADA</th>
                    <th className={styles.blockTitle}>LARGURA ENCONTRADA</th>
                    <th className={styles.blockTitle}>OBSERVAÇÕES</th>
                </tr>
                </thead>
                <tbody>
                { pecasIncluidas.length > 0 && pecasIncluidas.map((item, index) => { 
                    return (
                    <tr className={styles.tableLines} key={index}>
                        <td className={styles.tableLines}>
                        <Button 
                            variant='outline-danger'
                            onClick={() => handleShowModalDelete(item)}
                        >
                            DELETAR
                        </Button>
                        </td>
                        <td className={styles.tableLines}>{item.peca}</td>
                        <td className={styles.tableLines}>{item.desc_teste}</td>
                        <td className={styles.tableLines}>{item.indice.toUpperCase()}</td>
                        <td className={styles.tableLines}>{item.status.toUpperCase()}</td>
                        <td className={styles.tableLines}>{item.gram_enc ? item.gram_enc + ' g/m2' : null}</td>
                        <td className={styles.tableLines}>{item.larg_enc ? item.larg_enc + ' cm' : null}</td>
                        <td className={styles.tableLines}>{item.obs}</td>
                    </tr>
                    )})}
                </tbody>
            </Table>
            </article>

            <Modal
                show={showModalDelete}
                onHide={handleCloseModalDelete}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Tem certeza?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>A análise da peça <strong>{pecaDeletada.peca}</strong> será apagada.</span>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="success"
                        className={styles.modalBtn}
                        onClick={() => handleDeletePecasIncluidas()}
                    >
                        Sim
                    </Button>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseModalDelete}
                    >
                        Voltar
                    </Button>
                </Modal.Footer>
            </Modal>
        </article>
    )
}

export default PecasIncluidas;
