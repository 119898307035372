import React, { useState } from "react";
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { api }  from "../../../services/api";
import { toast } from "react-toastify";
import styles from '../../../styles/MP/LaboratorioCemPorcentoRetorno.module.scss';
import { useEffect } from "react";
import CookiesService from "../../../services/cookies";


const AnalisePeca = ({   pecaSelecionada
                       , testes
                       , setTestes
                       , pecas
                       , setPecas
                       , pecasIncluidas
                       , setPecasIncluidas
                       , showAnalisePeca
                       , handleCloseAnalisePeca
                       
}) => {

  const [indices, setIndices] = useState([]);

  const [testeEscolhido, setTesteEscolhido] = useState({});
  const [indiceEscolhido, setIndiceEscolhido] = useState({});
  const [statusEscolhido, setStatusEscolhido] = useState('');

  const [observacoes, setObservacoes] = useState('');
  const [gramaturaEncontrada, setGramaturaEncontrada] = useState('');
  const [larguraEncontrada, setLarguraEncontrada] = useState('');

  const [testesIncluidos, setTestesIncluidos] = useState([]);

  const userLogin = CookiesService.getUserLogin();

  const handleIndices = async (teste) => {
    await api.get('MP/LaboratorioCemPorcentoRetorno/get-indices', { params: teste })
    .then(res => {
      setIndices(res.data)
      if (res.data.length === 0){
        setIndiceEscolhido('Não se aplica')
      }
    }).catch(e => {
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    })
  }

  const handleCheckTeste = (e, teste) => {
    const isChecked = e.target.checked;
    const testeChecked = isChecked ? teste : null
    setIndiceEscolhido({})
    setStatusEscolhido('');
    setTesteEscolhido(testeChecked)
  }

  const handleCheckIndice = (e, indice) => {
    const isChecked = e.target.checked;
    const indiceChecked = isChecked ? indice : null
    setStatusEscolhido('')
    setIndiceEscolhido(indiceChecked)
  }

  const handleCloseThisModal = () => {
    setTestesIncluidos([])
    handleResetPeca()
    handleCloseAnalisePeca()
  }
  
  const handleGramatura = (gramatura) => {
    const regex = /^\d*(?:\.\d*)?$/
    if (!regex.test(gramatura)){
      return
    }
    setGramaturaEncontrada(gramatura)
  }

  const handleLargura = (largura) => {
    const regex = /^\d*(?:\.\d*)?$/
    if (!regex.test(largura)){
      return
    }
    setLarguraEncontrada(largura)
  }

  const handleIncluirAnalise = () => {
    
    if (Object.keys(testeEscolhido).length === 0) { toast.warning('Teste não foi escolhido.'); return }
    else if (Object.keys(indiceEscolhido).length === 0) { toast.warning('Índice não foi escolhido.'); return }
    else if (Object.keys(statusEscolhido).length ===   0) { toast.warning('Status não foi escolhido.'); return }

    if (testeEscolhido.id_teste === 7 && (!gramaturaEncontrada || !larguraEncontrada)) {
      toast.warning('Gramatura e Largura devem ser preenchidas para esse teste.')
      return
    } 

    const indice = indiceEscolhido === 'Não se aplica' ? indiceEscolhido : indiceEscolhido.indice

    const teste = {
                      id_teste: testeEscolhido.id_teste
                    , desc_teste: testeEscolhido.desc_teste 
                    , peca: pecaSelecionada.peca
                    , indice: indice
                    , status: statusEscolhido
                    , gram_enc: gramaturaEncontrada
                    , larg_enc: larguraEncontrada
                    , obs: observacoes
                    , usuario: userLogin
                  }    
    
    setTestesIncluidos([...testesIncluidos, teste])
    setTestes(testes.filter(teste => teste.id_teste !== testeEscolhido.id_teste))
    handleResetPeca();
  }

  const handleFinalizarPeca = () => {
    if (testes.length !== 0) { toast.warning('Ainda há testes a serem analisados.'); return }
    setPecasIncluidas([...pecasIncluidas, ...testesIncluidos])
    setTestes([])
    setPecas(pecas.filter(peca => peca.peca !== pecaSelecionada.peca))
    handleCloseThisModal()
  }

  const handleDeleteTesteIncluido = (teste) => {
    setTestesIncluidos(testesIncluidos.filter(testeIncluido => testeIncluido.id_teste !== teste.id_teste))
    setTestes([...testes, {desc_teste: teste.desc_teste, id_teste: teste.id_teste, peca: teste.peca}])
    handleResetPeca()
  }

  const handleResetPeca = () => {
    setObservacoes('');
    setTesteEscolhido({});
    setIndiceEscolhido({});
    setStatusEscolhido('');
    setGramaturaEncontrada('');
    setLarguraEncontrada('');
    setIndices([]);
  }

  useEffect(() => {
    if (testeEscolhido && testeEscolhido.id_teste !== 7){
      setGramaturaEncontrada('')
      setLarguraEncontrada('')
    }
  }, [testeEscolhido])
  

    return (
      <Modal
        show={showAnalisePeca}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen
      >
        <Modal.Header>
          <Modal.Title>Peça: {pecaSelecionada.peca}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={styles.container}
        >
          <article className={styles.incTableInsertWrapper}>
              <div className={styles.tablesRow}>
                
                <div className={styles.tablesColumn}>
                  <Form>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className={styles.blockTitle}></th>
                          <th className={styles.blockTitle}>TESTES PENDENTES</th>
                        </tr>
                      </thead>
                      <tbody>
                        { testes && testes.length > 0 && testes.map((item, index) => {
                          return (
                            <tr className={styles.tableLines} key={index}>
                                <td className={styles.tableLines}>
                                <Form.Check 
                                    type='radio'
                                    name='tar'
                                    onClick={() => handleIndices(item)}
                                    onChange={(e) => handleCheckTeste(e, item)}
                                    checked={testeEscolhido === item}
                                />
                                </td>
                                <td className={styles.tableLines}>{item.desc_teste}</td>
                            </tr>
                            )
                          }) 
                        }
                      </tbody>
                    </Table>
                  </Form>
                </div>

                <div className={styles.tablesColumn}>
                  <Form>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className={styles.blockTitle}></th>
                          <th className={styles.blockTitle}>ÍNDICE</th>
                        </tr>
                      </thead>
                      <tbody>
                        { indices && indices.length > 0 && indices.map((item, index) => {
                            return (
                            <tr className={styles.tableLines} key={index}>
                                <td className={styles.tableLines}>
                                    <Form.Check 
                                    type='radio'
                                    name='idx'
                                    onChange={(e) => handleCheckIndice(e, item)}
                                    checked={indiceEscolhido === item}
                                    />
                                </td>
                                <td className={styles.tableLines}>{item.indice}</td>
                            </tr>
                            )
                          }) 
                        }
                      </tbody>
                    </Table>
                  </Form>
                </div>

                <div className={styles.tablesColumn}>
                  <Form>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className={styles.blockTitle}>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        { indiceEscolhido && Object.keys(indiceEscolhido).length > 0 ?
                          <>
                            <tr className={styles.tableLines} >
                              <td className={styles.tableLines}>                     
                                <Form.Check 
                                  type='radio'
                                  name='st'
                                  label='APROVADO'
                                  value='Aprovado'
                                  onChange={(e) => setStatusEscolhido(e.target.value)}
                                  checked={statusEscolhido === 'Aprovado'}
                                />
                              </td>
                            </tr>
                            <tr className={styles.tableLines} >
                              <td className={styles.tableLines}>                     
                                <Form.Check 
                                  type='radio'
                                  name='st'
                                  label='REPROVADO'
                                  value='Reprovado'
                                  onChange={(e) => setStatusEscolhido(e.target.value)}
                                  checked={statusEscolhido === 'Reprovado'}
                                />
                              </td>
                            </tr>
                          </> : null
                        } 
                      </tbody>
                    </Table>
                  </Form>
                </div>
              </div>
            </article>
            <Form className={styles.formInline}>
              <Form.Group className={styles.obsAnalise}>
                <Form.Label>Observações (Opcional):</Form.Label>
                <Form.Control 
                    type='text'
                    onChange={(e) => setObservacoes(e.target.value)}
                    value={observacoes}
                />
              </Form.Group>
              { testeEscolhido && testeEscolhido.id_teste === 7 ?
              <>
                <Form.Group>
                  <Form.Label>Gramatura Encontrada:</Form.Label>
                  <Form.Control 
                      type='text'
                      onChange={(e) => handleGramatura(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Largura Encontrada:</Form.Label>
                  <Form.Control 
                      type='text'
                      onChange={(e) => handleLargura(e.target.value)}
                  />
                </Form.Group>
              </> : null }
            </Form>


            <article className={styles.articleGrayAlt2}>
              <Table responsive>
                <thead>
                <tr>
                    <th className={styles.blockTitle}></th>
                    <th className={styles.blockTitle}>TESTE</th>
                    <th className={styles.blockTitle}>ÍNDICE</th>
                    <th className={styles.blockTitle}>STATUS</th>
                    <th className={styles.blockTitle}>GRAMATURA ENCONTRADA</th>
                    <th className={styles.blockTitle}>LARGURA ENCONTRADA</th>
                    <th className={styles.blockTitle}>OBSERVAÇÕES</th>
                </tr>
                </thead>
                <tbody>
                { testesIncluidos.length > 0 && testesIncluidos.map((item, index) => { 
                    return (
                    <tr className={styles.tableLines} key={index}>
                        <td className={styles.tableLines}>
                        <Button 
                            variant='outline-danger'
                            onClick={() => handleDeleteTesteIncluido(item)}
                        >
                            DELETAR
                        </Button>
                        </td>
                            <td className={styles.tableLines}>{item.desc_teste}</td>
                            <td className={styles.tableLines}>{item.indice.toUpperCase()}</td>
                            <td className={styles.tableLines}>{item.status.toUpperCase()}</td>
                            <td className={styles.tableLines}>{item.gram_enc ? item.gram_enc + ' g/m2' : null}</td>
                            <td className={styles.tableLines}>{item.larg_enc ? item.larg_enc + ' cm' : null}</td>
                            <td className={styles.tableLines}>{item.obs}</td>
                        </tr>
                    )})}
                </tbody>
            </Table>
            </article>

          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="outline-primary"
                className={styles.modalBtn}
                onClick={() => handleIncluirAnalise()}
              >
                Incluir Análise
            </Button>
            <Button 
              variant='success'
              className={styles.modalBtn}
              onClick={() => handleFinalizarPeca()}
            >
              Finalizar Peça
            </Button>
              <Button
                variant="outline-danger"
                className={styles.modalBtn}
                onClick={() => handleCloseThisModal()}
              >
                Voltar
            </Button>
          </Modal.Footer>
        </Modal>
    )
}

export default AnalisePeca;
