import React from "react";
import { Button, Modal, Form, Accordion } from 'react-bootstrap';
import styles from '../../../styles/MP/AnaliseRapportReprovadoCad.module.scss';

const ModalImpacto = ({ showImpacto
                      , handleCloseImpacto
                      , consumos
                      , consumosHandler
                      , handleShowConfirmacaoGrade
                      , pedidoSelecionado
                     }) => {

    return (
        <Modal
            show={showImpacto}
            onHide={handleCloseImpacto}
            backdrop="static"
            keyboard={false}
            centered
            size='lg'
        >
          <Modal.Header>
            <Modal.Title>Concluir Rapport Reprovado:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <article className={styles.articleSpacing}>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0" disabled>
                  <Accordion.Header>Dados do Pedido</Accordion.Header>
                  <Accordion.Body className={styles.infoBlockDisplay}>
                    <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Pedido:</span>
                          <span className={styles.fontBlue}>{pedidoSelecionado.Pedido}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Material:</span>
                          <span className={styles.fontBlue}>{pedidoSelecionado.Material}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição Material:</span>
                          <span className={styles.fontBlue}>{pedidoSelecionado.DescricaoMaterial}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Cor Material:</span>
                          <span className={styles.fontBlue}>{pedidoSelecionado.CorMaterial}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição Cor:</span>
                          <span className={styles.fontBlue}>{pedidoSelecionado.DescricaoCor}</span>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </article>
            <article className={styles.articleSpacing}>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0" disabled>
                  <Accordion.Header>Inserir Consumo</Accordion.Header>
                  <Accordion.Body>
              <Form className={styles.formModal}>
                <Form.Group className={styles.formGroupModal}>
                  <Form.Control
                      type="text"
                      placeholder="Consumo Atual"
                      value={consumos.consumoAtual}
                      onChange={(e) => consumosHandler(e, 'consumoAtual')}
                  />
                </Form.Group>

                <Form.Group className={styles.formGroupModal}>
                  <Form.Control
                      type="text"
                      placeholder="Novo Consumo"
                      value={consumos.novoConsumo}
                      onChange={(e) => consumosHandler(e, 'novoConsumo')}
                  />
                </Form.Group>
              </Form>
              </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </article>
          </Modal.Body>
          <Modal.Footer className={styles.modalFooter}>
            <Button
                variant="primary"
                className={styles.modalBtn}
                onClick={handleShowConfirmacaoGrade}
              >
                Processar
            </Button>
            <Button
                variant="outline-danger"
                className={styles.modalBtn}
                onClick={handleCloseImpacto}
              >
                Voltar
            </Button>
          </Modal.Footer>
        </Modal>
    ) 
}

export default ModalImpacto;
