import { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import styles from './controle_acesso.module.scss';
import { api } from '../../services/api';
import { useMisc } from '../../contexts/MiscContext';
import { toast } from 'react-toastify';
import Tabela from '../Misc/Tabela/Tabela';

const ControleAcessoModal = () => {
  const { setShowLoading, setShowControleAcessoModal } = useMisc();
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [accessOptions, setAccessOptions] = useState([]);
  const [selectedAccesses, setSelectedAccesses] = useState([]);
  const [selectedUserAccesses, setSelectedUserAccesses] = useState({});

  const fetchUserOptions = async (inputValue) => {
    if (inputValue.length >= 4) {
      try {
        const response = await api.get('/controle-acesso/fetch-lista-usuarios', { params: { login: inputValue } });
        setUserOptions(response.data);
      } catch (e) {
        toast.error('Ocorreu um erro ao buscar pelos usuários. Por favor tente novamente');
      }
    }
  }

  const handleInputChange = (inputValue) => {
    fetchUserOptions(inputValue);
  }

  const handleUserChange = async (selectedOption) => {
    setSelectedUser(selectedOption);

    try {
      const accessResponse = await api.get('/controle-acesso/fetch-acessos-by-user-id', {
        params: { id_usuario: selectedOption.value }
      });

      const novoAcessosUsuario = [];
      const keys = Object.keys(accessResponse.data);
      accessOptions.forEach(opt => {
        if (keys.some(key => key === opt.descricao_acesso)) novoAcessosUsuario.push(opt);
      });

      setSelectedAccesses([...novoAcessosUsuario]);
      setSelectedUserAccesses({ ...accessResponse.data });
    } catch (e) {
      console.error(e);
      toast.error('Ocorreu um erro ao carregar os acessos do usuário selecionado. Por favor tente novamente.');
    }
  }

  const handleCheckboxChange = (row) => {
    const isSelected = selectedAccesses.some(access => access.descricao_acesso === row.descricao_acesso);
  
    if (isSelected) return setSelectedAccesses(selectedAccesses.filter(access => access.descricao_acesso !== row.descricao_acesso));
    setSelectedAccesses([...selectedAccesses, row]);
  };

  const adicionarUsuario = async () => {
    if (!selectedUser.value) return toast.warning('Por favor selecione um usuário.');
    setShowLoading(true);

    try {
      await api.post('/controle-acesso/alterar-acesso-usuario', {
        user_data: selectedUser,
        acessos: selectedAccesses
      });
      const accessResponse = await api.get('/controle-acesso/fetch-acessos-by-user-id', {
        params: { id_usuario: selectedUser.value }
      });
      setSelectedUserAccesses(accessResponse.data);

      toast.success('Acesso alterado com sucesso.');
    } catch (e) {
      toast.error('Erro ao alterar acesso do usuário.');
    } finally {
      setShowLoading(false);
    }
  }

  const fecharModal = () => {
    setSelectedUser({});
    setSelectedAccesses([]);
    setShowControleAcessoModal(false);
    setSelectedUserAccesses({});
  }

  const userColumns = [
    { name: 'Login', selector: row => row.label || '-' },
    { name: 'Grupo', selector: row => row.id_grupo || 'Sem grupo' },
    { name: 'Admin', selector: row => row.is_admin ? 'Sim' : 'Não' },
    { name: 'Super User', selector: row => row.super_user || 'Não' }
  ];

  const accessColumns = [
    {
      name: '',
      cell: row => (
        <input
          className={styles.box}
          type="checkbox"
          checked={selectedAccesses.some(acesso => acesso.descricao_acesso === row.descricao_acesso)}
          onChange={() => handleCheckboxChange(row)}
        />
      )
    },
    { name: 'Acesso', selector: row => row.descricao_acesso }
  ];

  const conditionalRowStyles = [
    {
      when: row => selectedUserAccesses[row.descricao_acesso] === true,
      style: {
        backgroundColor: '#DFF0D8',
        color: 'black',
      },
    },
  ];

  useEffect(() => {
    const fetchListaAcessos = async () => {
      const accessResponse = await api.get('/controle-acesso/fetch-lista-acessos');
      setAccessOptions(accessResponse.data);
    }

    fetchListaAcessos();
  }, []);

  return (
    <>
      <Modal
        show={setShowControleAcessoModal}
        backdrop={true}
        onHide={fecharModal}
      >
        <Modal.Header closeButton>
          <div className={styles.formLabel}>Adicionar ou editar acesso de usuários</div>
        </Modal.Header>
        <Modal.Body className={styles.modal_controle_body}>
          <article className={styles.warning_text}>
            Este controle de acesso usa os dados dos usuários cadastrados no PLM e vincula suas informações básicas à cada serviço disponível no projeto qualidade.
            <br />
            Hoje, ele NÃO é responsável pela autenticação, que segue sendo feita através do PLM.
          </article>

          <Select
            options={userOptions}
            onInputChange={handleInputChange}
            onChange={handleUserChange}
            placeholder="Buscar usuário..."
            noOptionsMessage={() => "Digite ao menos 4 caracteres..."}
          />

          <Tabela
            columns={userColumns}
            data={[selectedUser]}
            noHeader
            pagination={false}
          />

          <Tabela
            columns={accessColumns}
            data={accessOptions}
            clearSelectedRows={!selectedUser}
            pagination={false}
            conditionalRowStyles={conditionalRowStyles}
          />
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Button
            className={styles.btnFooter}
            variant="outline-success"
            onClick={adicionarUsuario}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ControleAcessoModal;
