import { MPRapportEnviaCadProvider } from "../../contexts/MPRapportAnalisePlannerContext";
import RapportAnalisePlannerMain from "../../components/MP/RapportAnalisePlanner/RapportAnalisePlannerMain";

const RapportAnalisePlanner = () => {
    

    return (
        <MPRapportEnviaCadProvider>
            <RapportAnalisePlannerMain />
        </MPRapportEnviaCadProvider>
    );

}
export default RapportAnalisePlanner;