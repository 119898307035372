import React, { useState } from 'react';
import { api }  from "../services/api";
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import styles from '../styles/ReclassificacaoChamados.module.scss';
import Carregando from '../components/Carregando';
import useReclassificacaoChamados from '../hooks/useReclassificacaoChamados';
import { ToastContainer, toast } from 'react-toastify';
// import { toUTCDDMMYYYY, toDatetime } from '../utils/dateUtils';
import CookiesService from "../services/cookies";
import { 
  PROBLEMA_DETECTADO, 
  TIPO_DE_PROBLEMA_DETECTADO,
  AreaResponsavel 
} from '../components/ReclassificacaoChamado/ReclassificacaoEnum';

const options = {
  parent: PROBLEMA_DETECTADO,
  sub1: TIPO_DE_PROBLEMA_DETECTADO,
  sub2: AreaResponsavel,
}

function ReclassificacaoChamados() {
  const { data, loading } = useReclassificacaoChamados();
  const [parentValue, setParentValue] = useState("");
  const [subValue, setSubValue] = useState('');
  const [ areaResponsavel, setAreaResponsavel ] = useState("");
  const [selectItem, setSelectItem ] = useState([]);

  const [filterObj, setFilterObj] = useState({});
  const [filterErrs, setFilterErrs] = useState({});
  const [mainArr, setMainArr] = useState([]);

  const [show, setShow] = useState(false);

  const setFilters = (field, value) => {
    setFilterObj({
        ...filterObj,
        [field]: value
    });

    if(!!filterErrs[field]) {
        setFilterErrs({
            ...filterErrs,
            [field]: null
        });
    }
}

  const handleClose = () => {
    setParentValue('');
    setAreaResponsavel('')
    setShow(false);
  }
  const handleShow = (item) => {
    setSelectItem(item)
    setShow(true);
  };

  const handleParentChange = (event) => {
    const newValue = event.target.value;
    setParentValue(newValue);
    setSubValue('');
    setAreaResponsavel('')
  }

  const handleSubChange = ( event ) => {
    const newValue = event.target.value
    setSubValue(newValue);
    setAreaResponsavel('');
  }

  const handleAreaChange = (event) => {
    const newValue = event.target.value;
    setAreaResponsavel(newValue);
  }

  const subOptions = options.sub1[parentValue] || [];

  async function handleProcessar () {
    const data = {
      grupoDefeito: parentValue,
      grupoSubDefeito: subValue,
      areaResponsavel: areaResponsavel,
      chamado: selectItem?.CHAMADO,
      ordemProducao: selectItem?.ORDEM_PRODUCAO,
      produto: selectItem?.PRODUTO,
      marca: selectItem?.MARCA,
      fornecedor: selectItem?.FORNECEDOR,
      tipoProgramacao: selectItem?.TIPO_PROGRAMACAO,
      idUsuario: CookiesService.getUserLogin()
    }

    const value = {
      chamado: selectItem?.CHAMADO
    }
    await api.post("/corte/salvar-chamado", data).then(() => {
      setSelectItem([])
      setShow(false)
    })
    await api.patch("/corte/update-chamado", value).then(() => {
      toast.success('Seu chamado foi registrado com sucesso');
      window.location.reload()
    })

    return
  }

  const handleFilterValidation = () => {
    const {chamado, op} = filterObj;
    const errs = {}
    if (!isNaN(chamado) && parseInt(chamado) <= 0) errs.chamado = 'Insira um chamado válido';
    if (!isNaN(op) && parseInt(op) <= 0) errs.op = 'Insira uma Op válida';
    return errs;
  }

  const handleFilteredSearch = async () => {

    const errs = handleFilterValidation();

    if (Object.keys(errs).length > 0) {
      setFilterErrs(errs);
      return
    } 

    const params = {
      chamado: filterObj.chamado && filterObj.chamado !== '' ? filterObj.chamado : null,
      op: filterObj.op && filterObj.op !== '' ? filterObj.op : null
    }

    await api.get("/corte/classificacao-chamados", {params})
      .then(res => {
        if (res.status === 200 && res.data.length > 0) {
          setMainArr(res.data.slice(0, 100));
        } else if (res.status === 200 && res.data.length === 0) {
          toast.warning('Nenhum chamado foi encontrado para esses parametros')
        } else if(res.status !== 200) {
          toast.error("Erro Status Code" + res.status + ".\nFale com suporte.")
        }
      }).catch(e => console.error(`Erro na requisição: ${e}`))
  }

    return (
        <div>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
            <article>
                <h3 className="p-3">Reclassificação de Chamados</h3>
                <Form className={styles.formWrapper}>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Chamado</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={'Chamado'}
                          className={styles.formControl}
                          value={filterObj.chamado || ''}
                          onChange={(event) => setFilters('chamado', event.target.value)}
                          isInvalid={!!filterErrs.chamado}
                        />
                        <Form.Control.Feedback type='invalid'>{filterErrs.chamado}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>OP</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={'OP'}
                          className={styles.formControl}
                          value={filterObj.op || ''}
                          onChange={(event) => setFilters('op', event.target.value)}
                          isInvalid={!!filterErrs.op}
                        />
                        <Form.Control.Feedback type='invalid'>{filterErrs.op}</Form.Control.Feedback>
                    </Form.Group>
                    <Button
                      type="button"
                      variant="primary"
                      className={styles.filterBtn}
                      onClick={handleFilteredSearch}
                    >
                      Buscar
                    </Button>
                </Form>
            </article>

            {loading ? <Carregando /> :

            <article className={styles.articleGray}>
                { loading ?
                    <Carregando/>
                : 
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                          <th className={styles.blockTitle}></th>
                          <th className={styles.blockTitle}>Chamado</th>
                          <th className={styles.blockTitle}>OP</th>
                          <th className={styles.blockTitle}>Produto</th>
                          <th className={styles.blockTitle}>Marca</th>
                          <th className={styles.blockTitle}>Fornecedor</th>
                          <th className={styles.blockTitle}>Canal</th>
                          <th className={styles.blockTitle}>Criado em</th>
                        </tr>
                    </thead>
                    <tbody>
                      { mainArr && mainArr.length > 0 ? mainArr.map((item, index) => {
                        return (
                        <tr key={`row-${index}`}>
                          <td>
                            <i 
                              style={{ 'cursor': 'pointer' }}
                              className={styles.icon}
                              onClick={() => handleShow(item)}
                              >
                                <MaterialIcon
                                  key={`icon-${index}`}
                                  icon="post_add"
                                  size={30}
                                  color='#0D6EFD'
                                />
                            </i>
                          </td>
                          <td className={styles.tableLines} >{item.CHAMADO}</td>
                            <td className={styles.tableLines} >{item.ORDEM_PRODUCAO}</td>
                            <td className={styles.tableLines} >{item.PRODUTO}</td>
                            <td className={styles.tableLines} >{item.MARCA}</td>
                            <td className={styles.tableLines} >{item.FORNECEDOR}</td>
                            <td className={styles.tableLines} >{item.TIPO_PROGRAMACAO}</td>
                            <td className={styles.tableLines} >{new Date(item.CRIADO_EM).toLocaleDateString()}</td>
                        </tr>)
                      }) : data.data && data.data.map((item, index) => {
                        return (
                          <tr key={`row-${index}`}>
                            <td>
                              <i 
                                style={{ 'cursor': 'pointer' }}
                                className={styles.icon}
                                onClick={() => handleShow(item)}
                              >
                                <MaterialIcon
                                  key={`icon-${index}`}
                                  icon="post_add"
                                  size={30}
                                  color='#0D6EFD'
                                />
                              </i>
                            </td>
                            <td className={styles.tableLines} >{item.CHAMADO}</td>
                            <td className={styles.tableLines} >{item.ORDEM_PRODUCAO}</td>
                            <td className={styles.tableLines} >{item.PRODUTO}</td>
                            <td className={styles.tableLines} >{item.MARCA}</td>
                            <td className={styles.tableLines} >{item.FORNECEDOR}</td>
                            <td className={styles.tableLines} >{item.TIPO_PROGRAMACAO}</td>
                            <td className={styles.tableLines} >{new Date(item.CRIADO_EM).toLocaleDateString()}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                </Table>
                }
            </article>
            }

            <Modal
                show={show}
                onHide={handleClose}
                size='lg'
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Reclassificação de Chamado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Accordion>
                        <h3 style={{marginTop: '10px', marginBottom: '30px'}}>
                            Chamado: 
                            <span className={styles.fontBlue}>{selectItem.CHAMADO}</span>
                        </h3>
                    </Accordion>

                    <Accordion defaultActiveKey={"0"} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Informações do Chamado</Accordion.Header>
                            <Accordion.Body className={styles.infoBlockDisplay}>
                                <div className={styles.infoBlock}>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Chamado:</span>
                                        <span className={styles.fontBlue}>{selectItem.CHAMADO}</span>
                                    </div>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Marca:</span>
                                        <span className={styles.fontBlue}>{selectItem.MARCA}</span>
                                    </div>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Criado em:</span>
                                        <span className={styles.fontBlue}>{selectItem.CRIADO_EM}</span>
                                    </div>
                                </div>
                                <div className={styles.infoBlock}>
                                    <div className={styles.infoBlockColumm}>
                                        <span>OP:</span>
                                        <span className={styles.fontBlue}>{selectItem.ORDEM_PRODUCAO}</span>
                                    </div>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Fornecedor:</span>
                                        <span className={styles.fontBlue}>{selectItem.FORNECEDOR}</span>
                                    </div>
                                </div>
                                <div className={styles.infoBlock}>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Produto:</span>
                                        <span className={styles.fontBlue}>{selectItem.PRODUTO}</span>
                                    </div>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Canal:</span>
                                        <span className={styles.fontBlue}>{selectItem.TIPO_PROGRAMACAO}</span>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Form className={styles.formWrapperColumn}>

                        <Form.Group controlId='parentSelect'>
                            <Form.Label className={styles.formLabel}>1. Tipo de problema detectado: </Form.Label>
                            <Form.Control as={'select'} value={parentValue} onChange={handleParentChange}>
                              <option value="">Escolha o tipo do problema</option>
                              {options.parent.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label className={styles.formLabel}>2. Subtipo: </Form.Label>
                          <Form.Control as={'select'} value={subValue} onChange={handleSubChange} disabled={!parentValue}>
                            <option value="">Escolha o subtipo do problema</option>
                            {subOptions.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label className={styles.formLabel}>3. Área responsável: </Form.Label>
                          <Form.Control as={'select'} value={areaResponsavel} onChange={handleAreaChange} disabled={!subValue}>
                            <option value="">Escolha a area responsavel</option>
                            {AreaResponsavel.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button 
                        variant="secondary" 
                        onClick={handleClose}
                        type={'submit'} 
                    >
                        Cancelar
                    </Button>
                    <Button
                      disabled={!areaResponsavel} 
                      variant="primary" 
                      onClick={handleProcessar}
                    >
                        Avançar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ReclassificacaoChamados;