import React from 'react';
import Select from 'react-select';
import { Button, Modal, Form } from 'react-bootstrap';
import { useEnfesto } from '../../../../contexts/Corte/ControleEnfesto/EnfestoContext'; 

import styles from '../../../../styles/Corte/ControleEnfesto/Enfesto.module.scss';

const ModalMotivo = () => {

    const { showPausa, handleClosePausa, motivoPausaData, motivoPausaSelecionado, setMotivoPausaSelecionado, handlePausaEnfesto } = useEnfesto();

    return (
        <>
           <Modal
                show={showPausa}
                onHide={handleClosePausa}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Pausa:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label><strong>Selecione o motivo da pausa:</strong></Form.Label>
                            <Select
                                className={styles.formControl}
                                closeMenuOnSelect={true}
                                value={motivoPausaSelecionado || ''}
                                options={motivoPausaData}
                                getOptionLabel={(option)=>option.Motivo}
                                getOptionValue ={(option)=>option.IdMotivoPausa}
                                onChange={(e) => setMotivoPausaSelecionado(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleClosePausa}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={"outline-success"}
                        className={styles.modalBtn}
                        onClick={handlePausaEnfesto}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalMotivo;
