import React, { useState } from "react";
import { Button, Form, FormGroup, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { api }  from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import styles from '../../styles/AdmMp/AlterarLaboratorio.module.scss';
import CookiesService from "../../services/cookies"; 

const AlterarLaboratorio = () => {
  // const [showModal, setShowModal] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [checkedProcess, setCheckedProcess] = useState({});
  const [mainData, setMainData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [IDTesteArr, setIDTesteArr] = useState([]);
  const [IDTesteLista, setIDTesteLista] = useState([]);
  const actionOpts = [{ label: 'Produção', value: '1' }, { label: 'TOP Sample', value: '2' }];
  const [action, setAction] = useState(null);
  const userLogin = CookiesService.getUserLogin();

  // const handleCloseModal = () => setShowModal(false);
  const handleProcessing = async () => {
    const params = {
      Tipo: action,
      NFEntrada: checkedProcess.NFEntrada,
      SerieNF: checkedProcess.SerieNF,
      NomeClifor: checkedProcess.NomeClifor,
      Material: checkedProcess.Material,
      CorMaterial: checkedProcess.CorMaterial,
      PecaPedido: checkedProcess.Peca,
      DataAlteracaoPedido: null,
      IDTesteLista: IDTesteLista.join(';').concat(';'),
      Item: checkedProcess.Item,
      IdUsuario: userLogin
      // IdUsuario: userLogin || 'TESTE.HOMOLOG'
    }

    await api.get('/MPAdm/ExecAlteraLaboratorio', {params})
    .then(res => {
      if (res.status === 200 && res.data[0].MENSAGEM === 'PROCESSO CONCLUIDO COM SUCESSO') {
          handleClearObjs();
          handleSearch(params);
          toast.success('Dados alterados com sucesso!');
        } else {
          toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
      }).catch(e => {
        console.error(`Erro na requisição '/ExecAlteraLaboratorio' - ${e}`);
        toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
      });
  }

  const handleSearch = async (reload = null) => {
    if (handleFilterValidation() === true) {
      let acaoEndpoint;
      if (filterObj.Acao.value === '1') acaoEndpoint = 'GetListaLab';
      else if (filterObj.Acao.value === '2') acaoEndpoint = 'GetListaTopSample';

      await api.get(`/MPAdm/${acaoEndpoint}`, {params: {...filterObj}})
        .then(res => {
          if ((res.status === 200 && res.data.length > 0) || reload) {
            setMainData(res.data);
            setAction(filterObj.Acao.value);
            handleClearObjs();
          }
          else if (res.status === 200 && res.data.length === 0) toast.warning('Não foram encontrados registros com esses parâmetros de filtro, tente novamente');
        }).catch(e => {
          console.error(`Erro na requisição '/${acaoEndpoint}' - ${e}`);
          toast.error('Erro ao carregar os dados da página, por favor cheque sua conexão com a internet e/ou entre em contato com o suporte');
        });
    } else {
      return toast.warning('Por favor, preencha ao menos um campo de filtro.');
    }
  }

  const handleFilterValidation = () => {
    switch (filterObj.Acao.value) {
      case '1':
        if ((filterObj.Peca && filterObj.Peca !== '') || (filterObj.NFEntrada && filterObj.NFEntrada !== '') || (filterObj.Material && filterObj.Material !== '') || (filterObj.CorMaterial && filterObj.CorMaterial !== '')) {
          return true;
        }
        break;
      case '2':
        if ((filterObj.Material && filterObj.Material !== '') || (filterObj.CorMaterial && filterObj.CorMaterial !== '') || (filterObj.Pedido && filterObj.Pedido !== '')) {
          return true;
        }
        break
      default:
        return false;
    }
  }

  const handleFetchTests = async (el, index) => {
    await api.get('/MPAdm/GetListaTesteLab', {params: {...el}})
        .then(res => {
          if (res.status === 200 && res.data.length > 0) {
            setTestData(res.data);
            let testArr = [];
            res.data.forEach(el => {
              testArr.push(el.IDTeste);
            });
            setIDTesteArr(testArr);
          }
          else if (res.status === 200 && res.data.length === 0) toast.warning('Não foram encontrados testes associados a esse processo');
        }).catch(e => {
          console.error(`Erro na requisição '/GetListaTesteLab' - ${e}`);
          toast.error('Erro ao carregar os dados da página, por favor cheque sua conexão com a internet e/ou entre em contato com o suporte');
        });
    setCheckedProcess({...el, index: index});
  }

  const handleTestCheck = (IDTeste) => {
    const i = IDTesteLista.indexOf(IDTeste);
    let newIDList = [...IDTesteLista];
    newIDList.splice(i, 1);
    if (i > -1) setIDTesteLista([...newIDList]);
    else if (i < 0) setIDTesteLista([...IDTesteLista, IDTeste]);
  }

  const handleValueMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
    setFilterObj({...filterObj, [field]: value});
  }

  const handleActionSelect = (e) => {
    if (e.value === '1') setFilterObj({...filterObj, Pedido: '', Acao: e});
    if (e.value === '2') setFilterObj({...filterObj, Peca: '', NFEntrada: '', Acao: e});
  }
  
  const handleClearObjs = () => {
    setTestData([]);
    setIDTesteArr([]);
    setIDTesteLista([]);
    setCheckedProcess({});
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Selecione ao menos um item e um teste para processar
    </Tooltip>
  );

  return (
    <div className="p-3">
      <h3>ADM MP - Alterar Laboratório</h3>
      <article className={styles.articleMargim}>
        <Form className={styles.form}>
          <FormGroup className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>Ação:</Form.Label>
            <Select
              options={actionOpts}
              className={styles.formSelectModal}
              placeholder={'Selecionar'}
              closeMenuOnSelect={true}
              isClearable={true}
              backspaceRemovesValue={true}
              value={filterObj.Acao}
              onChange={e => handleActionSelect(e)}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup} controlId={'Peca'}>
            <Form.Label className={styles.formLabel}>Número Peça</Form.Label>
            <Form.Control
              type="text"
              className={styles.formControlModal}
              value={filterObj.Peca || ''}
              onChange={e =>  setFilterObj({...filterObj, 'Peca': e.target.value })}
              placeholder={'Número da peça'}
              disabled={!filterObj.Acao || filterObj.Acao.label !== 'Produção'}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup} controlId={'NFEntrada'}>
            <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
            <Form.Control
              type="text"
              className={styles.formControlModal}
              value={filterObj.NFEntrada || ''}
              onChange={e => handleValueMask('NFEntrada', e.target.value)}
              placeholder={'Nota fiscal'}
              disabled={!filterObj.Acao || filterObj.Acao.label !== 'Produção'}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup} controlId={'Material'}>
            <Form.Label className={styles.formLabel}>Material</Form.Label>
            <Form.Control
              type="text"
              className={styles.formControlModal}
              value={filterObj.Material || ''}
              onChange={e => handleValueMask('Material', e.target.value)}
              placeholder={'Material'}
              disabled={!filterObj.Acao}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup} controlId={'CorMaterial'}>
            <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
            <Form.Control
              type="text"
              className={styles.formControlModal}
              value={filterObj.CorMaterial || ''}
              onChange={e => handleValueMask('CorMaterial', e.target.value)}
              placeholder={'Cor material'}
              disabled={!filterObj.Acao}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup} controlId={'Pedido'}>
            <Form.Label className={styles.formLabel}>Pedido</Form.Label>
            <Form.Control
              type="text"
              className={styles.formControlModal}
              value={filterObj.Pedido || ''}
              onChange={e => handleValueMask('Pedido', e.target.value)}
              placeholder={'Pedido'}
              disabled={!filterObj.Acao || filterObj.Acao.label !== 'TOP Sample'}
            />
          </FormGroup>
          <Button 
            type="button" 
            variant="primary"
            className={styles.formControlModal}
            onClick={() => handleSearch(null)}
          >
            Buscar
          </Button>
        </Form>
      </article>

      <article className={styles.articleGray}>
        <Table className="m-2">
          <thead>
            <tr>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}>PEÇA</th>
              <th className={styles.blockTitle}>NF ENTRADA</th>                            
              <th className={styles.blockTitle}>SERIE</th>
              <th className={styles.blockTitle}>FORNECEDOR</th>
              <th className={styles.blockTitle}>MATERIAL</th>
              <th className={styles.blockTitle}>COR MATERIAL</th>
              <th className={styles.blockTitle}>QTDE ENTRADA</th>
              <th className={styles.blockTitle}>DATA ENTRADA</th>
              <th className={styles.blockTitle}>COMPRADORA</th>  
              <th className={styles.blockTitle}>ITEM</th>
            </tr>
          </thead>
          <tbody>
            {mainData.map((el, index) => {
              return (
                <tr key={index}>
                  <td className={styles.tableLines}>
                    <Form.Check
                      type={'radio'}
                      checked={checkedProcess.index === index}
                      onChange={() => handleFetchTests(el, index)}
                    />
                  </td>
                  <td className={styles.tableLines}>{el.Peca}</td>
                  <td className={styles.tableLines}>{el.NFEntrada}</td>
                  <td className={styles.tableLines}>{el.SerieNF}</td>
                  <td className={styles.tableLines}>{el.NomeClifor}</td>
                  <td className={styles.tableLines}>{el.Material}</td>
                  <td className={styles.tableLines}>{el.CorMaterial}</td>
                  <td className={styles.tableLines}>{el.QtdeEntrada}</td>
                  <td className={styles.tableLines}>{el.DataEntrada}</td>
                  <td className={styles.tableLines}>{el.Compradora}</td>
                  <td className={styles.tableLines}>{el.Item}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </article>

      <h4 className={styles.articleMargim}>Testes:</h4>

      <article className={styles.articleGray}>
        <Table  className="m-2">
          <thead>
            <tr>
              <th className={styles.blockTitle}>
                <Form.Check 
                  type={'checkbox'}
                  checked={IDTesteArr === IDTesteLista}
                  onChange={IDTesteLista === IDTesteArr ? () => setIDTesteLista([]) : () => setIDTesteLista(IDTesteArr)}
                />
              </th>
              <th className={styles.blockTitle}>ID TESTE</th>
              <th className={styles.blockTitle}>DESCRIÇÃO TESTE</th>                            
              <th className={styles.blockTitle}>STATUS TESTE</th>
              <th className={styles.blockTitle}>DATA TESTE</th>
              <th className={styles.blockTitle}>ID USUÁRIO TESTE</th>
            </tr>
          </thead>
          <tbody>
            {testData.map((el, index) => {
              return (
                <tr key={index}>
                  <td className={styles.tableLines}>
                    <Form.Check 
                      type={'checkbox'}
                      checked={IDTesteLista.includes(el.IDTeste) === true}
                      onChange={() => handleTestCheck(el.IDTeste)}
                    />
                  </td>
                  <td className={styles.tableLines}>{el.IDTeste}</td>
                  <td className={styles.tableLines}>{el.DescricaoTeste}</td>
                  <td className={styles.tableLines}>{el.StatusTeste}</td>
                  <td className={styles.tableLines}>{el.DataTeste}</td>
                  <td className={styles.tableLines}>{el.IDUsuarioTeste}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </article>

      {/* Footer */}
      <article className={styles.footer}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 1500, hide: 400 }}
        overlay={renderTooltip}
      >
        <div>
          <Button
              variant="primary"
              onClick={handleProcessing}
              disabled={IDTesteLista.length === 0}
          >
              Processar
          </Button>
        </div>
      </OverlayTrigger>
      </article>

      {/* <Modal 
          show={showModal} 
          onHide={handleCloseModal}
          centered
      >
        <Modal.Header closeButton>
        <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>Atenção! Deseja voltar o status da Nota Fiscal selecionada?</p>
            <p>Essa ação irá excluir todos os dados lançados anteriormente SEM ALTERAR A PERDA DE METRAGEM NA PEÇA, CASO TENHA SIDO REALIZADA.</p> 
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
            <Button variant="danger" onClick={handleCloseModal}>
                Não
            </Button>
            <Button variant="primary" onClick={handleCloseModal}>
                Sim
            </Button>
        </Modal.Footer>
      </Modal> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AlterarLaboratorio;