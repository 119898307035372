import React, { useState, useEffect } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import styles from '../../styles/MP/RapportReprovado.module.scss';
import Carregando from '../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import { api }  from "../../services/api";
import { toUTCDDMMYYYY } from '../../utils/dateUtils';
import ModalHistorico from '../../components/MP/RapportReprovado/ModalHistorico';
import ModalReprovado from '../../components/MP/RapportReprovado/ModalReprovado';
import ModalCad from '../../components/MP/RapportReprovado/ModalCad';


const RapportReprovado = () => {

    const [mainData, setMainData] = useState([]);
    const [historicoData, setHistoricoData] = useState([]);
    const [historicoPedido, setHistoricoPedido] = useState({});

    const [filterObj, setFilterObj] = useState({
        nf_entrada: '',
        material: '',
        cor_material: ''
    });

    const [envio, setEnvio] = useState({ CAD: 0, DEVOLUCAO: 0});

    const [pedidoSelecionado, setPedidoSelecionado] = useState({});

    const [showHistorico, setShowHistorico] = useState(false);
    const [showReprovado, setShowReprovado] = useState(false);
    const [showCad, setShowCad] = useState(false);

    const [loading, setLoading] = useState(false);

    const resetAllAndSearch = () => {
        setFilterObj({
            nf_entrada: '',
            material: '',
            cor_material: ''
        });
        setEnvio({ CAD: 0, DEVOLUCAO: 0});
        setPedidoSelecionado({});
        setShowReprovado(false);
        setShowCad(false);
        handleSearch();
    }

    const handleShowHistorico = (item) => {
        Promise.all([handleGetHistorico(item), handleGetHistoricoPedido(item)])
        setPedidoSelecionado(item);
        setShowHistorico(true);   
    }

    const handleCloseHistorico = () => {
        setPedidoSelecionado({});
        setHistoricoData([]);
        setHistoricoPedido({});
        setShowHistorico(false);
    }

    const handleShowReprovado = (item) => {
        setEnvio({ CAD: 0, DEVOLUCAO: 1});
        setPedidoSelecionado(item);
        setShowReprovado(true);
    }

    const handleCloseReprovado = () => {
        setEnvio({ CAD: 0, DEVOLUCAO: 0});
        setPedidoSelecionado({});
        setShowReprovado(false);
    }

    const handleShowCad = (item) => {
        setEnvio({ CAD: 1, DEVOLUCAO: 0});
        setPedidoSelecionado(item);
        setShowCad(true);
    }

    const handleCloseCad = () => {
        setEnvio({ CAD: 0, DEVOLUCAO: 0});
        setPedidoSelecionado({});
        setShowCad(false);
    }

    const handleSearch = async () => {
        setLoading(true);
        const params = { ...filterObj }
        await api.get('MPRapportReprovado/get-reprovados', { params })
          .then(res => {
            setMainData(res.data);
            setLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    }

    const handleGetHistorico = async (pedido) => {
        const params = { ...pedido }
        await api.get('MPRapportReprovado/get-historico', { params })
          .then(res => {
            setHistoricoData(res.data);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    }

    const handleGetHistoricoPedido = async (pedido) => {
        const params = { ...pedido }
        await api.get('MPRapportReprovado/get-historicoByPedido', { params })
          .then(res => {
            setHistoricoPedido(res.data);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    }

    const handleEnviarCadDevolucao = async (pedido) => {
        const send = { ...pedido }
        await api.post('MPRapportReprovado/update-cad-devolucao', { send })
        .then(res => {
          if (res.status === 200) { 
            toast.success('Dados inseridos com sucesso!'); 
            resetAllAndSearch();
          } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }).catch(e => {
          toast.error(`Entre em contato com o suporte. ${e}`);
          console.error(e)
        });
    }

    useEffect(() =>{
        handleSearch();
    },[])

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <article>
                <h3 className="p-3">Rapports Reprovados</h3>
                <Form className={styles.formWrapper}>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Nota fiscal'}
                        className={styles.formControl}
                        value={filterObj.nf_entrada || ''}
                        onChange={(event) => setFilterObj({...filterObj, nf_entrada: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Material'}
                        className={styles.formControl}
                        value={filterObj.material || ''}
                        onChange={(event) => setFilterObj({...filterObj, material: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Cor Material'}
                        className={styles.formControl}
                        value={filterObj.cor_material || ''}
                        onChange={(event) => setFilterObj({...filterObj, cor_material: event.target.value})}
                    />
                </Form.Group>
                <Button
                    type="button"
                    variant="primary"
                    className={styles.filterBtn}
                    onClick={handleSearch}
                >
                    Buscar
                </Button>
                </Form>
            </article>

            <article className={styles.articleGray}>
                { loading ?
                    <Carregando></Carregando>
                : 
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}>DATA REPROVAÇÃO</th>
                            <th className={styles.blockTitle}>PEDIDO</th>
                            <th className={styles.blockTitle}>NOTA FISCAL</th>
                            <th className={styles.blockTitle}>SERIE NF</th>
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                            <th className={styles.blockTitle}>COR MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO COR MATERIAL</th>
                            <th className={styles.blockTitle}>METRAGEM RECEBIDA</th>
                        </tr>
                    </thead>
                    <tbody>
                        { mainData.map((item,index) => {
                        return (
                        <tr key={index}>
                            <td>
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => handleShowHistorico(item)}
                                >
                                    Histórico
                                </Button>
                            </td> 
                            <td>
                                <Button
                                    variant="danger"
                                    type="button"
                                    onClick={() => handleShowReprovado(item)}
                                >
                                    Reprovar
                                </Button>
                            </td> 
                            <td>
                                <Button
                                    variant="warning"
                                    type="button"
                                    onClick={() => handleShowCad(item)}
                                >
                                    EnvioCad
                                </Button>
                            </td> 
                            <td className={styles.tableLines}>{toUTCDDMMYYYY(item.DATAREPROVACAO)}</td>
                            <td className={styles.tableLines}>{item.PEDIDO}</td>
                            <td className={styles.tableLines}>{item.NFENTRADA}</td>
                            <td className={styles.tableLines}>{item.SERIENF}</td>
                            <td className={styles.tableLines}>{item.FORNECEDOR}</td>
                            <td className={styles.tableLines}>{item.MATERIAL}</td>
                            <td className={styles.tableLines}>{item.DESCRICAOMATERIAL}</td>
                            <td className={styles.tableLines}>{item.CORMATERIAL}</td>
                            <td className={styles.tableLines}>{item.DESCRICAOCOR}</td>
                            <td className={styles.tableLines}>{item.QTDEENTRADA}</td>
                        </tr>
                        )})}
                    </tbody>
                </Table>
                }
            </article>

            <ModalHistorico 
                showHistorico={showHistorico}
                handleCloseHistorico={handleCloseHistorico}
                historicoData={historicoData}
                historicoPedido={historicoPedido}
            />
            
            <ModalReprovado 
                showReprovado={showReprovado}
                handleCloseReprovado={handleCloseReprovado}
                pedidoSelecionado={pedidoSelecionado}
                handleEnviarDevolucao={handleEnviarCadDevolucao}
                envio={envio}
            />

            <ModalCad 
                showCad={showCad}
                handleCloseCad={handleCloseCad}
                pedidoSelecionado={pedidoSelecionado}
                handleEnviarCad={handleEnviarCadDevolucao}
                envio={envio}
            />
        </div>
    );
};

export default RapportReprovado;