import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap';
import styles from '../pa_revisao.module.scss';
import SelectInput from '../../../Misc/SelectInput/SelectInput';
import Tabela from '../../../Misc/Tabela/Tabela';
import { toast } from 'react-toastify';
import MaterialIcon from 'material-icons-react';
import { usePARevisao } from '../pa_revisao_context';
import ListarImagemPreview from '../../../Misc/ListarImagens/listar_imagens_preview';

const PARevisaoDirecionamento = () => {
  const { referenciaSelecionada, modalDirecionamento, setModalDirecionamento, listaDefeitos, listaStatusDefeito, listaDirecionamentos, gradeRevisao, setGradeRevisao, direcionamentoRevisao, setDirecionamentoRevisao, handleRemoverDirecionamento, quantidadeTotalDirecionada, precoTagDivergente, composicaoTagDivergente, abrirModalImagens } = usePARevisao();

  const [formObj, setFormObj] = useState({ grade: null, defeito: [], status_defeito: null, direcionamento: null, quantidade: null, imagens: [] });

  const direcionamento_columns = [
    {
      cell: (row, index) => {
        return (
          <div onClick={() => handleRemoverDirecionamento(row, index)}>
            <MaterialIcon
              icon={'delete'}
              size={20}
              color='#BB271A'
            />
          </div>
        )
      }, width: '60px'
    },
    { name: 'Tamanho', selector: row => row.grade.tamanho, width: '80px' },
    { name: 'Quantidade', selector: row => row.quantidade, width: '80px' },
    { name: 'Status', selector: row => row.status_defeito.label, width: '160px' },
    { name: 'Defeitos', selector: row => row.defeito.map(item => item.label).join(' | ') },
    {
      name: 'Direcionamento',
      selector: row => {
        return composicaoTagDivergente.key || precoTagDivergente.key ? '04 - DEVOLUÇÃO' : row.direcionamento.label
      },
      width: '160px'
    },
    {
      name: 'Imagens',
      cell: (row, index) => {
        return (
          <div onClick={() => abrirModalImagens(index)}>
            <MaterialIcon
              icon="photo_library"
              size={20}
            />
          </div>
        )
      }, width: '80px'
    }
  ];

  const handleSetFormObj = (field, value) => {
    if (field === 'grade') { formObj.quantidade = 0; formObj.direcionamento = null }
    if (field === 'quantidade' && parseInt(value) > formObj.grade.quantidade_aprovada) {
      value = formObj.grade.quantidade_aprovada;
    }
    if (field === 'quantidade' && (quantidadeTotalDirecionada + parseInt(value)) > referenciaSelecionada.qtde_pecas_amostra) {
      value = referenciaSelecionada.qtde_pecas_amostra - quantidadeTotalDirecionada;
    }
    setFormObj({ ...formObj, [field]: value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    formObj.imagens = [...formObj.imagens, ...files];
    if (formObj.imagens.length > 5) {
      formObj.imagens = formObj.imagens.splice(0, 5);
      toast.warning('Você só pode adicionar até cinco imagens por direcionamento!');
    }
    setFormObj({ ...formObj });
  };

  const validarDirecionamento = () => {
    const { grade, defeito, status_defeito, direcionamento, quantidade } = formObj;
    const errs = [];
    try {
      if (!grade) errs.push('tamanho');
      if (defeito.length === 0) errs.push('defeito');
      if (!status_defeito) errs.push('status_defeito');
      if (!direcionamento) errs.push('direcionamento');
      if (!quantidade || quantidade === 0) errs.push('quantidade');
      if (errs.length > 0) return toast.warning('Por favor selecione e/ou preencha todos os campos antes de inserir um direcionamento.');
      handleInserirDirecionamento(formObj);
      setFormObj({ grade: null, defeito: [], status_defeito: null, direcionamento: null, quantidade: null, imagens: [] });
    } catch (e) {
      toast.error('Houve um problema ao inserir o direcionamento, por favor tente novamente.');
    }
  }

  const customFilter = (option, searchText) => {
    if (!searchText) return true;
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  const handleInserirDirecionamento = (formObj) => {
    const { grade, quantidade, defeito, status_defeito, direcionamento, imagens } = formObj;
    try {
      direcionamentoRevisao.push({ grade, quantidade: parseInt(quantidade), defeito, status_defeito, direcionamento, imagens });
      gradeRevisao[grade.index_grade].quantidade_aprovada -= parseInt(quantidade);
      gradeRevisao[grade.index_grade].quantidade_defeituosa += parseInt(quantidade);
      gradeRevisao[grade.index_grade].sem_defeitos = false;
      setGradeRevisao([...gradeRevisao]);
      setDirecionamentoRevisao([...direcionamentoRevisao]);
    } catch (e) {
      throw 'Erro ao inserir direcionamento.\nPor favor tente novamente ou entre em contato com o suporte.';
    }
  };

  const closeModal = () => {
    setModalDirecionamento(false);
  }

  const removerImagem = (index) => {
    formObj.imagens.splice(index, 1);
    setFormObj({
      ...formObj,
      imagens: [...formObj.imagens]
    });
  }

  useEffect(() => {
    setFormObj({ grade: null, defeito: [], status_defeito: null, direcionamento: null, quantidade: null, imagens: [] });
  }, [modalDirecionamento]);

  return (
    <Modal
      show={modalDirecionamento}
      backdrop={'static'}
      keyboard={false}
      size={'xl'}
      onHide={closeModal}
      centered={true}
      backdropClassName={styles.backdrop_z_override}
      fullscreen={'lg-down'}
    >
      <Modal.Header closeButton style={{ fontWeight: '500' }}>
        Direcionamento Revisão {referenciaSelecionada.nf_segunda_qualidade ? '(Segunda Qualidade)' : ''} | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <div className={styles.direcionamento_info}>
          Quantidade de Peças na Amostra: <span className={styles.font_blue_big}>{referenciaSelecionada.qtde_pecas_amostra}</span> | Quantidade Total de Peças Direcionadas: <span className={styles.font_blue_big}>{quantidadeTotalDirecionada}</span>
        </div>
        <div
          className={styles.direcionamento_info_warn}
          style={{ display: referenciaSelecionada.qtde_pecas_amostra === quantidadeTotalDirecionada ? 'block' : 'none' }}
        >
          Você chegou ao limite de peças da amostra.
        </div>
        <div
          className={styles.direcionamento_info_warn}
          style={{ display: precoTagDivergente.key || composicaoTagDivergente.key ? 'block' : 'none' }}
        >
          Você indicou que há divergências entre as informações da TAG e do produto - todos os direcionamentos serão automaticamente alterados para DEVOLUÇÃO durante o processamento.
        </div>
        <Form className={styles.defeitos_form}>
          <section>
            <label className={styles.form_label}>Selecionar Tamanho</label>
            <ListGroup className={styles.list_group}>
              {gradeRevisao.map((item, index) => {
                return (
                  <ListGroup.Item
                    className={styles.list_item}
                    key={item.tamanho}
                    action={true}
                    active={formObj.grade && formObj.grade.tamanho === item.tamanho}
                    onClick={e => { e.preventDefault(); handleSetFormObj('grade', { ...item, index }); }}
                    disabled={parseInt(item.quantidade_aprovada) === 0 || item.sem_defeitos}
                    variant={
                      parseInt(item.quantidade_aprovada) === 0 ? 'warning' : item.sem_defeitos ? 'secondary' : 'primary'
                    }
                  >
                    [{item.tamanho}] - Restam: {item.quantidade_aprovada}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </section>

          <section className={styles.flex_section}>
            <SelectInput
              label={'Defeito'}
              style={{ width: '33%' }}
              value={formObj.defeito}
              onChange={e => handleSetFormObj('defeito', e.splice(0, 5))}
              options={listaDefeitos}
              filterOption={customFilter}
              isMulti={true}
            />

            <SelectInput
              label={'Direcionamento'}
              style={{ width: '33%' }}
              value={formObj.direcionamento}
              onChange={e => handleSetFormObj('direcionamento', e)}
              options={listaDirecionamentos}
              isOptionDisabled={opt => {
                return direcionamentoRevisao.some(item => {
                  if ((item.grade.tamanho === formObj?.grade?.tamanho) && (item.direcionamento.value === opt.value)) return true;
                  return false;
                });
              }}
            />

            <Form.Group className={styles.select_group} style={{ width: '33%' }}>
              <Form.Label className={styles.form_label}>Quantidade Direcionada</Form.Label>
              <Form.Control
                type={'number'}
                placeholder={0}
                min={formObj.grade ? 1 : 0}
                max={formObj.grade ? formObj.grade.quantidade : 0}
                style={{ width: '100%', height: '38px' }}
                value={formObj.quantidade || 0}
                onChange={e => handleSetFormObj('quantidade', e.target.value)}
                disabled={!formObj.grade || referenciaSelecionada.qtde_pecas_amostra === quantidadeTotalDirecionada}
              />
            </Form.Group>
          </section>

          <section className={styles.flex_section}>
            <SelectInput
              label={'Status Defeito'}
              style={{ width: '33%' }}
              value={formObj.status_defeito}
              onChange={e => handleSetFormObj('status_defeito', e)}
              options={listaStatusDefeito}
              filterOption={customFilter}
            />
            <div style={{ width: '33%' }} />
            <div style={{ width: '33%' }} />
          </section>
          <section className={styles.flex_section}>
            <div style={{ width: '33%' }}>
              {
                <ListarImagemPreview
                  imagens={formObj.imagens}
                  removerImagem={removerImagem}
                />
              }
            </div>
            <div style={{ width: '33%' }}>
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                variant="secondary"
                className={styles.btn_footer}
                style={{ width: '100%', fontSize: '13px', height: '36px', fontWeight: 500 }}
                onClick={() => document.getElementById('imageInput').click()}
              >
                Adicionar Imagens
              </Button>
            </div>

            <Button
              className={styles.btn_footer}
              variant={'outline-success'}
              style={{ width: '33%', fontSize: '13px', height: '36px', fontWeight: 500 }}
              onClick={validarDirecionamento}
            >
              Inserir
            </Button>
          </section>
        </Form>

        <section>
          <label className={styles.form_label}>Peças Defeituosas Direcionadas</label>
          <Tabela
            columns={direcionamento_columns}
            data={direcionamentoRevisao}
            pagination={false}
          />
        </section>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button
          variant={'success'}
          className={styles.footer_btn_processamento}
          onClick={closeModal}
        >
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoDirecionamento;
