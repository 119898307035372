import React, { useState, useEffect } from "react";
import { Button, Table, Form, FormGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';

import { toUTCDDMMYYYY, toDatetime } from '../../../utils/dateUtils';
import { objToCsv } from "../../../utils/csvUtils";

import "bootstrap/dist/css/bootstrap.min.css";
import { api } from "../../../services/api";

import "react-toastify/dist/ReactToastify.min.css";

import styles from "../../../styles/AgendamentoDevolucao.module.scss";
import { downloadFile } from "../../../utils/downloadUtils";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useMisc } from "../../../contexts/MiscContext";

function AgendDev() {
  const { setShowLoading } = useMisc();

  const setField = (field, value) => {
    setFormObj({
      ...formObj,
      [field]: value
    });
    if (!!formObj[field]) {
      setFormErrs({
        ...formErrs,
        [field]: null
      });
    }
  }

  const actionList = [
    { value: 'CONTAGEM', label: 'Contagem' },
    { value: 'FATURAMENTO', label: 'Faturamento' },
    { value: 'PENDENCIA', label: 'Pendência PCP' }
  ];

  const [mainArr, setMainArr] = useState([]);
  const [activeObj, setActiveObj] = useState({});
  const [activeAction, setActiveAction] = useState(actionList[0]);
  const [filters, setFilters] = useState({ invoice: '', refNumber: '', color: '' });
  const [formObj, setFormObj] = useState({});
  const [formGrade, setFormGrade] = useState([]);
  const [qtdeInserida, setQtdeInserida] = useState(0);
  const [formErrs, setFormErrs] = useState({});
  const [showCountModal, setShowCountModal] = useState(false);
  const [showInvoicingModal, setShowInvoicingModal] = useState(false);

  const handleCloseModal = () => {
    setActiveObj({});
    setFormObj({});
    setFormGrade([]);
    setQtdeInserida(0);
    setFormErrs({});
    setShowCountModal(false);
    setShowInvoicingModal(false);
  }

  const handleShowModal = async (el) => {
    if (el.STATUS && el.STATUS === 'PENDENTE CONTAGEM') {

      const params = {
        nfEntrada: el.NFEntrada,
        serieNF: el.SerieNF,
        produto: el.Produto,
        corProduto: el.CorProduto,
        qtdeEntrada: el.QtdeEntrada,
        origem: el.Origem,
        ordemProducao: el.OrdemProducao,
        filialRevisao: el.FilialRevisao,
        grade: el.Grade
      }

      try {
        const res = await api.get('/PAAgendaDevolucao/agenda-devolucao-grade', { params });
        setActiveObj(el);
        setFormGrade(res.data);
        setShowCountModal(true);
      } catch (e) {
        setActiveObj({});
        setShowCountModal(false);
        toast.error('Ocorreu um erro durante a busca da grade da referência selecionada.\nPor favor tente novamente ou entre em contato com o suporte.');
      }
    }
    if (el.STATUS && el.STATUS === 'PENDENTE FATURAMENTO') {
      setActiveObj(el);
      setShowInvoicingModal(true);
    }
  }

  const handleCountProcess = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);
      const errors = handleCountValidation();
      if (Object.keys(errors).length > 0) return setFormErrs(errors);
      const params = {
        ...activeObj,
        QtdePecasContadas: qtdeInserida,
        Volume: parseInt(formObj.Volume),
        formGrade,
        Obs: formObj.Obs
      }
      await api.post("/PAAgendaDevolucao/AgDevPostContagem", params);
      toast.success('Dados alterados com sucesso!');
      handleCloseModal();
      handleFilteredSearch(e);
    } catch (e) {
      toast.error('Ocorreu um erro durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleCountValidation = () => {
    const { Volume, Obs } = formObj;
    const errors = {}
    if (!Volume || Volume <= 0) errors.Volume = 'Volume deve ser maior do que zero';
    if (!Obs || Obs === undefined) formObj.Obs = '';
    if (formGrade.some(item => item.marked && item.qtde_inserida === 0)) {
      errors.Grade = 'Quantidades de tamanhos selecionados não podem ser zero';
      toast.warn('Quantidades de tamanhos selecionados não podem ser zero');
    }
    return errors;
  }

  const handleInvoiceProcess = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);
      const errors = handleInvoiceValidation();
      if (Object.keys(errors).length > 0) return setFormErrs(errors);
      const params = {
        ...activeObj,
        NFSaida: formObj.NFSaida,
        SerieNFSaida: formObj.SerieNFSaida,
        OP_PedidoCriada: formObj.OP_PedidoCriada,
        DataEmissaoNFSaida: toDatetime(formObj.DataEmissaoNFSaida)
      }
      await api.post("/PAAgendaDevolucao/AgDevPostFaturamento", params)
      toast.success('Dados alterados com sucesso!');
      handleCloseModal();
      handleFilteredSearch(e);
    } catch (e) {
      toast.error('Ocorreu um erro durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleInvoiceValidation = () => {
    const { NFSaida, SerieNFSaida, OP_PedidoCriada, DataEmissaoNFSaida } = formObj;
    const errors = {}
    if (!NFSaida || NFSaida === '' || NFSaida < 0) errors.NFSaida = 'Por favor insira uma nota fiscal válida';
    if (!SerieNFSaida || SerieNFSaida === '' || SerieNFSaida < 0) errors.SerieNFSaida = 'Por favor insira uma série válida';
    if (!OP_PedidoCriada || OP_PedidoCriada === '' || OP_PedidoCriada < 0) errors.OP_PedidoCriada = 'Por favor insira um(a) pedido/ordem de produção válido(a)';
    if (!DataEmissaoNFSaida || !(new Date(DataEmissaoNFSaida).getTime() > 0) || DataEmissaoNFSaida === undefined) errors.DataEmissaoNFSaida = 'Por favor insira uma data válida';
    return errors;
  }

  const handleFilteredSearch = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);
      const params = {
        ...filters,
        action: activeAction.value
      }
      const res = await api.get('/PAAgendaDevolucao/AgendaDevolucaoBuscar', { params });
      setMainArr(res.data);
    } catch (e) {
      toast.error('Ocorreu um problema ao carregar o grid principal.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleCSVConversion = (e) => {
    e.preventDefault();
    if (mainArr.length > 0) {
      const csv = objToCsv(mainArr);
      downloadFile(csv, 'text/csv', 'agenda_devolucao_pa', 'csv');
    } else {
      toast.error(
        "Erro na exportação. Certifique-se que há items na tabela principal e tente novamente."
      );
    }
  }

  const semicolSep = (str) => {
    if (str && str !== undefined) return str.replace(/;/g, '; ');
  }

  useEffect(() => {
    (async () => {
      setShowLoading(true);
      try {
        const res = await api.get('/PAAgendaDevolucao/AgendaDevolucaoBuscar');
        setMainArr(res.data);
      } catch (e) {
        toast.error('Ocorreu um problema ao carregar o grid principal.\nPor favor tente novamente ou entre em contato com o suporte.');
      } finally {
        setShowLoading(false);
      }
    })()
  }, []);

  const handleCheck = (index) => {
    formGrade[index].marked = !formGrade[index].marked;
    formGrade[index].qtde_inserida = 0;
    setFormGrade([...formGrade]);
    setQtdeInserida(formGrade.reduce((sum, item) => sum + item.qtde_inserida, 0));
  }

  const handleGradeQtdeChange = (index, value) => {
    formGrade[index].qtde_inserida = parseInt(value.replace(/[^0-9]*/g, ''));
    if (isNaN(formGrade[index].qtde_inserida)) formGrade[index].qtde_inserida = 0;
    setFormGrade([...formGrade]);
    setQtdeInserida(formGrade.reduce((sum, item) => sum + item.qtde_inserida, 0));
  }

  return (
    <div>
      <article >
        <Form className={styles.form}>
          <FormGroup className={styles.formGroup}>
            <Form.Label >
              Ação
            </Form.Label>
            <Select
              closeMenuOnSelect={true}
              options={actionList}
              defaultValue={actionList[0]}
              onChange={actionObj => setActiveAction(actionObj)}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label >
              NF Entrada
            </Form.Label>
            <Form.Control
              type="text"
              value={filters.invoice}
              onChange={e => setFilters({ ...filters, invoice: e.target.value })}
              placeholder={'Nota fiscal de entrada'}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label>
              Referência
            </Form.Label>
            <Form.Control
              type="text"
              value={filters.refNumber}
              onChange={e => setFilters({ ...filters, refNumber: e.target.value })}
              placeholder={'Nº Referência'}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label>
              Cor
            </Form.Label>
            <Form.Control
              type="text"
              value={filters.color}
              onChange={e => setFilters({ ...filters, color: e.target.value })}
              placeholder={'Cor'}
            />
          </FormGroup>
          <Button
            className={styles.formGroup}
            variant="primary"
            type="button"
            onClick={handleFilteredSearch}
          >
            Buscar
          </Button>
          <Button
            type="button"
            variant="outline-success"
            onClick={e => handleCSVConversion(e)}
          >
            <div className={styles.formBtnDisplay}>
              <i>
                <MaterialIcon
                  icon="download"
                  size={20}
                  color='#198754'
                />
              </i>
              Exportar
            </div>
          </Button>
        </Form>
      </article>

      <article className={styles.articleGray}>
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th></th>
              <th className="block-title">NF ENTRADA</th>
              <th className="block-title">SÉRIE NF</th>
              <th className="block-title">PRODUTO</th>
              <th className="block-title">COR PRODUTO</th>
              <th className="block-title">QTDE ENTRADA</th>
              <th className="block-title">DATA REGISTRO</th>
              <th className="block-title">ORIGEM</th>
              <th className="block-title">DATA DA CONTAGEM</th>
              <th className="block-title">QTDE PEÇAS CONTADAS</th>
              <th className="block-title">DATA AGENDAMENTO</th>
              <th className="block-title">QTDE PEÇAS AGENDADAS</th>
              <th className="block-title">FILIAL REVISÃO</th>
              <th className="block-title">QTDE VOLUMES</th>
              <th className="block-title">DATA DA COLETA</th>
              <th className="block-title">DESTINO DEVOLUÇÃO</th>
              <th className="block-title">FORNECEDOR DEVOLUÇÃO</th>
              <th className="block-title">CÓD. FORNECEDOR DEVOLUÇÃO</th>
              <th className="block-title">TRANSPORTADORA</th>
              <th className="block-title">CNPJ TRANSPORTADORA</th>
              {/* <th className="block-title">ID TIPO FRETE</th> */}
              <th className="block-title">TIPO FRETE</th>
              <th className="block-title">CUSTO POR PEÇA</th>
              <th className="block-title">STATUS</th>
              <th className="block-title">GRADE</th>
              <th className="block-title">TIPO PRODUTO</th>
              <th className="block-title">RETORNA CD</th>
              <th className="block-title">OBS CONTAGEM</th>
            </tr>
          </thead>
          <tbody>
            {mainArr.length > 0 && mainArr.map((el, index) => {
              return (
                <tr className="table-lines" key={index}>
                  <td>
                    <i
                      onClick={() => { handleShowModal(el) }}
                      disabled={el.STATUS === 'PENDENTE PCP' || el.STATUS === 'PROCESSO FINALIZADO'}
                      className={`${(el.STATUS === 'PENDENTE PCP' || el.STATUS === 'PROCESSO FINALIZADO' ? styles.opaque : styles.hoverBtn)}`}
                    >
                      <MaterialIcon
                        icon="description"
                        size={30}
                        color='#0D6EFD'
                      />
                    </i>
                  </td>
                  <td className="table-line">{el.NFEntrada}</td>
                  <td className="table-line">{el.SerieNF}</td>
                  <td className="table-line">{el.Produto}</td>
                  <td className="table-line">{el.CorProduto}</td>
                  <td className="table-line">{el.QtdeEntrada}</td>
                  <td className="table-line">{toUTCDDMMYYYY(el.DataRegistro)}</td>
                  <td className="table-line">{el.Origem}</td>
                  <td className="table-line">{el.DataContagem}</td>
                  <td className="table-line">{el.QtdePecasContadas}</td>
                  <td className="table-line">{toUTCDDMMYYYY(el.DataAgendamento)}</td>
                  <td className="table-line">{el.QtdePecasAgendadas}</td>
                  <td className="table-line">{el.FilialRevisao}</td>
                  <td className="table-line">{el.QtdeVolumes}</td>
                  <td className="table-line">{el.DataColeta}</td>
                  <td className="table-line">{el.DestinoDevolucao}</td>
                  <td className="table-line">{el.Fornecedor}</td>
                  <td className="table-line">{el.CodFornecedorDevolucao}</td>
                  <td className="table-line">{el.Transportadora}</td>
                  <td className="table-line">{el.CNPJ_Transportadora}</td>
                  <td className="table-line">{el.IDTipoFrete}</td>
                  {/* <td className="table-line">{el.TipoFrete}</td> */}
                  <td className="table-line">{el.CustoPeca}</td>
                  <td className="table-line">{el.STATUS}</td>
                  <td className="table-line">{semicolSep(el.Grade)}</td>
                  <td className="table-line">{el.TipoProduto}</td>
                  <td className="table-line">{el.RetornaCD}</td>
                  <td className="table-line">{el.ObsContagem}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </article>

      <Modal
        show={showCountModal}
        onHide={handleCloseModal}
        backdrop="static"
        size="lg"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Controle Devolução - Contagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article className={styles.infoProduto}>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>NF Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Série:</span>
                <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Ordem Produção:</span>
                <span className={styles.fontBlue}>{activeObj.OrdemProducao}</span>
              </div>
            </div>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Fornecedor:</span>
                <span className={styles.fontBlue}>{activeObj.Origem}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Produto:</span>
                <span className={styles.fontBlue}>{activeObj.Produto}</span>
              </div>
            </div>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Cor Produto:</span>
                <span className={styles.fontBlue}>{activeObj.CorProduto}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Qtde Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
              </div>
            </div>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Filial Revisão:</span>
                <span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Data Contagem:</span>
                <span className={styles.fontBlue}>{toUTCDDMMYYYY(activeObj.DataContagem)}</span>
              </div>
            </div>
          </article>
          <article className={styles.column_wrapper}>
              <Form.Group controlId="Volume" className={`m-2 ${styles.formInput}`}>
                <Form.Label>Volume</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min={0}
                  value={formObj.Volume || ''}
                  onChange={e => setField('Volume', e.target.value)}
                  placeholder={'Volume'}
                  isInvalid={!!formErrs.Volume}
                  style={{width: '160px'}}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>{formErrs.Volume}</Form.Control.Feedback>
              </Form.Group>

            <div style={{ marginLeft: '6.5px' }}>Grade | Qtde. Máxima: {activeObj.QtdeEntrada} | Qtde. Inserida: {qtdeInserida}</div>
            {formGrade.map((item, index) => {
              return (
                <Form.Group
                  className={styles.form_grade_wrapper}
                  controlId={`tamanho-${index}`}
                  key={index}
                >
                  <Form.Check
                    type={'checkbox'}
                    className={styles.form_grade_checkbox}
                    onChange={() => handleCheck(index)}
                    label={`Tamanho ${item.label} | Quantidade ${item.qtde_max}`}
                    checked={item.marked}
                    disabled={item.qtde_max === 0}
                  />
                  <Form.Control
                    type="text"
                    className={styles.form_grade_number}
                    value={item.qtde_inserida}
                    onChange={e => handleGradeQtdeChange(index, e.target.value)}
                    disabled={item.qtde_max === 0 || !item.marked}
                  />
                </Form.Group>
              )
            })}
            
            <Form.Group controlId="Obs" className={`m-2 ${styles.form_obs}`}>
              <Form.Label>
                Observação<span className={styles.spanOpcional}>(Opcional)</span>
              </Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={4}
                value={formObj.Obs || ''}
                onChange={e => setField('Obs', e.target.value)}
                placeholder={'Observações sobre a contagem'}
                isInvalid={!!formErrs.Obs}
              ></Form.Control>
              <Form.Control.Feedback type='invalid'>{formErrs.Obs}</Form.Control.Feedback>
            </Form.Group>
          </article>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            variant="outline-danger"
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleCountProcess}
            disabled={
              (!formObj.Volume || formObj.Volume <= 0 || formObj.Volume === '')
              || (qtdeInserida === 0 || qtdeInserida > activeObj.QtdeEntrada)
            }
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showInvoicingModal}
        onHide={handleCloseModal}
        backdrop="static"
        size="lg"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Controle Devolução - Faturamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article className={styles.infoProduto}>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>NF Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Série:</span>
                <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Tipo Frete:</span>
                <span className={styles.fontBlue}>{activeObj.TipoFrete}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Custo por Peça:</span>
                <span className={styles.fontBlue}>{activeObj.CustoPeca}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Volumes:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeVolumes}</span>
              </div>
            </div>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Fornecedor:</span>
                <span className={styles.fontBlue}>{activeObj.Origem}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Produto:</span>
                <span className={styles.fontBlue}>{activeObj.Produto}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>CNPJ Transportadora:</span>
                <span className={styles.fontBlue}>{activeObj.CNPJ_Transportadora}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Transportadora:</span>
                <span className={styles.fontBlue}>{activeObj.Transportadora}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Data da Coleta:</span>
                <span className={styles.fontBlue}>{toUTCDDMMYYYY(activeObj.DataColeta)}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Retorna CD:</span>
                <span className={styles.fontRed}>{activeObj.RetornaCD}</span>
              </div>

            </div>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Cor Produto:</span>
                <span className={styles.fontBlue}>{activeObj.CorProduto}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Qtde Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Fornecedor Devolução:</span>
                <span className={styles.fontBlue}>{activeObj.CodFornecedorDevolucao}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Peças Contadas:</span>
                <span className={styles.fontBlue}>{activeObj.QtdePecasContadas}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Observação Contagem:</span>
                <span className={styles.fontBlue}>{activeObj.ObsContagem}</span>
              </div>
            </div>
            <div className={styles.infoProdutoMargin}>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Filial Revisão:</span>
                <span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Tipo Produto:</span>
                <span className={styles.fontBlue}>{activeObj.TipoProduto}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Destino Devolução:</span>
                <span className={styles.fontBlue}>{activeObj.DestinoDevolucao}</span>
              </div>
              <div className={styles.infoProdutoLabelColumm}>
                <span>Grade:</span>
                <span className={styles.fontBlue}>{semicolSep(activeObj.Grade)}</span>
              </div>
            </div>
          </article>
          <article>
            <div className={`mt-3 ${styles.flexWrapper}`}>
              <Form.Group controlId="NFSaida" className={`m-2 ${styles.formInput}`}>
                <Form.Label>NF Saída:</Form.Label>
                <Form.Control
                  required
                  value={formObj.NFSaida || ''}
                  onChange={e => setField('NFSaida', e.target.value)}
                  type="text"
                  placeholder={'Nota fiscal de saída'}
                  isInvalid={formErrs.NFSaida}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>{formErrs.NFSaida}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="SerieNFSaida" className={`m-2 ${styles.formInput}`}>
                <Form.Label>Série:</Form.Label>
                <Form.Control
                  required
                  value={formObj.SerieNFSaida || ''}
                  onChange={e => setField('SerieNFSaida', e.target.value)}
                  type="text"
                  placeholder={'Série da NF de saída'}
                  isInvalid={formErrs.SerieNFSaida}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>{formErrs.SerieNFSaida}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="OP_PedidoCriada" className={`m-2 ${styles.formInput}`}>
                <Form.Label>OP/Pedido Criado:</Form.Label>
                <Form.Control
                  required
                  value={formObj.OP_PedidoCriada || ''}
                  onChange={e => setField('OP_PedidoCriada', e.target.value)}
                  type="text"
                  placeholder={'OP/Pedido Criado'}
                  isInvalid={formErrs.OP_PedidoCriada}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>{formErrs.OP_PedidoCriada}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="DataEmissaoNFSaida" className={`m-2 ${styles.formInput}`}>
                <Form.Label>
                  Data Emissão:
                </Form.Label>
                <Form.Control
                  required
                  value={formObj.DataEmissaoNFSaida || ''}
                  onChange={e => setField('DataEmissaoNFSaida', e.target.value)}
                  type="date"
                  placeholder={'Data de emissão da NF de saída'}
                  min={'1970-01-01'}
                  isInvalid={formErrs.DataEmissaoNFSaida}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>{formErrs.DataEmissaoNFSaida}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </article>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            variant="outline-danger"
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            disabled={
              (formObj.NFSaida === '' || formObj.NFSaida === undefined)
              || (formObj.SerieNFSaida === '' || formObj.SerieNFSaida === undefined)
              || (formObj.OP_PedidoCriada === '' || formObj.OP_PedidoCriada === undefined)
              || (formObj.DataEmissaoNFSaida === '' || formObj.DataEmissaoNFSaida === undefined)
              || (formObj.DataEmissaoNFSaida !== undefined && new Date(formObj.DataEmissaoNFSaida).getTime() <= 0)
            }
            onClick={handleInvoiceProcess}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AgendDev;
