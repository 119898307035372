import React from 'react';
import styles from '../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import MainTable from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/MainTable';
import ModalGrade from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalGrade';
import ModalImagem from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalImagem';
import ModalEnfest from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalEnfest';
import ModalAlteraFolha from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalAlteraFolha';
import ModalConfirmacao from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/ModalConfirmacao';
import FilterComponent from '../../../components/Corte/ControleEnfesto/DistribuicaoDemanda/FilterComponent';

const DistribuicaoDemanda = () => {
    
    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">Corte - Distribuicao de Demanda</h3>
            </article>

            <FilterComponent/>

            <article className={styles.articleGray}>
                <MainTable/>
            </article>

            <ModalGrade/>
            <ModalImagem/>
            <ModalAlteraFolha/>
            <ModalEnfest/>
            <ModalConfirmacao/>
        </div>
    );
};

export default DistribuicaoDemanda;
