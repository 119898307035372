import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form } from 'react-bootstrap';

import Select from 'react-select';
import { toast, ToastContainer } from "react-toastify";
import { api }  from "../services/api";
import styles from "../styles/FormularioAnalise.module.scss";

import CookiesService from "../services/cookies";
import { toDatetime, toYYYYMMDD } from "../utils/dateUtils";

const FormularioAnalise = () => {
	const actionOpt = [{ label: 'Cadastrar', value: 1 }, { label: 'Atualizar', value: 2 }];
	const [showForm, setShowForm] = useState(false);
	const [mainFilters, setMainFilters] = useState({});
	const [activeAction, setActiveAction] = useState(0);
	const [filterErrs, setFilterErrs] = useState({});
	const [mainOpts, setMainOpts] = useState({});
	const [formObj, setFormObj] = useState({});
	const [formErrs, setFormErrs] = useState({});
	const userLogin = CookiesService.getUserLogin();

	const fetchOptFields = async () => {
		let listaFiliais, listaTipoAnalise, listaTipoDefeito, listaStatusAnalise, listaRevisoras, listaMotivoRecusa;
		listaFiliais = listaTipoAnalise = listaTipoDefeito = listaStatusAnalise = listaRevisoras = listaMotivoRecusa = [];
		await api.get('/PAAnalise/GetListaFiliais')
			.then(res => res.status === 200 ? listaFiliais = res.data : null)
			.catch(e => console.error(`Erro na requisição '/PAAnalise/GetListaFiliais' - ${e}`));
		await api.get('/PAAnalise/GetListaTipoAnalise')
			.then(res => res.status === 200 ? listaTipoAnalise = res.data : null)
			.catch(e => console.error(`Erro na requisição '/PAAnalise/GetListaTipoAnalise' - ${e}`));
		await api.get('/PAAnalise/GetListaTipoDefeito')
			.then(res => res.status === 200 ? listaTipoDefeito = res.data : null)
			.catch(e => console.error(`Erro na requisição '/PAAnalise/GetListaTipoDefeito' - ${e}`));
		await api.get('/PAAnalise/GetListaStatusAnalise')
			.then(res => res.status === 200 ? listaStatusAnalise = [{ label: 'EM ANALISE', value: 'EM ANALISE' }].concat(res.data) : null)
			.catch(e => console.error(`Erro na requisição '/PAAnalise/GetListaStatusAnalise' - ${e}`));
		await api.get('/PAAnalise/GetListaRevisoras')
			.then(res => res.status === 200 ? listaRevisoras = res.data : null)
			.catch(e => console.error(`Erro na requisição '/PAAnalise/GetListaRevisoras' - ${e}`));
		await api.get('/PAAnalise/GetListaMotivoRecusa')
			.then(res => res.status === 200 ? listaMotivoRecusa = res.data : null)
			.catch(e => console.error(`Erro na requisição '/PAAnalise/GetListaMotivoRecusa' - ${e}`));
		if (listaFiliais.length === 0 || listaTipoAnalise.length === 0 || listaTipoDefeito.length === 0 || listaStatusAnalise.length === 0 || listaRevisoras.length === 0 || listaMotivoRecusa.length === 0) {
			toast.error('Erro ao preencher os campos de filtro, por favor entre em contato com o suporte.');
		} else {
			setMainOpts({ listaFiliais, listaTipoAnalise, listaTipoDefeito, listaStatusAnalise, listaRevisoras, listaMotivoRecusa });
		}
	}

	const setMainAction = (e) => {
		if (e && e.value === 1) setMainFilters({ ...mainFilters, MainAction: e, NumeroAnalise: '' });
		if (e && e.value === 2) setMainFilters({ ...mainFilters, MainAction: e, CodigoLoja: null });
	}

	const handleMainSearch = async (e) => {
		e.preventDefault();
		const errs = handleFilterValidation();
		if (Object.values(errs).length > 0) {
			return setFilterErrs(errs);
		} else {
			const params = { Produto: mainFilters.Produto, CorProduto: mainFilters.CorProduto, CodigoLoja: mainFilters.CodigoLoja, NumeroAnalise: mainFilters.NumeroAnalise }
			if (mainFilters.MainAction.value === 1) {
				await api.get('/PAAnalise/FilterByProdutoAndCor', { params })
					.then(res => {
						if (res.status === 200 && res.data.length > 0) {
							setActiveAction(1);
							setShowForm(true);
							setFormObj({
								...formObj,
								Produto: mainFilters.Produto,
								CorProduto: mainFilters.CorProduto,
								CodigoLoja: mainFilters.CodigoLoja,
								...res.data[0]
							});
						} else {
							toast.warn('Não foi encontrado nenhum processo com esses dados.');
						}
					})
					.catch(e => console.error(`Erro na requisição '/PAAnalise/FilterByProdutoAndCor' - ${e}`));

			} else if (mainFilters.MainAction.value === 2) {
				await api.get('/PAAnalise/ExecConsultaAnalise', { params })
					.then(res => {
						if (res.status === 200 && res.data.length > 0) {
							const data = res.data[0];
							setActiveAction(2);
							setShowForm(true);
							setFormObj({
								...formObj,
								Produto: mainFilters.Produto,
								CorProduto: mainFilters.CorProduto,
								CodigoLoja: mainFilters.CodigoLoja,
								...data,
								DataEntradaLoja: data.DataEntradaLoja.slice(0, 10),
								TipoAnalise: { label: data.TipoAnalise, value: data.TipoAnalise.slice(0, 2) },
								TipoDefeito: { label: data.TipoDefeito, value: data.TipoDefeito.slice(0, 2) },

								StatusAnalise: data.StatusAnalise ? { label: data.StatusAnalise, value: parseInt(data.StatusAnalise.slice(0, 2)) } : data.StatusAnalise,
								// DataEnvioConserto: data.DataEnvioConserto ? data.DataEnvioConserto.slice(0, 10) : data.DataEnvioConserto,
								// DataRetornoConserto: data.DataRetornoConserto ? data.DataRetornoConserto.slice(0, 10) : data.DataRetornoConserto,
								// ResponsavelConserto: data.ResponsavelConserto,
								// ObservacaoConserto: data.ObservacaoConserto,

								DataEntradaAnalise: data.DataEntradaAnalise ? data.DataEntradaAnalise.slice(0, 10) : data.DataEntradaAnalise,
								Revisora: data.Revisora ? { label: data.Revisora, value: data.RevisoraValue } : data.Revisora,
								MotivoRecusa: data.MotivoRecusa ? { label: data.MotivoRecusa, value: data.MotivoRecusa.slice(0, 2) } : data.MotivoRecusa,
								// Consideracao: data.Consideracao,

								DataSaidaAnalise: data.DataSaidaAnalise ? data.DataSaidaAnalise.slice(0, 10) : data.DataSaidaAnalise,
								// NFSaida: data.NFSaida,
								// SerieNFSaida: data.SerieNFSaida,
								// NumeroLacre: data.NumeroLacre
							});
						} else {
							toast.warn('Não foi encontrado nenhum processo com esses dados.');
						}
					})
					.catch(e => console.error(`Erro na requisição '/PAAnalise/ExecConsultaAnalise' - ${e}`));
			}
		}
	}

	const handleFilterValidation = () => {
		const { MainAction, CodigoLoja, NumeroAnalise, Produto, CorProduto } = mainFilters;
		const errs = {}
		if (!MainAction || MainAction === '') errs.MainAction = 'Selecione uma ação';
		if (MainAction && MainAction.value === 1 && (!CodigoLoja || CodigoLoja === '')) errs.CodigoLoja = 'Selecione uma filial';
		if (MainAction && MainAction.value === 2 && (!NumeroAnalise || NumeroAnalise === '')) errs.NumeroAnalise = 'Insira um código de análise';
		if (!Produto || Produto === '') errs.Produto = 'Insira um código de produto';
		if (!CorProduto || CorProduto === '') errs.CorProduto = 'Insira um código de cor';
		return errs;
	}

	const handleProcess = async (e) => {
		e.preventDefault();
		if (activeAction === 1) {
			const errs = { ...handleCadastroValidation(), ...handleAtualizarValidation() };
			if (Object.keys(errs).length > 0) {
				return setFormErrs(errs);
			} else {
				const params = {
					DataRegistro: toDatetime(new Date()),
					IDEmpresa: 1,			 						// CAMPOS DESCONTINUADOS E PADRONIZADOS - VALOR: 1
					IDSetor: 1,			 							// CAMPOS DESCONTINUADOS E PADRONIZADOS - VALOR: 1
					IDSegmento: 1,			 					// CAMPOS DESCONTINUADOS E PADRONIZADOS - VALOR: 1
					IDArea: 1,			 							// CAMPOS DESCONTINUADOS E PADRONIZADOS - VALOR: 1
					IDFilialArea: 3,			 				// CAMPOS DESCONTINUADOS E PADRONIZADOS - VALOR: 3
					IDUsuario: userLogin ? userLogin : null,
					CodigoLoja: formObj.CodigoLoja.label,
					Produto: formObj.Produto,
					CorProduto: formObj.CorProduto,

					DescricaoProduto: formObj.DescProduto,
					DescricaoCor: formObj.DescCorProduto,
					Colecao: formObj.Colecao,
					Griffe: formObj.Griffe,
					Marca: formObj.Marca,
					Linha: formObj.Linha,
					TipoProduto: formObj.TipoProduto,
					GrupoProduto: formObj.GrupoProduto,
					Fabricante: formObj.Fabricante,

					NumeroAnalise: formObj.NumeroAnalise,
					DataEntradaLoja: formObj.DataEntradaLoja,
					NFEntrada: formObj.NFEntrada,
					SerieNFEntrada: formObj.SerieNFEntrada,
					TotalPecas: formObj.TotalPecas,
					TipoAnalise: formObj.TipoAnalise.label,
					TipoDefeito: formObj.TipoDefeito.label,
					TipoDefeito2: formObj.TipoDefeito2 ? formObj.TipoDefeito2.label : null,
					NomeCliente: formObj.NomeCliente,
					NomeVendedor: formObj.NomeVendedor,

					StatusAnalise: formObj.StatusAnalise ? formObj.StatusAnalise.label : null,
					DataEntradaAnalise: formObj.DataEntradaAnalise,
					Revisora: formObj.Revisora ? formObj.Revisora.value : null,
					MotivoRecusa: formObj.MotivoRecusa ? formObj.MotivoRecusa.label : null,
					Consideracao: formObj.Consideracao ? formObj.Consideracao : null,

					DataEnvioConserto: toDatetime(new Date()),
					DataRetornoConserto: toDatetime(new Date()),
					ResponsavelConserto: formObj.ResponsavelConserto ? formObj.ResponsavelConserto : null,
					ObservacaoConserto: formObj.ObservacaoConserto ? formObj.ObservacaoConserto : null,

					DataSaidaAnalise: formObj.DataSaidaAnalise ? formObj.DataSaidaAnalise : null,
					NFSaida: formObj.NFSaida ? formObj.NFSaida : null,
					SerieNFSaida: formObj.SerieNFSaida ? formObj.SerieNFSaida : null,
					NumeroLacre: formObj.NumeroLacre ? formObj.NumeroLacre : null
				}

				await api.post('/PAAnalise/ExecCadastroAnalise', params)
					.then(res => {
						if (!res.data.errNumber && res.data.errNumber !== 2627) {
							toast.success('Dados alterados com sucesso!');
							setFormObj({});
							setActiveAction(0);
							setShowForm(false);
							setFilterErrs({});
							setMainFilters({});
						} else if (res.data.errNumber && res.data.errNumber === 2627) {
							res.data.message = res.data.message.split('-')[1];
							console.error(`ERROR NUMBER ${res.data.errNumber} - ${res.data.message}`);
							toast.warning(`Ops! Parece que o número de análise "${params.NumeroAnalise}" já está cadastrado para esse produto, por favor confirme seu valor ou use a ação "Atualizar" se desejar altera-lo`);
						}
					})
					.catch(e => {
						console.error(e);
						toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
					});
			}
		} else if (activeAction === 2) {
			const errs = handleAtualizarValidation();
			if (Object.keys(errs).length > 0) {
				setFormErrs(errs);
				return toast.warn('Por favor revise os campos marcados em vermelho');
			} else {
				const params = {
					NumeroAnalise: formObj.NumeroAnalise,
					Produto: formObj.Produto,
					CorProduto: formObj.CorProduto,

					StatusAnalise: formObj.StatusAnalise.label ? formObj.StatusAnalise.label : null,
					// DataEntradaAnalise: formObj.DataEntradaAnalise,
					Revisora: formObj.Revisora ? formObj.Revisora.value : null,
					MotivoRecusa: formObj.MotivoRecusa ? formObj.MotivoRecusa.label : null,
					Consideracao: formObj.Consideracao ? formObj.Consideracao : null,

					DataEnvioConserto: toDatetime(new Date()),
					DataRetornoConserto: toDatetime(new Date()),
					ResponsavelConserto: formObj.ResponsavelConserto ? formObj.ResponsavelConserto : null,
					ObservacaoConserto: formObj.ObservacaoConserto ? formObj.ObservacaoConserto : null,

					DataSaidaAnalise: formObj.DataSaidaAnalise,
					NFSaida: formObj.NFSaida ? formObj.NFSaida : null,
					SerieNFSaida: formObj.SerieNFSaida ? formObj.SerieNFSaida : null,
					NumeroLacre: formObj.NumeroLacre ? formObj.NumeroLacre : null
				}

				await api.post('/PAAnalise/ExecUpdateAnalise', params)
					.then(res => {
						if (res) {
							toast.success('Dados alterados com sucesso!');
							setFormObj({});
							setActiveAction(0);
							setShowForm(false);
							setFilterErrs({});
							setMainFilters({});
						} else {
							toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
						}
					})
					.catch(e => {
						console.error(e);
						toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
					});
			}
		}
	}

	const handleCadastroValidation = () => {
		const { NumeroAnalise, DataEntradaLoja, NFEntrada, SerieNFEntrada, TotalPecas, TipoAnalise, TipoDefeito, NomeCliente, NomeVendedor } = formObj;
		const errs = {}
		if (!NumeroAnalise || NumeroAnalise === '') errs.NumeroAnalise = 'Insira um número de análise válido';
		if (!DataEntradaLoja) errs.DataEntradaLoja = 'Insira uma data de entrada em loja';
		if (!NFEntrada || NFEntrada === '') errs.NFEntrada = 'Insira uma nota fiscal de entrada';
		if (!SerieNFEntrada || SerieNFEntrada === '') errs.SerieNFEntrada = 'Insira uma série para a nota fiscal de entrada';
		if (!TotalPecas || TotalPecas === '') errs.TotalPecas = 'Insira a quantidade de peças';
		if (!TipoAnalise || TipoAnalise === '') errs.TipoAnalise = 'Selecione o tipo de análise';
		if (!TipoDefeito || TipoDefeito === '') errs.TipoDefeito = 'Selecione o tipo de defeito';
		if (!NomeCliente || NomeCliente === '') errs.NomeCliente = 'Insira o nome do cliente';
		if (!NomeVendedor || NomeVendedor === '') errs.NomeVendedor = 'Insira o nome do vendedor';
		return errs;
	}

	const handleAtualizarValidation = () => {
		// --------------------------------------------------------------------------- //
		// --- ObservacaoConserto, Consideracao e NumeroLacre são sempre opcionais --- //
		// --------------------------------------------------------------------------- //

		const { StatusAnalise, ResponsavelConserto, Revisora, MotivoRecusa, DataEntradaAnalise, DataSaidaAnalise, NFSaida, SerieNFSaida } = formObj;
		const errs = {}
		// if (!StatusAnalise || StatusAnalise === '') errs.StatusAnalise = 'Selecione o status da análise';
		// if (StatusAnalise && StatusAnalise.value === 1 && (!DataEnvioConserto || new Date(DataEnvioConserto).getTime() < new Date(toYYYYMMDD(new Date())).getTime())) {
		// 	errs.DataEnvioConserto = 'Insira uma data de envio válida para o conserto';
		// }
		// if (StatusAnalise && StatusAnalise.value === 1 && (DataRetornoConserto && new Date(DataRetornoConserto).getTime() < new Date(toYYYYMMDD(new Date())).getTime())) {
		// 	errs.DataRetornoConserto = 'A data de retono do conserto deve ser maior que hoje';
		// }
		if (StatusAnalise && StatusAnalise.value === 1 && (!ResponsavelConserto || ResponsavelConserto === '')) {
			errs.ResponsavelConserto = 'Insira o responsável pelo conserto';
		}
		// if (StatusAnalise & StatusAnalise.value === 1 && (!ObservacaoConserto || ObservacaoConserto === '')) errs.Consideracao = ''; // OPCIONAL
		if (activeAction === 1 && (!DataEntradaAnalise || new Date(DataEntradaAnalise).getTime() < new Date(toYYYYMMDD(new Date())).getTime())) errs.DataEntradaAnalise = 'A data de entrada da análise deve ser maior que hoje';
		if (StatusAnalise && StatusAnalise.value === 1 && (!Revisora || Revisora === '')) errs.Revisora = 'Para análises de conserto é obrigatório selecionar uma empresa revisora';
		if (StatusAnalise && StatusAnalise.value === 1 && (!MotivoRecusa || MotivoRecusa === '')) errs.MotivoRecusa = 'Para análises de conserto é obrigatório selecionar um motivo de recusa/aceitação';
		// if (!Consideracao || Consideracao === '') errs.Consideracao = ''; // OPCIONAL
		if (validateDataSaida(StatusAnalise, DataSaidaAnalise, DataEntradaAnalise) === false) errs.DataSaidaAnalise = 'Insira uma data de saída maior que hoje e maior que a data de entrada';
		if ((SerieNFSaida && SerieNFSaida !== '') && (!NFSaida || NFSaida === '')) errs.NFSaida = 'A nota fiscal de saída deve acompanhar sua série e vice-versa';
		if ((NFSaida && NFSaida !== '') && (!SerieNFSaida || SerieNFSaida === '')) errs.SerieNFSaida = 'A nota fiscal de saída deve acompanhar sua série e vice-versa';
		// if (!NumeroLacre || NumeroLacre === '') errs.NumeroLacre = ''; // OPCIONAL
		return errs;
	}

	const validateDataSaida = (StatusAnalise, DataSaidaAnalise, DataEntradaAnalise) => {
		if (!StatusAnalise || StatusAnalise.value === 'EM ANALISE') {
			if (DataSaidaAnalise && DataEntradaAnalise && (new Date(DataSaidaAnalise).getTime() < new Date(toYYYYMMDD(new Date())).getTime()
				|| new Date(DataSaidaAnalise).getTime() <= new Date(DataEntradaAnalise).getTime())) {
				return false;
			}
		} else if (StatusAnalise && StatusAnalise.value !== 'EM ANALISE') {
			if (!DataSaidaAnalise || !DataEntradaAnalise || (new Date(DataSaidaAnalise).getTime() < new Date(toYYYYMMDD(new Date())).getTime()
				|| new Date(DataSaidaAnalise).getTime() <= new Date(DataEntradaAnalise).getTime())) {
				return false;
			}
		} else {
			return true;
		}
	}

	const handleStatusChange = (e) => {
		if (e && e.value !== 1) {
			setFormErrs({ ...formErrs, DataEnvioConserto: null, ResponsavelConserto: null });
			setFormObj({ ...formObj, StatusAnalise: e, DataEnvioConserto: null, DataRetornoConserto: null, ResponsavelConserto: null, ObservacaoConserto: null });
		} else {
			setFormObj({ ...formObj, StatusAnalise: e });
		}
	}

	const clearErrs = (field) => {
		setFormErrs({ ...formErrs, [field]: null });
	}

	const handleNumberMask = (field, value, isFilter = false) => {
    if (value && value !== undefined) {
			value = `${value.replace(/[^0-9]*/g, '')}`;
		}
		if (isFilter === false) setFormObj({...formObj, [field]: value});
		else if (isFilter === true) setMainFilters({...mainFilters, [field]: value});
  }

	const handleNumberDotMask = (field, value, isFilter = false) => {
		if (value && value !== undefined) {
			value = `${value.replace(/[^0-9.]*/g, '')}`;
		}
		if (isFilter === false) setFormObj({...formObj, [field]: value});
		else if (isFilter === true) setMainFilters({...mainFilters, [field]: value});
  }

	useEffect(() => {
		fetchOptFields();
	}, []);

	return (
		<div>
			<h1 className="m-3">Formulário Análise PA</h1>
			<article className={styles.headerWrapper}>
				{/* Filtro principal */}
				<div>
					<h5 className={styles.title}>Filtros</h5>
					<Form className={styles.formFilters}>
						<Form.Group className={`${styles.formGroup} ${styles.filterWidth}`} controlId={'MainAction'}>
							<Form.Label>Ação</Form.Label>
							<Select
								options={actionOpt}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'Selecionar'}
								value={mainFilters.MainAction || null}
								onFocus={() => setFilterErrs({ ...filterErrs, MainAction: null })}
								onChange={e => setMainAction(e)}
								isInvalid={filterErrs.MainAction}
								styles={{
									control: (provided) => (
										filterErrs.MainAction ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
							/>
							<div className={filterErrs.MainAction ? styles.showFeedback : styles.hidden}>{filterErrs.MainAction}</div>
						</Form.Group>
						<Form.Group className={
							`${styles.formGroup} ${styles.filterWidth}`}
							controlId={'CodigoLoja'}
						>
							<Form.Label>Código + Loja</Form.Label>
							<Select
								options={mainOpts.listaFiliais}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'Selecionar'}
								value={mainFilters.CodigoLoja || null}
								onFocus={() => setFilterErrs({ ...filterErrs, CodigoLoja: null })}
								onChange={e => setMainFilters({ ...mainFilters, CodigoLoja: e })}
								isInvalid={filterErrs.CodigoLoja}
								styles={{
									control: (provided) => (
										filterErrs.CodigoLoja ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
								isDisabled={!mainFilters.MainAction || mainFilters.MainAction.value !== 1}
							/>
							<div className={filterErrs.CodigoLoja ? styles.showFeedback : styles.hidden}>{filterErrs.CodigoLoja}</div>
						</Form.Group>
						<Form.Group
							className={
								`${styles.formGroup} ${styles.filterWidth}`}
							controlId={'NumeroAnalise'}
						>
							<Form.Label>Número Análise</Form.Label>
							<Form.Control
								type="text"
								placeholder="Número Análise"
								value={mainFilters.NumeroAnalise || ''}
								onFocus={() => setFilterErrs({ ...filterErrs, NumeroAnalise: null })}
								onChange={e => handleNumberMask('NumeroAnalise', e.target.value, true)}
								disabled={!mainFilters.MainAction || mainFilters.MainAction.value !== 2}
								isInvalid={filterErrs.NumeroAnalise}
							/>
							<Form.Control.Feedback type='invalid'>{filterErrs.NumeroAnalise}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={`${styles.formGroup} ${styles.filterWidth}`} controlId={'Produto'}>
							<Form.Label>Referência</Form.Label>
							<Form.Control
								type="text"
								placeholder="Referência/Produto"
								value={mainFilters.Produto || ''}
								onFocus={() => setFilterErrs({ ...filterErrs, Produto: null })}
								onChange={e => handleNumberDotMask('Produto', e.target.value, true)}
								isInvalid={filterErrs.Produto}
							/>
							<Form.Control.Feedback type='invalid'>{filterErrs.Produto}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={`${styles.formGroup} ${styles.filterWidth}`} controlId={'CorProduto'}>
							<Form.Label>Cor Produto</Form.Label>
							<Form.Control
								type="text"
								placeholder="Cor Produto"
								value={mainFilters.CorProduto || ''}
								onFocus={() => setFilterErrs({ ...filterErrs, CorProduto: null })}
								onChange={e => handleNumberMask('CorProduto', e.target.value, true)}
								isInvalid={filterErrs.CorProduto}
							/>
							<Form.Control.Feedback type='invalid'>{filterErrs.CorProduto}</Form.Control.Feedback>
						</Form.Group>
						<Button
							className={`${styles.formBtn} ${styles.filterWidth}`}
							variant="primary"
							type="button"
							onClick={handleMainSearch}
						>
							Filtrar
						</Button>
					</Form>
				</div>

				{/* Dados do produto */}
				<div>
					<h5 className={styles.title}>Dados do produto</h5>
					<div className={styles.productInfo}>
						<div>
							<div className={styles.formLabel}>Descrição Referência:</div>
							<div className={styles.fontBlue}>{formObj.DescProduto ? formObj.DescProduto : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Linha:</div>
							<div className={styles.fontBlue}>{formObj.Linha ? formObj.Linha : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Descrição Cor:</div>
							<div className={styles.fontBlue}>{formObj.DescCorProduto ? formObj.DescCorProduto : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Tipo Produto:</div>
							<div className={styles.fontBlue}>{formObj.TipoProduto ? formObj.TipoProduto : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Coleção:</div>
							<div className={styles.fontBlue}>{formObj.Colecao ? formObj.Colecao : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Grupo Produto:</div>
							<div className={styles.fontBlue}>{formObj.GrupoProduto ? formObj.GrupoProduto : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Griffe:</div>
							<div className={styles.fontBlue}>{formObj.Griffe ? formObj.Griffe : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Fabricante:</div>
							<div className={styles.fontBlue}>{formObj.Fabricante ? formObj.Fabricante : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}>Marca:</div>
							<div className={styles.fontBlue}>{formObj.Marca ? formObj.Marca : '-'}</div>
						</div>
						<div>
							<div className={styles.formLabel}></div>
							<div className={styles.fontBlue}></div>
						</div>
					</div>
				</div>
			</article>

			{/* Dados do cliente */}
			<article className={showForm ? null : styles.hidden}>
				<h5 className={styles.title}>Dados do cliente</h5>
				<div className={styles.formWrapper}>

					<Form className={styles.formRow}>
						<Form.Group className={styles.formCell} controlId={'NumeroAnalise'}>
							<Form.Label>1. Número análise</Form.Label>
							<Form.Control
								type="text"
								value={formObj.NumeroAnalise || ''}
								onFocus={() => clearErrs('NumeroAnalise')}
								onChange={e => handleNumberMask('NumeroAnalise', e.target.value)}
								placeholder="Número análise"
								isInvalid={formErrs.NumeroAnalise}
								required
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.NumeroAnalise}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'DataEntradaLoja'}>
							<Form.Label>2. Data entrada loja:</Form.Label>
							<Form.Control
								type="date"
								value={formObj.DataEntradaLoja || ''}
								onFocus={() => clearErrs('DataEntradaLoja')}
								onChange={e => setFormObj({ ...formObj, DataEntradaLoja: e.target.value })}
								placeholder={'Data de entrada em loja'}
								min={toYYYYMMDD(new Date())}
								max={'9999-01-01'}
								isInvalid={formErrs.DataEntradaLoja}
								required
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.DataEntradaLoja}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'NFEntrada'}>
							<Form.Label>3. NF entrada:</Form.Label>
							<Form.Control
								type="text"
								value={formObj.NFEntrada || ''}
								onFocus={() => clearErrs('NFEntrada')}
								onChange={e => handleNumberMask('NFEntrada', e.target.value)}
								placeholder={'Nota fiscal de entrada'}
								isInvalid={formErrs.NFEntrada}
								required
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.NFEntrada}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'SerieNFEntrada'}>
							<Form.Label>4. Série NF Entrada:</Form.Label>
							<Form.Control
								type="text"
								value={formObj.SerieNFEntrada || ''}
								onFocus={() => clearErrs('SerieNFEntrada')}
								onChange={e => handleNumberMask('SerieNFEntrada', e.target.value)}
								placeholder={'Série da NF'}
								isInvalid={formErrs.SerieNFEntrada}
								required
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.SerieNFEntrada}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'TotalPecas'}>
							<Form.Label>5. Total de peças:</Form.Label>
							<Form.Control
								type="text"
								value={formObj.TotalPecas || ''}
								onFocus={() => clearErrs('TotalPecas')}
								onChange={e => setFormObj({ ...formObj, TotalPecas: e.target.value })}
								placeholder={'Total de peças'}
								isInvalid={formErrs.TotalPecas}
								required
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.TotalPecas}</Form.Control.Feedback>
						</Form.Group>
					</Form>

					<Form className={styles.formRow}>
						<Form.Group className={styles.formCell} controlId={'TipoAnalise'}>
							<Form.Label>6. Tipo análise:</Form.Label>
							<Select
								options={mainOpts.listaTipoAnalise}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'Selecionar'}
								value={formObj.TipoAnalise || null}
								onFocus={() => clearErrs('TipoAnalise')}
								onChange={e => setFormObj({ ...formObj, TipoAnalise: e })}
								isInvalid={formErrs.TipoAnalise}
								styles={{
									control: (provided) => (
										formErrs.TipoAnalise ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
								required
								isDisabled={activeAction === 2}
							/>
							<div className={formErrs.TipoAnalise ? styles.showFeedback : styles.hidden}>{formErrs.TipoAnalise}</div>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'TipoDefeito'}>
							<Form.Label>7. Tipo de defeito:</Form.Label>
							<Select
								options={mainOpts.listaTipoDefeito}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'Selecionar'}
								value={formObj.TipoDefeito || null}
								onFocus={() => clearErrs('TipoDefeito')}
								onChange={e => setFormObj({ ...formObj, TipoDefeito: e })}
								isInvalid={formErrs.TipoDefeito}
								styles={{
									control: (provided) => (
										formErrs.TipoDefeito ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
								required
								isDisabled={activeAction === 2}
							/>
							<div className={formErrs.TipoDefeito ? styles.showFeedback : styles.hidden}>{formErrs.TipoDefeito}</div>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'TipoDefeito2'}>
							<Form.Label>7.1. Tipo de defeito (2)<span className={styles.spanOpcional}>(opcional)</span>:</Form.Label>
							<Select
								options={mainOpts.listaTipoDefeito}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'Selecionar'}
								value={formObj.TipoDefeito2 || null}
								onFocus={() => clearErrs('TipoDefeito2')}
								onChange={e => setFormObj({ ...formObj, TipoDefeito2: e })}
								isInvalid={formErrs.TipoDefeito2}
								styles={{
									control: (provided) => (
										formErrs.TipoDefeito2 ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
								isDisabled={activeAction === 2}
							/>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'NomeCliente'}>
							<Form.Label>8. Nome do cliente:</Form.Label>
							<Form.Control
								type="text"
								value={formObj.NomeCliente || ''}
								onFocus={() => clearErrs('NomeCliente')}
								onChange={e => setFormObj({ ...formObj, NomeCliente: e.target.value })}
								placeholder={'Nome do cliente'}
								isInvalid={formErrs.NomeCliente}
								required
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.NomeCliente}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'NomeVendedor'}>
							<Form.Label>9. Nome do vendedor:</Form.Label>
							<Form.Control
								type="text"
								value={formObj.NomeVendedor || ''}
								onFocus={() => clearErrs('NomeVendedor')}
								onChange={e => setFormObj({ ...formObj, NomeVendedor: e.target.value })}
								placeholder={'Nome do vendedor'}
								isInvalid={formErrs.NomeVendedor}
								required
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.NomeVendedor}</Form.Control.Feedback>
						</Form.Group>
					</Form>
				</div>
			</article>

			{/* Dados da análise */}
			<article className={`${showForm ? null : styles.hidden}`}>
				<h5 className={styles.title}>Dados da análise</h5>
				<div className={styles.formWrapper}>
					<Form className={styles.formRow}>
						<Form.Group className={styles.formCell}>
							<Form.Label>1. Status análise</Form.Label>
							<Select
								options={mainOpts.listaStatusAnalise}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'EM ANALISE'}
								value={formObj.StatusAnalise || null}
								onFocus={() => clearErrs('StatusAnalise')}
								onChange={e => handleStatusChange(e)}
								styles={{
									control: (provided) => (
										formErrs.StatusAnalise ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
								required
							/>
							<div className={formErrs.StatusAnalise ? styles.showFeedback : styles.hidden}>{formErrs.StatusAnalise}</div>
						</Form.Group>
						{/* JEAN RUFINO - 16/12/2022 - CAMPOS DE DATA REMOVIDOS A PEDIDO DOS USUÁRIOS */}
						{/* MAIS DETALHES EM: https://gruposoma.atlassian.net/jira/software/c/projects/MDFDQ/boards/229?modal=detail&selectedIssue=MDFDQ-284&assignee=615c9be778e5e400706cc989 */}
						{/* <Form.Group className={styles.formCell} controlId={'DataEnvioConserto'}>
							<Form.Label>1.1. Data envio conserto:</Form.Label>
							<Form.Control
								type="date"
								value={formObj.DataEnvioConserto || ''}
								onFocus={() => clearErrs('DataEnvioConserto')}
								onChange={e => setFormObj({...formObj, DataEnvioConserto: e.target.value})}
								placeholder={'Data de envio para conserto'}
								min={toYYYYMMDD(new Date())}
								max={'9999-01-01'}
								isInvalid={formErrs.DataEnvioConserto}
								disabled={!formObj.StatusAnalise || formObj.StatusAnalise.value !== 1}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.DataEnvioConserto}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'DataRetornoConserto'}>
							<Form.Label>1.2. Data retorno conserto:</Form.Label>
							<Form.Control
								type="date"
								value={formObj.DataRetornoConserto || ''}
								onFocus={() => clearErrs('DataRetornoConserto')}
								onChange={e => setFormObj({...formObj, DataRetornoConserto: e.target.value})}
								placeholder={'Data de retorno do conserto'}
								min={toYYYYMMDD(new Date())}
								max={'9999-01-01'}
								isInvalid={formErrs.DataRetornoConserto}
								disabled={!formObj.StatusAnalise || formObj.StatusAnalise.value !== 1}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.DataRetornoConserto}</Form.Control.Feedback>
						</Form.Group> */}

						<Form.Group className={styles.formCell} controlId={'ResponsavelConsertos'}>
							<Form.Label>1.1. Responsável conserto:</Form.Label>
							<Form.Control
								type="text"
								value={formObj.ResponsavelConserto || ''}
								onFocus={() => clearErrs('ResponsavelConserto')}
								onChange={e => setFormObj({ ...formObj, ResponsavelConserto: e.target.value })}
								placeholder={'Responsável pelo conserto'}
								isInvalid={formErrs.ResponsavelConserto}
								disabled={!formObj.StatusAnalise || formObj.StatusAnalise.value !== 1}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.ResponsavelConserto}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'ObservacaoConserto'}>
							<Form.Label>1.2. Observação conserto<span className={styles.spanOpcional}>(opcional)</span>:</Form.Label>
							<Form.Control
								as="textarea"
								rows={1}
								value={formObj.ObservacaoConserto || ''}
								onFocus={() => clearErrs('ObservacaoConserto')}
								onChange={e => setFormObj({ ...formObj, ObservacaoConserto: e.target.value })}
								placeholder={'Observações sobre o conserto'}
								isInvalid={formErrs.ObservacaoConserto}
								disabled={!formObj.StatusAnalise || formObj.StatusAnalise.value !== 1}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.ObservacaoConserto}</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className={styles.formCell} controlId={'Placeholder'}></Form.Group>
						<Form.Group className={styles.formCell} controlId={'Placeholder'}></Form.Group>
					</Form>

					<Form className={styles.formRow}>
						<Form.Group className={styles.formCell} controlId={'DataEntradaAnalise'}>
							<Form.Label>2. Data entrada análise:</Form.Label>
							<Form.Control
								type="date"
								value={formObj.DataEntradaAnalise || ''}
								onFocus={() => clearErrs('DataEntradaAnalise')}
								onChange={e => setFormObj({ ...formObj, DataEntradaAnalise: e.target.value })}
								placeholder={'Data de entrada da análise'}
								min={toYYYYMMDD(new Date())}
								max={'9999-01-01'}
								isInvalid={formErrs.DataEntradaAnalise}
								disabled={activeAction === 2}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.DataEntradaAnalise}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'Revisora'}>
							<Form.Label>3. Revisora
								<span className={`
									${styles.spanOpcional}
									${formObj.StatusAnalise && formObj.StatusAnalise.value === 1 ? styles.hidden : null}`}>(opcional)
								</span>:
							</Form.Label>
							<Select
								options={mainOpts.listaRevisoras}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'Selecionar'}
								onFocus={() => clearErrs('Revisora')}
								value={formObj.Revisora || null}
								onChange={e => setFormObj({ ...formObj, Revisora: e })}
								styles={{
									control: (provided) => (
										formErrs.Revisora ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
							/>
							<div className={formErrs.Revisora ? styles.showFeedback : styles.hidden}>{formErrs.Revisora}</div>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'MotivoRecusa'}>
							<Form.Label>4. Motivo da recusa/aceitação
								<span className={`
									${styles.spanOpcional}
									${formObj.StatusAnalise && formObj.StatusAnalise.value === 1 ? styles.hidden : null}`}>(opcional)
								</span>:
							</Form.Label>
							<Select
								options={mainOpts.listaMotivoRecusa}
								isClearable={true}
								escapeClearsValue={true}
								placeholder={'Selecionar'}
								onFocus={() => clearErrs('MotivoRecusa')}
								value={formObj.MotivoRecusa || null}
								onChange={e => setFormObj({ ...formObj, MotivoRecusa: e })}
								styles={{
									control: (provided) => (
										formErrs.MotivoRecusa ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
									)
								}}
							/>
							<div className={formErrs.MotivoRecusa ? styles.showFeedback : styles.hidden}>{formErrs.MotivoRecusa}</div>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'Consideracao'}>
							<Form.Label>5. Considerações<span className={styles.spanOpcional}>(opcional)</span>:</Form.Label>
							<Form.Control
								as="textarea"
								rows={1}
								value={formObj.Consideracao || ''}
								onFocus={() => clearErrs('Consideracao')}
								onChange={e => setFormObj({ ...formObj, Consideracao: e.target.value })}
								placeholder={'Considerações'}
								isInvalid={formErrs.Consideracao}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.Consideracao}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'Placeholder'}>
						</Form.Group>
					</Form>

					<Form className={styles.formRow}>
						<Form.Group className={styles.formCell} controlId={'DataSaidaAnalise'}>
							<Form.Label>6. Data saída análise:</Form.Label>
							<Form.Control
								type="date"
								value={formObj.DataSaidaAnalise || ''}
								onFocus={() => clearErrs('DataSaidaAnalise')}
								onChange={e => setFormObj({ ...formObj, DataSaidaAnalise: e.target.value })}
								placeholder={'Data de saída em loja'}
								min={toYYYYMMDD(new Date())}
								max={'9999-01-01'}
								isInvalid={formErrs.DataSaidaAnalise}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.DataSaidaAnalise}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'NFSaida'}>
							<Form.Label>7. NF saída
								<span className={`${styles.spanOpcional} ${formObj.SerieNFSaida ? styles.hidden : null}`}>(opcional)</span>:
							</Form.Label>
							<Form.Control
								type="text"
								value={formObj.NFSaida || ''}
								onFocus={() => clearErrs('NFSaida')}
								onChange={e => handleNumberMask('NFSaida', e.target.value)}
								placeholder={'Nota fiscal de saída'}
								isInvalid={formErrs.NFSaida}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.NFSaida}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'SerieNFSaida'}>
							<Form.Label>8. Série NF saída
								<span className={`${styles.spanOpcional} ${formObj.NFSaida ? styles.hidden : null}`}>(opcional)</span>:
							</Form.Label>
							<Form.Control
								type="text"
								value={formObj.SerieNFSaida || ''}
								onFocus={() => clearErrs('SerieNFSaida')}
								// onChange={e => handleNumberMask('SerieNFSaida', e.target.value)}
								onChange={e => setFormObj({ ...formObj, SerieNFSaida: e.target.value })}
								placeholder={'Série da nota fiscal de saída'}
								isInvalid={formErrs.SerieNFSaida}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.SerieNFSaida}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'NumeroLacre'}>
							<Form.Label>9. Número lacre<span className={styles.spanOpcional}>(opcional)</span>:</Form.Label>
							<Form.Control
								type="text"
								value={formObj.NumeroLacre || ''}
								onFocus={() => clearErrs('NumeroLacre')}
								onChange={e => handleNumberMask('NumeroLacre', e.target.value)}
								placeholder={'Número do lacre'}
								isInvalid={formErrs.NumeroLacre}
							/>
							<Form.Control.Feedback type='invalid'>{formErrs.NumeroLacre}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className={styles.formCell} controlId={'Placeholder'}>
						</Form.Group>
					</Form>
				</div>
			</article>

			{/* Btn */}
			<article className={`${showForm ? null : styles.hidden} ${styles.articleBtn}`}>
				<Button
					className={styles.btn}
					onClick={e => handleProcess(e)}
				>
					{activeAction === 1 ? 'Cadastrar Análise' : 'Atualizar Análise'}
				</Button>
			</article>

			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default FormularioAnalise;
