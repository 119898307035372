import { FGMateriaisComposicaoProvider } from "../../contexts/FarmGlobal/MateriaisComposicaoContext";
import MateriaisComposicaoMain from "../../components/FarmGlobal/MateriaisComposicao/MateriaisComposicaoMain";

const MateriaisComposicao = () => {
    

    return (
        <FGMateriaisComposicaoProvider>
            <MateriaisComposicaoMain />
        </FGMateriaisComposicaoProvider>
    );

}
export default MateriaisComposicao;