import React, { useState, useEffect } from "react";
import { Button, Table, Form } from 'react-bootstrap';
import Select from 'react-select';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import styles from "../../../styles/AdmConsultarCatalogo.module.scss";

import { api }  from "../../../services/api";
import Carregando from "../../../components/Carregando"

function ConsultarCatalogo() {

  const [itens, setItens] = useState([]);
  const [araras, setAraras] = useState([]);
  const [andares, setAndares] = useState([]);
  const [lados, setLados] = useState([]);
  const [tipoMateriais, setTipoMateriais] = useState([]);
  const [colecoes, setColecoes] = useState([]);

  const [araraSelecionada, setAraraSelecionada] = useState("");
  const [andarSelecionado, setAndarSelecionado] = useState("");
  const [materialSelecionado, setMaterialSelecionado] = useState("");
  const [colecaoSelecionada, setColecaoSelecionada] = useState("");
  const [ladoSelecionado, setLadoSelecionado] = useState("");  
  const [corSelecionada, setCorSelecionada] = useState("");
  const [codigoSelecionado, setCodigoSelecionado] = useState("");
  const [tipoMaterialSelecionado, setTipoMaterialSelecionado] = useState([]);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    getFiltros();
}, []);

  async function getFiltros(){

    const resultArara = await api.get("Catalogo/CatalogoAraras");
    const listAraras = resultArara.data.map((item,index)=>{
        return {value: item.idArara, label: item.idArara};
    });   
    setAraras([{value :'', label: 'Selecione'}].concat(listAraras));  
    
    const resultLados = await api.get("Catalogo/SalaCatalogoLados");
    const listLados = resultLados.data.map((item,index)=>{
        return {value: item.Lado, label: item.Lado};
    });   
    setLados([{value :'', label: 'Selecione'}].concat(listLados)); 

    const resultTiposMateriais = await api.get("Catalogo/Catalogotipomaterial");
    const listTiposMateriais = resultTiposMateriais.data.map((item,index)=>{
        return {value: item.idTipo, label: item.local};
    });   
    setTipoMateriais([{value :'', label: 'Selecione'}].concat(listTiposMateriais)); 

    const resultColecoes = await api.get("catalogoADM/ADMConsultaColecao");
    const listColecoes = resultColecoes.data.map((item,index)=>{
        return {value: item.COLECAO, label: item.COLECAO};
    });   
    setColecoes([{value :'', label: 'Selecione'}].concat(listColecoes)); 
  }

  const handleChangeArara = async (selectedOptions) => {    
    setAraraSelecionada(selectedOptions.value);
    const params = { idarara: selectedOptions.value };
    const dadosAndar = await api.get("Catalogo/CatalogoListarAndar", {
      params,
    });    
    setAndares([{value :'', label: 'Selecione'}].concat(dadosAndar.data.map((item,index)=>{
        return {value: item.andar, label: item.andar};
    })));
  }

  const handleChangeAndar = async (selectedOptions) => {   
    setAndarSelecionado(selectedOptions.value);
  }

  const handleBusca= async () => {    
    const params = {
        Arara: araraSelecionada
        , Andar: andarSelecionado
        , Lado: ladoSelecionado
        , Colecao: colecaoSelecionada
        , TipoMaterial: tipoMaterialSelecionado
        , Material: materialSelecionado
        , CorMaterial: corSelecionada
        , Codigo: codigoSelecionado
    };
    setCarregando(true);
    const result = await api.get("catalogoADM/ADMConsulta", {
      params,
    });  
    if (result.data.length<1){
        toast.warning('Nenhum registro encontrato'); 
    }
    setItens(result.data);    
    setCarregando(false);
  }

    return (
        <body>           
            <article className={styles.articleForm}>
                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Código</Form.Label>
                        <Form.Control 
                            type="text" 
                            onChange = {e=>(setLadoSelecionado(e.target.value))} 
                        />
                    </Form.Group>

                    <Form.Group className={styles.formGroupDown}>
                        <Form.Label>Andar</Form.Label>
                        <Select 
                            closeMenuOnSelect={true} 
                            onChange={handleChangeAndar}
                            options={andares} 
                        />
                    </Form.Group>
                </Form>

                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Material</Form.Label>
                        <Form.Control type="text" onChange = {e=>(setMaterialSelecionado(e.target.value))} />
                    </Form.Group>

                    <Form.Group className={styles.formGroupDown}>
                        <Form.Label>Lado</Form.Label>
                        <Select 
                            closeMenuOnSelect={true}                                  
                            options={lados} 
                            onChange = {e=>(setLadoSelecionado(e.value))}
                        />
                    </Form.Group>
                </Form>

                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Cor Material</Form.Label>
                        <Form.Control type="text" onChange = {e=>(setCorSelecionada(e.target.value))}/>
                    </Form.Group>

                    <Form.Group className={styles.formGroupDown}>
                        <Form.Label>Coleção</Form.Label>
                        <Select 
                            closeMenuOnSelect={true}                                 
                            options={colecoes}
                            onChange = {e=>(setColecaoSelecionada(e.value))}
                        />
                    </Form.Group >
                </Form>

                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Arara</Form.Label>
                        <Select 
                            closeMenuOnSelect={true}                                  
                            options={araras} 
                            onChange={handleChangeArara}
                        />
                    </Form.Group>

                    <Form.Group className={styles.formGroupDown}>
                        <Form.Label>Tipo Material</Form.Label>
                        <Select 
                            closeMenuOnSelect={true}                                  
                            options={tipoMateriais} 
                            onChange = {e=>(setTipoMaterialSelecionado(e.value))}
                        />
                    </Form.Group>
                </Form>                    
                <Form className={styles.formBtn}>
                    <Button 
                        className={styles.btn}
                        variant="primary"
                        type="button" 
                        onClick={handleBusca}
                    >
                        Buscar
                    </Button>

                </Form>
               
            </article>

            <article className={styles.articleGray}>
                {carregando ?
                    <Carregando></Carregando>
                : 
                    <Table className="m-2" responsive>
                        <thead>
                            <tr>
                            <th className={styles.blockTitle}>ID</th>
                            <th className={styles.blockTitle}>LOCALIZAÇÃO</th>
                            <th className={styles.blockTitle}>ARARA</th>                            
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>COR</th>
                            <th className={styles.blockTitle}>COD FORN.</th>
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>COLECAO</th>
                            <th className={styles.blockTitle}>TIPO MATERIAL</th>
                            <th className={styles.blockTitle}>ANDAR</th>  
                            <th className={styles.blockTitle}>LADO</th>
                            <th className={styles.blockTitle}>POSIÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                        {itens.length>0 && itens.map((item,index)=>{
                            return (
                                <tr key={index}>
                                <td className={styles.tableLines}>{item.IDCADASTRO}</td>
                                <td className={styles.tableLines}>{item.IDLOCALIZACAO}</td>
                                <td className={styles.tableLines}>{item.idArara}</td>
                                <td className={styles.tableLines}>{item.Material}</td>
                                <td className={styles.tableLines}>{item.CorMaterial}</td>
                                <td className={styles.tableLines}>{item.CodClifor}</td>
                                <td className={styles.tableLines}>{item.NOME_CLIFOR}</td>
                                <td className={styles.tableLines}>{item.COLECAO}</td>
                                <td className={styles.tableLines}>{item.tipoMaterial}</td>
                                <td className={styles.tableLines}>{item.andar}</td>
                                <td className={styles.tableLines}>{item.lado}</td>                                
                                <td className={styles.tableLines}>{item.posicao}</td>                                
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                }
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                />
            </article>
        </body>
    );
}

export default ConsultarCatalogo;
 