import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from '../pa_revisao.module.scss'
import { usePARevisao } from '../pa_revisao_context';
import { isNullish } from '../../../../utils/misc_utils';

const FiltroRevisao = () => {
  const { listaRevisao, listaRevisaoPrio, setListaRevisaoFiltrada, setListaRevisaoPrioFiltrada, filterObj, setFilterObj } = usePARevisao();

  useEffect(() => {
    const listaFiltrada = listaRevisao.filter(item => {
      return (
        (isNullish(filterObj.nf_entrada) || item.nf_entrada.includes(filterObj.nf_entrada)) &&
        (isNullish(filterObj.produto) || item.produto.includes(filterObj.produto)) &&
        (isNullish(filterObj.cor_produto) || item.cor_produto.includes(filterObj.cor_produto))
      );
    });
    setListaRevisaoFiltrada([...listaFiltrada]);
  }, [filterObj, listaRevisao]);

  useEffect(() => {
    const listaFiltrada = listaRevisaoPrio.filter(item => {
      return (
        (isNullish(filterObj.nf_entrada) || item.nf_entrada.includes(filterObj.nf_entrada)) &&
        (isNullish(filterObj.produto) || item.produto.includes(filterObj.produto)) &&
        (isNullish(filterObj.cor_produto) || item.cor_produto.includes(filterObj.cor_produto))
      );
    });
    setListaRevisaoPrioFiltrada([...listaFiltrada]);
  }, [filterObj, listaRevisaoPrio]);

  const rgx = value => { return value.replace(/[^.0-9]*/g, '') }

  const FilterList = [
    { key: 'nf_entrada', label: 'NF Entrada', type: 'text' },
    { key: 'produto', label: 'Produto', type: 'text' },
    { key: 'cor_produto', label: 'Cor Produto', type: 'text' }
  ];

  return (
    <Form className={styles.filter_wrapper}>
      {FilterList.map((filter, index) => {
        return (
          <Form.Group key={index} className={styles.filter_group}>
            <Form.Label className={styles.filter_label}>{filter.label}</Form.Label>
            <Form.Control
              className={styles.filter_control}
              type={filter.type}
              placeholder={filter.label}
              value={filterObj[filter.key] || ''}
              onChange={e => setFilterObj({ ...filterObj, [filter.key]: rgx(e.target.value) })}
              required={filter.required}
            />
          </Form.Group>
        )
      })}
    </Form>
  )
}

export default FiltroRevisao;