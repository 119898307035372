import React, { useState, useEffect, useContext, useRef } from "react";

import { Button, Table, Form, FormGroup, Modal, Col, Container, Row, Accordion } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


import "bootstrap/dist/css/bootstrap.min.css";

import "react-toastify/dist/ReactToastify.min.css";

import MaterialIcon from 'material-icons-react';

import styles from "../../../../styles/MonitorRevisaoAnaliseGrade.module.scss";

import { api, retryApi } from "../../../../services/api";
import ProdutoInfo from "../componentes/ProdutoInfo";
import { wait } from "@testing-library/user-event/dist/utils";
import RadioButtonList from "../../../Utils/RadioButtonList"
import CookiesService from "../../../../services/cookies";

function MonitorRevisaoAnaliseGrade(props) {
    const [openIdentificacaoProduto, setOpenIdentificacaoProduto] = useState(false);
    const [show, setShow] = useState(false);
    const [qtdPecasDefeito, setQtdPecasDefeito] = useState(0);

    const [grupoAvaliacao, setGrupoAvaliacao] = useState([]);
    const [statusAvaliacao, setStatusAvaliacao] = useState([]);
    const [defeitosEncontrados, setDefeitosEncontrados] = useState([]);
    const [itemGradeDefeitoSelecionado, setItemGradeDefeitoSelecionado] = useState('');
    const [qdtPecasComDefeito, setQdtPecasComDefeito] = useState(0);
    const [percentPecasComDefeito, setPercentPecasComDefeito] = useState(0);
    const [carregandoDefeitos, setCarregandoDefeitos] = useState(false);
    const [areaEspecifica, setAreaEspecifica] = useState([]);
    const [localizacaoDefeito, setLocalizacaoDefeito] = useState([]);

    const [grupoAvaliacaoSelecionado, setGrupoAvaliacaoSelecionado] = useState({});
    const [statusAvaliacaoSelecionado, setStatusAvaliacaoSelecionado] = useState({});
    const [areaEspecificaSelecionada, setAreaEspecificaSelecionada] = useState({});
    const [localizacaoDefeitoSelecionada, setLocalizacaoDefeitoSelecionada] = useState({});
    const [defeitosEncontradosSelecionado, setDefeitosEncontradosSelecionado] = useState({});
    const [consideracoesSelecionada, setConsideracoesSelecionada] = useState('');
    const [gradeSemDefeito, setGradeSemDefeito] = useState([]);
    const [gradeAnaliseQualidadeTotal, setGradeAnaliseQualidadeTotal] = useState([]);
    const [gradeAnaliseQualidadeTotalAprovada, setGradeAnaliseQualidadeTotalAprovada] = useState([]);
    const [showModalDefeito115, setShowModalDefeito115] = useState(false);

    const [gradeDefeitos, setGradeDefeitos] = useState([]);
    const [gradeDefeitosInseridos, setGradeDefeitosInseridos] = useState([]);
    const [desabilitaProcessar, setDesabilitaProcessar] = useState(true);
    const [showModalConfirmaProcessar, setShowModalConfirmaProcessar] = useState(false)

    /* let selectListGrupo = useRef();
    let selectListStatus = useRef();
    let selectListDefeito= useRef();
    let selectListLocalizacao = useRef();
    let selectListArea = useRef(); */

    const listRef = useRef();

    const { gradeSegundaQualidadeDefeitosFinalizados, gradeSegundaQualidadeSelecionada, gradeSegundaQualidade, qtdPecasAmostra, precoIncorreto, setPrecoIncorreto } = props;

    const handleClose = () => setShow(false);
    const handleShow = () => {

        let grade = gradeDefeitosInseridos.filter(defeito => defeito.tamanho === itemGradeDefeitoSelecionado.Tamanho);
        if (grade?.length > 0) {
            setGradeDefeitos(grade[0].grade);
        }
        setShow(true);
    }

    useEffect(() => {
        carregaFiltros();
        if (props.recebido === 'nao' || props.gradeSegundaQualidade.length < 1) {
            getGradeNaoRecebido();
        } else {
            unificaTodasAsGrades();
        }

    }, [gradeSemDefeito, gradeAnaliseQualidadeTotalAprovada]);

    async function unificaTodasAsGrades() {
        let gradeSegundaQualidadeAlterada = props.gradeSegundaQualidade;
        gradeSegundaQualidadeAlterada.map((item, index) => {
            let grade = [];
            const itemComDefeito = props.gradeSegundaQualidadeDefeitosFinalizados.filter(defeito => {
                return defeito.tamanho == item.Tamanho;
            });
            item.grade = itemComDefeito.length > 0 ? itemComDefeito[0].grade : [];
            return item;
        });
        setGradeAnaliseQualidadeTotal(gradeSegundaQualidadeAlterada);
    }


    async function carregaFiltros() {
        listaGrupoAvaliacao();
        listaStatusAvaliacao();
        listaAreaEspecifica();
        listaLocalizacaoDefeito();
    }

    async function listaGrupoAvaliacao() {
        try {
            const lista = await retryApi('get', "PArevisao/ListaGrupoAvaliacao");
            if (lista.data.length > 0) {
                const listaGrupo = lista.data.map((item, index) => {
                    return { value: item.IDGrupoAnalise, label: item.GrupoAnalise };
                });
                setGrupoAvaliacao(listaGrupo);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de status!');
            return false;
        }
    }

    async function listaStatusAvaliacao() {
        try {
            const lista = await retryApi('get', "PArevisao/ListaStatusAvaliacao");
            if (lista.data.length > 0) {
                const listaStatus = lista.data.map((item, index) => {
                    return { value: item.IDStatus, label: item.LISTA_STATUS };
                });
                setStatusAvaliacao(listaStatus);

            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de status!');
            return false;
        }
    }

    async function listaDefeitosEncontrados(itemValue) {
        setCarregandoDefeitos(true);
        try {
            const params = {
                idGrupoAnalise: itemValue
            }
            const lista = await retryApi('get', "PArevisao/ListaDefeitosEncontrados", { params });
            if (lista.data.length > 0) {
                const listaDefeitos = lista.data.map((item, index) => {
                    return { value: item.IDDefeito, label: item.GrupoDefeito };
                });
                setDefeitosEncontrados(listaDefeitos);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de defeitos!');
            return false;
        }
        setCarregandoDefeitos(false);
    }

    async function listaAreaEspecifica() {
        try {
            const lista = await retryApi('get', "PArevisao/ListaAreaEspecifica");
            if (lista.data.length > 0) {
                const listaArea = lista.data.map((item, index) => {
                    return { value: item.IDLOCDEFPA, label: item.DetalhamentoLocalizacaoDefeito };
                });
                setAreaEspecifica(listaArea);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de áreas!');
            return false;
        }
    }

    async function listaLocalizacaoDefeito(itemValue) {

        try {
            const params = {
                grupoProduto: props.produto.GrupoProduto
            }
            const lista = await retryApi('get', "PArevisao/ListaLocalizacaoDefeitos", { params });
            if (lista.data.length > 0) {
                const listaLocalizacao = lista.data.map((item, index) => {
                    return { value: item.IDLocalizacao, label: item.DescricaoLocalizacao };
                });
                setLocalizacaoDefeito(listaLocalizacao);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de defeitos!');
            return false;
        }

    }

    const handleItemGradeDefeitoQualidade = async (opcaoSelecionada) => {
        setItemGradeDefeitoSelecionado(opcaoSelecionada);
        setQdtPecasComDefeito(opcaoSelecionada.QtdePecas);
        setPercentPecasComDefeito((opcaoSelecionada.QtdePecas / opcaoSelecionada.Qtde) * 100)
    }

    const handleIncluiAvaliacao = async () => {

        const itemParaInserir = {
            tamanho: itemGradeDefeitoSelecionado.Tamanho,
            grupo: grupoAvaliacaoSelecionado,
            status: statusAvaliacaoSelecionado,
            defeito: defeitosEncontradosSelecionado,
            localizacao: localizacaoDefeitoSelecionada,
            areaEspecifica: areaEspecificaSelecionada,
            consideracoes: consideracoesSelecionada
        }

        if (grupoAvaliacaoSelecionado.value == null || statusAvaliacaoSelecionado.value == null ||
            defeitosEncontradosSelecionado.value == null || localizacaoDefeitoSelecionada.value == null ||
            areaEspecificaSelecionada.value == null
        ) {
            toast.error('Você deve informar Grupo, Status, Defeito, Localização e Área para incluir.');
            return false;
        }

        let grade = [];
        grade = grade.concat(gradeDefeitos?.filter(item => { return item.tamanho === itemGradeDefeitoSelecionado.Tamanho }));
        grade.push(itemParaInserir);
        setGradeDefeitos(grade);
        setGrupoAvaliacaoSelecionado({});
        setStatusAvaliacaoSelecionado({});
        setDefeitosEncontradosSelecionado({});
        setLocalizacaoDefeitoSelecionada({});
        setAreaEspecificaSelecionada({});
        setConsideracoesSelecionada('');
        limpaFiltros();
    }

    async function limpaFiltros() {
        listRef.current.clearChecks();
    }

    const handleExcluiItemDefeito = async (itemParaExcluir) => {
        setGradeDefeitos(gradeDefeitos.filter(el => el !== itemParaExcluir));
    }

    async function handleFinalizarGrade() {
        if (gradeDefeitos.filter(defeito => { return defeito.tamanho === itemGradeDefeitoSelecionado.Tamanho }).length < 1) {
            toast.error('Você deve incluir pelo menos 1 defeito.');
            return false;
        }

        const objInserir = {
            tamanho: itemGradeDefeitoSelecionado.Tamanho,
            qtdPecasDefeito: qtdPecasDefeito,
            grade: gradeDefeitos
        }

        let grade = [];
        grade = grade.concat(gradeDefeitosInseridos.filter(defeito => { return defeito.tamanho !== itemGradeDefeitoSelecionado.Tamanho }));
        grade.push(objInserir);
        setGradeDefeitosInseridos(grade);
        setShow(false);
        setQtdPecasDefeito('');
    }

    const handleAvaliarGrade = () => {

        if (itemGradeDefeitoSelecionado.Tamanho === undefined) {
            toast.error('Favor selecionar uma grade.');
            return false;
        }
        if (qtdPecasDefeito === null || qtdPecasDefeito === undefined || (parseInt(qtdPecasDefeito) < 0)) {
            toast.error('Favor informar a quantidade de peças.');
            return false;
        }

        if (parseInt(qtdPecasDefeito) == 0) {
            const itemParaInserir = {
                tamanho: itemGradeDefeitoSelecionado.Tamanho,
                grade: [],
            };

            let gradeAux = gradeDefeitos?.filter(item => { return item.tamanho !== itemParaInserir.Tamanho });

            setGradeDefeitos(gradeAux);
            let grade = [];
            grade = grade.concat(gradeSemDefeito.filter(item => { return item.tamanho !== itemGradeDefeitoSelecionado.Tamanho }));
            grade.push(itemParaInserir);
            setGradeSemDefeito(grade);
            setGradeDefeitosInseridos(gradeDefeitosInseridos.filter(item => { return item.tamanho !== itemGradeDefeitoSelecionado.Tamanho }))
            setGradeDefeitos(gradeDefeitos.filter(item => { return item.tamanho !== itemGradeDefeitoSelecionado.Tamanho }))
            toast.warning('Atenção! Grade inserida sem defeito.');
            setQtdPecasDefeito('');
            return false;
        }
        handleShow();
    }

    const handleAprovarGrade = () => {
        const gradeTotalGrade = gradeAnaliseQualidadeTotal.length;
        const gradeTotalOk = gradeAnaliseQualidadeTotal.filter((item) => {
            return gradeDefeitosInseridos.concat(gradeSemDefeito).filter(defeito => { return defeito.tamanho === item.Tamanho }).length > 0
        }).length;
        if (parseInt(gradeTotalOk) < parseInt(gradeTotalGrade)) {
            toast.error('Você deve inserir os defeitos de todas as grades antes de aprovar');
            return false;
        }
        let gradeAnaliseQualidadeTotalAux = []
        gradeAnaliseQualidadeTotalAux = gradeAnaliseQualidadeTotalAux.concat(gradeAnaliseQualidadeTotal);
        let gradeProducao = []
        gradeAnaliseQualidadeTotalAux.map((item, index) => {
            const gradeDefeitosInseridosAux = gradeDefeitosInseridos;
            const itemDefeito = gradeDefeitosInseridosAux.filter(defeito => { return defeito.tamanho === item.Tamanho });

            item.TipoAnalise = props.gradeSegundaQualidade.length > 0 ? '02 - SEGUNDA QUALIDADE' : '01 - PRODUÇÃO'
            let itemGrade = { ...item }
            if (itemDefeito.length > 0) {
                itemGrade.QtdeDirecionada = itemDefeito[0].qtdPecasDefeito;
                itemGrade.grade = itemDefeito[0].grade;
                itemGrade.TipoAnalise = '01 - PRODUÇÃO';
            } else {
                itemGrade.QtdeDirecionada = 0;
                itemGrade.grade = [];
                itemGrade.TipoAnalise = '01 - PRODUÇÃO';
            }
            gradeProducao.push(itemGrade);
            return item;
        });

        if (props.gradeSegundaQualidade.length > 0) {
            setGradeAnaliseQualidadeTotalAprovada(gradeAnaliseQualidadeTotal.concat(gradeProducao));
        } else {
            setGradeAnaliseQualidadeTotalAprovada(gradeProducao);
        }

        setDesabilitaProcessar(false);
        setQtdPecasDefeito('');
        toast.success('Grades finalizadas. Favor clicar em processar para salvar as alterações.');
    }

    const handleDefeitoCasoEspecial = (defeito) => {
        if (defeito.value === 115) setShowModalDefeito115(true);
        setDefeitosEncontradosSelecionado(defeito);
    }

    async function handleBtnProcessar() {
        let objProcessar = {
            gradeAnaliseQualidadeTotalAprovada: gradeAnaliseQualidadeTotalAprovada,
            produto: props.produto,
            user: CookiesService.getUserLogin(),
            idArea: '5',
            identificacao: props.identificacao,
            qtdPecasAmostra: props.qtdPecasAmostra,
            statusAnaliseSelecionada: props.statusAnaliseSelecionada,
            responsavelAnaliseSelecionado: props.responsavelAnaliseSelecionado,
            recebido: props.recebido,
            qtdPecasRecedidas2Q: props.qtdPecasRecedidas2Q,
            qtdPecasDirecionadas: props.qtdPecasDirecionadas,
            gradeSegundaQualidadeSelecionada: props.gradeSegundaQualidadeSelecionada,
            observacaoEspecial: handleChecarObservacaoEspecial()
        }

        try {
            await retryApi('post', "PArevisao/FinalizarProcesso", objProcessar);
            setShowModalConfirmaProcessar(false);
            toast.success("Pedido processado!")
            setTimeout(() => {
                setShow(false);
                props.handleCloseAll();
            }, 5000)
        } catch (e) {
            console.error(e);
            toast.error('Erro ao processar revisão - Por favor tente novamente.');
        }
    }

    const handleChecarObservacaoEspecial = () => {
        let observacaoEspecial = ``;
        if (precoIncorreto) observacaoEspecial = observacaoEspecial.concat(`\nEtiqueta possuía o seguinte valor incorreto: R$ ${precoIncorreto}.`);

        if (observacaoEspecial.length > 0) { observacaoEspecial = observacaoEspecial.concat(`\n`); return observacaoEspecial }
        else return null;
    }

    async function getGradeNaoRecebido() {
        try {
            const { Produto, CorProduto, NFEntrada, SerieNF, Origem, OrdemProducao, QtdeEntrada, FilialRevisao } = props.produto;
            const params = {
                Produto: Produto,
                CorProduto: CorProduto,
                NFEntrada: NFEntrada,
                SerieNF: SerieNF,
                Origem: Origem,
                OrdemProducao: OrdemProducao,
                QtdeEntrada: QtdeEntrada,
                FilialRevisao: FilialRevisao,
                TipoAnalise: '01 - PRODUÇÃO'
            };
            const lista = await retryApi('get', "PArevisao/GradeSegundaQualidade", { params });
            setGradeAnaliseQualidadeTotal(lista.data);

        } catch (error) {
            toast.error('Ocorreu um erro para carregar a grade!');
            return false;
        }
    }

    const handleValueMask = (value) => {
        if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
        setPrecoIncorreto(parseFloat(value / 100).toFixed(2));
    }

    return (
        <div>
            {/* Collapse Identificação Produto */}
            <article className={styles.articleMargin}>
                {/* Identificação Produto */}
                <Accordion style={{ marginTop: "10px" }}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Identificação do Produto</Accordion.Header>
                        <Accordion.Body>
                            <ProdutoInfo item={props.produto}></ProdutoInfo>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </article>

            <article className={styles.articleBorder}>
                <h5>
                    Tipo:
                    <span className={styles.spanBlueBold}> PRODUÇÃO</span>
                </h5>
                <div className={styles.sectionContent} >
                    <p style={{ marginBottom: 10 }}> 1. Grade</p>
                    <Table bordered responsive="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th className={styles.blockTitle}>TAMANHO</th>
                                <th className={styles.blockTitle}>Q. RECEBIDA</th>
                                <th className={styles.blockTitle}>Q. DIRECIONADA</th>
                                <th className={styles.blockTitle}>Q. PENDENTE</th>
                                <th className={styles.blockTitle}>STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gradeAnaliseQualidadeTotal.map((item, index) => {
                                return (
                                    <tr
                                        className={styles.tableLines}
                                        key={`rowGradeSegundaQualidade` + index}
                                        style={{ backgroundColor: gradeDefeitosInseridos.concat(gradeSemDefeito).filter(defeito => { return defeito.tamanho === item.Tamanho }).length > 0 ? '#C4C4C4' : 'white' }}
                                    >
                                        <td>
                                            <Form.Check
                                                id={`itemGrade_` + item.Tamanho}
                                                key={`itemGrade_` + item.Tamanho}
                                                type={'radio'}
                                                name={'gradeQualidade'}
                                                value={item.Tamanho}
                                                onChange={() => { handleItemGradeDefeitoQualidade(item) }}
                                            />

                                        </td>
                                        <td className={styles.tableLine}>{item.Tamanho}</td>
                                        <td className={styles.tableLine}>{item.Qtde}</td>
                                        <td className={styles.tableLine}>{item.QtdeDirecionada}</td>
                                        <td className={styles.tableLine}>{item.Qtde_Pendente}</td>
                                        <td className={styles.tableLine}>{gradeDefeitosInseridos.concat(gradeSemDefeito).filter(defeito => { return defeito.tamanho === item.Tamanho }).length > 0 ? 'OK' : 'PENDENTE'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className={styles.alignLeft}>
                    <Form>
                        <Form.Label>2. Qtde peças com defeito:</Form.Label>
                        <Form.Control
                            className="mb-3"
                            value={qtdPecasDefeito}
                            type="number"
                            min="0"
                            onChange={(e) => (setQtdPecasDefeito(e.target.value))}
                        />
                    </Form>
                    <Form className={styles.formAlignLeft} style={{ marginTop: 2 }}>
                        <Form.Label>Peças com defeito: {qtdPecasAmostra}</Form.Label>
                        <span className={styles.spanGray}>{qtdPecasDefeito ? Math.round((parseInt(qtdPecasDefeito) / parseInt(qtdPecasAmostra === 0 || qtdPecasAmostra === null ? 1 : qtdPecasAmostra)) * 100, 2) : 0}%</span>
                    </Form>
                </div>
                <div className={styles.buttonRight}>
                    <Button
                        className={styles.buttonMarginSide}
                        variant="success"
                        onClick={handleAprovarGrade}
                    >
                        Finalizar Grades
                    </Button>
                    <Button
                        className={styles.buttonMarginSide}
                        variant="outline-primary"
                        onClick={handleAvaliarGrade}
                    >
                        Avaliar Grade
                    </Button>
                </div>
            </article>

            <h5 className={styles.fontBold}>Resumo Análise</h5>
            <article className={styles.articleGrayColumn}>

                {/* Grades Avaliadas */}
                <div className={styles.displayTwoTablesLeft}>
                    <div className={styles.sectionContent}>
                        <span className={styles.fontBold}> Grades Avaliadas:</span>
                        <Table className={styles.backgroundMiddleGray}>
                            <thead>
                                <tr>
                                    <th className={styles.blockTitle}>SEQ.</th>
                                    <th className={styles.blockTitle}>GRADE</th>
                                    <th className={styles.blockTitle}>PEÇAS COM DEFEITO</th>
                                    <th className={styles.blockTitle}>TIPO ANÁLISE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gradeAnaliseQualidadeTotalAprovada.map((item, index) => {
                                    return (
                                        <tr className={styles.tableLines} key={`rowGraderesumoQualidade` + index}>
                                            <td className={styles.tableLine}>{index + 1}</td>
                                            <td className={styles.tableLine}>{item.Tamanho}</td>
                                            <td className={styles.tableLine}>{item.QtdeDirecionada}</td>
                                            <td className={styles.tableLine}>{item.TipoAnalise}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* Avaliações */}
                <div className={styles.displayTwoTablesLeft}>
                    <div className={styles.sectionContent}>
                        <span className={styles.fontBold}> Avaliações:</span>
                        <Table className={styles.backgroundMiddleGray}>
                            <thead>
                                <tr>
                                    <th className={styles.blockTitle}>SEQ.</th>
                                    <th className={styles.blockTitle}>GRADE</th>
                                    <th className={styles.blockTitle}>GRUPO ANÁLISE</th>
                                    <th className={styles.blockTitle}>STATUS AVALIAÇÃO</th>
                                    <th className={styles.blockTitle}>DEFEITO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gradeAnaliseQualidadeTotalAprovada.map((row, index) => {
                                    const { grade } = row;
                                    return (<>
                                        {grade?.map((item, index) => {
                                            return (
                                                <tr className={styles.tableLines} key={`rowGraderesumoQualidade` + index}>
                                                    <td className={styles.tableLine}>{index + 1}</td>
                                                    <td className={styles.tableLine}>{row.Tamanho}</td>
                                                    <td className={styles.tableLine}>{item.grupo.label}</td>
                                                    <td className={styles.tableLine}>{item.status.label}</td>
                                                    <td className={styles.tableLine}>{item.defeito.label}</td>
                                                </tr>
                                            );
                                        })}
                                    </>)
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* Segunda Qualidade */}
                <div className={styles.displayTwoTablesLeft}>
                    <div className={styles.sectionContent}>
                        <span className={styles.fontBold}> Segunda Qualidade:</span>
                        <Table className={styles.backgroundMiddleGray}>
                            <thead>
                                <tr>
                                    <th className={styles.blockTitle}>SEQ.</th>
                                    <th className={styles.blockTitle}>GRADE</th>
                                    <th className={styles.blockTitle}>QTD RECEBIDA</th>
                                    <th className={styles.blockTitle}>QTD DIRECIONADA</th>
                                    <th className={styles.blockTitle}>QTD PENDENTE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gradeSegundaQualidade.map((item, index) => {
                                    return (
                                        <tr className={styles.tableLines} key={`rowSegQualidadeDir_` + index}>
                                            <td className={styles.tableLine}>{index + 1}</td>
                                            <td className={styles.tableLine}>{item.Tamanho}</td>
                                            <td className={styles.tableLine}>{item.Qtde}</td>
                                            <td className={styles.tableLine}>{item.QtdeDirecionada}</td>
                                            <td className={styles.tableLine}>{item.Qtde_Pendente}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* Direcionamento Segunda Qualidade */}
                <div className={styles.displayTwoTablesLeft}>
                    <div className={styles.sectionContent}>
                        <div className={styles.divAlignLine}>
                            <span className={styles.fontBold}> Direcionamento Segunda Qualidade:</span>
                            <span className={styles.fontSmall}> TT Recebido 2Q: <span className={styles.spanGray}> {props.qtdPecasRecedidas2Q}</span></span>
                            <span className={styles.fontSmall}> TT Direcionado 2Q: <span className={styles.spanGray}> {props.qtdPecasDirecionadas}</span></span>
                        </div>
                        <Table className={styles.backgroundMiddleGray}>
                            <thead>
                                <tr>
                                    <th className={styles.blockTitle}>SEQ.</th>
                                    <th className={styles.blockTitle}>GRADE</th>
                                    <th className={styles.blockTitle}>TIPO ANÁLISE</th>
                                    <th className={styles.blockTitle}>DESTINO</th>
                                    <th className={styles.blockTitle}>QTD PEÇAS</th>
                                    <th className={styles.blockTitle}>OBSERVAÇÕES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gradeSegundaQualidadeSelecionada.map((item, index) => {
                                    return (
                                        <tr className={styles.tableLines}>
                                            <td className={styles.tableLine}>{index + 1}</td>
                                            <td className={styles.tableLine}>{item.Tamanho}</td>
                                            <td className={styles.tableLine}>{item.TipoAnalise}</td>
                                            <td className={styles.tableLine}>{item.DestinoDesc}</td>
                                            <td className={styles.tableLine}>{item.QtdePecas}</td>
                                            <td className={styles.tableLine}>{item.Obs}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </article>

            {/* Footer */}
            <article className={styles.footer}>
                <Button //Só habilita quando tudo acima estiver preenchido
                    variant={desabilitaProcessar ? "secondary" : "primary"} // desebilitado
                    //variant="primary"   // habilitado
                    disabled={desabilitaProcessar}
                    onClick={() => (setShowModalConfirmaProcessar(true))}
                >
                    Processar
                </Button>
            </article>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                fullscreen={true}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Avaliar Grade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Accordion className="mt-3">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Identificação do Produto</Accordion.Header>
                            <Accordion.Body>
                                <ProdutoInfo item={props.produto}></ProdutoInfo>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <article className={styles.sectionBorder}>
                        <div className={styles.backgroundMiddleGray}>
                            <div className={styles.secondQaInfo}>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Tamanho:</Form.Label>
                                    <span className={styles.spanBold}>{itemGradeDefeitoSelecionado.Tamanho}</span>
                                </Form>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Qtde Peças com Defeito:</Form.Label>
                                    <span className={styles.spanBold}>{qtdPecasDefeito}</span>
                                </Form>
                            </div>
                        </div>
                        <div className={styles.sectionContent} >
                            <div className={styles.alignLineFormDefeito}>
                                <div className={styles.alignColummFormDefeito}>
                                    <Form className="m-2">
                                        <Form.Label>1. Grupo de Avaliação:</Form.Label>
                                        <RadioButtonList options={grupoAvaliacao}
                                            ref={listRef}
                                            changeList={(e) => { listaDefeitosEncontrados(e.value); setGrupoAvaliacaoSelecionado(e); }}></RadioButtonList>
                                    </Form>
                                    <Form className="m-2">
                                        <Form.Label>4. Localização do Defeito:</Form.Label>
                                        {localizacaoDefeito.length > 0 ?
                                            <RadioButtonList ref={listRef}
                                                options={localizacaoDefeito} changeList={(e) => { setLocalizacaoDefeitoSelecionada(e); }}></RadioButtonList>
                                            : ''
                                        }
                                    </Form>
                                </div>
                                <div className={styles.alignColummFormDefeito}>
                                    <Form className="m-2">
                                        <Form.Label>2. Status Avaliação:</Form.Label>
                                        <RadioButtonList ref={listRef}
                                            options={statusAvaliacao} changeList={(e) => { setStatusAvaliacaoSelecionado(e); }}></RadioButtonList>
                                    </Form>
                                    <Form className="m-2">
                                        <Form.Label>5. Especifique a área mais próxima:</Form.Label>
                                        <RadioButtonList options={areaEspecifica} changeList={(e) => { setAreaEspecificaSelecionada(e); }}></RadioButtonList>
                                    </Form>
                                </div>
                                <div className={styles.alignColummFormDefeito}>
                                    <Form className="m-2">
                                        <Form.Label>3. Defeito Encontrado:</Form.Label>
                                        {defeitosEncontrados.length > 0 ?
                                            <RadioButtonList
                                                options={defeitosEncontrados}
                                                changeList={(e) => { handleDefeitoCasoEspecial(e) }}
                                            ></RadioButtonList>
                                            : ''
                                        }
                                    </Form>
                                    <Form className="m-2">
                                        <Form.Label>
                                            6. Considerações
                                            <span className={styles.spanOpcional}> (Opcional)</span>
                                            :
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="2"
                                            class={styles.formControl}
                                            id="txtConsideracoes"
                                            value={consideracoesSelecionada}
                                            onChange={(e) => { setConsideracoesSelecionada(e.target.value); }}>
                                        </Form.Control>
                                    </Form>
                                </div>
                            </div>

                        </div>
                        <div className={styles.buttonRight}>
                            <Button
                                variant="success"
                                //variant="success"
                                onClick={handleIncluiAvaliacao}
                            >
                                Incluir Avaliação
                            </Button>
                        </div>
                    </article>

                    <h5 className={styles.fontBold}>Resumo Avaliação:</h5>
                    <article className={styles.sectionBorder}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className={styles.blockTitle}>GRUPO</th>
                                    <th className={styles.blockTitle}>STATUS</th>
                                    <th className={styles.blockTitle}>DEFEITO ENCONTRATO</th>
                                    <th className={styles.blockTitle}>LOCALIZAÇÃO</th>
                                    <th className={styles.blockTitle}>ÁREA MAIS PRÓXIMA</th>
                                    <th className={styles.blockTitle}>CONSIDERAÇÕES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gradeDefeitos.length > 0 ?
                                    gradeDefeitos
                                        .filter(item => {
                                            return item.tamanho === itemGradeDefeitoSelecionado.Tamanho;
                                        })
                                        .map((item, index) => {
                                            return (
                                                <tr className={styles.tableLines} key={`rowGradeDefeitosSelecionada` + index}>
                                                    <td>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => handleExcluiItemDefeito(item)}
                                                            disabled={item.defeito.value === 115}
                                                        >
                                                            <span className="fa-trash icons-uniao">
                                                                <FontAwesomeIcon
                                                                    className="fa-trash-2x"
                                                                    icon={faTrash}
                                                                />
                                                            </span>
                                                        </Button>
                                                    </td>
                                                    <td className={styles.tableLine}>{item.grupo.label}</td>
                                                    <td className={styles.tableLine}>{item.status.label}</td>
                                                    <td className={styles.tableLine}>{item.defeito.label}</td>
                                                    <td className={styles.tableLine}>{item.localizacao.label}</td>
                                                    <td className={styles.tableLine}>{item.areaEspecifica.label}</td>
                                                    <td className={styles.tableLine}>{item.consideracoes}</td>
                                                </tr>
                                            );
                                        })
                                    : ''
                                }
                            </tbody>
                        </Table>
                    </article>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="primary"  // quando desabilitado
                        //variant="primary" // quando habilitado -- só habilita quando todas peças forem incluidas no direcionamento
                        //onClick={handleClose}
                        onClick={handleFinalizarGrade}
                    >
                        Finalizar Grade
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalConfirmaProcessar}
            >
                <Modal.Header>
                    <Modal.Title>
                        Atenção!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Essa ação irá gravar as informações no banco de dados. Deseja continuar?!
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowModalConfirmaProcessar(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handleBtnProcessar()}
                    >
                        Gravar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalDefeito115}
                backdrop={'static'}
                keyboard={false}
                centered={true}
                style={{ backgroundColor: '#00000090' }}
            >
                <Modal.Header>
                    <Modal.Title>
                        Insira abaixo o valor do preço incorreto encontrado no código de barras
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-2">
                        <Form.Group className={styles.formControl}>
                            <Form.Label className={styles.formLabel}>
                                Preço Incorreto:
                            </Form.Label>
                            <Form.Control
                                type={'text'}
                                value={precoIncorreto || ''}
                                onChange={e => handleValueMask(e.target.value)}
                                placeholder={'0.00'}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        className={styles.footerBtn}
                        style={{ width: '40%' }}
                        variant="primary"
                        onClick={() => { setShowModalDefeito115(false); }}
                        disabled={!precoIncorreto || precoIncorreto === ''}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default MonitorRevisaoAnaliseGrade;