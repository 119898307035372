import React from "react";
import { Button, Modal } from 'react-bootstrap';
import styles from '../../../../styles/FarmGlobal/TesteDeBase.module.scss';
import { useFGTesteDeBase } from "../../../../contexts/FarmGlobal/TesteDeBaseContext.js";

const ModalConfirmacaoNovaAmostra = () => {

    const { showConfirmacaoNovaAmostra, setShowConfirmacaoNovaAmostra, materialAtivo, handleSaveUpdateMaterial} = useFGTesteDeBase();
    
    const handleSubmit = (status) => {
        handleSaveUpdateMaterial(status);
    };


    return (
        <Modal
            show={showConfirmacaoNovaAmostra}
            onHide={()=> { setShowConfirmacaoNovaAmostra(false);}}
            backdrop="static"
            keyboard={false}
            centered
        >
        <Modal.Header>
            <Modal.Title>Confirmação de Nova Amostra</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{textAlign:'center'}}>
            <span>              
                Tem certeza que deseja inserir a nova amostra  <br/><strong>{materialAtivo?.Descricao}</strong>?                
            </span>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
            <Button
                    variant="success"
                    className={styles.modalBtn}
                    onClick={() => handleSubmit('nova-amostra')}
                >
                    Sim
                </Button>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={()=> { setShowConfirmacaoNovaAmostra(false);}}
                >
                    Voltar
                </Button>
        </Modal.Footer>
      </Modal>
    ) 
}

export default ModalConfirmacaoNovaAmostra;
