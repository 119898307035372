import { useEffect, useState, useCallback } from 'react';
import styles from '../../styles/PlanejamentoDiarioCorte.module.scss';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MaterialIcon from 'material-icons-react';

import { obterDataAtual } from '../../utils/dateUtils.jsx';

function Agendamento({ handleSalvar, somaGradePlansPorData, atualizarDados, obterExcel }) {
  const [dataAtual, setDataAtual] = useState('');
  const [dataAtual1, setDataAtual1] = useState('');
  const [dataAtual2, setDataAtual2] = useState('');


  const limparCampo = () => {
    if (!extrairValorPorData(obterDataAtual())) setDataAtual('')
    if (!extrairValorPorData(obterDataAtual(1))) setDataAtual1('')
    if (!extrairValorPorData(obterDataAtual(2))) setDataAtual2('')
  }

  const extrairValorPorData = useCallback(
    (data) => {
      if (!Array.isArray(somaGradePlansPorData)) return;

      if (somaGradePlansPorData.length === 0) return;

      const primeiroElemento = somaGradePlansPorData[0];

      if (typeof primeiroElemento !== 'object' || primeiroElemento === null) return;

      if (!(data in primeiroElemento)) return;

      return primeiroElemento[data];
    },
    [somaGradePlansPorData]
  );

  useEffect(() => {
    setDataAtual(extrairValorPorData(obterDataAtual()));
    setDataAtual1(extrairValorPorData(obterDataAtual(1)));
    setDataAtual2(extrairValorPorData(obterDataAtual(2)));

    limparCampo()
  }, [extrairValorPorData]);


  const CSVConversor = (e) => {
    e.preventDefault();
     obterExcel()
  }
  
  return (
    <div>
      <h5 style={{ marginTop: '20px' }}>Total de agendamentos:</h5>
      <Form className={styles.form}>
        <Form.Group className={styles.formGroup}>
          <Form.Label className={styles.formLabel}>Data atual</Form.Label>
          <Form.Control
            type="text"
            placeholder="Qtd agend. data atual"
            value={dataAtual}
          />
        </Form.Group>
        <Form.Group className={styles.formGroup}>
          <Form.Label className={styles.formLabel}>Data atual + 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Qtd agend. data atual+1"
            value={dataAtual1}
          />
        </Form.Group>
        <Form.Group className={styles.formGroup}>
          <Form.Label className={styles.formLabel}>Data atual + 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Qtd agend. data atual+2"
            value={dataAtual2}
          />
        </Form.Group>
        <Button className={styles.formGroup} onClick={atualizarDados} variant="outline-primary" type="button">
          Atualizar base
        </Button>
        <Button className={styles.formGroup} variant="success" type="button" onClick={handleSalvar}>
          Salvar
        </Button>
        <Button 
            type="button" 
            variant="outline-success"
            onClick={e => CSVConversor(e)}
        >
            <div className={styles.formBtnDisplay}>
                <i>
                    <MaterialIcon 
                        icon="download" 
                        size={20} 
                        color='#198754'
                    />
                </i>
                Exportar
            </div>
         </Button>
      </Form>
    </div>
  );
}

export default Agendamento;