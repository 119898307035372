import React from "react";
import ConsultaExclusao from "../../components/PA/PecaPiloto/ConsultaExclusao/index";

import styles from '../../styles/AdmVigenciaExclusao.module.scss';

const PAConsultaExclusao = () => {
    return (
        <div className="p-2">
          <h3>Catálogo Amostras - Consulta e Exclusão PA</h3>
          <ConsultaExclusao />
        </div>
    );
};

export default PAConsultaExclusao;