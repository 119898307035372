import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form } from 'react-bootstrap';
import styles from '../../../../styles/ADM/Desconto/EditarPendenciar.module.scss';

const ModalConfirmacaoPend = ({ showConfirmacao, handleCloseConfirmacao, handleCriarPendencia, linhaSelecionada, 
                                defeitosData, defeitoSelecionado, consideracoes
}) => {

    const [defeitoEncontrado, setDefeitoEncontrado] = useState('');

    useEffect(() => {
        if(defeitoSelecionado) {
            const defeitoFind = defeitosData.find(item => item.value === defeitoSelecionado);
            setDefeitoEncontrado(defeitoFind.label)
        }
    }, [defeitoSelecionado])

    return (
        <Modal
            show={showConfirmacao}
            onHide={handleCloseConfirmacao}
            backdrop="static"
            keyboard={false}
            centered
            size='lg'
            >
            <Modal.Header>
                <Modal.Title>Confirmar Pendência</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <article className={styles.avisoConfirmacao}>
                        <div>
                            <span className={styles.avisoAtencao}>
                                <strong>ATENÇÃO!!</strong>
                            </span>
                        </div>
                        <div>
                            <span>
                                <em>Será criada uma pendência com essas informações:</em>
                            </span>
                        </div>
                </article>
                <article>
                    <Table responsive>
                        <tbody>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Nota Fiscal
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={linhaSelecionada.NfEntrada || ''}
                                            className={styles.disabledFormPend}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Série NF
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={linhaSelecionada.SerieNf || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Material
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={linhaSelecionada.Material || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Cor Material
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={linhaSelecionada.CorMaterial || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Item
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={linhaSelecionada.Item || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                Fornecedor
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={linhaSelecionada.Fornecedor || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Quantidade Entrada
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={linhaSelecionada.QtdeEntrada || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Defeito
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={defeitoEncontrado || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                            <tr className={styles.tableLines}>
                                <td className={styles.tableLines}>
                                    Consideração
                                </td>
                                <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Control 
                                            type='text'
                                            disabled
                                            value={consideracoes || ''}
                                        />
                                    </Form>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </article>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseConfirmacao}
                >
                    Voltar
                </Button>
                <Button
                    variant="outline-success"
                    className={styles.modalBtn}
                    onClick={() => handleCriarPendencia()}
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmacaoPend;
