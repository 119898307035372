import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const DadosDoPedido = (props) => {

  const [collapsed, setCollapsed] = useState(true);

  function CollapseDiv () {
    setCollapsed(!collapsed)
  }

  return (
    <div className="ms-3 me-3 d-flex flex-column text-secondary mt-4 border border-primary bg-light rounded">
      <div  style={{cursor:"pointer"}} onClick={CollapseDiv} className="d-flex pt-1 ms-2 text-dark">
        {
          collapsed ?
          <h6>▼</h6> :
          <h6>▲</h6>
        }
        <h6 className="ms-3">Ver Dados do Pedido</h6>
      </div>
      {
        !collapsed ?
        <div className={"d-flex flex-row justify-content-between px-3 pb-2"}>
          <div className="d-flex flex-column w-100">

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-2">Pedido:</h6>
              <h6 className="text-primary">{props.obj.pedido}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Nota Fiscal:</h6>
              <h6 className="text-primary">{props.obj.nfentrada}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Recebimento:</h6>
              <h6 className="text-primary">{props.obj.recebimento}</h6>
            </div>

            <div className="mt-2 d-flex flex-column">
              <h6 className="mt-1">Serie NF:</h6>
              <h6 className="text-primary">{props.obj.serieNF}</h6>
            </div>

          </div>

          <div className="d-flex flex-column w-100">

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-2">Material:</h6>
              <h6 className="text-primary">{props.obj.material}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Descrição Material:</h6>
              <h6 className="text-primary">{props.obj.descricaomaterial}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Cor Material:</h6>
              <h6 className="text-primary">{props.obj.cormaterial}</h6>
            </div>

            <div className="mt-2 d-flex flex-column">
              <h6 className="mt-1">Descrição Cor:</h6>
              <h6 className="text-primary">{props.obj.descricaocor}</h6>
            </div>

          </div>

          <div className="d-flex flex-column w-100">

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-2">Fornecedor:</h6>
              <h6 className="text-primary">{props.obj.nomeCliFor}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Composição:</h6>
              <h6 className="text-primary">{props.obj.composicao}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Marca:</h6>
              <h6 className="text-primary">{props.obj.Marca}</h6>
            </div>

            <div className="mt-2 d-flex flex-column">
              <h6 className="mt-1">Coleção:</h6>
              <h6 className="text-primary">{props.obj.colecaocompras}</h6>
            </div>

          </div>

          <div className="d-flex flex-column w-100">

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-2">Peças:</h6>
              <h6 className="text-primary">{props.obj.PECA}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Recebido:</h6>
              <h6 className="text-primary">{props.obj.QtdePecas}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Data Lib. Rev.:</h6>
              <h6 className="text-primary">{props.obj.DataLiberadoRevisao}</h6>
            </div>

            <div className="mt-2 d-flex flex-column">
              <h6 className="mt-1">Tipo Compra:</h6>
              <h6 className="text-primary">{props.obj.tipocompra}</h6>
            </div>

          </div>

          <div className="d-flex flex-column w-100">

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-2">Mostruario:</h6>
              <h6 className="text-primary">{props.obj.Mostruario === 1 ? 'SIM' : 'NÃO'}</h6>
            </div>

            <div className="mb-3 mt-2 d-flex flex-column">
              <h6 className="mt-1">Largura Linx:</h6>
              <h6 className="text-primary">{props.obj.LarguraLinx}</h6>
            </div>

            <div className="mt-2 d-flex flex-column">
              <h6 className="mt-1">Amostra:</h6>
              <h6 className="text-primary">{props.obj.Amostra}</h6>
            </div>

          </div>
        </div> :
        null
      }
    </div>
  )
}

export default DadosDoPedido;