import { Modal, Button } from "react-bootstrap";
import styles from '../pa_revisao.module.scss';
import { usePARevisao } from "../pa_revisao_context";

const PARevisaoModalConfirmacaoSQ = () => {
  const { modalConfirmacaoSQ, setModalConfirmacaoSQ, referenciaSelecionada, cancelarDirecionamentos } = usePARevisao();

  return (
    <Modal
      show={modalConfirmacaoSQ}
      centered
      size={'lg'}
      onHide={() => setModalConfirmacaoSQ(false)}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header style={{ fontWeight: '500' }} closeButton>
        Confirmar Mudança {referenciaSelecionada.nf_segunda_qualidade ? 'para NF Normal' : 'para NF Segunda Qualidade'}
      </Modal.Header>
      <Modal.Body className={styles.modal_body} style={{fontWeight: '500'}}>
        {
          referenciaSelecionada.nf_segunda_qualidade ?
            'Parece que você já inseriu alguns direcionamentos de segunda qualidade.\nAo prosseguir você concorda em desconsiderar todos os direcionamentos já inseridos.'
            :
            'Parece que você já inseriu alguns direcionamentos.\nAo prosseguir você concorda em desconsiderar todos os direcionamentos já inseridos.'
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'danger'}
          onClick={() => cancelarDirecionamentos()}
          className={styles.footer_btn_processamento}
        >
          Cancelar Direcionamentos Já Inseridos
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoModalConfirmacaoSQ;