import React, { useEffect, useState } from 'react';
import { Button, Table, Form, Modal } from 'react-bootstrap';
import { api } from "../services/api";
import DadosAccordion from '../components/RevisaoCemPorcentoRetorno/DadosPedidoAccordion';
import styles from '../styles/RevisaoCemPorcentoRetorno.module.scss';
import { toast, ToastContainer } from "react-toastify";
import { formatarDataBR } from '../utils/dateUtils';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import { isNullish } from '../utils/misc_utils';
import { useMisc } from '../contexts/MiscContext';

const RevisaoCemPorcentoRetorno = () => {
  const { setShowLoading, showLoading } = useMisc();
  const [acaoSelecionada, setAcaoSelecionada] = useState({ label: '100%', value: 'cemPorcento' });

  const [filtroBusca, setFiltroBusca] = useState({
    nf: '',
    material: '',
    cor_material: '',
    pedido: '',
    op: '',
    os: ''
  });

  const [buscaEscolhida, setBuscaEscolhida] = useState([]);
  const [linhaSelecionada, setLinhaSelecionada] = useState({});
  const [pecas, setPecas] = useState([]);

  const [medidas, setMedidas] = useState({
    metragem_fornecedor: '',
    relogio: '',
    largura_fornecedor: '',
    largura_util: '',
    ourela: ''
  })

  const [defeitos, setDefeitos] = useState([]);
  const [local, setLocal] = useState([]);
  const [tamanhoPontuacao, setTamanhoPontuacao] = useState([]);
  const [condenar, setCondenar] = useState(false);
  const [consideracao, setConsideracao] = useState('');

  const [pecaSelecionada, setPecaSelecionada] = useState({});
  const [defeitoSelecionado, setDefeitoSelecionado] = useState({});
  const [localSelecionado, setLocalSelecionado] = useState({});
  const [tamanhoPontuacaoSelecionada, setTamanhoPontuacaoSelecionada] = useState({});

  const [pecaEmAnalise, setPecaEmAnalise] = useState({});

  const [pecasAnalisadas, setPecasAnalisadas] = useState([]);
  const [defeitosIncluidos, setDefeitosIncluidos] = useState([]);
  const [defeitosModal, setDefeitosModal] = useState([]);

  const [showModalRevisao, setShowModalRevisao] = useState(false);
  const [showAnalisarAprovar, setShowAnalisarAprovar] = useState(false);
  const [showAnalisar, setShowAnalisar] = useState(false);

  const selecionarAcao = [
    { label: '100%', value: 'cemPorcento' },
    { label: 'Retorno', value: 'retorno' }
  ]

  const cabecalhoBusca = acaoSelecionada.value === 'cemPorcento' ?
    [
      'Fornecedor', 'NF Entrada', 'Pedido', 'Material', 'Descrição Material', 'Cor Material',
      'Descrição Cor', 'Data Revisão', 'Entrada Revisão Por', 'Qtde Peças Agendadas'
    ]
    :
    [
      'Ordem de Produção', 'Ordem de Serviço', 'Fornecedor', 'Marca', 'Produto',
      'Descrição Produto', 'Qtde Peças',
    ]

  const handleShowRevisao = (linha) => {
    handlePecas(linha);
    setShowModalRevisao(true);
    setLinhaSelecionada(linha);
  }

  const handleCloseRevisao = () => {
    setPecas([]);
    resetMedidas();
    resetDefeitoLocalTamanho();
    setPecaEmAnalise({});
    setPecasAnalisadas([]);
    setDefeitosIncluidos([]);
    setShowModalRevisao(false);
    setLinhaSelecionada({});
  }

  const handleShowAnalisarAprovar = () => {
    setShowAnalisarAprovar(true);
    acaoSelecionada.value === 'cemPorcento' ?
      setPecaEmAnalise({
        NFEntrada: linhaSelecionada.nf_entrada,
        Material: linhaSelecionada.material,
        CorMaterial: linhaSelecionada.cor_material,
        Pedido: linhaSelecionada.pedido,
        Fornecedor: linhaSelecionada.fornecedor
      })
      :
      setPecaEmAnalise({
        OrdemProducao: linhaSelecionada.ordem_producao,
        OrdemServico: linhaSelecionada.ordem_servico,
        Fornecedor: linhaSelecionada.fornecedor
      })
  }

  const handleCloseAnalisarAprovar = () => {
    setPecaSelecionada({});
    resetMedidas();
    setPecaEmAnalise({});
    setShowAnalisarAprovar(false);
  }

  const handleCloseAnalisar = () => {
    acaoSelecionada.value === 'cemPorcento' ?
      setPecaEmAnalise({
        NFEntrada: pecaEmAnalise.NFEntrada,
        Material: pecaEmAnalise.Material,
        CorMaterial: pecaEmAnalise.CorMaterial,
        Pedido: pecaEmAnalise.Pedido,
        Fornecedor: pecaEmAnalise.Fornecedor
      })
      :
      setPecaEmAnalise({
        OrdemProducao: pecaEmAnalise.OrdemProducao,
        OrdemServico: pecaEmAnalise.OrdemServico,
        Fornecedor: pecaEmAnalise.Fornecedor
      })
    resetDefeitoLocalTamanhoSelecionados();
    setCondenar(false);
    setConsideracao('');
    setShowAnalisar(false);
    setDefeitosModal([]);
  }

  const handleFinalizarPeca = (pecaSelecionada) => {
    if (defeitosModal.length === 0) { toast.warn('Insira, pelo menos, um defeito!'); return }
    setDefeitosIncluidos([...defeitosIncluidos, ...defeitosModal])
    setPecas(pecas.filter(item => item.peca !== pecaSelecionada.peca));

    if (acaoSelecionada.value === 'cemPorcento') {
      setPecasAnalisadas([...pecasAnalisadas,
      {
        nf_entrada: pecaSelecionada.nf_entrada,
        material: pecaSelecionada.material,
        cor_material: pecaSelecionada.cor_material,
        pedido: pecaSelecionada.pedido,
        peca: pecaSelecionada.peca,
        defeito: 1,
        metragem_fornecedor: parseFloat(medidas.metragem_fornecedor),
        relogio: parseFloat(medidas.relogio),
        largura_fornecedor: parseFloat(medidas.largura_fornecedor),
        largura_util: parseFloat(medidas.largura_util),
        ourela: parseFloat(medidas.ourela),
        diferenca: (parseFloat(medidas.largura_fornecedor) - parseFloat(medidas.largura_util))
      }
      ]);
    } else if (acaoSelecionada.value === 'retorno') {
      setPecasAnalisadas([...pecasAnalisadas,
      {
        ordem_producao: pecaSelecionada.ordem_producao,
        ordem_servico: pecaSelecionada.ordem_servico,
        peca: pecaSelecionada.peca,
        defeito: 1,
        metragem_fornecedor: parseFloat(medidas.metragem_fornecedor),
        relogio: parseFloat(medidas.relogio),
        largura_fornecedor: parseFloat(medidas.largura_fornecedor),
        largura_util: parseFloat(medidas.largura_util),
        ourela: parseFloat(medidas.ourela),
        diferenca: (parseFloat(medidas.largura_fornecedor) - parseFloat(medidas.largura_util))
      }
      ]);
    }


    setDefeitosModal([]);
    setShowAnalisar(false);
    handleCloseAnalisarAprovar();
    resetDefeitoLocalTamanhoSelecionados();
    resetDefeitoLocalTamanho();
    setCondenar(false);
    setConsideracao();
  }

  const filtroHandler = (event, field) => {
    const filtro = { ...filtroBusca };
    filtro[field] = event.target.value;
    setFiltroBusca(filtro);
  }

  const medidasHandler = (event, field) => {
    const value = event.target.value.replace(',', '.');
  
    const regex = /^\d*(?:\.\d*)?$/;
    if (!regex.test(value)) {
      return;
    }

    const medida = { ...medidas };
    medida[field] = value;
    setMedidas(medida);
  };

  const handleButtonClick = (field) => {
    if (field === 'relogio') {
      setMedidas({
        ...medidas,
        relogio: medidas.metragem_fornecedor
      });
    } else if (field === 'largura_util') {
      setMedidas({
        ...medidas,
        largura_util: medidas.largura_fornecedor
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      let nextElement = form.elements[index + 1];
      while (nextElement && (nextElement.tagName === 'BUTTON' || nextElement.getAttribute('data-skip') === 'true')) {
        nextElement = form.elements[index + 2];
      } 
      nextElement?.focus();
    }
  };

  const handleSearch = async () => {
    const isCemPorcento = acaoSelecionada['value'] === 'cemPorcento';
    const acao = isCemPorcento ? 0 : 1;
    const info = { ...filtroBusca, acao };

    await api.get('MPRevisaoCemPorcentoRetorno/get-linha', { params: info })
      .then(res => {
        setBuscaEscolhida(res.data)
        if (res.data.length === 0) toast.warn('A busca não encontrou resultados');
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const buscaValues = () => {
    let values;
    if (buscaEscolhida.length > 0) {
      values = Object.values(linhaSelecionada)
      if (acaoSelecionada.value === 'cemPorcento') values[7] = formatarDataBR(values[7])
    }
    return values
  }

  const handlePecas = async (params) => {
    const acao = acaoSelecionada['value'] === 'cemPorcento' ? 0 : 1
    const info = { ...params, acao }

    await api.get('MPRevisaoCemPorcentoRetorno/get-pecas', { params: info })
      .then(res => {
        setPecas(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handleDefeitos = async () => {
    await api.get('MPRevisaoCemPorcentoRetorno/get-defeitos')
      .then(res => {
        setDefeitos(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handleLocal = async () => {
    await api.get('MPRevisaoCemPorcentoRetorno/get-local')
      .then(res => {
        setLocal(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handleTamanhoPontuacao = async () => {
    await api.get('MPRevisaoCemPorcentoRetorno/get-TamanhoPonto')
      .then(res => {
        setTamanhoPontuacao(res.data)
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
      })
  }

  const handleAprovar = () => {
    const info = { ...medidas }
    const variablesToCheck = ['metragem_fornecedor', 'relogio', 'largura_fornecedor', 'largura_util', 'ourela'];
    const fieldEmpty = variablesToCheck.some(variable => !info[variable] || info[variable] === '0');
    const fieldError = variablesToCheck.some(variable => !parseFloat(info[variable]));

    if (fieldEmpty) {
      toast.warning('Campo(s) obrigatório(s) não preenchido(s)!');
      return;
    }

    if (fieldError) {
      toast.error('Campo(s) com erro(s)!');
      return;
    }

    if (acaoSelecionada.value === 'cemPorcento') {
      const params = {       
          nf_entrada: pecaSelecionada.nf_entrada,
          material: pecaSelecionada.material,
          cor_material: pecaSelecionada.cor_material,
          pedido: pecaSelecionada.pedido,
          peca: pecaSelecionada.peca,
          defeito: 0,
          metragem_fornecedor: parseFloat(medidas.metragem_fornecedor),
          relogio: parseFloat(medidas.relogio),
          largura_fornecedor: parseFloat(medidas.largura_fornecedor),
          largura_util: parseFloat(medidas.largura_util),
          ourela: parseFloat(medidas.ourela),
          diferenca: (parseFloat(medidas.largura_fornecedor) - parseFloat(medidas.largura_util))
        }
      isNullish(medidas.index) ? pecasAnalisadas.push(params) : pecasAnalisadas[medidas.index] = params;   
      setPecasAnalisadas([...pecasAnalisadas]);
    } else if (acaoSelecionada.value === 'retorno') {
      setPecasAnalisadas([...pecasAnalisadas,
      {
        ordem_producao: pecaSelecionada.ordem_producao,
        ordem_servico: pecaSelecionada.ordem_servico,
        peca: pecaSelecionada.peca,
        defeito: 0,
        metragem_fornecedor: parseFloat(medidas.metragem_fornecedor),
        relogio: parseFloat(medidas.relogio),
        largura_fornecedor: parseFloat(medidas.largura_fornecedor),
        largura_util: parseFloat(medidas.largura_util),
        ourela: parseFloat(medidas.ourela),
        diferenca: (parseFloat(medidas.largura_fornecedor) - parseFloat(medidas.largura_util))
      }
      ]);
    }
    resetMedidas();
    setShowAnalisarAprovar(false);
    setPecas(pecas.filter(item => item !== pecaSelecionada));
  }

  const handleAnalisar = () => {
    const info = { ...medidas }
    const variablesToCheck = ['metragem_fornecedor', 'relogio', 'largura_fornecedor', 'largura_util', 'ourela'];
    const fieldEmpty = variablesToCheck.some(variable => !info[variable] || info[variable] === '0');
    const fieldError = variablesToCheck.some(variable => !parseFloat(info[variable]));

    if (fieldEmpty) {
      toast.warning('Campo(s) obrigatório(s) não preenchido(s)!');
      return;
    }

    if (fieldError) {
      toast.error('Campo(s) com erro(s)!');
      return;
    }

    Promise.all([handleDefeitos(), handleLocal(), handleTamanhoPontuacao()]);
    setPecaEmAnalise(pecaEmAnalise => ({ ...pecaEmAnalise, ...medidas }));
    setShowAnalisar(true);
  }

  const handleIncluirDefeito = (pecaEmAnalise) => {
    if (Object.keys(defeitoSelecionado).length === 0) { toast.warning('Selecione o defeito!'); return }
    else if (Object.keys(localSelecionado).length === 0) { toast.warning('Selecione o local!'); return }
    else if (Object.keys(tamanhoPontuacaoSelecionada).length === 0) { toast.warning('Selecione o tamanho!'); return }

    if (defeitosModal.find(
      defeito => defeito.Peca === pecaSelecionada.peca
        && defeito.IdDefeito === defeitoSelecionado.id_defeito
        && defeito.IdLocalizacao === localSelecionado.id_local
        && defeito.IdTamanho === tamanhoPontuacaoSelecionada.id_tamanho
    )) {
      toast.warn('A combinação de Peça, Defeito, Localização e Tamanho já existe!');
      return
    }

    acaoSelecionada.value === 'cemPorcento' ?
      setDefeitosModal([...defeitosModal,
      {
        NFEntrada: pecaEmAnalise.NFEntrada,
        Material: pecaEmAnalise.Material,
        CorMaterial: pecaEmAnalise.CorMaterial,
        Pedido: pecaEmAnalise.Pedido,
        Fornecedor: pecaEmAnalise.Fornecedor,
        Peca: pecaSelecionada.peca,
        defeito: defeitoSelecionado.nome_defeito,
        localizacao: localSelecionado.local_defeito,
        tamanho: `DE ${tamanhoPontuacaoSelecionada.minimo}cm A ${tamanhoPontuacaoSelecionada.maximo}cm`,
        IdDefeito: defeitoSelecionado.id_defeito,
        IdLocalizacao: localSelecionado.id_local,
        IdTamanho: tamanhoPontuacaoSelecionada.id_tamanho,
        Pontuacao: !condenar ? tamanhoPontuacaoSelecionada.pontuacao : 9999,
        Consideracao: consideracao
      }
      ]) :
      setDefeitosModal([...defeitosModal,
      {
        OrdemProducao: pecaEmAnalise.OrdemProducao,
        OrdemServico: pecaEmAnalise.OrdemServico,
        Fornecedor: pecaEmAnalise.Fornecedor,
        Peca: pecaSelecionada.peca,
        defeito: defeitoSelecionado.nome_defeito,
        localizacao: localSelecionado.local_defeito,
        tamanho: `DE ${tamanhoPontuacaoSelecionada.minimo}cm A ${tamanhoPontuacaoSelecionada.maximo}cm`,
        IdDefeito: defeitoSelecionado.id_defeito,
        IdLocalizacao: localSelecionado.id_local,
        IdTamanho: tamanhoPontuacaoSelecionada.id_tamanho,
        Pontuacao: !condenar ? tamanhoPontuacaoSelecionada.pontuacao : 9999,
        Consideracao: consideracao
      }
      ])

    setCondenar(false);
    resetDefeitoLocalTamanhoSelecionados();
    setConsideracao('');
  }

  const handleProcessar = async () => {
    if (pecasAnalisadas.length === 0 || pecas.length > 0) return toast.warning('É necessário analisar todas as peças antes de continuar.');

    setShowLoading(true);
    if (acaoSelecionada.value === 'cemPorcento') {
      try {
        const send = {
          acao: 0,
          cemPorcento: {
            nf_entrada: linhaSelecionada.nf_entrada,
            material: linhaSelecionada.material,
            cor_material: linhaSelecionada.cor_material,
            pedido: linhaSelecionada.pedido
          },
          cemPorcentoRevisao: pecasAnalisadas, defeitos: defeitosIncluidos
        }

        await api.post('MPRevisaoCemPorcentoRetorno/process-revisaoCemPorcentoRetorno', { send });
        toast.success('Dados inseridos com sucesso!');
        resetAll();      
      } catch (e) {
        toast.error(`Ocorreu um erro, por favor tente novamente ou entre em contato com o suporte.`);
      } finally {
        setShowLoading(false);
      }
    }

    if (acaoSelecionada.value === 'retorno') {
      try {
        const send = {
          acao: 1,
          retorno: { ordem_producao: linhaSelecionada.ordem_producao, ordem_servico: linhaSelecionada.ordem_servico },
          retornoRevisao: pecasAnalisadas, defeitos: defeitosIncluidos
        }
        await api.post('MPRevisaoCemPorcentoRetorno/process-revisaoCemPorcentoRetorno', { send });
        toast.success('Dados inseridos com sucesso!');
        resetAll();
      } catch (e) {
        toast.error(`Ocorreu um erro, por favor tente novamente ou entre em contato com o suporte.`);
      } finally {
        setShowLoading(false);
      }
    }
  }

  const resetMedidas = () => {
    setMedidas({
      metragem_fornecedor: '',
      relogio: '',
      largura_fornecedor: '',
      largura_util: '',
      ourela: ''
    })
  }

  const resetDefeitoLocalTamanho = () => {
    setDefeitos([]);
    setLocal([]);
    setTamanhoPontuacao([]);
  }

  const resetDefeitoLocalTamanhoSelecionados = () => {
    setDefeitoSelecionado({});
    setLocalSelecionado({});
    setTamanhoPontuacaoSelecionada({});
  }

  const resetAll = () => {
    resetMedidas();
    resetDefeitoLocalTamanho();
    resetDefeitoLocalTamanhoSelecionados();
    setPecasAnalisadas([]);
    setDefeitosIncluidos([]);
    setShowModalRevisao(false);
    setFiltroBusca({
      nf: '',
      material: '',
      cor_material: '',
      pedido: '',
      op: '',
      os: ''
    });
    setLinhaSelecionada({});
    setBuscaEscolhida([]);
  }

  const handleCheckPeca = (e, peca) => {
    const isChecked = e.target.checked;
    const pecaChecked = isChecked ? peca : null
    setPecaSelecionada(pecaChecked)
    setMedidas({ metragem_fornecedor: peca.metragem_fornecedor});	
  }

  const handleCheckDefeito = (e, defeito) => {
    const isChecked = e.target.checked;
    const defeitoChecked = isChecked ? defeito : null
    setDefeitoSelecionado(defeitoChecked)
  }

  const handleCheckLocal = (e, local) => {
    if (Object.keys(defeitoSelecionado).length === 0) { toast.warning('Selecione o defeito!'); return }
    const isChecked = e.target.checked;
    const localChecked = isChecked ? local : null
    setLocalSelecionado(localChecked)
  }

  const handleCheckTamanhoPontuacao = (e, tamanhoPontuacao) => {
    if (Object.keys(defeitoSelecionado).length === 0) { toast.warning('Selecione o defeito!'); return }
    else if (Object.keys(localSelecionado).length === 0) { toast.warning('Selecione o local!'); return }
    const isChecked = e.target.checked;
    const tamanhoPontuacaoChecked = isChecked ? tamanhoPontuacao : null
    setTamanhoPontuacaoSelecionada(tamanhoPontuacaoChecked)
  }

  const handleCheckCondenar = (e) => {
    if (Object.keys(defeitoSelecionado).length === 0) { toast.warning('Selecione o defeito!'); return }
    else if (Object.keys(localSelecionado).length === 0) { toast.warning('Selecione o local!'); return }
    else if (Object.keys(tamanhoPontuacaoSelecionada).length === 0) { toast.warning('Selecione o tamanho!'); return }
    const condenarChecked = e.target.checked;
    setCondenar(condenarChecked)
  }

  const handleDeleteAnalisadas = (item) => {
    setPecasAnalisadas(pecasAnalisadas.filter(peca => peca.peca !== item.peca))
    setDefeitosIncluidos(defeitosIncluidos.filter(defeito => defeito.Peca !== item.peca))
    setPecas([...pecas, { ordem_servico: item.ordem_servico, peca: item.peca }])
    setPecaSelecionada({});
    resetMedidas();
    resetDefeitoLocalTamanho();
  }

  const handleEditAnalisadas = (item, index) => {
    setMedidas({
      metragem_fornecedor: item.metragem_fornecedor,
      relogio: item.relogio,
      largura_fornecedor: item.largura_fornecedor,
      largura_util: item.largura_util,
      ourela: item.ourela,
      index
    });
    setPecaSelecionada(item);
    setShowAnalisarAprovar(true);
  };

  const handleDeleteDefeitos = (item) => {
    setDefeitosIncluidos((defeitosIncluidos) => {
      const novosDefeitos = defeitosIncluidos.filter(defeito => defeito !== item);
      const aindaExistePeca = novosDefeitos.some(defeito => defeito.Peca === item.Peca);
      if (!aindaExistePeca) {
        setPecasAnalisadas(pecasAnalisadas.filter(peca => peca.peca !== item.Peca));
        setPecas([...pecas, { ordem_servico: item.OrdemServico, peca: item.Peca }])
      }
      return novosDefeitos;
    });
  }

  const handleDeleteDefeitosModal = (item) => {
    setDefeitosModal(defeitosModal.filter(defeito => defeito.peca !== item.peca))
  }

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setFiltroBusca({
      nf: '',
      material: '',
      cor_material: '',
      pedido: '',
      op: '',
      os: ''
    });
    setBuscaEscolhida([]);
  }, [acaoSelecionada]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <h3 style={{ 'margin': '20px' }}>Revisão - 100% / Retorno</h3>

      <article className={styles.headerWrapper}>

        <article className={styles.actionWrapper}>
          <Form className={styles.form}>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Ação</Form.Label>
              <Select
                closeMenuOnSelect={true}
                backspaceRemovesValue={true}
                placeholder={'Selecionar'}
                value={acaoSelecionada}
                options={selecionarAcao}
                onChange={(e) => setAcaoSelecionada(e)}
              />
            </Form.Group>
          </Form>
        </article>

        <article className={styles.formWrapper}>

          {/* ACAO === 'cemPorcento' */}
          <div
            style={{ display: acaoSelecionada?.value === 'cemPorcento' ? 'inline' : 'none' }}
          >
            <Form className={styles.form}>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Nota Fiscal*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nota Fiscal"
                  value={filtroBusca.nf}
                  onChange={(e) => filtroHandler(e, 'nf')}
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Material*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Material"
                  value={filtroBusca.material}
                  onChange={(e) => filtroHandler(e, 'material')}
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Cor Material*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Cor Material"
                  value={filtroBusca.cor_material}
                  onChange={(e) => filtroHandler(e, 'cor_material')}
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Pedido*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pedido"
                  value={filtroBusca.pedido}
                  onChange={(e) => filtroHandler(e, 'pedido')}
                />
              </Form.Group>
              <Button
                className={styles.formGroup}
                variant="primary"
                type="button"
                onClick={handleSearch}
              >
                Buscar
              </Button>
            </Form>
          </div>

          {/* ACAO === 'retorno' */}
          <div
            style={{ display: acaoSelecionada?.value !== 'cemPorcento' ? 'inline' : 'none' }}
          >
            <Form className={styles.form}>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Ordem de Produção*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ordem de Produção"
                  value={filtroBusca.op}
                  onChange={(e) => filtroHandler(e, 'op')}
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Ordem de Serviço*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ordem de Serviço"
                  value={filtroBusca.os}
                  onChange={(e) => filtroHandler(e, 'os')}
                />
              </Form.Group>
              <Button
                className={styles.formGroup}
                variant="primary"
                type="button"
                onClick={handleSearch}
              >
                Buscar
              </Button>
            </Form>
          </div>
        </article>
      </article>

      <article className={styles.bodyWrapper}>
        {/* ACAO === 'cemPorcento' */}
        <div
          style={{ display: acaoSelecionada?.value === 'cemPorcento' ? 'inline' : 'none' }}
        >
          <article className={styles.articleGrayAlt}>
            <Table responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>FORNECEDOR</th>
                  <th className={styles.blockTitle}>NF ENTRADA</th>
                  <th className={styles.blockTitle}>PEDIDO</th>
                  <th className={styles.blockTitle}>MATERIAL</th>
                  <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                  <th className={styles.blockTitle}>COR MATERIAL</th>
                  <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                  <th className={styles.blockTitle}>DATA REVISÃO</th>
                  <th className={styles.blockTitle}>ENTRADA REVISÃO POR</th>
                  <th className={styles.blockTitle}>QTDE PEÇAS AGENDADAS</th>
                </tr>
              </thead>
              <tbody>
                {acaoSelecionada?.value === 'cemPorcento' ?
                  buscaEscolhida.map((item, index) => {
                    return (
                      <tr className={styles.tableLines} key={index}>
                        <td className={styles.tableLines}>
                          <Button
                            variant='warning'
                            onClick={() => handleShowRevisao(item)}
                          >
                            Revisão
                          </Button>
                        </td>
                        <td className={styles.tableLines}>{item.fornecedor}</td>
                        <td className={styles.tableLines}>{item.nf_entrada}</td>
                        <td className={styles.tableLines}>{item.pedido}</td>
                        <td className={styles.tableLines}>{item.material}</td>
                        <td className={styles.tableLines}>{item.desc_material}</td>
                        <td className={styles.tableLines}>{item.cor_material}</td>
                        <td className={styles.tableLines}>{item.desc_cor}</td>
                        <td className={styles.tableLines}>{formatarDataBR(item.data_revisao)}</td>
                        <td className={styles.tableLines}>{item.usuario_revisao}</td>
                        <td className={styles.tableLines}>{item.qtde_pecas}</td>
                      </tr>
                    )
                  }) : null
                }
              </tbody>
            </Table>
          </article>
        </div>

        {/* ACAO <> 'cemPorcento' */}
        {/* Só deve mostrar dados após busca */}
        <div
          style={{ display: acaoSelecionada?.value !== 'cemPorcento' ? 'inline' : 'none' }}
        >
          <article className={styles.articleGrayAlt}>
            <Table responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>ORDEM DE PRODUÇÃO</th>
                  <th className={styles.blockTitle}>ORDEM DE SERVIÇO</th>
                  <th className={styles.blockTitle}>FORNECEDOR</th>
                  <th className={styles.blockTitle}>MARCA</th>
                  <th className={styles.blockTitle}>PRODUTO</th>
                  <th className={styles.blockTitle}>DESCRIÇÃO PRODUTO</th>
                  <th className={styles.blockTitle}>QTDE PEÇAS</th>
                </tr>
              </thead>
              <tbody>
                {acaoSelecionada?.value === 'retorno' ?
                  buscaEscolhida.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.tableLines}>
                          <Button
                            variant='warning'
                            onClick={() => handleShowRevisao(item)}
                          >
                            Revisão
                          </Button>
                        </td>
                        <td className={styles.tableLines}>{item.ordem_producao}</td>
                        <td className={styles.tableLines}>{item.ordem_servico}</td>
                        <td className={styles.tableLines}>{item.fornecedor}</td>
                        <td className={styles.tableLines}>{item.marca}</td>
                        <td className={styles.tableLines}>{item.produto}</td>
                        <td className={styles.tableLines}>{item.desc_produto}</td>
                        <td className={styles.tableLines}>{item.qtde_pecas}</td>
                      </tr>
                    )
                  }) : null
                }
              </tbody>
            </Table>
          </article>
        </div>
      </article>

      {/* Modal Revisão - 100% / Retorno */}

      <Modal
        show={showModalRevisao}
        onHide={handleCloseRevisao}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen
      >
        <Modal.Header>
          {
            acaoSelecionada.value === 'cemPorcento' ?
              <Modal.Title>Revisao - 100%</Modal.Title>
              :
              <Modal.Title>Revisao - Retorno</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <article>
            {acaoSelecionada?.value === 'cemPorcento' && buscaEscolhida.length > 0 &&
              <DadosAccordion
                cabecalho={cabecalhoBusca}
                values={buscaValues()}
              />
            }
            {acaoSelecionada?.value === 'retorno' && buscaEscolhida.length > 0 &&
              <DadosAccordion
                cabecalho={cabecalhoBusca}
                values={buscaValues()}
              />
            }
          </article>

          <div className={styles.tablesOneRow}>
      <Table responsive style={{ minWidth: '500px' }}>
        <thead>
          <tr>
            <th className={`${styles.blockTitle} ${styles.centerText}`} colSpan={5}>
              <strong>SELECIONE A PEÇA</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: Math.ceil(pecas.length / 5) }).map((_, rowIndex) => (
            <tr key={`row-${rowIndex}`}>
              {pecas.slice(rowIndex * 5, rowIndex * 5 + 5).map((item, colIndex) => (
                <td key={`col-${rowIndex * 5 + colIndex}`} className={styles.tableLines}>
                  <Form>
                    <Form.Check
                      type='radio'
                      onClick={handleShowAnalisarAprovar}
                      onChange={(e) => handleCheckPeca(e, item)}
                      checked={pecaSelecionada === item}
                      onKeyDown={handleKeyDown}
                    />
                  </Form>
                  {item.peca}                
                </td>
              ))}
              {Array.from({ length: 5 - pecas.slice(rowIndex * 5, rowIndex * 5 + 5).length }).map((_, emptyIndex) => (
                <td key={`empty-${rowIndex * 5 + emptyIndex}`} className={styles.tableLines}></td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>

          <article>
            <Table style={{ marginBottom: "50px" }} responsive>
              <thead>
                <tr>
                  <th>
                    <h5>Peças Analisadas</h5>
                  </th>
                </tr>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>Peça</th>
                  <th className={styles.blockTitle}>Metragem Fornecedor</th>
                  <th className={styles.blockTitle}>Relógio</th>
                  <th className={styles.blockTitle}>Largura Util</th>
                  <th className={styles.blockTitle}>Largura Fornecedor</th>
                  <th className={styles.blockTitle}>Ourela</th>
                </tr>
              </thead>
              <tbody>
                {pecasAnalisadas.map((item, index) => {
                  return (
                    <tr className={styles.tableLines} key={index}>
                      <td className={styles.tableLines}>
                        <Button
                          style={{ backgroundColor: 'white', border: 'white' }}
                          onClick={() => handleDeleteAnalisadas(item)}
                        >
                          <MaterialIcon
                            icon="delete"
                            size={25}
                            color='#BB271A'
                          />
                        </Button> 
                        <Button
                          style={{ backgroundColor: 'white', border: 'white' }}
                          onClick={() => handleEditAnalisadas(item, index)}
                        >
                          <MaterialIcon
                            icon="edit"
                            size={25}
                            color='#BB271A'
                          />
                        </Button>                      
                      </td>
                      <td className={styles.tableLines}>{item.peca}</td>
                      <td className={styles.tableLines}>{item.metragem_fornecedor} cm</td>
                      <td className={styles.tableLines}>{item.relogio} cm</td>
                      <td className={styles.tableLines}>{item.largura_util} cm</td>
                      <td className={styles.tableLines}>{item.largura_fornecedor} cm</td>
                      <td className={styles.tableLines}>{item.ourela} cm</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <h5>Defeitos Incluídos</h5>
                  </th>
                </tr>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>Peça</th>
                  <th className={styles.blockTitle}>Defeito</th>
                  <th className={styles.blockTitle}>Localização</th>
                  <th className={styles.blockTitle}>Tamanho</th>
                  <th className={styles.blockTitle}>Pontuação</th>
                  <th className={styles.blockTitle}>Considerações</th>
                </tr>
              </thead>
              <tbody>
                {defeitosIncluidos.map((item, index) => {
                  return (
                    <tr className={styles.tableLines} key={index}>
                      <td className={styles.tableLines}>
                        <Button
                          style={{ backgroundColor: 'white', border: 'white' }}
                          onClick={() => handleDeleteDefeitos(item)}
                        >
                          <MaterialIcon
                            icon="delete"
                            size={25}
                            color='#BB271A'
                          />
                        </Button>
                      </td>
                      <td className={styles.tableLines}>{item.Peca}</td>
                      <td className={styles.tableLines}>{item.defeito}</td>
                      <td className={styles.tableLines}>{item.localizacao}</td>
                      <td className={styles.tableLines}>{item.tamanho}</td>
                      <td className={styles.tableLines}>{item.Pontuacao}</td>
                      <td className={styles.tableLines}>{item.Consideracao}</td>
                    </tr>
                  )
                })}

              </tbody>
            </Table>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseRevisao}
            className={styles.modalBtn}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            className={styles.modalBtn}
            onClick={handleProcessar}
            disabled={showLoading}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAnalisarAprovar}
        onHide={handleCloseAnalisarAprovar}
        backdrop="static"
        keyboard={false}
        centered
        size='sm'
      >
        <Modal.Header>
          {pecas.length > 0 &&
            <h6>Peça: {pecaSelecionada.peca} | Metragem Fornecedor: {pecaSelecionada.metragem_fornecedor}</h6>
          }
        </Modal.Header>
        <Modal.Body>
        <Form className={styles.formModal}>
        <Form.Group className={styles.formGroupModal}>
          <Form.Control
            type="text"
            placeholder="Metragem Fornecedor"
            value={medidas.metragem_fornecedor}
            onChange={(e) => medidasHandler(e, 'metragem_fornecedor')}
            onKeyDown={handleKeyDown}
          />
        </Form.Group>

        <Form.Group className={`${styles.formButtonFlex}`}>
          <Form.Control
            type="text"
            placeholder="Relógio"
            value={medidas.relogio}
            onChange={(e) => medidasHandler(e, 'relogio')}
            className={styles.formControl}
            onKeyDown={handleKeyDown}
          />
          <Button
            variant="outline-primary"
            onClick={() => handleButtonClick('relogio')}
            className={styles.formButton}
          >
          </Button>
        </Form.Group>

        <Form.Group className={styles.formGroupModal}>
          <Form.Control
            type="text"
            placeholder="Largura Fornecedor"
            value={medidas.largura_fornecedor}
            onChange={(e) => medidasHandler(e, 'largura_fornecedor')}
            onKeyDown={handleKeyDown}
          />
        </Form.Group>

        <Form.Group className={`${styles.formButtonFlex}`}>
          <Form.Control
            type="text"
            placeholder="Largura Encontrada"
            value={medidas.largura_util}
            onChange={(e) => medidasHandler(e, 'largura_util')}
            className={styles.formControl}
            onKeyDown={handleKeyDown}
          />
          <Button
            variant="outline-primary"
            onClick={() => handleButtonClick('largura_util')}
            className={styles.formButton}
          >
          </Button>
        </Form.Group>

        <Form.Group className={styles.formGroupModal}>
          <Form.Control
            type="text"
            placeholder="Ourela"
            value={medidas.ourela}
            onChange={(e) => medidasHandler(e, 'ourela')}
            onKeyDown={handleKeyDown}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>

          <Button
            variant="outline-primary"
            className={styles.modalBtn}
            onClick={handleAprovar}
          >
            Aprovar
          </Button>
          <Button
            variant="outline-secondary"
            className={styles.modalBtn}
            onClick={handleAnalisar}
          >
            Analisar
          </Button>

          <Button
            variant="outline-danger"
            className={styles.modalBtn}
            onClick={handleCloseAnalisarAprovar}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAnalisar}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen
      >
        <Modal.Header>
          {pecas.length > 0 &&
            <Modal.Title>Peça: {pecaSelecionada.peca}</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <article className={styles.incTableInsertWrapper}>
            <div className={styles.tablesRow}>
              <div className={styles.colOverflow}>

                <Table responsive>
                  <thead>
                    <tr>
                      <th className={styles.blockTitle}>MEDIDAS:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={styles.tableLines}>
                      <td className={styles.tableLines}>Metragem Fornecedor</td>
                      <td className={styles.tableLines}>
                        <Form>
                          <Form.Control
                            type='text'
                            disabled
                            value={pecaEmAnalise.metragem_fornecedor + ' cm'}
                          />
                        </Form>
                      </td>
                    </tr>
                    <tr className={styles.tableLines}>
                      <td className={styles.tableLines}>Relógio</td>
                      <td className={styles.tableLines}>
                        <Form>
                          <Form.Control
                            type='text'
                            disabled
                            value={pecaEmAnalise.relogio + ' cm'}
                          />
                        </Form>
                      </td>
                    </tr>
                    <tr className={styles.tableLines}>
                      <td className={styles.tableLines}>Largura Fornecedor</td>
                      <td className={styles.tableLines}>
                        <Form>
                          <Form.Control
                            type='text'
                            disabled
                            value={pecaEmAnalise.largura_fornecedor + ' cm'}
                          />
                        </Form>
                      </td>
                    </tr>
                    <tr className={styles.tableLines}>
                      <td className={styles.tableLines}>Largura Util</td>
                      <td className={styles.tableLines}>
                        <Form>
                          <Form.Control
                            type='text'
                            disabled
                            value={pecaEmAnalise.largura_util + ' cm'}
                          />
                        </Form>
                      </td>
                    </tr>
                    <tr className={styles.tableLines}>
                      <td className={styles.tableLines}>Ourela</td>
                      <td className={styles.tableLines}>
                        <Form>
                          <Form.Control
                            type='text'
                            disabled
                            value={pecaEmAnalise.ourela + ' cm'}
                          />
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className={styles.colOverflow}>
                <Table responsive>
                  <thead className={styles.thead}>
                    <tr>
                      <th className={styles.blockTitle}></th>
                      <th className={styles.blockTitle}>SELECIONE O DEFEITO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      defeitos.map((item, index) => {
                        return (
                          <tr className={styles.tableLines} key={index}>
                            <td className={styles.tableLines}>
                              <Form>
                                <Form.Check
                                  type='radio'
                                  onChange={(e) => handleCheckDefeito(e, item)}
                                  checked={defeitoSelecionado === item}
                                />
                              </Form>
                            </td>
                            <td className={styles.tableLines}>{item.nome_defeito}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </div>

              <div className={styles.colOverflow}>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className={styles.blockTitle}></th>
                      <th className={styles.blockTitle}>SELECIONE O LOCAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {local.map((item, index) => {
                      return (
                        <tr className={styles.tableLines} key={index}>
                          <td className={styles.tableLines}>
                            <Form>
                              <Form.Check
                                type='radio'
                                onChange={(e) => handleCheckLocal(e, item)}
                                checked={localSelecionado === item}
                              />
                            </Form>
                          </td>
                          <td className={styles.tableLines}>{item.local_defeito}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>

              <div className={styles.colOverflow}>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className={styles.blockTitle}></th>
                      <th className={styles.blockTitle}>SELECIONE O TAMANHO (PONTUAÇÃO)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tamanhoPontuacao.map((item, index) => {
                      return (
                        <tr className={styles.tableLines} key={index}>
                          <td className={styles.tableLines}>
                            <Form>
                              <Form.Check
                                type='radio'
                                onChange={(e) => handleCheckTamanhoPontuacao(e, item)}
                                checked={tamanhoPontuacaoSelecionada === item}
                              />
                            </Form>
                          </td>
                          <td className={styles.tableLines}>DE {item.minimo}cm A {item.maximo}cm ({item.pontuacao})</td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td className={styles.tableLines}>
                        <Form>
                          <Form.Check
                            type='checkbox'
                            onChange={(e) => handleCheckCondenar(e)}
                            checked={condenar}
                          />
                        </Form>
                      </td>
                      <td className={styles.tableLines}>Condenar</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div style={{ height: '335px' }} >
                <p className={styles.blockTitle} style={{ margin: '10px 0 0 10px' }}>Considerações:</p>
                <Form>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      placeholder="(Não Obrigatório)"
                      className={styles.formGroupTextarea}
                      value={consideracao}
                      onChange={e => setConsideracao(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </article>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <h5>Defeitos Incluídos</h5>
                </th>
              </tr>
              <tr>
                <th className={styles.blockTitle}></th>
                <th className={styles.blockTitle}>Peça</th>
                <th className={styles.blockTitle}>Defeito</th>
                <th className={styles.blockTitle}>Localização</th>
                <th className={styles.blockTitle}>Tamanho</th>
                <th className={styles.blockTitle}>Pontuação</th>
                <th className={styles.blockTitle}>Considerações</th>
              </tr>
            </thead>
            <tbody>
              {defeitosModal.map((item, index) => {
                return (
                  <tr className={styles.tableLines} key={index}>
                    <td className={styles.tableLines}>
                      <Button
                        style={{ backgroundColor: 'white', border: 'white' }}
                        onClick={() => handleDeleteDefeitosModal(item)}
                      >
                        <MaterialIcon
                          icon="delete"
                          size={25}
                          color='#BB271A'
                        />
                      </Button>
                    </td>
                    <td className={styles.tableLines}>{item.Peca}</td>
                    <td className={styles.tableLines}>{item.defeito}</td>
                    <td className={styles.tableLines}>{item.localizacao}</td>
                    <td className={styles.tableLines}>{item.tamanho}</td>
                    <td className={styles.tableLines}>{item.Pontuacao}</td>
                    <td className={styles.tableLines}>{item.Consideracao}</td>
                  </tr>
                )
              })}

            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter} style={{ display: 'flex', flexDirection: 'column' }}>

          <div>
            <Button
              variant="outline-primary"
              className={styles.modalBtn}
              onClick={() => handleIncluirDefeito(pecaEmAnalise)}
            >
              Incluir Defeito
            </Button>
            <Button
              variant='success'
              className={styles.buttonsFinalizar}
              onClick={() => handleFinalizarPeca(pecaSelecionada)}
            >
              Finalizar Peça
            </Button>
          </div>
          <div>
            <Button
              variant="outline-danger"
              className={styles.modalBtn}
              onClick={handleCloseAnalisar}
              style={{ marginTop: '15px' }}
            >
              Voltar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default RevisaoCemPorcentoRetorno;