import React, { useState } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import styles from '../../../../styles/ADM/Desconto/EditarPendenciar.module.scss';
import { api }  from "../../../../services/api";
import CookiesService from "../../../../services/cookies";
import { toDatetime } from "../../../../utils/dateUtils";

const ModalStatus = ({ statusData, showStatus, handleCloseStatus, motivoSelecionado, handleResetEditar }) => {

    const [novoStatus, setNovoStatus] = useState(null)

    const handleAtualizaStatus = async () => {
        if (motivoSelecionado.IdStatusValidacao === novoStatus){
            toast.warning('Novo status não pode ser igual ao anterior.'); 
            return
        } else if (novoStatus === null){
            toast.warning('É necessário escolher um status.'); 
            return
        }

        const params = { update: {
                            nf_entrada: motivoSelecionado.NfEntrada,
                            material: motivoSelecionado.Material,
                            cor_material: motivoSelecionado.CorMaterial,
                            item: motivoSelecionado.Item,
                            id_motivo: motivoSelecionado.IdMotivoEnvio, 
                            status_novo: novoStatus
                        }, 
                         insert: {
                            NfEntrada: motivoSelecionado.NfEntrada,
                            Material: motivoSelecionado.Material,
                            CorMaterial: motivoSelecionado.CorMaterial,
                            Item: motivoSelecionado.Item,
                            IdMotivoEnvio: motivoSelecionado.IdMotivoEnvio, 
                            StatusAnterior: motivoSelecionado.IdStatusValidacao,
                            StatusNovo: novoStatus,
                            IdTipoProcesso: 1,
                            Usuario: CookiesService.getUserLogin(),
                            DataProcesso: toDatetime(new Date())
                        } 
        }

        await api.post('ADM/Desconto/EditarPendenciar/atualiza-status', { params })
            .then(res => {
                if (res.status === 200) { 
                    toast.success('Dado atualizado com sucesso!'); 
                    handleResetEditar();
                } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
            }).catch(e => {
                toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
                console.error(`Erro na requisição - ${e}`);
        });  
    }

    return (
        <Modal
            show={showStatus}
            onHide={handleCloseStatus}
            backdrop="static"
            keyboard={false}
            centered
            size='lg'
            >
            <Modal.Header>
                <Modal.Title>Editar Motivo - {motivoSelecionado && motivoSelecionado.DescMotivoEnvio}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className={styles.form}>
                    <Form.Group className={styles.formGroupDown}>
                        <Form.Label><em>Novo Status</em></Form.Label>
                        <Select 
                            closeMenuOnSelect={true}
                            options={statusData}
                            onChange = {e=>(setNovoStatus(e.value))}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseStatus}
                >
                    Voltar
                </Button>
                <Button
                    variant="outline-success"
                    className={styles.modalBtn}
                    onClick={handleAtualizaStatus}
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalStatus;
