import React, { useState } from 'react';
import { Button, Table, Form, Modal } from 'react-bootstrap';
import Carregando from '../../../components/Carregando';
import styles from './gerar_laudo.module.scss';
import { usePAGerarLaudoContext } from './gerar_laudo_context';
import Tabela from '../../Misc/Tabela/Tabela';
import FiltroGerarLaudoPA from './componentes/gerar_laudo_filtro';

const PAGerarLaudo = () => {
  const { listaPrincipal, selecionarLinhas, clearSelectedRows } = usePAGerarLaudoContext();

  const laudoColumns = [
    { name: 'Enviado', selector: row => row.enviado },
    { name: 'Status CQ', selector: row => row.status_cq },
    { name: 'NF Entrada', selector: row => row.nf_entrada },
    { name: 'Série NF', selector: row => row.serie_nf },
    { name: 'Produto', selector: row => row.produto },
    { name: 'Cor Produto', selector: row => row.cor_produto },
    { name: 'Qtde. Entrada', selector: row => row.qtde_entrada },
    { name: 'Filial Revisão', selector: row => row.filial_revisao },
    { name: 'Origem', selector: row => row.origem },
    { name: 'Data Análise', selector: row => row.data_analise },
    { name: 'Linha', selector: row => row.linha }
  ];

	return (
		<div>
      <section className={styles.flex_title}>
        <h4 className={styles.title}>
          Gerar Laudo PA
        </h4>
      </section>
      <section className={styles.header_section}>
        <FiltroGerarLaudoPA />
      </section>

      <section style={{ margin: '0px 20px 20px' }}>
        <Tabela
          columns={laudoColumns}
          data={listaPrincipal}
          pending={false}
          paginationPerPage={20}
          highlightOnHover={true}
          selectableRows={true}
          clearSelectedRows={clearSelectedRows}
          onSelectedRowsChange={selecionarLinhas}
        />
      </section>
		</div>
	);
};

export default PAGerarLaudo;