import { Button } from "react-bootstrap";

function ImprimirEtiquetas(params) {
  const downloadTxtFile = () => {
    if (params.etiquetas.length === 0) return;

    let etiquetas = params.etiquetas;

    for (let i = 0; i < etiquetas.length; i++) {
      let contentFile = `L
SK
PG
D11
H20
N
132400103200020${etiquetas[i]["LOCALIZACAO"]}
192200103400230Cad:
192200103400300${etiquetas[i]["DATA_CADASTRO"]}
192200103200300${etiquetas[i]["ARARA"]}
192200103200230Arara:
192200103000300${etiquetas[i]["ANDAR"]}
192200103000230Andar:
192200102750300${etiquetas[i]["LADO"]}
192200102750230Lado:
1E6205002500015${etiquetas[i]["LOCALIZACAO"]},
192200201700020Local: ${etiquetas[i]["SALA_AMOSTRA"]}
192200202300020Material/Cor: ${etiquetas[i]["MATERIAL"]}/${etiquetas[i]["COR_MATERIAL"]}
192200102100020Fornecedor: ${etiquetas[i]["FORNECEDOR"]}
192200101400020Material:
192200201400100${etiquetas[i]["MATERIAL"]}
192200101200020Desc:${etiquetas[i]["DESC_MATERIAL"]}
192200101000100${etiquetas[i]["COR_MATERIAL"]}${etiquetas[i]["DESC_COR_MATERIAL"]}
192200101000020Cor:
192200100800020Colecao: ${etiquetas[i]["COLECAO"]}
1X2200100500010B380120002002
1X2200100100010L380030002002
192200100600020Usuario: ${etiquetas[i]["USUARIO_CADASTRO"]}
192200100150020${etiquetas[i]["TEXTO"]}
Q0001
E
`;

      let idCadastro = etiquetas[i]["ID_CADASTRO"];

      const element = document.createElement("a");
      const file = new Blob([contentFile], {
        type: "text/plain",
        endings: "native",
      });
      element.href = URL.createObjectURL(file);
      element.download = `${idCadastro.replaceAll(".", "")}-aflex-qld.txt`;
      document.body.appendChild(element);
      element.click();
    }
  };

  return <Button onClick={downloadTxtFile}>Imprimir Etiquetas</Button>;
}

export default ImprimirEtiquetas;
