import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import styles from '../styles/Corte.module.scss';
import { api }  from "../services/api";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

const optRecCorte = [
  "Aleatório",
  "Aleatório II",
  "Aleatório III",
  "Barrado",
  "Barrado II",
  "Barrado Duplo",
  "Localizado",
  "Localizado II",
  "Localizado III",
  "Localizado IV",
  "Engenharia",
  "Engenharia II",
]

const optRecPlanta = [
  "Automático",
  "Artesanal",
  "Farm - 551",
]

const DescontoGL = () => {

  const [lista, setLista] = useState([]);
  const [modificacoes, setModificacoes] = useState([]);
  
  const [filteredList, setFileteredList] = useState([]);

  const [os, setOS] = useState('');
  const [osField, setOSField] = useState('');

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    LoadPedidos();
  }, [])

  useEffect(() => {
    BuscarPedido();
  }, [os])

  async function LoadPedidos(){
    const prom = api.get('/corte/buscar-pedidos');
    

    toast.promise(prom, {
      pending:'Carregando...',
      error:"Houve um erro ao carregar a lista!",
      success:"Carregado!"
    })

    try{
      const {data} = await prom;
      const newLista = [...data.data].map((v,i) => {
        return {...v, ReCLASSIFICACAO:v.CLASSIFICACAO, RePLANTACORTE:v.PLANTACORTE, REENFESTOS:0, index:i}
      });
  
      setLista(newLista);
      setFileteredList(newLista);
      setModificacoes([]);
    }catch(e){

    }
  }

  function ChangeCheckbox (index) {
    const newArr = [...modificacoes];

    if(newArr.indexOf(index) === -1){
      newArr.push(index);
    }else{
      newArr.splice(newArr.indexOf(index), 1);
    }
    setModificacoes(newArr);
  }

  async function BuscarPedido(array){
    if(!os) setFileteredList(lista);

    const filter = !!array ? array.filter(v => {
      return (v.ORDEM_SERVICO.match(os)?.length > 0);
    }) : lista.filter(v => {
      return (v.ORDEM_SERVICO.match(os)?.length > 0);
    });

    setFileteredList(filter);
  }

  function SetQtdeReenfestos (index, value) {
    let object = {...lista[index]};
    object.REENFESTOS = value;

    const newLista = [...lista];
    newLista[index] = object;

    setLista(newLista);

    if(modificacoes.indexOf(index) === -1){
      const newMod = [...modificacoes];
      newMod.push(index);
      setModificacoes(newMod);
    }
  }

  function SetReClassificaçãoCorte (index, value) {
    let object = {...lista[index]};
    object.ReCLASSIFICACAO = value

    const newLista = [...lista];
    newLista[index] = object;
    
    setLista(newLista);
    BuscarPedido(newLista);

    if(modificacoes.indexOf(index) === -1){
      const newMod = [...modificacoes];
      newMod.push(index);
      setModificacoes(newMod);
    }
  }

  function SetReClassificaçãoPlanta (index, value) {
    let object = {...lista[index]};
    object.RePLANTACORTE = value

    const newLista = [...lista]
    newLista[index] = object;
    
    setLista(newLista);
    BuscarPedido(newLista);

    if(modificacoes.indexOf(index) === -1){
      const newMod = [...modificacoes];
      newMod.push(index);
      setModificacoes(newMod);
    }
  }

  async function Save () {
    setSaving(true);

    const array = lista.filter(v => {
      return (modificacoes.indexOf(v.index) !== -1)
    })

    try{
      const prom = api.post('/corte/salvar', {data:array});

      toast.promise(prom, {
        pending:'Salvando...',
        error:"Houve um erro ao salvar!",
        success:"Salvo!"
      });

      await prom;
      setModificacoes([]);
    }catch(e){}
    window.location.reload();
  }

  return (
    <div className="mx-4">
      <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      <div className="mt-5">
        <h3 className="">Corte</h3>
      </div>
      <div className={styles['bg-lightgray-filtro']}>
        <div className="d-flex">
          <div className="d-flex flex-column">
            <Button onClick={() => setOS(osField)} size="sm">Filtrar</Button>
          </div>
          <div className="ms-2 d-flex flex-column">
            <Form.Control onChange={e => setOSField(e.target.value.replace(/[^0-9]/g, ''))} value={osField} placeholder={"OS"} style={{width:150}} size="sm"/>
          </div>
          <div className={"d-flex flex-column " + (osField || os ? '' : 'opacity-0')}>
            <Button className="ms-2" variant="outline-primary" onClick={() => {setOS(''); setOSField('')}} size="sm">X</Button>
          </div>
        </div>
        {/* <div className="d-flex">
          <div className="d-flex flex-column">
            <Button size="sm" onClick={OpenHistoricoModal}>Histórico</Button>
          </div>
        </div> */}
      </div>
      <div className={styles['bg-lightgray-table']}>
        <div className="overflow-auto" style={{maxHeight:600, height:600}}>
          <Table className="w-100 text-nowrap">
            <thead className={styles['sticky-th']}>
              <tr>
                <th className={styles['spaced-td']}></th>
                <th className={styles['spaced-td']}>OS</th>
                <th className={styles['spaced-td']}>OP</th>
                <th className={styles['spaced-td']}>Produto</th>
                <th className={styles['spaced-td']}>Classificação</th>
                <th className={styles['spaced-td']}>Planta de Corte</th>
                <th className={styles['spaced-td']}>Grade</th>
                <th className={styles['spaced-td']}>Qtde Localizações</th>
                <th className={styles['spaced-td']}>Data</th>
                <th className={styles['spaced-td']}>Qtde Reenfestos</th>
                <th className={styles['spaced-td']}>ReClassificação Corte</th>
                <th className={styles['spaced-td']}>ReClassificação Planta</th>
              </tr>
            </thead>
            <tbody>
              {
                filteredList.map((v, i) => {
                  return <Row
                          Buscapedido={BuscarPedido}
                          onCheckboxChange={ChangeCheckbox}
                          check={modificacoes.indexOf(v.index) !== -1}
                          key={i}
                          obj={v}
                          index={i}
                          SetQtdeReenfestos={SetQtdeReenfestos}
                          SetReClassificaçãoCorte={SetReClassificaçãoCorte}
                          SetReClassificaçãoPlanta={SetReClassificaçãoPlanta}/>
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Button disabled={!!saving} onClick={Save}>Salvar</Button>
      </div>
    </div>
  );
};

function Row (props) {

  const onChangeQtdeReenfestos = (e) => {
    props.SetQtdeReenfestos(props.obj.index, e.target.innerText);
  }

  const onChangeReClassificaçãoCorte = (e) => {
    props.SetReClassificaçãoCorte(props.obj.index, e.target.value);
  }

  const onChangeReClassificaçãoPlanta = (e) => {
    props.SetReClassificaçãoPlanta(props.obj.index, e.target.value);
  }
  
  useEffect(() => {
    document.getElementById("reenfestos_"+props.obj.index)?.addEventListener('focusout', (ev) => {
      props.BuscaPedido();
    });
  }, [])

  return (
    <tr key={props.obj.index}>
      <td><Form.Check checked={props.check} onChange={() => props.onCheckboxChange(props.obj.index)}/></td>
      <td>{props.obj.ORDEM_SERVICO.trim()}</td>
      <td>{props.obj.ORDEM_PRODUCAO.trim()}</td>
      <td>{props.obj.PRODUTO}</td>
      <td>{props.obj.CLASSIFICACAO}</td>
      <td>{props.obj.PLANTACORTE}</td>
      <td>{props.obj.GRADE_PLAN}</td>
      <td>{props.obj.QUANTIDADE_LOCALIZACOES}</td>
      <td>{props.obj.DATA.split('T')[0].split('-').reverse().toString().replace(/\,/g,'/')}</td>
      <td id={"reenfestos_"+props.obj.index} onInput={onChangeQtdeReenfestos} contentEditable className={styles['fieldYellow']}>{props.obj.REENFESTOS}</td>
      <td className={styles['fieldYellow']}>
        <Form.Select value={props.obj.ReCLASSIFICACAO} onChange={onChangeReClassificaçãoCorte} size="sm" className={styles['selectYellow']}>
          {
            optRecCorte.map((v, i) => {
              return <option key={i} value={v}>{v}</option>
            })
          }
        </Form.Select>
      </td>
      <td className={styles['fieldYellow']}>
        <Form.Select value={props.obj.RePLANTACORTE} onChange={onChangeReClassificaçãoPlanta} size="sm" className={styles['selectYellow']}>
          {
            optRecPlanta.map((v,i) => {
              return <option key={i} value={v}>{v}</option>
            })
          }
        </Form.Select>
      </td>
    </tr>
  )

}

export default DescontoGL;