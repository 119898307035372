import React from "react";
import Desconto from "../components/Desconto/index";

import styles from '../styles/Desconto.module.scss';

const DescontoGL = () => {
    return (
        <div className="p-3">
          <div>
            <h3>Acompanhamento Descontos</h3>
            <h5>Formulário Inserção Desconto MP</h5>
          </div>
          <Desconto />
        </div>
    );
};

export default DescontoGL;