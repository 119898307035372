import React,{ useState } from "react";
import { ToastContainer } from "react-toastify";

import styles from '../styles/PlanejamentoDiarioCorte.module.scss';
import { Filtros } from "../components/PlanejamentoDiarioCorte/Filtros";
import Agendamento from '../components/PlanejamentoDiarioCorte/Agendamentos'
import CheckoutModalDiarioCorte from '../components/PlanejamentoDiarioCorte/CheckoutModalDiarioCorte'
import TableDiarioCorte from '../components/PlanejamentoDiarioCorte/TableDiarioCorte'
import useDiarioCorte from '../hooks/useDiarioCorte'
import Carregando from "../components/Carregando";

const PlanejamentoDiarioCorte = () => {
  const { 
    data, 
    loading, 
    buscar, 
    agendamentos, 
    filtrado,
    handleAgendamentos, 
    somaGradePlansPorData,
    criarCSV,
    processarFiltro,
    definirTipoCorte,
    limpaEsteFiltro,
    limpaFiltros,
    limparSelecionado,
    atualizarBD,
    filtroSelecionado,
    selecionarFiltro,
    fazerAgendamentos
  } = useDiarioCorte();

  const [showModal, setShowModal] = useState(false);

  const DIARIO_CORTES = filtrado && filtrado.diarioCortes.length > 0 ? filtrado : data

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    limparSelecionado()
    setShowModal(false);
  };

  return (
    <div>
      <h3  style={{ 'margin': '20px' }}>Planejamento Diário</h3>
      <article className={styles.headerWrapper}>
        <div>
          <Filtros handleBuscar={buscar} limpaFiltros={limpaFiltros} />
        </div>
      </article>
      <CheckoutModalDiarioCorte 
        show={showModal} 
        onHide={handleCloseModal} 
        data={data}
        autolimpar={!!filtrado}
        limparFn={limpaFiltros}
        limpaFiltroFn={limpaEsteFiltro}
        nomeFiltro={filtroSelecionado.nome}
        processarFiltro={processarFiltro}
        filtros={filtroSelecionado.estado.values && filtroSelecionado.estado.values}
      />
      <article className={styles.headerWrapper}>
        <Agendamento 
         data={agendamentos} 
         somaGradePlansPorData={somaGradePlansPorData}
         handleSalvar={fazerAgendamentos}
         atualizarDados={atualizarBD}
         obterExcel={criarCSV}
        />
      </article>

      <article className={styles.articleGrayAlt} >
        {loading ? <Carregando /> : 
        (
          <TableDiarioCorte 
           diariosCortes={DIARIO_CORTES} 
           agendamentos={agendamentos} 
           handleAgendamentos={handleAgendamentos} 
           definirTipoCorte={definirTipoCorte}
           objetoSelecionado={(prop, value) => selecionarFiltro(prop, value, handleShowModal)}
           /> 
         )
        }
      </article>
      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default PlanejamentoDiarioCorte;