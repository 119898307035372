import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import styles from '../gerar_laudo.module.scss';
import { usePAGerarLaudoContext } from '../gerar_laudo_context';

const FiltroGerarLaudoPA = () => {
  const { filterObj, setFilterObj, filterErrs, setFilterErrs, filterOptions, listOptions, itensSelecionados, fetchLaudoGrid, gerarLaudos } = usePAGerarLaudoContext();
  const [today] = useState(new Date().toISOString().split('T')[0]);

  const handleValueMask = (key, value, isText = false) => {
    if (isText) return setFilterObj({ ...filterObj, [key]: value.replace(/[^.0-9]*/g, '') });
    setFilterObj({ ...filterObj, [key]: value });
  };

  const renderInput = (label, value, key, type = 'text', extraProps = {}) => (
    <Form.Group className={styles.form_input}>
      <Form.Label className={styles.filter_label}>{label}</Form.Label>
      <Form.Control
        type={type}
        value={value || ''}
        onChange={e => handleValueMask(key, e.target.value, type === 'text' ? true : false)}
        placeholder={label}
        {...extraProps}
        style={{ height: '38px', borderColor: '#CCCCCC' }}
      />
    </Form.Group>
  );

  const renderSelect = (label, value, key, options) => (
    <Form.Group className={styles.form_input}>
      <Form.Label className={styles.filter_label}>{label}</Form.Label>
      <Select
        isClearable={true}
        backspaceRemovesValue={true}
        placeholder={'Selecionar'}
        closeMenuOnSelect={true}
        value={value}
        onChange={e => setFilterObj({ ...filterObj, [key]: e })}
        options={options}
      />
    </Form.Group>
  );

  return (
    <div className={styles.filter_wrapper}>
      <Form className={styles.filter_row}>
        {renderSelect('Filtrar Status', filterObj.status, 'status', filterOptions)}
        {renderSelect('Filtrar Envio', filterObj.envio, 'envio', listOptions)}
        {renderInput('Data Início', filterObj.data_inicio, 'data_inicio', 'date', {
          max: '3000-01-01',
          onClick: () => setFilterErrs({ ...filterErrs, data_inicio: null }),
          isInvalid: filterErrs.data_inicio,
          feedback: filterErrs.data_inicio && <Form.Control.Feedback type='invalid'>{filterErrs.data_inicio}</Form.Control.Feedback>
        })}
        {renderInput('Data Fim', filterObj.data_fim, 'data_fim', 'date', {
          min: today,
          max: '3000-01-01',
          onClick: () => setFilterErrs({ ...filterErrs, data_fim: null }),
          isInvalid: filterErrs.data_fim,
          feedback: filterErrs.data_fim && <Form.Control.Feedback type='invalid'>{filterErrs.data_fim}</Form.Control.Feedback>
        })}
      </Form>

      <Form className={styles.filter_row}>
        {renderInput('NF Entrada', filterObj.nf_entrada, 'nf_entrada')}
        {renderInput('Produto', filterObj.produto, 'produto')}
        {renderInput('Cor Produto', filterObj.cor_produto, 'cor_produto')}

        <Button
          className={styles.form_btn}
          variant={'primary'}
          onClick={fetchLaudoGrid}
          style={{ height: '38px' }}
        >
          Buscar
        </Button>

        <Button
          className={styles.form_btn}
          variant={'success'}
          onClick={gerarLaudos}
          disabled={itensSelecionados.length < 1}
          style={{ height: '38px' }}
        >
          Gerar Laudos
        </Button>
      </Form>
    </div>
  );
};

export default FiltroGerarLaudoPA;
