import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';
import { toUTCDDMMYYYY } from '../../../../utils/dateUtils';

const MainTable = ({ data, pending, onSelectedRowsChange, clearSelectedRows }) => {
    const checklistColumns = [
        { name: 'ORDEM PRODUÇÃO', selector: row => row.ORDEM_PRODUCAO },
        { name: 'PRIORIZAÇÃO', selector: row => row.PRIORIZACAO, sortable: true },
        { name: 'DATA ALTERAÇÃO COR', selector: row => row.DATA_ALTERACAO_COR },
        { name: 'MARCA', selector: row => row.MARCA },
        { name: 'CANAL', selector: row => row.CANAL, sortable: true },
        { name: 'ORDEM SERVIÇO', selector: row => row.ORDEM_SERVICO },
        { name: 'PRODUTO', selector: row => row.PRODUTO },
        { name: 'QUANTIDADE', selector: row => row.QTDE },
        { name: 'LOCALIZAÇÃO', selector: row => row.LOCALIZACAO },
        { name: 'COLEÇÃO', selector: row => row.COLECAO, sortable: true },
        { name: 'DATA ALTERAÇÃO PRÓXIMA', selector: row => row.DATA_ALTERACAO_PROXIMA_COR },
        { name: 'LANÇAMENTO', selector: row => row.LANCAMENTO, sortable: true },
        { name: 'QUANTIDADE TECIDOS', selector: row => row.QTDE_TECIDOS },
        { name: 'STATUS', selector: row => row.STATUS, sortable: true },
        { name: 'PLANTA', selector: row => row.PLANTACORTE },
        { name: 'TIPO CORTE', selector: row => row.TIPOCORTE },
        { name: 'DATA CONSUMO', selector: row => toUTCDDMMYYYY(row.DATA_CONSUMO) },
        { name: 'LT', selector: row => row.LT, sortable: true },
    ];

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                title
                columns={checklistColumns}
                data={data}
                pending={pending}
                selectableRows
                onSelectedRowsChange={onSelectedRowsChange}
                clearSelectedRows={clearSelectedRows}
            />
        </section>
    );
};

export default MainTable;
