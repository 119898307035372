import React from "react";
import { Button, Modal } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/SelecaoRiscos.module.scss';

const ModalConfirmacao = ({ show, handleCloseConfirmacao, linhasSelecionadas, handleProcessar }) => {

    return (
        <Modal
            show={show}
            onHide={handleCloseConfirmacao}
            backdrop="static"
            keyboard={false}
            centered
        >
        <Modal.Header>
            <Modal.Title>Tem certeza?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <span>
                <strong>{linhasSelecionadas.length}</strong> 
                {linhasSelecionadas.length === 1 ? ' risco será enviado' : ' riscos serão enviados'} para o CAD.
            </span>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
            <Button
                    variant="success"
                    className={styles.modalBtn}
                    onClick={() => handleProcessar(linhasSelecionadas)}
                >
                    Sim
                </Button>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseConfirmacao}
                >
                    Voltar
                </Button>
        </Modal.Footer>
      </Modal>
    ) 
}

export default ModalConfirmacao;
