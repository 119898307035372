import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../services/api';
import { useMisc } from './MiscContext';
import { toast } from 'react-toastify';
import CookiesService from '../services/cookies';

const PAReversaContext = createContext();
export const usePAReversa = () => useContext(PAReversaContext);

export const PAReversaProvider = ({ children }) => {
  const { setShowLoading } = useMisc();

  const [showModal, setShowModal] = useState(false);
  const [listReversa, setListReversa] = useState([]);
  const [listReversaFiltrada, setListReversaFiltrada] = useState([]);
  const [listReversaPending, setListReversaPending] = useState(false);
  const [listaDefeitos, setListaDefeitos] = useState([]);
  const [listaDirecionamentos, setListaDirecionamentos] = useState([]);
  const [defeitosSelecionados, setDefeitosSelecionados] = useState([]);
  const [referenciaSelecionada, setReferenciaSelecionada] = useState([]);
  const [showModalBusca, setShowModalBusca] = useState({ show: false, filtro: {} });
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);
  const [romaneioFiltrado, setRomaneioFiltrado] = useState(null);

  const [filtroProduto, setFiltroProduto] = useState('');

  useEffect(() => {
    fetchReversaInicializacao();
  }, []);

  const handleFilterClick = (filtro) => {
    if (listReversa.length > 0) return setShowModalBusca({ show: true, filtro: { ...filtro } });
    fetchReversaGrid(filtro);
  }

  const fetchReversaGrid = async (filtro) => {
    setShowModalBusca({ show: false, filtro: {} });
    setShowLoading(true);
    setListReversaPending(true);
    const params = { ...filtro }
    try {
      const res = await api.get('/pa-reversa/reversa-fetch-grid', { params });

      if (res.data.length > 0 && res.data[0].jaProcessado) return toast.warning(`O romaneio ${filtro.romaneio.trim()} já foi processado.`);
      if (res.data.length === 0) return toast.warning(`O romaneio ${filtro.romaneio.trim()} não existe.`);

      res.data.forEach((item, index) => {
        res.data[index] = { ...item, index, nao_possui_defeitos: false, grade: [], direcionamento_defeitos: [] }
      });

      setRomaneioFiltrado(filtro.romaneio);
      setListReversa(res.data);
      setListReversaFiltrada(res.data);
    } catch (e) {
      toast.error('Ocorreu um erro ao preencher a listagem\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte');
    } finally {
      setShowLoading(false);
      setListReversaPending(false);
    }
  }

  const fetchReversaInicializacao = async () => {
    try {
      const res = await api.get('/pa-reversa/reversa-inicializacao');
      setListaDefeitos(res.data.resListaDefeitos);
      setListaDirecionamentos(res.data.resListaDirecionamentos);
    } catch (e) {
      toast.error('Ocorreu um erro ao inicializar a interface.\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte.');
    }
  }

  const filtrarPorProduto = (str) => {
    setFiltroProduto(str);
    setListReversaFiltrada(listReversa.filter(item => item.produto.includes(str)));
  }

  const abrirModalDefeitos = async (item) => {
    setShowLoading(true);
    try {
      if (item.grade.length === 0) {
        const { romaneio, filial, produto, cor_produto } = listReversa[item.index];
        listReversa[item.index].grade = (
          await api.get('/pa-reversa/reversa-fetch-grade-by-ref', { params: { romaneio, filial, produto, cor_produto } })
        ).data;
      }
      setReferenciaSelecionada(listReversa[item.index]);
      setShowModal(true);
    } catch (e) {
      toast.error('Encontramos um erro ao carregar as informações.\nPor favor tente novamente ou entre em contato com o suporte.');
    }
    setShowLoading(false);
  }

  const fecharModalDefeitos = () => {
    setShowModal(false);
    setDefeitosSelecionados([]);
  }

  const handleInserirDirecionamento = (index, formObj) => {
    const { grade, quantidade, defeito, direcionamento } = formObj;
    try {
      listReversa[index].direcionamento_defeitos.push({ grade, quantidade: parseInt(quantidade), defeito, direcionamento });
      listReversa[index].grade[grade.index].quantidade -= parseInt(quantidade);
      setListReversa([...listReversa]);
    } catch (e) {
      throw 'Erro ao inserir direcionamento';
    }
  }

  const handleRemoverDirecionamento = (row, indexRef, indexDir) => {
    const { quantidade, grade } = row;
    try {
      listReversa[indexRef].grade[grade.index].quantidade += parseInt(quantidade);
      listReversa[indexRef].direcionamento_defeitos.splice(indexDir, 1);
      setListReversa([...listReversa]);
    } catch (e) {
      toast.error('Encontramos um erro ao remover o direcionamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    }
  }

  const togglePossuiDefeito = (index) => {
    listReversa[index].nao_possui_defeito = !listReversa[index].nao_possui_defeito;
    setListReversa([...listReversa]);
  }

  const processarReversa = async () => {
    setShowLoading(true);
    setShowModalConfirmacao(false);
    try {
      await api.post('/pa-reversa/reversa-nv-processar', { listReversa, usuario_responsavel: CookiesService.getUserLogin(), });
      toast.success('Processamento completado com sucesso!');
      setListReversa([]); setListReversaFiltrada([]);
    } catch (e) {
      setShowModalConfirmacao(true);
      toast.error('Houve um problema durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    }
    setShowLoading(false);
  }

  return (
    <PAReversaContext.Provider
      value={{ handleFilterClick, listReversa, togglePossuiDefeito, setListReversa, listReversaFiltrada, listReversaPending, abrirModalDefeitos, showModal, listaDefeitos, listaDirecionamentos, fecharModalDefeitos, referenciaSelecionada, defeitosSelecionados, handleInserirDirecionamento, filtroProduto, filtrarPorProduto, handleRemoverDirecionamento, showModalBusca, setShowModalBusca, fetchReversaGrid, processarReversa, showModalConfirmacao, setShowModalConfirmacao, romaneioFiltrado }}
    >
      {children}
    </PAReversaContext.Provider>
  )
}