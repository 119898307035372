const XLSX = require('xlsx');

export function objToCsv (arr) {
    const csv = [];
    const headers = Object.keys(arr[0]);
    csv.push(headers.join(','));
    for (const row of arr) {
        const values = headers.map(header => {
            let escVal = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escVal}"`;
        });
        csv.push(values.join(','));
    }
    return csv.join('\n');
}

export function objetoXLSX (data, nomeArquivo, camposParaExportar) {
        const camposParaExportarPadrao = [
          'VERAO',
          'CorOP',
          'DataAlteracaoProxCor',
          'DataAlvoEmissao',
          'DataConsumo',
          'GradePlan',
          'InicioReal',
          'LTPosConsumo',
          'Lancamento',
          'Localizacao',
          'OrdemProducao',
          'OrdemServico',
          'Pendencia',
          'PlantaCorte',
          'Produto',
          'QtdeTecidos',
          'Status',
          'TipoCorte',
        ];

        const campos = camposParaExportar || camposParaExportarPadrao
    
        const workbook = XLSX.utils.book_new();
        const sheetData = data.diarioCortes.map((item) => 
         campos.map((campo) => item[campo] ?? '')
        );
        
        const worksheet = XLSX.utils.aoa_to_sheet([campos, ...sheetData]);
        XLSX.utils.book_append_sheet(workbook, worksheet, nomeArquivo);
    
        const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
        const wbout = XLSX.write(workbook, wopts);
    
        function s2ab(s) {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
          return buf;
        }
    
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'diario-corte.xlsx';
        link.click();
}