import React, { useState } from 'react';
import { Button, Table, Form, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { api }  from '../../../../services/api';
import Carregando from '../../../Carregando'
import CookiesService from '../../../../services/cookies';
import Select from 'react-select';
import styles from '../../../../styles/AdmVigenciaExclusao.module.scss';
import { useEffect } from 'react';

function ConsultaExclusao() {
  const [formObj, setFormObj] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [listaIdCadastroParaExcluir, setListaIdCadastroParaExcluir] = useState([]);
  const [listaAmostras, setListaAmostras] = useState([]);
  const [araraList, setAraraList] = useState([]);
  const [estoqueList, setEstoqueList] = useState([]);

  async function handleSearch() {
    const {arara, estoque, material, corMaterial} = formObj;
    const params = { idArara: arara?.value, idEstoque: estoque?.value, material, corMaterial };
    if (estoque && (!arara && !material)) {
      setCarregando(false);
      return toast.warn('Você deve selecionar uma arara ou buscar por um produto específico');
    }
    if (!estoque && !material) {
      setCarregando(false);
      return toast.warn('Você deve buscar por um(a) produto ou arara específico(a)');
    }

    await api.get('PAPecaPiloto/GetAmostraList', { params })
      .then(res => {
        if (res.data.length < 1) return toast.warning('Nenhum registro encontrato');
        setListaAmostras(res.data.sort((a, b) => {
          if (a.posicao < b.posicao) return -1;
          else if (a.posicao > b.posicao) return 1;
          else return 0;
        }));
        setListaIdCadastroParaExcluir([]);
      })
      .catch(e => {
        toast.warning('Ocorreu um erro durante a busca, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
        console.error(e);
      });
    setCarregando(false);
  }

  function handleSelect(idCadastroSelecionado) {
    const index = listaIdCadastroParaExcluir.findIndex(idCadastro => idCadastro === idCadastroSelecionado);
    index < 0 ? listaIdCadastroParaExcluir.push(idCadastroSelecionado) : listaIdCadastroParaExcluir.splice(index, 1);
    setListaIdCadastroParaExcluir([...listaIdCadastroParaExcluir]);
  }

  async function handleProcessar() {
    if (listaIdCadastroParaExcluir.length <= 0) return toast.warn('Nenhuma amostra foi selecionada');
    const params = {
      listaIdCadastroParaExcluir,
      usuarioExclusao: CookiesService.getUserLogin()
    } 

    await api.post(`PAPecaPiloto/CatalogoCadastroExcluir`, params)
      .then(res => {
        const listaUpdated = listaAmostras.filter(amostra => !listaIdCadastroParaExcluir.includes(amostra.idCadastro));
        setListaAmostras([...listaUpdated]);
        setListaIdCadastroParaExcluir([]);
        setShowConfirmationModal(false);
        toast.success('Amostras excluídas com sucesso!');
      }).catch(e => {
        toast.error('Ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
        console.error(e);
      });
    setCarregando(false);
  }

  const handleSelectAll = () => {
    if (listaAmostras.length === listaIdCadastroParaExcluir.length) setListaIdCadastroParaExcluir([]);
    else {
      const listaIds = [];
      listaAmostras.forEach(amostra => listaIds.push(amostra.idCadastro));
      setListaIdCadastroParaExcluir([...listaIds]);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        await api.get('PAPecaPiloto/CatalogoAraras', { params: { impressao: true } })
          .then(res => {
            setAraraList(res.data);
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });

        await api.get('PAPecaPiloto/GetDescricaoLocal')
          .then(res => {
            setEstoqueList(res.data);
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });
      } catch (e) {
        return toast.error('Ocorreu um erro no carregamento da interface, por favor recarregue a página.');
      }
    })();
  }, []);

  return (
    <>
      <article>
        <Form className={styles.form}>
          <Form.Group className={styles.formGroup}>
            <Form.Label>
              Estoque
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={estoqueList}
              value={formObj.estoque || null}
              onChange={opt => setFormObj({ ...formObj, estoque: opt, arara: null })}
              className={styles.select}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label>
              Arara
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={araraList}
              value={formObj.arara || null}
              onChange={opt => setFormObj({ ...formObj, arara: opt })}
              filterOption={option => option.data.idLocal === formObj.estoque?.value}
              isDisabled={!formObj.estoque}
              className={styles.select}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label>Produto</Form.Label>
            <Form.Control
              required
              type={'text'}
              placeholder={'Produto'}
              title={'Este campo é obrigatório'}
              value={formObj.material || ''}
              onChange={e => setFormObj({ ...formObj, material: e.target.value })}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label>Cor Produto</Form.Label>
            <Form.Control
              required
              type={'text'}
              placeholder={'Cor Produto'}
              title={'Este campo é obrigatório'}
              value={formObj.corMaterial || ''}
              onChange={e => setFormObj({ ...formObj, corMaterial: e.target.value })}
            />
          </Form.Group>
          <Button
            className={styles.formGroup}
            variant="primary"
            type="button"
            onClick={() => { setCarregando(true); handleSearch(); }}
            style={{minWidth: '200px'}}
          >
            Buscar
          </Button>
          <Button
            className={styles.formGroup}
            variant="danger"
            type="button"
            onClick={() => setShowConfirmationModal(true)}
            style={{mindWidth: '200px'}}
          >
            Excluir amostras selecionadas
          </Button>
        </Form>
      </article>

      <article className={styles.articleGray}>
        {<Table className={styles.colNoMargin} responsive>
            <thead>
              <tr>
                <th className={styles.blockTitle}>
                  <Form>
                    <Form.Check
                      type='checkbox'
                      onChange={handleSelectAll}
                      checked={listaIdCadastroParaExcluir.length === listaAmostras.length && listaIdCadastroParaExcluir.length !== 0}
                    />
                  </Form></th>
                <th className={styles.blockTitle}>ESTOQUE</th>
                <th className={styles.blockTitle}>ARARA</th>
                <th className={styles.blockTitle}>PRODUTO</th>
                <th className={styles.blockTitle}>COR</th>
                <th className={styles.blockTitle}>COD FORN.</th>
                {/* <th className={styles.blockTitle}>FORNECEDOR</th> */}
                <th className={styles.blockTitle}>COLEÇÃO</th>
                <th className={styles.blockTitle}>TIPO PRODUTO</th>
                <th className={styles.blockTitle}>ANDAR</th>
                <th className={styles.blockTitle}>LADO</th>
                <th className={styles.blockTitle}>POSIÇÃO</th>
              </tr>
            </thead>
            <tbody >
              {listaAmostras.map((item, index) => {
                return (
                  <tr key={index} >
                    <td className={styles.tableLines}>
                      <Form>
                        <Form.Check key={index}
                          type='checkbox'
                          onChange={() => handleSelect(item.idCadastro)}
                          checked={listaIdCadastroParaExcluir.some(idCadastroMarcado => idCadastroMarcado === item.idCadastro)}
                        />
                      </Form>
                    </td>
                    <td className={styles.tableLines}>{item.estoque}</td>
                    <td className={styles.tableLines}>{item.idArara}</td>
                    <td className={styles.tableLines}>{item.material}</td>
                    <td className={styles.tableLines}>{item.corMaterial}</td>
                    <td className={styles.tableLines}>{item.codClifor}</td>
                    {/* <td className={styles.tableLines}>{item.NOME_CLIFOR}</td> */}
                    <td className={styles.tableLines}>{item.colecao}</td>
                    <td className={styles.tableLines}>{item.tipoMaterial}</td>
                    <td className={styles.tableLines}>{item.andar}</td>
                    <td className={styles.tableLines}>{item.lado}</td>
                    <td className={styles.tableLines}>{item.posicao}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        }
      </article>

      <Modal show={showConfirmationModal} centered={true}>
        <Modal.Header>
          <Modal.Title>
            Atenção!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Essa ação irá remover todas as amostras marcadas de suas respectivas posições. Deseja continuar?
        </Modal.Body>
        <Modal.Footer
          style={{display: 'flex', justifyContent: 'center'}}
        >
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => { setCarregando(true); handleProcessar() }}
          >
            Remover amostras
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={carregando} centered={true}>
        <Carregando />
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ConsultaExclusao;
