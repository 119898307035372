const useChange = (setter, preProcess = [], args = []) => {
  return function (event) {
    let value = event.target.value;
    for (let i = 0; i < preProcess.length; i++) {
      if (preProcess[i]) {
        value = preProcess[i](value, args[i]);
      }
    }
    setter(value);
  };
};

export default useChange;
