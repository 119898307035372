import React, { useState, useEffect } from "react";
import { Button, Table, Form, FormGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import MaterialIcon from 'material-icons-react';

import styles from "../../../styles/ControleDevolucao.module.scss";
import { api } from "../../../services/api";
import { toast } from "react-toastify";

import CookiesService from "../../../services/cookies";
import { fromDDMMYYYYToNewDate, toYYYYMMDD } from "../../../utils/dateUtils";
import UploadModal from "./UploadModal";

import { objToCsv } from "../../../utils/csvUtils";
import { downloadFile } from "../../../utils/downloadUtils";

function ControleDevolucao() {
  const userLogin = CookiesService.getUserLogin();
  const setFilters = (field, value) => {
    setFilterObj({
      ...filterObj,
      [field]: value
    });

    if (!!filterErrs[field]) {
      setFilterErrs({
        ...filterErrs,
        [field]: null
      });
    }
  }

  const [uploadObject, setUploadObject] = useState();

  const [showCadastro, setShowCadastro] = useState(false);
  const [showReagendar, setShowReagendar] = useState(false);
  const [activeObj, setActiveObj] = useState({});
  const [formObj, setFormObj] = useState({});
  const [formOptions, setFormOptions] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [mainArr, setMainArr] = useState([]);
  const [filterErrs, setFilterErrs] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [filterObj, setFilterObj] = useState({});
  const today = toYYYYMMDD(new Date());

  const fetchFilters = async () => {
    let statusDevList, statusEnvioList, fornOrigemList, marcaList;
    statusDevList = statusEnvioList = fornOrigemList = marcaList = [];
    await api.get("/PAControleDevolucao/GetStatusDev")
      .then(res => res.status === 200 ? statusDevList = res.data : null)
      .catch(e => console.error(`Erro na requisição: ${e}`));
    await api.get("/PAControleDevolucao/GetStatusEnvio")
      .then(res => res.status === 200 ? statusEnvioList = res.data : null)
      .catch(e => console.error(`Erro na requisição: ${e}`));
    await api.get("/PAControleDevolucao/GetFornOrigem")
      .then(res => res.status === 200 ? fornOrigemList = res.data : null)
      .catch(e => console.error(`Erro na requisição: ${e}`));
    // ----------------------------------------------------------------------------- //
    // --- FILTRO BLOQUEADO TEMPORARIAMENTE, MAIS INFORMAÇÕES NO ENDPOINT ABAIXO --- //
    // ----------------------------------------------------------------------------- //
    // await api.get("/PAControleDevolucao/GetFornConserto")
    //     .then(res => res.status === 200 ? fornConsertoList = res.data : null)
    //     .catch(e => console.error(`Erro na requisição: ${e}`));
    await api.get("/PAControleDevolucao/GetMarca")
      .then(res => res.status === 200 ? marcaList = res.data : null)
      .catch(e => console.error(`Erro na requisição: ${e}`));
    if (statusDevList.length === 0 || statusEnvioList.length === 0 || fornOrigemList.length === 0 || marcaList.length === 0) {
      toast.error('Erro ao preencher os campos de filtro, por favor entre em contato com o suporte.');
    } else {
      setFilterOptions({ statusDevList, statusEnvioList, fornOrigemList, marcaList });
    }
  }

  const handleFilterValidation = () => {
    const { produto, corProduto, ordemProducao } = filterObj;
    const errs = {}
    if (!isNaN(produto) && parseInt(produto) <= 0) errs.produto = 'Insira um produto válido';
    if (!isNaN(corProduto) && parseInt(corProduto) <= 0) errs.corProduto = 'Insira uma cor de produto válida';
    if (!isNaN(ordemProducao) && parseInt(ordemProducao) <= 0) errs.ordemProducao = 'Insira uma ordem de produção válida';
    return errs;
  }

  const handleFilteredSearch = async () => {
    const errs = handleFilterValidation();
    if (Object.keys(errs).length > 0) {
      setFilterErrs(errs);
    } else {
      const params = {
        statusDev: filterObj.statusDev ? filterObj.statusDev.label : null,
        marca: filterObj.marca ? filterObj.marca.label : null,
        // fornConserto: filterObj.fornConserto ? filterObj.fornConserto.value : null,
        fornOrigem: filterObj.fornOrigem ? filterObj.fornOrigem.label.split('-')[1].trim() : null,
        statusEnvio: filterObj.statusEnvio ? filterObj.statusEnvio.label : null,
        produto: filterObj.produto && filterObj.produto !== '' ? filterObj.produto : null,
        corProduto: filterObj.corProduto && filterObj.corProduto !== '' ? filterObj.corProduto : null,
        ordemProducao: filterObj.ordemProducao && filterObj.ordemProducao !== '' ? filterObj.ordemProducao : null
      }

      await api.get("/PAControleDevolucao/GetProcessos", { params })
        .then(res => {
          if (res.status === 200 && res.data.length > 0) {
            setMainArr(res.data.slice(0, 100));
          } else if (res.status === 200 && res.data.length === 0) {
            toast.warning('Nenhum processo foi encontrado utilizando seus parâmetros de busca.');
          } else if (res.status !== 200) {
            toast.error("Erro Status Code: " + res.status + ".\nEntre em contato com o suporte.");
          }
        }).catch(e => console.error(`Erro na requisição: ${e}`));
    }
  }

  const fetchProcessos = async () => {
    await api.get("/PAControleDevolucao/GetProcessos").then(res => {
      if (res.status === 200) {
        // ------------------------------------------- //
        // --- LIMITANDO OS 100 PRIMEIROS RESULTADOS - // 
        // --- ATÉ A IMPLEMENTAÇÃO DE PAGINAÇÃO ------ //
        // ------------------------------------------- //
        setMainArr(res.data.slice(0, 100));
      } else {
        toast.error("Erro Status Code: " + res.status + ".\nEntre em contato com o suporte.");
      }
    });
  }

  const handleUploadPDF = async (e, el) => {
    setUploadObject(el);
  }

  const handleShowPDF = async (e, el) => {
    e.preventDefault();
    try {
      const fileName = `${el.NFEntrada}_${el.SerieNF}_${el.Origem.replace(/ /g, '_')}.pdf`;
      const res = await api.get('/PAControleDevolucao/ler-nota-fiscal/' + fileName);
      if (res.data.statusCode && res.data.statusCode === 404) return toast.warning(res.data.message);
      window.open(res.data[0], '_blank');
    } catch (e) {
      toast.error('Ocorreu um erro ao baixar o arquivo.');
    }
  }

  const handleShowModal = async (el) => {
    if (el.STATUS_DEV === 'PENDENTE PCP - REAGENDAMENTO COLETA' || el.STATUS_DEV === 'PENDENTE COLETA FORNECEDOR') {
      setShowReagendar(true);
      setActiveObj(el);
    }
    else if (el.STATUS_DEV === 'PENDENTE PCP - DATA COLETA FORNECEDOR') {
      setShowCadastro(true);
      setActiveObj(el);
      if (el.TipoProduto !== 'PRODUTO ACABADO') setFormObj({ ...formObj, RetornaCD: true });
    }
    else return;
  }

  const handleClose = () => {
    setActiveObj({});
    setFormObj({});
    setFormErrs({});
    setShowCadastro(false);
    setShowReagendar(false);
  }

  const fetchModalOptions = async () => {
    try {
      const [destDevRes, tipoTransporteRes, tipoFreteRes, motivoColetaRes] = await Promise.all([
        api.get("/PAControleDevolucao/GetListaDestDev"),
        api.get("/PAControleDevolucao/GetListaTipoTransporte"),
        api.get("/PAControleDevolucao/GetListaTipoFrete"),
        api.get("/PAControleDevolucao/GetListaMotivoColeta")
      ]);

      const DestinoDevList = destDevRes.data;
      const TipoTransporteList = tipoTransporteRes.data;
      const TipoFreteList = tipoFreteRes.data;
      const MotivoColetaList = motivoColetaRes.data;

      if (DestinoDevList.length === 0 || TipoTransporteList.length === 0 || TipoFreteList.length === 0 || MotivoColetaList.length === 0) {
        return toast.error('Erro ao preencher os campos de seleção, por favor entre em contato com o suporte.');
      }
      setFormOptions({ DestinoDevList, TipoTransporteList, TipoFreteList, MotivoColetaList });
    } catch (e) {
      toast.error('Erro ao preencher os campos de seleção, por favor entre em contato com o suporte.');
    }
  }

  const handleDestinoDevChange = async (e) => {
    if (e === null) return setFormObj({ ...formObj, DestinoDev: null });
    const option = e;
    if (option.value === 1 || option.value === 3) {
      const params = { Nome: activeObj.Origem.trim() };
      try {
        const res = await api.get("/PAControleDevolucao/GetCNPJERazaoSocialByName", { params })
        return setFormObj({
          ...formObj,
          DestinoDev: option,
          Fornecedor: res.data[0],
          CustoPeca: '0.00'
        });
      } catch (e) {
        return toast.error('Um erro inexperado aconteceu ao buscar o CNPJ. Por favor tente novamente ou entre em contato com o suporte.');
      }
    }

    if (option.value === 2) {
      return setFormObj({
        ...formObj,
        DestinoDev: option,
        TipoTransporte: null,
        Transportadora: null,
        Fornecedor: null,
        TipoFrete: null
      });
    }

    if (option.value === 4) {
      return setFormObj({
        ...formObj,
        DestinoDev: option,
        TipoTransporte: null,
        Transportadora: null,
        Fornecedor: null
      });
    }

    return setFormObj({ ...formObj, DestinoDev: option });
  }

  const handleTipoTransporteChange = (e) => {
    if (e === null) return setFormObj({ ...formObj, TipoTransporte: null });
    const option = e;
    if (option.value === 2) {
      setFormObj({
        ...formObj,
        TipoTransporte: option,
        Transportadora: null,
        TipoFrete: null,
      });
    }

    if (option.value === 1) {
      setFormObj({
        ...formObj,
        TipoTransporte: option,
        TipoFrete: { label: '02 - FOB', value: 2 }
      });
    }
  }

  const loadTransportOpts = async (filter) => {
    const params = { filter: filter }
    const data = await api.get("/PAControleDevolucao/GetTransportadoras", { params })
      .catch(e => console.error(`Erro na requisição: ${e}`));
    return data.data.slice(0, 20);
  }

  const loadFornOpts = async (filter) => {
    const params = { filter: filter }
    const data = await api.get("/PAControleDevolucao/GetFornecedores", { params })
      .catch(e => console.error(`Erro na requisição: ${e}`));
    return data.data.slice(0, 20);
  }

  const clearErrs = (val) => {
    if (val) return setFormErrs({ ...formErrs, [val]: null })
    setFormErrs({
      ...formErrs,
      DestinoDev: null,
      TipoTransporte: null,
      Transportadora: null,
      Fornecedor: null,
      TipoFrete: null,
      MotivoAlteracao: null
    })
  }

  const handleProcess = async (e) => {
    e.preventDefault();
    try {
      const errs = handleValidation();
      if (Object.keys(errs).length > 0) return setFormErrs(errs);

      const params = {
        NFEntrada: activeObj.NFEntrada,
        SerieNF: activeObj.SerieNF,
        Origem: activeObj.Origem,
        Produto: activeObj.Produto,
        CorProduto: activeObj.CorProduto,
        QtdeEntrada: activeObj.QtdeEntrada,
        FilialRevisao: activeObj.FilialRevisao,
        UsuarioResponsavel: userLogin,

        DestinoDevolucao: formObj.DestinoDev.value,
        TipoTransporte: formObj.TipoTransporte ? formObj.TipoTransporte.value : '',
        // TipoFrete:          formObj.TipoFrete ? formObj.TipoFrete.value : '',
        TipoFrete: 2,
        CodFornecedor: formObj.Fornecedor ? formObj.Fornecedor.value : '',
        CNPJTransp: formObj.Transportadora ? formObj.Transportadora.value : '',
        CustoPeca: formObj.CustoPeca,
        DataColeta: formObj.DataColeta,
        Observacao: formObj.Observacao,
        RetornaCD: formObj.RetornaCD
      }

      await api.post("/PAControleDevolucao/UpsertCadastro", params)
      toast.success('Dados alterados com sucesso!');
      handleClose();
      fetchProcessos();
      setFilterObj({});
    } catch (e) {
      toast.error(`Ocorreu um erro durante o processamento, por favor tente novamente ou entre em contato com o suporte.`);
    }
  }

  const handleValidation = () => {
    const { DestinoDev, Fornecedor, TipoTransporte, DataColeta, CustoPeca, RetornaCD, Observacao } = formObj;
    const dataAgendamentoContagem = fromDDMMYYYYToNewDate(activeObj.DataAgendamentoContagem);
    const errs = {}
    if (!DestinoDev) errs.DestinoDev = 'Selecione uma opção válida';
    if (validateTipoTransporte(DestinoDev) === false) errs.TipoTransporte = 'Selecione uma opção válida';
    if (validateTransportadora(DestinoDev, TipoTransporte) === false) errs.Transportadora = 'Selecione uma opção válida';
    if (!Fornecedor) errs.Fornecedor = 'Selecione uma opção válida';
    if (validateTipoFrete(DestinoDev, TipoTransporte) === false) errs.TipoFrete = 'Tipo de frete inválido';
    if (!DataColeta || new Date(DataColeta).getTime() <= 0 || new Date(DataColeta) < new Date(today) || new Date(DataColeta) < dataAgendamentoContagem) errs.DataColeta = 'Insira uma data válida, maior ou igual à data atual e maior do que a data de agendamento';

    // ------------------------------------------------------------------------------------------------------------------------- //
    // -- Os destinos 2, 3 e 4 são de beneficiamento, nesses casos o usuário é obrigado a imputar um custo maior que R$ 0,00 -- //
    // ----------------------------------------------------------------------------------------------------------------------- //
    if (![2, 3, 4].includes(DestinoDev.value) && (!CustoPeca || CustoPeca === '' || CustoPeca < 0)) {
      errs.CustoPeca = 'Insira um valor válido, igual ou maior que R$ 0.00';
    } else if ([2, 3, 4].includes(DestinoDev.value) && (!CustoPeca || CustoPeca === '' || CustoPeca <= 0)) {
      errs.CustoPeca = 'O destino devolução é de beneficiamento, insira um valor válido maior que R$ 0.00';
    }

    if (validateRetornaCD(activeObj.TipoProduto, RetornaCD) === false) errs.RetornaCD = 'Marque uma opção válida';
    if (!Observacao) formObj.Observacao = '';
    return errs;
  }

  const validateTipoTransporte = (DestinoDev) => {
    if (DestinoDev && DestinoDev.value === 2) {
      setFormObj({ ...formObj, TipoTransporte: null });
      return true;
    } else if (!formObj.TipoTransporte) {
      return false;
    }
  }

  const validateTransportadora = (DestinoDev, TipoTransporte) => {
    if (
      (DestinoDev && DestinoDev.value === 2)
      || (TipoTransporte && TipoTransporte.value === 2)
    ) {
      return true;
    } else if (!formObj.Transportadora) {
      return false;
    }
  }

  const validateTipoFrete = (DestinoDev, TipoTransporte) => {
    if (
      (DestinoDev && DestinoDev.value === 2)
      || (DestinoDev && TipoTransporte && DestinoDev.value !== 2 && TipoTransporte.value === 2)
    ) {
      return true;
    } else if (DestinoDev && TipoTransporte && DestinoDev.value !== 2 && TipoTransporte.value === 1) {
      return true;
    } else { return false }
  }

  const handleUpdateColeta = async (e) => {
    e.preventDefault();
    try {
      const errs = validateUpdateColeta();
      if (Object.keys(errs).length > 0) return setFormErrs(errs);

      const params = {
        NFEntrada: activeObj.NFEntrada,
        SerieNF: activeObj.SerieNF,
        Origem: activeObj.Origem,
        Produto: activeObj.Produto,
        CorProduto: activeObj.CorProduto,
        QtdeEntrada: activeObj.QtdeEntrada,
        FilialRevisao: activeObj.FilialRevisao,
        NovaDataColeta: formObj.NovaDataColeta,
        UsuarioResponsavel: userLogin,
        MotivoAlteracao: formObj.MotivoAlteracao.value,
        Observacao: formObj.Observacao,
      }

      await api.post('/PAControleDevolucao/UpdateReagendamentoColeta', params)
      toast.success('Dados alterados com sucesso!');
      handleClose();
      fetchProcessos();
      setFilterObj({});
    } catch (e) {
      toast.error(`Ocorreu um erro durante o processamento, por favor tente novamente ou entre em contato com o suporte.`);
    }
  }

  const validateUpdateColeta = () => {
    const { NovaDataColeta, MotivoAlteracao, Observacao } = formObj;
    const dataAgendamentoContagem = fromDDMMYYYYToNewDate(activeObj.DataAgendamentoContagem);
    const errs = {}
    if (!NovaDataColeta || new Date(NovaDataColeta).getTime() <= 0 || new Date(NovaDataColeta) < new Date(today) || new Date(NovaDataColeta) < dataAgendamentoContagem) errs.NovaDataColeta = 'Insira uma data válida, maior ou igual à data atual e maior do que a data de agendamento';
    if (!MotivoAlteracao) errs.MotivoAlteracao = 'Selecione um motivo';
    if (!Observacao) formObj.Observacao = '';
    return errs;
  };

  const validateRetornaCD = (TipoProduto, RetornaCD) => {
    if (TipoProduto !== 'PRODUTO ACABADO' && RetornaCD === undefined) return true;
    else if (RetornaCD === undefined) return false;
  }

  const handleValueMask = (value) => {
    if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
    value = parseFloat(value / 100).toFixed(2);
    setFormObj({ ...formObj, CustoPeca: value });
  }

  const handleNumberDotMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
    setFilterObj({ ...formObj, [field]: value });
    setFilterErrs({ ...filterErrs, [field]: null });
  }

  const handleCSVConversion = (e) => {
    e.preventDefault();
    if (mainArr.length > 0) {
      const csv = objToCsv(mainArr);
      downloadFile(csv, 'text/csv', 'controle_devolucao_pa', 'csv');
    } else {
      toast.error(
        "Erro na exportação. Certifique-se que há items na tabela principal e tente novamente."
      );
    }
  }

  useEffect(() => {
    fetchProcessos();
    fetchFilters();
    fetchModalOptions();
  }, []);

  return (
    <div>
      <UploadModal obj={uploadObject} show={!!uploadObject} handleClose={() => setUploadObject()} />
      {/* Formulário de Busca */}
      <article >
        <Form className={styles.form}>
          <FormGroup className={`${styles.formGroup} ${styles.filterWidth}`}>
            <Form.Label>
              Status
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={filterOptions.statusDevList}
              value={filterObj.statusDev || null}
              onChange={e => setFilterObj({ ...filterObj, statusDev: e })}
            />
          </FormGroup>

          <FormGroup className={`${styles.formGroup} ${styles.filterWidth}`}>
            <Form.Label >
              Marca
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={filterOptions.marcaList}
              value={filterObj.marca || null}
              onChange={e => setFilterObj({ ...filterObj, marca: e })}
            />
          </FormGroup>

          <FormGroup className={`${styles.formGroup} ${styles.filterWidth}`}>
            <Form.Label >
              Fornecedor Entrada Fiscal
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={filterOptions.fornOrigemList}
              value={filterObj.fornOrigem || null}
              onChange={e => setFilterObj({ ...filterObj, fornOrigem: e })}
            />
          </FormGroup>

          <FormGroup className={`${styles.formGroup} ${styles.filterWidth}`}>
            <Form.Label >
              Pendente Envio NF
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={filterOptions.statusEnvioList}
              value={filterObj.statusEnvio || null}
              onChange={e => setFilterObj({ ...filterObj, statusEnvio: e })}
            />
          </FormGroup>
        </Form>

        <Form className={styles.form}>
          <FormGroup className={`${styles.formGroup} ${styles.filterWidth}`} controlId='produto'>
            <Form.Label >
              Produto
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={'Produto'}
              value={filterObj.produto || ''}
              onChange={e => handleNumberDotMask('produto', e.target.value)}
              isInvalid={!!filterErrs.produto}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.produto}</Form.Control.Feedback>
          </FormGroup>
          <FormGroup className={`${styles.formGroup} ${styles.filterWidth}`} controlId='corProduto'>
            <Form.Label>
              Cor Produto
            </Form.Label>
            <Form.Control
              type="number"
              placeholder={'Cor do produto'}
              value={filterObj.corProduto || ''}
              onChange={e => setFilters('corProduto', e.target.value)}
              isInvalid={!!filterErrs.corProduto}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.corProduto}</Form.Control.Feedback>
          </FormGroup>
          <FormGroup className={`${styles.formGroup} ${styles.filterWidth}`} controlId='ordemProducao'>
            <Form.Label>
              OP / Pedido
            </Form.Label>
            <Form.Control
              type="number"
              placeholder={'OP/Pedido'}
              value={filterObj.ordemProducao || ''}
              onChange={e => setFilters('ordemProducao', e.target.value)}
              isInvalid={!!filterErrs.ordemProducao}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.ordemProducao}</Form.Control.Feedback>
          </FormGroup>
          <Button
            className={`${styles.formGroup} ${styles.filterWidth}`}
            variant="primary"
            type="button"
            onClick={handleFilteredSearch}
          >
            Buscar
          </Button>
          <Button
            type="button"
            className={`${styles.formGroup} ${styles.filterWidth}`}
            variant="outline-success"
            onClick={e => handleCSVConversion(e)}
          >
            <div className={styles.formBtnDisplay}>
              <i>
                <MaterialIcon
                  icon="download"
                  size={20}
                  color='#198754'
                />
              </i>
              Exportar
            </div>
          </Button>
        </Form>
      </article>

      {/* Listagem */}
      <article className={styles.articleGray}>
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th className={styles.blockTitle}>STATUS</th>
              <th className={styles.blockTitle}>NF ENTRADA</th>
              <th className={styles.blockTitle}>SÉRIE</th>
              <th className={styles.blockTitle}>PRODUTO</th>
              <th className={styles.blockTitle}>COR</th>
              <th className={styles.blockTitle}>FORNECEDOR ORIGEM</th>
              <th className={styles.blockTitle}>QTDE. ENTRADA</th>
              <th className={styles.blockTitle}>FILIAL REVISÃO</th>
              <th className={styles.blockTitle}>QTDE. DEVOLVIDA</th>
              <th className={styles.blockTitle}>DATA AGENDAMENTO CONTAGEM</th>
              <th className={styles.blockTitle}>DATA DA CONTAGEM</th>
              <th className={styles.blockTitle}>DATA DA COLETA</th>
              <th className={styles.blockTitle}>DESTINO DEVOLUÇÃO</th>
              <th className={styles.blockTitle}>TIPO TRANSPORTE</th>
              <th className={styles.blockTitle}>TRANSPORTADORA</th>
              <th className={styles.blockTitle}>TIPO FRETE</th>
              <th className={styles.blockTitle}>FORNECEDOR DESTINO</th>
              <th className={styles.blockTitle}>CUSTO PEÇA</th>
              <th className={styles.blockTitle}>ALTERA DATA COLETA</th>
              <th className={styles.blockTitle}>COLETADO</th>
              <th className={styles.blockTitle}>MARCA</th>
              <th className={styles.blockTitle}>NF ENVIADA POR</th>
              <th className={styles.blockTitle}>DATA MÍNIMA COLETA</th>
              <th className={styles.blockTitle}>DATA COLETAGEM NO CD</th>
              <th className={styles.blockTitle}>TIPO PRODUTO</th>
              <th className={styles.blockTitle}>ASSISTENTE RESPONSÁVEL</th>
            </tr>
          </thead>
          <tbody>
            {mainArr.map((el, index) => {
              return (
                <tr className={styles.tableLines} key={index}>
                  <td
                    onClick={e => handleShowPDF(e, el)}
                  >
                    <Button className={styles.verNotaFiscal}>
                      <i className={styles.icon}>
                        <MaterialIcon
                          icon="visibility"
                          size={20}
                          color='white'
                        />
                      </i>
                    </Button>
                  </td>
                  <td
                    onClick={e => handleUploadPDF(e, el)}
                  >
                    <Button className={styles.verNotaFiscal}>
                      <i className={styles.icon}>
                        <MaterialIcon
                          icon="upload"
                          size={20}
                          color='white'
                        />
                      </i>
                    </Button>
                  </td>
                  <td
                    onClick={() => handleShowModal(el)}
                  >
                    <Button
                      className={styles.verNotaFiscal}
                      disabled={el.STATUS_DEV !== 'PENDENTE PCP - REAGENDAMENTO COLETA'
                        && el.STATUS_DEV !== 'PENDENTE COLETA FORNECEDOR'
                        && el.STATUS_DEV !== 'PENDENTE PCP - DATA COLETA FORNECEDOR'}
                    >
                      <i className={styles.icon}>
                        <MaterialIcon
                          icon="add"
                          size={20}
                          color='white'
                        />
                      </i>
                    </Button>
                  </td>
                  <td className={styles.tableLines}>{el.STATUS_DEV}</td>
                  <td className={styles.tableLines}>{el.NFEntrada}</td>
                  <td className={styles.tableLines}>{el.SerieNF}</td>
                  <td className={styles.tableLines}>{el.Produto}</td>
                  <td className={styles.tableLines}>{el.CorProduto}</td>
                  <td className={styles.tableLines}>{el.Origem}</td>
                  <td className={styles.tableLines}>{el.QtdeEntrada}</td>
                  <td className={styles.tableLines}>{el.FilialRevisao}</td>
                  <td className={styles.tableLines}>{el.QtdeDevolvida}</td>
                  <td className={styles.tableLines}>{el.DataAgendamentoContagem}</td>
                  <td className={styles.tableLines}>{el.DataContagem}</td>
                  <td className={styles.tableLines}>{el.DataColeta}</td>
                  <td className={styles.tableLines}>{el.DestinoDevolucao}</td>
                  <td className={styles.tableLines}>{el.TipoTransporte}</td>
                  <td className={styles.tableLines}>{el.Transportadora}</td>
                  <td className={styles.tableLines}>{el.TipoFrete}</td>
                  <td className={styles.tableLines}>{el.Fornecedor}</td>
                  <td className={styles.tableLines}>{el.CustoPeca}</td>
                  <td className={styles.tableLines}>{el.AlteraDataColeta}</td>
                  <td className={styles.tableLines}>{el.Coletado}</td>
                  <td className={styles.tableLines}>{el.Marca}</td>
                  <td className={styles.tableLines}>{el.EnvioNF}</td>
                  <td className={styles.tableLines}>{el.DataMinimaColeta}</td>
                  <td className={styles.tableLines}>{el.DataColetaCD}</td>
                  <td className={styles.tableLines}>{el.TipoProduto}</td>
                  <td className={styles.tableLines}>{el.AssistenteResponsavel}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </article>

      {/* Modal Cadastro */}
      <Modal
        show={showCadastro}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName={styles.modalWidth}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Dados Devolução</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>NF Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Série:</span>
                <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Tipo Produto:</span>
                <span className={styles.fontBlue}>{activeObj.TipoProduto}</span>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Produto:</span>
                <span className={styles.fontBlue}>{activeObj.Produto}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Data Agendamento:</span>
                <span className={styles.fontBlue}>{activeObj.DataAgendamentoContagem}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Data Mínima Coleta:</span>
                <span className={styles.fontRed}>{activeObj.DataMinimaColeta}</span>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Cor Produto:</span>
                <span className={styles.fontBlue}>{activeObj.CorProduto}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Qtde Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Qtde Devolvida:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeDevolvida}</span>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Filial Revisão:</span>
                <span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Origem:</span>
                <span className={styles.fontBlue}>{activeObj.Origem}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>OP/Pedido:</span>
                <span className={styles.fontBlue}>{activeObj.OrdemProducao ? activeObj.OrdemProducao : '-'}</span>
              </div>
            </div>
          </article>
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <Form>
                <Form.Group className={styles.formGroup} controlId="DestinoDev">
                  <Form.Label>1. Destino Devolução:</Form.Label>
                  <Select
                    value={formObj.DestinoDev}
                    options={formOptions.DestinoDevList}
                    onChange={obj => { handleDestinoDevChange(obj); clearErrs() }}
                    isClearable={true}
                    escapeClearsValue={true}
                    styles={{
                      control: (provided) => (
                        formErrs.DestinoDev ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                      )
                    }}
                  />
                  <div className={formErrs.DestinoDev ? styles.showFeedback : styles.hidden}>{formErrs.DestinoDev}</div>
                </Form.Group>
              </Form>
              <Form>
                <Form.Group className={styles.formGroup} controlId="TipoTransporte">
                  <Form.Label>2. Tipo Transporte:</Form.Label>
                  <Select
                    value={formObj.TipoTransporte}
                    options={formOptions.TipoTransporteList}
                    onChange={obj => { handleTipoTransporteChange(obj); clearErrs() }}
                    isDisabled={
                      formObj.DestinoDev && formObj.DestinoDev.value === 2
                    }
                    isClearable={true}
                    escapeClearsValue={true} styles={{
                      control: (provided) => (
                        formErrs.TipoTransporte ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                      )
                    }}
                  />
                  <div className={formErrs.TipoTransporte ? styles.showFeedback : styles.hidden}>{formErrs.TipoTransporte}</div>
                </Form.Group>
              </Form>
              <Form>
                <Form.Group className={styles.formGroup} controlId="Transportadora">
                  <Form.Label>3. Transportadora:</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    value={formObj.Transportadora}
                    loadOptions={loadTransportOpts}
                    onChange={obj => { setFormObj({ ...formObj, Transportadora: obj }); clearErrs() }}
                    isDisabled={
                      (formObj.DestinoDev && formObj.DestinoDev.value === 2)
                      || (formObj.TipoTransporte && formObj.TipoTransporte.value === 2)
                    }
                    isClearable={true}
                    escapeClearsValue={true}
                    noOptionsMessage={() => 'Utilize o campo acima para filtrar as transportadoras'}
                    styles={{
                      control: (provided) => (
                        formErrs.Transportadora ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                      )
                    }}
                  />
                  <div className={formErrs.Transportadora ? styles.showFeedback : styles.hidden}>{formErrs.Transportadora}</div>
                </Form.Group>
              </Form>
              <Form>
                <Form.Group className={styles.formGroup} controlId="Fornecedor">
                  <Form.Label>4. Fornecedor:</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    value={formObj.Fornecedor}
                    loadOptions={loadFornOpts}
                    onChange={obj => { setFormObj({ ...formObj, Fornecedor: obj }); clearErrs() }}
                    isDisabled={
                      formObj.DestinoDev
                      && (formObj.DestinoDev.value === 1
                        || formObj.DestinoDev.value === 3)
                    }
                    isClearable={true}
                    escapeClearsValue={true}
                    noOptionsMessage={() => 'Utilize o campo acima para filtrar os fornecedores'}
                    styles={{
                      control: (provided) => (
                        formErrs.Fornecedor ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                      )
                    }}
                  />
                  <div className={formErrs.Fornecedor ? styles.showFeedback : styles.hidden}>{formErrs.Fornecedor}</div>
                </Form.Group>
              </Form>
              <Form>
                <Form.Group className={styles.formGroup} controlId="Fornecedor">
                  {/* --------------------- IMPORTANTE -------------------- */}
                  {/* --- NO MOMENTO DA CRIAÇÃO DESTA TELA, ESTÁ ACORDADO - */}
                  {/* --- COM OS FORNECEDORES QUE O ÚNICO TIPO DE FRETE --- */}
                  {/* --- POSSÍVEL É O FOB, MAS FOI PEDIDO QUE ------------ */}
                  {/* --- MANTIVESSE ESTE INPUT MESMO ASSIM --------------- */}
                  {/* ----------------------------------------------------- */}
                  <Form.Label>5. Tipo Frete:</Form.Label>
                  <Select
                    value={formObj.TipoFrete}
                    isDisabled={true}
                    isClearable={true}
                    escapeClearsValue={true}
                    styles={{
                      control: (provided) => (
                        formErrs.TipoFrete ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                      )
                    }}
                  />
                  <div className={formErrs.TipoFrete ? styles.showFeedback : styles.hidden}>{formErrs.TipoFrete}</div>
                </Form.Group>
              </Form>
            </div>
            <div className={styles.infoBlock}>
              <Form>
                <Form.Group className={styles.formGroup} controlId="DataColeta">
                  <Form.Label>6. Data para coleta:</Form.Label>
                  <Form.Control
                    type="date"
                    value={formObj.DataColeta || ''}
                    onFocus={() => clearErrs('DataColeta')}
                    onChange={e => setFormObj({ ...formObj, DataColeta: e.target.value })}
                    placeholder={'Data para coleta'}
                    min={today}
                    max={'9999-01-01'}
                    isInvalid={formErrs.DataColeta}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>{formErrs.DataColeta}</Form.Control.Feedback>
                </Form.Group>
              </Form>
              <Form>
                <Form.Group className={styles.formGroup} controlId="CustoPeca">
                  <Form.Label>7. Custo por peça:</Form.Label>
                  <Form.Control
                    type="text"
                    value={formObj.CustoPeca || ''}
                    onFocus={() => clearErrs('CustoPeca')}
                    onChange={e => handleValueMask(e.target.value)}
                    placeholder={'Custo da peça em R$; ex.: 2.10'}
                    isInvalid={formErrs.CustoPeca}
                    disabled={formObj.DestinoDev?.value === 1}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>{formErrs.CustoPeca}</Form.Control.Feedback>
                </Form.Group>
              </Form>
              <Form>
                <Form.Group className={styles.formGroup} controlId="RetornaCD">
                  <Form.Label>8. Devolução terá retorno?</Form.Label>
                  <Form.Check
                    onChange={() => { setFormObj({ ...formObj, RetornaCD: false }); clearErrs('RetornaCD') }}
                    type={'radio'}
                    label={'Não'}
                    name={'RetornaCD'}
                    id={'Não'}
                    isInvalid={formErrs.RetornaCD}
                    checked={formObj.RetornaCD === false}
                    disabled={activeObj.TipoProduto !== 'PRODUTO ACABADO'}
                  />
                  <Form.Check
                    onChange={() => { setFormObj({ ...formObj, RetornaCD: true }); clearErrs('RetornaCD') }}
                    type={'radio'}
                    label={'Sim'}
                    name={'RetornaCD'}
                    id={'Sim'}
                    isInvalid={formErrs.RetornaCD}
                    checked={formObj.RetornaCD === true}
                    disabled={activeObj.TipoProduto !== 'PRODUTO ACABADO'}
                  />
                </Form.Group>
              </Form>
              <Form>
                <Form.Group className={styles.formGroup} controlId="Observacao">
                  <Form.Label>9. Observação<span className={styles.spanOpcional}>(opcional)</span>:</Form.Label>
                  <Form.Control
                    className={styles.noResize}
                    type="text"
                    as="textarea"
                    rows={7}
                    placeholder={'Observações sobre a devolução aqui'}
                    value={formObj.Observacao || ''}
                    onChange={e => setFormObj({ ...formObj, Observacao: e.target.value })}
                  />
                </Form.Group>
              </Form>
            </div>

          </article>
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <Button
                className={`${styles.btn} ${styles.btnLimit}`}
                variant="primary"
                type="button"
                onClick={e => handleProcess(e)}
                disabled={
                  !formObj.DestinoDev
                  || !formObj.CustoPeca
                  || !formObj.DataColeta
                }
              >
                Processar
              </Button>
            </div>
          </article>
        </Modal.Body>
      </Modal>

      {/* Modal Reagendamento */}
      <Modal
        show={showReagendar}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName={styles.modalWidth}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reagendar Data Coleta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>NF Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Serie NF:</span>
                <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Data Contagem:</span>
                <span className={styles.fontBlue}>{activeObj.DataContagem}</span>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Origem:</span>
                <span className={styles.fontBlue}>{activeObj.Origem}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Filial Revisão:</span>
                <span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Data Mínima Coleta:</span>
                <span className={styles.fontBlue}>{activeObj.DataMinimaColeta}</span>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Produto:</span>
                <span className={styles.fontBlue}>{activeObj.Produto}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Cor:</span>
                <span className={styles.fontBlue}>{activeObj.CorProduto}</span>
              </div>
              <div className={styles.infoBlockColumm}>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Qtde Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Qtde Devolvida:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeDevolvida}</span>
              </div>
              <div className={styles.infoBlockColumm}>
              </div>
            </div>
          </article>
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                {/* <span>Data Coleta Agendada:</span>
                                <span className={styles.fontBlue}>{activeObj.DataColeta}</span> */}
                <span>Data Agendamento:</span>
                <span className={styles.fontBlue}>{activeObj.DataAgendamentoContagem}</span>
              </div>
              <div className={styles.flex}>
                <Form className={styles.selectWidth}>
                  <Form.Group className={styles.formGroup} controlId="NovaDataColeta">
                    <Form.Label>1. Nova data para coleta:</Form.Label>
                    <Form.Control
                      type="date"
                      value={formObj.NovaDataColeta || ''}
                      onFocus={() => clearErrs('NovaDataColeta')}
                      onChange={e => setFormObj({ ...formObj, NovaDataColeta: e.target.value })}
                      placeholder={'Data para coleta'}
                      min={today}
                      max={'9999-01-01'}
                      isInvalid={formErrs.NovaDataColeta}
                      required
                    />
                    <Form.Control.Feedback type='invalid'>{formErrs.NovaDataColeta}</Form.Control.Feedback>
                  </Form.Group>
                </Form>
                <Form className={styles.selectWidth}>
                  <Form.Group className={styles.formGroup} controlId="MotivoAlteracao">
                    <Form.Label>2. Motivo:</Form.Label>
                    <Select
                      value={formObj.MotivoAlteracao}
                      options={formOptions.MotivoColetaList}
                      onChange={obj => { setFormObj({ ...formObj, MotivoAlteracao: obj }); clearErrs() }}
                      isClearable={true}
                      escapeClearsValue={true}
                      styles={{
                        control: (provided) => (
                          formErrs.MotivoAlteracao ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                        )
                      }}
                    />
                    <div className={formErrs.MotivoAlteracao ? styles.showFeedback : styles.hidden}>{formErrs.MotivoAlteracao}</div>
                  </Form.Group>
                </Form>
              </div>
              <Form>
                <Form.Group className={styles.formGroup} controlId="Observacao">
                  <Form.Label>3. Observação<span className={styles.spanOpcional}>(opcional)</span>:</Form.Label>
                  <Form.Control
                    className={styles.noResize}
                    type="text"
                    as="textarea"
                    rows={5}
                    placeholder={'Observações sobre a alteração de coleta.'}
                    value={formObj.Observacao || ''}
                    onChange={e => setFormObj({ ...formObj, Observacao: e.target.value })}
                  />
                </Form.Group>
              </Form>
            </div>
          </article>
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <Button
                className={`${styles.btn} ${styles.btnLimit}`}
                variant="primary"
                type="button"
                onClick={handleUpdateColeta}
                disabled={!formObj.NovaDataColeta || !formObj.MotivoAlteracao}
              >
                Processar
              </Button>
            </div>
          </article>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ControleDevolucao;
