import React, { useState, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { api }  from "../../../services/api";
import EnvioAmostra from "./Components/EnvioAmostra";
import RetornoAmostra from "./Components/RetornoAmostra";
import CancelarEnvio from "./Components/CancelarEnvio";
import CancelarRecebimento from "./Components/CancelarRecebimento";
import NaoRetorno from "./Components/NaoRetorno";

import styles from "../../../styles/AdmEnvioRetornoAmostra.module.scss";

function EnvioRetornoAmostra() {
    const [codigo, setCodigo] = useState("");
    const [material, setMaterial] = useState("");
    const [corMaterial, setCorMaterial] = useState("");
    const [acao, setAcao] = useState([]);
    const [acaoSelecionada, setAcaoSelecionada] = useState("");
    const [listaAmostras, setListaAmostras] = useState([]);

    useEffect(() => {
        api.get("CatalogoADM/ADMEnvioAcao").then((result) => {
            setAcao(result.data);
        });
    }, []);

    async function handleForm() {
        const params = {
            Acao: parseInt(acaoSelecionada),
            Codigo: codigo, 
            Material: material, 
            CorMaterial: corMaterial 
        };
         if(!codigo && ( !material || !corMaterial)){
            toast.warning("Informe um código, ou material e cor.")
            return false;
            
         }


        const listaDeAmostras = await api.get(
            "CatalogoADM/ADMEnvioBuscar",
            { params }
        );
        if(listaDeAmostras.data.length===0){
            toast.warning("Nenhum registro encontrado.")
        }
        setListaAmostras(listaDeAmostras.data);
    }

    return (
        <body>
            <article>
                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Ação</Form.Label>
                        <Form.Select
                            onChange={(e) => {setAcaoSelecionada(e.target.value)}}
                        >
                            <option 
                                selected={true} 
                                disabled 
                                readOnly 
                            >
                                Ação
                            </option>
                            {acao.map((local) => {
                                return (
                                    <option value={local.acao}>
                                        {local.acao}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Código</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Codigo"
                            value={codigo}
                            onChange={(event) => setCodigo(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Material</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Material"
                            value={material}
                            onChange={(event) => setMaterial(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Cor Material</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Cor Material"
                            value={corMaterial}
                            onChange={(event) => setCorMaterial(event.target.value)}
                        />
                    </Form.Group>
                    <Button 
                        className={styles.formGroup}
                        type="button" 
                        onClick={handleForm}
                    >
                        Buscar
                    </Button>
                </Form>
                    <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    />
            </article>

            {acaoSelecionada==='01 - ENVIO DE AMOSTRA'
            ?
            <EnvioAmostra lista = {listaAmostras} acaoSelecionada = {acaoSelecionada}/>
            :null
            }
                    
            {acaoSelecionada==='02 - RETORNO DE AMOSTRA'
            ?
            <RetornoAmostra lista = {listaAmostras} acaoSelecionada = {acaoSelecionada}/>
            :null
            }

            {acaoSelecionada==='03 - CANCELAR ENVIO'
            ?
            <CancelarEnvio lista = {listaAmostras} acaoSelecionada = {acaoSelecionada}/>
            :null
            }     

            {acaoSelecionada==='04 - CANCELAR RECEBIMENTO - ERRO BAIXA'
            ?
            <CancelarRecebimento lista = {listaAmostras} acaoSelecionada = {acaoSelecionada}/>
            :null
            }

            {acaoSelecionada==='05 - NÃO RETORNO DE AMOSTRA'
            ?
            <NaoRetorno lista = {listaAmostras} acaoSelecionada = {acaoSelecionada}/>
            :null
            }
        </body>
    );
}

export default EnvioRetornoAmostra;
 