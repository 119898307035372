import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import styles from './Filtro.module.scss'
import FilterList from './FiltroAux';
import { toast } from 'react-toastify';

const Filtro = (props) => {
  const { activeFilters, searchFn, children, btnVariant = 'primary' } = props;
  const [filterObj, setFilterObj] = useState({});
  const [activeFilterList, setActiveFilterList] = useState([]);

  useEffect(() => {
    const filteredArray = FilterList.map(filterItem => {
      const matchedObj = activeFilters.find(item => item.key === filterItem.key);
      if (matchedObj) {
        return { ...matchedObj, ...filterItem };
      }
    }).filter(Boolean);
    setActiveFilterList([...filteredArray]);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let invalid = false;
    activeFilterList.forEach(obj => {
      if (!obj.required) return;
      if (!obj.validationFn(filterObj[obj.key])) invalid = true;
    });    
    if (invalid) {
      toast.warn('Por favor preencha os filtros obrigatórios corretamente.');
      return e.stopPropagation();
    }
    searchFn(filterObj);
  };

  return (
    <Form className={styles.form} onSubmit={e => handleSubmit(e)}>
      {activeFilterList.map(filter => {
        return (
          <Form.Group key={`filter_${filter.key}`} className={styles.form_group}>
            <Form.Label className={styles.filter_label}>{filter.label}</Form.Label>
            <Form.Control
              className={styles.filter_control}
              type={filter.type}
              placeholder={filter.label}
              value={filterObj[filter.key] || ''}
              onChange={e => setFilterObj({ ...filterObj, [filter.key]: filter.subFn(e.target.value) })}
              required={filter.required}
              isInvalid={filter.required && !filter.validationFn(filterObj[filter.key])}
            />
          </Form.Group>
        )
      })}
      <Button
        className={styles.form_group_btn}
        variant={btnVariant}
        type={'submit'}
      >
        Buscar
      </Button>

      {children && children}

    </Form>
  )
}

export default Filtro;