import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import styles from '../styles/MedicaoRapport.module.scss';
import Carregando from '../components/Carregando';
import CustomPagination from "../components/Utils/CustomPagination";
import { toast, ToastContainer } from 'react-toastify';
import { toUTCDDMMYYYY, toDatetime } from '../utils/dateUtils';
import CookiesService from "../services/cookies";
import OPInfoColumn from "../components/MedicaoRapport/OPInfoColumn";
import { api }  from "../services/api";

const MedicaoRapport = () => {

    const [mainData, setMainData] = useState([]);
    const [filterObj, setFilterObj] = useState({
        nf_entrada: '',
        material: '',
        cor_material: ''
    });

    const [dataModal, setDataModal] = useState([]);

    const [lineSelected, setLineSelected] = useState([]);

    const [tipoMaterial, setTipoMaterial] = useState('');

    const [selectAll, setSelectAll] = useState(false);

    const [arraySelecaoPecas, setArraySelecaoPecas] = useState([]);
    const [arrayPecasMetragem, setArrayPecasMetragem] = useState([]);
    const [selecaoPecaRapport, setSelecaoPecaRapport] = useState({});

    const [loading, setLoading] = useState(false);

    const [selected, setSelected] = useState(0);
    const [showModalPecas, setShowModalPecas] = useState(false);
    const [showModalRapport, setShowModalRapport] = useState(false);
    const [showModalAvisoPlotter, setShowModalAvisoPlotter] = useState(false);
    const [showModalPecaPlotter, setShowModalPecaPlotter] = useState(false);

    const [envioPlotter, setEnvioPlotter] = useState('');
    const [validacaoEnvio, setValidacaoEnvio] = useState(false);

    const [medidasPecaRapport, setMedidasPecaRapport] = useState({
        PECA: '',
        Metragem: '',
        medidaInfo: '',
        medidaUm: '',
        medidaDois: '',
        medidaTres: '',
        resultado: ''
    });

    const [menorMedida, setMenorMedida] = useState(null);
    const [menorMedidaIndex, setMenorMedidaIndex] = useState(null);

    const [pecasIncluded, setPecasIncluded] = useState([]);
    const [radioSelected, setRadioSelected] = useState(false);

    const [analiseRapport, setAnaliseRapport] = useState({});

    const userLogin = CookiesService.getUserLogin();

    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
	const [totalRecords, setTotalRecords] = useState();
    const quantityPerPage = 20;	

    const OpInfo = [
        "Pedido",
        "Fornecedor",
        "Nota Fiscal",
        "Série NF",
        "Data Registro",
        "Qtde. peças",
        "Tipo Material",
        "Item",
        "Material",
        "Cor Material",
        "Descrição Material",
        "Descrição cor material",
        "Tempo Descanso"
    ]

    const getTempoDescanso = (timestamp) => Math.floor((Date.now() - Date.parse(timestamp)) / 3600000);
    
    const handleShowModalPecas = (params) => { 
        params[4] = toUTCDDMMYYYY(params[4]) // DATAREGISTRO
        setLineSelected(params)
        setSelected(1)
    }
    
    const handleCloseModalPecas = () => {
        setLineSelected([]);
        setSelected(0);
        setDataModal([]);
        setMedidasPecaRapport({
            PECA: '',
            Metragem: '',
            medidaInfo: '',
            medidaUm: '',
            medidaDois: '',
            medidaTres: '',
            resultado: ''
        });
        setTipoMaterial('');
        setAnaliseRapport({});
        setArraySelecaoPecas([]);
        setSelectAll(false);
        setArrayPecasMetragem([]);
        setShowModalPecas(false);
        setValidacaoEnvio(false);
    };

    const handleShowModalRapport = () => {
        if(arraySelecaoPecas.length === 0){
          toast.warning('Selecione, ao menos, uma peça antes de avançar!');
          return;
        }
        const pecasMetragem = arraySelecaoPecas.map(({PECA, QTDE}) => ({peca: PECA, metragem: QTDE}));
        setArrayPecasMetragem(pecasMetragem);
        setShowModalRapport(true);

    };

    const handleShowModalAvisoPlotter = (item) => {
        const dataAtual = new Date()
        const dataPlotter = new Date(item.DATAENVIOPLOTTER)
        const diffMeses = (dataAtual.getFullYear() - dataPlotter.getFullYear()) * 12 
                        + (dataAtual.getMonth() - dataPlotter.getMonth());

        setValidacaoEnvio(diffMeses > 2);
        
        setShowModalAvisoPlotter(true);
        handlePecas(item);
    }

    const handleCloseModalAvisoPlotter = () => {
        setShowModalAvisoPlotter(false);
        setShowModalPecas(true);
    }

    const handleShowModalPecaPlotter = () => {
        if (verifyArrayPecas === false){
            toast.warning('É necessário incluir todas as peças antes de continuar.');
            return;
        }  
        setShowModalPecaPlotter(true)
    }

    const handleCloseModalPecaPlotter = () => {
        setShowModalPecaPlotter(false)
    }

    const handleCloseModalRapport = () => {
        setMedidasPecaRapport({
            ...medidasPecaRapport,
            Metragem: '',
            IdStatus: '',
            PECA: '',
            medidaInfo: '',
            medidaUm: '',
            medidaDois: '',
            medidaTres: '',
            resultado: ''
        });
        setPecasIncluded([]);
        setArrayPecasMetragem([]);
        setShowModalRapport(false);
        setEnvioPlotter('')
        setMenorMedida(null);
        setMenorMedidaIndex(null)
    }

    const handleInputChange = (event, field) => {
        const value = event.target.value;
        switch (field) {
          case "medidasDaInfo":
            if (value >= 0) {
              setMedidasPecaRapport({...medidasPecaRapport, 
                                        medidaInfo: value
                                    })
            } 
            break;
          case "medidaEncontradaUm":
            if (value >= 0) {
              setMedidasPecaRapport({...medidasPecaRapport, 
                                        medidaUm: value
                                    })
            }
            break;
          case "medidaEncontradaDois":
            if (value >= 0) {
              setMedidasPecaRapport({...medidasPecaRapport, 
                                        medidaDois: value
                                    })
            }
            break;
          case "medidaEncontradaTres":
            if (value >= 0) {
              setMedidasPecaRapport({...medidasPecaRapport, 
                                        medidaTres: value
                                    })
            }
            break;
          default:
            break;
        }
    }

    const handleNegativeKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-|e/i.test(keyValue)) {
            event.preventDefault();
        }
    }

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearch = async () => {
        setLoading(true);
        const params = {
          ...filterObj
        }
        if (Object.keys(params).length < 1) {
          setLoading(false);
          return toast.warn('Por favor preencha ao menos um campo de busca');
        }
    
        await api.get('MPMedicaoRapport/get-lista', { params })
          .then(res => {
            setMainData(res.data);
            setTotalRecords(res.data.length)
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
        setLoading(false);
    }

    const handlePecas = async (params) => {
        const pecaInfo = Object.values(params)
        getTempoDescanso(params.DATAREGISTRO) > 1 ? 
            pecaInfo.push(getTempoDescanso(params.DATAREGISTRO).toString() + ' horas') : 
            pecaInfo.push(getTempoDescanso(params.DATAREGISTRO).toString() + ' hora')
        handleShowModalPecas(pecaInfo);

        setTipoMaterial(params.TIPOMATERIAL);
        
        setAnaliseRapport({
            DataRegistro: toDatetime(new Date()),
            Pedido: params.PEDIDO,
            NfEntrada: params.NFENTRADA,
            SerieNf: params.SERIENF,
            Item: params.ITEM,
            Fornecedor: params.FORNECEDOR,
            QtdPecas: params.QTDEPECA,
            Material: params.MATERIAL,
            CorMaterial: params.CORMATERIAL,
            TipoMaterial: params.TIPOMATERIAL
        });

        setMedidasPecaRapport({
            DataRegistro: toDatetime(new Date()),
            Pedido: params.PEDIDO,
            NfEntrada: params.NFENTRADA,
            SerieNf: params.SERIENF,
            Item: params.ITEM,
            Fornecedor: params.FORNECEDOR,
            QtdPecas: params.QTDEPECA,
            Material: params.MATERIAL,
            CorMaterial: params.CORMATERIAL,
            TipoMaterial: params.TIPOMATERIAL,
            Tolerancia: params.TIPOMATERIAL === "MALHA" ? 7 : 5,
            Metragem: '',
            IdStatus: '',
            PECA: '',
            medidaInfo: '',
            medidaUm: '',
            medidaDois: '',
            medidaTres: '',
            resultado: ''
        });

        await api.get('MPMedicaoRapport/get-pecas', { params })
          .then(res => {
            setDataModal(res.data);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição '' - ${e}`);
          });
    }

    const handleCheckAll = (e) => {
      const isChecked = e.target.checked;
      setSelectAll(isChecked);
      setArraySelecaoPecas(isChecked ? dataModal : []);
    };

    const handleCheckPecas = (e, peca) => {
        const isChecked = e.target.checked;
        const updatedArray = isChecked
          ? [...arraySelecaoPecas, peca]
          : arraySelecaoPecas.filter(item => item !== peca);
        setArraySelecaoPecas(updatedArray);
      
        const allChecked = updatedArray.length === dataModal.length;
        setSelectAll(allChecked);
      };

    const handleCheckRadioPeca = (item) => {
        setSelecaoPecaRapport(item);
        setMedidasPecaRapport({...medidasPecaRapport, PECA: item.peca, Metragem: item.metragem})
        setRadioSelected(item);
    }

    const [verifyArrayPecas, setVerifyArrayPecas] = useState(false);
    const [verifyPecasIncluded, setVerifyPecasIncluded] = useState(false);

    const handleIncludeGrade = () => {
        const fieldEmpty = ( medidasPecaRapport.PECA       === '' || 
                             medidasPecaRapport.medidaUm   === '' || medidasPecaRapport.medidaUm   === '0' ||
                             medidasPecaRapport.medidaDois === '' || medidasPecaRapport.medidaDois === '0' ||
                             medidasPecaRapport.medidaTres === '' || medidasPecaRapport.medidaTres === '0' ||
                             medidasPecaRapport.medidaInfo === '' || medidasPecaRapport.medidaInfo === '0' )

        if(fieldEmpty){
            toast.warning('Selecione, ao menos, uma peça e confira se preencheu as medidas antes de incluir!');
            return;
        }

        setRadioSelected(false);
        setPecasIncluded([...pecasIncluded, medidasPecaRapport])
        setArrayPecasMetragem(arrayPecasMetragem.filter(item => item !== selecaoPecaRapport));
        setSelecaoPecaRapport({});
        setMedidasPecaRapport({
            ...medidasPecaRapport,
            Metragem: '',
            IdStatus: '',
            PECA: '',
            medidaInfo: '',
            medidaUm: '',
            medidaDois: '',
            medidaTres: '',
            resultado: ''
        });
    }

    const handleDeletePeca = (item) => {
        setRadioSelected(false);
        setPecasIncluded(pecasIncluded.filter(peca => peca.PECA !== item.PECA));
        setArrayPecasMetragem([...arrayPecasMetragem, {peca: item.PECA, metragem: item.Metragem}])
        setMedidasPecaRapport({
            ...medidasPecaRapport,
            Metragem: '',
            IdStatus: '',
            PECA: '',
            medidaInfo: '',
            medidaUm: '',
            medidaDois: '',
            medidaTres: '',
            resultado: ''
        });
    }

    const handleProcess = async () => {
        if (verifyArrayPecas === false){
            toast.warning('É necessário incluir todas as peças antes de continuar.');
            return;
        }  

        let send = {};
        if (validacaoEnvio)
            send = {analise: analiseRapport, pecas: pecasIncluded, plotter: envioPlotter}
        else
            send = {analise: analiseRapport, pecas: pecasIncluded}

        await api.post('MPMedicaoRapport/process-rapport', { send })
          .then(res => {
            if (res.status === 200) { 
                toast.success('Dados inseridos com sucesso!'); 
                handleCloseModalPecaPlotter()
                handleCloseModalRapport();
                handleCloseModalPecas();
                setMainData([]);
                setFilterObj({ nf_entrada: '', material: '', cor_material: '' });
                handleSearch();
            }
            else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
          }).catch(e => {
            toast.error(
                `Entre em contato com o suporte. ${e}`
            );
            console.error(e)
        });
    }

    const handleEnvioPlotter = () => {
        let allMedidas = [];
        let minIndex = 0;

        for (const [index, peca] of pecasIncluded.entries()) {
            const valoresPeca = Math.min(peca.medidaUm, peca.medidaDois, peca.medidaTres);
            allMedidas.push(valoresPeca);

            if ((valoresPeca < allMedidas[minIndex]) || valoresPeca == allMedidas[minIndex]) {
                minIndex = index;
            }
        }
        const min = Math.min(...allMedidas);

        setMenorMedida(min);
        setMenorMedidaIndex(minIndex);

        setEnvioPlotter(pecasIncluded[minIndex]);
    }

    function splitObjectIntoColumns(header, value) {
        let obj = {}
        header.forEach((v, i) => {
          obj[v] = value[i];
        })
    
        const entries = Object.entries(obj);
        
        const numEntries = entries.length;
    
        const numCols = 4;
        const targetNumPerCol = Math.ceil(numEntries / numCols);
    
        const columns = Array.from({ length: numCols }, (_, i) => {
          const start = i * targetNumPerCol;
          const end = start + targetNumPerCol;
          return entries.slice(start, end);
        });

        return columns.map((col, i) => {
          return (
            <OPInfoColumn
              key={i}
              columns={col.map(([label, value]) => ({ label, value }))}
            />
          );
        });
    }

    useEffect(() => {
        handleSearch();
    }, []);

    useEffect(() => {                 
        setCurrentData(mainData.slice(
            ((currentPage-1) * quantityPerPage), (currentPage * quantityPerPage)
          ));
    },[currentPage, mainData]);

    useEffect(() => {
        if (arraySelecaoPecas.length === dataModal.length && arraySelecaoPecas.length !== 0 && dataModal.length !== 0){
            setSelectAll(true);
        } else setSelectAll(false);
    }, [arraySelecaoPecas]);

    useEffect(() => {
        let maxDiff = 0;
        if (tipoMaterial === 'MALHA') maxDiff = 0.07
        else if (tipoMaterial === 'TECIDO') maxDiff = 0.05;
                
        if ( Math.abs((medidasPecaRapport.medidaInfo - medidasPecaRapport.medidaUm   ) / medidasPecaRapport.medidaInfo) > maxDiff || 
             Math.abs((medidasPecaRapport.medidaInfo - medidasPecaRapport.medidaDois ) / medidasPecaRapport.medidaInfo) > maxDiff ||
             Math.abs((medidasPecaRapport.medidaInfo - medidasPecaRapport.medidaTres ) / medidasPecaRapport.medidaInfo) > maxDiff ) {
               return setMedidasPecaRapport({ ...medidasPecaRapport, resultado: 'Reprovado', IdStatus: 8 })
        } else return setMedidasPecaRapport({ ...medidasPecaRapport, resultado: 'Aprovado', IdStatus: 4 })
    }, [ medidasPecaRapport.medidaUm,
         medidasPecaRapport.medidaDois,
         medidasPecaRapport.medidaTres,
         tipoMaterial ]
    )
         
    useEffect(() => {
        if (arrayPecasMetragem.length === 0) setVerifyArrayPecas(true)
        else setVerifyArrayPecas(false)

        if (pecasIncluded.length > 0) {
            setVerifyPecasIncluded(true)
            setMedidasPecaRapport({...medidasPecaRapport, medidaInfo: pecasIncluded[0].medidaInfo})

            const reprovado = pecasIncluded.some((item) => item.resultado === "Reprovado")

            if (reprovado) {
                setAnaliseRapport({...analiseRapport
                                    , MedidaInfo: pecasIncluded[0].medidaInfo
                                    , IdStatusFinal: 8
                                    , IdUsuario: userLogin
                                })
            } else {
                setAnaliseRapport({...analiseRapport
                                    , MedidaInfo: pecasIncluded[0].medidaInfo
                                    , IdStatusFinal: 4
                                    , IdUsuario: userLogin
                                })                
            }
            if (validacaoEnvio)
                handleEnvioPlotter();
        }
        else { 
            setVerifyPecasIncluded(false)
            setEnvioPlotter('');
        }

    }, [pecasIncluded, arrayPecasMetragem])

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <article>
                <h3 className="p-3">Medição de Rapport</h3>
                <Form className={styles.formWrapper}>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Nota fiscal'}
                        className={styles.formControl}
                        value={filterObj.nf_entrada || ''}
                        onChange={(event) => setFilterObj({...filterObj, nf_entrada: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Material'}
                        className={styles.formControl}
                        value={filterObj.material || ''}
                        onChange={(event) => setFilterObj({...filterObj, material: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Cor Material'}
                        className={styles.formControl}
                        value={filterObj.cor_material || ''}
                        onChange={(event) => setFilterObj({...filterObj, cor_material: event.target.value})}
                    />
                </Form.Group>
                <Button
                    type="button"
                    variant="primary"
                    className={styles.filterBtn}
                    onClick={handleSearch}
                >
                    Buscar
                </Button>
                </Form>
            </article>

            <CustomPagination 
                totalRecords={totalRecords}
                quantityPerPage={quantityPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            ></CustomPagination>

            <article className={styles.articleGray}>
                { loading ?
                    <Carregando></Carregando>
                : 
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}>DATA REGISTRO</th>
                            <th className={styles.blockTitle}>PEDIDO</th>
                            <th className={styles.blockTitle}>NOTA FISCAL</th>
                            <th className={styles.blockTitle}>SERIE NF</th>
                            <th className={styles.blockTitle}>ITEM</th>
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>QTDE PEÇAS</th>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                            <th className={styles.blockTitle}>COR MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO COR MATERIAL</th>
                            <th className={styles.blockTitle}>TIPO MATERIAL</th>
                            <th className={styles.blockTitle}>TEMPO DESCANSO</th>
                        </tr>
                    </thead>
                    <tbody>
                        { currentData.map((item,index) => {
                            let tempo_descanso = getTempoDescanso(item.DATAREGISTRO)
                            let botao_habilitado = (tempo_descanso >= 48 && item.TIPOMATERIAL === 'MALHA') || item.TIPOMATERIAL === 'TECIDO'
                        return (
                        <tr key={index}>
                            { botao_habilitado ? 
                            <td>
                                <i 
                                    style={{ 'cursor': 'pointer' }}
                                    className={styles.icon}
                                    onClick={() => handleShowModalAvisoPlotter(item)}
                                >
                                    <MaterialIcon
                                        icon="send"
                                        size={30}
                                        color='#0D6EFD'
                                    />
                                </i>
                            </td> 
                            :
                            <td aria-disabled>
                                <i 
                                    style={{ 'cursor': 'pointer' }}
                                    className={styles.icon}
                                    disabled
                                >
                                    <MaterialIcon
                                        icon="send"
                                        size={30}
                                        color='#C4C4C4'
                                    />
                                </i>
                            </td>
                            }
                            <td className={styles.tableLines}>{toUTCDDMMYYYY(item.DATAREGISTRO)}</td>
                            <td className={styles.tableLines}>{item.PEDIDO}</td>
                            <td className={styles.tableLines}>{item.NFENTRADA}</td>
                            <td className={styles.tableLines}>{item.SERIENF}</td>
                            <td className={styles.tableLines}>{item.ITEM}</td>
                            <td className={styles.tableLines}>{item.FORNECEDOR}</td>
                            <td className={styles.tableLines}>{item.QTDEPECA}</td>
                            <td className={styles.tableLines}>{item.MATERIAL}</td>
                            <td className={styles.tableLines}>{item.DESCRICAOMATERIAL}</td>
                            <td className={styles.tableLines}>{item.CORMATERIAL}</td>
                            <td className={styles.tableLines}>{item.DESCRICAOCOR}</td>
                            <td className={styles.tableLines}>{item.TIPOMATERIAL}</td>
                            { botao_habilitado ? 
                                <td className={styles.tableLinesBlue}>
                                    {tempo_descanso}hora(s)
                                </td>
                                :
                                <td className={styles.tableLinesRed}>
                                    {tempo_descanso} hora(s)
                                </td>
                            }
                        </tr>
                        )})}
                    </tbody>
                </Table>
                }
            </article>

            {/* Modal - Escolher peças */}
            <Modal
                show={showModalPecas}
                onHide={handleCloseModalPecas}
                fullscreen={false}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Escolha a(s) peça(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <article className={styles.articleGray}>
                        <Table className="m-2" responsive>
                            <thead>
                                <tr>
                                    <th className={styles.blockTitle}>
                                        <Form>
                                            <Form.Check 
                                                type='checkbox'
                                                onChange={(e) => handleCheckAll(e)}
                                                checked={selectAll}
                                            />
                                        </Form>
                                    </th>
                                    <th className={styles.blockTitle}>PEÇA</th>
                                    <th className={styles.blockTitle}>METRAGEM</th>
                                </tr>
                            </thead>
                            <tbody>
                                { dataModal.map((item, index) => {
                                return (
                                <tr key={index}>
                                    <td className={styles.tableLines}>
                                        <Form>
                                            <Form.Check 
                                                type='checkbox'
                                                onChange={(e) => handleCheckPecas(e, item)}
                                                checked={arraySelecaoPecas.includes(item)}
                                            />
                                        </Form>
                                    </td>
                                    <td className={styles.tableLines}>{item.PECA}</td>
                                    <td className={styles.tableLines}>{item.QTDE}</td>
                                </tr>
                            )})}
                            </tbody>
                        </Table>
                    </article>

                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={handleCloseModalPecas} 
                    >
                        Cancelar
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleShowModalRapport}
                    >
                        Avançar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal - Aviso Plotter */}

            <Modal
                show={showModalAvisoPlotter}
                onHide={handleCloseModalAvisoPlotter}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Enviar Plotter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={styles.flexAviso}>
                        <strong>ATENÇÃO!!</strong>
                        {
                            validacaoEnvio 
                                ? <div>É preciso retirar amostra para envio plotter.</div> 
                                : <div><strong className={styles.pecaPlotter}>NÃO</strong> retirar amostra para envio plotter.</div>
                        }
                    </span>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={() => handleCloseModalAvisoPlotter()}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal - Peca Plotter */}

            <Modal
                show={showModalPecaPlotter}
                onHide={handleCloseModalPecaPlotter}
                backdrop="static"
                keyboard={false}
                centered
                className='bg-dark bg-gradient'
            >
                <Modal.Header>
                    <Modal.Title>Enviar Plotter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={styles.flexAviso}>
                        <strong>ATENÇÃO!!</strong>
                        <div>Retirar plotter da peça <span className={styles.pecaPlotter}><em>{envioPlotter && envioPlotter.PECA}</em></span>.</div>
                    </span>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={handleProcess}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal - Medição Rapport */}

            <Modal
                show={showModalRapport}
                onHide={handleCloseModalRapport}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                <Modal.Title>Medição de Rapport</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Accordion >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Dados do pedido</Accordion.Header>
                        <Accordion.Body className={styles.infoBlockDisplay}>
                            {splitObjectIntoColumns(
                                OpInfo,
                                !!selected
                                ? lineSelected
                                : Array.from('-'.repeat(OpInfo.length)).map(v => v.repeat(3))
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <article className={styles.articleGrayRow}>
                        <div className={styles.articleItemMarginPecas}>
                            <h6>Peças</h6>
                            <div className={styles.articleItemWhite}>
                                <Table className={styles.tableStyle} responsive>
                                    <tbody>
                                    { arrayPecasMetragem.map((item, index) => {
                                    return (
                                        <tr className={styles.tableLines} key={index}>
                                            <td>
                                                <Form.Check 
                                                    type={'radio'} 
                                                    name='radioPecas' 
                                                    value={medidasPecaRapport.PECA} 
                                                    onChange={() => handleCheckRadioPeca(item)}
                                                    checked={radioSelected === item}
                                                    >
                                                </Form.Check>
                                            </td>
                                            <td className={styles.tableLines}>{item.peca}</td>
                                        </tr>
                                    )})}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className={styles.articleItemMargin}>
                            <Form className={styles.formModal}>
                                <Form.Group className={styles.filterInput}>
                                    <Form.Label className={styles.formLabel}>Medidas da info:</Form.Label>
                                    { verifyPecasIncluded ?
                                    <Form.Control
                                        type="number"
                                        placeholder={'0'}
                                        value={medidasPecaRapport.medidaInfo}
                                        onChange={(event) => handleInputChange(event, "medidasDaInfo")}
                                        onKeyPress={handleNegativeKeyPress}
                                        disabled
                                    />
                                    : 
                                    <Form.Control
                                        type="number"
                                        placeholder={'0'}
                                        value={medidasPecaRapport.medidaInfo}
                                        onChange={(event) => handleInputChange(event, "medidasDaInfo")}
                                        onKeyPress={handleNegativeKeyPress}
                                    />
                                    }
                                </Form.Group>
                                <Form.Group className={styles.filterInput}>
                                    <Form.Label className={styles.formLabel}>Resultado final:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={medidasPecaRapport.resultado}
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <div className={styles.articleItemMargin}>
                            <Form className={styles.formModal}>
                                <Form.Group className={styles.filterInput}>
                                    <Form.Label className={styles.formLabel}>Medida encontrada 1:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder={'0'}
                                        value={medidasPecaRapport.medidaUm}
                                        onChange={(event) => handleInputChange(event, "medidaEncontradaUm")}
                                        onKeyPress={handleNegativeKeyPress}
                                    />
                                </Form.Group>
                                <Form.Group className={styles.filterInput}>
                                    <Form.Label className={styles.formLabel}>Medida encontrada 2:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder={'0'}
                                        value={medidasPecaRapport.medidaDois}
                                        onChange={(event) => handleInputChange(event, "medidaEncontradaDois")}
                                        onKeyPress={handleNegativeKeyPress}
                                    />
                                </Form.Group>
                                <Form.Group className={styles.filterInput}>
                                    <Form.Label className={styles.formLabel}>Medida encontrada 3:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder={'0'}
                                        value={medidasPecaRapport.medidaTres}
                                        onChange={(event) => handleInputChange(event, "medidaEncontradaTres")}
                                        onKeyPress={handleNegativeKeyPress}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className={styles.articleItemMarginBottom}>
                            <div>
                                <Form className={styles.formModal}>
                                    <Form.Group className={styles.filterInput}>
                                        <Form.Label className={styles.formLabel}>Envio Plotter (Peça)</Form.Label>
                                        <Form.Control
                                            disabled
                                            type="text"
                                            value={envioPlotter ? envioPlotter.PECA : ''}
                                        />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div>
                                <Button
                                    className={styles.filterInput}
                                    variant="primary"
                                    type="button"
                                    onClick={handleIncludeGrade}
                                >
                                    Incluir Medidas
                                </Button>
                            </div>
                        </div>

                    </article>
                    
                    {/* tabela de medidas inseridas */}
                    <article>
                        <div className={styles.tableWrapper}>
                            <Table className={styles.tableStyle} responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className={styles.blockTitle}>PEÇAS</th>
                                        <th className={styles.blockTitle}>MEDIDA ENCONTRADA 1</th>
                                        <th className={styles.blockTitle}>MEDIDA ENCONTRADA 2</th>
                                        <th className={styles.blockTitle}>MEDIDA ENCONTRADA 3</th>
                                        <th className={styles.blockTitle}>RESULTADO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { pecasIncluded.map((item, index) => {
                                    return (
                                    item.PECA === envioPlotter.PECA ?
                                    <tr className='bg-secondary text-white' key={index}>
                                        <td>
                                            <i
                                                className={styles.icon}
                                                style={{ 'cursor': 'pointer' }}
                                                onClick={() => handleDeletePeca(item)}
                                            >
                                                <MaterialIcon
                                                    icon="delete"
                                                    size={20}
                                                    color='#BB271A'
                                                />
                                            </i>
                                        </td>
                                        <td className={styles.tableLines}>{item.PECA}</td>
                                        <td className={styles.tableLines}>{item.medidaUm + ' m'}</td>
                                        <td className={styles.tableLines}>{item.medidaDois + ' m'}</td>
                                        <td className={styles.tableLines}>{item.medidaTres + ' m'}</td>
                                        <td className={styles.tableLines}>{item.resultado}</td>
                                    </tr>
                                    :
                                    <tr className={styles.tableLines} key={index}>
                                        <td>
                                            <i
                                                className={styles.icon}
                                                style={{ 'cursor': 'pointer' }}
                                                onClick={() => handleDeletePeca(item)}
                                            >
                                                <MaterialIcon
                                                    icon="delete"
                                                    size={20}
                                                    color='#BB271A'
                                                />
                                            </i>
                                        </td>
                                        <td className={styles.tableLines}>{item.PECA}</td>
                                        <td className={styles.tableLines}>{item.medidaUm + ' m'}</td>
                                        <td className={styles.tableLines}>{item.medidaDois + ' m'}</td>
                                        <td className={styles.tableLines}>{item.medidaTres + ' m'}</td>
                                        <td className={styles.tableLines}>{item.resultado}</td>
                                    </tr>
                                    )})}
                                </tbody>
                            </Table>
                        </div>
                    </article>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button 
                        variant="outline-danger"
                        onClick={handleCloseModalRapport}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        variant="primary"
                        onClick={validacaoEnvio ? handleShowModalPecaPlotter : handleProcess}
                    >
                        Processar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MedicaoRapport;