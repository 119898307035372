import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { api } from "../services/api";
import axios from "axios";
import { useAuth } from '../contexts/AuthContext';
import { ProgressBar } from 'react-bootstrap';

const Auth = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, authLoading, setAuthLoading } = useAuth();
  const [authLoadingProgress, setAuthLoadingProgress] = useState({ now: 0, label: '' });

  useEffect(() => {
    const checkCookies = async (redirectUrl) => {
      const userDataCookie = Cookies.get('user_data');
      const jwtTokenCookie = Cookies.get('jwt_token');

      if (userDataCookie && jwtTokenCookie) {
        setAuthLoadingProgress({ now: 100, label: 'Bem-vindo(a)!' });
        await new Promise(resolve => setTimeout(resolve, 300));

        setIsAuthenticated(true);
        return navigate(redirectUrl);
      }
      
      setTimeout(checkCookies, 1000);
    };

    const handleAuth = async () => {
      setAuthLoading(true);
      setAuthLoadingProgress({ now: 0, label: 'Validando informações do usuário...' });
      await new Promise(resolve => setTimeout(resolve, 300));

      const urlParams = new URLSearchParams(window.location.search);
      const jwtToken = urlParams.get('jwt_token');
      const redirectTo = urlParams.get('redirectTo') || '/';

      if (jwtToken) {
        try {
          Cookies.remove('user_data');
          Cookies.remove('jwt_token');

          let data;

          const result = await axios.get(process.env.REACT_APP_AUTH, { headers: { authorization: jwtToken } });
          data = result.data;

          setAuthLoadingProgress({ now: 25, label: 'Validando acessos...' });
          await new Promise(resolve => setTimeout(resolve, 300));

          const accessResponse = await api.get('/controle-acesso/fetch-acessos-by-user-id', { params: { id_usuario: data.id_usuario } });
          data.acessos = accessResponse.data;

          setAuthLoadingProgress({ now: 50, label: 'Validando acessos...' });
          await new Promise(resolve => setTimeout(resolve, 300));

          Cookies.set('user_data', JSON.stringify(data), { expires: 365, secure: process.env.NODE_ENV === 'production', sameSite: 'Strict' });
          Cookies.set('jwt_token', jwtToken, { expires: 365, secure: process.env.NODE_ENV === 'production', sameSite: 'Strict' });

          setAuthLoadingProgress({ now: 75, label: 'Validando cookies...' });
          await new Promise(resolve => setTimeout(resolve, 300));

          checkCookies(redirectTo);
        } catch (e) {
          toast.error('Token inválido. Redirecionando para a página de login.');
          window.location = process.env.REACT_APP_PLM;
        } finally {
          setAuthLoading(false);
        }
      }
      if (!jwtToken) window.location = process.env.REACT_APP_PLM;
    }

    handleAuth();
  }, []);

  {
    return authLoading &&
      <ProgressBar style={{ margin: '45vh 30% 0px' }} animated now={authLoadingProgress.now} label={authLoadingProgress.label} />
  }
};

export default Auth;
