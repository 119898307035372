import React, { useState } from 'react';
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';

import styles from '../../../styles/PA/ADM/Medidas.module.scss';
import RadioButtonList from '../../../components/Utils/RadioButtonList';
import { useEffect } from 'react';
import Carregando from '../../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import { api }  from '../../../services/api';
import { toDatetime } from '../../../utils/dateUtils';

const Medidas = () => {
	const [loading, setLoading] = useState(false)
	const [mainData, setMainData] = useState([]);
	const [filterObj, setFilterObj] = useState({});
	const [formObj, setFormObj] = useState({});
	const [formErrs, setFormErrs] = useState({});
	const [activeObj, setActiveObj] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [deleteQueue, setDeleteQueue] = useState({});
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [gradeList, setGradeList] = useState({ Grades: [], Medidas: [] });
	const [medidasCadastradas, setMedidasCadastradas] = useState([]);

	const handleClose = () => {
		setShowModal(false);
		setActiveObj({});
		setFormObj({});
		setFormErrs({});
		setMedidasCadastradas([]);
	}

	const handleOpenModal = async (el) => {
		const params = {
			NFEntrada: el.NFEntrada,
			SerieNF: el.SerieNF,
			Origem: el.Origem,
			Produto: el.Produto,
			CorProduto: el.CorProduto,
			QtdeEntrada: el.QtdeEntrada,
			FilialRevisao: el.FilialRevisao
		}

		await api.get('/PAAdmControle/M_GetMedidasCadastradasList', { params })
			.then(res => {
				if (res.status === 200) { setMedidasCadastradas(res.data); }
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao se preencher a lista de processos, por favor cheque sua conexão com a internet ou entre contato com o suporte');
				console.error(`Erro na requisição '/M_GetMedidasCadastradasList' - ${e}`);
			});

		setActiveObj(el);
		setShowModal(true);
	}

	const handleFiltering = async () => {
		setLoading(true);
		const params = {
			NFEntrada: filterObj.NFEntrada,
			Produto: filterObj.Produto,
			CorProduto: filterObj.CorProduto,
			OrdemServico: filterObj.OrdemServico,
			OrdemProducao: filterObj.OrdemProducao
		}

		await api.get('/PAAdmControle/M_GetMainData', { params })
			.then(res => {
				if (res.status === 200) { setMainData(res.data); }
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao se preencher a lista de processos, por favor cheque sua conexão com a internet ou entre contato com o suporte');
				console.error(`Erro na requisição '/M_GetMainData' - ${e}`);
			});
		setLoading(false);
	}

	const handleIncludeGrade = async () => {
		const errs = handleIncludeGradeValidation();
		if (Object.keys(errs).length > 0) return setFormErrs(errs);
		setFormErrs({});
		const params = {
			DataRegistro: toDatetime(new Date()),
			...activeObj,
			IDArea: 3, // ORIGINAMENTE ATRELADO AO LOGIN DO USUÁRIO, POR HORA FUI AUTORIZADO PELO RAFALE AUBINO A MANTÊ-LO HARDCODED;

			IDGrade: formObj.Grade.value,
			DescricaoGrade: formObj.Grade.label,
			IDMedida: formObj.Medida.value,
			Tamanho: parseFloat(formObj.MedidaProducao),
			TamanhoFichaTecnica: formObj.MedidaPiloto ? parseFloat(formObj.MedidaPiloto) : 0,
			Tolerancia: formObj.Tolerancia ? parseFloat(formObj.Tolerancia) : 0
		}

		await api.post('/PAAdmControle/M_PostMedida', params)
			.then(res => {
				if (res.status === 200 && res.data[0].mensagem === 1) {
					toast.success('Grade inserida com sucesso');
					handleOpenModal(activeObj);
					setFormObj({ Medida: null, Grade: null });
					setShowModal(true);
				} else if (res.status === 200 && res.data[0].mensagem === 0) {
					toast.warn('Grade já inserida, por favor altere o tamanho ou a medida');
				}
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao se inserir a nova grade, cheque sua conexão com a internet e/ou entre em contato com o suporte');
				console.error(`Erro na requisição '/M_PostMedida' - ${e}`);
			});
	}

	const handleIncludeGradeValidation = () => {
		const { MedidaProducao, Grade, Medida } = formObj;
		const errs = {}
		if (!Grade) errs.Grade = 'Selecione uma grade';
		if (!Medida) errs.Medida = 'Selecione uma medida';
		if (!MedidaProducao || MedidaProducao <= 0) errs.MedidaProducao = 'A medida piloto deve ser maior que zero';
		return errs;
	}

	const handleOpenConfirmationModal = (cadastro) => {
		setShowConfirmationModal(true);
		setDeleteQueue(cadastro);
	}

	const handleCloseConfirmation = () => {
		setShowConfirmationModal(false);
		setDeleteQueue({});
	}

	const handleDeleteGrade = async () => {
		const params = {
			...activeObj,
			DescricaoGrade: deleteQueue.DescricaoGrade,
			DescricaoMedida: deleteQueue.Medida
		}

		await api.post('/PAAdmControle/M_DeleteMedida', params)
			.then(res => {
				if (res.status === 200 && res.data[0].mensagem === 'EXCLUSÃO REALIZADA COM SUCESSO!') {
					toast.success('Grade removida com sucesso');
					handleOpenModal(activeObj);
					setShowConfirmationModal(false);
				}
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao se inserir a nova grade, cheque sua conexão com a internet e/ou entre em contato com o suporte');
				console.error(`Erro na requisição '/M_DeleteMedida' - ${e}`);
			});
	}

	const handleFetchGrades = async () => {
		const list = {}

		await api.get('PAADMControle/M_GetGradesList')
			.then(res => {
				if (res.status === 200) { list.Grades = res.data; }
			})
			.catch(e => {
				console.error(`Erro na requisição '/M_GetGradesList' - ${e}`);
			});

		await api.get('PAADMControle/M_GetMedidasList')
			.then(res => {
				if (res.status === 200) { list.Medidas = res.data; }
			})
			.catch(e => {
				console.error(`Erro na requisição '/M_GetMedidasList' - ${e}`);
			});

		if (list.Grades && list.Medidas) {
			setGradeList(list);
		} else {
			toast.error('Ocorreu um erro no carregamento da página, por favor cheque sua conexão com a internet e/ou entre em contato com o suporte');
		}
	}

	const handleValueMask = (field, value, isFilter = false) => {
		if (value && value !== undefined && isFilter === true) value = `${value.replace(/[^.0-9]*/g, '')}`;
		else if (value && value !== undefined && value !== '' && isFilter === false) value = `${value.replace(/[^0-9]*/g, '')}`;
		else if ((!value || value === '') && isFilter === false) value = 0;

		if (isFilter === true) setFilterObj({ ...filterObj, [field]: value });
		else if (isFilter === false && field !== 'Tolerancia') setFormObj({ ...formObj, [field]: (parseFloat(value) / 100).toFixed(2) });
		else setFormObj({ ...formObj, [field]: (parseFloat(value) / 1000).toFixed(3) });
	}

	const clearErrs = (str) => {
		setFormErrs({ ...formErrs, [str]: null });
	}

	useEffect(() => {
		handleFetchGrades();
	}, []);

	return (
		<div>
			<article>
				<h3 className="p-2">ADM PA - Medidas</h3>
				<Form className={styles.formWrapper}>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>NF Entrada</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.NFEntrada || ''}
							onChange={e => handleValueMask('NFEntrada', e.target.value, true)}
							placeholder={'Número da NF'}
						/>
					</Form.Group>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>Produto</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.Produto || ''}
							onChange={e => handleValueMask('Produto', e.target.value, true)}
							placeholder={'Produto'}
						/>
					</Form.Group>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>Cor Produto</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.CorProduto || ''}
							onChange={e => handleValueMask('CorProduto', e.target.value, true)}
							placeholder={'Cor Produto'}
						/>
					</Form.Group>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>Ordem Serviço</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.OrdemServico || ''}
							onChange={e => handleValueMask('OrdemServico', e.target.value, true)}
							placeholder={'Ordem Serviço'}
						/>
					</Form.Group>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>Ordem Produção</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.OrdemProducao || ''}
							onChange={e => handleValueMask('OrdemProducao', e.target.value, true)}
							placeholder={'Ordem Produção'}
						/>
					</Form.Group>
					<Button
						className={styles.filterBtn}
						variant="primary"
						type="button"
						onClick={handleFiltering}
					>
						Buscar
					</Button>
				</Form>
			</article>

			<article className={styles.articleGray}>
				<Table className="m-2" responsive>
					<thead>
						<tr>
							<th className={styles.blockTitle}></th>
							<th className={styles.blockTitle}>TRANSFERIDO</th>
							<th className={styles.blockTitle}>NF ENTRADA</th>
							<th className={styles.blockTitle}>SERIE NF</th>
							<th className={styles.blockTitle}>ORIGEM</th>
							<th className={styles.blockTitle}>PRODUTO</th>
							<th className={styles.blockTitle}>COR</th>
							<th className={styles.blockTitle}>QTDE ENTRADA</th>
							<th className={styles.blockTitle}>FILIAL REVISÃO</th>
							<th className={styles.blockTitle}>STATUS LINHA</th>
							<th className={styles.blockTitle}>ORDEM SERVIÇO</th>
							<th className={styles.blockTitle}>ORDEM PRODUÇÃO</th>
						</tr>
					</thead>
					<tbody>
						{loading ? <tr><td><Carregando></Carregando></td></tr> :
							mainData.map((el, index) => {
								return (
									<tr key={index}>
										<td>
											<i style={{ 'cursor': 'pointer' }}
												className={styles.icon}
												onClick={() => handleOpenModal(el)}
											>
												<MaterialIcon
													icon="straighten"
													size={30}
													color='#0D6EFD'
												/>
											</i>
										</td>
										<td className={styles.tableLines}>{el.TranferidoEstoque}</td>
										<td className={styles.tableLines}>{el.NFEntrada}</td>
										<td className={styles.tableLines}>{el.SerieNF}</td>
										<td className={styles.tableLines}>{el.Origem}</td>
										<td className={styles.tableLines}>{el.Produto}</td>
										<td className={styles.tableLines}>{el.CorProduto}</td>
										<td className={styles.tableLines}>{el.QtdeEntrada}</td>
										<td className={styles.tableLines}>{el.FilialRevisao}</td>
										<td className={styles.tableLines}>{el.StatusLinha}</td>
										<td className={styles.tableLines}>{el.OrdemServico}</td>
										<td className={styles.tableLines}>{el.OrdemProducao}</td>
									</tr>
								)
							})
						}
					</tbody>
				</Table>
			</article>

			<Modal
				show={showModal}
				onHide={handleClose}
				fullscreen={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>ADM PA - Medidas</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className={styles.modalBodyObservacao}>A inclusão das medidas independe da análise.</p>

					<Accordion defaultActiveKey={['0']} alwaysOpen>
						<Accordion.Item eventKey="0">
							<Accordion.Header>Dados do Pedido</Accordion.Header>
							<Accordion.Body className={styles.infoBlockDisplay}>
								<div className={styles.infoBlock}>
									<div>
										<div className={styles.infoBlockColumm}>
											<span>NF Entrada:</span>
											<span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Qtde. Recebida:</span>
											<span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
										</div>
									</div>
									<div>
										<div className={styles.infoBlockColumm}>
											<span>Série NF:</span>
											<span className={styles.fontBlue}>{activeObj.SerieNF}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Filial Revisão:</span>
											<span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
										</div>
									</div>
									<div style={{ width: '20%' }}>
										<div className={styles.infoBlockColumm}>
											<span>Origem:</span>
											<span className={styles.fontBlue}>{activeObj.Origem}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Ordem Produção:</span>
											<span className={styles.fontBlue}>{activeObj.OrdemProducao}</span>
										</div>
									</div>
									<div>
										<div className={styles.infoBlockColumm}>
											<span>Referência:</span>
											<span className={styles.fontBlue}>{activeObj.Produto}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Ordem Serviço:</span>
											<span className={styles.fontBlue}>{activeObj.OrdemServico}</span>
										</div>
									</div>
									<div>
										<div className={styles.infoBlockColumm}>
											<span>Cor:</span>
											<span className={styles.fontBlue}>{activeObj.CorProduto}</span>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>

					<article className={styles.articleGrayFlex}>
						<div className={styles.formModalWrapper}>
							<div>
								<h6>Grade</h6>
								<Form.Group className={formErrs.Grade ? styles.radioBtnListRed : styles.radioBtnList} controlId="Grade">
									{gradeList.Grades.map((grade, index) => {
										return (
											<Form.Check
												onChange={() => { setFormObj({ ...formObj, Grade: grade }); clearErrs('Grade'); }}
												type={'radio'}
												label={grade.label}
												name={'Grade'}
												key={index}
												id={`Grade${index}`}
												isInvalid={formErrs.Grade}
												checked={formObj.Grade === grade}
											/>
										)
									})}
								</Form.Group>
								<div className={formErrs.Grade ? styles.showFeedback : styles.hidden}>{formErrs.Grade}</div>
							</div>

							<div>
								<h6>Medida</h6>
								<Form.Group className={formErrs.Medida ? styles.radioBtnListRed : styles.radioBtnList} controlId="Medida">
									{gradeList.Medidas.map((medida, index) => {
										return (
											<Form.Check
												onChange={() => { setFormObj({ ...formObj, Medida: medida }); clearErrs('Medida'); }}
												type={'radio'}
												label={medida.label}
												name={'Medida'}
												key={index}
												id={`Medida${index}`}
												isInvalid={formErrs.Medida}
												checked={formObj.Medida === medida}
											/>
										)
									})}
								</Form.Group>
								<div className={formErrs.Medida ? styles.showFeedback : styles.hidden}>{formErrs.Medida}</div>
							</div>

							<Form className={styles.formModal}>
								<Form.Group className={styles.filterInput}>
									<Form.Label className={styles.modalFormLabel}>Medidas Piloto (cm):</Form.Label>
									<Form.Control
										type="text"
										value={formObj.MedidaPiloto || ''}
										onClick={() => clearErrs('MedidaPiloto')}
										onChange={e => handleValueMask('MedidaPiloto', e.target.value, false)}
										placeholder={'0.00'}
										isInvalid={formErrs.MedidaPiloto}
									/>
									<Form.Control.Feedback type='invalid'>{formErrs.MedidaPiloto}</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className={styles.filterInput}>
									<Form.Label className={styles.modalFormLabel}>Tolerância (cm):</Form.Label>
									<Form.Control
										type="text"
										value={formObj.Tolerancia || ''}
										onClick={() => clearErrs('Tolerancia')}
										onChange={e => handleValueMask('Tolerancia', e.target.value, false)}
										placeholder={'0.000'}
										isInvalid={formErrs.Tolerancia}
									/>
									<Form.Control.Feedback type='invalid'>{formErrs.Tolerancia}</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className={styles.filterInput}>
									<Form.Label className={styles.modalFormLabel}>Medidas Produção (cm):</Form.Label>
									<Form.Control
										type="text"
										value={formObj.MedidaProducao || ''}
										onClick={() => clearErrs('MedidaProducao')}
										onChange={e => handleValueMask('MedidaProducao', e.target.value, false)}
										placeholder={'0.00'}
										isInvalid={formErrs.MedidaProducao}
									/>
									<Form.Control.Feedback type='invalid'>{formErrs.MedidaProducao}</Form.Control.Feedback>
								</Form.Group>

								<Button
									className={styles.filterInput}
									variant="primary"
									type="button"
									onClick={handleIncludeGrade}
								>
									Incluir Medida
								</Button>
							</Form>
						</div>

						<div className={styles.tableWrapper}>
							<Table className={styles.tableStyle} responsive>
								<thead>
									<tr>
										<th></th>
										<th className={styles.blockTitle}>Grade</th>
										<th className={styles.blockTitle}>Descrição Medida</th>
										<th className={styles.blockTitle}>Medida Peça (cm)</th>
										<th className={styles.blockTitle}>Medida Ficha Técnica (cm)</th>
										<th className={styles.blockTitle}>Tolerância (cm)</th>
									</tr>
								</thead>
								<tbody>
									{medidasCadastradas.map((cadastro, index) => {
										return (
											<tr className={styles.tableLines} key={index}>
												<td>
													<i
														className={styles.icon}
														style={{ 'cursor': 'pointer' }}
														onClick={() => handleOpenConfirmationModal(cadastro)}
													>
														<MaterialIcon
															icon="delete"
															size={20}
															color='#BB271A'
														/>
													</i>
												</td>
												<td className={styles.tableLines}>{cadastro.DescricaoGrade}</td>
												<td className={styles.tableLines}>{cadastro.Medida}</td>
												<td className={styles.tableLines}>{parseFloat(cadastro.MedidaProducao).toFixed(2)}</td>
												<td className={styles.tableLines}>{parseFloat(cadastro.TamanhoFichaTecnica).toFixed(3)}</td>
												<td className={styles.tableLines}>{parseFloat(cadastro.Tolerancia).toFixed(2)}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</div>
					</article>

				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					<Button
						variant="primary"
						onClick={handleClose}
					>
						Concluir
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showConfirmationModal}
				onHide={handleCloseConfirmation}
				centered={true}
				size={'lg'}
			>
				<Modal.Header closeButton	>
					<h5>Deseja remover a medida {deleteQueue.Medida} na grade {deleteQueue.DescricaoGrade}?</h5>
				</Modal.Header>
				<Modal.Footer className={styles.modalFooter}>
					<Button
						variant="primary"
						onClick={() => handleDeleteGrade()}
					>
						Confirmar
					</Button>
					<Button
						variant="secondary"
						onClick={handleCloseConfirmation}
					>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>

			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default Medidas;