import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MiscProvider } from "./contexts/MiscContext";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import ModalCarregando from "./components/Misc/ModalCarregando/ModalCarregando";
import "bootstrap/dist/css/bootstrap.min.css";

function Main() {
  return (
    <div className="App">
      <BrowserRouter>
        <MiscProvider>
          <AuthProvider>
            <Navbar />
            <App />
            <ModalCarregando />
          </AuthProvider>
        </MiscProvider>
      </BrowserRouter>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Main />, rootElement);
