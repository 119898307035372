import React from "react";
import MonitorRevisaoAnaliseGrade from "../../components/PA/MonitorRevisao/AnaliseGrade/index";

const AnaliseGrade = () => {
    return (
        <div className="p-2">
          <h3>Análise Grade</h3>
          <MonitorRevisaoAnaliseGrade />
        </div>
    );
};

export default AnaliseGrade;