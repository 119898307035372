import React, { useState, useEffect } from "react";
import { Button, Table, Form } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { api }  from "../../../../services/api";
import CookiesService from "../../../../services/cookies";

import styles from "../../../../styles/AdmEnvioRetornoAmostra.module.scss";

function EnvioAmostra(props) {
    const [acao, setAcao] = useState([]);
    const [dataEnvio, setDataEnvio] = useState(new Date());
    const [motivo, setMotivo] = useState([]);
    const [motivoSelecionado, setMotivoSelecionado] = useState([]);
    const [setor, setSetor] = useState([]);
    const [setorSelecionado, setSetorSelecionado] = useState([]);
    const [observacao, setObservacao] = useState("");
    const [responsavel, setResponsavel] = useState("");
    const [lacre, setLacre] = useState("");
    const [listaRetornoAmostra, setListaRetornoAmostra] = useState([]);
    const [motivoNaoRetorno, setMotivoNaoRetorno] = useState([]);
    const userLogin = CookiesService.getUserLogin();

    useEffect(() => {
        api.get("CatalogoADM/ADMEnvioAcao").then((result) => {
            setAcao(result.data);
        });

        api.get("CatalogoADM/ADMEnvioMotivo").then((result) => {
            setMotivo(result.data);
        });

        api.get("CatalogoADM/ADMEnvioArea").then((result) => {
            setSetor(result.data);
        });

        api.get("CatalogoADM/ADMEnvioArea").then((result) => {
            setListaRetornoAmostra(result.data);
        });

        api.get("CatalogoADM/ADMEnvioNaoRetorno").then((result) => {
            setMotivoNaoRetorno(result.data);
        });
    }, []);

    async function processarEnvioDeAmostra() {
        let amostra = {
            ACAO: parseInt(props.acaoSelecionada),
            ID_CADASTRO: props.lista[0].idCadastro,
            ID_USUARIO: userLogin,
            ID_MOTIVO_ENVIO: parseInt(motivoSelecionado),
            ID_SETOR_ENVIO: parseInt(setorSelecionado),
            NOME_REPONSAVEL: responsavel,
            LACRE: lacre,
            DATA_ENVIO: dataEnvio,
            OBSERVACAO: observacao,
            ID_MOTIVO_NAO_RETORNO: null 
        };

        api
        .post(`/CatalogoADM/ADMEnvioRetornoProcessar`, amostra)
        .then((response) => {
            if (response.status === 200) {
                toast.success(Object.values(response.data[0])[0]);
                setTimeout(function () {}, 5000);
            } else {
                toast.error(
                    "Erro Status Code: " +
                    response.status +
                    ".\nEntre em contato com o suporte."
                );
            }
        });
    }

    return (
        <body className="m-3">
            <h5>Lista de Amostras</h5>

            <article className={styles.articleGray}>
                <Table className="m-2" responsive >
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>COR</th>
                            <th className={styles.blockTitle}>COD. FORN.</th>
                            <th className={styles.blockTitle}>ID_CADASTRO</th>
                            <th className={styles.blockTitle}>ID_LOCALIZACAO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.lista.map((locais) => {
                            return (
                                <tr>
                                    <td className={styles.tableLines}>{locais.material}</td>
                                    <td className={styles.tableLines}>{locais.CorMaterial}</td>
                                    <td className={styles.tableLines}>{locais.codClifor}</td>
                                    <td className={styles.tableLines}>{locais.idCadastro}</td>
                                    <td className={styles.tableLines}>{locais.idLocalizacao}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </article>

            <h5>Destino</h5>

            <article className={styles.articleRow}>
                <Form className={styles.formEnvioAmostra}>
                    <Form.Group className={styles.formGroupEnvioAmostra}>
                        <Form.Label>Motivo</Form.Label>
                        <Form.Select
                            onChange={(e) => {setMotivoSelecionado(e.target.value)}}
                        >
                            <option 
                                selected={true} 
                                disabled 
                                readOnly 
                            >
                                Motivo
                            </option>
                            {motivo.map((motivo) => {
                                return (
                                    <option value={Object.values(motivo)}>
                                        {Object.values(motivo)}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className={styles.formGroupEnvioAmostra}>
                        <Form.Label>Lacre:</Form.Label>
                        <Form.Control
                            type="text"
                            title="Somente números"
                            value={lacre}
                            onChange={(e) => setLacre(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Button 
                        className="mb-3" 
                        type="button" 
                        onClick={processarEnvioDeAmostra}
                    >
                        Processar
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable
                            pauseOnHover
                        />
                    </Button>
                </Form>

                <Form className={styles.formEnvioAmostra}>
                    <Form.Group className={styles.formGroupEnvioAmostra}>
                        <Form.Label>Setor</Form.Label>
                        <Form.Select
                            onChange={(e) => {setSetorSelecionado(e.target.value)}}
                        >
                            <option 
                                selected={true} 
                                disabled 
                                readOnly 
                            >
                                Setor
                            </option>
                            {setor.map((setor) => {
                                return (
                                    <option value={Object.values(setor)}>
                                        {Object.values(setor)}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className={styles.formGroupEnvioAmostra}>
                        <Form.Label>Data Envio:</Form.Label>
                        <Form.Control 
                            type="date"
                            value={dataEnvio}
                            onChange={(e) => setDataEnvio(e.target.value)}
                        >

                            </Form.Control>
                    </Form.Group>
                </Form>

                <Form className={styles.formEnvioAmostra}>
                    <Form.Group className={styles.formGroupEnvioAmostra}>
                        <Form.Label>Responsável:</Form.Label>
                        <Form.Control
                            type="text"
                            title="Este campo é obrigatório"
                            value={responsavel}
                            onChange={(e) => setResponsavel(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className={styles.formGroupEnvioAmostra}>
                        <Form.Label>Observação:</Form.Label>
                        <Form.Control
                            as="textarea" rows={1}
                            type="text"
                            title="Este campo não é obrigatório"
                            value={observacao}
                            onChange={(e) => {setObservacao(e.target.value)}}
                            required
                        />
                        
                    </Form.Group>
                </Form>
            </article>
        </body>
        
    )
}

export default EnvioAmostra;
 