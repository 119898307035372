import React, { useState, useEffect } from "react";
import { Form, Table, Button } from 'react-bootstrap';
import Select from 'react-select';
import { api }  from "../../../services/api";

import "react-toastify/dist/ReactToastify.min.css";
import styles from '../../../styles/PA/DirecionamentoPecasComp.module.scss'

const DirecionamentoPecasComp = (params) => {
  const { prodInfo, formObj, setFormObj, gradeDirecionamento, setGradeDirecionamento, gradeTamanhos, setGradeTamanhos, toast } = params;
  const [itemSelecionado, setItemSelecionado] = useState({});
  const [listDestinos, setListDestinos] = useState([]);

  const handleIncluirAvaliacao = async () => {
    if (formObj.qtdPecas > itemSelecionado.Qtde_Pendente) return toast.warn(`A quatidade informada é maior que a quantidade pendente para o tamanho selecionado!`);
    if (!itemSelecionado.Tamanho || !formObj.destino || !formObj.qtdPecas) return toast.error('Você deve informar a grade, destino e quantidade.');

    const itemParaInserir = {
      tamanho: itemSelecionado.Tamanho,
      destino: formObj.destino,
      qtdPecas: formObj.qtdPecas,
      obs: formObj.obs,
      tipoAnalise: '01 - PRODUÇÃO'
    }

    gradeDirecionamento.push(itemParaInserir);
    setGradeDirecionamento([...gradeDirecionamento]);

    gradeTamanhos.map((itemSelecionado, index) => {
      if (itemParaInserir.tamanho === itemSelecionado.Tamanho) {
        itemSelecionado.QtdeDirecionada = parseInt(itemSelecionado.QtdeDirecionada) + parseInt(itemParaInserir.qtdPecas)
        itemSelecionado.Qtde_Pendente = parseInt(itemSelecionado.Qtde) - itemSelecionado.QtdeDirecionada
      }
    });
    setFormObj({});
    setItemSelecionado({});
  }

  useEffect(() => {
    const params = {
      Produto: prodInfo.Produto,
      CorProduto: prodInfo.CorProduto,
      NFEntrada: prodInfo.NFEntrada,
      SerieNF: prodInfo.SerieNF,
      Origem: prodInfo.Origem,
      OrdemProducao: prodInfo.OrdemProducao,
      QtdeEntrada: prodInfo.QtdeEntrada,
      FilialRevisao: prodInfo.FilialRevisao,
      TipoAnalise: '01 - PRODUÇÃO'
    }
    if (!params.Produto) return; // Garante que esse useEffect não rode durante o primeiro render.

    (async () => {
      if (!listDestinos.length > 0) {
        await api.get('PAMonitorCheckList/ListaDestino')
          .then(res => {
            const list = [];
            for (let i = 0; i < res.data.length; i++) {
              list.push({ value: res.data[i].IDDestino, label: res.data[i].Destino });
            }
            setListDestinos(list);
          }).catch(e => {
            console.error(e);
            toast.error('Ocorreu um erro, por favor recarregue a página ou entre em contato com o suporte');
          });
      }

      await api.get("PAMonitorCheckList/ListaDirecionamento", { params })
        .then(res => {
          setGradeTamanhos(res.data);
        }).catch(e => {
          console.error(e);
          toast.error('Ocorreu um erro, por favor recarregue a página ou entre em contato com o suporte');
        });
    })();
    setGradeDirecionamento([]);
  }, [prodInfo]);

  const handleExcluiAvaliacao = async (itemExclusao, index) => {
    gradeTamanhos.map(item => {
      if (itemExclusao.tamanho === item.Tamanho) {
        item.QtdeDirecionada = parseInt(item.QtdeDirecionada) - parseInt(itemExclusao.qtdPecas);
        item.Qtde_Pendente = parseInt(item.Qtde) - parseInt(item.QtdeDirecionada);
      }
    });
    setGradeTamanhos([...gradeTamanhos.filter(el => el !== itemExclusao)]);
    gradeDirecionamento.splice(index, 1);
    setGradeDirecionamento([...gradeDirecionamento]);
  }

  const handleMask = (value) => {
    if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
    setFormObj({ ...formObj, qtdPecas: value });
  }

  return (
    <>
      <div className={styles.flexWrapper}>
        <div>
          <div className={styles.tableWrapper}>
            <Form.Label className={styles.formLabel}>Grade</Form.Label>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>Tamanho</th>
                  <th className={styles.blockTitle}>Qtde Recebida</th>
                  <th className={styles.blockTitle}>Qtde Direcionada</th>
                  <th className={styles.blockTitle}>Qtde Pendente</th>
                </tr>
              </thead>
              <tbody>
                {gradeTamanhos.map((item, index) => {
                  return (
                    <tr key={index}
                      onClick={() => { setItemSelecionado(item) }}>
                      <td className={styles.tableLines}>
                        <Form.Check
                          id={`itemGrade_` + item.Tamanho}
                          key={`itemGrade_` + item.Tamanho}
                          type={'radio'}
                          name={'gradeQualidade'}
                          value={item.Tamanho}
                          onChange={() => { setItemSelecionado(item) }}
                          checked={itemSelecionado === item}
                          disabled={formObj.EnviaAprovacao === true}
                        />
                      </td>
                      <td className={styles.tableLine}>
                        {item.Tamanho}
                      </td>
                      <td className={styles.tableLine}>
                        {item.Qtde}
                      </td>
                      <td className={styles.tableLine}>
                        {item.QtdeDirecionada}
                      </td>
                      <td className={styles.tableLine}>
                        {item.Qtde_Pendente}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className={styles.formWrapper}>
            <Form className={styles.form}>
              <div>
                <Form.Group style={{ width: '50%' }}>
                  <Form.Label className={styles.formLabel}>Qtde Peças</Form.Label>
                  <Form.Control className={styles.formControl}
                    type={'text'}
                    value={formObj.qtdPecas || ''}
                    onChange={e => handleMask(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group style={{ width: '50%' }}>
                  <Form.Label className={styles.formLabel}>Destino</Form.Label>
                  <Select
                    name={'statusCQPA'}
                    options={listDestinos}
                    value={formObj.destino || null}
                    onChange={e => setFormObj({ ...formObj, destino: e })}
                    menuPlacement={'top'}
                  />
                </Form.Group>
              </div>
              <Form.Group className={styles.formTextWrapper}>
                <Form.Label className={styles.formLabel}>Observação <span className={styles.spanOpcional}>(Opcional)</span></Form.Label>
                <Form.Control
                  className={styles.formControl}
                  as={'textarea'}
                  rows={3}
                  value={formObj.obs || ''}
                  onChange={e => setFormObj({ ...formObj, obs: e.target.value })}
                >
                </Form.Control>
              </Form.Group>
              <Button
                variant="success"
                className={styles.btnWidth}
                onClick={handleIncluirAvaliacao}
              >
                Incluir Direcionamento
              </Button>
            </Form>
          </div>
        </div>
        <div>
          <div className={styles.tableWrapper}>
            <Form.Label className={styles.formLabel}>Peças Direcionadas</Form.Label>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>Grade</th>
                  <th className={styles.blockTitle}>Tipo Análise</th>
                  <th className={styles.blockTitle}>Destino</th>
                  <th className={styles.blockTitle}>Qtde Peças</th>
                  <th className={styles.blockTitle}>Observação</th>
                </tr>
              </thead>
              <tbody>
                {gradeDirecionamento?.map((item, index) => {
                  return (
                    <tr key={`rowGradeDestino_${index}`}>
                      <td className={styles.removeBtn}>
                        <i
                          style={{color: '#E90101', fontWeight: 500, fontStyle: 'normal'}}
                          onClick={() => handleExcluiAvaliacao(item, index)}
                        >
                          Excluir
                        </i>
                      </td>
                      <td className={styles.tableLines}>{item.tamanho}</td>
                      <td className={styles.tableLines}>{item.tipoAnalise}</td>
                      <td className={styles.tableLines}>{item.destino.label}</td>
                      <td className={styles.tableLines}>{item.qtdPecas}</td>
                      <td className={styles.tableLines}>{item.obs}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

export default DirecionamentoPecasComp;
