import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useEnfesto } from '../../../../contexts/Corte/ControleEnfesto/EnfestoContext'; 

import styles from '../../../../styles/Corte/ControleEnfesto/Enfesto.module.scss';

const ModalFolhas = () => {

    const { showFolhas, handleCloseFolhas, folhasRealizadas, handleFolhasRealizadas, handleFinalizaEnfesto } = useEnfesto();

    return (
        <>
           <Modal
                show={showFolhas}
                onHide={handleCloseFolhas}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Finalizar Enfesto:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label><strong>Quantas folhas foram realizadas:</strong></Form.Label>
                            <Form.Control
                                type="text"
                                className={styles.formControl}
                                placeholder="Quantidade de Folhas"
                                value={folhasRealizadas || ''}
                                onChange={e => handleFolhasRealizadas(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseFolhas}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={"outline-success"}
                        className={styles.modalBtn}
                        onClick={handleFinalizaEnfesto}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalFolhas;
