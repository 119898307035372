import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap'
import styles from '../pa_revisao_sq_nv.module.scss';
import SelectInput from '../../../Misc/SelectInput/SelectInput';
import Tabela from '../../../Misc/Tabela/Tabela';
import { toast } from 'react-toastify';
import MaterialIcon from 'material-icons-react';
import { usePARevisaoSegundaQualidadeNV } from '../pa_revisao_sq_nv_context';

const PARevisaoSegundaQualidadeNVDirecionamento = () => {
  const { referenciaSelecionada, modalDirecionamento, setModalDirecionamento, listaDefeitos, listaDirecionamentos, gradeRevisao, setGradeRevisao, direcionamentoRevisao, setDirecionamentoRevisao, handleRemoverDirecionamento } = usePARevisaoSegundaQualidadeNV();

  const [formObj, setFormObj] = useState({ grade: null, defeito: [], direcionamento: null, quantidade: null });

  const direcionamento_columns = [
    {
      cell: (row, index) => {
        return (
          <div onClick={() => handleRemoverDirecionamento(row, index)}>
            <MaterialIcon icon="delete" size={20} color='#BB271A' />
          </div>
        )
      }, width: '60px'
    },
    { name: 'Tamanho', selector: row => row.grade.tamanho },
    { name: 'Quantidade', selector: row => row.quantidade },
    { name: 'Defeitos', selector: row => row.defeito.map(item => item.label).join(' | '), width: '60%' },
    { name: 'Direcionamento', selector: row => row.direcionamento.label },
  ];

  const handleSetFormObj = (field, value) => {
    if (field === 'quantidade' && parseInt(value) > formObj.grade.quantidade_aprovada) value = formObj.grade.quantidade_aprovada;
    if (field === 'grade') formObj.quantidade = 0;
    setFormObj({ ...formObj, [field]: value });
  };

  const inserirDefeito = () => {
    const { grade, defeito, direcionamento, quantidade } = formObj;
    const errs = [];
    try {
      if (!grade) errs.push('tamanho');
      if (defeito.length === 0) errs.push('defeito');
      if (!direcionamento) errs.push('direcionamento');
      if (!quantidade || quantidade === 0) errs.push('quantidade');
      if (errs.length > 0) return toast.warning('Por favor selecione e/ou preencha todos os campos antes de inserir um direcionamento.');
      handleInserirDirecionamento(formObj);
      setFormObj({ grade: null, defeito: [], direcionamento: null, quantidade: null });
    } catch (e) {
      toast.error('Houve um problema ao inserir o direcionamento, por favor tente novamente.');
    }
  }

  const customFilter = (option, searchText) => {
    if (!searchText) return true;
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  const handleInserirDirecionamento = (formObj) => {
    const { grade, quantidade, defeito, direcionamento } = formObj;
    try {
      direcionamentoRevisao.push({ grade, quantidade: parseInt(quantidade), defeito, direcionamento });
      gradeRevisao[grade.index_grade].quantidade_aprovada -= parseInt(quantidade);
      gradeRevisao[grade.index_grade].quantidade_defeituosa += parseInt(quantidade);
      gradeRevisao[grade.index_grade].sem_defeitos = false;
      setGradeRevisao([...gradeRevisao]);
      setDirecionamentoRevisao([...direcionamentoRevisao]);
    } catch (e) {
      throw 'Erro ao inserir direcionamento.\nPor favor tente novamente ou entre em contato com o suporte.';
    }
  }

  useEffect(() => {
    setFormObj({ grade: null, defeito: [], direcionamento: null, quantidade: null });
  }, [modalDirecionamento]);

  return (
    <Modal
      show={modalDirecionamento}
      backdrop={'static'}
      keyboard={false}
      size={'xl'}
      onHide={() => setModalDirecionamento(false)}
      centered={true}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header closeButton style={{ fontWeight: '500' }}>
        Revisão Segunda Qualidade Nati Vozza | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <div style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
          Importante: Peças sem defeito serão automaticamente direcionadas para o estoque.
        </div>
        <Form className={styles.defeitos_form}>
          <section>
            <label className={styles.form_label}>Selecionar Tamanho</label>
            <ListGroup className={styles.list_group}>
              {gradeRevisao.map((item, index) => {
                return (
                  <ListGroup.Item
                    className={styles.list_item}
                    key={item.tamanho}
                    action={true}
                    active={formObj.grade && formObj.grade.tamanho === item.tamanho}
                    onClick={e => { e.preventDefault(); handleSetFormObj('grade', { ...item, index }); }}
                    disabled={parseInt(item.quantidade_aprovada) === 0 || item.sem_defeitos}
                    variant={
                      parseInt(item.quantidade_aprovada) === 0 ? 'warning' : item.sem_defeitos ? 'secondary' : 'primary'
                    }
                  >
                    [{item.tamanho}] - Restam: {item.quantidade_aprovada}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </section>

          <section className={styles.flex_section}>
            <SelectInput
              label={'Defeito'}
              style={{ width: '33%' }}
              value={formObj.defeito}
              onChange={e => handleSetFormObj('defeito', e.splice(0, 5))}
              options={listaDefeitos}
              filterOption={customFilter}
              isMulti={true}
            />

            <SelectInput
              label={'Direcionamento'}
              style={{ width: '33%' }}
              value={formObj.direcionamento}
              onChange={e => handleSetFormObj('direcionamento', e)}
              options={listaDirecionamentos}
            />

            <Form.Group className={styles.select_group} style={{ width: '33%' }}>
              <Form.Label className={styles.form_label}>Quantidade Direcionada</Form.Label>
              <Form.Control
                type={'number'}
                placeholder={0}
                min={formObj.grade ? 1 : 0}
                max={formObj.grade ? formObj.grade.quantidade : 0}
                style={{ width: '100%', height: '38px' }}
                value={formObj.quantidade || 0}
                onChange={e => handleSetFormObj('quantidade', e.target.value)}
                disabled={!formObj.grade}
              />
            </Form.Group>
          </section>

          <section className={styles.flex_section}>
            <div style={{ width: '33%' }}></div>
            <div style={{ width: '33%' }}></div>
            <Button
              className={styles.btn_footer}
              variant={'outline-success'}
              style={{ width: '33%', fontSize: '13px' }}
              onClick={inserirDefeito}
            >
              Inserir
            </Button>
          </section>
        </Form>

        <section>
          <label className={styles.form_label}>Peças Defeituosas Direcionadas</label>
          <Tabela
            columns={direcionamento_columns}
            data={direcionamentoRevisao}
            pagination={false}
          />
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default PARevisaoSegundaQualidadeNVDirecionamento;
