import React, { useState, useEffect } from "react";
import { Button, Table, Form, Modal, Accordion } from "react-bootstrap";
import styles from "../styles/AgendamentoRevisao.module.scss";
import Select from "react-select";
import FormInput from "../components/AgendamentoRevisao/FormInput";
import TableRow from "../components/AgendamentoRevisao/TableRow";
import OPInfoColumn from "../components/AgendamentoRevisao/OPInfoColumn";
import { toast, ToastContainer } from "react-toastify";
import { api } from "../services/api";
import { useMisc } from '../contexts/MiscContext';

const AgendamentoRevisao = () => {
  const { showLoading, setShowLoading } = useMisc();
  const [acaoSelecionada, setAcaoSelecionada] = useState({
    label: "100%",
    value: "cemPorcento",
  });

  const [formData, setFormData] = useState({
    Nf: '',
    Material: '',
    Cor: '',
    Pedido: '',
    OS: ''
  });

  const [selected, setSelected] = useState(0);

  const [rows, setRows] = useState([]);

  const [showModalCemPorcento, setShowModalCemPorcento] = useState(false);
  const [showModalRetorno, setShowModalRetorno] = useState(false);

  const [formAgendado, setFormAgendado] = useState({
    OrdemServico: '',
    OrdemProducao: '',
    NF: '',
    Pedido: '',
    Material: '',
    Cor: '',
    QuantidadeEntrada: '',
    Fornecedor: '',
    AgendadoRevisao: 0,
    AgendadoLaboratorio: 0
  });

  const [marcadoRevisao, setMarcadoRevisao] = useState(false)

  const selecionarAcao = [
    { label: "100%", value: "cemPorcento" },
    { label: "Retorno", value: "retorno" },
  ];

  const handleCloseCemPorcento = () => {
    setShowModalCemPorcento(false);
    setSelected(0);
    setFormAgendado({
      OrdemServico: '',
      OrdemProducao: '',
      NF: '',
      Pedido: '',
      Material: '',
      Cor: '',
      QuantidadeEntrada: '',
      Fornecedor: '',
      AgendadoRevisao: 0,
      AgendadoLaboratorio: 0
    });
  }
  const handleShowModalCemPorcento = (i) => {
    setShowModalCemPorcento(true);
    setSelected(i);
  }

  const handleCloseRetorno = () => {
    setShowModalRetorno(false);
    setSelected(0);
    setFormAgendado({
      OrdemServico: '',
      OrdemProducao: '',
      NF: '',
      Pedido: '',
      Material: '',
      Cor: '',
      QuantidadeEntrada: '',
      Fornecedor: '',
      AgendadoRevisao: 0,
      AgendadoLaboratorio: 0
    });
  }
  const handleShowModalRetorno = (i) => {
    setShowModalRetorno(true);
    setSelected(i);
  };

  const handleRevisao = (event) => {
    if (event.target.checked) {
      setFormAgendado({
        ...formAgendado,
        AgendadoRevisao: 1
      })
    } else {
      setFormAgendado({
        ...formAgendado,
        AgendadoRevisao: 0
      })
    }
  }

  const handleLaboratorio = (event) => {
    if (event.target.checked) {
      setFormAgendado({
        ...formAgendado,
        AgendadoLaboratorio: 1
      })
    } else {
      setFormAgendado({
        ...formAgendado,
        AgendadoLaboratorio: 0
      })
    }
  }

  const handleProcessar = async () => {
    if (!marcadoRevisao) {
      toast.warning('Revisão e/ou Laboratório deverá ser marcado.');
      return;
    }

    setShowLoading(true); // Desativa o botão

    const send = { acao: acaoSelecionada?.value === "cemPorcento" ? 0 : 1, revisaoLaboratorio: formAgendado }

    try {
      const res = await api.post('/agendamento-revisao/agendamentoRevisaoProcessar', { send });
      if (res.status === 200) {
        if (acaoSelecionada?.value === "cemPorcento") {
          handleCloseCemPorcento();
        } else {
          handleCloseRetorno();
        }
        toast.success('Os dados foram salvos com sucesso!');
      }
    } catch (e) {
      toast.error('Ocorreu um erro ao salvar os dados, por favor entre em contato com o suporte.');
      console.error(`Erro na requisição /agendamento-revisao/agendamentoRevisaoProcessar - ${e}`);
    } finally {
      setShowLoading(false); // Reativa o botão
    }
  };

  useEffect(() => {
    setFormData({
      Nf: '',
      Material: '',
      Cor: '',
      Pedido: '',
      OS: ''
    });
    setFormAgendado({
      OrdemServico: '',
      OrdemProducao: '',
      NF: '',
      Pedido: '',
      Material: '',
      Cor: '',
      QuantidadeEntrada: '',
      Fornecedor: '',
      AgendadoRevisao: 0,
      AgendadoLaboratorio: 0
    });
    setRows([]);
    setSelected(0);
  }, [acaoSelecionada]);

  useEffect(() => {
    setMarcadoRevisao(false)
  }, [showModalCemPorcento, showModalRetorno])

  useEffect(() => {
    if (formAgendado.AgendadoLaboratorio === 1 || formAgendado.AgendadoRevisao === 1) setMarcadoRevisao(true)
    else setMarcadoRevisao(false)
  }, [formAgendado])

  const buscar = async () => {
    const acao = acaoSelecionada['value'] === 'cemPorcento' ? 0 : 1
    const info = { ...formData, acao }

    const fieldEmpty = acaoSelecionada['value'] === 'cemPorcento' ? !(!!info.Cor && !!info.Material && !!info.Nf && !!info.Pedido) : !info.OS

    if (fieldEmpty) {
      toast.warning('Campo(s) obrigatório(s) não preenchido(s)!');
      return;
    }

    let entries = Object.entries(info).reduce((params, v, index) => (params + `${v[0]}=${v[1]}&`), '');
    entries = entries.slice(0, entries.length - 1);
    // A partir daqui está quase tudo errado!
    try {
      setShowLoading(true);
      const res = await api.get('/agendamento-revisao/buscar?' + entries);
      const dataRows = res.data;
            
      setRows(dataRows.map(v => {
        if (v['DATAREVISAO']) {
          let rev_date = v['DATAREVISAO'].split('T')
          rev_date = [
            rev_date[0].split('-').reverse().join('/'),
            rev_date[1].slice(0, 5)
          ].join(' ');

          v['DATAREVISAO'] = rev_date
        }
        return Object.values(v);
      }));

      if (dataRows.length !== 0) {
        const dataForm = dataRows[0];
        if (acao === 0) {
          setFormAgendado({
            ...formAgendado,
            NF: dataForm.NF_ENTRADA,
            Pedido: dataForm.PEDIDO,
            Material: dataForm.MATERIAL,
            Cor: dataForm.COR_MATERIAL,
            QuantidadeEntrada: dataForm.QTDE_ENTRADA,
            Fornecedor: dataForm.FORNECEDOR
          })
        } else {
          setFormAgendado({
            ...formAgendado,
            OrdemServico: dataForm.ORDEM_SERVICO,
            OrdemProducao: dataForm.ORDEM_PRODUCAO
          })
        }
      }
    } catch (e) {
      toast.error('Erro ao realizar a busca!');
      console.error(e);
    } finally {
      setShowLoading(false);
    }
  }

  const headers = acaoSelecionada?.value === "cemPorcento"
    ? [
      "FORNECEDOR",
      "NF ENTRADA",
      "PEDIDO",
      "MATERIAL",
      "DESCRIÇÃO MATERIAL",
      "COR MATERIAL",
      "DESCRIÇÃO COR MATERIAL",
      "QTDE ENTRADA",
      "QTDE ESTOQUE",
      "TIPO COMPRA",
      "DATA REVISÃO",
      "ENTRADA REVISÃO POR",
    ]
    : [
      "ORDEM PRODUÇÃO",
      "ORDEM DE SERVIÇO",
      "FASE DE PRODUÇÃO",
      "ORIGEM",
      "MARCA",
      "PRODUTO",
      "DESCRIÇÃO PRODUTO",
      "QTDE PEÇAS",
      "TIPO ORDEM PRODUÇÃO",
      "COLEÇÃO",
    ];

  const OpInfo = acaoSelecionada?.value === "cemPorcento"
    ? [
      "Fornecedor",
      "NF entrada",
      "Pedido",
      "Material",
      "Descrição Material",
      "Cor Material",
      "Descrição cor material",
      "Qtde. entrada",
      "Qtde. estoque",
      "Tipo compra",
      "Data revisão",
      "Entrada revisado por"
    ]
    : [
      "Ordem de produção",
      "Ordem de serviço",
      "Fase de produção",
      "Origem",
      "Marca",
      "Produto",
      "Descrição produto",
      "Qtde peças",
      "Tipo ordem produção",
      "Coleção"
    ];

  const formDataHandler = (event, field) => {
    const formD = { ...formData };

    formD[field] = event.target.value;
    setFormData(formD);
  }

  function splitObjectIntoColumns(header, value) {
    let obj = {}
    header.forEach((v, i) => {
      obj[v] = value[i];
    })

    const entries = Object.entries(obj);

    const numEntries = entries.length;

    const numCols = 3;
    const targetNumPerCol = Math.ceil(numEntries / numCols);

    const columns = Array.from({ length: numCols }, (_, i) => {
      const start = i * targetNumPerCol;
      const end = start + targetNumPerCol;
      return entries.slice(start, end);
    });

    return columns.map((col, i) => {
      return (
        <OPInfoColumn
          key={i}
          columns={col.map(([label, value]) => ({ label, value }))}
        />
      );
    });
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <h3 style={{ margin: "20px" }}>Agendamento Revisão 100%/Retorno</h3>
      <article className={styles.headerWrapper}>
        <article className={styles.actionWrapper}>
          <div className="ms-2">
            <Form className={styles.form}>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Ação</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  backspaceRemovesValue={true}
                  placeholder={"Selecionar"}
                  value={acaoSelecionada}
                  options={selecionarAcao}
                  onChange={(e) => setAcaoSelecionada(e)}
                />
              </Form.Group>
            </Form>
          </div>
        </article>

        <article className={styles.formWrapper}>
          <Form className={styles.form}>
            {
              acaoSelecionada?.value === "cemPorcento"
                ? <div className="d-flex">
                  <FormInput
                    value={formData.Nf}
                    onChange={(e) => formDataHandler(e, 'Nf')}
                    label="Nota Fiscal*"
                    placeholder="Nota Fiscal"
                    type="text"
                  />
                  <FormInput
                    value={formData.Material}
                    onChange={(e) => formDataHandler(e, 'Material')}
                    label="Material*"
                    placeholder="Material"
                    type="text"
                  />
                  <FormInput
                    value={formData.Cor}
                    onChange={(e) => formDataHandler(e, 'Cor')}
                    label="Cor Material*"
                    placeholder="Cor Material"
                    type="text"
                  />
                  <FormInput
                    value={formData.Pedido}
                    onChange={(e) => formDataHandler(e, 'Pedido')}
                    label="Pedido*"
                    placeholder="Pedido"
                    type="text"
                  />
                </div>
                : <div className="d-flex">
                  <FormInput
                    value={formData.OS}
                    onChange={(e) => formDataHandler(e, 'OS')}
                    label="Ordem Serviço*"
                    placeholder="Ordem Serviço"
                    type="text"
                  />
                </div>
            }
            <Button
              className={styles.formGroup}
              variant="primary"
              type="button"
              onClick={buscar}
            >
              Buscar
            </Button>
          </Form>
        </article>
      </article>

      {/* Main Table */}
      <article className={styles.bodyWrapper}>
        <article className={styles.articleGrayAlt}>
          <Table responsive>
            <thead>
              <tr>
                <th className={styles.blockTitle}></th>
                {headers.map((h, i) => {
                  return <th className={styles.blockTitle} key={i}>{h}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {
                rows.map((r, i) => {
                  return (
                    <TableRow
                      headerlength={headers.length}
                      handleShowModal={
                        acaoSelecionada?.value === "cemPorcento"
                          ? () => handleShowModalCemPorcento(i + 1)
                          : () => handleShowModalRetorno(i + 1)
                      }
                      key={i}
                      rowData={r}
                    />
                  )
                })
              }
            </tbody>
          </Table>
        </article>
      </article>

      {/* Modal 100% */}
      <Modal
        show={showModalCemPorcento}
        onHide={handleCloseCemPorcento}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Agendar - 100%</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dados do Pedido</Accordion.Header>
                <Accordion.Body className={styles.infoBlockDisplay}>
                  {splitObjectIntoColumns(
                    OpInfo,
                    !!selected
                      ? rows[selected - 1]
                      : Array.from('-'.repeat(OpInfo.length)).map(v => v.repeat(3))
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </article>

          <article>
            <div className={styles.marginTop}>
              <Form className="m-2">
                <Form.Group
                  className={styles.formControl}
                  style={{ marginBottom: "20px" }}
                >
                  <Form.Label className={styles.formLabel}>
                    Selecione a análise:
                  </Form.Label>
                  <Form.Check type={"checkbox"} label={"Revisão"} name='agendado' value='revisao' onChange={handleRevisao} />
                  <Form.Check type={"checkbox"} label={"Laboratório"} name='agendado' value='laboratorio' onChange={handleLaboratorio} />
                </Form.Group>
              </Form>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseCemPorcento}
            className={styles.modalBtn}
          >
            Cancelar
          </Button>
          <Button variant="primary" className={styles.modalBtn} onClick={handleProcessar} disabled={showLoading}>
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Retorno */}
      <Modal
        show={showModalRetorno}
        onHide={handleCloseRetorno}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Agendar - Retorno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dados do Pedido</Accordion.Header>
                <Accordion.Body className={styles.infoBlockDisplay}>
                  {splitObjectIntoColumns(
                    OpInfo,
                    !!selected
                      ? rows[selected - 1]
                      : Array.from('-'.repeat(OpInfo.length)).map(v => v.repeat(3))
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </article>

          <article>
            <div className={styles.marginTop}>
              <Form className="m-2">
                <Form.Group
                  className={styles.formControl}
                  style={{ marginBottom: "20px" }}
                >
                  <Form.Label className={styles.formLabel}>
                    Selecione a análise:
                  </Form.Label>
                  <Form.Check type={"checkbox"} label={"Revisão"} name='agendado' value='revisao' onChange={handleRevisao} />
                  <Form.Check type={"checkbox"} label={"Laboratório"} name='agendado' value='laboratorio' onChange={handleLaboratorio} />
                </Form.Group>
              </Form>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseRetorno}
            className={styles.modalBtn}
          >
            Cancelar
          </Button>
          <Button variant="primary" className={styles.modalBtn} onClick={handleProcessar} disabled={showLoading}>
            Processar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AgendamentoRevisao;
