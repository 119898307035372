import { Modal, Form, Button, Container, Row, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react'

import { NavLink } from 'react-router-dom';

const CheckoutModalDiarioCorte = ({ 
  show, 
  onHide, 
  filtros, 
  processarFiltro, 
  autolimpar,
  nomeFiltro, 
  limpaFiltroFn,
  limparFn 
}) => {
    // eslint-disable-next-line no-undef
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [term, setTerm] = useState('')

    useEffect(() => { if (!autolimpar) setSelectedFilters([]) }, [autolimpar])
    
    const handleFilterChange = (e) => {
      const filterValue = e.target.value;
      if (e.target.checked) {
        setSelectedFilters([...selectedFilters, filterValue]);
      } else {
        setSelectedFilters(selectedFilters.filter((filter) => filter !== filterValue));
      }
    };
    
    const processar = () => {
      processarFiltro({ values: selectedFilters })
    }

    const filtroDiario = (data, term) => {
      if (!term) return data;

      const filtrado = data.filter((item) => item.toLowerCase().includes(term.toLowerCase()));
      return filtrado;
    }
  
    return (
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{nomeFiltro}: </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <nav>
              <ul style={{ paddingLeft: 0 }}>
                <li style={{ listStyle: 'none' }} onClick={() => { onHide(); limparFn(); }}>
                  <NavLink to="#" exact>
                    Limpar filtros
                  </NavLink>
                </li>
                <li style={{ listStyle: 'none' }} onClick={() => { onHide(); limpaFiltroFn(nomeFiltro) }}>
                  <NavLink to="#">
                    Limpar este filtro
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <FullScreenInput  search={{ setTerm }} />
          <div>
            <h5 style={{ marginBottom: '20px' }}></h5>
            <Form>
              {filtroDiario(filtros, term).map((filter, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={filter}
                  value={filter}
                  checked={selectedFilters.includes(filter)}
                  onChange={handleFilterChange}
                />
              ))}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => { onHide(); processar(); }}>
            Filtrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const FullScreenInput = ({ search }) => {
    return (
      <Container fluid className="vh-20 d-flex" style={{ paddingRight: '0' }}>
        <Row style={{ width: '100%' }}>
          <Col xs={12} className="text-center" style={{ paddingLeft: '0' }}>
            <Form>
              <Form.Group>
                <Form.Control style={{ width: '100%' }} type="text" onChange={(e) => search.setTerm(e.target.value)} placeholder="Digite aqui..." size="lg" />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default CheckoutModalDiarioCorte;