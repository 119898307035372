import React from 'react';
import { useEnfesto } from '../../../contexts/Corte/ControleEnfesto/EnfestoContext';
import styles from '../../../styles/Corte/ControleEnfesto/Enfesto.module.scss';

import MainTable from '../../../components/Corte/ControleEnfesto/Enfesto/MainTable';
import ModalInicia from '../../../components/Corte/ControleEnfesto/Enfesto/ModalInicia';
import ModalIniciado from '../../../components/Corte/ControleEnfesto/Enfesto/ModalIniciado';
import ModalMotivo from '../../../components/Corte/ControleEnfesto/Enfesto/ModalMotivo';
import ModalFolhas from '../../../components/Corte/ControleEnfesto/Enfesto/ModalFolhas';
import ModalSetup from '../../../components/Corte/ControleEnfesto/Enfesto/ModalSetup';


const Enfesto = () => {

    const { isSetup, isIniciado } = useEnfesto();
    
    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">Corte - Enfesto</h3>
            </article>

            <article className={styles.articleGray}>
                { isSetup ? <ModalSetup/> : (isIniciado ? <ModalIniciado/> :<MainTable/>) }
                <ModalInicia/>
                <ModalMotivo/>
                <ModalFolhas/>
            </article>
        </div>
    );
};

export default Enfesto;
