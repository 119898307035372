export function downloadFile (file, MIMEtype, fileTitle, extension) {
    const blob = new Blob([file], {type: `${MIMEtype}`});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileTitle}.${extension}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}