import React from 'react';
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import { Form, Button } from 'react-bootstrap';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 

const FilterComponent = () => {

    const { setFiltro, filtro } = useDistribuicaoDemanda();

    return (
        <Form.Group
            className={styles.filterText}
        >
            <Form.Control
                type="text"
                placeholder="Filtro"
                value={filtro}
                onChange={e => setFiltro(e.target.value)}
                className={styles.textControl}
            />
            <Button
                variant='danger'
                className={styles.xBtn}
                onClick={() => setFiltro('')}
            >
                X
            </Button>
        </Form.Group>
    );
};

export default FilterComponent;
