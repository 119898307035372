import React, { useEffect, useState } from "react";
import { Button, Table, Form, Modal } from "react-bootstrap";
import MaterialIcon from 'material-icons-react';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import styles from "../../styles/SalaAmostras.module.scss";

import { api }  from "../../services/api";
import CookiesService from "../../services/cookies";
import Select from "./Select";
import Carregando from "../Carregando"

function SalaAmostra() {
  
  const [idArara, setIdArara] = useState([]);
  const [idAraraDisponivel, setIdAraraDisponivel] = useState([]);

  const [localizacao, setLocalizacao] = useState([]);
  const [posicao, setPosicao] = useState([]);
  const [corArara, setCorArara] = useState([]);
  const [tipoArara, setTipoArara] = useState([]);
  const [andar, setAndar] = useState([]);
  const [lado, setLado] = useState([]);

  const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState("");
  const [posicaoSelecionada, setPosicaoSelecionada] = useState("");
  const [corAraraSelecionada, setCorAraraSelecionada] = useState("");
  const [tipoAraraSelecionada, setTipoAraraSelecionada] = useState("");
  const [qtdeLadosSelecionada, setQtdeLadosSelecionada] = useState("");
  const [qtdeAndaresSelecionada, setQtdeAndaresSelecionada] = useState("");

  const [idAraraSelecionadaEditada, setIdAraraSelecionadaEditada] =
    useState("");
  const [localizacaoSelecionadaEditada, setLocalizacaoSelecionadaEditada] =
    useState("");
  const [posicaoSelecionadaEditada, setPosicaoSelecionadaEditada] =
    useState("");
  const [corAraraSelecionadaEditada, setCorAraraSelecionadaEditada] =
    useState("");
  const [tipoAraraSelecionadaEditada, setTipoAraraSelecionadaEditada] =
    useState("");
  const [qtdeLadosSelecionadaEditada, setQtdeLadosSelecionadaEditada] =
    useState("");
  const [qtdeAndaresSelecionadaEditada, setQtdeAndaresSelecionadaEditada] =
    useState("");
  const [
    espacoAmostralSelecionadoEditado,
    setEspacoAmostralSelecionadoEditado,
  ] = useState("");
  const [comprimentoSelecionadoEditado, setComprimentoSelecionadoEditado] =
    useState("");
  const [
    capacidadePorAndarSelecionadaEditada,
    setCapacidadePorAndarSelecionadaEditada,
  ] = useState("");
  const [
    capacidadeTotalSelecionadaEditada,
    setCapacidadeTotalSelecionadaEditada,
  ] = useState("");

  const [comprimento, setComprimento] = useState(0);
  const [espacoAmostral, setEspacoAmostral] = useState(0);

  const [capacidadePorAndar, setCapacidadePorAndar] = useState(0);
  const [capacidadeTotal, setCapacidadeTotal] = useState(0);

  const userLogin = CookiesService.getUserLogin();

  const [cadastrarArara, setCadastrarArara] = useState([]);
  const [atualizarArara, setAtualizarArara] = useState([]);
  const [deletarArara, setDeletarArara] = useState([]);

  const [show, setShow] = useState(false);  
  const [carregando, setCarregando] = useState(false);

  async function handleFetchOptions () {
    await api.get("Catalogo/SalaCatalogoListAraras").then((result) => {
      const ultimoIdArara = result.data.pop();
      setIdAraraDisponivel(ultimoIdArara.idArara + 1);
    });

    setCarregando(true);
    await api.get("Catalogo/SalaCatalogoListAraras").then((result) => {
      setIdArara(result.data);      
      setCarregando(false);
    });

    await api.get("Catalogo/SalaCatalogoListLocalizacao").then((result) => {
      setLocalizacao(result.data);
    });

    await api.get("Catalogo/SalaCatalogoListPosicao").then((result) => {
      setPosicao(result.data);
    });

    await api.get("Catalogo/SalaCatalogoListCor").then((result) => {
      setCorArara(result.data);
    });

    await api.get("Catalogo/SalaCatalogoListTipoArara").then((result) => {
      setTipoArara(result.data);
    });
  }

  useEffect(() => {
    handleFetchOptions();
  }, []);

  useEffect(() => {
    setCadastrarArara({
      ACAO: 1,
      ID_ARARA: idAraraDisponivel,
      ID_LOCALIZACAO: parseInt(localizacaoSelecionada),
      ID_POSICAO: parseInt(posicaoSelecionada),
      ID_COR: parseInt(corAraraSelecionada),
      ID_TIPO: parseInt(tipoAraraSelecionada),
      QTDE_LADOS: qtdeLadosSelecionada,
      QTDE_ANDARES: qtdeAndaresSelecionada,
      COMPRIMENTO: comprimento,
      ESPACO_AMOSTRAL: espacoAmostral,
      CAPACIDADE_ANDAR: capacidadePorAndar,
      CAPACIDADE_TOTAL: capacidadeTotal,
      ID_USUARIO: userLogin,
      TIPO_ATUALIZACAO: 2,
    });

    setAtualizarArara({
      ACAO: 2,
      ID_ARARA: idAraraSelecionadaEditada,
      ID_LOCALIZACAO: parseInt(localizacaoSelecionadaEditada),
      ID_POSICAO: parseInt(posicaoSelecionadaEditada),
      ID_COR: parseInt(corAraraSelecionadaEditada),
      ID_TIPO: parseInt(tipoAraraSelecionadaEditada),
      QTDE_LADOS: qtdeLadosSelecionadaEditada,
      QTDE_ANDARES: qtdeAndaresSelecionadaEditada,
      COMPRIMENTO: comprimentoSelecionadoEditado,
      ESPACO_AMOSTRAL: espacoAmostralSelecionadoEditado,
      CAPACIDADE_ANDAR: capacidadePorAndarSelecionadaEditada,
      CAPACIDADE_TOTAL: capacidadeTotalSelecionadaEditada,
      ID_USUARIO: userLogin,
      TIPO_ATUALIZACAO: 2,
    });
  }, [
    andar,
    lado,
    comprimento,
    espacoAmostral,
    localizacaoSelecionada,
    posicaoSelecionada,
    corAraraSelecionada,
    tipoAraraSelecionada,
    qtdeLadosSelecionada,
    qtdeAndaresSelecionada,
    idAraraSelecionadaEditada,
    localizacaoSelecionadaEditada,
    posicaoSelecionadaEditada,
    corAraraSelecionadaEditada,
    tipoAraraSelecionadaEditada,
    qtdeLadosSelecionadaEditada,
    qtdeAndaresSelecionadaEditada,
    espacoAmostralSelecionadoEditado,
    comprimentoSelecionadoEditado,
    capacidadePorAndarSelecionadaEditada,
    capacidadeTotalSelecionadaEditada,
  ]);

  function OnChangeComprimento(event) {
    setComprimento(event.target.value);
    calcularCapacidades(
      event.target.value,
      espacoAmostral,
      qtdeAndaresSelecionada,
      qtdeLadosSelecionada
    );
  }

  function OnChangeEspacoAmostral(event) {
    setEspacoAmostral(event.target.value);
    calcularCapacidades(
      comprimento,
      event.target.value,
      qtdeAndaresSelecionada,
      qtdeLadosSelecionada
    );
  }

  function OnChangeAndares(event) {
    setQtdeAndaresSelecionada(event.target.value);
    calcularCapacidades(
      comprimento,
      espacoAmostral,
      event.target.value,
      qtdeLadosSelecionada
    );
  }

  function OnChangeLados(event) {
    setQtdeLadosSelecionada(event.target.value);
    calcularCapacidades(
      comprimento,
      espacoAmostral,
      qtdeAndaresSelecionada,
      event.target.value
    );
  }

  function calcularCapacidades(
    comprimento,
    espacoAmostral,
    qtdeAndares,
    qtdeLados
  ) {
    comprimento = Number(comprimento);
    espacoAmostral = Number(espacoAmostral);
    qtdeAndares = Number(qtdeAndares);
    qtdeLados = Number(qtdeLados);

    let X = 0;
    let Y = 0;

    if (comprimento !== 0 && espacoAmostral !== 0) {
      X = (comprimento * 100) / espacoAmostral;
      X = X.toString().split(".");
      X = X[0] + "." + (X[1] + "").slice(0, 2);
      X = parseFloat(X);

      Y = X * qtdeLados * qtdeAndares;
      Y = Y.toString().split(".");
      Y = Y[0] + "." + (Y[1] + "").slice(0, 2);
      Y = parseFloat(Y);
    } else {
      X = 0;
      Y = 0;
    }

    setCapacidadePorAndar(X);
    setCapacidadeTotal(Y);
  }

  function OnChangeComprimentoEditado(event) {
    setComprimentoSelecionadoEditado(event.target.value);
    calcularCapacidadesEditada(
      event.target.value,
      espacoAmostralSelecionadoEditado,
      qtdeAndaresSelecionadaEditada,
      qtdeLadosSelecionadaEditada
    );
  }

  function OnChangeEspacoAmostralEditado(event) {
    setEspacoAmostralSelecionadoEditado(event.target.value);
    calcularCapacidadesEditada(
      comprimentoSelecionadoEditado,
      event.target.value,
      qtdeAndaresSelecionadaEditada,
      qtdeLadosSelecionadaEditada
    );
  }

  function OnChangeAndaresEditado(event) {
    setQtdeAndaresSelecionadaEditada(event.target.value);
    calcularCapacidadesEditada(
      comprimentoSelecionadoEditado,
      espacoAmostralSelecionadoEditado,
      event.target.value,
      qtdeLadosSelecionadaEditada
    );
  }

  function OnChangeLadosEditado(event) {
    setQtdeLadosSelecionadaEditada(event.target.value);
    calcularCapacidadesEditada(
      comprimentoSelecionadoEditado,
      espacoAmostralSelecionadoEditado,
      qtdeAndaresSelecionadaEditada,
      event.target.value
    );
  }

  function calcularCapacidadesEditada(
    comprimentoSelecionado,
    espacoAmostralSelecionado,
    qtdeAndaresSelecionada,
    qtdeLadosSelecionada
  ) {
    comprimentoSelecionado = Number(comprimentoSelecionado);
    espacoAmostralSelecionado = Number(espacoAmostralSelecionado);
    qtdeAndaresSelecionada = Number(qtdeAndaresSelecionada);
    qtdeLadosSelecionada = Number(qtdeLadosSelecionada);

    let W = 0;
    let Z = 0;

    if (comprimentoSelecionado !== 0 && espacoAmostralSelecionado !== 0) {
      W = (comprimentoSelecionado * 100) / espacoAmostralSelecionado;
      W = W.toString().split(".");
      W = W[0] + "." + (W[1] + "").slice(0, 2);
      W = parseFloat(W);

      Z = W * qtdeLadosSelecionada * qtdeAndaresSelecionada;
      Z = Z.toString().split(".");
      Z = Z[0] + "." + (Z[1] + "").slice(0, 2);
      Z = parseFloat(Z);
    } else {
      W = 0;
      Z = 0;
    }

    setCapacidadePorAndarSelecionadaEditada(W);
    setCapacidadeTotalSelecionadaEditada(Z);
  }

  function handleEdit(locais) {
    setShow(true);

    setIdAraraSelecionadaEditada(locais.idArara);
    setLocalizacaoSelecionadaEditada(locais.descricaoLocal);
    setPosicaoSelecionadaEditada(locais.descricaoPosicao);
    setTipoAraraSelecionadaEditada(locais.descricaoTipo);
    setCorAraraSelecionadaEditada(locais.descricaoCor);
    setEspacoAmostralSelecionadoEditado(locais.espacoAmostral_cm);
    setComprimentoSelecionadoEditado(locais.comprimento_m);
    setCapacidadeTotalSelecionadaEditada(locais.capacidadeTotal);
    setCapacidadePorAndarSelecionadaEditada(locais.capacidadePorAndar);
    setQtdeAndaresSelecionadaEditada(locais.qtdeAndares);
    setQtdeLadosSelecionadaEditada(locais.qtdeLados);
  }

  async function handleDelete(locais) {
    let delArara = {
      ACAO: 3,
      ID_ARARA: locais.idArara,
      ID_LOCALIZACAO: parseInt(locais.descricaoLocal),
      ID_POSICAO: parseInt(locais.descricaoPosicao),
      ID_COR: parseInt(locais.descricaoCor),
      ID_TIPO: parseInt(locais.descricaoTipo),
      QTDE_LADOS: locais.qtdeLados,
      QTDE_ANDARES: locais.qtdeAndares,
      COMPRIMENTO: locais.comprimento_m,
      ESPACO_AMOSTRAL: locais.espacoAmostral_cm,
      CAPACIDADE_ANDAR: locais.capacidadeporAndar,
      CAPACIDADE_TOTAL: locais.capacidadeTotal,
      ID_USUARIO: userLogin,
      TIPO_ATUALIZACAO: 2,
    };

    await api.post(`catalogo/SalaCatalogoProcessar`, delArara).then((response) => {
      if (response.status === 200) {
        toast.warning(Object.values(response.data[0])[0]);
        toast.success('Dados processados com sucesso!');
        setTimeout(function () {}, 5000);
      } else {
        toast.error("Erro Status Code: " + response.status + ". \nEntre em contato com o suporte.");
      }
    });
  }

  async function InserirArara() {    
    if (!localizacaoSelecionada) {
      toast.warning("Atenção: Localização deve ser preenchida!");
    } else if (!posicaoSelecionada) {
      toast.warning("Atenção: Posição deve ser preenchida!");
    } else if (!corAraraSelecionada) {
      toast.warning("Atenção: Cor Arara deve ser preenchida!");
    } else if (!tipoAraraSelecionada) {
      toast.warning("Atenção: Tipo Arara deve ser preenchida!");
    } else if (!qtdeLadosSelecionada) {
      toast.warning("Atenção: Qtde Lado deve ser preenchida!");
    } else if (!qtdeAndaresSelecionada) {
      toast.warning("Atenção: Qtde Andar deve ser preenchida!");
    } else if (!comprimento) {
      toast.warning("Atenção: Comprimento deve ser preenchida!");
    } else if (!espacoAmostral) {
      toast.warning("Atenção: Espaço Amostral deve ser preenchida!");
    } else {
      await api.post(`catalogo/SalaCatalogoProcessar`, cadastrarArara)
        .then((response) => {         
          if (response.status === 200) {
            toast.success("Cadastrado com sucesso!");
            setTimeout(function () {
              window.location.reload();
            }, 5000);
          } else {
            toast.error("Erro Status Code: " + response.status + ". \nEntre em contato com o suporte.");
          }
        });
    }
  }

  async function AtualizarArara() {
    api.post(`catalogo/SalaCatalogoProcessar`, atualizarArara)
      .then((response) => {
        if (response.status === 200) {
          toast.warning(Object.values(response.data[0])[0]);
          setShow(false);
          setTimeout(function () {}, 5000);
        } else {
          toast.error("Erro Status Code: " + response.status + ". \nEntre em contato com o suporte.");
        }
      });
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <body>
      <article className={styles.articleInRow}>
        <div className={styles.salaAmostraCodArara}>
          <span className={styles.codArara}>
            {idAraraDisponivel}
          </span>
          <span className="m-2">CODIGO ARARA</span>
        </div>
        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Localização</Form.Label>
            <Select
              change={(e) => {
                setLocalizacaoSelecionada(e.target.value);
              }}
              options={localizacao}
            />
          </Form.Group>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Qtde Andares</Form.Label>
            <Select
              change={OnChangeAndares}
              options={[
                { local: 1 },
                { local: 2 },
                { local: 3 },
                { local: 4 },
              ]}
            />
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Posição</Form.Label>
            <Select
              change={(e) => {
                setPosicaoSelecionada(e.target.value);
              }}
              options={posicao}
            />
          </Form.Group>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Comprimento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Comprimento"
              value={comprimento}
              onChange={OnChangeComprimento}
            />
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Cor Arara</Form.Label>
            <Select
              change={(e) => {
                setCorAraraSelecionada(e.target.value);
              }}
              options={corArara}
            />
          </Form.Group>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Group>
              <Form.Label>Espaço Amostral</Form.Label>
              <Form.Control
                type="text"
                placeholder="Espaço Amostral"
                value={espacoAmostral}
                onChange={OnChangeEspacoAmostral}
              />
            </Form.Group>
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Tipo Arara</Form.Label>
            <Select
              change={(e) => {
                setTipoAraraSelecionada(e.target.value);
              }}
              options={tipoArara}
            />
          </Form.Group>
          <Form.Group className={styles.alignColummBottom}>
            <Form.Label>Capacidade por Andar: {capacidadePorAndar}</Form.Label>
            <Form.Label className={styles.displayContent}>Capacidade Total: {capacidadeTotal}</Form.Label>
          </Form.Group>
        </Form>

        <Form className={styles.blockNoborder}>
          <Form.Group className={styles.formGroupMargin}>
            <Form.Label>Qtde Lados</Form.Label>
            <Select
              change={OnChangeLados}
              options={[{ local: 1 }, { local: 2 }]}
            />
          </Form.Group>
          <Form.Group className={styles.alignBtn}>
            <Button 
              className={styles.alignBtn}
              variant="primary"
              type="button"
              onClick={() => InserirArara()} 
            >
              Inserir
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                />
            </Button>
          </Form.Group>
        </Form>
      </article>

      <article  className={styles.articleGray}>
      {carregando ?
                    <Carregando></Carregando>
        : 
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}>ID</th>
              <th className={styles.blockTitle}>LOCAL</th>
              <th className={styles.blockTitle}>POSIÇÃO</th>
              <th className={styles.blockTitle}>COR</th>
              <th className={styles.blockTitle}>TIPO</th>
              <th className={styles.blockTitle}>LADOS</th>
              <th className={styles.blockTitle}>ANDARES</th>
              <th className={styles.blockTitle}>COMPRIMENTO</th>
              <th className={styles.blockTitle}>ESPAÇO AMOS.</th>
              <th className={styles.blockTitle}>CAP. ANDAR</th>
              <th className={styles.blockTitle}>CAP. TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {idArara.map((locais, index) => {
              return (
                <tr>
                  <td className={styles.tableLines}>
                    <Button
                      variant="primary"
                      onClick={() => handleEdit(locais)}
                    >
                      <i>
                        <MaterialIcon 
                            icon="edit" 
                            size={20} 
                            color='white'
                        />
                      </i>
                    </Button>
                  </td>
                  <td className={styles.tableLines}>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(locais)}
                    >
                      <i>
                        <MaterialIcon 
                            icon="delete" 
                            size={20} 
                            color='white'
                        />
                      </i>
                    </Button>
                  </td>
                  <td className={styles.tableLines}>{locais.idArara}</td>
                  <td className={styles.tableLines}>{locais.descricaoLocal}</td>
                  <td className={styles.tableLines}>{locais.descricaoPosicao}</td>
                  <td className={styles.tableLines}>{locais.descricaoCor}</td>
                  <td className={styles.tableLines}>{locais.descricaoTipo}</td>
                  <td className={styles.tableLines}>{locais.qtdeLados}</td>
                  <td className={styles.tableLines}>{locais.qtdeAndares}</td>
                  <td className={styles.tableLines}>{locais.comprimento_m}</td>
                  <td className={styles.tableLines}>{locais.espacoAmostral_cm}</td>
                  <td className={styles.tableLines}>{locais.capacidadeporAndar}</td>
                  <td className={styles.tableLines}>{locais.capacidadeTotal}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      }
      </article>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header 
          closeButton 
          centered>
          <Modal.Title>
            Editando Arara: {idAraraSelecionadaEditada}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form className={styles.blockNoborder}>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Localização</Form.Label>
              <Select
                prefix="localizacaoModal"
                label={localizacaoSelecionadaEditada}
                change={(e) => {
                  setLocalizacaoSelecionadaEditada(e.target.value);
                }}
                options={localizacao}
                disabled
                readOnly
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Cor Arara</Form.Label>
              <Select
                label={corAraraSelecionadaEditada}
                change={(e) => {
                  setCorAraraSelecionadaEditada(e.target.value);
                }}
                options={corArara}
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Qtde Lados</Form.Label>
              <Select
                label={qtdeLadosSelecionadaEditada}
                change={OnChangeLadosEditado}
                options={[{ local: 1 }, { local: 2 }]}
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Comprimento</Form.Label>
              <Form.Control
                type="text"
                value={comprimentoSelecionadoEditado}
                onChange={OnChangeComprimentoEditado}
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <div>
                <span label={capacidadePorAndar}>
                  Capacidade por Andar:{" "}
                  {capacidadePorAndarSelecionadaEditada}
                </span>
              </div>
            </Form.Group>
          </Form>
          <Form className={styles.blockNoborder}>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Posição</Form.Label>
              <Select
                label={posicaoSelecionadaEditada}
                change={(e) => {
                  setPosicaoSelecionadaEditada(e.target.value);
                }}
                options={posicao}
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Tipo Arara</Form.Label>
              <Select
                label={tipoAraraSelecionadaEditada}
                change={(e) => {
                  setTipoAraraSelecionadaEditada(e.target.value);
                }}
                options={tipoArara}
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Qtde Andares</Form.Label>
              <Select
                label={qtdeAndaresSelecionadaEditada}
                change={OnChangeAndaresEditado}
                options={[
                  { local: 1 },
                  { local: 2 },
                  { local: 3 },
                  { local: 4 },
                ]}
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <Form.Label>Espaço Amostral</Form.Label>
              <Form.Control
                type="text"
                value={espacoAmostralSelecionadoEditado}
                onChange={OnChangeEspacoAmostralEditado}
              />
            </Form.Group>
            <Form.Group className={styles.marginSelectsModal}>
              <div>
                <span label={capacidadeTotal}>
                  Capacidade Total: {capacidadeTotalSelecionadaEditada}
                </span>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <span className="m-2 text-center">
            Atualizações nas informações de capacidades só serão
            permitidas se a quantidade cadastrada for inferior a nova
            capacidade.
          </span>
          <Button 
            variant="secondary" 
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => AtualizarArara()}
          >
            Salvar
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
          </Button>
        </Modal.Footer>
      </Modal>
    </body>
  );
}

export default SalaAmostra;
