import React from "react";
import VigenciaExclusao from "../components/Adm/VigenciaExclusao/index";

import styles from '../styles/AdmVigenciaExclusao.module.scss';

const AdmVigenciaExclusao = () => {
    return (
        <div className="p-2">
          <h3>Catálogo Amostras - ADM - Vigência e Exclusão</h3>
          <VigenciaExclusao />
        </div>
    );
};

export default AdmVigenciaExclusao;