import React, { useState,useEffect, useRef } from "react";
import { Button, Table, Form, FormGroup, Modal, Col, Container, Row, Accordion } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

import "react-toastify/dist/ReactToastify.min.css";

import MaterialIcon from 'material-icons-react';
import { Link } from "react-router-dom";

import styles from "../../../../styles/MonitorRevisaoSegundaQualidadeDefeito.module.scss";

import ProdutoInfo from "../componentes/ProdutoInfo";
import { api, retryApi }  from "../../../../services/api"
import RadioButtonList from "../../../Utils/RadioButtonList"

function MonitorRevisaoSegundaQualidadeDefeito(props) {
    const [openEntradaFiscal, setOpenEntradaFiscal] = useState(false);
    const [openIdentificacaoProduto, setOpenIdentificacaoProduto] = useState(false);
    const [show, setShow] = useState(false);

    const [grupoAvaliacao,setGrupoAvaliacao] = useState([]);
    const [statusAvaliacao, setStatusAvaliacao] = useState([]);
    const [defeitosEncontrados,setDefeitosEncontrados] = useState([]);    
    const [gradeSegundaQualidadeAtualizada, setGradeSegundaQualidadeAtualizada] = useState([]);
    const [totalPendentes, setTotalPendentes] = useState(0);
    const [itemGradeDefeitoSelecionado,setItemGradeDefeitoSelecionado] = useState('');
    const [qdtPecasComDefeito,setQdtPecasComDefeito ] =useState(0);
    const [percentPecasComDefeito, setPercentPecasComDefeito ] = useState(0);
    const [carregandoDefeitos, setCarregandoDefeitos] = useState(false);
    const [areaEspecifica, setAreaEspecifica] = useState([]);
    const [localizacaoDefeito, setLocalizacaoDefeito]=useState([]);

    const [grupoAvaliacaoSelecionado,setGrupoAvaliacaoSelecionado] = useState({});
    const [statusAvaliacaoSelecionado, setStatusAvaliacaoSelecionado] = useState({});
    const [areaEspecificaSelecionada, setAreaEspecificaSelecionada] = useState({});
    const [localizacaoDefeitoSelecionada, setLocalizacaoDefeitoSelecionada]=useState({});
    const [defeitosEncontradosSelecionado,setDefeitosEncontradosSelecionado] = useState({});
    const [consideracoesSelecionada, setConsideracoesSelecionada] = useState('');

        
    const [gradeDefeitos, setGradeDefeitos] = useState([]);
    const [gradeDefeitosInseridos, setGradeDefeitosInseridos] = useState([]);

    
    const listRef = useRef();
    
    const handleClose = () => setShow(false);
    const handleShow = () => { 
        if(itemGradeDefeitoSelecionado===''){
            toast.error('Você deve selecionar uma grade!');            
            return false;
        }       
        setShow(true);  
       // setGradeDefeitos(gradeDefeitos.filter(item => { return  item.tamanho===itemGradeDefeitoSelecionado.Tamanho}));  
    };

   
    useEffect(() => {
        carregaFiltros();
    }, []);

    async function carregaFiltros(){
        listaGrupoAvaliacao();
        listaStatusAvaliacao();      
        listaAreaEspecifica();
        ListarGradeDirecionados();
        listaLocalizacaoDefeito();          
    }
    async function handleFinalizarGrade(){
        if(gradeDefeitos.filter(defeito => {return defeito.tamanho === itemGradeDefeitoSelecionado.Tamanho}).length<1){
            toast.error('Você deve incluir pelo menos 1 defeito.');            
            return false;
        }

        const objInserir ={
            tamanho: itemGradeDefeitoSelecionado.Tamanho,
            grade: gradeDefeitos
        }

        let grade = [];    
        grade = grade.concat(gradeDefeitosInseridos.filter(defeito => {return defeito.tamanho !== itemGradeDefeitoSelecionado.Tamanho}));     
        grade.push(objInserir);
        setGradeDefeitosInseridos(grade);
        //props.handleFinalizaRevisaoSegundaQualidade(props.grade, itemGradeDefeitoSelecionado.Tamanho);
        setShow(false);    
    }

    async function handleFinalizaRevisaoSegundaQualidade(){
        props.handleFinalizaRevisaoSegundaQualidade(gradeDefeitosInseridos);
        setShow(false); 
    }

    async function listaGrupoAvaliacao(){
        try {           
            const lista = await retryApi('get', "PArevisao/ListaGrupoAvaliacao");  
            if(lista.data.length>0){
                const listaGrupo = lista.data.map((item,index)=>{
                    return {value: item.IDGrupoAnalise, label: item.GrupoAnalise};
                });   
                setGrupoAvaliacao(listaGrupo);
               
            }                    
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de status!');            
            return false;
        }
    }

    async function listaStatusAvaliacao(){
        try {           
            const lista = await retryApi('get', "PArevisao/ListaStatusAvaliacao");    
            if(lista.data.length>0){
                const listaStatus = lista.data.map((item,index)=>{
                    return {value: item.IDStatus, label: item.LISTA_STATUS};
                });   
                setStatusAvaliacao(listaStatus);
               
            }                    
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de status!');            
            return false;
        }
    }

    async function listaDefeitosEncontrados(itemValue){
        setCarregandoDefeitos(true);             
        try {                               
            const params ={
                idGrupoAnalise: itemValue
            }
            const lista = await retryApi('get', "PArevisao/ListaDefeitosEncontrados",{params});    
            if(lista.data.length>0){
                const listaDefeitos = lista.data.map((item,index)=>{
                    return {value: item.IDDefeito, label: item.GrupoDefeito};
                });   
                setDefeitosEncontrados(listaDefeitos);
               
            }                    
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de defeitos!');            
            return false;
        }
        setCarregandoDefeitos(false);
    }

    async function listaAreaEspecifica(){             
        try {   
            const lista = await retryApi('get', "PArevisao/ListaAreaEspecifica");    
            if(lista.data.length>0){
                const listaArea = lista.data.map((item,index)=>{
                    return {value: item.IDLOCDEFPA, label: item.DetalhamentoLocalizacaoDefeito};
                });   
                setAreaEspecifica(listaArea);
               
            }                    
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de áreas!');            
            return false;
        }        
    }

    async function listaLocalizacaoDefeito(itemValue){                  
        
        try {                               
            const params ={
                grupoProduto: props.produto.GrupoProduto
            }
            const lista = await retryApi('get', "PArevisao/ListaLocalizacaoDefeitos",{params});    
            if(lista.data.length>0){
                const listaLocalizacao = lista.data.map((item,index)=>{
                    return {value: item.IDLocalizacao, label: item.DescricaoLocalizacao};
                });   
                setLocalizacaoDefeito(listaLocalizacao);
               
            }                    
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de defeitos!');            
            return false;
        }
        
    }


    async function ListarGradeDirecionados (){
        //calcular o total das peças

        let novaGrade = props.gradeSegundaQualidade;
        let totalPendente = props.qtdPecasDirecionadas;

        novaGrade.map((item,index)=>{            
            let qtdDirecionada =0;
            let qtdPendente = item.QtdePecas;
            let qtdTotal = item.QtdePecas;

            props.gradeSegundaQualidadeSelecionada.map((itemSelecionado,index)=>{
                if(item.Tamanho === itemSelecionado.Tamanho){
                    qtdDirecionada = parseInt(qtdDirecionada) + parseInt(itemSelecionado.QtdePecas)
                    qtdPendente = parseInt(qtdTotal) - qtdDirecionada
                    totalPendente = parseInt(totalPendente) - parseInt(qtdDirecionada)
                    
                }

            });            
            if(parseInt(qtdDirecionada) > 0){
                item.QtdePecas = qtdDirecionada
                item.Qtde_Pendente = item.Qtde - qtdDirecionada
               }
        });
        const novaGradeAtualizada = novaGrade.filter(item => parseInt(item.QtdePecas)>0);

        setGradeSegundaQualidadeAtualizada(novaGradeAtualizada);
    }

    const handleItemGradeDefeitoQualidade = async(opcaoSelecionada) =>{
        setItemGradeDefeitoSelecionado(opcaoSelecionada);
        setQdtPecasComDefeito(opcaoSelecionada.QtdePecas);
        setPercentPecasComDefeito((opcaoSelecionada.QtdePecas/opcaoSelecionada.Qtde)*100)
    }

    const handleIncluiAvaliacao = async ()=>{
        const itemParaInserir ={
            tamanho: itemGradeDefeitoSelecionado.Tamanho,
            grupo: grupoAvaliacaoSelecionado,
            status: statusAvaliacaoSelecionado,
            defeito: defeitosEncontradosSelecionado,
            localizacao: localizacaoDefeitoSelecionada,
            areaEspecifica: areaEspecificaSelecionada,
            consideracoes: consideracoesSelecionada,
            tipoAnalise: '02 - SEGUNDA QUALIDADE'
        }
       
        if(grupoAvaliacaoSelecionado.value==null || statusAvaliacaoSelecionado.value==null ||
            defeitosEncontradosSelecionado.value==null || localizacaoDefeitoSelecionada.value==null ||
            areaEspecificaSelecionada.value==null 
            ){
            toast.error('Você deve informar Grupo, Status, Defeito, Localização e Área para incluir.');            
            return false;
        }

        let grade = [];    
        grade = grade.concat(gradeDefeitos.filter(item =>{return item.tamanho === itemGradeDefeitoSelecionado.Tamanho }));     
        grade.push(itemParaInserir);
        setGradeDefeitos(grade);
        setGrupoAvaliacaoSelecionado({});
        setStatusAvaliacaoSelecionado({});
        setDefeitosEncontradosSelecionado({});
        setLocalizacaoDefeitoSelecionada({});
        setAreaEspecificaSelecionada({});
        setConsideracoesSelecionada('');
        limpaFiltros();
        carregaFiltros();

    }

    async function limpaFiltros(){
       listRef.current.clearChecks();
    }

    const handleExcluiItemDefeito = async (itemParaExcluir)=>{
        setGradeDefeitos(gradeDefeitos.filter(el => el !== itemParaExcluir));       
    }


    return(
        <div>
            {/* Identificação Produto */}
            <Accordion style={{marginTop: "10px"}}>
                <Accordion.Item eventKey="1">
                <Accordion.Header>Identificação do Produto</Accordion.Header>
                <Accordion.Body style={{padding: "0px"}}>
                    <div>
                        <ProdutoInfo item={props.produto}></ProdutoInfo>
                    </div>
                </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <article className={styles.articleBorder}>
                <h5>
                    Tipo:
                    <span className={styles.spanBlueBold}> SEGUNDA QUALIDADE</span>
                </h5>
                <div className={styles.sectionContent}>
                    <p style={{marginBottom: 10}}> 1. Grade</p>
                    <Form>
                    <Table bordered responsive="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th className={styles.blockTitle}>TAMANHO</th>
                                <th className={styles.blockTitle}>Q. RECEBIDA</th>   
                                <th className={styles.blockTitle}>Q. DIRECIONADA</th>                         
                                <th className={styles.blockTitle}>Q. PENDENTE</th>
                                <th className={styles.blockTitle}>STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gradeSegundaQualidadeAtualizada.length>0 ?
                                gradeSegundaQualidadeAtualizada.map((item,index) => {
                                return (
                                    <tr className={styles.tableLine} key={`rowGradeSegundaQualidadeSelecionada`+ index}
                                    style={{ backgroundColor: gradeDefeitosInseridos.filter(defeito => {return defeito.tamanho === item.Tamanho}).length>0 ? '#C4C4C4' : 'white'}}
                                    >
                                    <td>
                                           
                                                <Form.Check                                                    
                                                    id={`${item.Tamanho}_${item.Destino}`}
                                                    key = {`itemGrade_`+ item.Tamanho}
                                                    type={'radio'}                                                  
                                                    name={'gradeSegundaQualidade'}
                                                    onChange ={()=>{handleItemGradeDefeitoQualidade(item)}} 
                                                />
                                           
                                        </td>
                                        <td className={styles.tableLine}>{item.Tamanho}</td>
                                        <td className={styles.tableLine}>{item.Qtde}</td>
                                        <td className={styles.tableLine}>{item.QtdePecas}</td>
                                        <td className={styles.tableLine}>{item.Qtde_Pendente}</td>       
                                        <td className={styles.tableLine}>{gradeDefeitosInseridos.filter(defeito => {return defeito.tamanho === item.Tamanho}).length>0 ? 'OK' : 'PENDENTE'}</td>                                      
                                         
                                    </tr>
                                    );                                    
                                })
                                :''
                                }
                        </tbody>
                    </Table>       
                </Form>
                </div>
                <div className={styles.alignLeft}>
                    <Form>
                        <Form.Label>2. Qtde peças com defeito:</Form.Label>
                        <Form.Control type="number" className="mb-3" value={qdtPecasComDefeito} disabled={true}/>
                    </Form>
                    <Form className={styles.formAlignLeft} style={{marginTop: 2, display: 'flex'}}>
                        <Form.Label>3. % Peças com defeito: </Form.Label>
                        <span className={styles.spanGray}>{Math.round(percentPecasComDefeito,0)}%</span>
                    </Form>
                </div>
                <div className={styles.buttonRight}>
                    <Button 
                        className={styles.buttonMarginSide}
                        variant="outline-primary"
                        onClick={handleShow}
                    >
                        Incluir Defeito
                    </Button>
                </div>
            </article>

            {/* Footer */}
            <article className={styles.footer}>
                <Button //Só habilita quando tudo acima estiver preenchido
                    variant="primary"
                    onClick={handleFinalizaRevisaoSegundaQualidade}
                >
                    Finalizar Segunda Qualidade
                </Button>
                
            </article>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                fullscreen={true}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Incluindo Defeito da Grade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Accordion Identificação Produto */}
                    <Accordion className="mt-3">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Identificação do Produto</Accordion.Header>
                            <Accordion.Body>
                                <ProdutoInfo item={props.produto}></ProdutoInfo>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <article className={styles.sectionBorder}>
                        <div className={styles.backgroundMiddleGray}>
                            <div className={styles.secondQaInfo}>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Tamanho:</Form.Label>
                                    <span className={styles.spanBold}>{itemGradeDefeitoSelecionado.Tamanho}</span>
                                </Form>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Qtde Peças com Defeito:</Form.Label>
                                    <span className={styles.spanBold}>{qdtPecasComDefeito}</span>
                                </Form>
                            </div>
                        </div>
                        <div className={styles.sectionContent}>
                            <div className={styles.alignLineFormDefeito}>
                                <div className={styles.alignColummFormDefeito}>
                                    <Form className="m-2 mb-3">
                                        <Form.Label>1. Grupo de Avaliação:</Form.Label>
                                        <RadioButtonList options={grupoAvaliacao}
                                        ref={listRef}
                                        changeList={(e)=>{listaDefeitosEncontrados(e.value);setGrupoAvaliacaoSelecionado(e);}}></RadioButtonList>
                                    
                                    </Form>
                                
                                    <Form className="m-2 mb-3">
                                        <Form.Label>2. Status Avaliação:</Form.Label>
                                        <RadioButtonList ref={listRef}
                                        options={statusAvaliacao} changeList={(e)=>{setStatusAvaliacaoSelecionado(e);}}></RadioButtonList>
                                    </Form>
                                </div>
                                <div className={styles.alignColummFormDefeito}>
                                    <Form className="m-2 mb-3">
                                        <Form.Label>3. Defeito Encontrado:</Form.Label>
                                        {defeitosEncontrados.length>0 ?
                                                <RadioButtonList options={defeitosEncontrados} changeList={(e)=>{setDefeitosEncontradosSelecionado(e);}}></RadioButtonList>
                                            : ''
                                            }
                                    </Form>
                                
                                    <Form className="m-2 mb-3">
                                        <Form.Label>4. Localização do Defeito:</Form.Label>
                                        {localizacaoDefeito.length>0 ?
                                            <RadioButtonList  ref={listRef}
                                            options={localizacaoDefeito} changeList={(e)=>{setLocalizacaoDefeitoSelecionada(e);}}></RadioButtonList>
                                        :''
                                        }
                                    </Form>
                                </div>
                                <div className={styles.alignColummFormDefeito}>
                                    <Form className="m-2 mb-3">
                                        <Form.Label>5. Especifique a área mais próxima:</Form.Label>
                                        <RadioButtonList options={areaEspecifica} changeList={(e)=>{setAreaEspecificaSelecionada(e);}}></RadioButtonList>
                                    </Form>
                                
                                    <Form className="m-2 mb-3">
                                        <Form.Label>
                                            6. Considerações 
                                            <span className={styles.spanOpcional}>(Opcional)</span>
                                            :
                                        </Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            rows="2" 
                                            class={styles.formControl} 
                                            id="txtConsideracoes" 
                                            value={consideracoesSelecionada} 
                                            onChange={(e)=>{setConsideracoesSelecionada(e.target.value);}}>
                                        </Form.Control>
                                    </Form>
                                </div>
                            </div>
                            
                        </div>
                        <div className={styles.buttonRight}>
                            <Button
                                variant="success"
                                onClick={handleIncluiAvaliacao}
                            >
                                Incluir Avaliação
                            </Button>
                        </div>
                    </article>

                    <h5 className={styles.fontBold}>Resumo Avaliação:</h5>
                    <section className={styles.sectionBorder}>
                        <Table  responsive="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className={styles.blockTitle}>GRUPO</th>
                                    <th className={styles.blockTitle}>STATUS</th>   
                                    <th className={styles.blockTitle}>DEFEITO ENCONTRATO</th>                         
                                    <th className={styles.blockTitle}>LOCALIZAÇÃO</th>
                                    <th className={styles.blockTitle}>ÁREA MAIS PRÓXIMA</th>
                                    <th className={styles.blockTitle}>CONSIDERAÇÕES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gradeDefeitos.length>0 ?
                                gradeDefeitos
                                .filter(item => {
                                return item.tamanho ===  itemGradeDefeitoSelecionado.Tamanho;
                                })
                                .map((item,index) => {
                                return (
                                    <tr className={styles.tableLine} key={`rowGradeDefeitosSelecionada`+ index}>
                                    <td>
                                    <Button
                                        variant="danger"
                                        onClick={() => handleExcluiItemDefeito(item)}
                                        >
                                        <span className="fa-trash icons-uniao">
                                            <FontAwesomeIcon
                                            className="fa-trash-2x"
                                            icon={faTrash}
                                            />
                                        </span>
                                    </Button>
                                    </td>
                                    <td className={styles.tableLine}>{item.grupo.label}</td>
                                    <td className={styles.tableLine}>{item.status.label}</td>
                                    <td className={styles.tableLine}>{item.defeito.label}</td>   
                                    <td className={styles.tableLine}>{item.localizacao.label}</td> 
                                    <td className={styles.tableLine}>{item.areaEspecifica.label}</td>   
                                    <td className={styles.tableLine}>{item.consideracoes}</td> 
                                </tr>
                                );                                    
                                })
                                :''
                                }
                            </tbody>
                        </Table>
                    </section>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button 
                        variant="primary"
                        onClick={handleFinalizarGrade}>
                        Finalizar Grade
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MonitorRevisaoSegundaQualidadeDefeito;