import { React, useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { api }  from "../../../../services/api";
import styles from '../../../../styles/PA/Desfazer.module.scss';
import Select from 'react-select';
import CookiesService from "../../../../services/cookies";
import { listaProcessos } from "../desfazer_aux";
import DevolucaoForm from "./conditionalModalFields/devolucaoForm";
import GradeEntradaForm from "./conditionalModalFields/gradeEntradaForm";
import { useMisc } from "../../../../contexts/MiscContext";

const ModalDesfazer = (props) => {
  const { setShowLoading } = useMisc();

  const { processoFiltrado, setModalAtivo, objSelecionado, modalAtivo, setObjSelecionado, listaMotivos, toast, mainData, setMainData } = props;
  // const [modalInfo, setModalInfo] = useState({ value: '', header: '', bodyMain: '',  bodySub: '', btn: '' });
  const [modalInfo, setModalInfo] = useState({});
  const [motivoSelecionado, setMotivoSelecionado] = useState(null);
  const [novaDataEntrada, setNovaDataEntrada] = useState('');
  const [formErrs, setFormErrs] = useState({});
  const [formDevolucao, setFormDevolucao] = useState({});
  const [formGradeEntrada, setFormGradeEntrada] = useState({});
  const [listaMotivosFiltrado, setListaMotivosFiltrado] = useState({});

  const closeModal = () => {
    setMotivoSelecionado(null);
    setObjSelecionado({});
    setModalAtivo(false);
    setNovaDataEntrada('');
    setFormDevolucao({});
    setFormGradeEntrada({});
  }

  const validarDados = () => {
    const errs = {}
    if (!motivoSelecionado) errs.motivo = true;
    if (processoFiltrado.value === 'dataEntrada') {
      if (!novaDataEntrada || novaDataEntrada === '') errs.novaDataEntrada = true;
    }
    if (processoFiltrado.value === 'gradeEntrada') {
      let parcial = 0;
      Object.keys(formGradeEntrada.grade).forEach(item => {
        const obj = formGradeEntrada.grade[item];
        parcial += parseInt(obj.qtde);
        if (obj.value && (!obj.qtde || parseInt(obj.qtde) === 0)) errs.gradeEntradaQtdeNula = true;
      });
      if (parcial !== formGradeEntrada.qtdePecasTotal) errs.gradeEntradaQtdeTotal = true;
    }
    return errs;
  }

  const processarDesfazer = async () => {
    setShowLoading(true);
    const errs = validarDados();
    if (Object.keys(errs).length > 0) {
      toast.warning(
        <>
          <div>Por favor preencha todos os campos.</div>
          {errs.gradeEntradaQtdeNula ? <div>Insira quantidades válidas nas grades.</div> : null}
          {errs.gradeEntradaQtdeTotal ? <div>A quantidade inserida nas grades não corresponde ao total.</div> : null}
        </>
      );
      setShowLoading(false);
      return setFormErrs({ ...errs });
    }

    let params = {
      nfEntrada: objSelecionado.NFEntrada,
      produto: objSelecionado.Produto,
      qtdeEntrada: objSelecionado.QtdeEntrada,
      tipoProcesso: processoFiltrado.value,
      motivo: motivoSelecionado.value,
      user: CookiesService.getUserLogin(),
    }

    if (processoFiltrado.value === 'dataEntrada') {
      params.novaDataEntrada = novaDataEntrada;
      params.dataEntradaAnterior = objSelecionado.DataDigitacao.slice(0, 10);
    }

    if (processoFiltrado.value === 'analiseDuplicada') {
      params.corProduto = objSelecionado.CorProduto;
      params.ordemProducao = objSelecionado.OrdemProducao;
      params.filialRevisao = objSelecionado.FilialRevisao;
    }

    if (processoFiltrado.value === 'numeroAnalise') {
      params.numeroAnalise = objSelecionado.NumeroAnalise;
    }

    if (processoFiltrado.value === 'devolucao') {
      const keys = Object.keys(formDevolucao.grade);
      let str = '';
      let qtde = 0;
      for (let i = 0; i < keys.length; i++) {
        if (formDevolucao.grade[keys[i]].value === true) {
          str += `${keys[i]}/${formDevolucao.grade[keys[i]].qtde};`;
          qtde += parseInt(formDevolucao.grade[keys[i]].qtde);
        }
      }
      str = str.slice(0, -1);
      params = { ...params, ...formDevolucao, gradeStr: str, qtdePecasContadas: qtde }
    }

    if (processoFiltrado.value === 'gradeEntrada') {
      params.corProduto = objSelecionado.CorProduto;
      params.ordemProducao = objSelecionado.OrdemProducao;
      params.filialRevisao = objSelecionado.FilialRevisao;
      params.serieNF = objSelecionado.SerieNF;
      params.marca = objSelecionado.Marca;
      params.origem = objSelecionado.Origem;
      params.grade = objSelecionado.Grade;
      params.empresa = objSelecionado.Empresa;
      params.novaGradeEntrada = Object.entries(formGradeEntrada.grade)
        .filter(([key, obj]) => obj.value === true || obj.marcado === 1)
        .map(([key, obj]) => ({ ...obj, tamanho: key }));
    }

    await api.post(`PADesfazer/${processoFiltrado.endpointProc}`, params)
      .then(res => {
        setShowLoading(false);
        closeModal();
        if (processoFiltrado.value !== 'gradeEntrada') mainData.splice(objSelecionado.index, 1);
        setMainData([...mainData]);
        setObjSelecionado({});
        toast.success('Processamento completado com sucesso');
      })
      .catch(e => {
        setShowLoading(false);
        toast.error('Ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
      });
  }

  useEffect(() => {
    if (!processoFiltrado) return;
    setListaMotivosFiltrado([...listaMotivos.filter(motivo => processoFiltrado.motivoEspecifico.includes(motivo.value))]);

    const index = listaProcessos.findIndex(item => {
      return item.value === processoFiltrado.value;
    });
    setModalInfo(listaProcessos[index]);
  }, [processoFiltrado]);

  return (
    <>
      <Modal
        show={modalAtivo}
        backdrop={'static'}
        fullscreen={false}
        keyboard={false}
        centered
        style={{ backgroundColor: '#030303020' }}
      >
        <Modal.Header>{modalInfo?.label}</Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <div
            style={{
              display: objSelecionado?.IDStatusChecklist === 4 && objSelecionado?.FilialRevisao === 'CONTROLE QUALIDADE ATAC' ?
                'block' : 'none'
            }}
            className={styles.modalWarn}
          >{modalInfo?.bodySub}</div>

          <Form.Group
            className={styles.filterWidth}
            controlId='novaDataEntrada'
            style={{ display: processoFiltrado.value === 'dataEntrada' ? 'block' : 'none' }}
          >
            <Form.Label>Nova Data de Entrada</Form.Label>
            <Form.Control
              required
              type="date"
              value={novaDataEntrada || ''}
              onChange={e => setNovaDataEntrada(e.target.value)}
              placeholder={'Nova Data de Entrada'}
              min={'2000-01-01'}
              max={'9999-01-01'}
              onClick={() => setFormErrs({ ...formErrs, novaDataEntrada: null })}
            />
            <Form.Control.Feedback type='invalid'>{formErrs.novaDataEntrada}</Form.Control.Feedback>
          </Form.Group>

          {processoFiltrado.value === 'devolucao' &&
            <DevolucaoForm
              formDevolucao={formDevolucao}
              setFormDevolucao={setFormDevolucao}
              formErrs={formErrs}
              setFormErrs={setFormErrs}
              objSelecionado={objSelecionado}
            />
          }

          {processoFiltrado.value === 'gradeEntrada' &&
            <GradeEntradaForm
              formGradeEntrada={formGradeEntrada}
              setFormGradeEntrada={setFormGradeEntrada}
              formErrs={formErrs}
              setFormErrs={setFormErrs}
              objSelecionado={objSelecionado}
            />
          }

          <Form.Group controlId='motivo'>
            <Form.Label className={styles.formLabel}>Motivo</Form.Label>
            <Select
              required
              className={styles.filterWidth}
              placeholder={'Selecionar'}
              closeMenuOnSelect={true}
              value={motivoSelecionado}
              onChange={opt => setMotivoSelecionado(opt)}
              options={listaMotivosFiltrado}
              isClearable={true}
              onClick={() => setFormErrs({ ...formErrs, motivo: null })}
            />
            <Form.Control.Feedback type='invalid'>{formErrs.motivo}</Form.Control.Feedback>
          </Form.Group>

          <div className={styles.modalMain}>{modalInfo?.bodyMain}</div>
        </Modal.Body>
        <Modal.Footer className={styles.flexFooter}>
          <Button
            variant={'outline-danger'}
            onClick={closeModal}
          >
            Voltar
          </Button>
          <Button
            onClick={processarDesfazer}
          >
            {modalInfo?.btn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalDesfazer;