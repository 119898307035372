import React from "react";
import AgendDev from "../../components/PA/AgendamentoDevolucao/index";
import styles from '../../styles/AgendamentoDevolucao.module.scss';

const PAAgendamentoDevolucao = () => {
  return (
    <div>
      <div className="p-2">
        <h3>Agendamento Devolução</h3>
      </div>
      <AgendDev />
    </div>
  );
};

export default PAAgendamentoDevolucao;
