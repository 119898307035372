import React, { useEffect, useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import styles from '../../styles/MP/AnaliseRapportReprovadoCad.module.scss';
import Carregando from '../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import ModalCad from '../../components/MP/AnaliseRapportReprovadoCad/ModalCad';
import ModalImpacto from '../../components/MP/AnaliseRapportReprovadoCad/ModalImpacto';
import { api }  from "../../services/api";
import ModalConfirmacaoImpacto from '../../components/MP/AnaliseRapportReprovadoCad/ModalConfirmacaoImpacto';
import ModalConfirmacaoGrade from '../../components/MP/AnaliseRapportReprovadoCad/ModalConfirmacaoGrade';


const AnaliseRapportReprovadoCad = () => {

    const [mainData, setMainData] = useState([]);
    const [dataProduct, setDataProduct] = useState([]);

    const [filterObj, setFilterObj] = useState({
        pedido: '',
        material: '',
        cor_material: ''
    });

    const [pedidoSelecionado, setPedidoSelecionado] = useState({});
    const [idCq, setIdCq] = useState('');

    const [loading, setLoading] = useState(false);

    const [showAnalisar, setShowAnalisar] = useState(false);
    const [showImpacto, setShowImpacto] = useState(false);
    const [showConfirmacaoImpacto, setShowConfirmacaoImpacto] = useState(false);
    const [showConfirmacaoGrade, setShowConfirmacaoGrade] = useState(false);

    const [impactoConsumo, setImpactoConsumo] = useState();

    const [consumos, setConsumos] = useState({
        consumoAtual: '',
        novoConsumo: ''
    });

    const consumosHandler = (event, field) => {
        const regex = /^\d*(?:\.\d*)?$/
        if (!regex.test(event.target.value)){
          return
        }
        const consumo = {...consumos};
        consumo[field] = event.target.value;
        setConsumos(consumo);
    }

    const handleShowAnalisar = (pedido) => {
        setPedidoSelecionado(pedido);
        handleSearchProducts(pedido);
        handleGetIdCq(pedido);
        setShowAnalisar(true);
    };

    const handleCloseAnalisar = () => {
        setPedidoSelecionado({});
        setDataProduct({});
        setIdCq('');
        setShowAnalisar(false);
    };

    const handleShowImpacto = (pedido) => {
        handleGetIdCq(pedido);
        setPedidoSelecionado(pedido);
        setShowImpacto(true);
    };

    const handleCloseImpacto = () => {
        setPedidoSelecionado({});
        setShowImpacto(false);
        setConsumos({
            consumoAtual: '',
            novoConsumo: ''
        })
        setIdCq('');
    };

    const handleShowConfirmacao = (impacto) => {
        setImpactoConsumo(impacto);
        setShowConfirmacaoImpacto(true);
        setShowAnalisar(false);
    };

    const handleCloseConfirmacao = () => {
        setImpactoConsumo();
        setShowConfirmacaoImpacto(false);
        setShowAnalisar(true);
    };

    const resetStates = () => {
        setMainData([]);
        setDataProduct([]);
        setFilterObj({
            pedido: '',
            material: '',
            cor_material: ''
        });
        setPedidoSelecionado({});
        setImpactoConsumo();
        setIdCq('');
        setLoading(false);
        setShowAnalisar(false);
        setShowImpacto(false);
        setShowConfirmacaoImpacto(false);
        setShowConfirmacaoGrade(false);
        setConsumos({
            consumoAtual: '',
            novoConsumo: ''
        });
    }

    const handleShowConfirmacaoGrade = () => {
        const info = {...consumos}
        const variablesToCheck = ['consumoAtual', 'novoConsumo'];
        const fieldEmpty = variablesToCheck.some(variable => !info[variable] || info[variable] === '0');
        const fieldError = variablesToCheck.some(variable => !parseFloat(info[variable]));

        if(fieldEmpty){
            toast.warning('Preencha os consumos antes de continuar!');
            return;
          }
    
          if (fieldError) {
            toast.error('Campo(s) com erro(s)!');
            return;
          }

        setShowConfirmacaoGrade(true);
        setShowImpacto(false);
    };

    const handleCloseConfirmacaoGrade = () => {
        setShowConfirmacaoGrade(false);
        setShowImpacto(true);
    };

    const handleSearch = async () => {
        setLoading(true);
        const params = { ...filterObj }
        await api.get('MPAnaliseRapportReprovadoCad/get-rapportCad', { params })
          .then(res => {
            setMainData(res.data);
            setLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    };

    const handleSearchProducts = async (pedido) => {
        setLoading(true);
        const params = { ...pedido };
        await api.get('MPAnaliseRapportReprovadoCad/get-dados', { params })
          .then(res => {
            setDataProduct(res.data);
            setLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });   
    }

    const handleGetIdCq = async (pedido) => {
        const params = { ...pedido };
        await api.get('MPAnaliseRapportReprovadoCad/get-idCq', { params })
          .then(res => {
            setIdCq(res.data[0].IDStatusCQ);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });   
    }

    const handleProcessImpactoConsumo = async (impacto) => {

        const params = { 
            Pedido: pedidoSelecionado.Pedido,
            NfEntrada: pedidoSelecionado.NFEntrada,
            Material: pedidoSelecionado.Material,
            CorMaterial: pedidoSelecionado.CorMaterial,
            Item: pedidoSelecionado.Item,
            ImpactoConsumo: impacto
        };

        await api.post('MPAnaliseRapportReprovadoCad/update-impacto', { params })
          .then(res => {
            if (res.status === 200) { 
                resetStates();
                handleSearch();
            } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });   
    }

    const handleProcessConsumo = async () => {

        const params = { 
            Pedido: pedidoSelecionado.Pedido,
            NfEntrada: pedidoSelecionado.NFEntrada,
            Material: pedidoSelecionado.Material,
            CorMaterial: pedidoSelecionado.CorMaterial,
            Item: pedidoSelecionado.Item,
            consumoAtual: consumos.consumoAtual,
            novoConsumo: consumos.novoConsumo,
            idCq: idCq
        };

        await api.post('MPAnaliseRapportReprovadoCad/processar-consumo', { params })
        .then(res => {
          if (res.status === 200) { 
            resetStates();
            handleSearch();
          } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }).catch(e => {
          toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
          console.error(`Erro na requisição - ${e}`);
        });   
    }

    useEffect(() =>{
        handleSearch();
    },[]);

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <article>
                <h3 className="p-3">Análise Rapport Reprovado - CAD</h3>
                <Form className={styles.formWrapper}>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Pedido</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Pedido'}
                        className={styles.formControl}
                        value={filterObj.pedido || ''}
                        onChange={(event) => setFilterObj({...filterObj, pedido: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Material'}
                        className={styles.formControl}
                        value={filterObj.material || ''}
                        onChange={(event) => setFilterObj({...filterObj, material: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Cor Material'}
                        className={styles.formControl}
                        value={filterObj.cor_material || ''}
                        onChange={(event) => setFilterObj({...filterObj, cor_material: event.target.value})}
                    />
                </Form.Group>
                <Button
                    type="button"
                    variant="primary"
                    className={styles.filterBtn}
                    onClick={handleSearch}
                >
                    Buscar
                </Button>
                </Form>
            </article>

            <article className={styles.articleGray}>
                { loading ?
                    <Carregando></Carregando>
                : 
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}>PEDIDO</th>
                            <th className={styles.blockTitle}>NOTA FISCAL</th>
                            <th className={styles.blockTitle}>SERIE NF</th>
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                            <th className={styles.blockTitle}>COR MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                            <th className={styles.blockTitle}>LARGURA UTIL</th>
                            <th className={styles.blockTitle}>MEDIDA INFO</th>
                            <th className={styles.blockTitle}>MENOR RAPPORT ENCONTRADO</th>
                            <th className={styles.blockTitle}>MAIOR RAPPORT ENCONTRADO</th>
                        </tr>
                    </thead>
                    <tbody>
                        { mainData.map((item,index) => {
                        const temImpacto = item.TemImpactoConsumo == 1 ? true : false
                        return (
                        <tr key={index}>
                        { temImpacto ?
                            <td>
                                <Button
                                    variant="success"
                                    type="button"
                                    onClick={() => handleShowImpacto(item)}
                                >
                                    Concluir
                                </Button>
                            </td> 
                            :
                            <td>
                                <Button
                                    variant="warning"
                                    type="button"
                                    onClick={() => handleShowAnalisar(item)}
                                >
                                    Analisar
                                </Button>
                            </td> 
                            }
                            <td className={styles.tableLines}>{item.Pedido}</td>
                            <td className={styles.tableLines}>{item.NFEntrada}</td>
                            <td className={styles.tableLines}>{item.SerieNF}</td>
                            <td className={styles.tableLines}>{item.Fornecedor}</td>
                            <td className={styles.tableLines}>{item.Material}</td>
                            <td className={styles.tableLines}>{item.DescricaoMaterial}</td>
                            <td className={styles.tableLines}>{item.CorMaterial}</td>
                            <td className={styles.tableLines}>{item.DescricaoCor}</td>
                            <td className={styles.tableLines}>{item.MenorLarguraUtil}</td>
                            <td className={styles.tableLines}>{item.MedidaInfo}</td>
                            <td className={styles.tableLines}>{item.MenorLarguraMedida}</td>
                            <td className={styles.tableLines}>{item.MaiorLarguraMedida}</td>
                        </tr>
                        )})}
                    </tbody>
                </Table>
                }
            </article>

            <ModalCad 
                showAnalisar={showAnalisar}
                handleCloseAnalisar={handleCloseAnalisar}
                handleShowConfirmacao={handleShowConfirmacao}
                dataProduct={dataProduct}
                pedidoSelecionado={pedidoSelecionado}
            />

            <ModalConfirmacaoImpacto
                showConfirmacaoImpacto={showConfirmacaoImpacto}
                handleProcessImpactoConsumo={handleProcessImpactoConsumo} 
                handleCloseConfirmacao={handleCloseConfirmacao}
                impactoConsumo={impactoConsumo}
            />

            <ModalImpacto
                showImpacto={showImpacto}
                handleCloseImpacto={handleCloseImpacto}
                consumos={consumos}
                consumosHandler={consumosHandler}
                handleShowConfirmacaoGrade={handleShowConfirmacaoGrade}
                pedidoSelecionado={pedidoSelecionado}
            />

            <ModalConfirmacaoGrade
                showConfirmacaoGrade={showConfirmacaoGrade}
                handleCloseConfirmacaoGrade={handleCloseConfirmacaoGrade}
                handleProcessConsumo={handleProcessConsumo}
                consumos={consumos}
            />
        </div>
        
    );
};

export default AnaliseRapportReprovadoCad;