import React from "react";
import ImpressaoEtiquetas from "../components/Adm/ImpressaoEtiquetas/index";

import styles from '../styles/AdmImpressaoEtiquetas.module.scss';

const AdmImpressaoEtiquetas = () => {
    return (
        <div className="p-2">
          <h3>Impressão Etiquetas - MP</h3>
          <ImpressaoEtiquetas />
        </div>
    );
};

export default AdmImpressaoEtiquetas;