import React from 'react';
import { useLancamentoCad } from '../../../contexts/Corte/ControleEnfesto/LancamentoCadContext';
import { Button, Form } from 'react-bootstrap';
import styles from '../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import MainTable from '../../../components/Corte/ControleEnfesto/LancamentoCad/MainTable';
import ModalLocalTipoPlanta from '../../../components/Corte/ControleEnfesto/LancamentoCad/ModalLocalTipoPlanta';
import ModalMateriais from '../../../components/Corte/ControleEnfesto/LancamentoCad/ModalMateriais';
import ModalVoltar from '../../../components/Corte/ControleEnfesto/LancamentoCad/ModalVoltar';
import ModalRiscos from '../../../components/Corte/ControleEnfesto/LancamentoCad/ModalRiscos';
import ModalConfirmacao from '../../../components/Corte/ControleEnfesto/LancamentoCad/ModalConfirmacao';
import ModalConfirmacaoRiscos from '../../../components/Corte/ControleEnfesto/LancamentoCad/ModalConfirmacaoRiscos';
import ModalFolha from '../../../components/Corte/ControleEnfesto/LancamentoCad/ModalFolha';

const LancamentoCad = () => {

    const { filterObj, setFilterObj, fetchMainData } = useLancamentoCad();

    return (
        <>
            <article>
                <h3 className="p-3">Corte - Lançamento CAD</h3>
                <Form className={styles.formWrapper}>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Ordem Serviço</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Ordem Serviço'}
                            className={styles.formControl}
                            value={filterObj.os || ''}
                            onChange={(event) => setFilterObj({...filterObj, os: event.target.value})}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Ordem Produção</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Ordem Produção'}
                            className={styles.formControl}
                            value={filterObj.op || ''}
                            onChange={(event) => setFilterObj({...filterObj, op: event.target.value})}
                        />
                    </Form.Group>
                    <Button
                        type="button"
                        variant="primary"
                        className={styles.filterBtn}
                        onClick={fetchMainData}
                    >
                        Buscar
                    </Button>
                </Form>
            </article>

            <MainTable/>
            <ModalLocalTipoPlanta/>
            <ModalMateriais/>
            <ModalVoltar/>
            <ModalRiscos/>
            <ModalFolha/>
            <ModalConfirmacao/>
            <ModalConfirmacaoRiscos/>
        </>        
    );
};

export default LancamentoCad;