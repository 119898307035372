import { createContext, useContext, useState } from 'react';
import { useMisc } from '../../../contexts/MiscContext';
import { toast } from 'react-toastify';
import PAGerarLaudo from './gerar_laudo_main';
import { api } from '../../../services/api';
import { gerarFichaTecnica } from '../../../utils/gerar_laudo_excel';
import { fifteenDaysAgoSimple, todaySimple, toUTCDatetime } from '../../../utils/dateUtils';

const PAGerarLaudoContext = createContext();
export const usePAGerarLaudoContext = () => useContext(PAGerarLaudoContext);

export const PAGerarLaudoProvider = () => {
  const { setShowLoading } = useMisc();
  const [listaPrincipal, setListaPrincipal] = useState([]);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [filterErrs, setFilterErrs] = useState({});
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterObj, setFilterObj] = useState({
    status: null,
    envio: null,
    data_inicio: fifteenDaysAgoSimple(),
    data_fim: todaySimple(),
    nf_entrada: '',
    produto: '',
    cor_produto: '',
  });

  const filterOptions = [
    { label: 'Aprovados', value: 'APROV' },
    { label: 'Devolvidos', value: 'DEVOL' },
    // {label: 'Pendente Definição', value: 'PENDENTE'},
    { label: 'Pendente Definicao', value: 'PEND' }
  ]
  ;
  const listOptions = [
    { label: 'Pendente envio', value: 0 },
    { label: 'Laudos enviados', value: 1 },
    { label: 'Todos', value: 2 }
  ];

  const selecionarLinhas = (items) => setItensSelecionados(items.selectedRows);

  const fetchLaudoGrid = async () => {
    try {
      const errs = handleFilterValidation();
      if (Object.keys(errs).length > 0) return toast.warn('É necessário ter duas datas para usar o filtro desta tela.');
      setShowLoading(true);

      const params = {
        status: filterObj.status ? filterObj.status.value : null,
        envio: filterObj.envio ? filterObj.envio.value : null,
        data_inicio: filterObj.data_inicio ? toUTCDatetime(filterObj.data_inicio) : null,
        data_fim: filterObj.data_fim ? toUTCDatetime(filterObj.data_fim) : null,
        nf_entrada: filterObj.nf_entrada,
        produto: filterObj.produto,
        cor_produto: filterObj.cor_produto
      }

      const resLista = await api.get('/pa-gerar-laudo/listar-laudos', { params });
      setListaPrincipal([...resLista.data]);
      setItensSelecionados([]);
      setClearSelectedRows(!clearSelectedRows);
    } catch (e) {
      toast.error('Ocorreu um erro ao recarregar a listagem.\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte');
    } finally {
      setShowLoading(false);
    }
  }

  const handleFilterValidation = () => {
    const { data_inicio, data_fim } = filterObj;
    const errs = {}
    if (!data_inicio || (data_fim < data_inicio)) errs.data_inicio = 'Por favor insira uma data início válida';
    if (!data_fim || (data_fim < data_inicio)) errs.data_fim = 'Por favor insira uma data fim válida';
    return errs;
  }

  const gerarLaudos = async () => {
    try {
      setShowLoading(true);

      const errArr = await gerarFichaTecnica(itensSelecionados);
      if (errArr && errArr.length > 0) {
        for (var i = 0; i < errArr.length; i++) {
          if (errArr[i].includes('nfEntrada')) errArr[i] = 'Nota Fiscal Entrada'
          if (errArr[i].includes('serieNf')) errArr[i] = 'Série da Nota Fiscal'
          if (errArr[i].includes('ordemProducao')) errArr[i] = 'Ordem Produção'
          if (errArr[i].includes('valorItemNf')) errArr[i] = 'Valor do Item da Nota Fiscal'
          if (errArr[i].includes('dataDigitacao')) errArr[i] = 'Data Digitação'
          if (errArr[i].includes('qtdeRecebida')) errArr[i] = 'Quantidade Recebida'
        }
        toast.warn(`Os seguintes campos são nulos, por favor corrija-os: ${errArr.toString()}`);
      }
    } catch (e) {
      toast.error(`Ocorreu um erro durante a geração do(s) laudo(s).\nPor favor tente novamente ou entre em contato com o suporte.`);
    } finally {
      setShowLoading(false);
    }
  }

  return (
    <PAGerarLaudoContext.Provider
      value={{ listaPrincipal, selecionarLinhas, itensSelecionados, filterOptions, listOptions, fetchLaudoGrid, gerarLaudos, filterObj, setFilterObj, filterErrs, setFilterErrs, clearSelectedRows }}
    >
      {< PAGerarLaudo />}
    </PAGerarLaudoContext.Provider >
  )
}
