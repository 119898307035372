import React from 'react';
import Tabela from '../../Misc/Tabela/Tabela';
import TabelaDND from '../../Misc/TabelaDND/tabela_dnd';
import { Button } from 'react-bootstrap';
import { usePARevisao } from './pa_revisao_context';
import PARevisaoDirecionamento from './components/pa_revisao_modal_direcionamento';
import PARevisaoGrade from './components/pa_revisao_modal_grade';
import styles from './pa_revisao.module.scss'
import PARevisaoModalConfirmacaoSQ from './components/pa_revisao_modal_confirmacao_sq';
import PARevisaoModalProcessamento from './components/pa_revisao_modal_processamento';
import FiltroRevisao from './components/pa_revisao_filtro';
import ListarImagemModal from '../../Misc/ListarImagens/listar_imagens_modal';

const PARevisaoMain = () => {
  const { fetchRevisaoGrid, listaRevisaoFiltrada, iniciarRevisao, modalRevisao, modalDirecionamento, modalConfirmacaoSQ, modalProcessamento, modoAdm, setModoAdm, resetarTarefas, handleAdmAlterar, revisaoDesabilitada, podePriorizar, modalImagens, modalImagensDados, fecharModalImagens, adicionarImagens, removerImagem, direcionamentoRevisao } = usePARevisao();

  const conditionalRowStyles = [
    {
      when: row => row.priorizado,
      style: {
        '&:nth-child(odd)': { backgroundColor: '#FFF8DA' },
        '&:nth-child(even)': { backgroundColor: '#FFFBEA' }
      }
    },
    {
      when: row => row.em_revisao,
      style: {
        '&:nth-child(odd)': { backgroundColor: '#CCFFDA50' },
        '&:nth-child(even)': { backgroundColor: '#CCFFDA80' }
      }
    }
  ];

  const revisaoColumns = [
    {
      cell: row => {
        return (
          <Button
            style={{ fontWeight: '500' }}
            variant={row.nf_segunda_qualidade || row.produto_de_couro ? 'warning' : 'primary'}
            onClick={() => iniciarRevisao(row)}
            disabled={row.priorizado && !row.em_revisao ? false : revisaoDesabilitada(row.em_revisao, row.id_entrada_fiscal)}
          >
            {row.nf_segunda_qualidade || row.produto_de_couro ? 'Rev. 100%' : 'Revisão'}
          </Button>
        )
      }
    },
    { name: 'NF Entrada', selector: row => row.nf_entrada },
    { name: 'Serie NF', selector: row => row.serie_nf },
    { name: 'Origem', selector: row => row.origem },
    { name: 'Filial Revisão', selector: row => row.filial_revisao },
    { name: 'Produto', selector: row => row.produto },
    { name: 'Desc. Produto', selector: row => row.descricao_produto },
    { name: 'Cor', selector: row => row.cor_produto },
    { name: 'Desc. Cor', selector: row => row.descricao_cor },
    { name: 'Qtde.', selector: row => row.qtde_entrada },
    { name: 'Ordem Produção', selector: row => row.ordem_producao },
    { name: 'Coleção', selector: row => row.colecao },
    { name: 'Linha', selector: row => row.linha },
    { name: 'Preço', selector: row => row.preco },
    { name: 'Data Entrada Revisão', selector: row => row.data_entrada_revisao_br }
  ];

  return (
    <>
      <section className={styles.flex_title}>
        <h4 className={styles.title}>
          Revisão PA
        </h4>
      </section>
      <section className={styles.header_section}>
        <div className={styles.adm_btn_wrapper}>
          <FiltroRevisao />

          <Button
            style={{ alignSelf: 'flex-end', fontWeight: '500' }}
            variant={'secondary'}
            onClick={fetchRevisaoGrid}
          >
            Atualizar Tabela
          </Button>
        </div>

        {
          podePriorizar &&
          <div className={styles.adm_btn_wrapper}>
            <Button
              style={{ alignSelf: 'flex-end', fontWeight: '500' }}
              variant={'success'}
              onClick={handleAdmAlterar}
              disabled={!modoAdm}
            >
              Priorizar Produções
            </Button>
            <Button
              style={{ alignSelf: 'flex-end', fontWeight: '500' }}
              variant={'warning'}
              onClick={resetarTarefas}
              disabled={!modoAdm}
            >
              Desfazer Alterações
            </Button>
            <Button
              style={{ alignSelf: 'flex-end', fontWeight: '500' }}
              variant={modoAdm ? 'danger' : 'primary'}
              disabled={false}
              onClick={() => setModoAdm(!modoAdm)}
            >
              {modoAdm ? 'Desativar Modo Admin' : 'Ativar Modo Admin'}
            </Button>
          </div>
        }
      </section>
      <section style={{ margin: '0px 20px 20px' }}>
        {!modoAdm &&
          <Tabela
            columns={revisaoColumns}
            data={listaRevisaoFiltrada}
            pending={false}
            paginationPerPage={20}
            highlightOnHover={true}
            conditionalRowStyles={conditionalRowStyles}
          />
        }

        {modoAdm && <TabelaDND />}
      </section>

      {modalDirecionamento && <PARevisaoDirecionamento />}
      {modalRevisao && <PARevisaoGrade />}
      {modalConfirmacaoSQ && <PARevisaoModalConfirmacaoSQ />}
      {modalProcessamento && <PARevisaoModalProcessamento />}
      {modalImagens.show &&
        <ListarImagemModal
          showModal={modalImagens.show}
          closeModal={fecharModalImagens}
          imagens={direcionamentoRevisao.length > 0 && direcionamentoRevisao[modalImagens.index].imagens || []}
          direcionamentoIndex={modalImagens.index}
          adicionarImagens={adicionarImagens}
          removerImagem={removerImagem}
        />
      }
    </>
  )
}

export default PARevisaoMain;
