import React from 'react';
import Filtro from '../../Misc/Filtro/Filtro';
import { usePAReversa } from '../../../contexts/PAReversaContext';
import Tabela from '../../Misc/Tabela/Tabela';
import PAReversaInserirDefeito from './PAReversaInserirDefeito';
import { Button, Form } from 'react-bootstrap';
import styles from './PAReversa.module.scss';
import PAReversaModalBusca from './PAReversaModalBusca';
import PAReversaModalConfirmacao from './PAReversaModalConfirmacao';

const PAReversaMain = () => {
  const { handleFilterClick, listReversa, togglePossuiDefeito, listReversaFiltrada, listReversaPending, abrirModalDefeitos, showModal, filtroProduto, filtrarPorProduto, showModalBusca, romaneioFiltrado, showModalConfirmacao, setShowModalConfirmacao } = usePAReversa();

  const checklistColumns = [
    {
      cell: row => {
        return (
          <Button
            variant={row.nao_possui_defeito ? 'success' : 'primary'}
            disabled={row.direcionamento_defeitos.length > 0}
            onClick={() => togglePossuiDefeito(row.index)}
          >
            Sem defeitos
          </Button>
        )
      },
      width: '160px'
    },
    {
      cell: row => {
        return (
          <Button
            variant={row.direcionamento_defeitos.length > 0 ? 'danger' : 'primary'}
            disabled={row.nao_possui_defeito}
            onClick={() => abrirModalDefeitos(row)}
          >
            Inserir Defeitos
          </Button>
        )
      },
      width: '160px'
    },
    { name: 'Romaneio', selector: row => row.romaneio },
    { name: 'Filial', selector: row => row.filial },
    { name: 'Filial Destino', selector: row => row.filial_destino },
    { name: 'Produto', selector: row => row.produto },
    { name: 'Cor Produto', selector: row => row.cor_produto },
    { name: 'Data Saída', selector: row => row.data_saida_br },
    { name: 'Qtde. Saída', selector: row => row.qtde_saida },
    { name: 'Qtde. Romaneio', selector: row => row.qtde_romaneio },
  ];

  const conditionalRowStyles = [
    {
      when: row => row.direcionamento_defeitos.length > 0,
      style: row => ({ backgroundColor: row.index % 2 === 0 ? '#FFCCCC80' : '#FFCCCC50' })
    },
    {
      when: row => row.nao_possui_defeito,
      style: row => ({ backgroundColor: row.index % 2 === 0 ? '#CCFFDA80' : '#CCFFDA50' })
    }
  ];

  return (
    <>
      {/* Romaneios para teste: RAB21634, RYBQ1947 */}
      <h4 style={{ margin: '10px 20px 0px', color: '#000000AE' }}>Reversa E-commerce PA</h4>
      <Filtro
        activeFilters={[{ key: 'romaneio', required: true }]}
        searchFn={handleFilterClick}
      >
        {
          listReversa.length > 0 &&
          <section style={{ display: 'flex', gap: '20px', width: '100%' }}>
            <Form.Group className={styles.form_group} style={{ marginLeft: 'auto' }}>
              <Form.Label className={styles.filter_label}>Filtrar por Produto</Form.Label>
              <Form.Control
                className={styles.filter_control}
                type={'text'}
                placeholder={'00.0000'}
                style={{ fontSize: '13px' }}
                value={filtroProduto}
                onChange={e => filtrarPorProduto(e.target.value)}
              />
            </Form.Group>
            <Button
              variant={'success'}
              className={styles.filter_btn}
              onClick={() => setShowModalConfirmacao(true)}
            >
              {`Processar Romaneio ${romaneioFiltrado}`}
            </Button>
          </section>
        }
      </Filtro>
      <section style={{ margin: '0px 20px 20px' }}>
        <Tabela
          columns={checklistColumns}
          data={listReversaFiltrada}
          pending={listReversaPending}
          paginationPerPage={20}
          conditionalRowStyles={conditionalRowStyles}
          highlightOnHover={false}
        />
      </section>
      {showModal && <PAReversaInserirDefeito />}
      {showModalBusca && <PAReversaModalBusca />}
      {showModalConfirmacao && <PAReversaModalConfirmacao />}
    </>
  )
}

export default PAReversaMain;
