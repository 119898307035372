import React, { useState, useEffect } from "react";
import { Button, Table, Form, FormGroup, Modal, Col, Container, Row, Accordion } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';

import "bootstrap/dist/css/bootstrap.min.css";

import "react-toastify/dist/ReactToastify.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import MaterialIcon from 'material-icons-react';

import styles from "../../../../styles/MonitorRevisaoIdentificacao.module.scss";

import { api, retryApi } from "../../../../services/api";
import ProdutoInfo from "../componentes/ProdutoInfo";
import MonitorRevisaoAnaliseGrade from "../AnaliseGrade"
import MonitorRevisaoSegundaQualidadeDefeito from "../SegundaQualidadeDefeito"
import { toUTCDDMMYYYY } from "../../../../utils/dateUtils"
import CookiesService from "../../../../services/cookies";

function MonitorRevisaoIdentificacao(props) {
    const [openEntradaFiscal, setOpenEntradaFiscal] = useState(false);
    const [openIdentificacaoProduto, setOpenIdentificacaoProduto] = useState(false);
    const [show, setShow] = useState(false);
    const [modelagemEstilo, setModelagemEstilo] = useState([]);
    const [pecaLacrada, setPecaLacrada] = useState([]);
    const [corte, setCorte] = useState([]);
    const [graduacao, setGraduacao] = useState([]);
    const [beneficiamento, setBeneficiamento] = useState([]);
    const [recebePiloto, setRecebePiloto] = useState([]);
    const [statusAnalise, setStatusAnalise] = useState([]);
    const [responsavelAnalise, setResponsavelAnalise] = useState([]);
    // const [desabilitaDadosAnalise, setDesabilitaDadosAnalise] = useState(true);

    const [modelagemEstiloSelecionada, setModelagemEstiloSelecionada] = useState('');
    const [pecaLacradaSelecionada, setPecaLacradaSelecionada] = useState('');
    const [corteSelecionada, setCorteSelecionada] = useState('');
    const [graduacaoSelecionada, setGraduacaoSelecionada] = useState('');
    const [beneficiamentoSelecionada, setBeneficiamentoSelecionada] = useState('');
    const [recebePilotoSelecionada, setRecebePilotoSelecionada] = useState('');
    const [statusAnaliseSelecionada, setStatusAnaliseSelecionada] = useState('');
    const [responsavelAnaliseSelecionado, setResponsavelAnaliseSelecionado] = useState('');
    const [obsRecebimentoPiloto, setObsRecebimentoPiloto] = useState('');

    const [recebido, setRecebido] = useState('');
    const [qtdPecasRecedidas2Q, setQtdPecasRecedidas2Q] = useState('');
    const [qtdPecasDirecionadas, setQtdPecasDirecionadas] = useState('');
    const [qtdPecasAmostra, setQtdPecasAmostra] = useState(0);

    const [gradeSegundaQualidade, setGradeSegundaQualidade] = useState([]);
    const [destinos, setDestinos] = useState([]);
    const [qtdDePecasSegundaQualidade, setQtdDePecasSegundaQualidade] = useState('');
    const [destinoSelecionado, setDestinoSelecionado] = useState('');
    const [destinoSelecionadoDesc, setDestinoSelecionadoDesc] = useState('');
    const [itemGradeSegundaQualidadeSelecionado, setItemGradeSegundaQualidadeSelecionado] = useState({});
    const [gradeSegundaQualidadeSelecionada, setGradeSegundaQualidadeSelecionada] = useState([]);
    const [obsRecebimentoPilotoSegundaQualidade, setObsRecebimentoPilotoSegundaQualidade] = useState('');
    const [gradeSegundaQualidadeSelecionadaQtd, setGradeSegundaQualidadeSelecionadaQtd] = useState(0);
    const [segundaQualidadeFinalizada, setSegundaQualidadeFinalizada] = useState(false);
    const [showModalSegundaQualidade, setShowModalSegundaQualidade] = useState(false);
    const [closeModalSegundaQualidade, setCloseModalSegundaQualidade] = useState(false);
    const [showModalAnalisaGrade, setShowModalAnalisaGrade] = useState(false);
    const [indicaDirecionamento, setIndicaDirecionamento] = useState(false);
    const [precoIncorreto, setPrecoIncorreto] = useState('');

    const [gradeSegundaQualidadeDefeitosFinalizados, setGradeSegundaQualidadeDefeitosFinalizados] = useState([]);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = async () => {
        if (parseInt(qtdPecasDirecionadas) > parseInt(qtdPecasRecedidas2Q)) {
            toast.error('A quantidade de peças direcionadas não pode ser maior que a quantidade recebida!');
            return false;
        }

        await getGradeSegundaAnalise();
        await listDestinos();
        setGradeSegundaQualidadeSelecionada([]);
        setShow(true);
    }

    useEffect(() => {
        listListaStatus();
        listRecebePiloto();
        listListaStatusAnalise();
        listListaResponsavelAnalise();
    }, []);
    
    useEffect(() => {
        calculaQuantidadeQueFaltaSegundaQualidade();
    }, [itemGradeSegundaQualidadeSelecionado, gradeSegundaQualidadeSelecionada, gradeSegundaQualidadeDefeitosFinalizados, recebido]);
    
    function temRegistrosNoArray(arrayRecebido) {
        return arrayRecebido.length > 0;
    }

    async function listListaStatus() {
        try {
            const lista = await retryApi('get', "PArevisao/ListaStatus");
            if (temRegistrosNoArray(lista.data)) {
                const listaStatus = lista.data.map((item, index) => {
                    return { value: item.IDStatusDev, label: item.LISTA_STATUS };
                });
                setModelagemEstilo(listaStatus);
                setPecaLacrada(listaStatus);
                setCorte(listaStatus);
                setGraduacao(listaStatus);
                setBeneficiamento(listaStatus);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de status!');
            return false;
        }
    }

    async function listRecebePiloto() {
        const params = { recebePiloto: true }
        try {
            const lista = await retryApi('get', "PArevisao/ListaStatus", { params });
            if (temRegistrosNoArray(lista.data)) {
                const listaStatus = lista.data.map((item, index) => {
                    return { value: item.IDStatusDev, label: item.LISTA_STATUS };
                });
                setRecebePiloto(listaStatus);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar recebe piloto!');
            return false;
        }
    }

    async function listListaStatusAnalise() {
        try {
            const lista = await retryApi('get', "PArevisao/ListaStatusAnalise");
            if (temRegistrosNoArray(lista.data)) {
                const listaStatus = lista.data.map((item, index) => {
                    return { value: item.ID_STATUS, label: item.LISTA_STATUS };
                });
                setStatusAnalise(listaStatus);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar status de análise!');
            return false;
        }
    }

    async function listListaResponsavelAnalise() {
        try {
            const lista = await retryApi('get', "PArevisao/ListaResponsavelAnalise");
            if (temRegistrosNoArray(lista.data)) {
                const listaStatus = lista.data.map((item, index) => {
                    return { value: item.IDFUNCIONARIO, label: item.LISTAFUNCIONARIO };
                });
                setResponsavelAnalise(listaStatus);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar lista de responsáveis!');
            return false;
        }
    }

    const handleModelagemEstilo = async (opcaoSelecionada) => {
        setModelagemEstiloSelecionada(opcaoSelecionada);
    }
    const handlePecaLacrada = async (opcaoSelecionada) => {
        setPecaLacradaSelecionada(opcaoSelecionada);
    }
    const handleCorte = async (opcaoSelecionada) => {
        setCorteSelecionada(opcaoSelecionada);
    }
    const handleGraduacao = async (opcaoSelecionada) => {
        setGraduacaoSelecionada(opcaoSelecionada);
    }
    const handleBeneficiamento = async (opcaoSelecionada) => {
        setBeneficiamentoSelecionada(opcaoSelecionada);
    }
    const handleRecebePiloto = async (opcaoSelecionada) => {
        setRecebePilotoSelecionada(opcaoSelecionada);
    }
    const handleStatusAnalise = async (opcaoSelecionada) => {
        setStatusAnaliseSelecionada(opcaoSelecionada);
    }
    const handleResponsavelAnalise = async (opcaoSelecionada) => {
        setResponsavelAnaliseSelecionado(opcaoSelecionada);
    }
    const handleDestino = async (opcaoSelecionada) => {
        setDestinoSelecionado(opcaoSelecionada.value);
        setDestinoSelecionadoDesc(opcaoSelecionada.label);
    }
    const handleItemSegundaGradeQualidade = async (opcaoSelecionada) => {
        setItemGradeSegundaQualidadeSelecionado(opcaoSelecionada);
    }
    const handleIncluiItemSegundaQualidade = async () => {
        if (!qtdDePecasSegundaQualidade) {
            toast.error('Você deve preencher a quantidade de peças!');
            return false;
        }
        if (!destinoSelecionado) {
            toast.error('Você deve selecionar um destino!');
            return false;
        }
        if (parseInt(qtdPecasDirecionadas) < parseInt(qtdDePecasSegundaQualidade)) {
            toast.error('A quantidade de peças não pode ser maior que Qtde 2Q Direcionadas!');
            return false;
        }
        if (qtdDePecasSegundaQualidade > gradeSegundaQualidadeSelecionadaQtd) {
            toast.error(`A quantidade de peças não pode ser maior que a quantidade que falta. No máximo ${gradeSegundaQualidadeSelecionadaQtd}`);
            return false;
        }
        if (qtdDePecasSegundaQualidade < 1) {
            toast.error(`A quantidade de peças não poder igual a zero.`);
            return false;
        }


        let jaExiste = gradeSegundaQualidadeSelecionada.filter((item) => item.Destino === destinoSelecionado && item.Tamanho === itemGradeSegundaQualidadeSelecionado.Tamanho);

        if (jaExiste.length > 0) {
            toast.error(`Já existe este direcionamento para a Grade e Destino informados. Selecione outro destino ou exclua o direcionamento informado.`);
            return false;
        }

        if (!itemGradeSegundaQualidadeSelecionado) {
            return false;
        }
        const itemParaInserir = {
            ...itemGradeSegundaQualidadeSelecionado,
            QtdePecas: qtdDePecasSegundaQualidade,
            Destino: destinoSelecionado,
            Obs: obsRecebimentoPilotoSegundaQualidade,
            DestinoDesc: destinoSelecionadoDesc,
            Qtde_Pendente: parseInt(itemGradeSegundaQualidadeSelecionado.Qtde) - parseInt(qtdDePecasSegundaQualidade),
            TipoAnalise: '02 - SEGUNDA QUALIDADE'
        }
        if (itemGradeSegundaQualidadeSelecionado.Qtde_Pendente - parseInt(itemParaInserir.QtdePecas) < 0) {
            toast.error('A quantidade de peças não pode ser maior que a quantidade de pendente!');
            return false;
        }
        if (parseInt(itemParaInserir.QtdePecas) > parseInt(itemGradeSegundaQualidadeSelecionado.Qtde)) {
            toast.error('A quantidade de peças não pode ser maior que a quantidade recebida!');
            return false;
        }
        let grade = [];
        grade = grade.concat(gradeSegundaQualidadeSelecionada);
        grade.push(itemParaInserir);
        setGradeSegundaQualidadeSelecionada(grade);
        const gradeSegundaQualidadeAlterada = gradeSegundaQualidade.map((item) => {
            let novaQtd = parseInt(item.QtdeDirecionada);
            let novaPendencia = parseInt(item.Qtde_Pendente);
            if (item.Tamanho == itemParaInserir.Tamanho) {
                novaQtd += parseInt(itemParaInserir.QtdePecas);
                novaPendencia = novaPendencia - parseInt(itemParaInserir.QtdePecas);
            }
            item.QtdeDirecionada = novaQtd;
            item.Qtde_Pendente = novaPendencia;
            return { ...item }
        });
        setGradeSegundaQualidade(gradeSegundaQualidadeAlterada);
        grade = [];
    }

    const handleExcluiItemSegundaQualidade = async (itemParaExcluir) => {
        setGradeSegundaQualidadeSelecionada(gradeSegundaQualidadeSelecionada.filter(el => el !== itemParaExcluir));
        let grade = [];
        grade = grade.concat(gradeSegundaQualidade);

        const gradeSegundaQualidadeAlterada = gradeSegundaQualidade.map((item) => {
            let novaQtd = parseInt(item.QtdeDirecionada);
            let novaPendencia = parseInt(item.Qtde_Pendente);
            if (item.Tamanho == itemParaExcluir.Tamanho) {
                novaQtd = novaQtd - parseInt(itemParaExcluir.QtdePecas);
                novaPendencia = novaPendencia + parseInt(itemParaExcluir.QtdePecas);
            }
            item.QtdeDirecionada = novaQtd;
            item.Qtde_Pendente = novaPendencia;
            return { ...item }
        });
        setGradeSegundaQualidade(grade);
    }


    async function getGradeSegundaAnalise() {
        try {
            const { Produto, CorProduto, NFEntrada, SerieNF, Origem, OrdemProducao, QtdeEntrada, FilialRevisao } = props.item;
            const params = {
                Produto: Produto,
                CorProduto: CorProduto,
                NFEntrada: NFEntrada,
                SerieNF: SerieNF,
                Origem: Origem,
                OrdemProducao: OrdemProducao,
                QtdeEntrada: QtdeEntrada,
                FilialRevisao: FilialRevisao,
                TipoAnalise: '02 - SEGUNDA QUALIDADE'
            };
            const lista = await retryApi('get', "PArevisao/GradeSegundaQualidade", { params });
            setGradeSegundaQualidade(lista.data);
        } catch (error) {
            toast.error('Ocorreu um erro para carregar grade da segunda qualidade!');
            return false;
        }
    }

    async function listDestinos() {
        const params = { recebePiloto: true }
        try {
            const lista = await retryApi('get', "PArevisao/ListaDestino", { params });
            if (temRegistrosNoArray(lista.data)) {
                const listaDestino = lista.data.map((item, index) => {
                    return { value: item.IDDestino, label: item.Destino };
                });
                setDestinos(listaDestino);
            }
        } catch (error) {
            toast.error('Ocorreu um erro para carregar recebe piloto!');
            return false;
        }
    }

    async function calculaQuantidadeQueFaltaSegundaQualidade() {
        let totalPecas = 0;
        if (gradeSegundaQualidadeSelecionada.length === 0) {
            totalPecas = qtdPecasDirecionadas;
        }
        totalPecas = gradeSegundaQualidadeSelecionada.reduce(function (sum, item) {
            const updatedSum = sum + parseInt(item.QtdePecas);
            return updatedSum;
        }, 0);
        setGradeSegundaQualidadeSelecionadaQtd(qtdPecasDirecionadas - totalPecas);
    }

    async function handleFinalizaRevisaoSegundaQualidade(gradeRevisaoSegundaQualidade) {
        setGradeSegundaQualidadeDefeitosFinalizados(gradeRevisaoSegundaQualidade);
        toast.success('Segunda Qualidade Finalizada!');
        setShowModalSegundaQualidade(false);
        setShow(false);
    }

    async function handleCloseAll() {
        setShow(false);
        setShowModalAnalisaGrade(false);
        setShowModalSegundaQualidade(false);
        setGradeSegundaQualidade([]);
        setGradeSegundaQualidadeDefeitosFinalizados([]);
        setGradeSegundaQualidadeSelecionada([]);
        props.handleClose();
    }

    return (
        <div>
            {/* Entrada Fiscal */}
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Entrada Fiscal</Accordion.Header>
                    <Accordion.Body style={{ padding: "0px" }}>
                        <div className={styles.gridRow}>
                            <div className={styles.gridItem}>
                                <span>Nota Fiscal:</span>
                                <span className={styles.fontBlue}>{props.item.NFEntrada}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Origem:</span>
                                <span className={styles.fontBlue}>{props.item.Origem}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Filial Revisão:</span>
                                <span className={styles.fontBlue}>{props.item.FilialRevisao}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Série:</span>
                                <span className={styles.fontBlue}>{props.item.SerieNF}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Qtde Recebida:</span>
                                <span className={styles.fontBlue}>{props.item.QtdeEntrada}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Recebimento:</span>
                                <span className={styles.fontBlue}>{toUTCDDMMYYYY(props.item.Recebimento)}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Ordem Produção:</span>
                                <span className={styles.fontBlue}>{props.item.OrdemProducao}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Qtde a Revisar:</span>
                                <span className={styles.fontBlue}>FALTANDO</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Entrada Revisão:</span>
                                <span className={styles.fontBlue}>{toUTCDDMMYYYY(props.item.DataEntradaRevisao)}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Ordem Serviço:</span>
                                <span className={styles.fontBlue}>{props.item.OrdemServico}</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Porc. a Revisar:</span>
                                <span className={styles.fontBlue}>FALTANDO</span>
                            </div>
                            <div className={styles.gridItem}>
                                <span>Digitação:</span>
                                <span className={styles.fontBlue}>{toUTCDDMMYYYY(props.item.DataDigitacao)}</span>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/* Identificação Produto */}
            <Accordion style={{ marginTop: "10px" }}>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Identificação do Produto</Accordion.Header>
                    <Accordion.Body>
                        <ProdutoInfo item={props.item}></ProdutoInfo>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/* Identificação Desenvolvimento */}
            {/* <article className={styles.articleBorder}>
                <h5>Identificação Desenvolvimento</h5>
                <div className={styles.formLine}>
                    <Form className={styles.formIdentificacao}>
                        <Form.Label>Modelagem Estilo</Form.Label>
                        <Select 
                            closeMenuOnSelect={true} 
                            onChange={(e)=>{handleModelagemEstilo(e)}}
                            options={modelagemEstilo} 
                        />
                    </Form>
                    <Form className={styles.formIdentificacao}>
                        <Form.Label>Peça Lacrada:</Form.Label>
                        <Select 
                            closeMenuOnSelect={true} 
                            onChange={(e)=>{handlePecaLacrada(e)}}
                            options={pecaLacrada} 
                        />
                    </Form>
                    <Form className={styles.formIdentificacao}>
                        <Form.Label>Corte:</Form.Label>
                        <Select 
                            closeMenuOnSelect={true} 
                            onChange={(e)=>{handleCorte(e)}}
                            options={corte} 
                        />
                    </Form>
                    <Form className={styles.formIdentificacao}>
                        <Form.Label>Graduação:</Form.Label>
                        <Select 
                            closeMenuOnSelect={true} 
                            onChange={(e)=>{handleGraduacao(e)}}
                            options={graduacao} 
                        />
                    </Form>
                    <Form className={styles.formIdentificacao}>
                        <Form.Label>Beneficiamento:</Form.Label>
                        <Select 
                            closeMenuOnSelect={true} 
                            onChange={(e)=>{handleBeneficiamento(e)}}
                            options={beneficiamento} 
                        />
                    </Form>
                    <Form className={styles.formIdentificacao}>
                        <Form.Label>Receb. Piloto:</Form.Label>
                        <Select 
                            closeMenuOnSelect={true} 
                            onChange={(e)=>{handleRecebePiloto(e)}}
                            options={recebePiloto} 
                        />
                    </Form>

                </div>
                <div className={styles.formLine}>
                    <Form>
                        <Form.Label>
                            Observação Recebimento Piloto 
                            <span className={styles.spanOpcional}>(Opcional)</span>
                            :
                        </Form.Label>
                        <Form.Control as="textarea" className={styles.formControl} id="exampleFormControlTextarea1" rows="2" onChange = {e=>(setObsRecebimentoPiloto(e.target.value))}></Form.Control>
                    </Form>
                </div>
                <div className={styles.formLine} id="deb" style={{ display:'inline'}}>

                // <span>recebido: {recebido}</span>
                // <span>desabilitaSegundaQualidade: {desabilitaSegundaQualidade}</span>
                // <span>desabilitaDadosAnalise: {desabilitaDadosAnalise}</span>
                
                </div>
            </article> */}

            {/* Segunda Qualidade */}
            <article className={styles.articleBorder} >
                <h5>Segunda Qualidade</h5>
                <div className={styles.formColumm}>
                    <Form>
                        <Form.Label>1. Recebido?</Form.Label>
                        <Form.Check
                            type={'radio'}
                            id={'naoRecebido'}
                            label={'Não'}
                            name={'groupRecebido'}
                            value={'nao'}
                            onChange={(e) => { setRecebido(e.target.value); setQtdPecasRecedidas2Q(''); setQtdPecasDirecionadas(''); }}

                        />
                        <Form.Check
                            type={'radio'}
                            id={'simRecebido'}
                            label={'Sim'}
                            name={'groupRecebido'}
                            value={'sim'}
                            onChange={(e) => { setRecebido(e.target.value); setIndicaDirecionamento(true) }}
                        />
                    </Form>
                    <Form>
                        <Form.Label>1.1 Qtde peças recebidas 2Q:</Form.Label>
                        <Form.Control type="number" min="0" disabled={recebido !== 'sim'}
                            value={recebido === 'nao' ? '' : qtdPecasRecedidas2Q}
                            onChange={(e) => (setQtdPecasRecedidas2Q(e.target.value))}
                        />
                    </Form>
                    <Form>
                        <Form.Label>1.2 Qtde peças direcionadas:</Form.Label>
                        <Form.Control type="number" min="0" disabled={recebido !== 'sim'}
                            value={recebido === 'nao' ? '' : qtdPecasDirecionadas}
                            onChange={(e) => (setQtdPecasDirecionadas(e.target.value))}
                        />
                    </Form>
                </div>
                <div className={styles.buttonRight}>
                    <Button
                        disabled={recebido !== 'sim' || !qtdPecasRecedidas2Q || !qtdPecasDirecionadas}
                        variant="outline-secondary"  //desabilitado
                        //variant="outline-primary"  //habilitado
                        onClick={handleShow}
                    >
                        Iniciar Direcionamento Segunda Qualidade
                    </Button>

                </div>
            </article>

            {/* Dados Análise */}
            <article className={styles.articleBorder} style={{ marginBottom: 70 }}>
                <h5>Dados Análise</h5>
                <div className={styles.formLine}>
                    <Form className={styles.formDadosanalise}>
                        <Form.Label>Status:</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            onChange={handleStatusAnalise}
                            options={statusAnalise}
                            isOptionDisabled={(option) => (gradeSegundaQualidadeDefeitosFinalizados.length === 0 && recebido !== 'nao')}
                        />
                    </Form>
                    <Form className={styles.formDadosanalise}>
                        <Form.Label>Responsável:</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            onChange={handleResponsavelAnalise}
                            options={responsavelAnalise}
                            isOptionDisabled={(option) => (gradeSegundaQualidadeDefeitosFinalizados.length === 0 && recebido !== 'nao')}
                        />
                    </Form>
                    <Form className={styles.formDadosanalise}>
                        <Form.Label>Qtde Peças Amostra:</Form.Label>
                        <Form.Control type="number" min="0" onChange={(e) => (setQtdPecasAmostra(e.target.value))}
                            disabled={(gradeSegundaQualidadeDefeitosFinalizados.length === 0 && recebido !== 'nao')}
                        />
                    </Form>
                    <Form className={styles.formDadosanalise}>
                        <Form.Label>{Math.round((parseInt(qtdPecasAmostra) / parseInt(props.item.QtdeEntrada === 0 || props.item.QtdeEntrada === null ? 1 : props.item.QtdeEntrada)) * 100, 2)}% Revisado:</Form.Label>
                        <span className={styles.spanGray}></span>
                    </Form>
                </div>
            </article>

            {/* Footer */}
            <article className={styles.footer}>
                <Button //Só habilita quando tudo acima estiver preenchido
                    variant="primary"
                    onClick={() => (setShowModalAnalisaGrade(true))}
                    disabled={(gradeSegundaQualidadeDefeitosFinalizados.length === 0 && recebido !== 'nao') || !statusAnaliseSelecionada || !responsavelAnaliseSelecionado
                    }
                >
                    Prosseguir
                </Button>
            </article>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                fullscreen={true}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Direcionamento Segunda Qualidade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Accordion Identificação Produto */}
                    <Accordion className="mt-3">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Identificação do Produto</Accordion.Header>
                            <Accordion.Body>
                                <ProdutoInfo item={props.item}></ProdutoInfo>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <article className={styles.sectionBorder}>
                        <div className={styles.backgroundMiddleGray}>
                            <div className={styles.secondQaInfo}>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Qtde Peças Recebidas:</Form.Label>
                                    <span className={styles.spanBold}>{props.item.QtdeEntrada}</span>
                                </Form>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Qtde Peças Recebidas 2Q:</Form.Label>
                                    <span className={styles.spanBold}>{qtdPecasRecedidas2Q}</span>
                                </Form>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Qtde 2Q Direcionadas:</Form.Label>
                                    <span className={styles.spanBold}>{qtdPecasDirecionadas}</span>
                                </Form>
                                <Form className={styles.formSecondQaInfo}>
                                    <Form.Label>Faltam:</Form.Label>
                                    <span className={styles.spanBold}>{gradeSegundaQualidadeSelecionadaQtd}</span> {/* Esse número vai diminuindo a medida que vai incluindo peças no direcionamento */}
                                </Form>
                            </div>
                        </div>
                        <div className={styles.sectionContent} >
                            {/* <div>{itemGradeSegundaQualidadeSelecionado}</div> */}
                            <Table bordered responsive="sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className={styles.blockTitle}>TAMANHO</th>
                                        <th className={styles.blockTitle}>Q. RECEBIDA</th>
                                        <th className={styles.blockTitle}>Q. DIRECIONADA</th>
                                        <th className={styles.blockTitle}>Q. PENDENTE</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {gradeSegundaQualidade.map((item, index) => {
                                        return (
                                            <tr className={styles.tableLines} key={`rowGradeSegundaQualidade` + index}>
                                                <td>

                                                    <Form.Check
                                                        id={`itemGrade_` + item.Tamanho}
                                                        key={`itemGrade_` + item.Tamanho}
                                                        type={'radio'}
                                                        name={'gradeSegundaQualidade'}
                                                        value={item.Tamanho}
                                                        onChange={() => { handleItemSegundaGradeQualidade(item) }}
                                                    />

                                                </td>
                                                <td className={styles.tableLine}>{item.Tamanho}</td>
                                                <td className={styles.tableLine}>{item.Qtde}</td>
                                                <td className={styles.tableLine}>{item.QtdeDirecionada}</td>
                                                <td className={styles.tableLine}>{item.Qtde_Pendente}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div>
                                <div>
                                    <Form className={styles.formNameLine}>
                                        <Form.Label>Qtde Peças:</Form.Label>
                                        <Form.Control type="number" min="0" onChange={(e) => setQtdDePecasSegundaQualidade(e.target.value)} />
                                    </Form>
                                    <Form className={styles.formNameLine}>
                                        <Form.Label>Destino:</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            onChange={(e) => { handleDestino(e) }}
                                            options={destinos}
                                        />
                                    </Form>
                                </div>
                                <div>
                                    <Form className={styles.formNameLine} style={{ width: "100%", marginTop: "10px" }}>
                                        <Form.Label>
                                            Observação Recebimento Piloto
                                            <span className={styles.spanOpcional}>(Opcional)</span>
                                            :
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="2"
                                            className={styles.formControl}
                                            id="exampleFormControlTextarea1"
                                            onChange={(e) => (setObsRecebimentoPilotoSegundaQualidade(e.target.value))}>
                                        </Form.Control>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonRight} style={{ marginTop: "10px" }}>
                            <Button
                                style={{ width: "100%", margin: "0px 20px" }}
                                variant="success"
                                onClick={handleIncluiItemSegundaQualidade}
                            >
                                Incluir
                            </Button>
                        </div>
                    </article>

                    <article className={styles.sectionBorder}>
                        <Table responsive="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className={styles.blockTitle}>GRADE</th>
                                    <th className={styles.blockTitle}>TIPO ANÁLISE</th>
                                    <th className={styles.blockTitle}>DESTINO</th>
                                    <th className={styles.blockTitle}>QTDE PEÇAS</th>
                                    <th className={styles.blockTitle}>OBSERVAÇÕES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gradeSegundaQualidadeSelecionada.length > 0 ?
                                    gradeSegundaQualidadeSelecionada.map((item, index) => {
                                        return (
                                            <tr className={styles.tableLines} key={`rowGradeSegundaQualidadeSelecionada` + index}>
                                                <td>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => handleExcluiItemSegundaQualidade(item)}
                                                    >
                                                        <span className="fa-trash icons-uniao">
                                                            <FontAwesomeIcon
                                                                className="fa-trash-2x"
                                                                icon={faTrash}
                                                            />
                                                        </span>
                                                    </Button>
                                                </td>
                                                <td className={styles.tableLine}>{item.Tamanho}</td>
                                                <td className={styles.tableLine}>02 - SEGUNDA QUALIDADE</td>
                                                <td className={styles.tableLine}>{item.DestinoDesc}</td>
                                                <td className={styles.tableLine}>{item.QtdePecas}</td>
                                                <td className={styles.tableLine}>{item.Obs}</td>
                                            </tr>
                                        );
                                    })
                                    : ''
                                }
                            </tbody>
                        </Table>
                    </article>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button disabled={gradeSegundaQualidadeSelecionadaQtd > 0}
                        variant="primary"  // quando desabilitado
                        onClick={() => (setShowModalSegundaQualidade(true))}>
                        Incluir Defeitos Segunda Qualidade
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalSegundaQualidade}
                onHide={() => (setShowModalSegundaQualidade(false))}
                backdrop="static"
                fullscreen={true}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Identificação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MonitorRevisaoSegundaQualidadeDefeito qtdPecasDirecionadas={qtdPecasDirecionadas} gradeSegundaQualidadeSelecionada={gradeSegundaQualidadeSelecionada} gradeSegundaQualidade={gradeSegundaQualidade} produto={props.item} handleFinalizaRevisaoSegundaQualidade={handleFinalizaRevisaoSegundaQualidade}></MonitorRevisaoSegundaQualidadeDefeito>
                </Modal.Body>
            </Modal>

            <Modal show={showModalAnalisaGrade}
                onHide={() => (setShowModalAnalisaGrade(false))}
                backdrop="static"
                fullscreen={true}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Análise Grade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MonitorRevisaoAnaliseGrade handleCloseAll={handleCloseAll}
                        identificacao={{ beneficiamentoSelecionada, modelagemEstiloSelecionada, pecaLacradaSelecionada, corteSelecionada, graduacaoSelecionada, recebePilotoSelecionada, obsRecebimentoPiloto }}
                        statusAnaliseSelecionada={statusAnaliseSelecionada} responsavelAnaliseSelecionado={responsavelAnaliseSelecionado}
                        recebido={recebido} qtdPecasDirecionadas={qtdPecasDirecionadas} gradeSegundaQualidadeSelecionada={gradeSegundaQualidadeSelecionada} gradeSegundaQualidade={gradeSegundaQualidade} gradeSegundaQualidadeDefeitosFinalizados={gradeSegundaQualidadeDefeitosFinalizados} produto={props.item} qtdPecasAmostra={qtdPecasAmostra} qtdPecasRecedidas2Q={qtdPecasRecedidas2Q} handleFinalizaRevisaoSegundaQualidade={handleFinalizaRevisaoSegundaQualidade} precoIncorreto={precoIncorreto} setPrecoIncorreto={setPrecoIncorreto}></MonitorRevisaoAnaliseGrade>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default MonitorRevisaoIdentificacao;