import React from "react";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import styles from '../../styles/Revisao.module.scss';

const TableComponent = (props) => {

  return (
    <Table className={styles.list} responsive>
      <thead>
        <tr>
          <th></th>
          <th className={styles.blockTitle}>NOTA FISCAL</th>
          <th className={styles.blockTitle}>DATA RECEBIMENTO</th>
          <th className={styles.blockTitle}>DATA LIBERADO REVISAO</th>
          <th className={styles.blockTitle}>PRIORIDADE</th>
          <th className={styles.blockTitle}>RESPONSAVEL INICIO</th>
          <th className={styles.blockTitle}>ID USUARIO</th>
        </tr>
      </thead>
      <tbody>
        {
          props.rows.slice((50*(props.page-1)), (50*(props.page-1))+50).map((v, i) => {
            
            let recebimento = new Date(v.recebimento);
            recebimento = `${recebimento.getDate()}/${recebimento.getMonth()+1}/${recebimento.getFullYear()}`;
            return (
              <tr className="border-light"key={i}>
                <td className={styles.tableLines}>
                  <Button onClick={() => props.starthandler(i)} className="me-2 shadow-none">
                    Revisar
                  </Button>
                </td>
                <td className={styles.tableLines}>{v.nfentrada}</td>
                <td className={styles.tableLines}>{recebimento}</td>
                <td className={styles.tableLines}>{v.DataLiberadoRevisao}</td>
                <td className={styles.tableLines}>{v.prioridade}</td>
                <td className={styles.tableLines}>{v.ResponsavelInicioRevisao}</td>
                <td className={styles.tableLines}>{v.IDUsuarioInicioRevisao}</td>
              </tr>
            )
          })
        }
        
      </tbody>
    </Table>
  )
}

export default TableComponent;