import React from 'react';
import CookiesService from '../services/cookies';

const AccessControl = ({ children, areas, acesso }) => {
  const isDebugMode = process.env.REACT_APP_IS_DEBUG_MODE;
  const userData = CookiesService.getUserData();

  if (isDebugMode) {
    return <>{children}</>;
  }

  if (userData) {
    const userAreasObj = Object.getOwnPropertyNames(userData.areas);
    const userAcessObj = userData.acessos;

    if (userData.admin === 1) return <>{children}</>;
    if (areas && areas.some(area => userAreasObj.includes(area))) return <>{children}</>;
    if (acesso && userAcessObj[acesso] === true) return <>{children}</>;
  }

  return null;
};

export default AccessControl;
