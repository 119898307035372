import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import CookiesService from '../../../services/cookies';
import { toDatetime, toUTCDDMMYYYY } from '../../../utils/dateUtils';

const PriorizacaoOpsContext = createContext();
export const usePriorizacaoOps = () => useContext(PriorizacaoOpsContext);

export const PriorizacaoOpsProvider = ({ children }) => {

  const checklistColumns = [
    { name: 'RISCO REALIZADO', selector: row => row.RISCO_REALIZADO, atr: 'RISCO_REALIZADO' },
    { name: 'ORDEM PRODUÇÃO', selector: row => row.ORDEM_PRODUCAO, atr: 'ORDEM_PRODUCAO', filterable: true },
    { name: 'PRIORIZAÇÃO', selector: row => row.PRIORIZACAO, atr: 'PRIORIZACAO', sortable: true, filterable: true },
    { name: 'DATA ALTERAÇÃO COR', selector: row => toUTCDDMMYYYY(row.DATA_ALTERACAO_COR), atr: 'DATA_ALTERACAO_COR' },
    { name: 'MARCA', selector: row => row.MARCA, atr: 'MARCA' },
    { name: 'CANAL', selector: row => row.CANAL, atr: 'CANAL', sortable: true, filterable: true },
    { name: 'ORDEM SERVIÇO', selector: row => row.ORDEM_SERVICO, atr: 'ORDEM_SERVICO', filterable: true },
    { name: 'PRODUTO', selector: row => row.PRODUTO, atr: 'PRODUTO', filterable: true },
    { name: 'QUANTIDADE', selector: row => row.QTDE, atr: 'QTDE' },
    { name: 'LOCALIZAÇÃO', selector: row => row.LOCALIZACAO, atr: 'LOCALIZACAO' },
    { name: 'COLEÇÃO', selector: row => row.COLECAO, atr: 'COLECAO', sortable: true, filterable: true },
    { name: 'DATA ALTERAÇÃO PRÓXIMA COR', selector: row => toUTCDDMMYYYY(row.DATA_ALTERACAO_PROXIMA_COR), att: 'DATA_ALTERACAO_PROXIMA_COR' },
    { name: 'LANÇAMENTO', selector: row => row.LANCAMENTO, atr: 'LANCAMENTO', sortable: true, filterable: true },
    { name: 'QUANTIDADE TECIDOS', selector: row => row.QTDE_TECIDOS, atr: 'QTDE_TECIDOS' },
    { name: 'STATUS', selector: row => row.STATUS, atr: 'STATUS', sortable: true, filterable: true },
    { name: 'PLANTA CORTE', selector: row => row.PLANTACORTE, atr: 'PLANTACORTE' },
    { name: 'TIPO', selector: row => row.TIPOCORTE, atr: 'TIPOCORTE' },
    { name: 'LT', selector: row => row.LT, atr: 'LT', sortable: true, filterable: true },
  ];

  const [loading, setLoading] = useState(false);

  const userLogin = CookiesService.getUserLogin();
  const dataProcesso = toDatetime(new Date());

  const [mainData, setMainData] = useState([]);

  const [equipeCorteData, setEquipeCorteData] = useState([]);
  const [equipeCorteSelecionado, setEquipeCorteSelecionado] = useState({});

  const [modoCorteData, setModoCorteData] = useState([]);
  const [modoCorteSelecionado, setModoCorteSelecionado] = useState({});

  const [filtroSelecionado, setFiltroSelecionado] = useState({})
  const [filtrosAplicados, setFiltrosAplicados] = useState({});
  
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([])
  const [linhasCorteManual, setLinhasCorteManual] = useState([])
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const [dataPriorizacao, setDataPriorizacao] = useState('')

  const [showModal, setShowModal] = useState(false)
  const [showConfirmacao, setShowConfirmacao] = useState(false)
  const [showSelecaoFiltro, setShowSelecaoFiltro] = useState(false)
  const [showFiltroData, setShowFiltroData] = useState(false)

  const [selectedValues, setSelectedValues] = useState(filtrosAplicados[filtroSelecionado.atr] || []);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredValues, setFilteredValues] = useState([]);

  const handleCheckLinhas = ({ selectedRows }) => setLinhasSelecionadas(selectedRows);
  const handleClearRows = () => setToggleClearRows(!toggledClearRows);

  const applyFiltro = (filtro, valores) => {
    setFiltrosAplicados(prevFiltros => ({
      ...prevFiltros,
      [filtro]: valores
    }));
  };

  const clearFiltro = (filtro) => {
    setFiltrosAplicados(prevFiltros => {
      const { [filtro]: _, ...rest } = prevFiltros;
      return rest;
    });
  };

  const clearAllFiltros = () => {
    setFiltrosAplicados({});
  };

  const getFilteredData = () => {
    let filteredData = [...mainData];
    Object.keys(filtrosAplicados).forEach(key => {
      filteredData = filteredData.filter(row => filtrosAplicados[key].includes(row[key]));
    });
    return filteredData;
  };

  const handleShowModal = () => {
    if (linhasSelecionadas.length < 1) {
      toast.warn('Ao menos uma linha deverá ser selecionada.')
      return
    }
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setEquipeCorteSelecionado('')
    setModoCorteSelecionado('')
    setDataPriorizacao('')
  }

  const handleShowConfirmacao = () => {
    if (dataPriorizacao === ''){
      toast.warning('Data precisa ser preenchida.')
      return
    }
    if (Object.keys(equipeCorteSelecionado).length === 0) {
      toast.warning('Equipe de corte precisa ser selecionada.')
      return           
    }
    if (linhasCorteManual.length > 0)
      if (Object.keys(modoCorteSelecionado).length === 0) {
        toast.warning('Modo de corte precisa ser selecionado.')
        return           
      }

    setShowConfirmacao(true)
    setShowModal(false)
  }

  const handleCloseConfirmacao = () => {
    setShowConfirmacao(false)
    setShowModal(true)
  }

  const handleShowSelecaoFiltro = () => {
    setShowSelecaoFiltro(true)
  }

  const handleCloseSelecaoFiltro = () => {
    setShowSelecaoFiltro(false)
  }

  const handleShowFiltroData = (item) => {
    setFiltroSelecionado(item)
    setShowSelecaoFiltro(false)
    setShowFiltroData(true)
  }

  const handleCloseFiltroData = () => {
    setFiltroSelecionado({})
    setShowSelecaoFiltro(true)
    setShowFiltroData(false)
  }

  const handleResetAll = () => {
    handleClearRows();
    setLinhasSelecionadas([]);
    setDataPriorizacao('');
    setEquipeCorteSelecionado({});
    setModoCorteSelecionado({});
    setLinhasCorteManual([]);
    setShowConfirmacao(false);
    setFiltrosAplicados({});
    setFiltroSelecionado({});
  }

  const fetchMainData = async () => {
    setLoading(true);
    try {
      const res = await api.get('Corte/ControleEnfesto/PriorizacaoOps/get-rows');
      setMainData(res.data.resPrincipal);
      setEquipeCorteData(res.data.resEquipeCorte);
      setModoCorteData(res.data.resModoCorte);
    } catch (e) {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setLoading(false);
    }
  };

  const handleProcessar = async () => {
    const params = { 
      ops: linhasSelecionadas, 
      info: { 
        usuario: userLogin, 
        dataProcesso: dataProcesso, 
        equipe: equipeCorteSelecionado, 
        modo: modoCorteSelecionado,
        dataPriorizacao: dataPriorizacao
      } 
    }
    try {
        const res = await api.post('Corte/ControleEnfesto/PriorizacaoOps/prioriza-ops', { params });
        if (res.status === 200) {
            toast.success('Dados inseridos com sucesso!');
            handleResetAll();
            fetchMainData();
        } else {
            toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
    } catch (e) {
        toast.error('Ocorreu um erro ao inserir os dados na tabela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    }
  };

    useEffect(() => {
        fetchMainData();
    }, []);

    useEffect(() => {
      const plantaExiste = linhasSelecionadas.filter(linha => linha.PLANTACORTE !== null)
      const corteManual = plantaExiste.filter(planta => planta.PLANTACORTE.match(/Arte.*/))
      setLinhasCorteManual(corteManual)
  }, [linhasSelecionadas]);

  return (
    <PriorizacaoOpsContext.Provider
      value={{ selectedValues, setSelectedValues, searchTerm, setSearchTerm, filteredValues, setFilteredValues, filtrosAplicados, applyFiltro, clearFiltro, clearAllFiltros, getFilteredData, filtroSelecionado, showFiltroData, handleShowFiltroData, handleCloseFiltroData, checklistColumns, handleShowSelecaoFiltro, handleCloseSelecaoFiltro, showSelecaoFiltro, handleProcessar, linhasCorteManual, showConfirmacao, handleShowConfirmacao, handleCloseConfirmacao, handleShowModal, handleCloseModal, showModal, loading, mainData, handleCheckLinhas, linhasSelecionadas, toggledClearRows, dataPriorizacao, setDataPriorizacao, equipeCorteData, equipeCorteSelecionado, setEquipeCorteSelecionado, modoCorteData, modoCorteSelecionado, setModoCorteSelecionado }}
    >
      {children}
    </PriorizacaoOpsContext.Provider>
  )
}