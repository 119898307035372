import React from "react";
import styles from '../../styles/AgendamentoRevisao.module.scss';

function InfoBlockCell({ label, value, className }) {
  return (
    <div className={className}>
      <span>{label}:</span>
      <span className={styles.fontBlue}>{value}</span>
    </div>
  );
}

function OPInfoColumn(props) {
  const { columns } = props;

  const columnComponents = columns.map(({ label, value }) => (
    <InfoBlockCell
      key={label}
      label={label}
      value={value}
      className={styles.infoBlockColumm}
    />
  ));
  return <div className={styles.infoBlock}>{columnComponents}</div>;
}

export default OPInfoColumn;