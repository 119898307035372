import { MPRapportEnviaCadProvider } from "../../contexts/MPRapportEnvioCadContext";
import RapportEnvioCadMain from "../../components/MP/RapportEnvioCad/RapportEnvioCadMain";

const RapportEnvioCad = () => {
    

    return (
        <MPRapportEnviaCadProvider>
            <RapportEnvioCadMain />
        </MPRapportEnviaCadProvider>
    );

}
export default RapportEnvioCad;