import React, { useState } from "react";
import { Button, Table, Form, Modal } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { api }  from "../../../services/api";
import Carregando from "../../../components/Carregando"
import ImprimirEtiquetasV2 from "../../ImprimirEtiquetaV2";
import CookiesService from "../../../services/cookies.js"

import styles from "../../../styles/AdmVigenciaExclusao.module.scss";

function VigenciaExclusao() {
    const [codigo, setCodigo] = useState("");
    const [material, setMaterial] = useState("");
    const [corMaterial, setCorMaterial] = useState("");
    const [itens, setItens] = useState([]);
    const [itensParaImprimir, setItensParaImprimir] = useState([]);
    const itensProcessados = [];
    const [carregando, setCarregando] = useState(false);
    const [acao, setAcao] = useState([
        {value:1, label:'Fechar Vigência'},
        {value:2, label:'Reabrir Vigência'},
        {value:3, label:'Excluir Amostra'}
    ])
    const [acaoSelecionada, setAcaoSelecionada]= useState(0);    
    const [showImprimir, setShowImprimir] = useState(false);
    const [idImprimir, setIdImprimir] = useState([]);
    const [showModal, setShowModal] = useState(false);

    async function handleForm() {
        const params = {
            Codigo: codigo, 
            Material: material, 
            CorMaterial: corMaterial,
            Vigencia: 1
        };

        setShowModal(false);
        
        if(acaoSelecionada < 1){
            toast.error('Selecione uma ação.');            
            return false;
        }

        if(codigo==='' && material===''){
            toast.error('Favor digital um código ou material');            
            return false;
        }
        
        setCarregando(true);
        const lista = await api.get(
            "CatalogoADM/ADMVigenciaList",
            { params }
        );
        if (lista.data.length<1){
            toast.warning('Nenhum registro encontrado'); 
        }

        setItens(lista.data);
        setCarregando(false);
    }

    function handleDelete(item) {
        setItens(itens.filter(el => el !== item));
    }

    async function btnProcessar() {
        setShowModal(false);

        if(itens.length < 1){
            toast.error('Selecione pelo menos 1 item para processar');
            setTimeout(function () {}, 2000);
            return false;
        }

        if(acaoSelecionada < 1){
            toast.error('Selecione uma ação.');
            setTimeout(function () {}, 2000);
            return false;
        }

        let listIdsImprimir =[]

        for (const itemForCreate of itens) {
            let itemCadastro = { 
                ACAO: acaoSelecionada,
                ID_CADASTRO: itemForCreate.IDCADASTRO,
                ID_USUARIO: CookiesService.getUserLogin()
            }

            let result = await api.post(`catalogoADM/ProcessarVigenciaExclusao`, itemCadastro);
            
            let status = "ENCERRADO COM SUCESSO";
            
            if(acaoSelecionada==='2'){            
                status="REABERTO COM SUCESSO "
            }

            if(acaoSelecionada==='3'){
                status="EXCLUÍDO COM SUCESSO "
            }

            itensProcessados.push({...itemForCreate,status: status, texto:status });                  
        }

        setItensParaImprimir(itensParaImprimir.concat(itensProcessados));    
        setIdImprimir(idImprimir.concat(listIdsImprimir));
        setItens([]);
      }

    return (
        <>
            <article>
                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Ação</Form.Label>
                            <Form.Select
                            onChange={(e) => {setAcaoSelecionada(e.target.value)}}
                            defaultValue={0}
                        >
                            <option 
                                value={0}
                                disabled 
                                readOnly 
                            >
                                Selecione
                            </option>
                            {acao.map((local) => {
                                return (
                                    <option key={`chkAcaa_` + local.value} value={local.value}>
                                        {local.label}
                                    </option>
                                );
                            })}
                        </Form.Select>                            
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Código</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Codigo"
                            value={codigo}
                            onChange={(event) => setCodigo(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Material</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Material"
                            value={material}
                            onChange={(event) => setMaterial(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Cor Material</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Cor Material"
                            value={corMaterial}
                            onChange={(event) => setCorMaterial(event.target.value)}
                        />
                    </Form.Group>
                    <Button 
                        className={styles.formGroup}
                        variant="primary"
                        type="button" 
                        onClick={()=> handleForm()}
                    >
                        Buscar
                    </Button>
                    
                </Form>
            </article>

            <article className={styles.articleGray}>
                {carregando ?
                    <Carregando></Carregando>
                
                : 
                    <Table className={styles.colNoMargin} responsive>
                        <thead>
                            <tr>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}>ID</th>
                            <th className={styles.blockTitle}>LOCALIZAÇÃO</th>
                            <th className={styles.blockTitle}>ARARA</th>                            
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>COR</th>
                            <th className={styles.blockTitle}>COD FORN.</th>
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>COLECAO</th>
                            <th className={styles.blockTitle}>TIPO MATERIAL</th>
                            <th className={styles.blockTitle}>ANDAR</th>  
                            <th className={styles.blockTitle}>LADO</th>
                            <th className={styles.blockTitle}>POSIÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {itens.length>0 && itens.map((item,index)=>{
                                return (
                                    <tr key={index}>
                                    <td className={styles.tableLines}>
                                        <Button title="Remover da lista"
                                        className="btn btn-danger"
                                        onClick={() => handleDelete(item)}
                                        >
                                            <i>
                                                <MaterialIcon
                                                icon="delete" 
                                                size={25} 
                                                color='#BB271A'
                                                />
                                            </i>
                                        </Button>
                                    </td>
                                    <td className={styles.tableLines}>{item.IDCADASTRO}</td>
                                    <td className={styles.tableLines}>{item.IDLOCALIZACAO}</td>
                                    <td className={styles.tableLines}>{item.idArara}</td>
                                    <td className={styles.tableLines}>{item.Material}</td>
                                    <td className={styles.tableLines}>{item.CorMaterial}</td>
                                    <td className={styles.tableLines}>{item.CodClifor}</td>
                                    <td className={styles.tableLines}>{item.NOME_CLIFOR}</td>
                                    <td className={styles.tableLines}>{item.COLECAO}</td>
                                    <td className={styles.tableLines}>{item.tipoMaterial}</td>
                                    <td className={styles.tableLines}>{item.andar}</td>
                                    <td className={styles.tableLines}>{item.lado}</td>                                
                                    <td className={styles.tableLines}>{item.posicao}</td>                                
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                }
                   
            </article>
            
            <div className="m-3">
                <h5>Status do Processamento</h5>
            </div>

            <article className={styles.articleWhite}>
                <Table className={styles.colNoMargin} responsive>
                    <thead>
                    <tr>
                        <th className={styles.blockTitle}>STATUS</th>
                        <th className={styles.blockTitle}>ID</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {itensParaImprimir.length>0 && itensParaImprimir.map((item,index)=>{
                            return (
                                <tr key={index}>
                                <td className={styles.tableLines}>{item.status}</td>
                                <td className={styles.tableLines}>{item.IDCADASTRO}</td>
                                                            
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </article>
            <Button disabled={itens.length<1}
                className="m-2" 
                variant="primary"
                onClick={()=>setShowModal(true)}
            >
                Processar
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                />
            </Button> 
            {showImprimir===true ? <ImprimirEtiquetasV2 etiquetas={itensParaImprimir}/> : ''}
            

            <Modal 
                show={showModal && itens.length>0}
            >
                <Modal.Header>
                <Modal.Title>
                    Atenção!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Essa ação irá alterar a vigência dos materiais listados. Deseja continuar?!
                </Modal.Body>
                <Modal.Footer>
                <Button 
                    variant="secondary" 
                    onClick={()=>setShowModal(false)} 
                >
                    Cancelar
                </Button>
                <Button 
                    variant="primary" 
                    onClick={()=>btnProcessar()}
                >
                    Gravar
                </Button>
                </Modal.Footer>
            </Modal>
        </> 
    );
}

export default VigenciaExclusao;
 