import React from "react";
import ConsultarCatalogo from "../components/Adm/ConsultarCatalogo/index";

import styles from '../styles/AdmConsultarCatalogo.module.scss';

const AdmConsultarCatalogo = () => {
    return (
        <div className="p-2">
          <h3>Catálogo Amostras - ADM - Consultar Catálogo</h3>
          <ConsultarCatalogo />
        </div>
    );
};

export default AdmConsultarCatalogo;