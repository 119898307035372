import React, { useMemo } from 'react';
import { Modal, Button, Form, FloatingLabel, ListGroup, ListGroupItem } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/SelecaoRiscos.module.scss'; 
import MaterialIcon from 'material-icons-react';

const FilterUnique = ({ show, close, data, filtro, searchText, setSearchText, 
    handleCheckboxChange, selectedValuesFiltro, handleClearCurrentFilter }) => {

  const uniqueFilterValues = useMemo(() => {
    const values = data.map(item => item[filtro.att]);
    return [...new Set(values)];
  }, [data, filtro.att]);

  const filteredValues = useMemo(() => {
    const filtered = uniqueFilterValues.filter(value => 
      value && value.toString().toLowerCase().includes(searchText.toLowerCase())
    );
    return filtered;
  }, [uniqueFilterValues, searchText]);

  return (
    <>
        <Modal
          show={show}
          onHide={close}
          backdrop="static"
          keyboard={false}
          centered
        >
            <Modal.Header>
                <Modal.Title>Filtro</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', flexDirection: 'column', margin: '0 10px' }}>
                <Form>
                    <Form.Group style={{display: 'flex', alignItems: 'center', justifyItems:'center'}}>
                        <MaterialIcon
                            icon="search"
                            size={30}
                            color='black'
                        />
                        <FloatingLabel
                            controlId="floatingInput"
                            label={filtro.name}
                            className='m-3'
                            style={{width: '100%'}}
                        >
                            <Form.Control 
                                type='text'
                                value={searchText || ''}
                                onChange={e => setSearchText(e.target.value)}
                            />
                        </FloatingLabel>
                        <Button
                            variant="outline-warning"
                            className={styles.modalBtn}
                            style={{width: '200px'}}
                            onClick={() => handleClearCurrentFilter(filtro.att)}
                        >
                            Limpar Filtro
                        </Button>
                    </Form.Group>
                    <ListGroup>
                        {filteredValues.map((item, index) => (
                            <ListGroupItem key={index}>
                                <Form.Check
                                    type='checkbox'
                                    label={item !== '' ? item : '(vazio)'}
                                    value={item}
                                    className='mx-3'
                                    checked={selectedValuesFiltro[filtro.att] ? selectedValuesFiltro[filtro.att].includes(item) : false}
                                    onChange={() => handleCheckboxChange(item, filtro.att)}
                                />
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={close}
                >
                    Voltar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  );
};

export default FilterUnique;
