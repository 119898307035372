import React from "react";
import { Button, Modal } from 'react-bootstrap';
import styles from '../../../styles/MP/AnaliseRapportReprovadoCad.module.scss';

const ModalConfirmacaoImpacto = ({ showConfirmacaoImpacto, 
                                   handleProcessImpactoConsumo, 
                                   handleCloseConfirmacao, 
                                   impactoConsumo }) => {

    return (
        <Modal
            show={showConfirmacaoImpacto}
            onHide={handleCloseConfirmacao}
            backdrop="static"
            keyboard={false}
            centered
        >
        <Modal.Header>
            <Modal.Title>Tem certeza?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <span>O pedido será marcado como 
                <strong>
                    { impactoConsumo === 1 ? ' localização de encaixe modificada' 
                                           : ' localização de encaixe mantida' }
                </strong>.
            </span>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
        <Button
                    variant="success"
                    className={styles.modalBtn}
                    onClick={() => handleProcessImpactoConsumo(impactoConsumo)}
                >
                    Sim
                </Button>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseConfirmacao}
                >
                    Voltar
                </Button>
        </Modal.Footer>
      </Modal>
    ) 
}

export default ModalConfirmacaoImpacto;
