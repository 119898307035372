import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './PickingMatereais.module.scss'

const ModalEscolha = (params) => {
	const { processarPesagem, showModal, setShowModal } = params;

	return (
		<>
			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				backdrop='static'
				keyboard={false}
				centered
				size={'sm'}
			>
				<Modal.Header closeButton>Processar</Modal.Header>
				<Modal.Body>
					Deseja continuar editando a mesma Ordem Serviço?
				</Modal.Body>
				<Modal.Footer className={styles.modal_footer}>
					<Button
						variant={'danger'}
						onClick={() => processarPesagem(false)}
						className={styles.btn_modal}
					>
						Não
					</Button>
					<Button
						variant={'success'}
						onClick={() => processarPesagem(true)}
						className={styles.btn_modal}
					>
						Sim
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalEscolha;
