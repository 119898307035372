import { FGTesteDeBaseProvider } from "../../../contexts/FarmGlobal/TesteDeBaseContext";
import TesteDeBaseAnalisarMain from "../../../components/FarmGlobal/TesteDeBase/Laboratorio/TesteBaseAnalisarMain";

const FGTesteDeBase = () => {    

    return (
        <FGTesteDeBaseProvider>
            <TesteDeBaseAnalisarMain />
        </FGTesteDeBaseProvider>
    );

}
export default FGTesteDeBase;