import React from "react";
import { Button } from "react-bootstrap";
import { api }  from "../services/api"

function ImprimirEtiquetasV2(params) {
  let listIdCadastro = "";
  
  for (let i = 0; i < params.etiquetas.length; i++) {
    let idCadastro =  params.etiquetas[i].IDCADASTRO ? params.etiquetas[i].IDCADASTRO : params.etiquetas[i].ID_CADASTRO;
    //listIdCadastro = listIdCadastro + idCadastro.replace(/\s+/g, '') + ","
    listIdCadastro = listIdCadastro + idCadastro + ","
  }
  
  
  async function downloadTxtFile() {
    const  listaAdmEtiquetas = await api.get(
        "CatalogoADM/ADMetiqueta",
        { params: {"listIdCadastro": listIdCadastro} }
    );
    
    if (listaAdmEtiquetas.data.length === 0) return;

    let etiquetas = listaAdmEtiquetas.data;

    for (let i = 0; i < etiquetas.length; i++) {
      let contentFile = `L
SK
PG
D11
H20
N
132400103200020${etiquetas[i]["idLocalizacao"]}
192200103400230Cad:
192200103400300${etiquetas[i]["datacadastro"]}
192200103200300${etiquetas[i]["idArara"]}
192200103200230Arara:
192200103000300${etiquetas[i]["andar"]}
192200103000230Andar:
192200102750300${etiquetas[i]["lado"]}
192200102750230Lado:
1E6205002500015${etiquetas[i]["idLocalizacao"]},
192200201700020Local: ${etiquetas[i]["SALA_AMOSTRA"]}
192200202300020Material/Cor: ${etiquetas[i]["material"]}/${etiquetas[i]["corMaterial"]}
192200102100020Fornecedor: ${etiquetas[i]["NOME_CLIFOR"]}
192200101400020Material:
192200201400100${etiquetas[i]["material"]}
192200101200020Desc:${etiquetas[i]["DESC_MATERIAL"]}
192200101000100${etiquetas[i]["corMaterial"]}${etiquetas[i]["DESC_COR_MATERIAL"]}
192200101000020Cor:
192200100800020Colecao: ${etiquetas[i]["colecao"]}
1X2200100500010B380120002002
1X2200100100010L380030002002
192200100600020Usuario: ${etiquetas[i]["idusuario"]}
192200100150020${params.etiquetas[i]["texto"] ? params.etiquetas[i]["texto"] : ''}
Q0001
E
`;

      let idCadastro = etiquetas[i]["idCadastro"];

      const element = document.createElement("a");
      const file = new Blob([contentFile], {
        type: "text/plain",
        endings: "native",
      });
      element.href = URL.createObjectURL(file);
      element.download = `${idCadastro.replaceAll(".", "")}-aflex-qld.txt`;
      document.body.appendChild(element);
      element.click();
    }
  };

  return <Button onClick={downloadTxtFile}>Imprimir Etiquetas</Button>;
}

export default ImprimirEtiquetasV2;
