import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/SelecaoRiscos.module.scss'; 
import FilterUnique from './FilterUnique';

const Filter = ({ show, setShow, data, handleCheckboxChange, selectedValuesFiltro, handleClearCurrentFilter }) => {
  const [showFilteredUnique, setShowFilteredUnique] = useState(false);
  const [filtroSelecionado, setFiltroSelecionado] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredSearch, setFilteredSearch] = useState([]);

  const handleShowFilteredUnique = (filtro) => {
    setFiltroSelecionado(filtro);
    setFilteredSearch(data);
    setShowFilteredUnique(true);
    setShow(false);
  };

  const handleCloseFilteredUnique = () => {
    setShow(true);
    setShowFilteredUnique(false);
    setFilteredSearch([]);
    setSearchText('');
    setFiltroSelecionado('');
  };

  const filterColumns = [
    { name: 'Canal', att: 'CANAL' },
    { name: 'Coleção', att: 'COLECAO' },
    { name: 'Lançamento', att: 'LANCAMENTO' },
    { name: 'LT', att: 'LT' },
    { name: 'Ordem Producao', att: 'ORDEM_PRODUCAO' },
    { name: 'Ordem Serviço', att: 'ORDEM_SERVICO' },
    { name: 'Produto', att: 'PRODUTO' },
    { name: 'Priorização', att: 'PRIORIZACAO' },
    { name: 'Status', att: 'STATUS' },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Filtro</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {filterColumns.map((item, index) => (
            <Button
              key={index}
              variant="outline-primary"
              style={{ width: '150px', margin: '5px 0' }}
              onClick={() => handleShowFilteredUnique(item)}
            >
              {item.name}
            </Button>
          ))}
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            className={styles.modalBtn}
            onClick={() => setShow(false)}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <FilterUnique
        show={showFilteredUnique}
        close={handleCloseFilteredUnique}
        data={data}
        filtro={filtroSelecionado}
        filteredSearch={filteredSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        setFilteredSearch={setFilteredSearch}
        handleClearCurrentFilter={handleClearCurrentFilter}
        handleCheckboxChange={handleCheckboxChange}
        selectedValuesFiltro={selectedValuesFiltro}
      />
    </>
  );
};

export default Filter;
