import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal } from 'react-bootstrap';
import styles from '../../../styles/PA/ADM/EntradaFiscal.module.scss';
import { toDatetime, toUTCDatetime, toUTCDDMMYYYY, toYYYYMMDD } from '../../../utils/dateUtils';
import { api } from '../../../services/api';
import Carregando from '../../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import CookiesService from "../../../services/cookies";

const PAAdmLiberarRiscoSacado = () => {
	const [acaoSelecionada, setAcaoSelecionada] = useState({ label: 'Inclusão', value: 'INCLUSAO' });
	const [buscaEscolhida, setBuscaEscolhida] = useState('ChaveNF');
	const [showModal, setShowModal] = useState(false);
	const [filterObjInclusion, setFilterObjInclusion] = useState({});
	const [filterErrs, setFilterErrs] = useState({});
	const [filterObjAlteration, setFilterObjAlteration] = useState({});
	const [mainAlterationData, setMainAlterationData] = useState([]);
	const [mainInsertData, setMainInsertData] = useState({ EntradaFiscal: [], EntradaEstoque: [], Grade: [] });
	const [loading, setLoading] = useState(false);
	const [selectedEF, setSelectedEF] = useState(null);
	const [selectedEE, setSelectedEE] = useState(null);
	const [selectedEERomaneio, setSelectedEERomaneio] = useState(null);
	const [activeObj, setActiveObj] = useState(null);
	const [showProcessingInclusion, setShowProcessingInclusion] = useState({ value: false, message: null });
	const userLogin = CookiesService.getUserLogin();

	const selecionarAcao = [
		{ label: 'Inclusão', value: 'INCLUSAO' },
		{ label: 'Exclusão', value: 'EXCLUSAO' },
		{ label: 'Duplicidade', value: 'DUPLICIDADE' },
		{ label: 'Desabilitar', value: 'DESABILITAR' },
		{ label: 'Habilitar', value: 'HABILITAR' }
	]

	const handleClose = () => setShowModal(false);
	const handleShowModal = (el) => {
		setShowModal(true);
		setActiveObj(el);
	}

	const handleSearchInclusion = async () => {
		const errs = handleInclusionFilterValidation();
		if (Object.keys(errs).length > 0) return setFilterErrs(errs);
		setLoading(true);

		let params = {}
		switch (buscaEscolhida) {
			case 'ChaveNF':
				params = { ChaveNFE: filterObjInclusion.ChaveNFE }
				break;
			// case 'NotaFiscal':
			// 	params = {
			// 		NFEntrada: filterObjInclusion.NFEntrada,
			// 		SerieNF: filterObjInclusion.SerieNF,
			// 		Origem: filterObjInclusion.Origem
			// 	}
			// 	break;
			case 'Produto':
				params = {
					Produto: filterObjInclusion.Produto,
					CorProduto: filterObjInclusion.CorProduto,
					DataInicio: toUTCDatetime(filterObjInclusion.DataInicio),
					DataFim: toUTCDatetime(filterObjInclusion.DataFim)
				}
				break;
			default:
				setLoading(false);
				return toast.warn('Atenção! Selecione um tipo de busca');
		}

		const insertData = { EntradaFiscal: [], EntradaEstoque: [], Grade: [] }

		try {
			const resEntradaFiscal = await api.get(`/PAAdmControle/EF_GetIDEntradaFiscal`, { params });
			const resEntradaEstoque = await api.get(`/PAAdmControle/EF_GetIDEntradaEstoque`, { params });
			const resGrade = await api.get(`/PAAdmControle/EF_GetIDGrade`, { params });

			insertData.EntradaFiscal = resEntradaFiscal.data;
			insertData.EntradaEstoque = resEntradaEstoque.data;
			insertData.Grade = resGrade.data;

			if (insertData.EntradaFiscal.length === 0 && insertData.EntradaEstoque.length === 0 && insertData.Grade.length === 0) {
				setLoading(false);
				return toast.warn('Atenção! Nenhum processo foi encontrado para esses parâmetros de busca');
			}

			setSelectedEE(null);
			setSelectedEERomaneio(null);
			setSelectedEF(null);
			setMainInsertData(insertData);
		} catch (e) {
			console.error(e);
			toast.error('Ocorreu um erro durante a busca, por favor cheque sua conexão com a internet ou entre contato com o suporte');
		}
		setLoading(false);
	}

	const handleInclusionFilterValidation = () => {
		const { ChaveNFE, /* NFEntrada, SerieNF, Origem,*/ Produto, CorProduto, DataInicio, DataFim } = filterObjInclusion;
		const errs = {}

		switch (buscaEscolhida) {
			case 'ChaveNF':
				if (!ChaveNFE || ChaveNFE === '') errs.ChaveNFE = 'Insira uma chave';
				break;
			// case 'NotaFiscal':
			// 	if (!NFEntrada || NFEntrada === '') errs.NFEntrada = 'Insira a nota fiscal';
			// 	if (!SerieNF || SerieNF === '') errs.SerieNF = 'Insira a série da nota fiscal';
			// 	if (!Origem || Origem === '') errs.Origem = 'Insira um fornecedor/origem';
			// 	break;
			case 'Produto':
				if (!Produto || Produto === '') errs.Produto = 'Insira um produto';
				if (!CorProduto || CorProduto === '') errs.CorProduto = 'Insira uma cor';
				if (!DataInicio || DataInicio === '') errs.DataInicio = 'Insira uma data de início';
				if (!DataFim || DataFim === '') errs.DataFim = 'Insira uma data de fim';
				break;
			case null || undefined:
				return;
		}

		return errs;
	}

	const handleProcessEF = async () => {
		if (selectedEF === null || selectedEE === null) return toast.warn('Para processar é necessário escolher uma linha de Identificação Entrada Fiscal e uma de Identificação Entrada Estoque');
		const dataEF = mainInsertData.EntradaFiscal[selectedEF];
		const dataEE = mainInsertData.EntradaEstoque[selectedEE];

		const params = {
			ChaveNFE: dataEF.ChaveNFE.trim(),
			RomaneioProduto: dataEE.Romaneio.trim(),
			FilialRevisao: dataEE.FilialRevisao.trim(),
			Produto: dataEE.Produto.trim(),
			CorProduto: dataEE.CorProduto.trim(),
			QtdeEntrada: dataEE.QtdeEntrada,

			IDUsuario: userLogin,
			DataRegistro: toDatetime(new Date())
		}

		try {
			const res = await api.post('PAAdmControle/EF_ProcessarEntradaFiscal', params);
			if (res.status === 200 && res.data[0].mensagem === 'PROCESSO CONCLUIDO') {
				handleSearchInclusion();

				setSelectedEE(null);
				setSelectedEERomaneio(null);
				setSelectedEF(null);
				return setShowProcessingInclusion({ value: true, message: 'Os dados foram atualizados com sucesso' });
			} else if (res.status === 200 && res.data[0].mensagem !== 'PROCESSO CONCLUIDO') {
				return setShowProcessingInclusion({ value: true, message: res.data[0].mensagem });
			}
		} catch (e) {
			console.error(e);
			setShowProcessingInclusion({ value: false, message: 'Parece que ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte' });
		}
	}

	const handleSearchAlteration = async () => {
		setLoading(true);
		const params = {
			NFEntrada: filterObjAlteration.NFEntrada,
			Produto: filterObjAlteration.Produto,
			CorProduto: filterObjAlteration.CorProduto,
			OrdemServico: filterObjAlteration.OrdemServico,
			OrdemProducao: filterObjAlteration.OrdemProducao
		}
		try {
			const res = await api.get(`/PAAdmControle/EF_GetAlterarMainData`, { params })
			setMainAlterationData(res.data);
		} catch (e) {
			toast.error('Ocorreu um erro ao se preencher a lista de processos, por favor cheque sua conexão com a internet ou entre contato com o suporte');
			console.error(e);
		}
		setLoading(false);
	}

	const handleFilterValidation = () => {
		const keys = Object.keys(filterObjAlteration);
		for (let i = 0; i < keys.length; i++) {
			if (filterObjAlteration[keys[i]] && filterObjAlteration[keys[i]] !== '') return true;
		}
		return false;
	}

	const handleProcessAlteracao = async () => {
		const params = {
			NFEntrada: activeObj.NFEntrada,
			SerieNF: activeObj.SerieNF,
			Origem: activeObj.Origem,
			Produto: activeObj.Produto,
			CorProduto: activeObj.CorProduto,
			QtdeEntrada: activeObj.QtdeEntrada,
			FilialRevisao: activeObj.FilialRevisao,
			IDUsuario: userLogin,
			// IDUsuario: userLogin || 'TESTE.TESTE',
			Acao: acaoSelecionada.value,
			DataRegistro: toDatetime(new Date()),
			IDAutorizador: userLogin
			// IDAutorizador: userLogin || 'TESTE.TESTE'
		}

		await api.post('PAAdmControle/EF_ProcessarAlteracao', params)
			.then(res => {
				if (res.status === 200 && res.data[0].mensagem === 'PROCESSO CONCLUIDO') {
					handleSearchAlteration();
					setActiveObj({});
					handleClose();
					toast.success('Os dados foram atualizados com sucesso!');
				} else if (res.status === 200 && res.data[0].mensagem !== 'PROCESSO CONCLUIDO') {
					toast.error('Ocorreu um erro, cheque sua conexão de internet ou entre em contato com o suporte.');
				}
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao atualizar os dados, por favor entre em contato com o suporte.');
				console.error(`Erro na requisição '/EF_ProcessarAlteracao' - ${e}`);
			});
	}

	const handleValueMask = (field, value, type) => {
		if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
		if (type === 'INC') setFilterObjInclusion({ ...filterObjInclusion, [field]: value });
		if (type === 'ALT') setFilterObjAlteration({ ...filterObjAlteration, [field]: value });
	}

	const clearErrs = (str) => {
		setFilterErrs({ ...filterErrs, [str]: null });
	}

	useEffect(() => {
		// if (selectedEE !== null) {
		// 	mainInsertData.EntradaEstoque[selectedEE]?.Romaneio
		// }
	}, [selectedEERomaneio]);

	return (
		<div>
			<h3 style={{ 'margin': '20px' }}>ADM PA - Entrada Fiscal</h3>

			<article className={styles.headerWrapper}>

				<article className={styles.actionWrapper}>
					<Form className={styles.form}>
						<Form.Group className={styles.formGroup}>
							<Form.Label className={styles.formLabel}>Ação</Form.Label>
							<Select
								closeMenuOnSelect={true}
								backspaceRemovesValue={true}
								placeholder={'Selecionar'}
								value={acaoSelecionada}
								options={selecionarAcao}
								onChange={(e) => setAcaoSelecionada(e)}
							/>
						</Form.Group>
					</Form>
					<Form
						className={styles.formCheck}
						style={{ display: acaoSelecionada?.value === 'INCLUSAO' ? 'inline' : 'none' }}
					>
						<Form.Label className={styles.formLabel}>Buscar por:</Form.Label>
						<Form.Group className={styles.formGroupCheck}>
							<Form.Check
								className={styles.formCheck}
								type={'radio'}
								label={'Chave NF'}
								onChange={() => { setBuscaEscolhida('ChaveNF'); setFilterObjInclusion({}) }}
								checked={buscaEscolhida === 'ChaveNF'}
							/>
							{/* <Form.Check
								className={styles.formCheck}
								type={'radio'}
								label={'Nota Fiscal | Série NF | Fornecedor'}
								onChange={() => { setBuscaEscolhida('NotaFiscal'); setFilterObjInclusion({})}}
								checked={buscaEscolhida === 'NotaFiscal'}
							/> */}
							<Form.Check
								className={styles.formCheck}
								type={'radio'}
								label={'Produto | Cor Produto | Data Inicio | Data Fim'}
								onChange={() => { setBuscaEscolhida('Produto'); setFilterObjInclusion({}) }}
								checked={buscaEscolhida === 'Produto'}
							/>
						</Form.Group>
					</Form>
				</article>

				<article className={styles.formWrapper}>
					{/* ACAO === 'INCLUSAO' */}
					<div
						style={{ display: buscaEscolhida === 'ChaveNF' && acaoSelecionada?.value === 'INCLUSAO' ? 'inline' : 'none' }}
					>
						<Form className={styles.form}>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Chave NF</Form.Label>
								<Form.Control
									type="text"
									placeholder="Chave NF"
									value={filterObjInclusion.ChaveNFE || ''}
									onChange={e => handleValueMask('ChaveNFE', e.target.value, 'INC')}
									onFocus={() => clearErrs('ChaveNFE')}
									isInvalid={filterErrs.ChaveNFE}
								/>
							</Form.Group>
							<Button
								className={styles.formGroup}
								variant="primary"
								type="button"
								onClick={handleSearchInclusion}
							>
								Buscar
							</Button>
						</Form>
					</div>

					{/* <div
						style={{ display: buscaEscolhida === 'NotaFiscal' && acaoSelecionada?.value === 'INCLUSAO' ? 'inline' : 'none' }}
					>
						<Form className={styles.form}>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
								<Form.Control
									type="text"
									placeholder="Nota Fiscal"
									value={filterObjInclusion.NFEntrada || ''}
									onChange={e => handleValueMask('NFEntrada', e.target.value, 'INC')}
									onFocus={() => clearErrs('NFEntrada')}
									isInvalid={filterErrs.NFEntrada}
								/>
							</Form.Group>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Série NF</Form.Label>
								<Form.Control
									type="text"
									placeholder="Série NF"
									value={filterObjInclusion.SerieNF || ''}
									onChange={e => handleValueMask('SerieNF', e.target.value, 'INC')}
									onFocus={() => clearErrs('SerieNF')}
									isInvalid={filterErrs.SerieNF}
								/>
							</Form.Group>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Fornecedor</Form.Label>
								<Form.Control
									type="text"
									placeholder="Fornecedor"
									value={filterObjInclusion.Origem || ''}
									onChange={e => setFilterObjInclusion({ ...filterObjInclusion, Origem: e.target.value })}
									onFocus={() => clearErrs('Origem')}
									isInvalid={filterErrs.Origem}
								/>
							</Form.Group>
							<Button
								className={styles.formGroup}
								variant="primary"
								type="button"
								onClick={handleSearchInclusion}
							>
								Buscar
							</Button>
						</Form>
					</div> */}

					<div
						style={{ display: buscaEscolhida === 'Produto' && acaoSelecionada?.value === 'INCLUSAO' ? 'inline' : 'none' }}
					>
						<Form className={styles.form}>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Produto</Form.Label>
								<Form.Control
									type="text"
									placeholder="Produto"
									value={filterObjInclusion.Produto || ''}
									onChange={e => handleValueMask('Produto', e.target.value, 'INC')}
									onFocus={() => clearErrs('Produto')}
									isInvalid={filterErrs.Produto}
								/>
							</Form.Group>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Cor Produto</Form.Label>
								<Form.Control
									type="text"
									placeholder="Cor Produto"
									value={filterObjInclusion.CorProduto || ''}
									onChange={e => handleValueMask('CorProduto', e.target.value, 'INC')}
									onFocus={() => clearErrs('CorProduto')}
									isInvalid={filterErrs.CorProduto}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label className={styles.formLabel}>Data Início</Form.Label>
								<Form.Control
									type="date"
									value={filterObjInclusion.DataInicio || ''}
									onFocus={() => clearErrs('DataInicio')}
									onChange={e => setFilterObjInclusion({ ...filterObjInclusion, DataInicio: e.target.value })}
									// min={today}
									max={'3000-01-01'}
									isInvalid={filterErrs.DataInicio}
								>
								</Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label className={styles.formLabel}>Data Fim</Form.Label>
								<Form.Control
									type="date"
									value={filterObjInclusion.DataFim || ''}
									onFocus={() => clearErrs('DataFim')}
									onChange={e => setFilterObjInclusion({ ...filterObjInclusion, DataFim: e.target.value })}
									// min={today}
									max={'3000-01-01'}
									isInvalid={filterErrs.DataFim}
								>
								</Form.Control>
							</Form.Group>
							<Button
								className={styles.formGroup}
								variant="primary"
								type="button"
								onClick={handleSearchInclusion}
							>
								Buscar
							</Button>
						</Form>
					</div>

					{/* ACAO !== 'INCLUSAO' */}
					<div
						style={{ display: acaoSelecionada?.value !== 'INCLUSAO' ? 'inline' : 'none' }}
					>
						<Form className={styles.form}>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
								<Form.Control
									type="text"
									placeholder="Nota Fiscal"
									value={filterObjAlteration.NFEntrada || ''}
									onChange={e => handleValueMask('NFEntrada', e.target.value, 'ALT')}
								/>
							</Form.Group>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Produto</Form.Label>
								<Form.Control
									type="text"
									placeholder="Produto"
									value={filterObjAlteration.Produto || ''}
									onChange={e => handleValueMask('Produto', e.target.value, 'ALT')}
								/>
							</Form.Group>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Cor Produto</Form.Label>
								<Form.Control
									type="text"
									placeholder="Cor Produto"
									value={filterObjAlteration.CorProduto || ''}
									onChange={e => handleValueMask('CorProduto', e.target.value, 'ALT')}
								/>
							</Form.Group>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Ordem Serviço</Form.Label>
								<Form.Control
									type="text"
									placeholder="Ordem Serviço"
									value={filterObjAlteration.OrdemServico || ''}
									onChange={e => handleValueMask('OrdemServico', e.target.value, 'ALT')}
								/>
							</Form.Group>
							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.formLabel}>Ordem Produção</Form.Label>
								<Form.Control
									type="text"
									placeholder="Ordem Produção"
									value={filterObjAlteration.OrdemProducao || ''}
									onChange={e => handleValueMask('OrdemProducao', e.target.value, 'ALT')}
								/>
							</Form.Group>
							<Button
								className={styles.formGroup}
								variant="primary"
								type="button"
								onClick={handleSearchAlteration}
							>
								Buscar
							</Button>
						</Form>
					</div>
				</article>
			</article>

			<article className={styles.bodyWrapper}>
				{/* ACAO === 'INCLUSAO' */}
				<div
					style={{ display: acaoSelecionada?.value === 'INCLUSAO' ? null : 'none' }}
					className={styles.incArticleWrapper}
				>
					{/* Só deve mostrar dados após busca */}
					<article className={styles.articleGray}>
						<h6>Identificação Entrada Fiscal</h6>
						<div className={styles.incTableWrapper}>
							<Table className={styles.articleWhiteTable} responsive>
								<thead className={styles.thead}>
									<tr>
										<th className={styles.blockTitle}></th>
										<th className={styles.blockTitle}>NF ENTRADA</th>
										<th className={styles.blockTitle}>SERIE NF</th>
										<th className={styles.blockTitle}>FILIAL</th>
										<th className={styles.blockTitle}>NOME CLIFOR</th>
										<th className={styles.blockTitle}>DATA ENTRADA FISCAL</th>
										<th className={styles.blockTitle}>CHAVE NFE</th>
										<th className={styles.blockTitle}>REFERÊNCIA</th>
										<th className={styles.blockTitle}>QTDE. TOTAL</th>
										{/* <th className={styles.blockTitle}>QTDE. ITEM</th> */}
										{/* <th className={styles.blockTitle}>VALOR ITEM</th> */}
									</tr>
								</thead>
								<tbody>
									{loading ? <tr style={{ 'backgroundColor': 'white' }}><td><Carregando></Carregando></td></tr> :
										mainInsertData.EntradaFiscal.map((el, index) => {
											return (
												<tr className={styles.tableLines} key={index}>
													<td className={styles.tableLines}>
														<Form>
															<Form.Check
																className={styles.tableLinesCheck}
																type='radio'
																name={'EntradaFiscal'}
																id={'EntradaFiscal'}
																onChange={() => {
																	setSelectedEF(index);
																}}
																checked={selectedEF === index}
															/>
														</Form>
													</td>
													<td className={styles.tableLines}>{el.NFEntrada ? el.NFEntrada.trim() : null}</td>
													<td className={styles.tableLines}>{el.SerieNF ? el.SerieNF.trim() : null}</td>
													<td className={styles.tableLines}>{el.FilialRevisao ? el.FilialRevisao.trim() : null}</td>
													<td className={styles.tableLines}>{el.Origem ? el.Origem.trim() : null}</td>
													<td className={styles.tableLines}>{el.DataEntrada ? toUTCDDMMYYYY(el.DataEntrada) : null}</td>
													<td className={styles.tableLines}>{el.ChaveNFE ? el.ChaveNFE.trim() : null}</td>
													<td className={styles.tableLines}>{el.Referencia ? el.Referencia.trim() : null}</td>
													<td className={styles.tableLines}>{el.QtdeTotal ? el.QtdeTotal : null}</td>
													{/* <td className={styles.tableLines}>{el.QtdeItem}</td> */}
													{/* <td className={styles.tableLines}>{el.ValorItem}</td> */}
												</tr>
											)
										})
									}
								</tbody>
							</Table>
						</div>
					</article>

					<div className={styles.incIDWrapper}>
						<article className={styles.articleGray}>
							<h6>Identificação Entrada Estoque</h6>
							<div className={styles.incTableWrapper}>
								<Table className={styles.articleWhiteTable} responsive>
									<thead className={styles.thead}>
										<tr>
											<th className={styles.blockTitle}></th>
											<th className={styles.blockTitle}>ROMANEIO</th>
											<th className={styles.blockTitle}>PRODUTO</th>
											<th className={styles.blockTitle}>COR</th>
											<th className={styles.blockTitle}>QTDE</th>
											<th className={styles.blockTitle}>FILIAL REVISÃO</th>
										</tr>
									</thead>
									<tbody>
										{loading ? <tr><td><Carregando></Carregando></td></tr> :
											mainInsertData.EntradaEstoque.map((el, index) => {
												return (
													<tr className={styles.tableLines} key={index}>
														<td className={styles.tableLines}>
															<Form>
																<Form.Check
																	className={styles.tableLinesCheck}
																	type='radio'
																	name={'EntradaEstoque'}
																	id={'EntradaEstoque'}
																	onChange={() => {
																		setSelectedEE(index);
																		setSelectedEERomaneio(el.Romaneio);
																	}}
																	checked={selectedEE === index}
																/>
															</Form>
														</td>
														<td className={styles.tableLines}>{el.Romaneio ? el.Romaneio.trim() : ' - '}</td>
														<td className={styles.tableLines}>{el.Produto ? el.Produto.trim() : ' - '}</td>
														<td className={styles.tableLines}>{el.CorProduto ? el.CorProduto.trim() : ' - '}</td>
														<td className={styles.tableLines}>{el.QtdeEntrada ? el.QtdeEntrada : ' - '}</td>
														<td className={styles.tableLines}>{el.FilialRevisao ? el.FilialRevisao.trim() : ' - '}</td>
													</tr>
												)
											})
										}
									</tbody>
								</Table>
							</div>
						</article>

						<article className={styles.articleGray}>
							<h6>Grade (apenas leitura)</h6>
							<div className={styles.incTableWrapper}>
								<Table className={styles.articleWhiteTable} responsive>
									<thead className={styles.thead}>
										<tr>
											{/* <th className={styles.blockTitle}></th> */}
											<th className={styles.blockTitle}>ROMANEIO</th>
											<th className={styles.blockTitle}>PRODUTO</th>
											<th className={styles.blockTitle}>COR</th>
											<th className={styles.blockTitle}>TAMANHO</th>
											<th className={styles.blockTitle}>QTDE</th>
											<th className={styles.blockTitle}>FILIAL REVISÃO</th>
										</tr>
									</thead>
									<tbody className={styles.tbody}>
										{loading ? <tr><td><Carregando></Carregando></td></tr> :
											mainInsertData.Grade.map((el, index) => {
												return (
													<tr
														className={styles.tableLines}
														key={index}
														style={selectedEERomaneio !== null && selectedEERomaneio === el.Romaneio ? {} : { 'display': 'none' }}
													// style={index < 3 ? {} : {'display': 'none'}}
													>
														{/* <td className={styles.tableLines}>
															<Form>
																<Form.Check
																	type='checkbox'
																/>
															</Form>
														</td> */}
														<td className={styles.tableLines}>{el.Romaneio ? el.Romaneio.trim() : ' - '}</td>
														<td className={styles.tableLines}>{el.Produto ? el.Produto.trim() : ' - '}</td>
														<td className={styles.tableLines}>{el.CorProduto ? el.CorProduto.trim() : ' - '}</td>
														<td className={styles.tableLines}>{el.Tamanho ? el.Tamanho.trim() : ' - '}</td>
														<td className={styles.tableLines}>{el.QtdeGrade ? el.QtdeGrade : ' - '}</td>
														<td className={styles.tableLines}>{el.FilialRevisao ? el.FilialRevisao.trim() : ' - '}</td>
													</tr>
												);
											})
										}
									</tbody>
								</Table>
							</div>
						</article>
					</div>

					<article className={styles.footer}>
						<Button
							variant="primary"
							type="button"
							onClick={() => {
								handleProcessEF();
								setShowProcessingInclusion({ value: true, message: null });
							}}
							disabled={selectedEE === null || selectedEF === null}
						>
							Processar Entrada Fiscal
						</Button>
					</article>
				</div>

				{/* ACAO !== 'INCLUSAO' */}
				<div style={{ display: acaoSelecionada?.value !== 'INCLUSAO' ? 'inline' : 'none' }}>
					<article className={styles.articleGrayAlt}>
						<Table responsive>
							<thead>
								<tr>
									<th className={styles.blockTitle}></th>
									<th className={styles.blockTitle}>TRANSFERIDO</th>
									<th className={styles.blockTitle}>NOTA FISCAL</th>
									<th className={styles.blockTitle}>SÉRIE NF</th>
									<th className={styles.blockTitle}>ORIGEM</th>
									<th className={styles.blockTitle}>PRODUTO</th>
									<th className={styles.blockTitle}>COR PRODUTO</th>
									<th className={styles.blockTitle}>QTDE. ENTRADA</th>
									<th className={styles.blockTitle}>FILIAL REVISÃO</th>
									<th className={styles.blockTitle}>STATUS LINHA</th>
									<th className={styles.blockTitle}>ORDEM PRODUÇÃO</th>
									<th className={styles.blockTitle}>ORDEM SERVIÇO</th>
								</tr>
							</thead>
							<tbody>
								{loading ? <tr><td><Carregando></Carregando></td></tr> :
									mainAlterationData.map((el, index) => {
										return (
											<tr className={styles.tableLines} key={index}>
												<td className={styles.tableLines}>
													<Button
														variant="danger"
														style={{ display: acaoSelecionada?.value === 'EXCLUSAO' ? 'inline' : 'none' }}
														onClick={() => handleShowModal(el)}
													>
														Excluir
													</Button>

													<Button
														variant="success"
														style={{ display: acaoSelecionada?.value === 'DUPLICIDADE' ? 'inline' : 'none' }}
														onClick={() => handleShowModal(el)}
													>
														Duplicidade
													</Button>

													<Button
														variant="warning"
														style={{ display: acaoSelecionada?.value === 'DESABILITAR' ? 'inline' : 'none' }}
														onClick={() => handleShowModal(el)}
													>
														Desabilitar
													</Button>

													<Button
														variant="primary"
														style={{ display: acaoSelecionada?.value === 'HABILITAR' ? 'inline' : 'none' }}
														onClick={() => handleShowModal(el)}
													>
														Habilitar
													</Button>

												</td>
												<td className={styles.tableLines}>{el.TransferidoEstoque}</td>
												<td className={styles.tableLines}>{el.NFEntrada}</td>
												<td className={styles.tableLines}>{el.SerieNF}</td>
												<td className={styles.tableLines}>{el.Origem}</td>
												<td className={styles.tableLines}>{el.Produto}</td>
												<td className={styles.tableLines}>{el.CorProduto}</td>
												<td className={styles.tableLines}>{el.QtdeEntrada}</td>
												<td className={styles.tableLines}>{el.FilialRevisao}</td>
												<td className={styles.tableLines}>{el.StatusLinha}</td>
												<td className={styles.tableLines}>{el.OrdemProducao}</td>
												<td className={styles.tableLines}>{el.OrdemServico}</td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
					</article>
				</div>
			</article>

			<Modal
				show={showModal}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="medium"
				centered
			>
				<Modal.Header>
					<Modal.Title>Atenção, confira os dados a seguir:</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>Nota Fiscal: <b>{activeObj?.NFEntrada}</b></div>
					<div>Série NF: <b>{activeObj?.SerieNF}</b></div>
					<div>Origem: <b>{activeObj?.Origem}</b></div>
					<div>Produto: <b>{activeObj?.Produto}</b></div>
					<div>Cor: <b>{activeObj?.CorProduto}</b></div>
					<br />
					<div>Deseja finalizar a operação com a ação <b className={styles.red}>{acaoSelecionada?.label}</b>?</div>
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					<Button
						variant="outline-danger"
						onClick={handleClose}
						className={styles.modalBtn}
					>
						Não
					</Button>
					<Button
						variant="primary"
						onClick={handleProcessAlteracao}
						className={styles.modalBtn}
					>
						Sim
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showProcessingInclusion.value === true}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="medium"
				centered
			>
				<Modal.Header>
					<Modal.Title> {'  '}  </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{showProcessingInclusion.message === null ? <Carregando></Carregando> :
						<div>
							<h5>Atenção, {showProcessingInclusion.message.toLowerCase()}.</h5>
						</div>
					}
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					<Button
						variant="primary"
						onClick={() => setShowProcessingInclusion({ value: false, message: null })}
						className={styles.modalBtn}
					>
						Concluir
					</Button>
				</Modal.Footer>
			</Modal>

			<ToastContainer
				position="top-center"
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
			/>
		</div>
	)
}

export default PAAdmLiberarRiscoSacado;