
import React from 'react';
import styles from './listar_imagens_preview.module.scss';
import MaterialIcon from 'material-icons-react';

const ListarImagemPreview = (params) => {
  const { imagens, removerImagem } = params;
  return (
    <div className={styles.image_preview_container}>
      {
        imagens.map((image, index) => (
          <div key={index} className={styles.image_preview}>
            <img src={URL.createObjectURL(image)} alt={`preview_${index}`} />
            <div className={styles.remove_btn} onClick={() => removerImagem(index)}>
              <MaterialIcon
                icon={'cancel'}
                size={20}
                color='#DC3545'
              />
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default ListarImagemPreview;
