import Cookies from "js-cookie";

export default class CookiesService {
  static getUserData() {
    try {
      const userData = Cookies.get('user_data');
      return userData ? JSON.parse(userData) : {}
    } catch (e) {
      console.error('Error parsing user_data cookie:', e);
      return null;
    }
  }

  static get(key) {
    if (process.env.REACT_APP_IS_DEBUG_MODE) return null;
    const userData = this.getUserData();
    return userData ? userData[key] : null;
  }

  static getUserLogin() {
    if (process.env.REACT_APP_IS_DEBUG_MODE) return 'usuario.teste';
    const userData = this.getUserData();
    return userData ? userData.login : null;
  }

  static getUserId() {
    if (process.env.REACT_APP_IS_DEBUG_MODE) return Math.floor(100000 + Math.random() * 900000);
    const userData = this.getUserData();
    return userData ? userData.id_usuario : null;
  }

  static isUserAdmin() {
    if (process.env.REACT_APP_IS_DEBUG_MODE) return true;
    const userData = this.getUserData();
    return userData ? userData.admin : false;
  }

  static getAcessos() {
    if (process.env.REACT_APP_IS_DEBUG_MODE) return { pa_revisao_priorizar: true };
    const userData = this.getUserData();
    return userData ? userData.acessos : {};
  }
}
