import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { api }  from '../../../../services/api';
import Carregando from '../../../Carregando';

function ImprimirEtiquetasPA(params) {
  const { etiquetas, disabled, btnString, filtrarErros } = params;
  const [carregando, setCarregando] = useState(false);

  async function downloadTxtFile() {
    if (etiquetas.length === 0) { setCarregando(false); return alert('Selecione para imprimir.'); }

    let listIdCadastro = '';
    for (let i = 0; i < etiquetas.length; i++) {
      if (filtrarErros === true && etiquetas[i].result === false) continue;
      listIdCadastro = listIdCadastro + etiquetas[i].idCadastro.toString() + ','
    }
    listIdCadastro = listIdCadastro.slice(0, -1);

    const listaAdmEtiquetas = await api.get('PAPecaPiloto/GetAmostraList', { params: { 'listIdCadastro': listIdCadastro } });

    if (listaAdmEtiquetas.data.length === 0) { setCarregando(false); return; }

    let etiquetasInfo = listaAdmEtiquetas.data;
    for (let i = 0; i < etiquetasInfo.length; i++) {
      let contentFile = `
        ^XA
        ^MMT
        ^PW831
        ^LL0799
        ^LS0
        ^FT36,100^A0N,100,75^FH^FD ${etiquetasInfo[i]['material']}^FS
        ^FT500,50^A0N,28,28^FH^FDCAD: ${etiquetasInfo[i]['dataCadastro']}^FS
        ^FT500,86^A0N,28,28^FH^FDArara: ${etiquetasInfo[i]['idArara']}^FS
        ^FT500,122^A0N,28,28^FH^FDAndar: ${etiquetasInfo[i]['andar']}^FS
        ^FT500,158^A0N,28,28^FH^FDLado: ${etiquetasInfo[i]['lado']}^FS
        ^FT500,194^A0N,28,28^FH^FDPosicao: ${etiquetasInfo[i]['posicao']}^FS
        
        ^FO36,220^GB760,190,4^FS
        ^FT56,270^A0N,34,33^FH^FDLocal: ${etiquetasInfo[i]['estoque']}^FS
        ^BY3,2,47^FT210,350^BCN,,Y,N^FD>:Qualidade^FS
        
        ^FO36,430^GB760,200,4^FS
        ^FT56,480^A0N,34,33^FH^FDProduto: ${etiquetasInfo[i]['material']}^FS
        ^FT56,520^A0N,34,33^FH^FDDesc: ${etiquetasInfo[i]['descProduto']}^FS
        ^FT56,560^A0N,34,33^FH^FDTipo Piloto: ${etiquetasInfo[i]['tipoMaterial']}^FS
        ^FT56,600^A0N,34,33^FH^FDColecao: ${etiquetasInfo[i]['colecao']}^FS
        
        ^FT56,690^A0N,34,33^FH^FDUsuario: ${etiquetasInfo[i]['idUsuarioCadastro']}^FS
        ^FT56,762^A0N,45,45^FH^FDCQ - CATALOGO PRODUTO ACABADO^FS
        ^LRY
        ^FO36,644^GB760,0,145^FS
        ^LRN

        ^PQ1,0,1,Y
        ^XZ
      `;

      let sufixo = '';
      if (etiquetasInfo[i]['estoque'] === 'ESTOQUE PAVUNA') sufixo = '-pavuna';
      if (etiquetasInfo[i]['estoque'] === 'ESTOQUE CHECKLIST') sufixo = '-checklist';

      // @IDCADASTRO = @MATERIAL + @COR_MATERIAL + @COD_CLIFOR
      let idCadastro = etiquetasInfo[i].material + etiquetasInfo[i].corMaterial + etiquetasInfo[i].codClifor;

      const element = document.createElement('a');
      const file = new Blob([contentFile], {
        type: 'text/plain',
        endings: 'native',
      });
      element.href = URL.createObjectURL(file);
      element.download = `${idCadastro.replaceAll('.', "")}-pa-qld${sufixo}.txt`;
      document.body.appendChild(element);
      element.click();
    }
    setCarregando(false);
  };

  return (
    <>
      <Button
        onClick={() => { setCarregando(true); downloadTxtFile(); }}
        disabled={disabled}>{btnString || 'Imprimir Etiquetas PA'}
      </Button>
      <Modal show={carregando} centered={true}><Carregando /></Modal>
    </>
  )
}

export default ImprimirEtiquetasPA;
