import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styles from '../../styles/Revisao.module.scss';
import { useState } from "react";

export function Filtros ({ handleBuscar, limpaFiltros }) {
  const [state, setState] = useState({
    ordemServico: "",
    ordemProducao: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!value) {
      limpaFiltros()
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleBuscar(state);
  };

    return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <Form.Group className={styles.formGroup}>
        <Form.Label className={styles.formLabel}>OS</Form.Label>
        <Form.Control
          type="text"
          name="ordemServico"
          placeholder="OS"
          value={state.ordemServico}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className={styles.formGroup}>
        <Form.Label className={styles.formLabel}>OP</Form.Label>
        <Form.Control
          type="text"
          name="ordemProducao"
          placeholder="OP"
          value={state.ordemProducao}
          onChange={handleChange}
        />
      </Form.Group>
      <Button
        className={styles.formGroup}
        variant="primary"
        type="submit"
      >
        Buscar
      </Button>
    </Form>
   );
}
