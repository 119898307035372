import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from 'react-toastify';

import { formatarDataBR } from '../../utils/dateUtils'

const DadosEntrada = (props) => {

  const [expanded, setExpanded] = useState(true);

  return (
    <div className={props.className}>
      <div className="border rounded border-secondary p-1 bg-lighter">
        <div className="d-flex cursor-pointer " onClick={() => setExpanded(!expanded)}>
          <span className="ms-2 text-dark m-0">{expanded ? "▲" : "▼"}</span>
          <h6 className="ms-3 text-dark m-1">Dados de Entrada</h6>
        </div>

        <div className={(expanded ? "d-block " : "d-none ") + "border-top border-secondary mt-1 px-3 pt-3 overflow-hd"}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column me-4">
              <div className="d-flex flex-column">
                <h6 className="text-nowrap mb-0 fw-normal">Pedido:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.pedido || '-'}</h6>
              </div>
              <div className="d-flex flex-column mt-4">
                <h6 className="text-nowrap mb-0 fw-normal">Material:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.material || '-'}</h6>
              </div>
            </div>
            <div className="d-flex flex-column  me-4">
              <div className="d-flex flex-column">
                <h6 className="text-nowrap mb-0 fw-normal">Nota Fiscal:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.nfentrada || '-'}</h6>
              </div>
              <div className="d-flex flex-column mt-4">
                <h6 className="text-nowrap mb-0 fw-normal">Descrição Material:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.descricaomaterial || '-'}</h6>
              </div>
            </div>
            <div className="d-flex flex-column  me-4">
              <div className="d-flex flex-column">
                <h6 className="text-nowrap mb-0 fw-normal">Série NF:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.serienf || '-'}</h6>
              </div>
              <div className="d-flex flex-column mt-4">
                <h6 className="text-nowrap mb-0 fw-normal">Cor Material:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.cormaterial || '-'}</h6>
              </div>
            </div>
            <div className="d-flex flex-column me-4">
              <div className="d-flex flex-column">
                <h6 className="text-nowrap mb-0 fw-normal">Marca:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.marca || '-'}</h6>
              </div>
              <div className="d-flex flex-column mt-4">
                <h6 className="text-nowrap mb-0 fw-normal">Descrição Cor:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.descricaocor || '-'}</h6>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column">
                <h6 className="text-nowrap mb-0 fw-normal">Requerido Por:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{props.obj?.requeridopor || '-'}</h6>
              </div>
              <div className="d-flex flex-column mt-4">
                <h6 className="text-nowrap mb-0 fw-normal">Fornecedor:</h6>
                <h6 className="text-nowrap fw-normal text-primary">{(props.obj?.nomeclifor || props.obj?.fonecedor) || '-'}</h6>
              </div>
            </div>
              <div className="d-flex flex-colunm">
              <div className="d-flex flex-column">
                  <h6 className="text-nowrap mb-0 fw-normal">Data Alteracao Pedido:</h6>
                  <h6 className="text-nowrap fw-normal text-primary">{(formatarDataBR(props.obj?.dataalteracaopedido) || formatarDataBR(props.obj?.dataalteracaopedido)) || '-'}</h6>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DadosEntrada;