import react, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from '../../../../../styles/PA/Desfazer.module.scss';
import { toUTCDDMMYYYY } from '../../../../../utils/dateUtils';

const DevolucaoForm = (props) => {
  const { formDevolucao, setFormDevolucao, formErrs, setFormErrs, objSelecionado } = props;
  const [qtdePecasContadas, setQtdePecasContadas] = useState(0);

  const handleGradeQtdeChange = (tam, qtde) => {
    setFormDevolucao({
      ...formDevolucao,
      grade: {
        ...formDevolucao.grade,
        [tam]: {
          value: true,
          qtde: qtde.replace(/[^0-9]*/g, '')
        }
      }
    });
  }

  const handleNumberMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
    setFormDevolucao({ ...formDevolucao, [field]: value });
  }

  const handleCheck = (tam) => {
    let setter = true;
    if (formDevolucao?.grade?.[tam]?.value === true) setter = false;
    setFormDevolucao({
      ...formDevolucao,
      grade: {
        ...formDevolucao.grade,
        [tam]: {
          value: setter,
          qtde: 0
        }
      }
    });
  }

  const handleDisabledCheck = (tam) => {
    if (formDevolucao?.grade?.[tam]?.value === true) return false;
    else return true;
  }

  useEffect(() => {
    if (typeof objSelecionado.Grade === 'string') {
      const gradeObj = {}
      const gradeArr = objSelecionado.Grade.split(';');
      for (let i = 0; i < gradeArr.length; i++) {
        let str = gradeArr[i];
        str = str.split('/');
        if (str[0] === '') continue;
        gradeObj[str[0]] = { value: true, qtde: str[1] }
      }
      setFormDevolucao({ ...formDevolucao, grade: { ...gradeObj } });
    }
  }, [objSelecionado]);

  useEffect(() => {
    if (!formDevolucao.grade) return;
    const keys = Object.keys(formDevolucao?.grade);
    let qtde = 0;
    for (let i = 0; i < keys.length; i++) {
      qtde += parseInt(formDevolucao.grade[keys[i]].qtde) || 0;
    }
    setQtdePecasContadas(qtde);
  }, [formDevolucao])

  return (
    <div className={styles.devolucaoWrapper}>
      {objSelecionado?.grade?.map((tam, index) => {
        return (
          <Form.Group
            className={styles.formGroupRow}
            controlId={`tamanho-${index}`}
            key={index}
          >
            <Form.Check
              type={'checkbox'}
              className={styles.check}
              onChange={() => handleCheck(tam.label)}
              label={tam.label}
              checked={formDevolucao?.grade?.[tam.label]?.value || false}
            />
            <Form.Control
              type="text"
              className={styles.qtBox}
              placeholder={formDevolucao?.grade?.[tam.label]?.qtde || 0}
              value={formDevolucao?.grade?.[tam.label]?.qtde || ''}
              onChange={e => handleGradeQtdeChange(tam.label, e.target.value)}
              disabled={handleDisabledCheck(tam.label)}
            />
          </Form.Group>
        )
      })}
      <Form.Label>Quantidade Peças Contadas - {qtdePecasContadas}</Form.Label>

      <Form.Group className={styles.filterWidth} controlId='observacao'>
        <Form.Label>Observação</Form.Label>
        <Form.Control
          as="textarea"
          rows={2}
          type="text"
          value={formDevolucao.observacao || ''}
          placeholder={objSelecionado.Observacao}
          onChange={e => { setFormDevolucao({ ...formDevolucao, observacao: e.target.value }) }}
          required
        />
      </Form.Group>

      <Form.Group className={styles.filterWidth} controlId='volume'>
        <Form.Label>Volume</Form.Label>
        <Form.Control
          type="text"
          placeholder={objSelecionado.Volume}
          value={formDevolucao.volume || ''}
          onChange={e => handleNumberMask('volume', e.target.value)}
        />
      </Form.Group>

      <Form.Group className={styles.filterWidth} controlId='dataAgendamento'>
        <Form.Label>
          Data de Agendamento -&nbsp;
          <span>Anterior: {toUTCDDMMYYYY(objSelecionado.DataAgendamento)}</span>
        </Form.Label>
        <Form.Control
          required
          type="date"
          value={formDevolucao.dataAgendamento || ''}
          onChange={e => setFormDevolucao({ ...formDevolucao, dataAgendamento: e.target.value })}
          placeholder={objSelecionado.DataAgendamento}
          min={'2000-01-01'}
          max={'9999-01-01'}
          onClick={() => setFormErrs({ ...formErrs, dataAgendamento: null })}
        />
      </Form.Group>
    </div>
  )
}

export default DevolucaoForm;