import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import Image from 'react-bootstrap/Image';

const ModalGrade = () => {

    const { imagemData, showImagem, handleCloseImagem } = useDistribuicaoDemanda();

    return (
        <>
            <Modal
                show={showImagem}
                onHide={handleCloseImagem}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <Image
                        src={imagemData}
                        width='400vw'
                        fluid
                        className={styles.imagem}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalGrade;
