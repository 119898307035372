import { Modal, Button, Form } from "react-bootstrap";
import styles from '../pa_revisao_sq_nv.module.scss';
import { usePARevisaoSegundaQualidadeNV } from "../pa_revisao_sq_nv_context";
import Tabela from "../../../Misc/Tabela/Tabela";

const PARevisaoSQNVModalProcessamento = () => {
  const { modalFinalizacao, setModalFinalizacao, gradeRevisao, setGradeRevisao, direcionamentoRevisao, referenciaSelecionada, processarRevisaoSQNV, processarAjusteSQNV } = usePARevisaoSegundaQualidadeNV();

  const direcionamento_columns = [
    { name: 'Tamanho', selector: row => row.grade.tamanho },
    { name: 'Quantidade', selector: row => row.quantidade },
    { name: 'Defeitos', selector: row => row.defeito.map(item => item.label).join(' | '), width: '30%' },
    { name: 'Direcionamento', selector: row => row.direcionamento.label, width: '30%' }
  ];

  const grade_columns = [
    { name: 'Tamanho', selector: row => row.tamanho },
    { name: 'Quantidade Aprovada', selector: row => row.quantidade_aprovada },
    { name: 'Qtde. Estoque', selector: row => (row.quantidade_aprovada - row.quantidade_aprovada_cristiano_viana) },
    {
      name: 'Qtde. Cristiano Viana', selector: (row, index) => {
        return (
          <Form.Control
            type="number"
            value={row.quantidade_aprovada_cristiano_viana}
            onChange={e => alterarQuantidadesCV(index, e.target.value, 'quantidade_aprovada_cristiano_viana')}
            placeholder={'0'}
            disabled={row.quantidade_aprovada === 0}
          />
        )
      }
    }
  ];

  const alterarQuantidadesCV = (index, value, field) => {
    let newValue = parseInt(value, 10);

    if (newValue === null || isNaN(newValue) || newValue < 0) {
      gradeRevisao[index][field] = '';
      return setGradeRevisao([...gradeRevisao]);
    }

    const currentItem = gradeRevisao[index];
    if (newValue > currentItem.quantidade_aprovada) newValue = currentItem.quantidade_aprovada;
    gradeRevisao[index][field] = newValue;

    setGradeRevisao([...gradeRevisao]);
  }

  const fecharModalFinalizacao = () => {
    gradeRevisao.forEach(tamanho => {
      tamanho.quantidade_aprovada_cristiano_viana = 0;
    });
    setGradeRevisao([...gradeRevisao]);
    setModalFinalizacao(false);
  }

  return (
    <Modal
      show={modalFinalizacao}
      centered
      size={'lg'}
      onHide={fecharModalFinalizacao}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header style={{ fontWeight: '500' }} closeButton>
        Resumo {modalFinalizacao === 'Ajuste' ? 'do Ajuste' : ''} da Análise | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body} style={{ fontWeight: '500' }}>
        <section>
          <label className={styles.form_label}>Direcionamento de peças sem defeitos para loja Cristiano Viana</label>
          <Tabela
            columns={grade_columns}
            data={gradeRevisao}
            pagination={false}
          />
        </section>
        <section>
          <label className={styles.form_label}>Direcionamento de Defeitos</label>
          <Tabela
            columns={direcionamento_columns}
            data={direcionamentoRevisao}
            pagination={false}
          />
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'success'}
          onClick={modalFinalizacao === 'Ajuste' ? processarAjusteSQNV : processarRevisaoSQNV}
          style={{ margin: '0px auto' }}
          className={styles.footer_btn_processamento}
        >
          {modalFinalizacao === 'Ajuste' ? 'Processar Ajuste da Análise' : 'Processsar Análise'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoSQNVModalProcessamento;