import { useEffect, useState } from "react";
import { api }  from "../services/api";

const RECLASSIFICACAO_CHAMADOS__BASE_URL = '/corte/'

const useReclassificacaoChamados = () => {
  const [data, setData ] = useState([]);
  const [error, setError ] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(RECLASSIFICACAO_CHAMADOS__BASE_URL + 'classificacao-chamados')
        setData(response);
        setLoading(false)
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [])

  return {data, loading, error}
}

export default useReclassificacaoChamados;