import React, { useEffect } from "react";
import { Form, FormGroup, Button, Table, Modal } from "react-bootstrap";
import MaterialIcon from 'material-icons-react';
import { toast } from "react-toastify";
import Select from 'react-select';
import Carregando from "../../Carregando";

import ImprimirEtiquetasV2 from "../../ImprimirEtiquetaV2";
import { api }  from "../../../services/api";

import styles from "../../../styles/CadastroAmostras.module.scss";
import { toUTCDDMMYYYY } from "../../../utils/dateUtils";

import { useMPCadastroAmostra } from '../../../contexts/MPCadastroAmostraContext';

const CadastroAmostras = () => {

  const {setShowLoading, handleInserirItemCadastro, handleProcessar, handleAraraClick, handleCheckForTypes, handleListarCadastrados, handleSearch, handleResetForm
  ,material , setMaterial,cor, setCor,itemFiltrado, setItemFiltrado,tipoMaterial, setTipoMaterial,arara, setArara,andar, setAndar,lado, setLado,itensCadastrados, setItensCadastrados,itensCadastradosParaImprimir, setItensCadastradosParaImprimir,tipoMaterialSelecionado, setTipoMaterialSelecionado,araraSelecionada, setAraraSelecionada,andarSelecionado, setAndarSelecionado,itensACadastrar, setItensACadastrar, itensParaImprimir, setItensParaImprimir,processando, setProcessando,userLogin, handleDelete
  } = useMPCadastroAmostra();


  useEffect(() => {
    (async () => {
      try {
        const tipoMaterialResponse = await api.get('Catalogo/Catalogotipomaterial');
        const updatedTipoMaterialData = tipoMaterialResponse.data.map(item => ({
          value: item.idTipo,
          label: `${item.local} (${item.idTipo})`
        }));
        setTipoMaterial(updatedTipoMaterialData);
      
        const araraResponse = await api.get('Catalogo/CatalogoAraras');
        const updatedAraraData = araraResponse.data.map(item => ({
          value: item.idArara,
          label: `ARARA${item.idArara} - Disponível ${item.qtdeDisponivel}`
        }));
        setArara(updatedAraraData);
        
      } catch (e) {
        console.error(`Erro na requisição: ${e}`);
        toast.error('Ocorreu um erro no carregamento da interface, por favor recarregue a página.');
      }
      
    })();
  }, [itensParaImprimir]);

  return (
    <div className="m-3">
      <h3>Cadastro de Amostras MP</h3>

      <article className={styles.articleInRow} style={{marginLeft: '10px'}}>
        <Form className={styles.form}>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Material</Form.Label>
            <Form.Control
              required
              type="text"
              title="Este campo é obrigatório"
              value={material}
              onChange={(event) => setMaterial(event.target.value)}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label
              className="form-label-input"
              type="text"
            >
              Cor Material
            </Form.Label>
            <Form.Control
              type="text"
              value={cor}
              onChange={(event) => setCor(event.target.value)}
            />
          </FormGroup>
          <Button
            className={styles.btn}
            variant="primary"
            type="button"
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </Form>
      </article>

      <article className={styles.articleInRow}>
        <section className={styles.sectionLeft}>

          <h5 className="m-3">Item a ser cadastrado</h5>
          <div className={styles.flexWrapperCol}>
            <div className={styles.flexColumn}>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Material</label>
                <div>{itemFiltrado.MATERIAL || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Cor</label>
                <div>{itemFiltrado.COR_MATERIAL || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Cod. Fornecedor</label>
                <div>{itemFiltrado.COD_CLIFOR || ' - '}</div>
              </div>
            </div> 
            <div className={styles.flexColumn}>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Descrição Material</label>
                <div>{itemFiltrado.DESC_MATERIAL || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Descrição Cor</label>
                <div>{itemFiltrado.DESC_COR_MATERIAL || ' - '}</div>
              </div>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Fornecedor</label>
                <div>{itemFiltrado.FABRICANTE || ' - '}</div>
              </div>
            </div>
            <div className={styles.flexColumn}>
              <div className={styles.flexItem}>
                <label className={styles.blockTitle}>Coleção</label>
                <div>{itemFiltrado.COLECAO || ' - '}</div>
              </div>
              <div className={styles.flexItem} style={{ visibility: 'hidden' }}>
                <label className={styles.blockTitle}>Placeholder</label>
                <div>Placeholder</div>
              </div>
              <div className={styles.flexItem} style={{ visibility: 'hidden' }}>
                <label className={styles.blockTitle}>Placeholder</label>
                <div>Placeholder</div>
              </div>
            </div>
          </div>

          <div className={styles.flexWrapper}>
            <Form className={styles.formFlex}>
              <div className={styles.flexRow}>
                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Tipo Material
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={tipoMaterial}
                    value={tipoMaterialSelecionado || null}
                    onChange={e => { setTipoMaterialSelecionado(e);}}
                    isOptionDisabled={option => handleCheckForTypes(option)}
                    isDisabled={itemFiltrado?.length === 0}
                  />
                </FormGroup>
                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Arara
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={arara}
                    value={araraSelecionada || null}
                    onChange={e => { setAraraSelecionada(e); handleAraraClick(e); }}
                    //filterOption={option => option.data.idLocal === estoqueSelecionado.value}
                    isDisabled={itemFiltrado?.length === 0}
                  />
                </FormGroup>
                
                <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Andar
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={andar}
                    value={andarSelecionado || null}
                    onChange={e => setAndarSelecionado(e)}
                    isDisabled={!itemFiltrado || araraSelecionada === '' || !araraSelecionada}
                  />
                </FormGroup>

                {/* <FormGroup className={styles.formGroup}>
                  <Form.Label className={styles.blockTitle}>
                    Estoque
                  </Form.Label>
                  <Select
                    isClearable={true}
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={true}
                    options={estoque}
                    value={estoqueSelecionado || null}
                    onChange={e => { setEstoqueSelecionado(e); handleInputClick('estoque'); }}
                    isDisabled={!itemFiltrado || tipoMaterialSelecionado === '' || !tipoMaterialSelecionado}
                  />
                </FormGroup> */}
              </div>             
            </Form>
          </div>

          <div className={styles.itemCentral}>
            <Button
              variant="primary"
              onClick={handleInserirItemCadastro}
              disabled={!tipoMaterialSelecionado | !araraSelecionada || !andarSelecionado }
            >
              Inserir item para cadastro
            </Button>
          </div>

          <h5 className="m-3">Itens a cadastrar</h5>
          <div className={styles.tableGrupoLarge}>
            <Table>
              <thead>
                <tr>
                  <td className={styles.blockTitle}>Arara</td>
                  <td className={styles.blockTitle}>Material</td>
                  <td className={styles.blockTitle}>Cor</td>
                  <td className={styles.blockTitle}>Cod Forn.</td>
                  <td className={styles.blockTitle}>Tipo</td>                  
                  <td className={styles.blockTitle}>Andar</td>
                  <td className={styles.blockTitle}></td>                  
                </tr>
              </thead>
              <tbody id="itensParaCadastrar">
                {itensACadastrar.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={styles.tableLines}>{item.idArara}</td>
                      <td className={styles.tableLines}>{item.material}</td>
                      <td className={styles.tableLines}>{item.corMaterial}</td>
                      <td className={styles.tableLines}>{item.codClifor}</td>
                      <td className={styles.tableLines}>{item.idTipoMaterial}</td>                      
                      <td className={styles.tableLines}>{item.andar}</td>
                      <td className={styles.tableLines}>
                        <i onClick={() => handleDelete(index)} title="Excluir">
                        <MaterialIcon 
                                                    icon="delete" 
                                                    size={20} 
                                                    color='#BB271A'
                                                />
                        </i>
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className={styles.itemCentral}>
            <Button
              variant="primary"
              type="button"
              onClick={() => { setProcessando(true); handleProcessar(); }}
              disabled={itensACadastrar.length === 0 || processando === true}
            >
              Processar
            </Button>
          </div>
        </section>

        <section className={styles.partSection}>
          <h5 className="m-3">Itens já cadastrados</h5>
          <div className={styles.tableGray}>
            <Table>
              <thead>
                <tr>
                 <td className={styles.blockTitle}>ID Cadastro</td>                  
                  <td className={styles.blockTitle}>Material</td>
                  <td className={styles.blockTitle}>Cor</td>
                  <td className={styles.blockTitle}>Cod Forn.</td>
                  <td className={styles.blockTitle}>Data Cadastro</td>
                  <td className={styles.blockTitle}>ID Usuario</td>
                  <td className={styles.blockTitle}>Localização</td>                  
                </tr>
              </thead>
              <tbody>
                { itensCadastrados.map((itemCadastrado, index) => {
                  return (
                    <tr key={index}>
                      <td className={styles.tableLines}>{itemCadastrado.ID_CADASTRO}</td>                      
                      <td className={styles.tableLines}>{itemCadastrado.material}</td>
                      <td className={styles.tableLines}>{itemCadastrado.corMaterial}</td>
                      <td className={styles.tableLines}>{itemCadastrado.codClifor}</td>
                      <td className={styles.tableLines}>{toUTCDDMMYYYY(itemCadastrado.datacadastro)}</td>
                      <td className={styles.tableLines}>{itemCadastrado.idusuario || ' - '}</td>
                      <td className={styles.tableLines}>{itemCadastrado.idLocalizacao}</td>                      
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className={styles.itemCentral}>
              <ImprimirEtiquetasV2
                etiquetas={itensCadastrados}
                disabled={itensCadastrados.length === 0}
                btnString={'Imprimir etiquetas - Já cadastrados'}
              />
            </div>
          </div>

          <div style={{display:'flex', alignItems: 'center'}}>
            <h5 className="m-3">Cadastros nesta sessão</h5>
            <Button
              variant="primary"
              type="button"
              onClick={() => { setItensParaImprimir([]) }}
              disabled={itensParaImprimir.length === 0}
              style={{height: 'auto'}}
            >
              Limpar lista de cadastrados
            </Button>
          </div>
          <div className={styles.tableGray}>
            <Table>
              <thead>
                <tr>
                  <td className={styles.blockTitle}>Status</td>                  
                  <td className={styles.blockTitle}>Mensagem</td>
                  <td className={styles.blockTitle}>ID Cadastro</td>
                  <td className={styles.blockTitle}>Material</td>
                  <td className={styles.blockTitle}>Cor</td>
                  <td className={styles.blockTitle}>Cod Forn.</td>
                  <td className={styles.blockTitle}>Localização</td>                  
                </tr>
              </thead>
              <tbody>
                {itensParaImprimir.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={styles.tableLines}>
                        <span 
                          style={{
                            backgroundColor: item.MSG == 'CADASTRADO COM SUCESSO' ? '#4CBB5098' : '#DC354598',
                            padding: '4px 10px',
                            borderRadius: '10px',
                            color: 'white'
                          }}
                        >
                          {item.MSG !== 'CADASTRADO COM SUCESSO' ? 'Erro' : 'Sucesso'}
                        </span>
                      </td>
                      <td className={styles.tableLines}>{item.MSG}</td>
                      <td className={styles.tableLines}>{item.ID_CADASTRO}</td>                      
                      <td className={styles.tableLines}>{item.MATERIAL}</td>
                      <td className={styles.tableLines}>{item.COR_MATERIAL}</td>
                      <td className={styles.tableLines}>{item.COD_CLIFOR}</td>
                      <td className={styles.tableLines}>{item.LOCALIZACAO}</td>                      
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className={styles.itemCentral}>
              <ImprimirEtiquetasV2
                etiquetas={itensParaImprimir}
                disabled={itensParaImprimir.length === 0}
                filtrarErros={true}
                btnString={'Imprimir etiquetas - Cadastradas na sessão'}
              />
            </div>
          </div>
          <Modal
            show={processando}
            backdrop="static"
            fullscreen={false}
            keyboard={false}
            dialogClassName={styles.widthModal}
            contentClassName={styles.widthModal}
            centered
            style={{ backgroundColor: '#030303020' }}
          >
            <Modal.Body>
              <Carregando></Carregando>
            </Modal.Body>
          </Modal>
        </section>       
      </article>
    </div>
  );
};

export default CadastroAmostras;
