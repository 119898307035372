import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';
import { usePriorizacaoOps } from '../../../../contexts/Corte/ControleEnfesto/PriorizacaoOpsContext'; 

const MainTable = () => {

    const { loading, getFilteredData, handleCheckLinhas, toggledClearRows, checklistColumns } = usePriorizacaoOps();

    const filteredData = getFilteredData();

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                title
                columns={checklistColumns}
                data={filteredData}
                pending={loading}
                selectableRows
                onSelectedRowsChange={handleCheckLinhas}
                clearSelectedRows={toggledClearRows}
            />
        </section>
    );
};

export default MainTable;
