import { React, useState, useEffect } from "react";
import { Table, Accordion, Button } from "react-bootstrap";

import styles from '../../../../styles/PA/Desfazer.module.scss';
import { toUTCDDMMYYYY } from "../../../../utils/dateUtils";
import CustomPagination from "../../../Utils/CustomPagination";
import { api }  from "../../../../services/api";
import { useMisc } from "../../../../contexts/MiscContext";

const MainGridDesfazer = (props) => {
  const { setShowLoading } = useMisc();
  const { mainData, processoFiltrado, setModalAtivo, setObjSelecionado, toast } = props;

  const [currentData, setCurrentData] = useState([]);
  const [quantityPerPage, setQuantityPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleCheckForInvalidity = (el) => {
    let disabled = false;
    switch (processoFiltrado.value) {
      case 'revisao':
        if (el?.LiberaRiscoSacado === true) disabled = true;
        break;
      case 'revisaoChecklist':
      case 'revisaoMaisRevisaoChecklist':
      case 'statusPCP':
        if (el?.NFSaida && !el?.NotaCancelada) disabled = true;
        break;
      default:
        disabled = false;
        break;
    }
    return disabled;
  }

  const abrirModalAlteracoes = async (el, index) => {
    setShowLoading(true);
    const params = {
      nfEntrada: el.NFEntrada,
      serieNF: el.SerieNF,
      produto: el.Produto,
      qtdeEntrada: el.QtdeEntrada,
      corProduto: el.CorProduto,
      origem: el.Origem,
      ordemProducao: el.OrdemProducao,
      filialRevisao: el.FilialRevisao,
      grade: el.Grade
    }
    
    try {
      let grade = [];
      if (processoFiltrado?.value === 'devolucao') grade = (await api.get('/PADesfazer/consultarDevolucaoTamanhos', {params})).data;
      if (processoFiltrado?.value === 'gradeEntrada') grade = (await api.get('/PADesfazer/consultarGradeEntradaEspecifica', {params})).data;
      setObjSelecionado({ ...el, index: (index + quantityPerPage * (currentPage - 1)), grade: grade });
      setModalAtivo(true);
    } catch (e) {
      toast.error('Ocorreu um erro ao buscar a grade deste produto, por favor tente novamente ou entre em contato com o suporte.');
    }
    setShowLoading(false);
  }

  useEffect(() => {
    const start = (currentPage - 1) * quantityPerPage;
    const end = (currentPage * quantityPerPage);
    setCurrentData(mainData.slice(start, end));
  }, [currentPage, mainData]);

  return (
    <div className={styles.mairArrWrapper}>
      <CustomPagination
        totalRecords={mainData.length}
        quantityPerPage={10}
        currentPage={currentPage}
        onPageChange={n => setCurrentPage(n)}
      ></CustomPagination>

      <Table striped={true} >
        <tbody>
          {currentData.map((el, index) => {
            return (
              <tr key={index} className={styles.mainArrRow}>
                <td className={styles.mainArrItem}>
                  <div className={styles.mainArrInfoRow}>
                    <div className={styles.mainArrIcon}>
                      <Button
                        variant={'danger'}
                        disabled={handleCheckForInvalidity(el)}
                        onClick={() => abrirModalAlteracoes(el, index)}
                      >
                        Desfazer
                      </Button>
                    </div>
                    <div className={styles.mainArrCol}>
                      <div><label className={styles.mainArrLabel}>NF Entrada:&nbsp;</label><b>{el.NFEntrada || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>Série:&nbsp;</label><b>{el.SerieNF || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>Coleção:&nbsp;</label><b>{el.Colecao || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>Ordem Producão:&nbsp;</label><b>{el.OrdemProducao || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>NF Saída:&nbsp;</label><b>{el.NFSaida || ' - '}</b></div>
                    </div>
                    <div className={styles.mainArrCol}>
                      <div><label className={styles.mainArrLabel}>Referência:&nbsp;</label><b>{el.Produto || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>Cor:&nbsp;</label><b>{el.CorProduto || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>Preço:&nbsp;</label><b>{el.Preco1?.toFixed(2) || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>Número da Análise:&nbsp;</label><b>{el.NumeroAnalise || ' - '}</b></div>
                    </div>
                    <div className={styles.mainArrCol}>
                      <div><label className={styles.mainArrLabel}>Qtde Entrada:&nbsp;</label><b>{el.QtdeEntrada || ' - '}</b></div>
                      <div><label className={styles.mainArrLabel}>Marca:&nbsp;</label><b>{el.Empresa || ' - '}</b></div>
                      <div>
                        <label className={styles.mainArrLabel}>Fornecedor:&nbsp;</label>
                        <br></br>
                        <b>{el.Origem || ' - '}</b>
                      </div>
                    </div>
                  </div>
                  <span
                    className={styles.nf_warn}
                    style={{
                      display: (
                        processoFiltrado?.value === 'revisaoChecklist' ||
                        processoFiltrado?.value === 'revisaoMaisRevisaoChecklist' ||
                        processoFiltrado?.value === 'statusPCP'
                      ) && (el.NFSaida && !el?.NotaCancelada) ? 'inline' : 'none'
                    }}
                  >
                    Atenção! A NFSaida de devolução dessa referência já foi emitida, não é mais possível fazer alterações no seu Status PCP ou no Checklist
                  </span>
                  <Accordion>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>Mostrar mais detalhes</Accordion.Header>
                      <Accordion.Body >
                        <div><label className={styles.mainArrLabel}>Status Revisao:&nbsp;</label><b>{el.StatusRevisao || ' - '}</b></div>
                        <div><label className={styles.mainArrLabel}>Status Checklist:&nbsp;</label><b>{el.StatusChecklist || ' - '}</b></div>
                        <div><label className={styles.mainArrLabel}>Data Entrada:&nbsp;</label><b>{toUTCDDMMYYYY(el.DataDigitacao) || ' - '}</b></div>
                        <div><label className={styles.mainArrLabel}>Tipo de Produto:&nbsp;</label><b>{el.TipoProduto || ' - '}</b></div>
                        <div><label className={styles.mainArrLabel}>Descrição da Referência:&nbsp;</label><b>{el.DescricaoProduto || ' - '}</b>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default MainGridDesfazer;