import React, { useState, useEffect } from "react";
import { Button, Form, Table, Modal } from 'react-bootstrap';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';

import styles from "../styles/EnvioPlotter.module.scss";
import { api }  from "../services/api";
import { toast, ToastContainer } from "react-toastify";
import { toDatetime } from "../utils/dateUtils";
import CookiesService from "../services/cookies";
import Carregando from "../components/Carregando";

const EnvioPlotter = () => {
  const [mainData, setMainData] = useState([]);
  const [showModalEnvio, setShowModalEnvio] = useState(false);
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [activeObj, setActiveObj] = useState({});
  const [formObj, setFormObj] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [loading, setLoading] = useState(false);
  const filterOpts = [
    { value: 1, label: 'Pendente Envio' },
    { value: 2, label: 'Pendente Confirmação Recebimento' }
  ];
  const userLogin = CookiesService.getUserLogin();

  const handleFilteredSearch = async () => {
    const params = {
      FilterOpt: filterObj.FilterOpt ? filterObj.FilterOpt.value : null,
      Material: filterObj.Material,
      CorMaterial: filterObj.CorMaterial,
      NFEntrada: filterObj.NFEntrada
    }
    setLoading(true);
    await api.get('/MPEnvioPlotter/GetMainData', {params})
      .then(res => {
        if (res.status === 200 && res.data.length > 0) setMainData(res.data.slice(0, 100))
        else if (res.status === 200 && res.data.length === 0) toast.warning('Não foram encontrados registors com esses parâmetros de filtro, tente novamente')
      })
      .catch(e => {
        console.error(`Erro na requisição '/GetMainData' - ${e}`);
        toast.error('Erro ao carregar os dados da página, por favor cheque sua conexão com a internet e/ou entre em contato com o suporte');
      });
      setLoading(false);
  }

  const handleShowModal = (el) => {
    if (el.STATUS && el.STATUS.trim() === 'PENDENTE ENVIO') setShowModalEnvio(true);
    else if (el.STATUS && el.STATUS.trim() === 'PENDENTE CONFIRMAÇÃO RECEBIMENTO') setShowModalConfirmacao(true);
    setActiveObj(el);
  }

  const handleCloseModal = () => {
    setShowModalConfirmacao(false);
    setShowModalEnvio(false);
    setFormObj({});
    setFormErrs({});
    setActiveObj({});
  }

  const handleFetchMainData = async () => {
    setLoading(true);
    await api.get('/MPEnvioPlotter/GetMainData')
      .then(res => { if (res.status === 200) setMainData(res.data.slice(0, 100)) })
      .catch(e => {
        console.error(`Erro na requisição '/GetMainData' - ${e}`);
        toast.error('Erro ao carregar os dados da página, por favor cheque sua conexão com a internet e/ou entre em contato com o suporte');
      });
      setLoading(false);
  }

  const handleEnvio = async () => {
    const errs = handeEnvioValidation();
    if (Object.keys(errs).length > 0) {
      setFormErrs({...errs});
    } else {
      const params = {
        DataRegistroEnvio: toDatetime(new Date()),
        Lacre: formObj.Lacre,
        Consideracoes: formObj.Consideracoes ? formObj.Consideracoes : null,
        UsuarioResponsavel: userLogin ? userLogin : 'TESTE.HOMOLOG',
        
        NFEntrada: activeObj.NFEntrada,
        SerieNF: activeObj.SerieNF,
        NomeClifor: activeObj.NomeClifor,
        Material: activeObj.Material,
        CorMaterial: activeObj.CorMaterial,
        QtdeEntrada: activeObj.QtdeEntrada
      }
      await api.post('/MPEnvioPlotter/PostConfirmaEnvioPlotter', params)
        .then(res => {
          if (res.status === 200) {
              toast.success('Dados alterados com sucesso!');
              handleCloseModal();
              setFilterObj({});
              handleFetchMainData();
          } else {
              toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
          }
        }).catch(e => {
            console.error(e);
            toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
        });
    }
  }

  const handeEnvioValidation = () => {
    const {Lacre} = formObj;
    const errs = {};
    if (!Lacre || Lacre === '') errs.Lacre = 'Por favor insira o número de lacre';
    // if (!Consideracoes) errs.Consideracoes = 'Por favor insira uma consideração';
    return errs;
  }

  const handleConfirmacao = async () => {
    const params = {
      DataRecebimento: toDatetime(new Date()),
      IDUsuarioRecebimento: userLogin ? userLogin : 'TESTE.HOMOLOG',
      
      NFEntrada: activeObj.NFEntrada,
      SerieNF: activeObj.SerieNF,
      NomeClifor: activeObj.NomeClifor,
      Material: activeObj.Material,
      CorMaterial: activeObj.CorMaterial,
      QtdeEntrada: activeObj.QtdeEntrada
    }
    await api.post('/MPEnvioPlotter/PostConfirmaRecebimentoPlotter', params)
      .then(res => {
        if (res.status === 200) {
            toast.success('Dados alterados com sucesso!');
            handleCloseModal();
            setFilterObj({});
            handleFetchMainData();
        } else {
            toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
      }).catch(e => {
          console.error(e);
          toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
      });
  }

  const handleValueMask = (field, value, filter = false) => {
    if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
    if (filter === true) setFilterObj({...filterObj, [field]: value}); 
    else setFormObj({...formObj, [field]: value});
  }

  useEffect(() => {
    handleFetchMainData();
  }, []);

  return (
    <div>
      <h3 className="m-3">Envio Plotter</h3>

      <article className={styles.articleMargim}>
        <Form className={styles.form}>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Filtrar por
            </Form.Label>
            <Select
              isClearable={true}
              escapeClearsValue={true}
              placeholder={'Selecionar'}
              options={filterOpts}
              value={filterObj.FilterOpt || null}
              onChange={e => setFilterObj({...filterObj, FilterOpt: e})}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Material
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Material"
              value={filterObj.Material || ''}
              // onChange={e => setFilterObj({...filterObj, Material: e.target.value})}
              onChange={e => handleValueMask('Material', e.target.value, true)}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Cor Material
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Cor Material"
              value={filterObj.CorMaterial || ''}
              // onChange={e => setFilterObj({...filterObj, CorMaterial: e.target.value})}
              onChange={e => handleValueMask('CorMaterial', e.target.value, true)}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              NF Entrada
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="NF Entrada"
              value={filterObj.NFEntrada || ''}
              // onChange={e => setFilterObj({...filterObj, NFEntrada: e.target.value})}
              onChange={e => handleValueMask('NFEntrada', e.target.value, true)}
            />
          </Form.Group>
          <Button 
            variant="primary"
            type="button"
            onClick={handleFilteredSearch}
          >
            Buscar                        
          </Button>
        </Form>
      </article> 

      <article className={styles.articleGray}>
        {
          loading ? <Carregando></Carregando> :
          <Table className="m-2" responsive>
            <thead>
              <tr>
                <th></th>
                <th className={styles.blockTitle}>DATA REGISTRO</th>
                <th className={styles.blockTitle}>NF ENTRADA</th>
                <th className={styles.blockTitle}>SERIE NF</th>
                <th className={styles.blockTitle}>FORNECEDOR</th>
                <th className={styles.blockTitle}>MATERIAL</th>  
                <th className={styles.blockTitle}>COR MATERIAL</th>                      
                <th className={styles.blockTitle}>QTDE ENTRADA</th>
                <th className={styles.blockTitle}>ITEM</th>
                <th className={styles.blockTitle}>DATA REGISTRO ENVIO</th>
                <th className={styles.blockTitle}>ID USUARIO ENVIO</th>
                <th className={styles.blockTitle}>LACRE</th>
                <th className={styles.blockTitle}>DATA RECEBIMENTO</th>
                <th className={styles.blockTitle}>ID USUARIO RECEBIMENTO</th>
              </tr>
            </thead>
            <tbody>
              {mainData.map((el, index) => {
                return (
                  <tr className={styles.tableLines} key={index}>
                    <td>
                      <i 
                        className={styles.iconCursor}
                        onClick={() => handleShowModal(el)}
                      >
                        <MaterialIcon
                          icon="task_alt"
                          size={30}
                          color='#0D6EFD'
                        />
                      </i>
                    </td>
                    <td className={styles.tableLines}>{el.DataRegistro}</td>
                    <td className={styles.tableLines}>{el.NFEntrada}</td>
                    <td className={styles.tableLines}>{el.SerieNF}</td>
                    <td className={styles.tableLines}>{el.NomeClifor}</td>
                    <td className={styles.tableLines}>{el.Material}</td>
                    <td className={styles.tableLines}>{el.CorMaterial}</td>
                    <td className={styles.tableLines}>{el.QtdeEntrada}</td>
                    <td className={styles.tableLines}>{el.Item}</td>
                    <td className={styles.tableLines}>{el.DataRegistroEnvio}</td>
                    <td className={styles.tableLines}>{el.IDUsuarioEnvio}</td>
                    <td className={styles.tableLines}>{el.Lacre}</td>
                    <td className={styles.tableLines}>{el.DataRecebimento}</td>
                    <td className={styles.tableLines}>{el.IDUsuarioRecebimento}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        }
      </article>

      {/* Filtrar por == "Pendente Envio" */}
      <Modal
        show={showModalEnvio} 
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>Confirmar Envio Plotter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Informações sobre o material */}
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>NF Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Material:</span>
                <span className={styles.fontBlue}>{activeObj.Material}</span>
              </div>
            </div>

            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Série:</span>
                <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Cor Material:</span>
                <span className={styles.fontBlue}>{activeObj.CorMaterial}</span>
              </div>
            </div>

            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Fornecedor:</span>
                <span className={styles.fontBlue}>{activeObj.NomeClifor}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Qtde Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
              </div>
            </div>

            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Item:</span>
                <span className={styles.fontBlue}>{activeObj.Item}</span>
              </div>
            </div>
          </article>

          {/* Formulário de inserção */}
          <article className="mt-3">
            <Form>
                <Form.Group className={styles.formGroupModal} controlId={'Lacre'}>
                  <Form.Label className={styles.formLabel}>Lacre:</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={formObj.Lacre || ''}
                    onClick={() => setFormErrs({...formErrs, Lacre: null})}
                    onChange={e => handleValueMask('Lacre', e.target.value)}
                    isInvalid={formErrs.Lacre}
                />
                <Form.Control.Feedback type='invalid'>{formErrs.Lacre}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={styles.formGroupModal}>
                  <Form.Label className={styles.formLabel}>
                    Considerações: <span className={styles.spanOpcional}>(Opcional)</span>
                  </Form.Label>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    type="text"
                    value={formObj.Consideracoes || ''}
                    onChange={e => setFormObj({...formObj, Consideracoes: e.target.value})}
                  />
                </Form.Group>
            </Form>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button 
            className={styles.iconCursor}
            variant="outline-danger"
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          <Button 
            className={styles.iconCursor}
            variant="primary"
            onClick={handleEnvio}
          >
            Confirmar Envio
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Filtrar por == "Pendente Confirmação Recebimento" */}
      <Modal
        show={showModalConfirmacao} 
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>Confirmar Recebimento Plotter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Informações sobre o material */}
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>NF Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Material:</span>
                <span className={styles.fontBlue}>{activeObj.Material}</span>
              </div>
            </div>

            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Série:</span>
                <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Cor Material:</span>
                <span className={styles.fontBlue}>{activeObj.CorMaterial}</span>
              </div>
            </div>

            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Fornecedor:</span>
                <span className={styles.fontBlue}>{activeObj.NomeClifor}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Qtde Entrada:</span>
                <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
              </div>
            </div>

            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Item:</span>
                <span className={styles.fontBlue}>{activeObj.Item}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Lacre:</span>
                <span className={styles.fontBlue}>{activeObj.Lacre}</span>
              </div>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button 
            className={styles.iconCursor}
            variant="outline-danger"
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          <Button 
            className={styles.iconCursor}
            variant="primary"
            onClick={handleConfirmacao}
          >
            Confirmar Recebimento
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default EnvioPlotter;
