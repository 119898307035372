import React, { useState } from 'react';
import Filtro from '../../Misc/Filtro/Filtro';
import Tabela from '../../Misc/Tabela/Tabela';
import { Button } from 'react-bootstrap';
import { usePARevisaoSegundaQualidadeNV } from './pa_revisao_sq_nv_context';
import PARevisaoSegundaQualidadeNVDirecionamento from './components/pa_revisao_sq_nv_modal_direcionamento';
import PARevisaoSegundaQualidadeNVGrade from './components/pa_revisao_sq_nv_modal_grade';
import PAAjusteSegundaQualidadeGrade from './components/pa_ajuste_sq_modal_grade';
import PARevisaoSQNVModalProcessamento from './components/pa_revisao_sq_nv_modal_processamento';
import styles from './pa_revisao_sq_nv.module.scss';

const PARevisaoSegundaQualidadeNVMain = () => {
  const { fetchRevisaoSQNVGrid, fetchAjusteSQGrid, listRevisao, listAjuste, iniciarRevisaoSQNV, iniciarAjusteSQ, modalRevisao, modalAjuste, modalDirecionamento, modalFinalizacao } = usePARevisaoSegundaQualidadeNV();
  const [modoAjuste, setModoAjuste] = useState(false);

  const checklistColumns = [
    {
      cell: row => {
        return (
          <Button
            style={{ fontWeight: '500' }}
            variant={modoAjuste ? 'warning' : 'primary' }
            onClick={() => modoAjuste ? iniciarAjusteSQ(row) : iniciarRevisaoSQNV(row)}
          >
            {modoAjuste ? 'Ajustar' : 'Revisão'}
          </Button>
        )
      }
    },
    { name: 'NF Entrada', selector: row => row.nf_entrada },
    { name: 'Serie NF', selector: row => row.serie_nf },
    { name: 'Origem', selector: row => row.origem },
    { name: 'Filial Revisão', selector: row => row.filial_revisao },
    { name: 'Produto', selector: row => row.produto },
    { name: 'Desc. Produto', selector: row => row.descricao_produto }, 
    { name: 'Cor', selector: row => row.cor_produto },
    { name: 'Desc. Cor', selector: row => row.descricao_cor },
    { name: 'Qtde.', selector: row => row.qtde_entrada },
    { name: 'Ordem Produção', selector: row => row.ordem_producao },
    { name: 'Coleção', selector: row => row.colecao },
    { name: 'Linha', selector: row => row.linha },
    { name: 'Preço', selector: row => row.preco },
    { name: 'Data Entrada Revisão', selector: row => row.data_entrada_revisao }
  ];

  return (
    <>
      <section className={styles.flex_title}>
        <h4 className={styles.title}>
          Revisão Segunda Qualidade Nati Vozza
        </h4>
        {modoAjuste ? <h4 className={styles.title}>&nbsp;- Modo Ajuste</h4> : null}
      </section>
      <section style={{ width: '100%' }}>
        <Filtro
          activeFilters={[{ key: 'nf_entrada' }, { key: 'produto_AN' }, { key: 'cor_produto_AN' }]}
          btnVariant={modoAjuste ? 'warning' : 'primary' }
          searchFn={modoAjuste ? fetchAjusteSQGrid : fetchRevisaoSQNVGrid}
        >
          <Button
            style={{ marginLeft: 'auto', alignSelf: 'flex-end', fontWeight: '500' }}
            variant={modoAjuste ? 'warning' : 'outline-warning'}
            onClick={() => setModoAjuste(!modoAjuste)}
          >
            {modoAjuste ? 'Desativar Modo Ajuste' : 'Ativar Modo Ajuste'}
          </Button>
        </Filtro>
      </section>
      <section style={{ margin: '0px 20px 20px' }}>
        <Tabela
          backgroundColor={modoAjuste ? '#ffc107' : '#527ac5'}
          color={modoAjuste ? '#000000' : '#ffffff'}
          columns={checklistColumns}
          data={modoAjuste ? listAjuste : listRevisao}
          pending={false}
          paginationPerPage={20}
          highlightOnHover={true}
        />
      </section>

      {modalDirecionamento && <PARevisaoSegundaQualidadeNVDirecionamento />}
      {modalRevisao && <PARevisaoSegundaQualidadeNVGrade />}
      {modalAjuste && <PAAjusteSegundaQualidadeGrade />}
      {modalFinalizacao && <PARevisaoSQNVModalProcessamento />}
    </>
  )
}

export default PARevisaoSegundaQualidadeNVMain;
