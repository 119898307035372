import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import { toast, ToastContainer } from "react-toastify";
import Carregando from "../../../Carregando";

import "react-toastify/dist/ReactToastify.min.css";
import styles from '../../../../styles/MonitorChecklistFinalizacao.module.scss';

import { api, retryApi } from "../../../../services/api";
import { toUTCDDMMYYYY } from "../../../../utils/dateUtils"
import MedidasProducao from "../../MedidasProducao";
import CookiesService from "../../../../services/cookies";

const MonitorChecklistFinalizacao = (props) => {
  const { precoIncorreto, setPrecoIncorreto } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [statusCQPA, setStatusCQPA] = useState([]);
  const [formObj, setFormObj] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [objInfoRevisao, setObjInfoRevisao] = useState([]);
  const [gradeDirecionamentoQualidade, setGradeDirecionamentoQualidade] = useState([]);
  const [listDestinos, setListDestinos] = useState([]);
  const [qtdPecas, setQtdPecas] = useState(0);
  const [destinoSelecionado, setDestinoSelecionado] = useState('');
  const [obs, setObs] = useState('');
  const [itemGradeDefeitoSelecionado, setItemGradeDefeitoSelecionado] = useState({});
  const [gradeDestino, setGradeDestino] = useState([]);
  const [gradeResumo2QualidadeAnalise, setGradeResumo2QualidadeAnalise] = useState([]);
  const [gradeResumo2QualidadeDirecionamento, setGradeResumo2QualidadeDirecionamento] = useState([]);
  const [gradeAnaliseQualidadeTotalAprovada, setGradeAnaliseQualidadeTotalAprovada] = useState(props.gradeAnaliseQualidadeTotalAprovada);
  const [habilitaAreaAprovacao, setHabilitaAreaAprovacao] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [listaMotivos, setListaMotivos] = useState([]);
  const userLogin = CookiesService.getUserLogin();

  //Modal Medidas Produção
  const [showMedidasProducao, setShowMedidasProducao] = useState(false);
  const handleCloseMedidasProducao = () => setShowMedidasProducao(false);
  const handleShowMedidasProducao = () => setShowMedidasProducao(true);

  const listRef = useRef();

  useEffect(() => {
    carregaFiltros();
    InfosRevisao();
  }, []);

  async function carregaFiltros() {
    listaStatusCQPA();
    listaDestinos();
    getGradeEncaminhamento();
    listaMotivoEnvio();
  }

  const InfosRevisao = async () => {
    try {
      const params = {
        Produto: props.item.Produto,
        CorProduto: props.item.CorProduto,
        NFEntrada: props.item.NFEntrada,
        SerieNF: props.item.SerieNF,
        Origem: props.item.Origem,
        OrdemProducao: props.item.OrdemProducao,
        QtdeEntrada: props.item.QtdeEntrada,
        FilialRevisao: props.item.FilialRevisao,
        IndicaPendenciaRevisao: props.item.IndicaPendenciaRevisao,
        IndicaPendenciaChecklist: props.item.IndicaPendenciaChecklist
      };

      const resInfo = await retryApi('get', "/PAMonitorCheckList/InfosRevisao", { params });
      const resDadosAnalise = await retryApi('get', "/PAMonitorCheckList/DadosAnalise", { params });
      const resSegundaQualidade = await retryApi('get', "/PAMonitorCheckList/DadosSegundaQualidade", { params });
      setObjInfoRevisao(resInfo.data)
      setGradeResumo2QualidadeAnalise(resDadosAnalise.data)
      setGradeResumo2QualidadeDirecionamento(resSegundaQualidade.data)
    } catch (e) {
      toast.error(`Ocorreu um  erro ao carregar as informações das listas`);
    }
  }

  async function listaMotivoEnvio() {
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/GetMotivoEnvioList");
      setListaMotivos(lista.data);
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de motivos de envio!');
      return false;
    }
  }

  async function listaStatusCQPA() {
    try {
      const listaStatus = await api.get("PAMonitorCheckList/ListarStatusCQPA");
      setStatusCQPA(listaStatus.data);
    } catch (e) {
      toast.error('Ocorreu um erro para carregar lista de status!');
    }
  }

  async function listaDestinos() {
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/ListaDestino");
      if (lista.data.length > 0) {
        const listaRes = lista.data.map((item, index) => {
          return { value: item.IDDestino, label: item.Destino };
        });
        setListDestinos(listaRes);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de destinos!');
      return false;
    }
  }

  async function getGradeEncaminhamento() {
    try {
      const { Produto, CorProduto, NFEntrada, SerieNF, Origem, OrdemProducao, QtdeEntrada, FilialRevisao } = props.item;
      const params = {
        Produto: Produto,
        CorProduto: CorProduto,
        NFEntrada: NFEntrada,
        SerieNF: SerieNF,
        Origem: Origem,
        OrdemProducao: OrdemProducao,
        QtdeEntrada: QtdeEntrada,
        FilialRevisao: FilialRevisao,
        TipoAnalise: '01 - PRODUÇÃO'
      };
      const lista = await retryApi('get', "PAMonitorCheckList/ListaDirecionamento", { params });
      setGradeDirecionamentoQualidade(lista.data);
    } catch (error) {
      toast.error('Ocorreu um erro para carregar a grade!');
      return false;
    }
  }

  const clearErrs = (val) => {
    if (val) return setFormErrs({ ...formErrs, [val]: null });
  }

  const handleIncluiAvaliacao = async () => {
    if (qtdPecas > itemGradeDefeitoSelecionado.Qtde_Pendente) {
      toast.error(`A quatidade ${qtdPecas} não pode ser maior que a quantidade pendente de ${itemGradeDefeitoSelecionado.Qtde_Pendente}`);
      return false;
    }

    const itemParaInserir = {
      tamanho: itemGradeDefeitoSelecionado.Tamanho,
      destino: destinoSelecionado,
      qtdPecas: qtdPecas,
      obs: obs,
      tipoAnalise: '01 - PRODUÇÃO'
    }
    if (destinoSelecionado == null) {
      toast.error('Você deve informar a grade, destino e quantidade.');
      return false;
    }
    if (itemGradeDefeitoSelecionado.Tamanho == null || destinoSelecionado.value == null ||
      qtdPecas == null
    ) {
      toast.error('Você deve informar a grade, destino e quantidade.');
      return false;
    }
    let grade = [];
    grade = grade.concat(gradeDestino);
    grade.push(itemParaInserir);
    setGradeDestino(grade);

    //altera quantidade pedente do grid recebido
    gradeDirecionamentoQualidade.map((itemSelecionado, index) => {
      if (itemParaInserir.tamanho === itemSelecionado.Tamanho) {
        itemSelecionado.QtdeDirecionada = parseInt(itemSelecionado.QtdeDirecionada) + parseInt(itemParaInserir.qtdPecas)
        itemSelecionado.Qtde_Pendente = parseInt(itemSelecionado.Qtde) - itemSelecionado.QtdeDirecionada
      }
    });
    setQtdPecas(0);
    setObs('');
    setItemGradeDefeitoSelecionado({});
    listRef.current.clearValue();
  }

  const handleExcluiAvaliacao = async (itemParaExcluir) => {
    gradeDirecionamentoQualidade.map((itemSelecionado, index) => {
      if (itemParaExcluir.tamanho === itemSelecionado.Tamanho) {
        itemSelecionado.QtdeDirecionada = parseInt(itemSelecionado.QtdeDirecionada) - parseInt(itemParaExcluir.qtdPecas)
        itemSelecionado.Qtde_Pendente = parseInt(itemSelecionado.Qtde) - itemSelecionado.QtdeDirecionada
      }
    });
    setGradeDestino(gradeDestino.filter(el => el !== itemParaExcluir));
  }

  const handleValidation = () => {
    const { statusCQPASelecionado, EnviaAprovacao, AreaAprovacao, IndicaCulpabilidade, motivoEnvio } = formObj;

    if (EnviaAprovacao === null || EnviaAprovacao === undefined) return toast.warn("Atenção! Favor informar se Produto será enviado ou não para aprovação.");
    if (EnviaAprovacao === true && (AreaAprovacao === null || AreaAprovacao === undefined)) return toast.warn("Atenção! Selecione a área de envio para aprovação.");
    if (EnviaAprovacao === true && !motivoEnvio) return toast.warn("Atenção! Selecione o motivo do envio para aprovação.");
    if (!statusCQPASelecionado && EnviaAprovacao !== true) return toast.warn("Atenção! Favor informar o STATUS CQ antes de continuar");

    const QtdePendenteDirecionamento = gradeDirecionamentoQualidade.reduce((prevValue, objeto) => prevValue + objeto.Qtde_Pendente, 0);
    if (QtdePendenteDirecionamento > 0 && EnviaAprovacao === false) {
      return toast.warn("Atenção! Favor informar o direcionamento das peças antes de continuar.");
    }

    // VERIFICAÇÃO DEVOLUÇÃO
    const QtdePecasDevolucao = gradeDestino.filter((item) => (item.destino.value == '4' || item.destino.value == '41')).reduce((prevValue, objeto) => prevValue + parseInt(objeto.qtdPecas), 0);

    if (QtdePecasDevolucao == 0 && (statusCQPASelecionado == 6 || statusCQPASelecionado == 7) && EnviaAprovacao === false) {
      return toast.warn("Atenção! Não existem peças direcionadas para a devolução, favor corrigir o status e/ou direcionamento das peças.");
    }
    if (QtdePecasDevolucao != props.item.QtdeEntrada && statusCQPASelecionado == 6 && EnviaAprovacao === false) {
      return toast.warn(`Atenção! O total de peças direcionados à devolução ou devolução + checklist (${QtdePecasDevolucao}), é diferente do total de peças recebidas (${props.item.QtdeEntrada}). Favor corrigir o status e/ou direcionamento das peças.`);
    }
    if (QtdePecasDevolucao > 0 && (statusCQPASelecionado != 6 && statusCQPASelecionado != 7) && EnviaAprovacao === false) {
      return toast.warn(`Atenção! Existem peças direcionadas para a Devolução, Favor corrigir o Status CQ.`);
    }
    if (IndicaCulpabilidade == null) return toast.warn(`Atenção! Favor informar se produção possui culpabilidade do Fornecedor.`);
    return true;
  }

  useEffect(() => {
    setProcessing(false)
  }, []);

  const handleProcess = async (e) => {
    e.preventDefault();
    try {
      if (handleValidation() !== true) return setProcessing(false);
      const params = {
        idArea: '3',
        produto: props.item,
        Responsavel: props.responsavelAnaliseSelecionado,
        StatusAnalise: props.statusAnaliseSelecionada.value,
        qtdPecasAmostra: props.qtdPecasAmostra,
        identificacao: props.identificacao,
        user: userLogin,
        GradeDefeitos: gradeAnaliseQualidadeTotalAprovada,
        GradeDevolucao: gradeDestino,
        StatusCQPA: formObj.statusCQPASelecionado,
        EnviaAprovacao: formObj.EnviaAprovacao,
        idMotivoEnvio: formObj.motivoEnvio,
        PendenciarDirecionamento: formObj.EnviaAprovacao, // Só são pendenciados direcionamentos que são enviados para aprovação;
        AreaAprovacao: formObj.AreaAprovacao,
        IndicaCulpabilidade: formObj.IndicaCulpabilidade,
        Consideracoes: formObj.Consideracoes ? formObj.Consideracoes : '',
        totalPecasDevolucao: gradeDestino.filter((item) => item.destino.value == '4').reduce((prevValue, objeto) => prevValue + parseInt(objeto.qtdPecas), 0),
        observacaoEspecial: handleChecarObservacaoEspecial()
      }

      await retryApi('post', "/PAMonitorCheckList/FinalizarProcesso", params);
      toast.success('Dados alterados com sucesso!');
      props.handleClose();
      window.location.href = "/PA/monitorchecklist";
    } catch (e) {
      console.error(e);
      toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
    }
    setProcessing(false);
  }

  const handleExcluiGradeConsultaDefeitos = (itemParaExcluir) => {
    const grade = gradeAnaliseQualidadeTotalAprovada.filter(el => el.Tamanho !== itemParaExcluir.Tamanho);
    setGradeAnaliseQualidadeTotalAprovada([]);
    setTimeout(() => {
      setGradeAnaliseQualidadeTotalAprovada(grade);
      toast.success('Grade removida.');
    }, 1000
    )
  }

  const handleExcluiItemConsultaDefeitos = async (itemParaExcluir) => {

    let gradeTotal = [];
    gradeAnaliseQualidadeTotalAprovada.forEach((row, index) => {
      if (row.Tamanho == itemParaExcluir.tamanho) {
        let gradeAux = row.grade.filter((item, index) => {
          return item !== itemParaExcluir
        }
        );

        row.grade = gradeAux;
        if (row.grade.length == 0) {
          row.QtdeDirecionada = 0;
        }
      }
      gradeTotal.push(row);
    });

    setGradeAnaliseQualidadeTotalAprovada(gradeTotal)
  }

  const handleChecarObservacaoEspecial = () => {
    let observacaoEspecial = ``;
    if (precoIncorreto) observacaoEspecial = observacaoEspecial.concat(`\nEtiqueta possuía o seguinte valor incorreto: R$ ${precoIncorreto}.`);

    if (observacaoEspecial.length > 0) { observacaoEspecial = observacaoEspecial.concat(`\n`); return observacaoEspecial }
    else return null;
  }

  function desabilitaStatus60(value) {
    if (value === 60 && props.item.FilialRevisao.trim() !== 'CONTROLE QUALIDADE ATAC') return true;
    return false;  
  }

  return (
    <div>
      <h2 className="m-3">Monitor Checklist PA - Finalização</h2>

      <article>
        <div className={styles.articleForm}>
          <Form className={styles.form}>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabelGreen}>Envia Aprovação:</Form.Label>
              <Form.Check
                name={'EnviaAprovacao'}
                id="EnviaAprovacaoSim"
                type={'radio'}
                label="Sim"
                onChange={() => { setFormObj({ ...formObj, EnviaAprovacao: true, statusCQPASelecionado: null }); setHabilitaAreaAprovacao(true); clearErrs('EnviaAprovacao') }}
              />
              <Form.Check
                name={'EnviaAprovacao'}
                id="EnviaAprovacaoNao"
                type={'radio'}
                label="Não"
                onChange={() => { setFormObj({ ...formObj, EnviaAprovacao: false, AreaAprovacao: '', motivoEnvio: null }); setHabilitaAreaAprovacao(false); clearErrs('EnviaAprovacao') }}
              />
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabelGreen}>Área Aprovação:</Form.Label>
              <Form.Check
                name={'AreaAprovacao'}
                type={'radio'}
                id="AreaAprovacaoSim"
                label="Estilo"
                disabled={!habilitaAreaAprovacao}
                checked={habilitaAreaAprovacao && formObj.AreaAprovacao == 'Estilo'}
                onChange={() => { setFormObj({ ...formObj, AreaAprovacao: "Estilo" }); clearErrs('AreaAprovacao') }}
              />
              <Form.Check
                name={'AreaAprovacao'}
                type={'radio'}
                id="AreaAprovacaoNao"
                label="Produtos"
                disabled={!habilitaAreaAprovacao}
                checked={habilitaAreaAprovacao && formObj.AreaAprovacao == 'Produtos'}
                onChange={() => { setFormObj({ ...formObj, AreaAprovacao: "Produtos" }); clearErrs('AreaAprovacao') }}
              />
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Motivo Envio:</Form.Label>
              <Select
                name={'MotivoEnvio'}
                options={listaMotivos}
                value={listaMotivos.find(motivo => motivo?.value === formObj.motivoEnvio) || null}
                onChange={e => setFormObj({ ...formObj, motivoEnvio: e?.value || null })}
                escapeClearsValue={true}
                isClearable={true}
                isDisabled={!habilitaAreaAprovacao}
              />
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Status CQ PA:</Form.Label>
              <Select
                name={'statusCQPA'}
                options={statusCQPA}
                value={statusCQPA.find(status => status?.value === formObj.statusCQPASelecionado) || null}
                onChange={e => setFormObj({ ...formObj, statusCQPASelecionado: e?.value || null })}
                escapeClearsValue={true}
                isClearable={true}
                isDisabled={formObj.EnviaAprovacao === true}
                isOptionDisabled={opt => desabilitaStatus60(opt.value)}
              />
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>
                Considerações
                <span className={styles.spanOpcional}>(Opcional)</span>
                :
              </Form.Label>
              <Form.Control
                className={styles.formControl}
                as="textarea"
                rows={2}
                onChange={e => setFormObj({ ...formObj, Consideracoes: e.target.value })}
              >
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        <div className={styles.articleForm}>
          <Form className={styles.form}>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Data da Análise:</Form.Label>
              <Form.Label>{toUTCDDMMYYYY(objInfoRevisao[0]?.DataRevisao)}</Form.Label>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Peças Amostra:</Form.Label>
              <Form.Label>{objInfoRevisao[0]?.QtdePecasAmostraRev}</Form.Label>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Responsável Análise:</Form.Label>
              <Form.Label>{objInfoRevisao[0]?.NomeFuncionario}</Form.Label>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Status Revisão:</Form.Label>
              <Form.Label className={styles.formLabelBlue}>{objInfoRevisao[0]?.StatusRevisao}</Form.Label>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Status Checklist:</Form.Label>
              <Form.Label className={styles.formLabelBlue}>{props?.statusAnaliseSelecionada?.label}</Form.Label>
            </Form.Group>
          </Form>
        </div>
      </article>

      {/* Destino Produção */}
      <article>
        <h5 className={styles.titleMargin}>Destino Produção</h5>
        <div className={styles.articleForm}>
          <Form className={styles.form}>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabelGreen}>Indica Culpabilidade:</Form.Label>
              <Form.Check
                name={'IndicaCulpabilidade'}
                id="IndicaCulpabilidadeSim"
                type={'radio'}
                label="Sim"
                onChange={() => { setFormObj({ ...formObj, IndicaCulpabilidade: true }); clearErrs('IndicaCulpabilidade') }}
              />

              <Form.Check
                name={'IndicaCulpabilidade'}
                id="IndicaCulpabilidadeNao"
                type={'radio'}
                label="Não"
                onChange={() => { setFormObj({ ...formObj, IndicaCulpabilidade: false }); clearErrs('IndicaCulpabilidade') }}
              />

            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabelGreen}>Peças Pendente de Direcionamento:</Form.Label>
              <Form.Label>{gradeDirecionamentoQualidade.reduce((prevValue, objeto) => prevValue + objeto.Qtde_Pendente, 0)}</Form.Label>
            </Form.Group>
          </Form>
        </div>
      </article>

      {/* Incluir Grade */}
      <article className={styles.articleGray} style={{ display: formObj.EnviaAprovacao === true ? 'none' : 'block' }}>
        <div className={styles.articleFlex}>
          <div style={{ "marginTop": "20px" }}>
            <Table className="m-2" responsive='sm'>
              <thead>
                <tr>
                  <th className={styles.blockTitle}></th>
                  <th className={styles.blockTitle}>Tamanho</th>
                  <th className={styles.blockTitle}>Qtde Recebida</th>
                  <th className={styles.blockTitle}>Qtde Direcionada</th>
                  <th className={styles.blockTitle}>Qtde Pendente</th>
                </tr>
              </thead>
              <tbody>
                {gradeDirecionamentoQualidade.map((item, index) => {
                  return (
                    <tr key={`itemGrade_` + item.Tamanho}
                      onClick={() => { setItemGradeDefeitoSelecionado(item) }}>
                      <td className={styles.tableLines}>
                        <Form.Check
                          id={`itemGrade_` + item.Tamanho}
                          key={`itemGrade_` + item.Tamanho}
                          type={'radio'}
                          name={'gradeQualidade'}
                          value={item.Tamanho}
                          onChange={() => { setItemGradeDefeitoSelecionado(item) }}
                          checked={itemGradeDefeitoSelecionado === item}
                          disabled={formObj.EnviaAprovacao === true}
                        />
                      </td>
                      <td className={styles.tableLine}>
                        {item.Tamanho}
                      </td>
                      <td className={styles.tableLine}>
                        {item.Qtde}
                      </td>
                      <td className={styles.tableLine}>
                        {item.QtdeDirecionada}
                      </td>
                      <td className={styles.tableLine}>
                        {item.Qtde_Pendente}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div style={{ "marginLeft": "10px" }}>
            <div className={styles.articleForm}>
              <Form className={styles.articleForm}>
                <Form.Group className={styles.formGroup}>
                  <Form.Label className={styles.formLabel}>Qtde Peças:</Form.Label>
                  <Form.Control className={styles.formControl}
                    type="number"
                    value={qtdPecas}
                    onChange={(e) => setQtdPecas(e.target.value)}
                    disabled={formObj.EnviaAprovacao === true}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className={styles.formGroup}>
                  <Form.Label className={styles.formLabel}>Destino:</Form.Label>
                  <Select
                    name={'statusCQPA'}
                    options={listDestinos}
                    ref={listRef}
                    onChange={e => setDestinoSelecionado(e)}
                    isDisabled={formObj.EnviaAprovacao === true}
                  />
                </Form.Group>
              </Form>

              <Form className={styles.articleForm}>
                <Form.Group className={styles.formGroup}>
                  <Form.Label className={styles.formLabel}>
                    Observação
                    <span className={styles.spanOpcional}>(Opcional)</span>
                    :
                  </Form.Label>
                  <Form.Control
                    className={styles.formControl}
                    as="textarea"
                    rows={2}
                    value={obs}
                    onChange={(e) => { setObs(e.target.value) }}
                    disabled={formObj.EnviaAprovacao === true}
                  >
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>

        <div className={styles.btnRightSide}>
          <Button
            variant="success"
            className={styles.btnWidth}
            onClick={handleIncluiAvaliacao}
            disabled={formObj.EnviaAprovacao === true}
          >
            Incluir
          </Button>
        </div>
      </article>

      {/* Dados Incluídos */}
      <article className={styles.articleFlex} style={{ display: formObj.EnviaAprovacao === true ? 'none' : 'block' }}>
        <div style={{ "marginTop": "15px", "width": "50%" }}>
          <Table className="m-2" responsive='sm'>
            <thead>
              <tr>
                <th className={styles.blockTitle}></th>
                <th className={styles.blockTitle}>Grade</th>
                <th className={styles.blockTitle}>Tipo Análise</th>
                <th className={styles.blockTitle}>Destino</th>
                <th className={styles.blockTitle}>Qtde Peças</th>
                <th className={styles.blockTitle}>Observação</th>
              </tr>
            </thead>
            <tbody>
              {gradeDestino?.map((item, index) => {
                return (
                  <tr key={`rowGradeDestino_${index}`}>
                    <td className="table-linhas">
                      <Button
                        title="Remover da lista"
                        variant="danger"
                        onClick={() => handleExcluiAvaliacao(item)}
                      >
                        <i>
                          <MaterialIcon
                            icon="delete"
                            size={20}
                            color='#FFFF'

                          />
                        </i>
                      </Button>
                    </td>
                    <td className={styles.tableLines}>{item.tamanho}</td>
                    <td className={styles.tableLines}>{item.tipoAnalise}</td>
                    <td className={styles.tableLines}>{item.destino.label}</td>
                    <td className={styles.tableLines}>{item.qtdPecas}</td>
                    <td className={styles.tableLines}>{item.obs}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div style={{ "width": "50%" }}>
          <Form className={styles.form}>
            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>Total de Peças para Devolução:</Form.Label>
              <Form.Label>{gradeDestino.filter((item) => item.destino.value == '4').reduce((prevValue, objeto) => prevValue + parseInt(objeto.qtdPecas), 0)}</Form.Label>
            </Form.Group>
          </Form>
        </div>
      </article>

      {/* Resumo */}
      <article style={{ "margin": "20px 20px 100px 20px" }}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Resumo Segunda Qualidade</Accordion.Header>
            <Accordion.Body>
              <div>
                <div className={styles.accordionFlex}>
                  <div>
                    <h5 className={styles.resumeTitleMargin}>Grade Analisada</h5>
                    <Table className="m-2" responsive="sm">
                      <thead>
                        <tr>
                          <th className={styles.blockTitle}>SEQ.</th>
                          <th className={styles.blockTitle}>GRADE</th>
                          <th className={styles.blockTitle}>PEÇAS DEFEITO PRO</th>
                          <th className={styles.blockTitle}>PEÇAS DEFEITO 2Q</th>
                        </tr>
                      </thead>
                      <tbody> {objInfoRevisao?.map((item, index) => {
                        return (
                          <tr key={`rowResumoRevisao` + index}>
                            <td className={styles.tableLines}>{index}</td>
                            <td className={styles.tableLines}>{item.DescricaoGrade}</td>
                            <td className={styles.tableLines}>{item.QtdePecasDefeitoProd}</td>
                            <td className={styles.tableLines}>{item.QtdePecasDefeito2Q}</td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </Table>
                  </div>

                  <div>
                    <h5 className={styles.resumeTitleMargin}>Dados Análise</h5>
                    <Table className="m-2" responsive>
                      <thead>
                        <tr>
                          <td className={styles.tableLines}>SEQ.</td>
                          <th className={styles.blockTitle}>GRADE</th>
                          <th className={styles.blockTitle}>GRUPO ANALISADO</th>
                          <th className={styles.blockTitle}>STATUS ANÁLISE</th>
                          <th className={styles.blockTitle}>DEFEITO ENCONTRADO</th>
                          <th className={styles.blockTitle}>LOCALIZAÇÃO DEIFEITO</th>
                          <th className={styles.blockTitle}>ÁREA MAIS PRÓXIMA</th>
                          <th className={styles.blockTitle}>CONSIDERAÇÃO</th>
                          <th className={styles.blockTitle}>TIPO DE ANÁLISE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradeResumo2QualidadeAnalise?.map((item, index) => {
                          return (
                            <tr key={`rowResumoRevisao2Qualidade` + index}>
                              <td className={styles.tableLines}>{index}</td>
                              <td className={styles.tableLines}>{item.DescricaoGrade}</td>
                              <td className={styles.tableLines}>{item.DescricaoGrupoAnalise}</td>
                              <td className={styles.tableLines}>{item.DescricaoStatus}</td>
                              <td className={styles.tableLines}>{item.DescricaoDefeito}</td>
                              <td className={styles.tableLines}>{item.DescricaoLocalizacao}</td>
                              <td className={styles.tableLines}>{item.DetalhamentoLocalizacaoDefeito}</td>
                              <td className={styles.tableLines}>{item.Consideracao}</td>
                              <td className={styles.tableLines}>{item.TipoAnalise}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>

                <div>
                  <div className={styles.divFormResumoSQ} style={{ "margin": "20px 30px 20px 20px" }}>
                    <h5 style={{ "marginRight": "20px", "fontWeight": "bold" }}>Direcionamento Segunda Qualidade</h5>
                    <Form className={styles.formResumoSQ}>
                      <Form.Group className={styles.formGroupResumoSQ}>
                        <Form.Label>TT Recebido 2Q:</Form.Label>
                        <Form.Label className={styles.formLabelResumoSQ}>{gradeResumo2QualidadeDirecionamento.reduce((prevValue, objeto) => prevValue + objeto.QtdePecaRecebidas2Q, 0)}</Form.Label>
                      </Form.Group>
                      <Form.Group className={styles.formGroupResumoSQ}>
                        <Form.Label>TT Direcionado 2Q:</Form.Label>
                        <Form.Label className={styles.formLabelResumoSQ}>{gradeResumo2QualidadeDirecionamento.reduce((prevValue, objeto) => prevValue + objeto.QtdePecaDirecionadas2Q, 0)}</Form.Label>
                      </Form.Group>
                    </Form>
                  </div>

                  <div>
                    <Table className="m-2" responsive>
                      <thead>
                        <tr>
                          <th className={styles.blockTitle}>GRADE</th>
                          <th className={styles.blockTitle}>TIPO ANÁLISE</th>
                          <th className={styles.blockTitle}>DESTINO</th>
                          <th className={styles.blockTitle}>QTDE PEÇAS</th>
                          <th className={styles.blockTitle}>OBSERVAÇÃO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradeResumo2QualidadeDirecionamento?.map((item, index) => {
                          return (
                            <tr key={`rowResumoRevisao2QualidadeDirecionamento` + index}>
                              <td className={styles.tableLines}>{item.Tamanho}</td>
                              <td className={styles.tableLines}>{item.TipoAnalise}</td>
                              <td className={styles.tableLines}>{item.DescricaoDestino}</td>
                              <td className={styles.tableLines}>{item.Qtde_Recebida}</td>
                              <td className={styles.tableLines}>{item.Observacao}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </article>

      {/* Footer */}
      <article className={styles.footer}>
        <Button
          className={styles.btnFooter}
          variant="outline-success"
          onClick={handleShow}
        >
          Consultar Avaliações
        </Button>
        <Button
          className={styles.btnFooter}
          variant="outline-primary"
          onClick={handleShowMedidasProducao}
        >
          Medidas Produção
        </Button>
        <Button
          className={styles.btnFooter}
          variant="primary"
          onClick={e => { setProcessing(true); handleProcess(e); }}
        >
          Processar
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          fullscreen={true}
          keyboard={false}
          //dialogClassName={styles.widthModal}
          //contentClassName={styles.widthModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Consultar Avaliações</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <h5 className={styles.fontBold}>Resumo Grades:</h5>

            <article className={styles.sectionBorder}>
              <Table responsive>
                <thead>
                  <tr>
                    <th></th>
                    {/* <th className={styles.blockTitle}>SEQ</th> */}
                    <th className={styles.blockTitle}>GRADE</th>
                    <th className={styles.blockTitle}>PEÇAS COM DEFEITO</th>
                    <th className={styles.blockTitle}>TIPO ANÁLISE</th>
                    <th className={styles.blockTitle}>QTDE RECEBIDA</th>
                  </tr>
                </thead>
                <tbody>
                  {gradeAnaliseQualidadeTotalAprovada?.map((item, index) => {
                    return (
                      <tr key={`rowGradeConsulta${index}`}>
                        <td>
                          <Button
                            variant="danger"
                          >
                            <span className="fa-trash icons-uniao">
                              <MaterialIcon
                                icon="delete"
                                size={20}
                                color='white'
                                key={`rowGradeConsultaDel${item.Tamanho}`}
                                onClick={() => handleExcluiGradeConsultaDefeitos(item)}
                              />
                            </span>
                          </Button>
                        </td>
                        {/*  <td className={styles.tableLine}>{index}</td> */}
                        <td className={styles.tableLine}>{item.Tamanho}</td>
                        <td className={styles.tableLine}>{item.QtdeDirecionada}</td>
                        <td className={styles.tableLine}>{item.TipoAnalise}</td>
                        <td className={styles.tableLine}>{item.Qtde}</td>

                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </article>
            <h5 className={styles.fontBold}>Resumo Avaliações:</h5>
            <article className={styles.sectionBorder}>
              <Table responsive>
                <thead>
                  <tr>
                    <th></th>
                    {/* <th className={styles.blockTitle}>SEQ</th> */}
                    <th className={styles.blockTitle}>GRADE</th>
                    <th className={styles.blockTitle}>GRUPO</th>
                    <th className={styles.blockTitle}>STATUS</th>
                    <th className={styles.blockTitle}>DEFEITO</th>
                  </tr>
                </thead>
                <tbody>
                  {gradeAnaliseQualidadeTotalAprovada?.map((itemGrade, index) => {
                    return (
                      itemGrade.grade.map((item, index) => {
                        return (
                          <tr key={`rowGradeAvaliacaoConsulta${index}`}>
                            <td>
                              <Button
                                variant="danger"
                              >
                                <span className="fa-trash icons-uniao">
                                  <MaterialIcon
                                    icon="delete"
                                    size={20}
                                    color='white'
                                    key={`rowGradeAvaliacaoConsultaDel${item.Tamanho}`}
                                    onClick={() => handleExcluiItemConsultaDefeitos(item)}
                                  />
                                </span>
                              </Button>
                            </td>
                            {/*  <td className={styles.tableLine}>{index}</td> */}
                            <td className={styles.tableLine}>{item.tamanho}</td>
                            <td className={styles.tableLine}>{item.grupo.label}</td>
                            <td className={styles.tableLine}>{item.status.label}</td>
                            <td className={styles.tableLine}>{item.defeito.label}</td>
                          </tr>
                        )
                      }
                      ))
                  })}
                </tbody>
              </Table>
            </article>

            <article>
              <p>Obs:</p>
              <p>Ao excluir a grade, todas as avaliações referentes a ela serão excluídas;</p>
              <p>Ao excluir todas as avaliações de uma grade, a grade também será excluída.</p>
            </article>
          </Modal.Body>
          <Modal.Footer className={styles.modalFooter}>
            <Button
              variant="outline-primary"
              onClick={handleClose}
            >
              Medidas Produção
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showMedidasProducao}
          onHide={handleCloseMedidasProducao}
          backdrop="static"
          fullscreen={true}
          centered
          keyboard={false}
          dialogClassName={styles.modalWith}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MedidasProducao item={props.item}></MedidasProducao>
          </Modal.Body>
        </Modal>

        <Modal
          show={processing}
          backdrop={"static"}
          centered={true}
          style={{ backgroundColor: '#00000090' }}
          fullscreen={false}
        >
          <Modal.Body>
            <Carregando></Carregando>
          </Modal.Body>
        </Modal>
      </article>
    </div>
  );
};

export default MonitorChecklistFinalizacao;