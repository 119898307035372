import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 

const ModalConfirmacao = () => {

    const { gradeSelecionada, folhasAlocadas, enfestSelecionadoUm, enfestSelecionadoDois, insertEnfest, showConfirmacao, handleCloseConfirmacao, linhaSelecionada } = useDistribuicaoDemanda();
    
    return (
        <>
            <Modal
                show={showConfirmacao}
                onHide={handleCloseConfirmacao}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Tem certeza?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        Ordem Serviço: <strong>{linhaSelecionada.OrdemServico}</strong>
                    </div>
                    <div>
                        Material: <strong>{linhaSelecionada.Material}</strong>
                    </div>
                    <div>
                        Cor Material: <strong>{linhaSelecionada.CorMaterial}</strong>
                    </div>
                    <div>
                        Grade: <strong>{gradeSelecionada.GRADE}</strong>
                    </div>
                    <div>
                        Enfestador 1: <strong>{enfestSelecionadoUm.Nome}</strong>
                    </div>
                    <div>
                        Enfestador 2: <strong>{enfestSelecionadoDois.Nome}</strong>
                    </div>
                    <div>
                        Folhas Alocadas: <strong>{folhasAlocadas}</strong>
                    </div>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseConfirmacao}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={insertEnfest}
                    >
                        Concluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalConfirmacao;
