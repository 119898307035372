import styles from "../../../../styles/MonitorRevisaoIdentificacao.module.scss"

function ProdutoInfo (props) {

    return(
       <>
            <div className={styles.gridRow}>
                <div className={styles.gridItem}>
                    <span>Referência:</span>
                    <span className={styles.fontBlue}>{props.item.Produto}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Griffe:</span>
                    <span className={styles.fontBlue}>{props.item.Griffe}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Linha:</span>
                    <span className={styles.fontBlue}>{props.item.Linha}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Descrição Referência:</span>
                    <span className={styles.fontBlue}>{props.item.DescricaoProduto}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Fabricante:</span>
                    <span className={styles.fontBlue}>{props.item.Fabricante}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Tipo Produto:</span>
                    <span className={styles.fontBlue}>{props.item.TipoProduto}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Cor:</span>
                    <span className={styles.fontBlue}>{props.item.CorProduto}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Marca:</span>
                    <span className={styles.fontBlue}>{props.item.Marca}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Composição:</span>
                    <span className={styles.fontBlue}>{props.item.Composicao}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Descrição Cor:</span>
                    <span className={styles.fontBlue}>{props.item.DescricaoCor}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Coleção:</span>
                    <span className={styles.fontBlue}>{props.item.Colecao}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Valor Varejo:</span>
                    <span className={styles.fontBlue}>R$ {props.item.Preco}</span>
                </div>
                <div className={styles.gridItem}>
                    <span>Entrega Piloto?</span>
                    <span  className={styles.fontRed}>{props.item.RecPiloto}</span>
                </div>
            </div>
        </>
    )

}

export default ProdutoInfo;