import React, { useEffect, useState } from "react";
import { Button, Table, Form, FormGroup, Modal, InputGroup } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import { toUTCDDMMYYYY } from "../../utils/dateUtils";

import styles from "../../styles/Desconto.module.scss";
import { api }  from "../../services/api";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Carregando from "../Carregando";
import Acompanhamento from "./Acompanhamento";

function Desconto() {
  const [show, setShow] = useState(false);
  const [showAcomp, setShowAcomp] = useState(false);
  const [activeObject, setActiveObject] = useState({});
  const [taxaImpostoAtual, setTaxaImpostoAtual] = useState(12.00); // PADRÃO: 12% de ICMS;
  const [formObject, setFormObject] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const setFields = (field, value, select = false) => {
    if (select === true) setFormObject({ ...formObject, [field]: value });
    else handleValueMask(field, value);

    if (formErrors[field]) {
      setFormErrors({
        ...formErrors,
        [field]: null
      });
    }
  }

  const handleValueMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
    setFormObject({ ...formObject, [field]: parseFloat(value / 100).toFixed(2) });
  }

  const [glMonitor, setGLMonitor] = useState([]);
  const [nfFilter, setNfFilter] = useState('');
  const [materialFilter, setMaterialFilter] = useState('');
  const [materialColorFilter, setMaterialColorFilter] = useState('');

  const [totalValue, setTotalValue] = useState(0);
  const [totalMeters, setTotalMeters] = useState(0);

  function handleClose() {
    setTotalValue(0);
    setTotalMeters(0);
    setFormObject({});
    setFormErrors({});
    setShow(false);
  }

  const handleCloseAcomp = () => {
    setTotalValue(0);
    setTotalMeters(0);
    setFormObject({});
    setFormErrors({});
    setActiveObject({});
    setShowAcomp(false); 
  }

  async function handleShow(el) {
    const params = {
      NotaFiscal: el.NFENTRADA,
      SERIE_NF_ENTRADA: el.SERIENF,
      NOME_CLIFOR: el.NOMECLIFOR,
      Material: el.MATERIAL
    }

    await api.get('/GLDescontos/GLValores', { params })
      .then(res => {
        const { descontoInserido, taxaImposto } = res.data;
        if (descontoInserido.length === 1) {
          formObject.discountedMeters = descontoInserido[0].QTDE_DESCONTO || 0;
          formObject.suggestedDiscPerc = descontoInserido[0].PERC_DESC_SUGERIDO;
        }

        if (taxaImposto.length === 1) setTaxaImpostoAtual(parseFloat(taxaImposto[0].taxaImposto));
        if (taxaImposto.length === 0 || taxaImposto.length > 1) setTaxaImpostoAtual(12.00); // PADRÃO: 12% de ICMS;

        setTotalValue(el.VALOR);
        setTotalMeters(el.QTDEENTRADA);
        setShow(true);
      })
      .catch(e => {
        toast.warn('Ops! Parece que ocorreu um erro durante a abertura do modal, por favor cheque sua conexão com a internet ou entre em contato com o suporte!')
        console.error(e);
      });
  }

  const handleShowAcomp = async(el) => {
    const params = {
      NotaFiscal: el.NFENTRADA,
      SERIE_NF_ENTRADA: el.SERIENF,
      NOME_CLIFOR: el.NOMECLIFOR,
      Material: el.MATERIAL
    }

    await api.get('/GLDescontos/GLValores', { params })
      .then(res => {
        const { descontoInserido, taxaImposto } = res.data;
        if (descontoInserido.length === 1) {
          formObject.discountedMeters = descontoInserido[0].QTDE_DESCONTO || 0;
          formObject.suggestedDiscPerc = descontoInserido[0].PERC_DESC_SUGERIDO;
        }

        if (taxaImposto.length === 1) setTaxaImpostoAtual(parseFloat(taxaImposto[0].taxaImposto));
        if (taxaImposto.length === 0 || taxaImposto.length > 1) setTaxaImpostoAtual(12.00); // PADRÃO: 12% de ICMS;

        setTotalValue(el.VALOR);
        setTotalMeters(el.QTDEENTRADA);
        setShowAcomp(true);
      })
      .catch(e => {
        toast.warn('Ops! Parece que ocorreu um erro durante a abertura do modal, por favor cheque sua conexão com a internet ou entre em contato com o suporte!')
        console.error(e);
      });
  }

  async function handleFilter() {
    const params = {
      NotaFiscal: nfFilter,
      Material: materialFilter,
      CorMaterial: materialColorFilter
    }

    await api.get('/GLDescontos/GLMonitor', { params })
      .then(res => setGLMonitor(res.data))
      .catch(e => {
        console.error(e);
        toast.warn('Ops! Parece que ocorreu um erro durante a busca, por favor cheque sua conexão com a internet ou entre em contato com o suporte!');
      })
  }

  function calcDiscountedValue() {
    // const value = (totalValue * 0.9075) / totalMeters * formObject.discountedMeters * (formObject.negotiatedDiscPerc / 100);
    const value = (totalValue * (100 - taxaImpostoAtual)/100) / totalMeters * formObject.discountedMeters * (formObject.negotiatedDiscPerc / 100);
    if (!isNaN(value)) return parseFloat(value).toFixed(2)
    else return '0.00'
  }

  async function handleSubmit() {
    const errors = handleValidation();
    if (Object.keys(errors).length > 0) return setFormErrors(errors);
    
    const params = {
      NF_ENTRADA: parseInt(activeObject.NFENTRADA),
      NF_SERIE: parseInt(activeObject.SERIENF),
      NOME_CLIFOR: activeObject.NOMECLIFOR,
      QTDE_DESCONTO: parseFloat(formObject.discountedMeters).toFixed(2),
      QTDE_METROS: parseFloat(activeObject.QTDEENTRADA).toFixed(2),
      PERC_DESC_SUGERIDO: parseInt(formObject.suggestedDiscPerc),
      PERC_DESC_NEGOCIADO: isNaN(formObject.negotiatedDiscPerc) || formObject.negotiatedDiscPerc === '' ? 0 : parseFloat(formObject.negotiatedDiscPerc).toFixed(2),
      VAL_DESCONTO: isNaN(formObject.negotiatedDiscPerc) ? 0 : parseFloat(calcDiscountedValue()).toFixed(2),
      VAL_NF: parseFloat(activeObject.VALOR).toFixed(2),
      LANC_CONTABIL: parseInt(activeObject.CTBLANCAMENTO),
      QTDE_NF: parseFloat(activeObject.QTDEENTRADA).toFixed(2)
    };

    await api.get(`/GLDescontos/GLProcessar`, { params })
      .then((res) => {
        toast.success('Dados alterados com sucesso!');
        handleFilter();
        handleClose();
      }).catch(e => {
        toast.error('Ops! Parece que ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
        console.error(e);
      });
    setLoading(false);
  }

  function handleValidation() {
    const { discountedMeters, suggestedDiscPerc, negotiatedDiscPerc } = formObject;
    const errorList = {}
    if (!discountedMeters || discountedMeters === 0 || discountedMeters === '' || isNaN(discountedMeters)) {
      errorList.discountedMeters = "Metragem descontada deve ser maior do que zero";
    }
    if (!suggestedDiscPerc || suggestedDiscPerc === 'Selecionar Desconto' || isNaN(suggestedDiscPerc)) {
      errorList.suggestedDiscPerc = "Selecione um percentual sugerido";
    }
    if (negotiatedDiscPerc < 0) {
      errorList.negotiatedDiscPerc = "Percentual negociado deve ser maior do que zero";
    }
    return errorList;
  }

  const suggestedDiscountList = [
    { value: 'Selecionar Desconto', label: 'select default' },
    { value: 0, label: 'select 0' },
    { value: 5, label: 'select 1' },
    { value: 10, label: 'select 2' },
    { value: 15, label: 'select 3' },
    { value: 20, label: 'select 4' },
    { value: 25, label: 'select 5' },
    { value: 30, label: 'select 6' },
    { value: 35, label: 'select 7' },
    { value: 40, label: 'select 8' },
    { value: 45, label: 'select 9' },
    { value: 50, label: 'select 10' },
    { value: 55, label: 'select 11' },
    { value: 60, label: 'select 12' },
    { value: 65, label: 'select 13' },
    { value: 70, label: 'select 14' },
    { value: 75, label: 'select 15' },
    { value: 80, label: 'select 16' },
    { value: 85, label: 'select 17' },
    { value: 90, label: 'select 18' },
    { value: 95, label: 'select 19' },
    { value: 100, label: 'select 20' }
  ];

  useEffect(() => {
    api.get("/GLDescontos/GLMonitor")
      .then(res => setGLMonitor(res.data))
      .catch(e => console.error(e));
  }, []);

  return (
    <div>
      <article>
        <Form className={styles.form}>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Nota Fiscal</Form.Label>
            <Form.Control
              required
              type="number"
              value={nfFilter}
              onChange={(e) => setNfFilter(e.target.value)}
              placeholder={'Número da NF'}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Material</Form.Label>
            <Form.Control
              type="text"
              value={materialFilter}
              onChange={(e) => setMaterialFilter(e.target.value)}
              placeholder={'Desc. do material'}
            />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Cor Material</Form.Label>
            <Form.Control
              type="number"
              value={materialColorFilter}
              onChange={(e) => setMaterialColorFilter(e.target.value)}
              placeholder={'Cod. de cor do material'}
            />
          </FormGroup>
          <Button
            className={styles.formGroup}
            variant="primary"
            type="button"
            onClick={handleFilter}
          >
            Buscar
          </Button>
        </Form>
      </article>

      <article className={styles.articleGray}>
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className={styles.blockTitle}>NF ENTRADA</th>
              <th className={styles.blockTitle}>SERIE NF</th>
              <th className={styles.blockTitle}>MATERIAL</th>
              <th className={styles.blockTitle}>COR MATERIAL</th>
              <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
              <th className={styles.blockTitle}>DESCRIÇÃO COR </th>
              <th className={styles.blockTitle}>DATA DIGITAÇÃO</th>
              <th className={styles.blockTitle}>PEDIDO</th>
              <th className={styles.blockTitle}>FORNECEDOR</th>
              <th className={styles.blockTitle}>MARCA</th>
              <th className={styles.blockTitle}>QTDE ENTRADA</th>
              <th className={styles.blockTitle}>VALOR</th>
              <th className={styles.blockTitle}>TIPO COMPRA</th>
              <th className={styles.blockTitle}>TIPO PRODUÇÃO</th>
              <th className={styles.blockTitle}>CTB LANÇAMENTO</th>
              <th className={styles.blockTitle}>QTDE METROS DESCONTO</th>
              <th className={styles.blockTitle}>DESCONTO SUGERIDO</th>
            </tr>
          </thead>
          <tbody>
            {glMonitor.map((el, index) => {
              return (
                <tr className={styles.tableLines} key={index}>
                  <td>
                    <i
                      onClick={() => { setActiveObject(el); handleShow(el); }}
                      className={styles.iconCursor}
                    >
                      <MaterialIcon
                        icon="sell"
                        size={30}
                        color='#0D6EFD'
                      />
                    </i>
                  </td>
                  <td>
                    <Button
                      variant={(el.QTDE_DESCONTO === null || el.PERC_DESC_SUGERIDO === null) ? 'secondary' : 'outline-primary'}
                      disabled={el.QTDE_DESCONTO === null || el.PERC_DESC_SUGERIDO === null}
                      onClick={() => { setActiveObject(el); handleShowAcomp(el); }}
                    >
                      Negociar
                    </Button>
                  </td>
                  <td className={styles.tableLines}>{el.NFENTRADA}</td>
                  <td className={styles.tableLines}>{el.SERIENF}</td>
                  <td className={styles.tableLines}>{el.MATERIAL}</td>
                  <td className={styles.tableLines}>{el.CORMATERIAL}</td>
                  <td className={styles.tableLines}>{el.DESCRICAOMATERIAL}</td>
                  <td className={styles.tableLines}>{el.DESCRICAOCOR}</td>
                  <td className={styles.tableLines}>{toUTCDDMMYYYY(el.DATADIGITACAO)}</td>
                  <td className={styles.tableLines}>{el.PEDIDO}</td>
                  <td className={styles.tableLines}>{el.NOMECLIFOR}</td>
                  <td className={styles.tableLines}>{el.MARCA}</td>
                  <td className={styles.tableLines}>{el.QTDEENTRADA}</td>
                  <td className={styles.tableLines}>{el.VALOR}</td>
                  <td className={styles.tableLines}>{el.TIPOCOMPRA}</td>
                  <td className={styles.tableLines}>{el.TIPOPRODUCAO}</td>
                  <td className={styles.tableLines}>{el.CTBLANCAMENTO}</td>
                  <td className={styles.tableLines}>{el.QTDEMETROSDESCONTO}</td>
                  <td className={styles.tableLines}>{el.DESCONTOSUGERIDO}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </article>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Acompanhamento Descontos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article className={styles.monitorInfo}>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>NF Entrada:</span>
                <span className={styles.fontBlue}>{activeObject.NFENTRADA}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Material:</span>
                <span className={styles.fontBlue}>{activeObject.MATERIAL}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Cor Material:</span>
                <span className={styles.fontBlue}>{activeObject.CORMATERIAL}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Tipo Compra:</span>
                <span className={styles.fontBlue}>{activeObject.TIPOCOMPRA}</span>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Série:</span>
                <span className={styles.fontBlue}>{activeObject.SERIENF}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Descrição Material:</span>
                <span className={styles.fontBlue}>{activeObject.DESCRICAOMATERIAL}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Descrição Cor:</span>
                <span className={styles.fontBlue}>{activeObject.DESCRICAOCOR}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Tipo Produção:</span>
                <span className={styles.fontBlue}>{activeObject.TIPOPRODUCAO}</span>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoBlockColumm}>
                <span>Pedido:</span>
                <span className={styles.fontBlue}>{activeObject.PEDIDO}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Marca:</span>
                <span className={styles.fontBlue}>{activeObject.MARCA}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Qtde Entrada:</span>
                <span className={styles.fontBlue}>{activeObject.QTDEENTRADA}</span>
              </div>
              <div className={styles.infoBlockColumm}>
                <span>Valor NF:</span>
                <span className={styles.fontBlue}>{activeObject.VALOR}</span>
              </div>
            </div>
          </article>
          <article>
            <div className="mt-3">
              <Form>
                <Form.Group className={styles.formGroup} controlId="discountedMeters">
                  <Form.Label>1. Metragem descontada:</Form.Label>
                  <InputGroup className={styles.discountFormWidth}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Quantidade (m)"
                      value={formObject.discountedMeters || ''}
                      onChange={(e) => setFields('discountedMeters', e.target.value)}
                      isInvalid={formErrors.discountedMeters}
                    />
                    <InputGroup.Text>m</InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>{formErrors.discountedMeters}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className={styles.formGroup} controlId="suggestedDiscPerc">
                  <Form.Label>2. Desconto sugerido:</Form.Label>
                  <InputGroup className={styles.discountFormWidth}>
                    <Form.Control
                      required
                      as="select"
                      placeholder="Desconto sugerido (%)"
                      value={formObject.suggestedDiscPerc}
                      onChange={(e) => setFields('suggestedDiscPerc', e.target.value, true)}
                      isInvalid={formErrors.suggestedDiscPerc}
                    >
                      {suggestedDiscountList.map((op) => (
                        <option key={op.label} defaultValue={formObject.suggestedDiscPerc === op.value}>{op.value}</option>
                      ))}
                    </Form.Control>
                    <InputGroup.Text>%</InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>{formErrors.suggestedDiscPerc}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                {/* <Form.Group className={styles.formGroup} controlId="negotiatedDiscPerc">
                  <Form.Label>3. Desconto negociado:</Form.Label>
                  <InputGroup className={styles.discountFormWidth}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Desconto negociado (%)"
                      value={formObject.negotiatedDiscPerc || ''}
                      onChange={(e) => setFields('negotiatedDiscPerc', e.target.value)}
                      isInvalid={formErrors.negotiatedDiscPerc}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>{formErrors.negotiatedDiscPerc}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className={styles.formFlex}>
                  <Form.Label>4. Valor do desconto negociado:</Form.Label>
                  <InputGroup>
                    <span className={styles.discountAmount}>
                      {'R$ ' + calcDiscountedValue()}
                      <span style={{marginLeft: '10px', fontSize: '14px', opacity: '0.8'}}>
                        {'(' + taxaImpostoAtual.toFixed(2) + '% de ICMS)'}
                      </span>
                    </span>
                  </InputGroup>
                </Form.Group> */}
              </Form>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            className={styles.iconCursor}
            variant="outline-danger"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            disabled={
              (isNaN(formObject.discountedMeters) || formObject.discountedMeters <= 0)
              || isNaN(formObject.suggestedDiscPerc)
              || formObject.negotiatedDiscPerc < 0
            }
            className={styles.iconCursor}
            variant="primary"
            onClick={() => { setLoading(true); handleSubmit(); }}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      <Acompanhamento
        activeObject={activeObject}
        taxaImpostoAtual={taxaImpostoAtual}
        showAcomp={showAcomp}
        handleCloseAcomp={handleCloseAcomp}
        handleFilter={handleFilter}
        formObject={formObject}
        setFields={setFields} 
        formErrors={formErrors} 
        calcDiscountedValue={calcDiscountedValue} 
        setLoading={setLoading}
      />
      
      <Modal
        show={loading}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{backgroundColor: '#00000090'}}
        centered={true}
      >
        <Modal.Body><Carregando></Carregando></Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Desconto;
