import React from "react";
import { useState } from "react";
import { Form, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import styles from "../../styles/Revisao.module.scss";

import Button from "react-bootstrap/Button";

const Filter = (props) => {

  const [nf, setNF] = useState('');
  const [material, setMaterial] = useState('');
  const [cor, setCor] = useState('');
  const [filtering, setFiltering] = useState(false);

  
  function ClearFilter () {
    setFiltering(false);
    setNF('');
    setMaterial('');
    setCor('');

    props.filterHandler({});
  }

  function FilterFn () {
    if(!!nf || !!material || !!cor){
      setFiltering(true);
    }else{
      setFiltering(false);
    }
    props.filterHandler({
      nfentrada: !!nf ? nf : undefined,
      material: !!material ? material : undefined,
      cormaterial: !!cor ? cor : undefined,
    });
  }

  return (
    <Form className={styles.form}>
      <Form.Group className={styles.formGroup}>
        <Form.Label>Nota Fiscal</Form.Label>
        <Form.Control 
          value={nf} 
          onChange={e => setNF(e.target.value)} 
        />
      </Form.Group>
      <Form.Group className={styles.formGroup}>
        <Form.Label>Material</Form.Label>
        <Form.Control 
          value={material} 
          onChange={e => setMaterial(e.target.value)} 
        />
      </Form.Group>
      <Form.Group className={styles.formGroup}>
        <Form.Label>Cor Material</Form.Label>
        <Form.Control 
          value={cor} 
          onChange={e => setCor(e.target.value)} 
        />
      </Form.Group>
      <Button 
        className={styles.formGroup} 
        variant="primary"
        type="button"
        onClick={FilterFn}
      >
        Buscar
      </Button>
      {
        (filtering) ? <Button onClick={ClearFilter} variant="outline-secondary">Clear Filter</Button> : null
      }
    </Form>
  )
}

export default Filter;