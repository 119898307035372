import { Modal, Button } from 'react-bootstrap';
import { useFGTesteDeBase } from "../../../../contexts/FarmGlobal/TesteDeBaseContext.js";
import MaterialIcon from 'material-icons-react';
import { ToBrDateWithTime } from '../../../../utils/dateUtils.jsx';


const ModalArquivos = () => {
  const { arquivos, setShowModalArquivos, showModalArquivos, handleShowPDF, descricaoAtiva } = useFGTesteDeBase();
  
  const handleClose = () => {
    setShowModalArquivos(false); 
  };

  const sortedArquivos = [...arquivos].sort((a, b) => new Date(b.DataRegistro) - new Date(a.DataRegistro));

  return (
    <Modal size="xl" backdrop="static" show={showModalArquivos} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'center', width: '100%' }}>Descrição do Material: {descricaoAtiva}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sortedArquivos && sortedArquivos.map((arquivo, index) => (
          <div key={arquivo.IdArquivo} style={{ cursor: 'pointer' }} onClick={() => handleShowPDF(arquivo.Arquivo)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MaterialIcon 
                icon="description" 
                size={index === 0 ? 90 : 50} 
                color="#0D6EFD"
              />
              <div style={{ marginLeft: '30px' }}>
                <p style={index === 0 ? { fontSize: '1.5em' } : {}}><strong>Nome do Arquivo:</strong> {arquivo.Arquivo}</p>          
                <p style={index === 0 ? { fontSize: '1.5em' } : {}}><strong>Data de Registro:</strong> {ToBrDateWithTime(arquivo.DataRegistro)}</p>
              </div>
            </div>
            {index < sortedArquivos.length - 1 && <hr />} 
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalArquivos;