import React from "react";
import { Button, Modal, Form, Table } from 'react-bootstrap';
import styles from '../../../styles/MP/AnaliseRapportReprovadoCad.module.scss';

const ModalConfirmacaoGrade = ({ showConfirmacaoGrade, handleCloseConfirmacaoGrade, handleProcessConsumo, consumos }) => {

    return (
        <Modal
            show={showConfirmacaoGrade}
            onHide={handleCloseConfirmacaoGrade}
            backdrop="static"
            keyboard={false}
            centered
        >
        <Modal.Header>
            <Modal.Title>Essas informações serão salvas:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table responsive>
                <tbody>
                    <tr className={styles.tableLines}>
                        <td className={styles.tableLines}>
                            Consumo atual
                        </td>
                        <td className={styles.tableLines}>
                            <Form>
                                <Form.Control 
                                    type='text'
                                    disabled
                                    value={consumos.consumoAtual + ' cm'}
                                />
                            </Form>
                        </td>
                    </tr>
                    <tr className={styles.tableLines}>
                        <td className={styles.tableLines}>
                            Novo consumo
                        </td>
                        <td className={styles.tableLines}>
                            <Form>
                                <Form.Control 
                                    type='text'
                                    disabled
                                    value={consumos.novoConsumo + ' cm'}
                                />
                            </Form>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
            <Button
                variant="success"
                className={styles.modalBtn}
                onClick={handleProcessConsumo}
            >
                Confirmar
            </Button>
            <Button
                variant="outline-danger"
                className={styles.modalBtn}
                onClick={handleCloseConfirmacaoGrade}
            >
                Voltar
            </Button>
        </Modal.Footer>
      </Modal>
    ) 
}

export default ModalConfirmacaoGrade;
