import React from "react";
import { Button, Modal, Form, Table } from 'react-bootstrap';
import Select from 'react-select';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext';
import DadosAccordion from "./DadosAccordion";
import RadioRisco from "./RadioRisco";
import MaterialIconsReact from "material-icons-react";

const ModalRiscos = () => {

    const { tipoCorteData, setTipoCorteMaterial, tipoCorteMaterial, handleFileChange, handleShowModalFolha, habilitaFinalizarMat, handleDeleteInserido, handleInserirRisco, habilitaIncluirRisco, handleComprimentoRisco, comprimentoRisco, showModalRiscos, handleCloseModalRiscos, tamanhosData, riscosInseridos, tamanhosSelecionados, handleCheckTamanhos } = useLancamentoCad();

    return (
        <>
            <Modal
                show={showModalRiscos}
                onHide={handleCloseModalRiscos}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen
            >
                <Modal.Header>
                    <Modal.Title>Insira os riscos para o material/cor escolhido:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DadosAccordion/>
                    <div className={styles.divGradeRisco}>
                        <article className={styles.articleGradeRisco}>
                            <article className={styles.articleGradeComprimento}>
                                <Form className={styles.btnFile}>
                                    <Form.Group className="mb-3">
                                        <Form.Label
                                             className={styles.formLabelComprimento}
                                        >
                                            Selecione o tipo de corte do material:
                                        </Form.Label>
                                        <Select
                                            className={styles.formControl}
                                            closeMenuOnSelect={true}
                                            value={tipoCorteMaterial || ''}
                                            options={tipoCorteData}
                                            getOptionLabel={(option)=>option.desc_producao_tipo_corte}
                                            getOptionValue ={(option)=>option.id_producao_tipo_corte}
                                            onChange={(e) => setTipoCorteMaterial(e)}
                                        />
                                    </Form.Group>
                                </Form>
                                <Form className={styles.btnFile}>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label className={styles.formLabelComprimento}>Escolha a imagem:</Form.Label>
                                        <Form.Control 
                                            type="file" 
                                            onChange={e => handleFileChange(e)}
                                            accept="image/*"
                                        />
                                    </Form.Group>
                                </Form>
                                <Form>
                                    <Form.Group className={styles.formGroupRadio}>
                                        <Form.Label className={styles.formLabelTamanho}>
                                            Selecione o risco:
                                        </Form.Label>

                                        <RadioRisco/>

                                    </Form.Group>
                                    <Form.Label className={styles.formLabel}>
                                        Selecione os tamanhos:
                                    </Form.Label>
                                    <div>
                                        { tamanhosData.map((item,index) => {
                                            return (
                                                <Form.Check
                                                    key={index}
                                                    type='checkbox'
                                                    name='checkTamanho' 
                                                    label={item.Grade}
                                                    onChange={(e) => handleCheckTamanhos(e, item)}
                                                    checked={tamanhosSelecionados.filter(i => i.idx === item.idx).length > 0}
                                                />
                                            )
                                        })}
                                        <Form.Group className={styles.formGroupComprimento}>
                                            <Form.Label className={styles.formLabelComprimento}>Digite o comprimento do Risco:</Form.Label>
                                            <Form.Control 
                                                className={styles.formControlTamanhoComprimento}
                                                type="text"
                                                value={comprimentoRisco || ''}
                                                onChange = {(e) => handleComprimentoRisco(e)} 
                                            />
                                        </Form.Group>
                                        <Button
                                            variant={habilitaIncluirRisco ? "outline-primary" : "secondary"}
                                            onClick={handleInserirRisco}
                                            className={styles.btnInserir}
                                            disabled={!habilitaIncluirRisco}
                                        >
                                            Incluir Risco
                                        </Button>
                                    </div>
                                </Form>
                            </article>
                            <article className={styles.articleGradeComprimento}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th className={styles.blockTitle}></th>
                                            <th className={styles.blockTitle}>Risco</th>
                                            <th className={styles.blockTitle}>Grade</th>
                                            <th className={styles.blockTitle}>Comprimento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { riscosInseridos.map((item,index) => {
                                        return (
                                        <tr key={index}>
                                            <td>
                                                <i 
                                                    style={{ 'cursor': 'pointer' }}
                                                    className={styles.icon}
                                                    onClick={() => handleDeleteInserido(item)}
                                                >
                                                    <MaterialIconsReact
                                                        icon="clear"
                                                        size={30}
                                                        color='red'
                                                    />
                                                </i>
                                            </td> 
                                            <td className={styles.tableLines}>{item.risco}</td>
                                            <td className={styles.tableLines}>{item.Grade}</td>
                                            <td className={styles.tableLines}>{item.comprimentoRisco}</td>
                                        </tr>
                                        )})}
                                    </tbody>
                                </Table>
                            </article>
                        </article>
                    </div> 
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseModalRiscos}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={habilitaFinalizarMat ? "outline-success" : 'secondary'}
                        className={styles.modalBtn}
                        onClick={handleShowModalFolha}
                        disabled={!habilitaFinalizarMat}
                        style={{width: '120px'}}
                    >
                        Inserir folhas
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )


}

export default ModalRiscos;
