import React from "react";
import { Button, Modal } from 'react-bootstrap';
import styles from '../../../styles/MP/RapportReprovado.module.scss';

const ModalReprovado = ({ showReprovado, handleCloseReprovado, pedidoSelecionado, handleEnviarDevolucao, envio }) => {
    const send = {...pedidoSelecionado, ...envio}
    return (
        <Modal
            show={showReprovado}
            onHide={handleCloseReprovado}
            backdrop="static"
            keyboard={false}
            centered
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>Reprovar Pedido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Deseja enviar o <strong>pedido {pedidoSelecionado.PEDIDO}</strong> para a filial devolução?</span>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseReprovado}
                >
                    Voltar
                </Button>
                <Button
                    variant="outline-success"
                    className={styles.modalBtn}
                    onClick={() => handleEnviarDevolucao(send)}
                >
                    Enviar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalReprovado;
