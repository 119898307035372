import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap'
import styles from './PAReversa.module.scss';
import SelectInput from '../../Misc/SelectInput/SelectInput';
import { usePAReversa } from '../../../contexts/PAReversaContext';
import Tabela from '../../Misc/Tabela/Tabela';
import { toast } from 'react-toastify';
import MaterialIcon from 'material-icons-react';

const PAReversaInserirDefeito = () => {
  const { listReversa, showModal, listaDefeitos, listaDirecionamentos, fecharModalDefeitos, referenciaSelecionada, handleInserirDirecionamento, handleRemoverDirecionamento } = usePAReversa();

  const [formObj, setFormObj] = useState({ grade: null, defeito: [], direcionamento: null, quantidade: null });

  const direcionamento_columns = [
    {
      cell: (row, indexDir) => {
        return (
          <div onClick={() => handleRemoverDirecionamento(row, referenciaSelecionada.index, indexDir)}>
            <MaterialIcon icon="delete" size={20} color='#BB271A' />
          </div>
        )
      }, width: '60px'
    },
    { name: 'Tamanho', selector: row => row.grade.tamanho },
    { name: 'Quantidade', selector: row => row.quantidade },
    { name: 'Defeitos', selector: row => row.defeito.map(item => item.label).join(' | '), width: '60%' },
    { name: 'Direcionamento', selector: row => row.direcionamento.label },
  ];

  const handleSetFormObj = (field, value) => {
    if (field === 'quantidade' && parseInt(value) > formObj.grade.quantidade) value = formObj.grade.quantidade;
    if (field === 'grade') formObj.quantidade = 0;
    setFormObj({ ...formObj, [field]: value });
  };

  const inserirDefeito = () => {
    const { grade, defeito, direcionamento, quantidade } = formObj;
    const index = referenciaSelecionada.index;
    const errs = [];
    try {
      if (!grade) errs.push('tamanho');
      if (defeito.length === 0) errs.push('defeito');
      if (!direcionamento) errs.push('direcionamento');
      if (!quantidade || quantidade === 0) errs.push('quantidade');
      if (errs.length > 0) return toast.warning('Por favor selecione e/ou preencha todos os campos antes de inserir um direcionamento.');
      handleInserirDirecionamento(index, formObj);
      setFormObj({ grade: null, defeito: [], direcionamento: null, quantidade: null });
    } catch (e) {
      toast.error('Houve um problema ao inserir o direcionamento, por favor tente novamente.');
    }
  }

  const customFilter = (option, searchText) => {
    if (!searchText) return true;
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  useEffect(() => {
    setFormObj({ grade: null, defeito: [], direcionamento: null, quantidade: null });
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      backdrop={'static'}
      keyboard={false}
      size={'xl'}
      onHide={fecharModalDefeitos}
      centered={true}
    >
      <Modal.Header closeButton style={{fontWeight: '500'}}>Inserir Defeito - {referenciaSelecionada.produto} | {referenciaSelecionada.cor_produto}</Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <div style={{fontSize: '16px', fontWeight: '500', textAlign: 'center'}}>
          Importante: Peças sem defeito serão automaticamente direcionadas para o estoque.
        </div>
        <Form className={styles.defeitos_form}>
          <section>
            <label className={styles.form_label}>Selecionar Tamanho</label>
            <ListGroup horizontal>
              {referenciaSelecionada.grade && listReversa[referenciaSelecionada.index].grade.map((t, index) => {
                return (
                  <ListGroup.Item
                    key={t.tamanho}
                    onClick={e => { e.preventDefault(); handleSetFormObj('grade', {...t, index}); }}
                    action
                    active={formObj.grade && formObj.grade.tamanho === t.tamanho}
                    style={{ cursor: 'pointer', fontWeight: '500' }}
                    disabled={parseInt(t.quantidade) === 0}
                  >
                    [{t.tamanho}] - Restam: {t.quantidade}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </section>

          <section className={styles.flex_section}>
            <SelectInput
              label={'Defeito'}
              style={{ width: '33%' }}
              value={formObj.defeito}
              onChange={e => handleSetFormObj('defeito', e.splice(0, 5))}
              options={listaDefeitos}
              filterOption={customFilter}
              isMulti={true}
            />

            <SelectInput
              label={'Direcionamento'}
              style={{ width: '33%' }}
              value={formObj.direcionamento}
              onChange={e => handleSetFormObj('direcionamento', e)}
              options={listaDirecionamentos}
            />

            <Form.Group className={styles.select_group} style={{ width: '33%' }}>
              <Form.Label className={styles.form_label}>Quantidade Direcionada</Form.Label>
              <Form.Control
                type={'number'}
                placeholder={0}
                min={formObj.grade ? 1 : 0}
                max={formObj.grade ? formObj.grade.quantidade : 0}
                style={{ width: '100%', height: '38px' }}
                value={formObj.quantidade || 0}
                onChange={e => handleSetFormObj('quantidade', e.target.value)}
                disabled={!formObj.grade}
              />
            </Form.Group>
          </section>

          <section className={styles.flex_section}>
            <div style={{ width: '33%' }}></div>
            <div style={{ width: '33%' }}></div>
            <Button
              className={styles.btn_footer}
              variant={'outline-success'}
              style={{ width: '33%', fontSize: '13px' }}
              onClick={inserirDefeito}
            >
              Inserir
            </Button>
          </section>
        </Form>

        <section>
          <label className={styles.form_label}>Peças Defeituosas Direcionadas</label>
          <Tabela
            columns={direcionamento_columns}
            data={listReversa[referenciaSelecionada.index]?.direcionamento_defeitos || []}
            pagination={false}
          />
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default PAReversaInserirDefeito;
