import React, { useState, useEffect } from 'react';
import {useMPRapportAnalisePlanner} from "../../../contexts/MPRapportAnalisePlannerContext"

import { Button, Table, Form } from 'react-bootstrap';
import styles from '../../../styles/MP/RapportAnalisePlanner.module.scss';
import { toUTCDDMMYYYY } from '../../../utils/dateUtils';

import ModalCadConsumo from './ModalCadConsumo';

const RapportAnalisePlannerMain = () => {

    const {mainData, setMainData,filterObj, setFilterObj, handleSearch, handleShowCad, handleShowHistorico, showHistorico} = useMPRapportAnalisePlanner();

    useEffect(() => {
        handleSearch();
      }, []);

    return (
        <div>
        <article>
                <h3 className="p-3">Rapport- Análise Estilo</h3>
                <Form className={styles.formWrapper}>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Nota fiscal'}
                        className={styles.formControl}
                        value={filterObj.nf_entrada || ''}
                        onChange={(event) => setFilterObj({...filterObj, nf_entrada: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Material'}
                        className={styles.formControl}
                        value={filterObj.material || ''}
                        onChange={(event) => setFilterObj({...filterObj, material: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Cor Material'}
                        className={styles.formControl}
                        value={filterObj.cor_material || ''}
                        onChange={(event) => setFilterObj({...filterObj, cor_material: event.target.value})}
                    />
                </Form.Group>
                <Button
                    type="button"
                    variant="primary"
                    className={styles.filterBtn}
                    onClick={handleSearch}
                >
                    Buscar
                </Button>
                </Form>
            </article>

            <article className={styles.articleGray}>
             
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}></th>                                                        
                            <th className={styles.blockTitle}>PEDIDO</th>
                            <th className={styles.blockTitle}>NOTA FISCAL</th>                            
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                            <th className={styles.blockTitle}>COR MATERIAL</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO COR MATERIAL</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        { mainData.map((item,index) => {
                        return (
                        <tr key={index}>                            
                        <td>
                                <Button style={{width: '100%'}}
                                    variant= {item.AnalisarRetornoCad > 0 ? 'warning' : item.AguardandoRetornoCad > 0 ? 'success' : 'info'} 
                                    type="button"
                                    onClick={() => handleShowHistorico(item)}
                                >
                                   {item.AnalisarRetornoCad > 0 ? 'Analisar Retorno Cad' : item.AguardandoRetornoCad > 0 ? 'Aguardando Cad' : 'Analisar'} 
                                </Button>
                            </td>                            
                            <td className={styles.tableLines}>{item.PEDIDO}</td>
                            <td className={styles.tableLines}>{item.NFENTRADA}</td>                            
                            <td className={styles.tableLines}>{item.FORNECEDOR}</td>
                            <td className={styles.tableLines}>{item.MATERIAL}</td>
                            <td className={styles.tableLines}>{item.DESCRICAOMATERIAL}</td>
                            <td className={styles.tableLines}>{item.CORMATERIAL}</td>
                            <td className={styles.tableLines}>{item.DESCRICAOCOR}</td>                            
                        </tr>
                        )})}
                    </tbody>
                </Table>               
            </article>
            <ModalCadConsumo  
            showHistorico={showHistorico}          
            />
        </div>
    )
}

export default RapportAnalisePlannerMain;