import React, { useState, useEffect } from "react";
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import styles from '../../styles/RevisaoExterna.module.scss';
import Select from 'react-select';
import CookiesService from "../../services/cookies";

import { api }  from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import Carregando from "../../components/Carregando"

import { toDatetime, toUTCDDMMYYYY } from "../../utils/dateUtils";

const PARevisaoExterna = () => {
  const [showModalAcompanhamento, setShowModalAcompanhamento] = useState(false);
  const [showModalResultado, setShowModalResultado] = useState(false);
  const [activeObj, setActiveObj] = useState({});
  const [formObj, setFormObj] = useState({});
  const [formOptions, setFormOptions] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [mainArr, setMainArr] = useState([]);
  const [filterErrs, setFilterErrs] = useState({});
  const [filterObj, setFilterObj] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [listAcompanhamentoVisitas, setListAcompanhamentoVisitas] = useState([]);
  const [listAcompanhamentoDefeitos, setListAcompanhamentoDefeitos] = useState([]);
  const [novoFornecedor, setNovoFornecedor] = useState('');
  const [novoFornecedorErr, setNovoFornecedorErr] = useState(false);
  const [isAdmin] = useState(CookiesService.isUserAdmin());

  const handleShowAcompanhamento = async (el) => {
    setFormObj({});
    setFormErrs({});

    let listFaseOperacao = []

    await api.get("/PARevisaoExterna/getListaFaseOperacao")
      .then(res => res.status === 200 ? listFaseOperacao = res.data : null)
      .catch(e => toast.error(`Erro ao preencher os campos de seleção. Erro na requisição: ${e}`));

    setActiveObj(el);
    setShowModalAcompanhamento(true);
    setFormOptions({ listFaseOperacao })
    await fetchAcompanhamentoVisitas(el);
  };

  const handleCloseAcompanhamento = () => {
    setActiveObj({});
    setFormObj({});
    setFormOptions({});
    setFormErrs({});
    setListAcompanhamentoVisitas([]);
    setShowModalAcompanhamento(false);
  };

  const fetchAcompanhamentoVisitas = async (el) => {
    const params = {
      DataRegistro: toDatetime(new Date()),

      Origem: el.FORNECEDOR.trim(),
      OrdemProducao: el.ORDEM_PRODUCAO.trim(),
      Produto: el.PRODUTO.trim(),
      CorProduto: el.COR_PRODUTO.trim(),
      QtdePecasOP: el.QTDE
    }

    await api.get("/PARevisaoExterna/getAcompanhamentoVisitas", { params })
      .then(res => res.status === 200 && res.data.length > 0 ? setListAcompanhamentoVisitas(res.data) : null)
      .catch(e => toast.error(`Erro ao preencher os campos de seleção. Erro na requisição: ${e}`));
  }

  const setFilters = (field, value) => {
    setFilterObj({
      ...filterObj,
      [field]: value
    });

    if (!!filterErrs[field]) {
      setFilterErrs({
        ...filterErrs,
        [field]: null
      });
    }
  }

  const fetchProcessos = async () => {
    setCarregando(true);

    await api.get("/PARevisaoExterna/getMonitor").then(res => {
      if (res.status === 200) {
        // ------------------------------------------- //
        // --- LIMITANDO OS 100 PRIMEIROS RESULTADOS - // 
        // --- ATÉ A IMPLEMENTAÇÃO DE PAGINAÇÃO ------ //
        // ------------------------------------------- //
        setMainArr(res.data.slice(0, 100));
      } else {
        toast.error("Erro Status Code: " + res.status + ".\nEntre em contato com o suporte.");
      }
    });
    setCarregando(false);
  }

  const handleFilteredSearch = async () => {
    setCarregando(true);
    const errs = handleFilterValidation();
    if (Object.keys(errs).length > 0) {
      setFilterErrs(errs);
    } else {
      const params = {
        produto: filterObj.produto && filterObj.produto !== '' ? filterObj.produto : null,
        corProduto: filterObj.corProduto && filterObj.corProduto !== '' ? filterObj.corProduto : null,
        OP: filterObj.OP && filterObj.OP !== '' ? filterObj.OP : null
      }

      await api.get("/PARevisaoExterna/getMonitor", { params })
        .then(res => {
          if (res.status === 200 && res.data.length > 0) {
            setMainArr(res.data.slice(0, 100));
          } else if (res.status === 200 && res.data.length === 0) {
            toast.warning('Nenhum processo foi encontrado utilizando seus parâmetros de busca.');
          } else if (res.status !== 200) {
            toast.error("Erro Status Code: " + res.status + ".\nEntre em contato com o suporte.");
          }
        }).catch(e => console.error(`Erro na requisição: ${e}`));
    }
    setCarregando(false);
  }

  const handleFilterValidation = () => {
    const { produto, corProduto, OP } = filterObj;
    const errs = {}
    if (!isNaN(produto) && parseInt(produto) <= 0) errs.produto = 'Insira um produto válido';
    if (!isNaN(corProduto) && parseInt(corProduto) <= 0) errs.corProduto = 'Insira uma cor de produto válida';
    if (!isNaN(OP) && parseInt(OP) <= 0) errs.OP = 'Insira uma OP válida';
    return errs;
  }

  const handleNumberDotMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
    setFilterObj({ ...formObj, [field]: value });
    setFilterErrs({ ...filterErrs, [field]: null });
  }

  const handleProcessAcompanhamento = async (e) => {
    e.preventDefault();
    const errs = handleValidationAcompanhamento();
    if (Object.keys(errs).length > 0) {
      return setFormErrs(errs);
    } else {
      const params = {
        DataRegistro: toDatetime(new Date()),

        Origem: activeObj.FORNECEDOR,
        OrdemProducao: activeObj.ORDEM_PRODUCAO,
        Produto: activeObj.PRODUTO,
        CorProduto: activeObj.COR_PRODUTO,
        QtdePecasOP: activeObj.QTDE,
        DataVisita: formObj.DataFase,
        IDFaseOperacao: formObj.FaseOperacao.value,
        ConsideracaoFase: formObj.ObsFase
      }
      await api.post("/PARevisaoExterna/ExecCadastroAcompanhamento", params)
        .then(res => {
          if (res.status === 200) {
            toast.success('Dados inseridos com sucesso!');
            setFormObj({});
            setFormErrs({});
            fetchAcompanhamentoVisitas(activeObj);
          } else {
            toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
          }
        })
        .catch(e => {
          console.error(e);
          toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
        });
    }
  }

  const handleValidationAcompanhamento = () => {
    const { DataFase, FaseOperacao, ObsFase } = formObj;
    const errs = {}
    if (!DataFase) errs.DataFase = 'Selecione a data da fase';
    if (!FaseOperacao) errs.FaseOperacao = 'Selecione uma fase da operação';
    if (!ObsFase) formObj.ObsFase = '';
    return errs;
  }

  const handleDeleteAcompanhamento = async (el) => {
    const params = {
      Origem: el.Origem,
      OrdemProducao: el.OP,
      Produto: el.Produto,
      CorProduto: el.Cor,
      QtdePecasOP: el.Qtde,
      DataVisita: el.DataVisita,
      IDFaseOperacao: el.IDFaseOperacao,
      ConsideracaoFase: el.ConsideracaoFase
    }
    await api.post("/PARevisaoExterna/ExecDeleteAcompanhamento", params)
      .then(res => {
        if (res.status === 200) {
          toast.success('Registro excluído com sucesso!');
          fetchAcompanhamentoVisitas(activeObj);
        } else {
          toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
      })
      .catch(e => {
        console.error(e);
        toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
      });

  }

  const handleProcessDefeito = async (e) => {
    e.preventDefault();
    const errs = handleValidationDefeito();
    if (Object.keys(errs).length > 0) {
      return setFormErrs(errs);
    } else {
      const params = {
        DataRegistro: toDatetime(new Date()),

        Origem: activeObj.FORNECEDOR,
        OrdemProducao: activeObj.ORDEM_PRODUCAO,
        Produto: activeObj.PRODUTO,
        CorProduto: activeObj.COR_PRODUTO,
        QtdePecasOP: activeObj.QTDE,
        //DataInspecao: formObj.DataInspecao,
        //ResultadoInspecao: formObj.ResultadoInspecao.value,
        //ObsInspecao: formObj.ObsInspecao,
        IDGrupoAnalise: formObj.GrupoAvaliacao.value,
        IDDefeito: formObj.DefeitoEncontrado.value,
        Consideracao: formObj.ObsDefeito
      }
      await api.post("/PARevisaoExterna/ExecCadastroDefeitos", params)
        .then(res => {
          if (res.status === 200) {
            toast.success('Dados inseridos com sucesso!');
            setFormObj({ ...formObj, GrupoAvaliacao: '', DefeitoEncontrado: '', ObsDefeito: '' });
            setFormErrs({});
            fetchAcompanhamentoDefeitos(activeObj);
          } else {
            toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
          }
        })
        .catch(e => {
          console.error(e);
          toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
        });
    }
  }

  const handleValidationDefeito = () => {
    const { DataInspecao, ResultadoInspecao, ObsInspecao, GrupoAvaliacao, DefeitoEncontrado, ObsDefeito } = formObj;
    const errs = {}
    if (!DataInspecao) errs.DataInspecao = 'Selecione a data de inspeção';
    if (!ResultadoInspecao) {
      errs.ResultadoInspecao = 'Selecione o resultado da inspeção';
    } else {
      if (ResultadoInspecao.value == 4) {
        errs.ResultadoInspecao = 'Atenção! Status da revisão consta como aprovado sem defeitos';
        toast.error(`Atenção! Status da revisão consta como aprovado sem defeitos`);
      }
    }
    if (!ObsInspecao) formObj.ObsInspecao = '';
    if (!GrupoAvaliacao) errs.GrupoAvaliacao = 'Selecione o grupo de avaliação';
    if (!DefeitoEncontrado) errs.DefeitoEncontrado = 'Selecione o defeito encontrado';
    if (!ObsDefeito) formObj.ObsDefeito = '';
    return errs;
  }

  const clearErrs = (val) => {
    if (val) return setFormErrs({ ...formErrs, [val]: null })
    setFormErrs({
      ...formErrs,
      FaseOperacao: null,
      ObsFase: null,
      DataFase: null
    })
  }

  const handleCloseResultado = () => setShowModalResultado(false);
  const handleShowResultado = async (el) => {

    let listResultadoInspecao = []

    await api.get("/PARevisaoExterna/getListaResultadoInspecao")
      .then(res => res.status === 200 ? listResultadoInspecao = res.data : null)
      .catch(e => toast.error(`Erro ao preencher os campos de seleção. Erro na requisição: ${e}`));

    let listGrupoAvaliacao = []

    await api.get("/PARevisaoExterna/getListaGrupoAvaliacao")
      .then(res => res.status === 200 ? listGrupoAvaliacao = res.data : null)
      .catch(e => toast.error(`Erro ao preencher os campos de seleção. Erro na requisição: ${e}`));

    setFormOptions({ listResultadoInspecao, listGrupoAvaliacao })
    setActiveObj(el);
    setFormObj({});
    setListAcompanhamentoDefeitos([]);
    fetchAcompanhamentoInspecao(el);
    fetchAcompanhamentoDefeitos(el);
    setShowModalResultado(true);
  }

  const fetchListDefeitos = async (el) => {
    //setFormObj({...formObj,DefeitoEncontrado:''})
    const params = {
      IdGrupoAnalise: el.value && el.value !== '' ? el.value : null
    }

    let listDefeitosEncontrados = []

    await api.get("/PARevisaoExterna/getListaDefeitosEncontrados", { params })
      .then(res => res.status === 200 ? listDefeitosEncontrados = res.data : null)
      .catch(e => toast.error(`Erro ao preencher os campos de seleção. Erro na requisição: ${e}`));

    setFormOptions({ ...formOptions, listDefeitosEncontrados })

  }

  const fetchAcompanhamentoInspecao = async (el) => {
    const params = {
      DataRegistro: toDatetime(new Date()),

      Origem: el.FORNECEDOR.trim(),
      OrdemProducao: el.ORDEM_PRODUCAO.trim(),
      Produto: el.PRODUTO.trim(),
      CorProduto: el.COR_PRODUTO.trim()
    }

    await api.get("/PARevisaoExterna/getAcompanhamentoInspecao", { params })
      .then(res => res.status === 200 && res.data.length > 0 ? setListAcompanhamentoVisitas(res.data) : null)
      .catch(e => toast.error(`Erro ao preencher os campos de seleção. Erro na requisição: ${e}`));
  }

  const fetchAcompanhamentoDefeitos = async (el) => {
    const params = {
      DataRegistro: toDatetime(new Date()),

      Origem: el.FORNECEDOR.trim(),
      OrdemProducao: el.ORDEM_PRODUCAO.trim(),
      Produto: el.PRODUTO.trim(),
      CorProduto: el.COR_PRODUTO.trim(),
      QtdePecasOP: el.QTDE
    }

    await api.get("/PARevisaoExterna/getAcompanhamentoDefeitos", { params })
      .then(res => res.status === 200 && res.data.length > 0 ? setListAcompanhamentoDefeitos(res.data) : null)
      .catch(e => toast.error(`Erro ao preencher os campos de seleção. Erro na requisição: ${e}`));
  }

  const handleDeleteDefeito = async (el) => {
    const params = {
      Origem: el.Origem,
      OrdemProducao: el.OrdemProducao,
      Produto: el.Produto,
      CorProduto: el.CorProduto,
      QtdePecas: el.QtdePecas,
      IDGrupoAnalise: el.IDGrupoAnalise,
      IDDefeito: el.IDDefeito,
      CONSIDERACAO: el.CONSIDERACAO ? el.CONSIDERACAO : ''
    }
    await api.post("/PARevisaoExterna/ExecDeleteDefeito", params)
      .then(res => {
        if (res.status === 200) {
          toast.success('Registro excluído com sucesso!');
          fetchAcompanhamentoDefeitos(activeObj);
        } else {
          toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
      })
      .catch(e => {
        console.error(e);
        toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
      });

  }

  const handleProcessRevisaoExterna = async (e) => {
    e.preventDefault();
    const errs = handleValidationRevisaoExterna();
    if (Object.keys(errs).length > 0) {
      return setFormErrs(errs);
    } else {
      const params = {
        DataRegistro: toDatetime(new Date()),

        Origem: activeObj.FORNECEDOR,
        OrdemProducao: activeObj.ORDEM_PRODUCAO,
        CorOP: activeObj.COR_OP,
        Marca: activeObj.MARCA,
        Produto: activeObj.PRODUTO,
        DescricaoProduto: activeObj.DESC_PRODUTO,
        CorProduto: activeObj.COR_PRODUTO,
        DescricaoCorProduto: activeObj.DESC_COR,
        Linha: activeObj.LINHA,
        QtdePeçasOP: activeObj.QTDE,
        DataRevisao: formObj.DataInspecao,
        IDStatusFinal: formObj.ResultadoInspecao.value,
        Observacao: formObj.ObsInspecao
      }
      await api.post("/PARevisaoExterna/ExecCadastroRevisaoExterna", params)
        .then(res => {
          if (res.status === 200) {
            toast.success('Dados inseridos com sucesso!');
            setFormObj({});
            setFormErrs({});
            setActiveObj({});
            setFormOptions({});
            setFormErrs({});
            setFilterErrs({});
            fetchProcessos();
            setShowModalResultado(false);
          } else {
            toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
          }
        })
        .catch(e => {
          console.error(e);
          toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
        });
    }
  }

  const handleValidationRevisaoExterna = () => {
    const { DataInspecao, ResultadoInspecao, ObsInspecao } = formObj;
    const errs = {}
    if (!DataInspecao) errs.DataInspecao = 'Selecione a data de inspeção';
    if (!ResultadoInspecao) errs.ResultadoInspecao = 'Selecione o resultado da inspeção';
    if (!ObsInspecao) formObj.ObsInspecao = '';
    return errs;
  }

  const handleInsertFornecedor = async () => {
    if (novoFornecedor.length < 2 || novoFornecedor.length > 50) return setNovoFornecedorErr(true);

    await api.post('/PARevisaoExterna/InsertNovoFornecedorFiltro', { novoFornecedor: novoFornecedor.toUpperCase() })
      .then(res => {
        toast.success('Fornecedor inserido com sucesso!');
        setNovoFornecedor('');
      }).catch(e => {
        console.error(e);
        toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
      });
  }

  useEffect(() => {
    fetchProcessos();
  }, []);

  return (
    <div>
      <h3 className="m-3">Revisão Externa - PA</h3>

      <article className={styles.articleMargim}>
        <Form className={styles.form}>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              OP
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={'OP'}
              value={filterObj.OP || ''}
              onChange={e => handleNumberDotMask('OP', e.target.value)}
              isInvalid={!!filterErrs.OP}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.produto}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>Referência</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Produto'}
              value={filterObj.produto || ''}
              onChange={e => handleNumberDotMask('produto', e.target.value)}
              isInvalid={!!filterErrs.produto}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.produto}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Cor Produto
            </Form.Label>
            <Form.Control
              type="number"
              placeholder={'Cor do produto'}
              value={filterObj.corProduto || ''}
              onChange={e => setFilters('corProduto', e.target.value)}
              isInvalid={!!filterErrs.corProduto}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.corProduto}</Form.Control.Feedback>
          </Form.Group>
          <Button
            className={styles.formGroup}
            variant="primary"
            type="button"
            onClick={handleFilteredSearch}
          >
            Buscar
          </Button>

          <Form.Group
            className={styles.formGroup}
            style={{
              display: isAdmin ? 'block' : 'none',
              marginLeft: 'auto'
            }}
          >
            <Form.Label className={styles.formLabel}>
              Inserir Novo Fornecedor
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={'Novo Fornecedor'}
              value={novoFornecedor || ''}
              onFocus={() => setNovoFornecedorErr(false)}
              onChange={e => setNovoFornecedor(e.target.value)}
              isInvalid={(novoFornecedor.length < 2 || novoFornecedor.length > 50) && novoFornecedorErr === true}
            />
            <Form.Control.Feedback type='invalid'>{'O fornecedor deve ter pelo menos 2 caracteres'}</Form.Control.Feedback>
          </Form.Group>
          <Button
            className={styles.formGroup}
            variant="primary"
            type="button"
            style={{ display: isAdmin ? 'block' : 'none' }}
            onClick={handleInsertFornecedor}
          >
            Inserir
          </Button>
        </Form>
      </article>
      {carregando ?
        <Carregando></Carregando>
        : ''
      }

      <article className={styles.articleGray}>
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className={styles.blockTitle}>OP</th>
              <th className={styles.blockTitle}>COR OP</th>
              <th className={styles.blockTitle}>FORNECEDOR</th>
              <th className={styles.blockTitle}>MARCA</th>
              <th className={styles.blockTitle}>PRODUTO</th>
              <th className={styles.blockTitle}>DESCRIÇÃO PRODUTO</th>
              <th className={styles.blockTitle}>COR PRODUTO</th>
              <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
              <th className={styles.blockTitle}>LINHA</th>
              <th className={styles.blockTitle}>QTDE</th>
            </tr>
          </thead>
          <tbody>
            {mainArr.map((el, index) => {
              return (
                <tr className={styles.tableLines} key={index}>
                  <td>
                    <i className={styles.iconCursor} onClick={() => { handleShowAcompanhamento(el); }}>
                      <MaterialIcon
                        icon="plagiarism"
                        size={30}
                        color='#0D6EFD'
                      />
                    </i>
                  </td>
                  <td>
                    <i className={styles.iconCursor} onClick={() => handleShowResultado(el)}>
                      <MaterialIcon
                        icon="task"
                        size={30}
                        color='#01A34C'
                      />
                    </i>
                  </td>
                  <td className={styles.tableLines}>{el.ORDEM_PRODUCAO}</td>
                  <td className={styles.tableLines}>{el.COR_OP}</td>
                  <td className={styles.tableLines}>{el.FORNECEDOR}</td>
                  <td className={styles.tableLines}>{el.MARCA}</td>
                  <td className={styles.tableLines}>{el.PRODUTO}</td>
                  <td className={styles.tableLines}>{el.DESC_PRODUTO}</td>
                  <td className={styles.tableLines}>{el.COR_PRODUTO}</td>
                  <td className={styles.tableLines}>{el.DESC_COR}</td>
                  <td className={styles.tableLines}>{el.LINHA}</td>
                  <td className={styles.tableLines}>{el.QTDE}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </article>

      <Modal
        show={showModalAcompanhamento}
        onHide={handleCloseAcompanhamento}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Revisão Externo - Acompanhamento</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-3">
          {/* Accordion de Identificação do Produto */}
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Dados da OP</Accordion.Header>
              <Accordion.Body>
                <article className={styles.monitorInfo}>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>OP:</span>
                      <span className={styles.fontBlue}>{activeObj.ORDEM_PRODUCAO}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Cor da OP:</span>
                      <span className={styles.fontBlue}>{activeObj.COR_OP}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Produto:</span>
                      <span className={styles.fontBlue}>{activeObj.PRODUTO}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Cor produto:</span>
                      <span className={styles.fontBlue}>{activeObj.COR_PRODUTO}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Descrição produto:</span>
                      <span className={styles.fontBlue}>{activeObj.DESC_PRODUTO}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Descrição cor:</span>
                      <span className={styles.fontBlue}>{activeObj.DESC_COR}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Fornecedor:</span>
                      <span className={styles.fontBlue}>{activeObj.FORNECEDOR}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Marca:</span>
                      <span className={styles.fontBlue}>{activeObj.MARCA}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Qtde peças:</span>
                      <span className={styles.fontBlue}>{activeObj.QTDE}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Linha:</span>
                      <span className={styles.fontBlue}>{activeObj.LINHA}</span>
                    </div>
                  </div>
                </article>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <h5 className={styles.articleTitle}>Análise</h5>

          <article className={styles.articleGrayModal}>
            <div className={styles.tableModal}>
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th></th>
                    <th className={styles.blockTitle}>ORIGEM</th>
                    <th className={styles.blockTitle}>OP</th>
                    <th className={styles.blockTitle}>PRODUTO</th>
                    <th className={styles.blockTitle}>COR</th>
                    <th className={styles.blockTitle}>QTDE</th>
                    <th className={styles.blockTitle}>DATA VISITA</th>
                    <th className={styles.blockTitle}>ID FASE</th>
                    <th className={styles.blockTitle}>FASE</th>
                    <th className={styles.blockTitle}>CONSIDERAÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {listAcompanhamentoVisitas.map((el, index) => {
                    return (
                      <tr className={styles.tableLines} key={`rowAnalise-${index}`}>
                        <td>
                          <i className={styles.iconCursor} onClick={() => { handleDeleteAcompanhamento(el); }}>
                            <MaterialIcon
                              icon="delete"
                              size={25}
                              color='#BB271A'
                            />
                          </i>
                        </td>
                        <td className={styles.tableLines}>{el.Origem}</td>
                        <td className={styles.tableLines}>{el.OP}</td>
                        <td className={styles.tableLines}>{el.Produto}</td>
                        <td className={styles.tableLines}>{el.Cor}</td>
                        <td className={styles.tableLines}>{el.Qtde}</td>
                        <td className={styles.tableLines}>{toUTCDDMMYYYY(el.DataVisita)}</td>
                        <td className={styles.tableLines}>{el.IDFaseOperacao}</td>
                        <td className={styles.tableLines}>{el.DescricaoFase}</td>
                        <td className={styles.tableLines}>{el.ConsideracaoFase}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>

            <Form className={styles.modalForm}>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabelInfo}>
                  1. Data Fase:
                </Form.Label>
                <Form.Control type="date"
                  value={formObj.DataFase || ''}
                  onChange={e => { setFormObj({ ...formObj, DataFase: e.target.value }); clearErrs('DataFase'); }}
                  isInvalid={!!formErrs.DataFase}
                />
                <Form.Control.Feedback type='invalid'>{formErrs.DataFase}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabel}>2. Fase Operação:</Form.Label>
                <Select
                  value={formObj.FaseOperacao || ''}
                  options={formOptions.listFaseOperacao}
                  onChange={obj => { setFormObj({ ...formObj, FaseOperacao: obj }); clearErrs() }}
                  escapeClearsValue={true}
                  styles={{
                    control: (provided) => (
                      formErrs.FaseOperacao ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                    )
                  }}
                />
                <div className={formErrs.FaseOperacao ? styles.showFeedback : styles.hidden}>{formErrs.FaseOperacao}</div>

              </Form.Group>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabel}>
                  3. Observação da Fase <span className={styles.spanOpcional}>(Opcional)</span> :
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  className={styles.formTextArea}
                  value={formObj.ObsFase || ''}
                  onChange={e => setFormObj({ ...formObj, ObsFase: e.target.value })}
                  isInvalid={formErrs.ObsFase}
                />
              </Form.Group>
            </Form>

            <Form className={styles.modalFormBtn}>
              <Button
                onClick={handleProcessAcompanhamento}
              >
                Incluir Análise
              </Button>
            </Form>
          </article>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalResultado}
        onHide={handleCloseResultado}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Revisão Externa - Resultado</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-3">
          {/* Accordion de Identificação do Produto */}
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Dados do Produto</Accordion.Header>
              <Accordion.Body>
                <article className={styles.monitorInfo}>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>OP:</span>
                      <span className={styles.fontBlue}>{activeObj.ORDEM_PRODUCAO}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Cor da OP:</span>
                      <span className={styles.fontBlue}>{activeObj.COR_OP}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Produto:</span>
                      <span className={styles.fontBlue}>{activeObj.PRODUTO}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Cor produto:</span>
                      <span className={styles.fontBlue}>{activeObj.COR_PRODUTO}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Descrição produto:</span>
                      <span className={styles.fontBlue}>{activeObj.DESC_PRODUTO}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Descrição cor:</span>
                      <span className={styles.fontBlue}>{activeObj.DESC_COR}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Fornecedor:</span>
                      <span className={styles.fontBlue}>{activeObj.FORNECEDOR}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Marca:</span>
                      <span className={styles.fontBlue}>{activeObj.MARCA}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Qtde peças:</span>
                      <span className={styles.fontBlue}>{activeObj.QTDE}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Linha:</span>
                      <span className={styles.fontBlue}>{activeObj.LINHA}</span>
                    </div>
                  </div>
                </article>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <h5 className={styles.articleTitle}>Inspeção</h5>

          <article className={styles.articleGrayModalInspecao}>
            <div className={styles.tableModal}>
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th className={styles.blockTitle}>DATAS DAS VISITAS</th>
                    <th className={styles.blockTitle}>FASES</th>
                    <th className={styles.blockTitle}>CONSIDERAÇÕES DAS FASES</th>
                  </tr>
                </thead>
                <tbody>
                  {listAcompanhamentoVisitas.map((el, index) => {
                    return (
                      <tr className={styles.tableLines}>
                        <td className={styles.tableLines}>{toUTCDDMMYYYY(el.DataVisita)}</td>
                        <td className={styles.tableLines}>{el.DescricaoFase}</td>
                        <td className={styles.tableLines}>{el.ConsideracaoFase}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>

            <Form className={styles.modalForm}>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabelInfo}>
                  1. Data Inspeção:
                </Form.Label>
                <Form.Control type="date"
                  value={formObj.DataInspecao || ''}
                  onChange={e => { setFormObj({ ...formObj, DataInspecao: e.target.value }); clearErrs('DataInspecao'); }}
                  isInvalid={!!formErrs.DataInspecao}
                />
                <Form.Control.Feedback type='invalid'>{formErrs.DataInspecao}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabel}>2. Resultado Inspeção:</Form.Label>
                <Select
                  value={formObj.ResultadoInspecao || ''}
                  options={formOptions.listResultadoInspecao}
                  onChange={obj => { setFormObj({ ...formObj, ResultadoInspecao: obj }); clearErrs() }}
                  escapeClearsValue={true}
                  styles={{
                    control: (provided) => (
                      formErrs.ResultadoInspecao ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                    )
                  }}
                />
                <div className={formErrs.ResultadoInspecao ? styles.showFeedback : styles.hidden}>{formErrs.ResultadoInspecao}</div>

              </Form.Group>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabel}>
                  3. Observações <span className={styles.spanOpcional}>(Opcional)</span> :
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  className={styles.formTextArea}
                  onChange={e => { setFormObj({ ...formObj, ObsInspecao: e.target.value }); clearErrs('ObsInspecao'); }}
                />
              </Form.Group>
            </Form>
          </article>

          <h5 className={styles.articleTitle}>Defeitos</h5>

          <article className={styles.articleGrayModal}>
            <div className={styles.tableModal}>
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th></th>
                    <th className={styles.blockTitle}>ORIGEM</th>
                    <th className={styles.blockTitle}>OP</th>
                    <th className={styles.blockTitle}>PRODUTO</th>
                    <th className={styles.blockTitle}>COR</th>
                    <th className={styles.blockTitle}>QTDE</th>
                    <th className={styles.blockTitle}>GRUPO</th>
                    <th className={styles.blockTitle}>DEFEITOE</th>
                    <th className={styles.blockTitle}>CONSIDERAÇÃO</th>
                    <th className={styles.blockTitle}>ID GRUPO</th>
                    <th className={styles.blockTitle}>ID DEFEITO</th>
                  </tr>
                </thead>
                <tbody>
                  {listAcompanhamentoDefeitos.map((el, index) => {
                    return (
                      <tr className={styles.tableLines}>
                        <td>
                          <i className={styles.iconCursor} onClick={() => { handleDeleteDefeito(el); }}>
                            <MaterialIcon
                              icon="delete"
                              size={25}
                              color='#BB271A'
                            />
                          </i>
                        </td>
                        <td className={styles.tableLines}>{el.Origem}</td>
                        <td className={styles.tableLines}>{el.OrdemProducao}</td>
                        <td className={styles.tableLines}>{el.Produto}</td>
                        <td className={styles.tableLines}>{el.CorProduto}</td>
                        <td className={styles.tableLines}>{el.QtdePecas}</td>
                        <td className={styles.tableLines}>{el.GrupoDefeito}</td>
                        <td className={styles.tableLines}>{el.DescricaoDefeito}</td>
                        <td className={styles.tableLines}>{el.CONSIDERACAO}</td>
                        <td className={styles.tableLines}>{el.IDGrupoAnalise}</td>
                        <td className={styles.tableLines}>{el.IDDefeito}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>

            <Form className={styles.modalForm}>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabel}>1. Grupo de Avaliação:</Form.Label>
                <Select
                  value={formObj.GrupoAvaliacao || ''}
                  options={formOptions.listGrupoAvaliacao}
                  onChange={obj => { setFormObj({ ...formObj, GrupoAvaliacao: obj, DefeitoEncontrado: '' }); fetchListDefeitos(obj); clearErrs() }}
                  escapeClearsValue={true}
                  styles={{
                    control: (provided) => (
                      formErrs.GrupoAvaliacao ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                    )
                  }}
                />
                <div className={formErrs.GrupoAvaliacao ? styles.showFeedback : styles.hidden}>{formErrs.GrupoAvaliacao}</div>

              </Form.Group>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabel}>2. Defeito Encontrado no Grupo:</Form.Label>
                <Select
                  value={formObj.DefeitoEncontrado || ''}
                  options={formOptions.listDefeitosEncontrados}
                  onChange={obj => { setFormObj({ ...formObj, DefeitoEncontrado: obj }); clearErrs() }}
                  escapeClearsValue={true}
                  styles={{
                    control: (provided) => (
                      formErrs.DefeitoEncontrado ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                    )
                  }}
                />
                <div className={formErrs.DefeitoEncontrado ? styles.showFeedback : styles.hidden}>{formErrs.DefeitoEncontrado}</div>

              </Form.Group>
              <Form.Group className={styles.modalFormGroup}>
                <Form.Label className={styles.formLabel}>
                  3. Considerações <span className={styles.spanOpcional}>(Opcional)</span> :
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  className={styles.formTextArea}
                  value={formObj.ObsDefeito ? formObj.ObsDefeito : ''}
                  onChange={e => { setFormObj({ ...formObj, ObsDefeito: e.target.value }); clearErrs('ObsDefeito'); }}
                />
              </Form.Group>
            </Form>

            <Form className={styles.modalFormBtn}>
              <Button
                onClick={handleProcessDefeito}
              >
                Incluir Defeito
              </Button>
            </Form>
          </article>

        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button variant="outline-danger" onClick={handleCloseResultado}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleProcessRevisaoExterna}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default PARevisaoExterna;