import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';
import { useEnfesto } from '../../../../contexts/Corte/ControleEnfesto/EnfestoContext'; 

const MainTable = () => {

    const { columns, mainData, loading, handleShowInicia } = useEnfesto();

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                columns={columns}
                data={mainData}
                pending={loading}
                rowClickedFn={handleShowInicia}
            />
        </section>
    );
};

export default MainTable;
