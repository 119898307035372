import { Table, Form, InputGroup } from "react-bootstrap";

import { formatarDataBR } from '../../utils/dateUtils'
import styles from '../../styles/PlanejamentoDiarioCorte.module.scss';

const searchIcon = (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
 <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>)

function TableDiarioCorte ({ diariosCortes, agendamentos, handleAgendamentos, definirTipoCorte, objetoSelecionado }) {
  const { diarioCortes, TiposCortes, PlantaCortes, Pendencias } = diariosCortes
  
  const obterCor = (cor) => {
    const cores = {
      Cyan: '#00bcd4',
      Yellow: '#ffc107',
      Green: '#4caf50',
      Black: '#000000',
      Red: '#ff0000',
    };
  
    return cores[cor] || null;
  };

  function obterValorItem(corte) {
    const checarRetorno = agendamentos.find((agendamento) => {
      return agendamento.OrdemProducao === corte.OrdemProducao && agendamento.OrdemServico === corte.OrdemServico;
    });
  
    return checarRetorno ? checarRetorno.dataAtual : null;
  }

  return (
      <Table responsive>
        <thead>
          <tr>
            <th className={styles.blockTitle}>Data agendamento corte</th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('OS', 'OrdemServico')}>OS<span> {searchIcon} </span></th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('OP', 'OrdemProducao')}>OP<span> {searchIcon} </span></th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Cor OP', 'CorOP')} >Cor OP<span> {searchIcon} </span></th>
            <th className={styles.blockTitle} >Produto</th>
            <th className={styles.blockTitle}>Grade plan</th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Tipo corte', 'TipoCorte')}>Tipo corte <span> {searchIcon} </span></th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Planta corte', 'PlantaCorte')} >Planta corte <span> {searchIcon} </span></th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Pendencia', 'Pendencia')}>Pendencias <span> {searchIcon} </span></th>
            <th className={styles.blockTitle}>Inicio real</th>
            <th className={styles.blockTitle}>Data consumo</th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Status', 'Status')}>Status <span> {searchIcon} </span></th>
            <th className={styles.blockTitle}>LTPOS consumo</th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Colecao', 'Colecao')}>Coleção <span> {searchIcon} </span></th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Lancamento', 'Lancamento')}>Lançamento <span> {searchIcon} </span></th>
            <th className={styles.blockTitle}>Data alteração próxima cor</th>
            <th className={styles.blockTitle}  onClick={() => objetoSelecionado('Localizacao', 'Localizacao')}>Localização <span> {searchIcon} </span></th>
            <th className={styles.blockTitle} onClick={() => objetoSelecionado('Tecidos', 'QtdeTecidos')}>Tecidos <span> {searchIcon} </span></th>
            <th className={styles.blockTitle}>Data alvo emissão</th>
          </tr>
        </thead>
        <tbody>
          
          {diarioCortes.length > 0 ? diarioCortes.map((cortes) => {
            return (
              <tr className={styles.tableLines}>
                <td className={styles.tableLines} style={{ 'backgroundColor': '#FDE8A3'}}>
                  <InputGroup>
                    <Form.Control
                      style={{ 'backgroundColor': '#FDE8A3'}}
                      placeholder=""
                      aria-label="data-agendamento-corte"
                      type="date"
                      value={obterValorItem(cortes) || '' }
                      onChange={(e) => handleAgendamentos({ dataAtual: e.target.value, ...cortes }) }
                    />
                  </InputGroup>
                </td>
                <td className={styles.tableLines}>{cortes.OrdemServico}</td>
                <td className={styles.tableLines}>{cortes.OrdemProducao}</td>
                <td className={styles.tableLines} style={{ color: obterCor(cortes.CorOP) }}>{cortes.CorOP}</td>
                <td className={styles.tableLines}>{cortes.Produto}</td>
                <td className={styles.tableLines}>{cortes.GradePlan}</td>
                <td className={styles.tableLines} style={{ 'backgroundColor': cortes.TipoCorte ? undefined : '#FDE8A3' }}>
                  <Form.Select size="md"  value={cortes.TipoCorte} onChange={(e) => { definirTipoCorte('TipoCorte', e.target.value, cortes) }}>
                  <option selected={!!cortes.TipoCorte} value="">Selecione uma opção</option>
                    {TiposCortes.map((tipoCorte) => {
                      return <option selected={!!cortes.TipoCorte} value={tipoCorte.TipoCorte} >{tipoCorte.TipoCorte}</option>
                    })}
                  </Form.Select>
                </td>
                <td className={styles.tableLines} style={{ 'backgroundColor': cortes.PlantaCorte ? undefined : '#FDE8A3' }}>
                  <Form.Select size="md"  value={cortes.PlantaCorte} onChange={(e) => {definirTipoCorte('PlantaCorte', e.target.value, cortes)}}>
                    <option value="">Selecione uma opção</option>
                    {PlantaCortes.map((plantaCorte) => {
                      return <option selected={!!cortes.PlantaCorte} value={plantaCorte.PlantaCorte} >{plantaCorte.PlantaCorte}</option>
                    })}
                  </Form.Select>
                </td>
                <td className={styles.tableLines} style={{ 'backgroundColor': cortes.Pendencia ? undefined : '#FDE8A3' }}>
                  <Form.Select size="md" value={cortes.Pendencia} onChange={(e) => {definirTipoCorte('Pendencia', e.target.value, cortes)}}>
                    <option  value="">Selecione uma opção</option> 
                    {Pendencias.map((pendencia) => {
                      return <option selected={!!cortes.Pendencia} value={pendencia.Pendencia}>{pendencia.Pendencia}</option>
                    })}
                  </Form.Select>
                </td>
                <td className={styles.tableLines}>{formatarDataBR(cortes.InicioReal)}</td>
                <td className={styles.tableLines}>{formatarDataBR(cortes.DataConsumo)}</td>
                <td className={styles.tableLines}>{cortes.Status}</td>
                <td className={styles.tableLines}>{cortes.LTPosConsumo}</td>
                <td className={styles.tableLines}>{cortes.Colecao}</td>
                <td className={styles.tableLines}>{cortes.Lancamento}</td>
                <td className={styles.tableLines}>{formatarDataBR(cortes.DataAlteracaoProxCor)}</td>
                <td className={styles.tableLines}>{cortes.Localizacao}</td>
                <td className={styles.tableLines}>{cortes.QtdeTecidos}</td>
                <td className={styles.tableLines}>{formatarDataBR(cortes.DataAlvoEmissao)}</td>
                </tr>
            );
          }) : null}
        </tbody>
      </Table>
  )
}

export default TableDiarioCorte;