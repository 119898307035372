import React from "react";
import ImpressaoEtiquetas from "../../components/PA/PecaPiloto/ImpressaoEtiquetas/index";

import styles from '../../styles/AdmImpressaoEtiquetas.module.scss';
const AdmImpressaoEtiquetas = () => {
    return (
        <div className="p-2">
          <div className="title--impressao-etiquetas">
            <h3>Impressão Etiquetas PA</h3>
          </div>
          <ImpressaoEtiquetas />
        </div>
    );
};

export default AdmImpressaoEtiquetas;