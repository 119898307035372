import React, { useState } from "react";
import { Button, Table, Form, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import styles from "../../../../styles/MonitorRevisaoConsulta.module.scss";
import { api, retryApi } from "../../../../services/api";
import Carregando from "../../../../components/Carregando"
import Identificacao from "../Identificacao"
import { toUTCDDMMYYYY } from "../../../../utils/dateUtils"

function MonitorRevisaoConsulta() {
    const [produto, setProduto] = useState("");
    const [corProduto, setCorProduto] = useState("");
    const [nfEntrada, setNfEntrada] = useState("");
    const [itens, setItens] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState('');

    const handleClose = () => setShowModal(false);

    async function handleForm() {
        const params = {
            Produto: produto,
            ProdutoCor: corProduto,
            NFEntrada: nfEntrada
        };

        try {
            setCarregando(true);
            const lista = await retryApi('get', "PArevisao/MonitorBuscar", { params });
            if (lista.data.length === 0) toast.warning('Nenhum registro encontrado!');
            setItens(lista.data);
            setCarregando(false);
        } catch (error) {
            toast.error('Ocorreu um erro ao buscar pela lista de processos a revisar. Por favor recarregue a página e tente novamente.');
            return false;
        }
    }

    function handleRevisar(item) {
        setItemSelecionado(item);
        setShowModal(true);
    }

    function handleCloseAll() {
        setProduto('');
        setCorProduto('');
        setNfEntrada('');
        handleClose();
        handleForm();
    }

    return (
        <div>
            <article className={styles.articleMargim}>
                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label className="form-label-input">Produto</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Produto"
                            value={produto}
                            onChange={(event) => setProduto(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label className="form-label-input">
                            Cor Produto
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={corProduto}
                            placeholder="Cor Produto"
                            onChange={(event) => setCorProduto(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label className="form-label-input">
                            NF Entrada
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="NF Entrada"
                            value={nfEntrada}
                            onChange={(event) => setNfEntrada(event.target.value)}
                        />
                    </Form.Group>
                    <Button
                        className={styles.formGroup}
                        variant="primary"
                        type="button"
                        onClick={handleForm}
                    >
                        Buscar
                    </Button>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover
                    />
                </Form>
            </article>

            <article className={styles.articleGray}>
                {carregando ?
                    <Carregando></Carregando>
                    :
                    <Table className="m-2" >
                        <thead>
                            <tr>
                                <th></th>
                                <th className={styles.blockTitle}>PRODUTO</th>
                                <th className={styles.blockTitle}>COR PRODUTO</th>
                                <th className={styles.blockTitle}>NF ENTRADA</th>
                                <th className={styles.blockTitle}>EMPRESA</th>
                                <th className={styles.blockTitle}>SERIE_NF</th>
                                <th className={styles.blockTitle}>DESTINO</th>
                                <th className={styles.blockTitle}>ORIGEM</th>
                                <th className={styles.blockTitle}>ORDEM PRODUÇÃO</th>
                                <th className={styles.blockTitle}>ORDEM SERVIÇO</th>
                                <th className={styles.blockTitle}>RECEBIMENTO</th>
                                <th className={styles.blockTitle}>DIGITAÇÃO</th>
                                <th className={styles.blockTitle}>QTDE. ENTRADA</th>
                                <th className={styles.blockTitle}>DESCRIÇÃO PRODUTO</th>
                                <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                                <th className={styles.blockTitle}>COLEÇÃO</th>
                                <th className={styles.blockTitle}>GRIFFE</th>
                                <th className={styles.blockTitle}>COD. MARCA</th>
                                <th className={styles.blockTitle}>MARCA</th>
                                <th className={styles.blockTitle}>LINHA</th>
                                <th className={styles.blockTitle}>TIPO PRODUTO</th>
                                <th className={styles.blockTitle}>GRUPO PRODUTO</th>
                                <th className={styles.blockTitle}>FABRICANTE</th>
                                <th className={styles.blockTitle}>ENTRADA REVISÃO</th>
                                <th className={styles.blockTitle}>FILIAL REVISÃO</th>
                                <th className={styles.blockTitle}>RESPONSÁVEL ENTRADA</th>
                                <th className={styles.blockTitle}>REVISÃO OK</th>
                                <th className={styles.blockTitle}>CHECKLIST OK</th>
                                <th className={styles.blockTitle}>STATUS QUALIDADE</th>
                                <th className={styles.blockTitle}>SLA</th>
                                <th className={styles.blockTitle}>PREÇO</th>
                                <th className={styles.blockTitle}>COMPOSIÇÃO</th>
                                <th className={styles.blockTitle}>RECEBIDO</th>
                                <th className={styles.blockTitle}>INDICA PENDÊNCIA</th>
                                <th className={styles.blockTitle}>INDICA PENDÊNCIA CHECKLIST</th>
                            </tr>
                        </thead>
                        <tbody>
                            {itens.map((item, index) => {
                                return (
                                    <tr key={index} className={styles.tableLines}>
                                        <td>
                                            <Button
                                                onClick={() => handleRevisar(item)}
                                            >
                                                Revisar
                                            </Button>
                                        </td>
                                        <td className={styles.tableLines}>{item.Produto}</td>
                                        <td className={styles.tableLines}>{item.CorProduto}</td>
                                        <td className={styles.tableLines}>{item.NFEntrada}</td>
                                        <td className={styles.tableLines}>{item.Empresa}</td>
                                        <td className={styles.tableLines}>{item.SerieNF}</td>
                                        <td className={styles.tableLines}>{item.Destino}</td>
                                        <td className={styles.tableLines}>{item.Origem}</td>
                                        <td className={styles.tableLines}>{item.OrdemProducao}</td>
                                        <td className={styles.tableLines}>{item.OrdemServico}</td>
                                        <td className={styles.tableLines}>{toUTCDDMMYYYY(item.Recebimento)}</td>
                                        <td className={styles.tableLines}>{toUTCDDMMYYYY(item.DataDigitacao)}</td>
                                        <td className={styles.tableLines}>{item.QtdeEntrada}</td>
                                        <td className={styles.tableLines}>{item.DescricaoProduto}</td>
                                        <td className={styles.tableLines}>{item.DescricaoCor}</td>
                                        <td className={styles.tableLines}>{item.Colecao}</td>
                                        <td className={styles.tableLines}>{item.Griffe}</td>
                                        <td className={styles.tableLines}>{item.CodMarca}</td>
                                        <td className={styles.tableLines}>{item.Marca}</td>
                                        <td className={styles.tableLines}>{item.Linha}</td>
                                        <td className={styles.tableLines}>{item.TipoProduto}</td>
                                        <td className={styles.tableLines}>{item.GrupoProduto}</td>
                                        <td className={styles.tableLines}>{item.Fabricante}</td>
                                        <td className={styles.tableLines}>{toUTCDDMMYYYY(item.DataEntradaRevisao)}</td>
                                        <td className={styles.tableLines}>{item.FilialRevisao}</td>
                                        <td className={styles.tableLines}>{item.ResponsavelEntradaFiscal}</td>
                                        <td className={styles.tableLines}>{item.StatusRevisao}</td>
                                        <td className={styles.tableLines}>{item.StatusChecklist}</td>
                                        <td className={styles.tableLines}>{item.StatusADMCQ}</td>
                                        <td className={styles.tableLines}>{item.StatusSLA}</td>
                                        <td className={styles.tableLines}>{item.Preco}</td>
                                        <td className={styles.tableLines}>{item.Composicao}</td>
                                        <td className={styles.tableLines}>{item.RecPiloto}</td>
                                        <td className={styles.tableLines}>{item.IndicaPendenciaRevisao}</td>
                                        <td className={styles.tableLines}>{item.IndicaPendenciaChecklist}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                }
            </article>

            <Modal show={showModal}
                onHide={handleClose}
                backdrop="static"
                fullscreen={true}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Identificação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Identificacao item={itemSelecionado} handleClose={handleCloseAll}></Identificacao>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default MonitorRevisaoConsulta;
