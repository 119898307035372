import React from "react";
import MonitorRevisaoIdentificacao from "../../components/PA/MonitorRevisao/Identificacao/index";

const Identificacao = () => {
    return (
        <div className="p-2">
          <h3>Identificação</h3>
          <MonitorRevisaoIdentificacao />
        </div>
    );
};

export default Identificacao;