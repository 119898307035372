import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 
import { toUTCDDMMYYYY } from '../../../../utils/dateUtils';

const MainTable = () => {

    const { handleShowGrade, loading, mainData, toggledClearRows, checklistColumns, filtro } = useDistribuicaoDemanda();

    const filteredItems = mainData.filter(item => 
        item.OrdemServico.includes(filtro) ||
        toUTCDDMMYYYY(item.DataPriorizacao).includes(filtro) ||
        item.TipoCorte.toLowerCase().includes(filtro.toLowerCase()) ||
        item.Equipe.toLowerCase().includes(filtro.toLowerCase()) 
    );

    const diffDias = (diffMs) => {
        return Math.floor(diffMs / (1000 * 60 * 60 * 24)) 
    }

    const conditionalRowStyles = [
        {
          when: row => {
            const diffMs = new Date() - new Date(row.DataPriorizacao)
            return diffDias(diffMs) >= 2
          },
          style: { backgroundColor: '#F05A7E' },
        },
        {
          when: row => {
            const diffMs = new Date() - new Date(row.DataPriorizacao)
            return diffDias(diffMs) === 1
          },
          style: { DataPriorizacao: '#FFFFC5' },
        },
    ];

    return (
        <section style={{ margin: '0px 20px 20px' }}>
            <Tabela
                columns={checklistColumns}
                data={filteredItems}
                pending={loading}
                rowClickedFn={handleShowGrade}
                clearSelectedRows={toggledClearRows}
                conditionalRowStyles={conditionalRowStyles}
            />
        </section>
    );
};

export default MainTable;
