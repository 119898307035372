import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 
import DadosAccordion from './DadosAccordion';

const ModalEnfest = () => {

    const { folhasAlocadas, handleFolhasAlocadas, enfestSelecionadoUm, enfestSelecionadoDois, setEnfestSelecionadoUm, setEnfestSelecionadoDois, enfestData, showEnfest, handleCloseEnfest, handleShowConfirmacao } = useDistribuicaoDemanda();

    return (
        <>
            <Modal
                show={showEnfest}
                onHide={handleCloseEnfest}
                backdrop="static"
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header>
                    <Modal.Title>Selecione a dupla de enfestadores:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DadosAccordion/>

                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label>
                                <strong>Enfestador 1:</strong>
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={true}
                                isOptionSelected={e => e === enfestSelecionadoUm}
                                isOptionDisabled={e => e === enfestSelecionadoUm}
                                value={enfestSelecionadoUm || ''}
                                options={enfestData}
                                getOptionLabel={option => option.Nome}
                                getOptionValue={option => option.IdOperadores}
                                onChange={e => setEnfestSelecionadoUm(e)}
                                placeholder='Enfestador 1'
                            />
                        </Form.Group>

                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label>
                                <strong>Enfestador 2:</strong>
                            </Form.Label>
                            <Select
                                closeMenuOnSelect={true}
                                isOptionSelected={e => e === enfestSelecionadoDois}
                                isOptionDisabled={e => e === enfestSelecionadoDois}
                                value={enfestSelecionadoDois || ''}
                                options={enfestData}
                                getOptionLabel={option => option.Nome}
                                getOptionValue={option => option.IdOperadores}
                                onChange={e => setEnfestSelecionadoDois(e)}
                                placeholder='Enfestador 2'
                            />
                        </Form.Group>

                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label>
                                <strong>Quantidade de folhas alocada para a dupla:</strong>
                            </Form.Label>
                            <Form.Control
                                type='text'
                                value={folhasAlocadas || ''}
                                onChange={e => handleFolhasAlocadas(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseEnfest}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={handleShowConfirmacao}
                    >
                        Avançar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalEnfest;
