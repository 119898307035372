const FilterList = [
  {
    key: 'nf_entrada',
    label: 'NF Entrada',
    type: 'text',
    subFn: value => { return value.replace(/[^.0-9]*/g, '') },
    validationFn: value => { if (!value || value.length < 4) return false; return true; }
  },
  {
    key: 'produto',
    label: 'Produto',
    type: 'text',
    subFn: value => { return value.replace(/[^.0-9]*/g, '') },
    validationFn: value => { if (!value || value.length < 4) return false; return true; }
  },
  {
    key: 'cor_produto',
    label: 'Cor Produto',
    type: 'text',
    subFn: value => { return value.replace(/[^.0-9]*/g, '') },
    validationFn: value => { if (!value || value.length < 4) return false; return true; }
  },
  {
    key: 'romaneio',
    label: 'Romaneio',
    type: 'text',
    subFn: value => { return value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() },
    validationFn: value => { if (!value || value.length < 4) return false; return true; }
  },
  {
    key: 'produto_AN',
    label: 'Produto',
    type: 'text',
    subFn: value => { return value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() },
    validationFn: value => { if (!value || value.length < 4) return false; return true; }
  },
  {
    key: 'cor_produto_AN',
    label: 'Cor Produto',
    type: 'text',
    subFn: value => { return value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() },
    validationFn: value => { if (!value || value.length < 4) return false; return true; }
  },
];

export default FilterList;