import React, { useEffect } from 'react';
import MaterialIcon from 'material-icons-react';
import { useFGMateriaisComposicao } from "../../../contexts/FarmGlobal/MateriaisComposicaoContext.js";
import { Button, Table, Form } from 'react-bootstrap';
import styles from '../../../styles/FarmGlobal/MateriaisComposicao.module.scss';
import ModalCadComposicao from './ModalCadComposicao.jsx';

const MateriaisComposicaoMain = () => {
    const { mainData, setMainData, filterObj, setFilterObj, handleSearch, handleShowCad, showCad, handleShowPDF,handleSort,getSortIcon, removeAccents } = useFGMateriaisComposicao();   

    useEffect(() => {
        handleSearch();
    }, []);    

    return (
        <div>
            <article className="d-flex align-items-center">
                <h3 className="p-3">Cadastro de Base</h3>
                <Button
                    type="button"
                    variant="info"
                    className={styles.filterBtn}
                    onClick={handleShowCad}
                >
                    + Cadastrar Nova Base
                </Button>
                
            </article>
            <article>
                <Form className={styles.formWrapper}>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Desc. Material</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Material'}
                            className={styles.formControl}
                            value={removeAccents(filterObj.descricao) || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, descricao: event.target.value })}
                            onKeyPress={(e) => {
                                             return removeAccents(e.key);
                                        }}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Tipo</Form.Label>
                        <Form.Control
                            as="select"
                            className={styles.formControl}
                            value={filterObj.tipo || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, tipo: event.target.value })}
                        >
                            <option value="">Todos</option>
                            <option value="Tinto">Tinto</option>
                            <option value="Estampado Cilindro">Estampado Cilindro</option>
                            <option value="Estampado Digital">Estampado Digital</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Ref. Linx</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Ref. Linx'}
                            className={styles.formControl}
                            value={filterObj.refLinx || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, refLinx: event.target.value })}
                        />
                    </Form.Group>
                    <Button
                        type="button"
                        variant="primary"
                        className={styles.filterBtn}
                        onClick={handleSearch}
                    >
                        Buscar
                    </Button>
                </Form>
            </article>
            <article className={styles.articleGray}>
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                        <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Arquivo')}
                            >
                                FICHA TÉCNICA
                                <MaterialIcon icon={getSortIcon('Arquivo')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Descricao')}
                            >
                                DESCRIÇÃO MATERIAL
                                <MaterialIcon icon={getSortIcon('Descricao')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Tipo')}
                            >
                                TIPO
                                <MaterialIcon icon={getSortIcon('Tipo')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('FornecedorMp')}
                            >
                                FORNECEDOR MP
                                <MaterialIcon icon={getSortIcon('FornecedorMp')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('FornecedorPa')}
                            >
                                FORNECEDOR PA
                                <MaterialIcon icon={getSortIcon('FornecedorPa')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Gramatura')}
                            >
                                GRAMATURA
                                <MaterialIcon icon={getSortIcon('Gramatura')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('RefLinx')}
                            >
                                REF. LINX
                                <MaterialIcon icon={getSortIcon('RefLinx')} size={16} />
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {mainData.map((item, index) => (
                            <tr key={index}>
                            <td className={styles.tableLines}>
                                    {item.Arquivo ? (
                                        <i onClick={() => handleShowPDF(item.Arquivo)}>
                                            <MaterialIcon
                                                icon="description"
                                                size={30}
                                                color='#0D6EFD'
                                            />
                                        </i>
                                    ) : ''}
                                </td>
                                <td className={styles.tableLines}>{item.Descricao}</td>                                
                                <td className={styles.tableLines}>{item.Tipo}</td>
                                <td className={styles.tableLines}>{item.FornecedorMp}</td>
                                <td className={styles.tableLines}>{item.FornecedorPa}</td>
                                <td className={styles.tableLines}>{item.Gramatura}</td>
                                <td className={styles.tableLines}>{item.RefLinx}</td>
                                
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </article>
            <ModalCadComposicao showCad={showCad} />
        </div>
    );
}

export default MateriaisComposicaoMain;
