import React from "react";

const Devolucao = () => {
  return (
    <div>
      <h1>Qualidade PA</h1>
      <h2>C.Q PROCESSO – DEVOLUÇÃO</h2>
      <h3>DEVOLUÇÃO</h3>
    </div>
  );
};

export default Devolucao;
