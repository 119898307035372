import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CookiesService from '../services/cookies';
import { Button } from 'react-bootstrap';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const initializeAuth = () => {
      const storedUserData = CookiesService.getUserData();
      const jwtToken = Cookies.get('jwt_token');

      if (storedUserData && jwtToken) return setIsAuthenticated(true);
      if (process.env.REACT_APP_IS_DEBUG_MODE) return setIsAuthenticated(true);
    }

    initializeAuth();
  }, [navigate]);

  useEffect(() => {
    if (authLoading) return setIsAuthenticated(false);
  }, [authLoading]);

  const clearSession = () => {
    setIsAuthenticated(false);
    Cookies.remove('user_data');
    Cookies.remove('jwt_token');
    window.location = process.env.REACT_APP_AUTH;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, clearSession, authLoading, setAuthLoading }}>
      {children}
      {(!isAuthenticated && !authLoading && (!CookiesService.getUserData() || !CookiesService.get('jwt_token'))) &&
        <div
          style={{
            margin: '40vh auto 0',
            textAlign: 'center'
          }}
        >
          <div>Não foi possível autenticar seu usuário - clique para voltar ao PLM</div>
          <br />
          <Button variant={'outline-secondary'} onClick={clearSession}>Voltar</Button>
        </div>
      }
    </AuthContext.Provider>
  );
}
