import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { toUTCDatetimeWithoutMs } from '../../../utils/dateUtils';
import { useMisc } from '../../MiscContext';

const AcompanhamentoEnfestoContext = createContext();
export const useAcompanhamentoEnfesto = () => useContext(AcompanhamentoEnfestoContext);

export const AcompanhamentoEnfestoProvider = ({ children }) => {

  const { setShowLoading } = useMisc();

  const columns = [
    { name: 'SOLICITANTE', selector: row => row.Solicitante.toUpperCase(), atr: 'Solicitante' },
    { name: 'ORDEM PRODUÇÃO', selector: row => row.OrdemProducao, atr: 'OrdemProducao', width: '160px' },
    { name: 'ORDEM SERVIÇO', selector: row => row.OrdemServico, atr: 'OrdemServico', width: '150px' },
    { name: 'DESC COR', selector: row => row.Desc_Cor_Material, atr: 'Desc_Cor_Material', width: '400px' },
    { name: 'OPERADOR 1', selector: row => row.Operador1, atr: 'Operador1', width: '200px' },
    { name: 'OPERADOR 2', selector: row => row.Operador2, atr: 'Operador2', width: '200px' },
    { name: 'TEMPO ESTIMADO', selector: row => row.TempoEstimado, atr: 'TempoEstimado', width: '200px' },
    { name: 'DATA INÍCIO', selector: row => toUTCDatetimeWithoutMs(row.DataInicio), atr: 'DataInicio', width: '200px', sortable: true },
    { name: 'FIM ESTIMADO', selector: row => toUTCDatetimeWithoutMs(row.FimEstimado), atr: 'FimEstimado', width: '200px', sortable: true },
    { name: 'STATUS', selector: row => row.Status, atr: 'Status', width: '150px', sortable: true },
    { name: 'ATRASO', selector: row => row.Atraso, atr: 'Atraso', width: '150px', sortable: true }

  ];

  const [loading, setLoading] = useState(false);

  const [mainData, setMainData] = useState([])
  const [pausasData, setPausasData] = useState([])

  const [filtro, setFiltro] = useState('')

  const [linhaSelecionada, setLinhaSelecionada] = useState({})

  const [showInfo, setShowInfo] = useState(false)
  const [showPausas, setShowPausas] = useState(false)

  const handleShowInfo = (row) =>{
    setLinhaSelecionada(row)
    setShowInfo(true)
  }

  const handleCloseInfo = () =>{
    setLinhaSelecionada({})
    setShowInfo(false)
  }
  const handleShowPausas = () =>{
    fetchPausas()
  }
  const handleClosePausas = () =>{
    setShowPausas(false)
    setShowInfo(true)
  }

  const initAcompanhamento = async () => {
    setLoading(true);
    try {
      const res = await api.get('Corte/ControleEnfesto/AcompanhamentoEnfesto/get-rows');
      setMainData(res.data);
    } catch (e) {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setLoading(false);
    }
  };

  const fetchPausas = async () => {
    setShowInfo(false)
    setShowLoading(true);
    const params = {...linhaSelecionada}
    try {
      const res = await api.get('Corte/ControleEnfesto/AcompanhamentoEnfesto/get-pausas', { params });
      setPausasData(res.data);
    } catch (e) {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setShowLoading(false);
        setShowPausas(true)
    }
  };

  useEffect(() => {
      initAcompanhamento();
    }, []);

  return (
    <AcompanhamentoEnfestoContext.Provider
      value={{ filtro, setFiltro, mainData, loading, columns, linhaSelecionada, handleShowInfo, handleShowPausas, handleCloseInfo, handleClosePausas, showInfo, showPausas, pausasData }}
    >
      {children}
    </AcompanhamentoEnfestoContext.Provider>
  )
}