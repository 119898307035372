import React, { useState, useRef } from 'react';
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import styles from '../../../styles/PA/ADM/DevolucaoPa.module.scss';

import { useEffect } from 'react';
import Carregando from '../../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import { api }  from '../../../services/api';
import { toDatetime } from '../../../utils/dateUtils';
import Select from 'react-select';
import CookiesService from '../../../services/cookies';

const popover = (
	<Popover id="popover-basic">
		<Popover.Header as="h3">Atenção!</Popover.Header>
		<Popover.Body>
			O direcionamento das peças é obrigatório quando o status for alterado.
			Escolhendo a opção SIM será gerado uma pendência de Direcionamento de peças.
			Escolhendo a opção NÃO, o direcionamento será automático, alterando todas as peças que tiverem sido indicadas como DEVOLUçÂO para o destino ESTOQUE SEM DESCONTO.
		</Popover.Body>
	</Popover>
);

const PAAdmDevolucaoPa = () => {
	const [loading, setLoading] = useState(false)
	const [mainData, setMainData] = useState([]);
	const [filterObj, setFilterObj] = useState({});
	const [formObj, setFormObj] = useState({});
	const [formErrs, setFormErrs] = useState({});
	const [activeObj, setActiveObj] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [optionsObj, setOptionsObj] = useState({});
	const userLogin = CookiesService.getUserLogin();

	const handleClose = () => {
		setShowModal(false);
		setActiveObj({});
		setFormObj({});
		setFormErrs({});
	}

	const handleShowModal = (el) => {
		setShowModal(true);
		setActiveObj(el);
	}

	const handleFiltering = async () => {
		setLoading(true);
		const params = {
			NFEntrada: filterObj.NFEntrada,
			Produto: filterObj.Produto,
			CorProduto: filterObj.CorProduto
		}

		await api.get('/PAAdmControle/D_GetMainData', { params })
			.then(res => {
				if (res.status === 200) { setMainData(res.data) }
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao se preencher a lista de processos, por favor cheque sua conexão com a internet ou entre contato com o suporte');
				console.error(`Erro na requisição '/D_GetMainData' - ${e}`);
			});
		setLoading(false);
	}

	const handleProcessing = async () => {
		const errs = handleValidation();
		if (Object.keys(errs).length > 0) return setFormErrs(errs);
		const params = {
			DataRegistro: toDatetime(new Date()),
			IDUsuario: userLogin,
			...activeObj,
			IDMotivo: formObj.Motivo.value,
			IDStatusFinal: formObj.StatusFinal.value,
			Observacao: formObj.Observacao,
			AjusteDirecionamento: formObj.DirecionamentoManual === true ? 1 : 2
			// --- NA PROC QUE CUIDA DESSE PROCESSAMENTO, O DIRECIONAMENTO MANUAL É INDICADO COMO AjusteDirecionamento = 1 --- //
			// --- ENQUANTO O DIRECIONAMENTO AUTOMÁTICO É INDICADO COMO AjusteDirecionamento = 2 --- //
		}

		await api.post('PAAdmControle/D_PostDevolucao', params)
			.then(res => {
				if (res.status === 200 && res.data[0].mensagem === 'PROCESSO CONCLUIDO: ') {
					toast.success('Processo concluído com sucesso!');
					handleClose();
					handleFiltering();
				}
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao se preencher a lista de processos, por favor cheque sua conexão com a internet ou entre contato com o suporte');
				console.error(`Erro na requisição '/D_GetMainData' - ${e}`);
			});
	}

	const handleValidation = () => {
		const { StatusFinal, Motivo, DirecionamentoManual } = formObj;
		const errs = {}
		if (!StatusFinal) errs.StatusFinal = 'Selecione um status final';
		if (!Motivo) errs.Motivo = 'Selecione um motivo';
		if (DirecionamentoManual === null || DirecionamentoManual === undefined) errs.DirecionamentoManual = 'Especifique o tipo de direcionamento';
		return errs;
	}

	const fetchSelectOptions = async () => {
		let MotivoList, StatusAnaliseList;
		MotivoList = StatusAnaliseList = [];

		await api.get('/PAAdmControle/D_GetMotivoList')
			.then(res => {
				if (res.status === 200) { MotivoList = res.data }
			})
			.catch(e => {
				console.error(`Erro na requisição '/D_GetMotivoList' - ${e}`);
			});
			
		await api.get('/PAAdmControle/D_GetStatusAnaliseList')
			.then(res => {
				if (res.status === 200) { StatusAnaliseList = res.data }
			})
			.catch(e => {
				console.error(`Erro na requisição '/D_GetStatusAnaliseList' - ${e}`);
			});
			
			if (MotivoList.length > 0 && StatusAnaliseList.length > 0) setOptionsObj({ MotivoList, StatusAnaliseList });
			else toast.error('Ocorreu um erro ao carregar a página, por favor cheque sua conexão com a internet e/ou entre contato com o suporte');
	}

	const clearErrs = (str) => {
		setFormErrs({ ...formErrs, [str]: null });
	}

	const handleValueMask = (field, value) => {
		if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
		setFilterObj({ ...filterObj, [field]: value });
	}

	useEffect(() => {
		fetchSelectOptions();
	}, []);

	return (
		<div>
			<h3 className="m-3">ADM PA - Devolução PA</h3>

			<article className={styles.articleMargim}>
				<Form className={styles.formWrapper}>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>NF Entrada</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.NFEntrada || ''}
							onChange={e => handleValueMask('NFEntrada', e.target.value, true)}
							placeholder={'Número da NF'}
						/>
					</Form.Group>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>Produto</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.Produto || ''}
							onChange={e => handleValueMask('Produto', e.target.value, true)}
							placeholder={'Produto'}
						/>
					</Form.Group>
					<Form.Group className={styles.filterInput}>
						<Form.Label className={styles.formLabel}>Cor Produto</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.CorProduto || ''}
							onChange={e => handleValueMask('CorProduto', e.target.value, true)}
							placeholder={'Cor Produto'}
						/>
					</Form.Group>
					<Button
						className={styles.filterBtn}
						variant="primary"
						type="button"
						onClick={handleFiltering}
					>
						Buscar
					</Button>
				</Form>
			</article>

			<p className={styles.information}>*Usar essa opção somente quando um produto for Devolvido pela Qualidade e posteriormente decidido liberar para o Estoque.</p>

			<article className={styles.articleGray}>
				<Table className="m-2" responsive>
					<thead>
						<tr>
							<th></th>
							<th className={styles.blockTitle}>RISCO LIBERADO?</th>
							<th className={styles.blockTitle}>NF ENTRADA</th>
							<th className={styles.blockTitle}>SERIE NF</th>
							<th className={styles.blockTitle}>ORIGEM</th>
							<th className={styles.blockTitle}>PRODUTO</th>
							<th className={styles.blockTitle}>COR PRODUTO</th>
							<th className={styles.blockTitle}>QTDE. PEÇAS</th>
							<th className={styles.blockTitle}>FILIAL REVISÃO</th>
							<th className={styles.blockTitle}>STATUS FINAL</th>
							<th className={styles.blockTitle}>VALOR NF</th>
							<th className={styles.blockTitle}>VALOR DESCONTO</th>
							<th className={styles.blockTitle}>DATA CHECKLIST</th>
						</tr>
					</thead>
					<tbody>
						{loading ? <tr><td><Carregando></Carregando></td></tr> :
							mainData.map((el, index) => {
								return (
									<tr className={styles.tableLines} key={index}>
										<td>
											<i
												style={{ 'cursor': 'pointer' }}
												className={styles.iconCursor}
												onClick={() => handleShowModal(el)}>
												<MaterialIcon
													icon="keyboard_return"
													size={30}
													color='#0D6EFD'
												/>
											</i>
										</td>
										<td className={styles.tableLines}>{el.LiberaRiscoSacado === 0 ? 'NÃO' : 'SIM'}</td>
										<td className={styles.tableLines}>{el.NFEntrada}</td>
										<td className={styles.tableLines}>{el.SerieNF}</td>
										<td className={styles.tableLines}>{el.Origem}</td>
										<td className={styles.tableLines}>{el.Produto}</td>
										<td className={styles.tableLines}>{el.CorProduto}</td>
										<td className={styles.tableLines}>{el.QtdeEntrada}</td>
										<td className={styles.tableLines}>{el.FilialRevisao}</td>
										<td className={styles.tableLines}>{el.DescricaoStatus}</td>
										<td className={styles.tableLines}>{el.ValorItemNF}</td>
										<td className={styles.tableLines}>{el.Desconto}</td>
										<td className={styles.tableLines}>{el.DataChecklist}</td>
									</tr>
								)
							})
						}
					</tbody>
				</Table>
			</article>

			<Modal
				show={showModal}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="lg"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>ADM PA - Devolução PA</Modal.Title>
				</Modal.Header>
				<Modal.Body className="m-3">
					{/* Accordion de Identificação do Produto */}
					<Accordion defaultActiveKey={['0']} alwaysOpen>
						<Accordion.Item eventKey="0">
							<Accordion.Header>Dados do Produto</Accordion.Header>
							<Accordion.Body>
								<article className={styles.monitorInfo}>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>NF Entrada:</span>
											<span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Qtde. Peças:</span>
											<span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Série NF:</span>
											<span className={styles.fontBlue}>{activeObj.SerieNF}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Filial Revisão:</span>
											<span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Origem:</span>
											<span className={styles.fontBlue}>{activeObj.Origem}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Status Final:</span>
											<span className={styles.fontBlue}>{activeObj.DescricaoStatus}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Produto:</span>
											<span className={styles.fontBlue}>{activeObj.Produto}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Valor NF:</span>
											<span className={styles.fontBlue}>{activeObj.ValorItemNF}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Cor Produto:</span>
											<span className={styles.fontBlue}>{activeObj.CorProduto}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Valor Descontado:</span>
											<span className={styles.fontBlue}>{activeObj.Desconto}</span>
										</div>
									</div>
								</article>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>

					<article className={styles.modalFormWrapper}>
						<Form className={styles.modalForm}>
							<Form.Group className={styles.formGroup} controlId="StatusFinal">
								<Form.Label className={styles.modalFormLabel}>1. Novo Status Final:</Form.Label>
								<Select
									value={formObj.StatusFinal}
									placeholder={'Selecionar'}
									options={optionsObj.StatusAnaliseList}
									onFocus={() => clearErrs('StatusFinal')}
									onChange={obj => setFormObj({...formObj, StatusFinal: obj})}
									isClearable={true}
									escapeClearsValue={true}
									styles={{
										control: (provided) => (
											formErrs.StatusFinal ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
										)
									}}
								/>
								<div className={formErrs.StatusFinal ? styles.showFeedback : styles.hidden}>{formErrs.StatusFinal}</div>
							</Form.Group>

							<Form.Group className={styles.formGroup} controlId="Motivo">
								<Form.Label className={styles.modalFormLabel}>2. Motivo:</Form.Label>
								<Select
									value={formObj.Motivo}
									placeholder={'Selecionar'}
									options={optionsObj.MotivoList}
									onFocus={() => clearErrs('Motivo')}
									onChange={obj => setFormObj({...formObj, Motivo: obj})}
									isClearable={true}
									escapeClearsValue={true}
									styles={{
										control: (provided) => (
											formErrs.Motivo ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
										)
									}}
								/>
								<div className={formErrs.Motivo ? styles.showFeedback : styles.hidden}>{formErrs.Motivo}</div>
							</Form.Group>

							<Form.Group className={styles.formGroup}>
								<Form.Label className={styles.modalFormLabel}>4. Realizar direcionamento manual: 
									<OverlayTrigger
										trigger="click"
										placement="right-end"
										overlay={popover}
										rootClose
									>
										<i>
											<MaterialIcon
												icon="info"
												size={30}
												color='#0D6EFD'
											/>
										</i>
									</OverlayTrigger>
								</Form.Label>
								<div className={styles.formChecksRow}>
									<Form.Check
										onChange={() => { setFormObj({ ...formObj, DirecionamentoManual: true }); clearErrs('DirecionamentoManual') }}
										type={'radio'}
										label={'Sim'}
										id={'sim'}
										name={'DirecionamentoManual'}
										isInvalid={formErrs.DirecionamentoManual}
										checked={formObj.DirecionamentoManual === true}
									/>
									<Form.Check
										onChange={() => { setFormObj({ ...formObj, DirecionamentoManual: false }); clearErrs('DirecionamentoManual') }}
										type={'radio'}
										label={'Não'}
										id={'nao'}
										name={'DirecionamentoManual'}
										isInvalid={formErrs.DirecionamentoManual}
										checked={formObj.DirecionamentoManual === false}
									/>
								</div>
								<div className={formErrs.DirecionamentoManual ? styles.showFeedback : styles.hidden}>{formErrs.DirecionamentoManual}</div>
							</Form.Group>
						</Form>
						<Form className={styles.modalForm}>
							<Form.Group className={styles.formGroup}>
								<Form.Label
									className={styles.modalFormLabel}
								>
									3. Observação:<span className={styles.spanOpcional}>(Opcional)</span>
								</Form.Label>
								<Form.Control
									style={{'height': '128px'}}
									as="textarea"
									type="text"
									placeholder={'Observações'}
									value={formObj.Observacao || ''}
									onClick={() => clearErrs('Observacao')}
									onChange={e => setFormObj({...formObj, Observacao: e.target.value})}
									className={styles.formTextArea}
								/>
							</Form.Group>
						</Form>
					</article>
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					<Button variant="outline-danger" onClick={handleClose}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={handleProcessing}
					>
						Processar
					</Button>
				</Modal.Footer>
			</Modal>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</div>
	)
}

export default PAAdmDevolucaoPa;