import { createContext, useEffect, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useMisc } from '../../../contexts/MiscContext';
import PARevisaoMain from './pa_revisao_main';
import CookiesService from '../../../services/cookies';
import { isNullish } from '../../../utils/misc_utils';
import io from 'socket.io-client';
const socket_url = process.env.REACT_APP_BASE_SOCKET_URL;

const PARevisaoContext = createContext();
export const usePARevisao = () => useContext(PARevisaoContext);

export const PARevisaoProvider = () => {
  const { setShowLoading } = useMisc();
  const [userId] = useState(CookiesService.getUserId());
  const [isAdmin] = useState(CookiesService.isUserAdmin());
  const [podePriorizar] = useState(CookiesService.getAcessos().pa_revisao_priorizar);
  const [sessionId] = useState(uuidv4());
  
  const [socket, setSocket] = useState(null);

  const [listaRevisao, setListaRevisao] = useState([]);
  const [listaRevisaoFiltrada, setListaRevisaoFiltrada] = useState([]);
  const [listaRevisaoPrio, setListaRevisaoPrio] = useState([]);
  const [listaRevisaoPrioFiltrada, setListaRevisaoPrioFiltrada] = useState([]);
  const [listaDefeitos, setListaDefeitos] = useState([]);
  const [listaDirecionamentos, setListaDirecionamentos] = useState([]);
  const [listaDefeitosComp, setListaDefeitosComp] = useState([]);
  const [listaStatusDefeito, setListaStatusDefeito] = useState([]);

  const [modalRevisao, setModalRevisao] = useState(false);
  const [gradeRevisao, setGradeRevisao] = useState([]);
  const [referenciaSelecionada, setReferenciaSelecionada] = useState({});
  const [modalDirecionamento, setModalDirecionamento] = useState(false);
  const [direcionamentoRevisao, setDirecionamentoRevisao] = useState([]);
  const [statusFinal, setStatusFinal] = useState({ label: '04 - APROVADO', value: 4, color: '#B4E380' });
  const [quantidadeTotalDirecionada, setQuantidadeTotalDirecionada] = useState(0);

  const [modalConfirmacaoSQ, setModalConfirmacaoSQ] = useState(false);
  const [modalProcessamento, setModalProcessamento] = useState(false);
  const [precoTagDivergente, setPrecoTagDivergente] = useState({ key: false, value: '' });
  const [composicaoTagDivergente, setComposicaoTagDivergente] = useState({ key: false, value: '', defeitos: [] });
  const [modoAdm, setModoAdm] = useState(false);
  const [filterObj, setFilterObj] = useState({ nf_entrada: '', produto: '', cor_produto: '' });

  const [modalImagens, setModalImagens] = useState({ show: false, index: null });

  useEffect(() => {
    const newSocket = io(`${socket_url}`, { query: { interfaceType: 'revisao' } });
    setSocket(newSocket);

    newSocket.on('grid:update', (data) => {
      setListaRevisao(data);
      setListaRevisaoPrio(data);
    });

    newSocket.on('revisao:grade', (data) => {
      setGradeRevisao(data);
    });

    return () => {
      if (newSocket) {
        newSocket.close();
        newSocket.off('revisao:grade');
        newSocket.off('grid:update');
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      (async () => {
        await fetchRevisaoInicializacao();
        fetchRevisaoGrid();
      })();
    }
  }, [socket]);

  const resetData = () => {
    setModalRevisao(false);
    setReferenciaSelecionada({});
    setGradeRevisao([]);
    setDirecionamentoRevisao([]);
    setModalDirecionamento(false);
    setModalConfirmacaoSQ(false);
    setModalProcessamento(false);
    setStatusFinal({ label: '04 - APROVADO', value: 4, color: '#B4E380' });
    setPrecoTagDivergente({ key: false, value: '' });
    setComposicaoTagDivergente({ key: false, value: '', defeitos: [] });
    setFilterObj({ nf_entrada: '', produto: '', cor_produto: '' });
  }

  const fetchRevisaoInicializacao = async () => {
    try {
      setShowLoading(true);
      const res = await api.get('/pa-revisao/revisao-inicializacao');
      setListaDefeitos(res.data.resListaDefeitos);
      setListaDirecionamentos(res.data.resListaDirecionamentos);
      setListaDefeitosComp(res.data.resListaDefeitosComp);
      setListaStatusDefeito(res.data.resListaStatusDefeito);
    } catch (e) {
      toast.error('Ocorreu um erro ao inicializar a interface.\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const fetchRevisaoGrid = () => {
    setShowLoading(true);
    socket.emit('grid:refresh', (res) => {
      if (!res.status) toast.error('Ocorreu um erro ao recarregar a listagem\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte');
      setShowLoading(false);
    });
  }

  const iniciarRevisao = async (ref) => {
    try {
      setShowLoading(true);

      socket.disconnect();
      await new Promise(resolve => {
        socket.connect();
        socket.on('connect', () => {
          resolve();
        });
      });

      const params = {
        nf_entrada: ref.nf_entrada,
        serie_nf: ref.serie_nf,
        origem: ref.origem,
        ordem_producao: ref.ordem_producao,
        produto: ref.produto,
        cor_produto: ref.cor_produto,
        qtde_entrada: ref.qtde_entrada,
        filial_revisao: ref.filial_revisao,
        id_controle: ref.id_controle,
        sessionId
      };

      await new Promise((resolve, reject) => {
        socket.emit('revisao:iniciar', params, (res) => {
          if (!res.status) reject(new Error(res.error));
          resolve(res);
        });
      });
      setReferenciaSelecionada({ ...ref });
      setModalRevisao(true);
    } catch (e) {
      const errorCode = parseInt(e.message);
      if (errorCode === 50001) return toast.warn('Não foi possível iniciar a revisão. Outro usuário já está revisando a produção');
      if (errorCode === 50002) return toast.error('Não foi possível encontrar a grade da referência selecionada.\nPor favor tente novamente ou entre em contato com o suporte.');
      toast.error('Ocorreu um erro inesperado. Por favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const checarSePossuiDefeitos = (index_grade) => {
    const possuiDefeitos = direcionamentoRevisao.some(obj => {
      return obj.grade.index_grade === index_grade;
    });
    return possuiDefeitos;
  }

  const validarProcessamento = () => {
    for (let i = 0; i < gradeRevisao.length; i++) {
      const item = gradeRevisao[i];
      if (!item.sem_defeitos && !checarSePossuiDefeitos(item.index_grade)) return false;
    }
    const precosIguais = parseFloat(precoTagDivergente.value) === parseFloat(referenciaSelecionada.preco_produto) ? true : false;
    if (precoTagDivergente.key && (isNullish(precoTagDivergente.value) || precosIguais)) return false;
    if (composicaoTagDivergente.key && isNullish(composicaoTagDivergente.value)) return false;
    if (composicaoTagDivergente.key && composicaoTagDivergente.defeitos.length === 0) return false;
    return true;
  }

  const abrirModalProcessamento = () => {
    if (!validarProcessamento()) return toast.warning('Por favor confirme que as grade sem direcionamentos não possuem defeitos e preencha todos os campos.');
    setModalProcessamento(true);
  }

  const processarRevisao = async () => {
    try {
      setShowLoading(true);
      if (!validarProcessamento()) return toast.warning('Por favor confirme que as grade sem direcionamentos não possuem defeitos e preencha todos os campos.');

      const params = {
        refData: {
          nf_entrada: referenciaSelecionada.nf_entrada,
          serie_nf: referenciaSelecionada.serie_nf,
          origem: referenciaSelecionada.origem,
          produto: referenciaSelecionada.produto,
          cor_produto: referenciaSelecionada.cor_produto,
          qtde_entrada: referenciaSelecionada.qtde_entrada,
          filial_revisao: referenciaSelecionada.filial_revisao,
          ordem_producao: referenciaSelecionada.ordem_producao,
          ordem_servico: referenciaSelecionada.ordem_servico,
          empresa: referenciaSelecionada.empresa
        },
        gradeRevisao,
        direcionamentoRevisao,
        usuario_responsavel: CookiesService.getUserLogin(),
        id_usuario: userId,
        id_tipo_analise: referenciaSelecionada.nf_segunda_qualidade ? 2 : 1,
        qtde_pecas_amostra: referenciaSelecionada.qtde_pecas_amostra,
        status_revisao: statusFinal,
        preco_tag_divergente: precoTagDivergente,
        composicao_tag_divergente: composicaoTagDivergente,
        id_controle: referenciaSelecionada.id_controle
      }

      await new Promise((resolve, reject) => {
        socket.emit('revisao:processar', params, (res) => {
          if (!res.status) reject(new Error(res.error));
          resolve(res);
        });
      });
      toast.success('Processamento completado com sucesso!');
      resetData();
    } catch (e) {
      if (parseInt(e.message) === 50011) return toast.warning('A referencia selecionada já teve sua revisão lançada.\nSe isso é um erro, por favor entre em contato com a gestão.');
      toast.error('Houve um problema durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const toggleSemDefeitos = (index_grade) => {
    gradeRevisao[index_grade].sem_defeitos = !gradeRevisao[index_grade].sem_defeitos;
    setGradeRevisao([...gradeRevisao]);
  }

  const handleRemoverDirecionamento = (row, index) => {
    const { quantidade, grade } = row;
    try {
      gradeRevisao[grade.index].quantidade_aprovada += parseInt(quantidade);
      gradeRevisao[grade.index].quantidade_defeituosa -= parseInt(quantidade);
      direcionamentoRevisao.splice(index, 1);

      setGradeRevisao([...gradeRevisao]);
      setDirecionamentoRevisao([...direcionamentoRevisao]);
    } catch (e) {
      toast.error('Encontramos um erro ao remover o direcionamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    }
  }

  const calcularStatusFinal = () => {
    let status_final = { label: '04 - APROVADO', value: 4, color: '#B4E380' }
    const qtde_pecas_defeituosas = direcionamentoRevisao.reduce((sum, item) => sum + item.quantidade, 0);
    const possuiDevolucao = direcionamentoRevisao.some(item => item.direcionamento.value === 4);
    const limite_reprovacao = referenciaSelecionada.qtde_pecas_para_reprovacao;

    if (qtde_pecas_defeituosas > 0 && qtde_pecas_defeituosas <= limite_reprovacao) status_final = { label: '05 - APROVADO COM RESTRIÇÕES', value: 5, color: '#F6FB7A' }
    if (qtde_pecas_defeituosas > 0 && qtde_pecas_defeituosas <= limite_reprovacao && possuiDevolucao) status_final = { label: '07 - DEVOLUÇÃO PARCIAL', value: 7, color: '#F6FB7A' }
    if (qtde_pecas_defeituosas > 0 && qtde_pecas_defeituosas > limite_reprovacao) status_final = { label: '08 - REPROVADO', value: 8, color: '#FF7777' }
    if (precoTagDivergente.key || composicaoTagDivergente.key) status_final = { label: '09 - DEVOLUÇÃO TOTAL', value: 9, color: '#FF7777' }
    setStatusFinal(status_final);
    setQuantidadeTotalDirecionada(qtde_pecas_defeituosas);
  }

  useEffect(() => {
    calcularStatusFinal();
  }, [direcionamentoRevisao, precoTagDivergente, composicaoTagDivergente]);

  const resetarTarefas = () => {
    setListaRevisaoPrio(listaRevisao);
  };

  const handleAdmAlterar = async () => {
    try {
      setShowLoading(true);

      const sqParams = listaRevisaoPrio.filter(prioItem => {
        const itemOriginal = listaRevisao.find(item => item.id_entrada_fiscal === prioItem.id_entrada_fiscal);
        return itemOriginal && itemOriginal.nf_segunda_qualidade !== prioItem.nf_segunda_qualidade;
      })
        .map(prioItem => ({
          id_entrada_fiscal: prioItem.id_entrada_fiscal,
          nf_segunda_qualidade: prioItem.nf_segunda_qualidade
        }));

      const prioParams = listaRevisaoPrio
        .filter(obj => obj.priorizado || obj.prioridade)
        .map((obj, index) => ({
          id_controle: obj.id_controle,
          priorizado: obj.priorizado,
          prioridade_old: obj.prioridade,
          index: index
        }));

      await new Promise((resolve, reject) => {
        socket.emit('adm:alterar', prioParams, sqParams, (res) => {
          if (!res.status) reject(new Error(res.error));
          resolve(res);
        });
      });
      toast.success('Prorização efetuada com sucesso.');
    } catch (e) {
      toast.error('Houve um problema durante a priorização dos itens. Pro favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  };

  const revisaoDesabilitada = (em_revisao, id_entrada_fiscal) => {
    if (em_revisao) return true;
    const itensPriorizadosSemRevisao = listaRevisao.some(item => item.priorizado && !item.em_revisao);
    if (itensPriorizadosSemRevisao) return true;

    const itensNaoRevisados = listaRevisao.filter(item => !item.em_revisao);
    const itensDisponiveis = itensNaoRevisados.slice(0, 10);
    return !itensDisponiveis.some(item => item.id_entrada_fiscal === id_entrada_fiscal);
  }

  const abrirModalImagens = (index) => {
    setModalImagens({ show: true, index });
  }

  const fecharModalImagens = () => {
    setModalImagens({ show: false, index: null });
  }

  const adicionarImagens = (e, index) => {
    const files = Array.from(e.target.files);

    direcionamentoRevisao[index] = {
      ...direcionamentoRevisao[index],
      imagens: [...direcionamentoRevisao[index].imagens, ...files]
    }
    if (direcionamentoRevisao[index].imagens.length > 5) {
      direcionamentoRevisao[index].imagens = direcionamentoRevisao[index].imagens.splice(0, 5);
      toast.warning('Só é possível adicionar cinco imagens por direcionamento');
    }

    setDirecionamentoRevisao([...direcionamentoRevisao]);
  }

  const removerImagem = (indexDir, indexImg) => {
    direcionamentoRevisao[indexDir].imagens.splice(indexImg, 1);
    setDirecionamentoRevisao([...direcionamentoRevisao]);
  }

  return (
    <PARevisaoContext.Provider
      value={{ listaDefeitos, listaDefeitosComp, listaDirecionamentos, listaRevisao, listaStatusDefeito, modalRevisao, setModalRevisao, modalDirecionamento, setModalDirecionamento, processarRevisao, referenciaSelecionada, gradeRevisao, setGradeRevisao, direcionamentoRevisao, setDirecionamentoRevisao, toggleSemDefeitos, handleRemoverDirecionamento, checarSePossuiDefeitos, fetchRevisaoGrid, iniciarRevisao, modalConfirmacaoSQ, setModalConfirmacaoSQ, modalProcessamento, setModalProcessamento, statusFinal, quantidadeTotalDirecionada, precoTagDivergente, setPrecoTagDivergente, abrirModalProcessamento, composicaoTagDivergente, setComposicaoTagDivergente, modoAdm, setModoAdm, listaRevisaoPrio, setListaRevisaoPrio, resetarTarefas, handleAdmAlterar, revisaoDesabilitada, listaRevisaoFiltrada, setListaRevisaoFiltrada, listaRevisaoPrioFiltrada, setListaRevisaoPrioFiltrada, filterObj, setFilterObj, isAdmin, podePriorizar, modalImagens, setModalImagens, abrirModalImagens, fecharModalImagens, adicionarImagens, removerImagem }}
    >
      {< PARevisaoMain />}
    </PARevisaoContext.Provider >
  )
}
