import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../services/api';
import { useMisc } from './MiscContext';
import { toast } from 'react-toastify';
import CookiesService from '../services/cookies';

const PAChecklistContext = createContext();
export const usePAChecklist = () => useContext(PAChecklistContext);

export const PAChecklistProvider = ({ children }) => {
  const { setToast, setShowLoading } = useMisc();

  const [showModal, setShowModal] = useState(false); // false - 'identificacao' - 'grade - 3;
  const [ultimoFiltroUsado, setUltimoFiltroUsado] = useState({}); // false - 'identificacao' - 'grade - 3;
  const [listChecklist, setListChecklist] = useState([]);
  const [listChecklistPending, setListChecklistPending] = useState(false);

  const [listaStatusDesenv, setListaStatusDesenv] = useState([]);
  const [listaDefeitos, setListaDefeitos] = useState([]);
  const [listaTamanhos, setListaTamanhos] = useState([]);
  const [listaMedidas, setListaMedidas] = useState([]);
  const [listaResponsavelAnalise, setListaResponsavelAnalise] = useState([]);
  const [listaStatusAnalise, setListaStatusAnalise] = useState([]);
  const [listaGrupoAnalise, setListaGrupoAnalise] = useState([]);
  const [listaAreaEspecifica, setListaAreaEspecifica] = useState([]);

  const [listaGrade, setListaGrade] = useState([]);
  const [listaMedidasCadastradas, setListaMedidasCadastradas] = useState([]);
  const [listaLocalizacaoDefeitos, setListaLocalizacaoDefeitos] = useState([]);

  const [itemSelecionado, setItemSelecionado] = useState({});
  const [dadosAnaliseForm, setDadosAnaliseForm] = useState({});
  const [identificacaoForm, setIdentificacaoForm] = useState({});
  const [grade, setGrade] = useState([]);


  const fetchChecklistGrid = async (filtro) => {
    setListChecklistPending(true);
    const params = { ...filtro }
    try {
      const res = await api.get('/PAChecklist/paChecklistFetchGrid', { params });
      setListChecklist(res.data);
      setUltimoFiltroUsado({ ...filtro });
    } catch (e) {
      setToast('error', 'Ocorreu um erro ao preencher a listagem - ');
      console.error(e);
    }
    setListChecklistPending(false);
  }

  const fetchChecklistInitialization = async () => {
    try {
      const res = await api.get('/PAChecklist/paChecklistInitialization');
      setListaStatusDesenv(res.data.resListaStatusDesenv);
      setListaDefeitos(res.data.resListaDefeitos);
      setListaTamanhos(res.data.resListaTamanhos);
      setListaMedidas(res.data.resListaMedidas);
      setListaResponsavelAnalise(res.data.resListaResponsavelAnalise);
      setListaStatusAnalise(res.data.resListaStatusAnalise);
      setListaGrupoAnalise(res.data.resListaGrupoAnalise);
      setListaAreaEspecifica(res.data.resListaAreaEspecifica);
    } catch (e) {
      setToast('error', 'Ocorreu um erro ao inicializar a interface\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte');
      console.error(e);
    }
  }

  useEffect(() => {
    // fetchChecklistInitialization();
    // fetchChecklistGrid();
    // setShowModal('grade');
  }, []);

  const iniciarChecklist = async (item) => {
    const params = {
      nfEntrada: item.NFEntrada,
      serieNF: item.SerieNF,
      origem: item.Origem,
      ordemProducao: item.OrdemProducao,
      produto: item.Produto,
      corProduto: item.CorProduto,
      qtdeEntrada: item.QtdeEntrada,
      filialRevisao: item.FilialRevisao,
      grupoProduto: item.GrupoProduto
    }

    try {
      const res = await api.get('PAChecklist/paChecklistStartProcess', { params });
      setListaGrade(res.data.resListaGrade);
      setListaMedidasCadastradas(res.data.resListaMedidasCadastradas);
      setListaLocalizacaoDefeitos(res.data.resListaLocalizacaoDefeitos);
      setItemSelecionado({ ...item });
      setShowModal('identificacao');
    } catch (e) {
      setToast('error', 'Não foi possível acessar a lista de defeitos inseridos dessa referência');
      console.error(e);
    }
  }

  const iniciarChecklistOld = async (item) => {
    try {
      if (item.StatusRevisao.trim() === "DEVOLUCAO TOTAL" || item.StatusRevisao.trim() === "DEVOLUCAO PARCIAL") {
        const params = { ...item }
        const resDefeitos = await api.get("PAMonitorCheckList/GetDefeitosProcessos", { params })
        setListaDefeitos(resDefeitos.data);
      }
      setItemSelecionado(item);
      setShowModal(true);
    } catch (e) {
      setToast('error', 'Não foi possível acessar a lista de defeitos inseridos dessa referência');
      console.error(e);
    }
  }

  const getGradeDevolucao = async () => {
    try {
      const { Produto, CorProduto, NFEntrada, SerieNF, Origem, OrdemProducao, QtdeEntrada, FilialRevisao } = itemSelecionado;
      const params = {
        Produto: Produto,
        CorProduto: CorProduto,
        NFEntrada: NFEntrada,
        SerieNF: SerieNF,
        Origem: Origem,
        OrdemProducao: OrdemProducao,
        QtdeEntrada: QtdeEntrada,
        FilialRevisao: FilialRevisao,
        TipoAnalise: '01 - PRODUÇÃO'
      }
      const lista = await api.get('PAChecklist/ListaDirecionamento', { params });
      const gradeDevolucao = [];
      if (lista.data.length > 0) {
        lista.data.forEach((item) => {
          gradeDevolucao.push({
            destino: { label: '01 - ESTOQUE', value: 1 },
            obs: '',
            qtdPecas: item.Qtde_Pendente,
            tamanho: item.Tamanho,
            tipoAnalise: '01 - PRODUÇÃO'
          });
        })
      }
      return gradeDevolucao;
    } catch (e) {
      setToast('error', 'Ocorreu um erro para carregar a grade!');
      console.error(e);
    }
  }

  const processarChecklist = async () => {
    setShowLoading(true);
    try {
      const gradeDevolucao = await getGradeDevolucao(); // Se processado a partir da primeira página;
      const params = {
        idArea: '3',
        produto: itemSelecionado, //props.item
        Responsavel: identificacaoForm.responsavelAnaliseSelecionado,
        StatusAnalise: identificacaoForm.statusAnaliseSelecionada.value,
        qtdPecasAmostra: identificacaoForm.qtdPecasAmostra,
        identificacao: {
          modelagemEstiloSelecionada: identificacaoForm.modelagemEstiloSelecionada,
          pecaLacradaSelecionada: identificacaoForm.pecaLacradaSelecionada,
          graduacaoSelecionada: identificacaoForm.graduacaoSelecionada,
          recebePilotoSelecionada: identificacaoForm.recebePilotoSelecionada,
          obsRecebimentoPiloto: identificacaoForm.obsRecebimentoPiloto
        },
        user: CookiesService.getUserLogin(),
        GradeDefeitos: [],
        GradeDevolucao: gradeDevolucao,
        StatusCQPA: 4,
        EnviaAprovacao: false,
        AreaAprovacao: '',
        IndicaCulpabilidade: false,
        PendenciarDirecionamento: false,
        Consideracoes: '',
        totalPecasDevolucao: 0,
      }

      await api.post('/PAChecklist/FinalizarProcesso', params);
      setToast('success', 'Dados alterados com sucesso!');
    } catch (e) {
      console.error(e);
      setToast('error', `Ocorreu um erro, por favor entre em contato com o suporte.`);
    }
    setShowLoading(false);
  }

  return (
    <PAChecklistContext.Provider
      value={{ fetchChecklistGrid, listChecklist, listChecklistPending, iniciarChecklist, itemSelecionado, showModal, setShowModal, listaStatusAnalise, listaResponsavelAnalise, identificacaoForm, setIdentificacaoForm, dadosAnaliseForm, setDadosAnaliseForm, listaAreaEspecifica, grade, listaStatusDesenv, listaGrade, listaMedidasCadastradas, listaLocalizacaoDefeitos, iniciarChecklistOld }}
    >
      {children}
    </PAChecklistContext.Provider>
  )
}