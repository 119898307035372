import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useAcompanhamentoEnfesto } from '../../../../contexts/Corte/ControleEnfesto/AcompanhamentoEnfestoContext';
import DadosAccordion from './DadosAccordion';
import { toUTCDatetimeWithoutMs } from '../../../../utils/dateUtils';

import styles from '../../../../styles/Corte/ControleEnfesto/AcompanhamentoEnfesto.module.scss';

const ModalPausas = () => {

    const { handleClosePausas, showPausas, linhaSelecionada, pausasData } = useAcompanhamentoEnfesto();

    return (
        <>
            <Modal
                show={showPausas}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>Pausas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DadosAccordion/>
                    <section>
                        <Table responsive style={{textAlign: 'center'}}>
                            <thead>
                                <tr>
                                    <th>Motivo</th>
                                    <th>Início</th>
                                    <th>Fim</th>
                                    <th>Tempo em Pausa</th>
                                </tr>
                            </thead>
                            <tbody>
                                { Object.keys(linhaSelecionada).length > 0 && pausasData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.Motivo}</td>
                                            <td>{toUTCDatetimeWithoutMs(item.DataHoraInicio)}</td>
                                            <td>{toUTCDatetimeWithoutMs(item.DataHoraFim)}</td>
                                            <td>{item.TempoTotalPausa}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </section>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleClosePausas}
                    >
                        Voltar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalPausas;
