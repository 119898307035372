import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from '../../../../../styles/PA/Desfazer.module.scss';

const GradeEntradaForm = (props) => {
  const { formGradeEntrada, setFormGradeEntrada, objSelecionado } = props;
  const [qtdePecasTotal, setQtdePecasTotal] = useState(0);
  const [qtdePecasInserida, setQtdePecasInserida] = useState(0);

  const handleGradeQtdeChange = (item, qtde) => {
    setFormGradeEntrada({
      ...formGradeEntrada,
      grade: {
        ...formGradeEntrada.grade,
        [item.Tamanho]: {
          value: true,
          qtde: parseInt(qtde.replace(/[^0-9]*/g, '')),
          marcado: item.Marcado,
          coluna: item.Coluna
        }
      }
    });
  }

  const handleCheck = (item) => {
    let setter = true;
    if (formGradeEntrada?.grade?.[item.Tamanho]?.value === true) setter = false;
    setFormGradeEntrada({
      ...formGradeEntrada,
      grade: {
        ...formGradeEntrada.grade,
        [item.Tamanho]: {
          value: setter,
          qtde: 0,
          marcado: item.Marcado,
          coluna: item.Coluna
        }
      }
    });
  }

  useEffect(() => {
    let total = 0;
    let formObj = {
      motivo: null,
      qtdePecasTotal: 0,
      grade: {}
    }

    objSelecionado.grade.forEach(item => {
      total += parseInt(item.Qtde);
      formObj.grade[item.Tamanho] = { value: item.Marcado ? true : false, qtde: item.Qtde, marcado: item.Marcado, coluna: item.Coluna }
    });

    setFormGradeEntrada({ ...formObj, qtdePecasTotal: total });
    setQtdePecasInserida(total);
    setQtdePecasTotal(total);
  }, []);

  useEffect(() => {
    if (!formGradeEntrada.grade) return;

    let qtdeInserida = 0;
    Object.keys(formGradeEntrada.grade).forEach(key => {
      let item = formGradeEntrada.grade[key];
      if (!item.qtde) return;
      qtdeInserida += parseInt(item.qtde);
    });
    setQtdePecasInserida(qtdeInserida);
  }, [formGradeEntrada]);

  return (
    <div className={styles.devolucaoWrapper}>
      {objSelecionado?.grade?.map((item, index) => {
        const qtde = parseInt(formGradeEntrada?.grade?.[item.Tamanho]?.qtde);
        const value = formGradeEntrada?.grade?.[item.Tamanho]?.value;
        return (
          <Form.Group
            className={styles.formGroupRow}
            controlId={`tamanho-${index}`}
            key={index}
          >
            <Form.Check
              type={'checkbox'}
              className={styles.check}
              onChange={() => handleCheck(item)}
              label={item.Tamanho}
              checked={formGradeEntrada?.grade?.[item.Tamanho]?.value || false}
              // disabled={item.Marcado}
            />
            <Form.Control
              type="text"
              className={styles.qtBox}
              placeholder={`Qtde atual: ${item.Qtde}` || 0}
              value={formGradeEntrada?.grade?.[item.Tamanho]?.qtde || ''}
              onChange={e => handleGradeQtdeChange(item, e.target.value)}
              disabled={!formGradeEntrada?.grade?.[item.Tamanho]?.value}
              style={{
                borderColor: ((qtde === 0 || !qtde) && value) ? '#DC3545' : '',
                backgroundColor: ((qtde === 0 || !qtde) && value) ? '#DC354530' : ''
              }}
            />
          </Form.Group>
        )
      })}
      <div>
        <Form.Label style={{ fontWeight: '500' }}>Quantidade Total: {qtdePecasTotal}</Form.Label>
        <br/>
        <Form.Label
          style={{fontWeight: '500', color: qtdePecasTotal - qtdePecasInserida === 0 ? 'initial' : '#DC3545' }}
        >
          Faltam: {qtdePecasTotal - qtdePecasInserida}
        </Form.Label>
      </div>

      {/* <Form.Group className={styles.filterWidth} controlId='observacao'>
        <Form.Label>Observação</Form.Label>
        <Form.Control
          as="textarea"
          rows={2}
          type="text"
          value={formGradeEntrada.observacao || ''}
          placeholder={objSelecionado.Observacao}
          onChange={e => { setFormGradeEntrada({ ...formGradeEntrada, observacao: e.target.value }) }}
          required
        />
      </Form.Group> */}
    </div>
  )
}

export default GradeEntradaForm;