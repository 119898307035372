import React from "react";
import { Button, Modal, Table } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext';
import DadosAccordion from "./DadosAccordion";

const ModalConfirmacao = () => {

    const { handleProcessar, handleShowModalConfirmacaoRiscos, materiaisFinalizadosUnique, handleCloseModalConfirmacao, showModalConfirmacao } = useLancamentoCad();

    return (
        <Modal
            show={showModalConfirmacao}
            onHide={handleCloseModalConfirmacao}
            backdrop="static"
            keyboard={false}
            centered
            size='lg'
        >
            <Modal.Header>
                <Modal.Title>Confirmar?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DadosAccordion/>

                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Material</th>
                            <th>Descrição Material</th>
                            <th>Cor Material</th>
                        </tr>
                    </thead>
                    <tbody>
                        { materiaisFinalizadosUnique.map((item, index) => {
                            return (
                                <tr 
                                    key={index} 
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleShowModalConfirmacaoRiscos(item)}
                                >
                                    <td>{item.material}</td>
                                    <td>{item.desc_material}</td>
                                    <td>{item.cor_material}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseModalConfirmacao}
                >
                    Não
                </Button>
                <Button
                    variant="outline-success"
                    className={styles.modalBtn}
                    onClick={handleProcessar}
                >
                    Sim
                </Button>
            </Modal.Footer>
        </Modal>
    )


}

export default ModalConfirmacao;
