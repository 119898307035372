import DataTable from 'react-data-table-component';
import styles from './Tabela.module.scss';
import { Spinner } from 'react-bootstrap';

const Tabela = (props) => {  
  const {
    columns,
    data,
    pending = false,
    pagination = true,
    selectableRows = false,
    filterable = false,
    rowClickedFn = () => null,
    onSelectedRowsChange = () => null,
    paginationPerPage = 10,
    conditionalRowStyles = [],
    highlightOnHover = false,
    noTableHead = false,
    title = false,
    clearSelectedRows = false,
    subHeader = false,
    subHeaderComponent = null,
    customStyles = {
      headCells: { style: { backgroundColor: '#527ac5', justifyContent: 'center', color: '#ffffff' } },
      cells: { style: { justifyContent: 'center' } },
      rows: { style: { cursor: 'pointer' } }
    },
    expandableRows = false
  } = props;

  return (
    <div className={styles.datatable_wrapper}>
      <DataTable
        title={title}
        striped={true}
        highlightOnHover={highlightOnHover}
        columns={columns}
        persistTableHead={true}
        data={data}
        pagination={pagination}
        progressPending={pending}
        progressComponent={<div style={{ padding: '10px' }}><Spinner animation={'border'} variant={'secondary'} /></div>}
        customStyles={customStyles}
        noDataComponent={<div style={{ padding: '10px', fontWeight: '500' }}>Nenhuma informação disponível</div>}
        onRowClicked={rowClickedFn}
        responsive={true}
        selectableRows={selectableRows}
        onSelectedRowsChange={onSelectedRowsChange}
        paginationPerPage={paginationPerPage}
        conditionalRowStyles={conditionalRowStyles}
        clearSelectedRows={clearSelectedRows}
        filterable={filterable}
        subHeader={subHeader}
        subHeaderComponent={subHeaderComponent}
        contextMessage={{ singular: 'item', plural: 'itens', message: 'selecionado(s)' }}
        noTableHead={noTableHead}
        expandableRows={expandableRows}
      />
    </div>
  )
}

export default Tabela;