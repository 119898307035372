import React, { useState, useEffect } from "react";
import { Button, Form, Accordion, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from "react-toastify";

import styles from "../../../../styles/MonitorChecklistIdentificacao.module.scss";

import ProdutoInfo from "../../MonitorRevisao/componentes/ProdutoInfo";
import { toUTCDDMMYYYY } from "../../../../utils/dateUtils";
import { api, retryApi } from "../../../../services/api";
import MonitorChecklistAnaliseGrade from "../AnaliseGrade/index";
import MedidasProducao from "../../MedidasProducao";
import Carregando from "../../../Carregando";
import { usePAChecklist } from "../../../../contexts/PAChecklistContext";
import CookiesService from "../../../../services/cookies";

const MonitorChecklistIdentificacao = (props) => {
  const { } = usePAChecklist();

  const [show, setShow] = useState(false);

  const [modelagemEstilo, setModelagemEstilo] = useState([]);
  const [pecaLacrada, setPecaLacrada] = useState([]);
  const [corte, setCorte] = useState([]);
  const [graduacao, setGraduacao] = useState([]);
  const [beneficiamento, setBeneficiamento] = useState([]);
  const [recebePiloto, setRecebePiloto] = useState([]);
  const [statusAnalise, setStatusAnalise] = useState([]);
  const [responsavelAnalise, setResponsavelAnalise] = useState([]);

  const [modelagemEstiloSelecionada, setModelagemEstiloSelecionada] = useState('');
  const [pecaLacradaSelecionada, setPecaLacradaSelecionada] = useState('');
  const [corteSelecionada, setCorteSelecionada] = useState('');
  const [graduacaoSelecionada, setGraduacaoSelecionada] = useState('');
  const [beneficiamentoSelecionada, setBeneficiamentoSelecionada] = useState('');
  const [recebePilotoSelecionada, setRecebePilotoSelecionada] = useState('');
  const [statusAnaliseSelecionada, setStatusAnaliseSelecionada] = useState('');
  const [responsavelAnaliseSelecionado, setResponsavelAnaliseSelecionado] = useState('');
  const [obsRecebimentoPiloto, setObsRecebimentoPiloto] = useState('');
  const [destinoSelecionado, setDestinoSelecionado] = useState('');
  const [destinoSelecionadoDesc, setDestinoSelecionadoDesc] = useState('');
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [precoIncorreto, setPrecoIncorreto] = useState('');

  const [qtdPecasAmostra, setQtdPecasAmostra] = useState(0);

  //Modal Medidas Produção
  const [showMedidasProducao, setShowMedidasProducao] = useState(false);
  const handleCloseMedidasProducao = () => setShowMedidasProducao(false);
  const handleShowMedidasProducao = () => setShowMedidasProducao(true);

  useEffect(() => {
    listListaStatus();
    listRecebePiloto();
    listListaStatusAnalise();
    listListaResponsavelAnalise();
  }, []);

  const handleClose = () => {
    setShow(false);
    props.handleClose();
  }

  async function handleCloseAll() {
    setShow(false);
    props.handleClose();
  }

  function temRegistrosNoArray(arrayRecebido) {
    return arrayRecebido.length > 0;
  }

  async function listListaStatus() {
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/ListaStatus");
      if (temRegistrosNoArray(lista.data)) {
        const listaStatus = lista.data.map((item, index) => {
          return { value: item.IDStatusDev, label: item.LISTA_STATUS };
        });
        setModelagemEstilo(listaStatus);
        setPecaLacrada(listaStatus);
        setCorte(listaStatus);
        setGraduacao(listaStatus);
        setBeneficiamento(listaStatus);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
  }

  async function listRecebePiloto() {
    const params = { recebePiloto: true }
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/ListaStatus", { params });
      if (temRegistrosNoArray(lista.data)) {
        const listaStatus = lista.data.map((item, index) => {
          return { value: item.IDStatusDev, label: item.LISTA_STATUS };
        });
        setRecebePiloto(listaStatus);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar recebe piloto!');
      return false;
    }
  }

  async function listListaStatusAnalise() {
    try {
      const listaStatus = await api.get('PAMonitorCheckList/ListaStatusAnalise');
      setStatusAnalise(listaStatus.data);
    } catch (error) {
      toast.error('Ocorreu um erro para carregar status de análise!');
    }
  }

  async function listListaResponsavelAnalise() {
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/ListaResponsavelAnalise");
      if (temRegistrosNoArray(lista.data)) {
        const listaStatus = lista.data.map((item, index) => {
          return { value: item.IDFUNCIONARIO, label: item.LISTAFUNCIONARIO };
        });
        setResponsavelAnalise(listaStatus);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de responsáveis!');
      return false;
    }
  }

  const handleModelagemEstilo = async (opcaoSelecionada) => {
    setModelagemEstiloSelecionada(opcaoSelecionada);
  }
  const handlePecaLacrada = async (opcaoSelecionada) => {
    setPecaLacradaSelecionada(opcaoSelecionada);
  }
  const handleGraduacao = async (opcaoSelecionada) => {
    setGraduacaoSelecionada(opcaoSelecionada);
  }
  const handleRecebePiloto = async (opcaoSelecionada) => {
    setRecebePilotoSelecionada(opcaoSelecionada);
  }
  const handleStatusAnalise = async (opcaoSelecionada) => {
    setStatusAnaliseSelecionada(opcaoSelecionada);
  }
  const handleResponsavelAnalise = async (opcaoSelecionada) => {
    setResponsavelAnaliseSelecionado(opcaoSelecionada);
  }
  const handleDestino = async (opcaoSelecionada) => {
    setDestinoSelecionado(opcaoSelecionada.value);
    setDestinoSelecionadoDesc(opcaoSelecionada.label);
  }

  function habilitaDadosAnalise() {
    const desabilita = !(modelagemEstiloSelecionada && pecaLacradaSelecionada && graduacaoSelecionada && recebePilotoSelecionada);
    return desabilita;
  }

  function desabilitaStatus60(value) {
    if (value === '60' && props.item.FilialRevisao.trim() !== 'CONTROLE QUALIDADE ATAC') return true;
    return false;  
  }

  async function getGradeEncaminhamento() {
    try {
      const { Produto, CorProduto, NFEntrada, SerieNF, Origem, OrdemProducao, QtdeEntrada, FilialRevisao } = props.item;
      const params = {
        Produto: Produto,
        CorProduto: CorProduto,
        NFEntrada: NFEntrada,
        SerieNF: SerieNF,
        Origem: Origem,
        OrdemProducao: OrdemProducao,
        QtdeEntrada: QtdeEntrada,
        FilialRevisao: FilialRevisao,
        TipoAnalise: '01 - PRODUÇÃO'
      };
      const lista = await retryApi('get', "PAMonitorCheckList/ListaDirecionamento", { params });
      const gradeEncaminhamento = []
      if (lista.data.length > 0) {
        lista.data.forEach((lt) => {
          gradeEncaminhamento.push({
            destino: {
              label: "01 - ESTOQUE",
              value: 1
            },
            obs: "",
            qtdPecas: lt.Qtde_Pendente,
            tamanho: lt.Tamanho,
            tipoAnalise: "01 - PRODUÇÃO"
          })
        })
      }

      return gradeEncaminhamento

    } catch (error) {
      toast.error('Ocorreu um erro para carregar a grade!');
      return false;
    }
  }

  const handleAvancarRevisao = () => {
    if (
      (statusAnaliseSelecionada['value'] === '4' && props.item.StatusRevisao === 'APROVADO') ||
      (statusAnaliseSelecionada['value'] === '57' && props.item.StatusRevisao === 'SEM ANALISE CQ - DIRETORIA OPERACOES')
    ) {
      setShowModalConfirmacao(true);
    }
    else setShow(true);
  }

  const handleProcess = async () => {
    try {
      const gradeDestino = await getGradeEncaminhamento();
      const params = {
        idArea: '3',
        produto: props.item,
        Responsavel: responsavelAnaliseSelecionado,
        StatusAnalise: statusAnaliseSelecionada.value,
        qtdPecasAmostra: qtdPecasAmostra,
        identificacao: { modelagemEstiloSelecionada, pecaLacradaSelecionada, graduacaoSelecionada, recebePilotoSelecionada, obsRecebimentoPiloto },
        user: CookiesService.getUserLogin(),
        GradeDefeitos: [],
        GradeDevolucao: gradeDestino,
        StatusCQPA: 4,
        EnviaAprovacao: false,
        AreaAprovacao: "",
        IndicaCulpabilidade: false,
        PendenciarDirecionamento: false,
        Consideracoes: "",
        totalPecasDevolucao: 0,
      }

      await retryApi('post', "/PAMonitorCheckList/FinalizarProcesso", params);
      toast.success('Dados alterados com sucesso!');
      props.handleClose();
      window.location.href = "/PA/monitorchecklist";
    } catch (e) {
      setShowModalConfirmacao(true);
      console.error(e);
      toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
    }
    setProcessing(false);
  }

  return (
    <div>
      {/* Entrada Fiscal */}
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Entrada Fiscal</Accordion.Header>
          <Accordion.Body style={{ padding: "0px" }}>
            <div className={styles.gridRow}>
              <div className={styles.gridItem}>
                <span>Nota Fiscal:</span>
                <span className={styles.fontBlue}>{props.item.NFEntrada}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Origem:</span>
                <span className={styles.fontBlue}>{props.item.Origem}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Filial Revisão:</span>
                <span className={styles.fontBlue}>{props.item.FilialRevisao}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Série:</span>
                <span className={styles.fontBlue}>{props.item.SerieNF}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Qtde Recebida:</span>
                <span className={styles.fontBlue}>{props.item.QtdeEntrada}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Recebimento:</span>
                <span className={styles.fontBlue}>{toUTCDDMMYYYY(props.item.Recebimento)}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Ordem Produção:</span>
                <span className={styles.fontBlue}>{props.item.OrdemProducao}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Qtde a Revisar:</span>
                <span className={styles.fontBlue}>FALTANDO</span>
              </div>
              <div className={styles.gridItem}>
                <span>Entrada Revisão:</span>
                <span className={styles.fontBlue}>{toUTCDDMMYYYY(props.item.DataEntradaRevisao)}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Ordem Serviço:</span>
                <span className={styles.fontBlue}>{props.item.OrdemServico}</span>
              </div>
              <div className={styles.gridItem}>
                <span>Porc. a Revisar:</span>
                <span className={styles.fontBlue}>FALTANDO</span>
              </div>
              <div className={styles.gridItem}>
                <span>Digitação:</span>
                <span className={styles.fontBlue}>{toUTCDDMMYYYY(props.item.DataDigitacao)}</span>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Identificação Produto */}
      <Accordion style={{ marginTop: "10px" }}>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Identificação do Produto</Accordion.Header>
          <Accordion.Body>
            <ProdutoInfo item={props.item}></ProdutoInfo>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Accordion Análise Defeitos */}
      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Análise Defeitos</Accordion.Header>
          <Accordion.Body>
            <div className="App">
              <table>
                <tr>
                  <th>Descrição Tamanho:</th>
                  <th>Descrição Defeito:</th>
                  <th>Quantidade com Defeito:</th>
                </tr>
                {props.listaDefeitos.map((objeto, index) => {
                  return (
                    <tr index={index}>
                      <td>{objeto.DescricaoGrade}</td>
                      <td>{objeto.DescricaoDefeito}</td>
                      <td>{objeto.QtdePecasDefeito}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Identificação Desenvolvimento */}
      <article className={styles.articleBorder}>
        <h5>Identificação Desenvolvimento</h5>

        <div className={styles.formLine}>
          <Form className={styles.formIdentificacao}>
            <Form.Label className={styles.formLabel}>Modelagem Estilo</Form.Label>
            <Select
              closeMenuOnSelect={true}
              onChange={(e) => { handleModelagemEstilo(e); }}
              options={modelagemEstilo}
            />
          </Form>
          <Form className={styles.formIdentificacao}>
            <Form.Label className={styles.formLabel}>Peça Lacrada:</Form.Label>
            <Select
              closeMenuOnSelect={true}
              onChange={(e) => { handlePecaLacrada(e); }}
              options={pecaLacrada}
            />
          </Form>
          <Form className={styles.formIdentificacao}>
            <Form.Label className={styles.formLabel}>Graduação:</Form.Label>
            <Select
              closeMenuOnSelect={true}
              onChange={(e) => { handleGraduacao(e); }}
              options={graduacao}
            />
          </Form>
          <Form className={styles.formIdentificacao}>
            <Form.Label className={styles.formLabel}>Receb. Piloto:</Form.Label>
            <Select
              closeMenuOnSelect={true}
              onChange={(e) => { handleRecebePiloto(e); }}
              options={recebePiloto}
            />
          </Form>

        </div>
        <div className={styles.formLine}>
          <Form>
            <Form.Label className={styles.formLabel}>
              Observação Recebimento Piloto
              <span className={styles.spanOpcional}>(Opcional)</span>
              :
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              id="exampleFormControlTextarea1"
              onChange={e => (setObsRecebimentoPiloto(e.target.value))}
            >
            </Form.Control>
          </Form>
        </div>
        <div className={styles.formLine} id="deb" style={{ display: 'inline' }}>
        </div>
      </article>

      {/* Dados Análise */}
      <article className={styles.articleBorder} style={{ marginBottom: 70 }}>
        <h5>Dados Análise</h5>

        <div className={styles.formLine}>
          <Form className={styles.formDadosanalise}>
            <Form.Label className={styles.formLabel}>Status Checklist:</Form.Label>
            <Select
              closeMenuOnSelect={true}
              onChange={handleStatusAnalise}
              options={statusAnalise}
              isDisabled={habilitaDadosAnalise() === true}
              isOptionDisabled={opt => desabilitaStatus60(opt.value)}
            />
          </Form>
          <Form className={styles.formDadosanalise}>
            <Form.Label className={styles.formLabel}>Responsável:</Form.Label>
            <Select
              closeMenuOnSelect={true}
              onChange={handleResponsavelAnalise}
              options={responsavelAnalise}
              isOptionDisabled={(option) => habilitaDadosAnalise()}
            />
          </Form>
          <Form className={styles.formDadosanalise}>
            <Form.Label className={styles.formLabel}>Qtde Peças Amostra:</Form.Label>
            <Form.Control
              type="number"
              min="0"
              onChange={(e) => (setQtdPecasAmostra(e.target.value))}
              isOptionDisabled={(option) => habilitaDadosAnalise()}
            />
          </Form>
          <Form className={styles.formDadosanalise}>
            <Form.Label className={styles.formLabel}>
              {Math.round((parseInt(qtdPecasAmostra) / parseInt(props.item.QtdeEntrada === 0 || props.item.QtdeEntrada === null ? 1 : props.item.QtdeEntrada)) * 100, 2)}
              % Revisado:
            </Form.Label>
            <span className={styles.spanGray}></span>
          </Form>
        </div>
      </article>

      {/* Footer */}
      <article className={styles.footer}>
        <Button
          className={styles.footerBtn}
          variant="outline-primary"
          onClick={handleShowMedidasProducao}
        >
          Medidas Produção
        </Button>

        <Button //Só habilita quando tudo acima estiver preenchido
          className={styles.footerBtn}
          variant="primary"
          onClick={() => { handleAvancarRevisao() }}
          disabled={
            statusAnaliseSelecionada === '' || responsavelAnaliseSelecionado === '' || qtdPecasAmostra === ''
          }
        >
          Prosseguir
        </Button>
      </article>

      <Modal
        show={showModalConfirmacao}
        backdrop="static"
        keyboard={false}
        centered={true}
        style={{ backgroundColor: '#00000090' }}
      >
        <Modal.Header>
          <Modal.Title>
            Ao selecionar '04 - Aprovado' no status, a revisão será salva e voltará para a tela de fila.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={styles.footerBtn}
            style={{ width: '40%' }}
            variant="primary"
            onClick={() => { setProcessing(true); setShowModalConfirmacao(false); handleProcess(); }}
          >
            Ok
          </Button>
          <Button
            className={styles.footerBtn}
            style={{ width: '40%' }}
            variant="primary"
            onClick={() => (setShowModalConfirmacao(false))}
          >
            Voltar
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen={true}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Identificação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MonitorChecklistAnaliseGrade
            identificacao={{ beneficiamentoSelecionada, modelagemEstiloSelecionada, pecaLacradaSelecionada, corteSelecionada, graduacaoSelecionada, recebePilotoSelecionada, obsRecebimentoPiloto }}
            item={props.item} qtdPecasAmostra={qtdPecasAmostra} statusAnaliseSelecionada={statusAnaliseSelecionada} responsavelAnaliseSelecionado={responsavelAnaliseSelecionado} handleClose={handleCloseAll} precoIncorreto={precoIncorreto} setPrecoIncorreto={setPrecoIncorreto}></MonitorChecklistAnaliseGrade>
        </Modal.Body>
      </Modal>

      <Modal
        show={showMedidasProducao}
        onHide={handleCloseMedidasProducao}
        backdrop="static"
        fullscreen={true}
        centeredc
        keyboard={false}
        dialogClassName={styles.modalWith}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MedidasProducao item={props.item}></MedidasProducao>
        </Modal.Body>
      </Modal>
      <Modal
        show={processing}
        backdrop={"static"}
        centered={true}
        style={{ backgroundColor: '#00000090' }}
        fullscreen={false}
      >
        <Modal.Body>
          <Carregando></Carregando>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MonitorChecklistIdentificacao;