import React, { useState } from 'react';
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import { api }  from '../../../services/api';

import styles from '../../../styles/PA/ADM/OrientacaoConserto.module.scss';
import Carregando from '../../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { toDatetime } from '../../../utils/dateUtils';
import CookiesService from "../../../services/cookies";


const OrientacaoConserto = () => {
  const [mainData, setMainData] = useState([]);
  const [activeObj, setActiveObj] = useState({});
  const [formObj, setFormObj] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [filterObj, setFilterObj] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [grupoAnaliseList, setGrupoAnaliseList] = useState([]);
  const [analiseCQList, setAnaliseCQList] = useState([]);
  const [direcionamentoList, setDirecionamentoList] = useState([]);
  const [loading, setLoading] = useState(false);

  const userLogin = CookiesService.getUserLogin();

  const handleSearch = async () => {
    setLoading(true);
    const params = {
      ...filterObj
    }
    if (Object.keys(params).length < 1) {
      setLoading(false);
      return toast.warn('Por favor preencha ao menos um campo de busca');
    }

    await api.get('/OrientacaoConserto/GetMainData', { params })
      .then(res => {
        setMainData(res.data);
      }).catch(e => {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
        console.error(`Erro na requisição '/GetMainData' - ${e}`);
      });
    setLoading(false);
  }

  const handleShowModal = async (obj) => {
    const params = {
      NFEntrada: obj.NFEntrada,
      SerieNF: obj.SerieNF,
      Origem: obj.Origem,
      Produto: obj.Produto,
      CorProduto: obj.CorProduto,
      QtdeEntrada: obj.QtdeEntrada,
      FilialRevisao: obj.FilialRevisao
    }

    await api.get('/OrientacaoConserto/GetGrupoAnaliseList', { params })
      .then(res => setGrupoAnaliseList(res.data))
      .catch(e => console.error(e));

    await api.get('/OrientacaoConserto/ConsultarAnaliseCQ', { params })
      .then(res => setAnaliseCQList(res.data))
      .catch(e => console.error(e));

    await api.get('/OrientacaoConserto/ConsultarDirecionamento', { params })
      .then(res => setDirecionamentoList(res.data))
      .catch(e => console.error(e));

    setShowModal(true);
    setActiveObj(obj);
  }
  const handleCloseModal = () => {
    setShowModal(false);
    setActiveObj({});
  }

  const handleFormValidation = () => {
    const { grupoAnalise, orientacao } = formObj;
    const errs = {}
    if (!grupoAnalise) errs.grupoAnalise = 'Selecione um grupo análise'; 
    if (!grupoAnalise || orientacao === '') errs.orientacao = 'O campo orientações não pode estar vazio';
    return errs;
  }

  const handleProcessData = async () => {
    const errs = handleFormValidation();
    if (Object.keys(errs).length > 0) return setFormErrs(errs);

    const params = {
      dataRegistro: toDatetime(new Date()),
      // idUsuario: userLogin || 'USUARIO.TESTE',
      idUsuario: userLogin,
      NFEntrada: activeObj.NFEntrada,
      SerieNF: activeObj.SerieNF,
      Origem: activeObj.Origem,
      Produto: activeObj.Produto,
      CorProduto: activeObj.CorProduto,
      QtdeEntrada: activeObj.QtdeEntrada,
      FilialRevisao: activeObj.FilialRevisao,
      acao: formObj.acao?.value,
      idGrupoAnalise: formObj.grupoAnalise?.value,
      orientacao: formObj.orientacao !== '' ? formObj.orientacao : null 
    }

    await api.post(`/OrientacaoConserto/IncluirOrientacao`, params)
      .then(res => {
        if (res.status === 200) {
          toast.success('Os dados foram inseridos com sucesso! Redirecionando para a página anterior...')
          setShowModal(false);
          setActiveObj({});
          setMainData([]);
        }
      })
      .catch(e => {
        toast.error('Ocorreu um erro ao inserir os dados, por favor cheque sua conexão de internet e/ou entre em contato com o suporte.');
        return console.error(`Erro na requisição '/IncluirOrientacao' - ${e}`);
      });
  }

  const handleValueMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
    if (value === '') {
      delete formObj[field];
      return setFilterObj({ ...formObj});
    }
    setFilterObj({ ...formObj, [field]: value });
  }

  return (
    <div>
      <article>
        <h3 className="p-2">ADM PA - Orientação Conserto</h3>
        <Form className={styles.formWrapper}>
          <Form.Group className={styles.filterInput}>
            <Form.Label className={styles.formLabel}>NF Entrada</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Nota fiscal'}
              className={styles.formControl}
              value={filterObj.NFEntrada || ''}
              onChange={e => handleValueMask('NFEntrada', e.target.value)}
            />
          </Form.Group>
          <Form.Group className={styles.filterInput}>
            <Form.Label className={styles.formLabel}>Produto</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Produto'}
              className={styles.formControl}
              value={filterObj.Produto || ''}
              onChange={e => handleValueMask('Produto', e.target.value)}
            />
          </Form.Group>
          <Form.Group className={styles.filterInput}>
            <Form.Label className={styles.formLabel}>Cor Produto</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Cor Produto'}
              className={styles.formControl}
              value={filterObj.CorProduto || ''}
              onChange={e => handleValueMask('CorProduto', e.target.value)}
            />
          </Form.Group>
          <Button
            type="button"
            variant="primary"
            className={styles.filterBtn}
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </Form>
      </article>

      <article className={styles.articleGray}>
        {loading === true ? <Carregando></Carregando> : 
          <Table className="m-2" responsive>
            <thead>
              <tr>
                <th className={styles.blockTitle}></th>
                <th className={styles.blockTitle}>NF ENTRADA</th>
                <th className={styles.blockTitle}>SERIE NF</th>
                <th className={styles.blockTitle}>ORIGEM</th>
                <th className={styles.blockTitle}>PRODUTO</th>
                <th className={styles.blockTitle}>COR PRODUTO</th>
                <th className={styles.blockTitle}>QTDE ENTRADA</th>
                <th className={styles.blockTitle}>FILIAL REVISÃO</th>
                <th className={styles.blockTitle}>STATUS CQ</th>
                <th className={styles.blockTitle}>STATUS ESTILO</th>
                <th className={styles.blockTitle}>STATUS PRODUTO</th>
              </tr>
            </thead>
            <tbody>
              {mainData.map((obj, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <i style={{ 'cursor': 'pointer' }}
                        className={styles.icon}
                        onClick={() => handleShowModal(obj)}
                      >
                        <MaterialIcon
                          icon="build"
                          size={30}
                          color='#0D6EFD'
                        />
                      </i>
                    </td>
                    <td className={styles.tableLines}>{obj.NFEntrada}</td>
                    <td className={styles.tableLines}>{obj.SerieNF}</td>
                    <td className={styles.tableLines}>{obj.Origem}</td>
                    <td className={styles.tableLines}>{obj.Produto}</td>
                    <td className={styles.tableLines}>{obj.CorProduto}</td>
                    <td className={styles.tableLines}>{obj.QtdeEntrada}</td>
                    <td className={styles.tableLines}>{obj.FiliealRevisao}</td>
                    <td className={styles.tableLines}>{obj.StatusCQ}</td>
                    <td className={styles.tableLines}>{obj.StatusEstilo}</td>
                    <td className={styles.tableLines}>{obj.StatusProduto}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        }
      </article>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>ADM PA - Orientação Conserto</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Accordion >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Identificação</Accordion.Header>
              <Accordion.Body style={{ padding: "0px" }}>
                <div className={styles.gridRow}>
                  <div className={styles.gridItem}>
                    <span>NF Entrada:</span>
                    <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
                  </div>
                  <div className={styles.gridItem}>
                    <span>Série NF:</span>
                    <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
                  </div>
                  <div className={styles.gridItem}>
                    <span>Origem:</span>
                    <span className={styles.fontBlue}>{activeObj.Origem}</span>
                  </div>
                  <div className={styles.gridItem}>
                    <span>Qtde Recebida:</span>
                    <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
                  </div>
                  <div className={styles.gridItem}>
                    <span>Produto:</span>
                    <span className={styles.fontBlue}>{activeObj.Produto}</span>
                  </div>
                  <div className={styles.gridItem}>
                    <span>Cor Produto:</span>
                    <span className={styles.fontBlue}>{activeObj.CorProduto}</span>
                  </div>
                  <div className={styles.gridItem}>
                    <span>Filial Revisão:</span>
                    <span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className={styles.modalMainWrapper}>
            <Form className={styles.modalFormWrapper}>
              <h6>Orientação Conserto</h6>

              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Grupo Análise</Form.Label>
                <Select
                  options={grupoAnaliseList}
                  isClearable={true}
                  escapeClearsValue={true}
                  placeholder={'Grupo Análise'}
                  // value={formObj.grupoAnalise || null}
                  onFocus={() => setFormErrs({ ...formErrs, grupoAnalise: null })}
                  onChange={e => setFormObj({ ...formObj, grupoAnalise: e })}
                  isInvalid={formErrs.grupoAnalise}
                  styles={{
                    control: (provided) => (
                      formErrs.grupoAnalise ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                    )
                  }}
                />
                <div className={formErrs.grupoAnalise ? styles.showFeedback : styles.hidden}>{formErrs.grupoAnalise}</div>
              </Form.Group>

              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Orientações:</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={5}
                  placeholder={'Orientações'}
                  onFocus={() => setFormErrs({ ...formErrs, orientacao: null })}
                  value={formObj.orientacao || ''}
                  onChange={e => setFormObj({ ...formObj, orientacao: e.target.value })}
                  isInvalid={formErrs.orientacao}
                />
                <div className={formErrs.orientacao ? styles.showFeedback : styles.hidden}>{formErrs.orientacao}</div>
              </Form.Group>

              <Button
                variant="primary"
                className={styles.modalFormBtn}
                onClick={handleProcessData}
                disabled={!formObj.grupoAnalise || !formObj.orientacao}
              >
                Adicionar Orientação Conserto
              </Button>
            </Form>

            <Accordion className={styles.modalInfo} defaultActiveKey={['0']}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Resumo Análise Qualidade e Direcionamento Peças</Accordion.Header>
                <Accordion.Body style={{ padding: "0px", backgroundColor: "#fdfdfd" }}>
                  <div className={styles.accordionResumo}>
                    <article className={styles.accordionStatus}>
                      <div>
                        <span className={styles.accordionStatusMarginRight}>Status CQ:</span>
                        <span className={styles.fontBlue}>{activeObj.StatusCQ}</span>
                      </div>
                      <div>
                        <span className={styles.accordionStatusMarginRight}>Status Estilo:</span>
                        <span className={styles.fontBlue}>{activeObj.StatusEstilo}</span>
                      </div>
                      <div>
                        <span className={styles.accordionStatusMarginRight}>Status Produto:</span>
                        <span className={styles.fontBlue}>{activeObj.StatusProduto}</span>
                      </div>
                    </article>

                    <article className={styles.divAnaliseDirecionamento}>
                      <div className={styles.analiseWrapper}>
                        <h6>Análise Qualidade</h6>
                        <Table responsive className={styles.analiseTable}>
                          <thead>
                            <tr>
                              <th className={styles.blockTitle}>TIPO DE ANÁLISE</th>
                              <th className={styles.blockTitle}>ÁREA</th>
                              <th className={styles.blockTitle}>GRADE</th>
                              <th className={styles.blockTitle}>GRUPO DEFEITO</th>
                              <th className={styles.blockTitle}>DEFEITO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {analiseCQList.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td className={styles.tableLines}>{obj.DescricaoTipoAnalise}</td>
                                  <td className={styles.tableLines}>{obj.Area}</td>
                                  <td className={styles.tableLines}>{obj.DescricaoGrade}</td>
                                  <td className={styles.tableLines}>{obj.GrupoDefeito}</td>
                                  <td className={styles.tableLines}>{obj.Defeito}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className={styles.analiseWrapper}>
                        <h6>Direcionamento Peças</h6>
                        <Table responsive className={styles.analiseTable}>
                          <thead>
                            <tr>
                              <th className={styles.blockTitle}>TIPO DE ANÁLISE</th>
                              <th className={styles.blockTitle}>GRADE</th>
                              <th className={styles.blockTitle}>QTDE PEÇAS</th>
                              <th className={styles.blockTitle}>DESTINO</th>
                              <th className={styles.blockTitle}>ÁREA</th>
                            </tr>
                          </thead>
                          <tbody>
                            {direcionamentoList.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td className={styles.tableLines}>{obj.DescricaoTipoAnalise}</td>
                                  <td className={styles.tableLines}>{obj.DescricaoGrade}</td>
                                  <td className={styles.tableLines}>{obj.QtdePecas}</td>
                                  <td className={styles.tableLines}>{obj.DescricaoDestino}</td>
                                  <td className={styles.tableLines}>{obj.Area}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </article>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div >
  );
};

export default OrientacaoConserto;