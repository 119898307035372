import { useState,forwardRef, useRef, useImperativeHandle  } from "react";
import styles from "../../styles/MonitorChecklistAnaliseGrade.module.scss";

const RadioList = forwardRef((props, ref) => {
const [checkedList, setCheckedList] = useState(props.options);

  useImperativeHandle(ref, () => ({
    clearChecks() {
      var radios = document.getElementsByName("options");
      for(let i=0; i < radios.length; i++) {
        radios[i].checked = false;
      }
    }
  }));

  function toggleOption(id, checked) {
    return props.options.map((option) =>
      option.id === id ? { ...option, checked } : option
    );
  }

  const changeList = (item, checked) => {
    const newCheckedList = toggleOption(item.value, checked);
    setCheckedList(newCheckedList);
    props.changeList(item);
  };

  
  return (
    <form className={styles.radioButtonForm}>
      <div className={styles.radioButtonListStyle}>
        {checkedList.map((item) => (
          <label key={item.value} style={{display:"block"}}>
            <input
              type="radio"
              name="options"
              value={item.value}
              //checked={item.checked}
              onChange={(e) => changeList(item, e.target.checked)}
              style={{marginRight: "10px"}}
            />
            {item.label}
          </label>
        ))}
      </div>
    </form>
  );
});

export default RadioList;
