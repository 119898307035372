import React, { useState, useEffect } from "react";
import { Button, Table } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { api }  from "../../../../services/api";
import CookiesService from "../../../../services/cookies";

import styles from "../../../../styles/AdmEnvioRetornoAmostra.module.scss";

function CancelarRecebimento(props) {
    const [acao, setAcao] = useState([]);
    const [motivo, setMotivo] = useState([]);
    const [setor, setSetor] = useState([]);
    const [listaRetornoAmostra, setListaRetornoAmostra] = useState([]);
    const userLogin = CookiesService.getUserLogin();

    useEffect(() => {
        api.get("CatalogoADM/ADMEnvioAcao").then((result) => {
            setAcao(result.data);
        });

        api.get("CatalogoADM/ADMEnvioMotivo").then((result) => {
            setMotivo(result.data);
        });

        api.get("CatalogoADM/ADMEnvioArea").then((result) => {
            setSetor(result.data);
        });

        api.get("CatalogoADM/ADMEnvioArea").then((result) => {
            setListaRetornoAmostra(result.data);
        });
    }, []);

    async function processarCancelarRecebimento() {
        let amostra = {
            ACAO: parseInt(props.acaoSelecionada),
            ID_CADASTRO: props.lista[0].idCadastro,
            ID_USUARIO: userLogin,
            ID_MOTIVO_ENVIO: null,
            ID_SETOR_ENVIO: null,
            NOME_REPONSAVEL: null,
            LACRE: null,
            DATA_ENVIO: null,
            OBSERVACAO: null,
            ID_MOTIVO_NAO_RETORNO: null 
        };
        api
        .post(`/CatalogoADM/ADMEnvioRetornoProcessar`, amostra)
        .then((response) => {
            if (response.status === 200) {
                toast.success(Object.values(response.data[0])[0]);
            setTimeout(function () {}, 5000);
            } else {
                toast.error(
                    "Erro Status Code: " +
                    response.status +
                    ". \nEntre em contato com o suporte."
                );
            }
        });
    }

    return (
        <body className="m-3">
            <h5>Amostras a confirmar recebimento</h5>

            <article className={styles.articleGray}>
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>COR</th>
                            <th className={styles.blockTitle}>COD. FORN.</th>
                            <th className={styles.blockTitle}>ID LOCAL.</th>
                            <th className={styles.blockTitle}>MOTIVO ENVIO</th>
                            <th className={styles.blockTitle}>AREA ENVIO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.lista.map((locais) => {
                            return (
                                <tr>
                                    <td className={styles.tableLines}>{locais.material}                                </td>
                                    <td className={styles.tableLines}>{locais.CorMaterial}</td>
                                    <td className={styles.tableLines}>{locais.codClifor}</td>
                                    <td className={styles.tableLines}>{locais.idCadastro}</td>
                                    <td className={styles.tableLines}>{locais.idLocalizacao}</td>
                                    <td className={styles.tableLines}>{locais.idLocalizacao}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </article>
            
            <Button 
                type="button" 
                onClick={processarCancelarRecebimento}
            >
                Cancelar Recebimento - Erro Baixa
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                />
            </Button>
        </body>
    )
}

export default CancelarRecebimento;
 