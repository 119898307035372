import React from "react";
import { Form } from "react-bootstrap";

function Select(props) {
  return (
    <Form.Select aria-label={props.label} onChange={props.change}>
      <option value={null} selected={true} disabled readOnly>
        {props.label}
      </option>
      {props.options.map((locais, index) => {
        return (
          <option
            key={props.index}
            value={locais.local}
            id={props.prefix + index}
          >
            {locais.local}
          </option>
        );
      })}
    </Form.Select>
  );
}

export default Select;
