import React, { useEffect } from 'react';
import MaterialIcon from 'material-icons-react';
import { useFGTesteDeBase } from "../../../../contexts/FarmGlobal/TesteDeBaseContext.js";
import { Button, Table, Form } from 'react-bootstrap';
import styles from '../../../../styles/FarmGlobal/TesteDeBase.module.scss';
import ModalTesteDeBaseAnalisar from './ModalTesteDeBaseAnalisar.jsx';
import ModalConfirmacaoRecebimento  from './ModalConfirmacaoRecebimento.jsx'
import ModalArquivos from './ModalArquivos.jsx';

const TesteDeBaseAnalisarMain = () => {
    const { mainDataGrid, handleShowCad, showCad, handleShowPDF,handleSortGrid,getSortIcon, handleSearchGridPendentes, filterObjGrid, setFilterObjGrid, removeAccents,btSearchGridPendentes, setMaterialSelecionado,
            handleShowCadTeste,
              setTesteSelecionado,
              testeSelecionado
              ,showConfirmacaoRecebimento
              ,setShowConfirmacaoRecebimento
              ,handleShowFetchArquivos
     } = useFGTesteDeBase();   

    useEffect(() => {
        handleSearchGridPendentes();
    }, []);    

    return (
        <div>
            <article className="d-flex align-items-center">
                <h3 className="p-3">Testes Pendentes</h3>
            </article>
            <article>
                <Form className={styles.formWrapper}>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Desc. Material</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Material'}
                            className={styles.formControl}
                            value={removeAccents(filterObjGrid.descricao) || ''}
                            onChange={(event) => setFilterObjGrid({ ...filterObjGrid, descricao: event.target.value })}
                            onKeyPress={(e) => {
                                             return removeAccents(e.key);
                                        }}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Tipo</Form.Label>
                        <Form.Control
                            as="select"
                            className={styles.formControl}
                            value={filterObjGrid.tipo || ''}
                            onChange={(event) => setFilterObjGrid({ ...filterObjGrid, tipo: event.target.value })}
                        >
                            <option value="">Todos</option>
                            <option value="Tinto">Tinto</option>
                            <option value="Estampado Cilindro">Estampado Cilindro</option>
                            <option value="Estampado Digital">Estampado Digital</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Gramatura(g/m²)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Gramatura(g/m²)'}
                            className={styles.formControl}
                            value={filterObjGrid.gramatura || ''}
                            onChange={(event) => setFilterObjGrid({ ...filterObjGrid, gramatura: event.target.value })}
                        />
                    </Form.Group>    
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Status</Form.Label>
                        <Form.Control
                            as="select"
                            className={styles.formControl}
                            value={filterObjGrid.idStatus || ''}
                            onChange={(event) => setFilterObjGrid({ ...filterObjGrid, idStatus: event.target.value })}
                        >
                            <option value="">Todos</option>
                            <option value="4">Aprovado</option>
                            <option value="8">Reprovado</option>
                            <option value="45">Pendente Laboratório</option>
                            <option value="46">Aprovado com Nova Ficha Técnica</option>                           
                            <option value="63">Enviado Nova Ficha Técnica</option>                           
                            <option value="64">Enviado uma Nova Amostra</option>                           
                            <option value="65">Pendente de Recebimento</option>                           
                            <option value="66">Recebimento Reprovado</option>                           
                        </Form.Control>
                    </Form.Group> 
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Nº Requisição</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Nº Requisição'}
                            className={styles.formControl}
                            value={filterObjGrid.IdTesteBase || ''}
                            onChange={(event) => setFilterObjGrid({ ...filterObjGrid, IdTesteBase: event.target.value })}
                        />
                    </Form.Group>                   
                    <Button
                        type="button"
                        variant="primary"
                        className={styles.filterBtn}
                        onClick={btSearchGridPendentes}
                    >
                        Buscar Testes
                    </Button>
                </Form>
            </article>
            <article className={styles.articleGray}>
            <Table className="m-2" responsive>
                <thead>
                    <tr>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('DescricaoStatus')}
                        >
                            STATUS
                            <MaterialIcon icon={getSortIcon('DescricaoStatus')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('ArquivoLaudo')}
                        >
                            LAUDO
                            <MaterialIcon icon={getSortIcon('ArquivoLaudo')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('Arquivo')}
                        >
                            FICHA TÉCNICA
                            <MaterialIcon icon={getSortIcon('Arquivo')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('Descricao')}
                        >
                            DESCRIÇÃO MATERIAL
                            <MaterialIcon icon={getSortIcon('Descricao')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('Tipo')}
                        >
                            TIPO
                            <MaterialIcon icon={getSortIcon('Tipo')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('FornecedorMp')}
                        >
                            FORNECEDOR MP
                            <MaterialIcon icon={getSortIcon('FornecedorMp')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('FornecedorPa')}
                        >
                            FORNECEDOR PA
                            <MaterialIcon icon={getSortIcon('FornecedorPa')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('ChaveTipo')}
                        >
                            CÓDIGO TECIDO
                            <MaterialIcon icon={getSortIcon('ChaveTipo')} size={16} />
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSortGrid('IdTesteBase')}
                        >
                            Nº REQUISIÇÃO
                            <MaterialIcon icon={getSortIcon('IdTesteBase')} size={16} />
                        </th>     
                        <th></th>                  
                    </tr>
                </thead>
                <tbody>
                    {mainDataGrid.map((item, index) => (
                        <tr key={index}>
                            <td className={styles.tableLines}>{item.DescricaoStatus}</td>
                            <td className={styles.tableLines}>
                                {item.ArquivoLaudo ? (
                                    <i onClick={() => handleShowPDF(item.ArquivoLaudo)}>
                                        <MaterialIcon icon="description" size={30} color='#0D6EFD' />
                                    </i>
                                ) : ''}
                            </td>
                            <td className={styles.tableLines}>
                                <i onClick={() => handleShowFetchArquivos(item.IdMaterial, item.Descricao)}>
                                    <MaterialIcon icon="more_horiz" size={30} color='#0D6EFD' />
                                </i>
                            </td>
                            <td className={styles.tableLines} onClick={()=> { handleShowCadTeste(item);}}>{item.Descricao}</td>
                            <td className={styles.tableLines} onClick={()=> { handleShowCadTeste(item);}}>{item.Tipo}</td>
                            <td className={styles.tableLines} onClick={()=> { handleShowCadTeste(item);}}>{item.FornecedorMp}</td>
                            <td className={styles.tableLines} onClick={()=> { handleShowCadTeste(item);}}>{item.FornecedorPa}</td>
                            <td className={styles.tableLines} onClick={()=> { handleShowCadTeste(item);}}>{item.ChaveTipo}</td>
                            <td className={styles.tableLines} onClick={()=> { handleShowCadTeste(item);}}>{item.IdTesteBase}</td>              
                            <td>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                { item.IdStatus == '65' && 
                                <>
                                    <Button
                                        type="button"
                                        variant="warning"   
                                        onClick={()=>{handleShowCadTeste(item)}}                                 
                                    >
                                        REGISTRAR RECEBIMENTO
                                    </Button>                                    
                                </>
                                }
                                </div>
                            </td>              
                        </tr>
                    ))}
                </tbody>
            </Table>

            </article>
            <ModalTesteDeBaseAnalisar showCad={showCad} />
            <ModalConfirmacaoRecebimento showCad={showConfirmacaoRecebimento} />
            <ModalArquivos />
        </div>
    );
}

export default TesteDeBaseAnalisarMain;
