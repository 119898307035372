import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  mode: "no-cors",
  headers: {
    authorization: process.env.REACT_APP_SECRET_FRONT,
    "content-type": "application/json",
  }
});

export const retryApi = async (method, url, params = {}, maxRetries = 3, retryInterval = 3000) => {
  let retry = 0;
  while (retry < maxRetries) {
    try {
      return await api[method](url, params);
    } catch (e) {
      if (retry === maxRetries - 1) throw e;
      await new Promise(resolve => setTimeout(resolve, retryInterval));
      retry++;
    }
  }
}