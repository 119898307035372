import React, { useState, useEffect } from "react";
import { Button, Table, Form, FormGroup, Modal, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import styles from "../../../../styles/PA/ControleAprovacao.module.scss";
import { api } from "../../../../services/api";
import { toast } from "react-toastify";
import CookiesService from "../../../../services/cookies";
import { toUTCDDMMYYYY } from "../../../../utils/dateUtils"
import DirecionamentoPecasComp from "../DirecionamentoPecas";
import { useMisc } from "../../../../contexts/MiscContext";
import Tabela from "../../../Misc/Tabela/Tabela";

const ControleAprovacao = () => {
  const { setShowLoading } = useMisc();
  const [show, setShow] = useState(false);
  const [direcionamentoFormObj, setDirecionamentoFormObj] = useState({})
  const [activeObj, setActiveObj] = useState({});
  const [formObj, setFormObj] = useState({});
  const [formOptions, setFormOptions] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [mainArr, setMainArr] = useState([]);
  const [filterErrs, setFilterErrs] = useState({});
  const [filterObj, setFilterObj] = useState({});
  const [analiseQualidadeList, setAnaliseQualidadeList] = useState([]);
  const [gradeDirecionamento, setGradeDirecionamento] = useState([]);
  const [gradeTamanhos, setGradeTamanhos] = useState([]);

  const handleShow = async (el) => {
    setShow(true);
    setActiveObj(el);
  }

  const handleClose = async () => {
    setAnaliseQualidadeList([]);
    setShow(false);
    setFilterObj({});
    setActiveObj({});
    setFormObj({});
  }

  const userLogin = CookiesService.getUserLogin();
  const setFilters = (field, value) => {
    setFilterObj({
      ...filterObj,
      [field]: value
    });

    if (!!filterErrs[field]) {
      setFilterErrs({
        ...filterErrs,
        [field]: null
      });
    }
  }

  const fetchProcessos = async () => {
    await api.get("/PAControleAprovacao/getMonitor")
      .then(res => {
        setMainArr(res.data.slice(0, 100));
      }).catch(e => {
        console.error(e);
        toast.error('Ocorreu um problema, por favor recarregue a página e tente novamente ou entre em contato com o suporte.');
      });
  }

  const handleFilteredSearch = async () => {
    try {
      setShowLoading(true);
      const errs = handleFilterValidation();
      if (Object.keys(errs).length > 0) {
        setFilterErrs(errs);
      } else {
        const params = {
          produto: filterObj.produto,
          corProduto: filterObj.corProduto,
          nfEntrada: filterObj.nfEntrada
        }

        const gridData = await api.get("/PAControleAprovacao/getMonitor", { params })
        if (gridData.data.length > 0) return setMainArr(gridData.data.slice(0, 100));
        return toast.warning('Nenhum processo foi encontrado utilizando seus parâmetros de busca.');
      }
    } catch (e) {
      return toast.error('Ocorreu um erro durante a busca, por favor tente novamente.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleNumberDotMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
    setFilterObj({ ...formObj, [field]: value });
    setFilterErrs({ ...filterErrs, [field]: null });
  }

  const handleFilterValidation = () => {
    const { produto, corProduto, nfEntrada } = filterObj;
    const errs = {}
    if (!isNaN(produto) && parseInt(produto) <= 0) errs.produto = 'Insira um produto válido';
    if (!isNaN(corProduto) && parseInt(corProduto) <= 0) errs.corProduto = 'Insira uma cor de produto válida';
    if (!isNaN(nfEntrada) && parseInt(nfEntrada) <= 0) errs.nfEntrada = 'Insira uma NF válida';
    return errs;
  }

  const fetchModalOptions = async (el) => {
    try {
      setShowLoading(true);
      const params = {
        produto: el.Produto,
        corProduto: el.CorProduto,
        nfEntrada: el.NFEntrada,
        serieNf: el.SerieNF,
        origem: el.Origem,
        qtdEntrada: el.QtdeEntrada,
        filialRevisao: el.FilialRevisao
      }

      const analiseQualidade = await api.get("/PAControleAprovacao/getAnaliseQualidade", { params });
      setAnaliseQualidadeList(analiseQualidade.data);
      handleShow(el);
    } catch (e) {
      console.error(e);
      toast.error('Erro ao preencher os campos de seleção, por favor entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleStatusChange = async (e) => {
    if (e === null) return setFormObj({ ...formObj, Status: null });
    setFormObj({
      ...formObj,
      Status: e
    });
  }

  const clearErrs = (val) => {
    if (val) return setFormErrs({ ...formErrs, [val]: null })
    setFormErrs({
      ...formErrs,
      StatusList: null,
      Consideracao: null
    })
  }

  const handleValidation = () => {
    const { Status, Consideracao } = formObj;
    const errs = {}
    if (!Status) errs.Status = 'Selecione uma opção válida';
    // if (!AlterarStatusFinal) errs.AlterarStatusFinal = 'Selecione uma opção válida';
    if (!Consideracao) formObj.Consideracao = '';
    if (handleCheckGradeTamanho()) errs.Direcionamento = 'Há peças não direcionadas';
    return errs;
  }

  const handleTotalPecas = () => {
    let total = 0;
    gradeDirecionamento.forEach(item => {
      if (item.destino.value === 6 || item.destino.value === 7) total = total + parseInt(item.qtdPecas);
    });
    return total;
  }

  const handleProcess = async () => {
    try {
      setShowLoading(true);
      const errs = handleValidation();
      if (Object.keys(errs).length > 0) return setFormErrs(errs);

      const params = {
        nfEntrada: activeObj.NFEntrada,
        serieNf: activeObj.SerieNF,
        origem: activeObj.Origem,
        produto: activeObj.Produto,
        corProduto: activeObj.CorProduto,
        qtdEntrada: activeObj.QtdeEntrada,
        filialRevisao: activeObj.FilialRevisao,
        confirmaRecebimento: activeObj.ConfirmaRecebimento,
        user: userLogin,
        areaAprovacao: activeObj.AreaAprovacao,
        idStatusFinal: formObj.Status.value,
        alteraStatusFinal: formObj.AlterarStatusFinal ? 'SIM' : 'NAO',
        consideracao: formObj.Consideracao,
        gradeDirecionamento,
        totalPecasDevolucao: handleTotalPecas()
      }

      await api.post("/PAControleAprovacao/processarControleAprovacao", params);
      toast.success('Processamento realizado com sucesso.');
      handleClose();
      fetchProcessos();
      setFilterObj({});
      setActiveObj({});
      setFormObj({});
    } catch (e) {
      toast.error('Ocorreu um erro durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.')
    } finally {
      setShowLoading(false);
    }
  }

  const handleCheckGradeTamanho = () => {
    for (let i = 0; i < gradeTamanhos.length; i++) {
      if (gradeTamanhos[i].Qtde_Pendente > 0) return true;
    }
    return false;
  }

  useEffect(() => {
    try {
      setShowLoading(true);
      fetchProcessos();
      (async () => {
        const statusCQ = await api.get('/PAControleAprovacao/getStatusSCQ');
        setFormOptions({ StatusList: statusCQ.data });
      })();
    } catch (e) {
      toast.error('Ocorreu um erro ao carregar a página, por favor recarregue e tente novamente.');
    } finally {
      setShowLoading(false);
    }
  }, []);

  const controleAprovacaoColumns = [
    {
      cell: row => {
        return (
          <Button onClick={() => fetchModalOptions(row)}>Alterar</Button>
        )
      },
      width: '160px'
    },
    { name: 'Área Aprovação', selector: row => row.AreaAprovacao },
    { name: 'Confirma Recebimento', selector: row => row.ConfirmaRecebimento },
    { name: 'Valida Estilo', selector: row => row.ValidaEstilo },
    { name: 'NF Entrada', selector: row => row.NFEntrada },
    { name: 'Serie NF', selector: row => row.SerieNF },
    { name: 'Origem', selector: row => row.Origem },
    { name: 'Produto', selector: row => row.Produto },
    { name: 'Cor Produto', selector: row => row.CorProduto },
    { name: 'Qtde Entrada', selector: row => row.QtdeEntrada },
    { name: 'Filial Revisão', selector: row => row.FilialRevisao },
    { name: 'Motivo Envio', selector: row => row.MotivoEnvio },
    { name: 'Data Envio', selector: row => toUTCDDMMYYYY(row.DataEnvio) },
    { name: 'Usuario Envio', selector: row => row.IDUsuarioEnvio }
  ];

  return (
    <div className="p-3">
      <article className="mb-3">
        <h3>Controle Aprovação PA</h3>
      </article>

      <article>
        <Form className={styles.form}>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Nota Fiscal</Form.Label>
            <Form.Control
              type="number"
              placeholder={'Número da NF'}
              value={filterObj.nfEntrada || ''}
              onChange={e => setFilters('nfEntrada', e.target.value)}
              isInvalid={filterErrs.nfEntrada}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.nfEntrada}</Form.Control.Feedback>
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Produto</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Produto'}
              value={filterObj.produto || ''}
              onChange={e => handleNumberDotMask('produto', e.target.value)}
              isInvalid={filterErrs.produto}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.produto}</Form.Control.Feedback>
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Form.Label>Cor Produto</Form.Label>
            <Form.Control
              type="number"
              placeholder={'Cor do produto'}
              value={filterObj.corProduto || ''}
              onChange={e => setFilters('corProduto', e.target.value)}
              isInvalid={filterErrs.corProduto}
            />
            <Form.Control.Feedback type='invalid'>{filterErrs.corProduto}</Form.Control.Feedback>
          </FormGroup>
          <Button
            className={styles.formGroup}
            variant="primary"
            type="button"
            onClick={handleFilteredSearch}
          >
            Buscar
          </Button>
        </Form>
      </article>

      <article className={styles.articleGray}>
        <Tabela
          columns={controleAprovacaoColumns}
          data={mainArr}
          paginationPerPage={20}
          highlightOnHover={false}
        />
      </article>

      <Modal
        show={show}
        onHide={handleClose}
        size="fullscreen"
      >
        <Modal.Header closeButton>
          <Modal.Title>Aprovar Análise</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-3">
          {/* Accordion de Identificação do Produto */}
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Identificação do Produto</Accordion.Header>
              <Accordion.Body>
                <article className={styles.monitorInfo}>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>NF Entrada:</span>
                      <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Série NF:</span>
                      <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Produto:</span>
                      <span className={styles.fontBlue}>{activeObj.Produto}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Cor Produto:</span>
                      <span className={styles.fontBlue}>{activeObj.CorProduto}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Origem:</span>
                      <span className={styles.fontBlue}>{activeObj.Origem}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Filial Revisão:</span>
                      <span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                      <span>Qtde Recebida:</span>
                      <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                      <span>Área Aprovação:</span>
                      <span className={styles.fontRed}>{activeObj.AreaAprovacao}</span>
                    </div>
                  </div>
                </article>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <article className={styles.modalFormRow}>
            <div className={styles.modalDefeitosTable}>
              <Form.Label className={styles.formLabel}>Defeitos Encontrados</Form.Label>
              {
                analiseQualidadeList.length > 0 ? <div><b>Essa revisão não encontrou nenhum defeito</b></div> :
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className={styles.blockTitle}>Tipa Análise</th>
                        <th className={styles.blockTitle}>Área</th>
                        <th className={styles.blockTitle}>Grade</th>
                        <th className={styles.blockTitle}>Grupo Defeito</th>
                        <th className={styles.blockTitle}>Defeito</th>
                      </tr>
                    </thead>
                    <tbody>
                      {analiseQualidadeList.length > 0 && analiseQualidadeList.map((el, index) => {
                        return (
                          <tr className={styles.tableLines} key={index}>
                            <td className={styles.tableLines}>{el.DescricaoTipoAnalise}</td>
                            <td className={styles.tableLines}>{el.Area}</td>
                            <td className={styles.tableLines}>{el.DescricaoGrade}</td>
                            <td className={styles.tableLines}>{el.GrupoDefeito}</td>
                            <td className={styles.tableLines}>{el.Defeito}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
              }
            </div>
          </article>
          <article className={styles.modalFormRow}>
            <DirecionamentoPecasComp
              prodInfo={activeObj}
              formObj={direcionamentoFormObj}
              setFormObj={setDirecionamentoFormObj}
              gradeDirecionamento={gradeDirecionamento}
              setGradeDirecionamento={setGradeDirecionamento}
              gradeTamanhos={gradeTamanhos}
              setGradeTamanhos={setGradeTamanhos}
              toast={toast}
            />
          </article>
          <article className={styles.modalFormRow}>
            <Form className={styles.modalForm} style={{ marginTop: '20px' }}>
              <Form.Group className={styles.modalFormGroup} controlId="discountedMeters" style={{ width: '50%' }}>
                <Form.Label className={styles.formLabel}>1. Status final da revisão checklist</Form.Label>
                <Select
                  value={formObj.Status}
                  options={formOptions.StatusList}
                  onChange={obj => { handleStatusChange(obj); clearErrs() }}
                  isClearable={true}
                  escapeClearsValue={true}
                  styles={{
                    control: (provided) => (
                      formErrs.Status ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
                    )
                  }}
                  isOptionDisabled={opt => opt.value === 60 && activeObj.FilialRevisao !== 'CONTROLE QUALIDADE ATAC'}
                />
                <div className={formErrs.StatusList ? styles.showFeedback : styles.hidden}>{formErrs.StatusList}</div>

              </Form.Group>
              <Form.Group className={styles.modalFormGroup} controlId="negotiatedDiscPerc">
                <Form.Label className={styles.formLabel}>
                  2. Considerações <span className={styles.spanOpcional}>(Opcional)</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  className={styles.formTextArea}
                  placeholder={'Considerações'}
                  value={formObj.Consideracao || ''}
                  onChange={e => setFormObj({ ...formObj, Consideracao: e.target.value })}
                />
              </Form.Group>
            </Form>
          </article>
          <article className={styles.modalFormRow} style={{ width: 'calc(50% - 10px)' }}>
            <Button
              variant="outline-danger"
              onClick={handleClose}
            >
              Cancelar Aprovação/Reprovação
            </Button>
            <Button
              variant="primary"
              onClick={handleProcess}
              disabled={!formObj.Status || handleCheckGradeTamanho()}
              style={{ marginLeft: 'auto' }}
            >
              Processar Aprovação/Reprovação
            </Button>
          </article>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ControleAprovacao;
