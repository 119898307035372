import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import useChange from "../hooks/useChange";
import { api } from "../services/api";

import ProcessoModal from '../components/Processos/pecasModal';
import GrupoModal from '../components/Processos/grupoModal';

import CustomPagination from "../components/Utils/CustomPagination";

import "../styles/Processos.css";
import CookiesService from "../services/cookies";

const Processos = () => {

  const [load, setLoad] = useState(false);

  const [processos, setProcessos] = useState([]);
  const [NF, setNF] = useState('');
  const [material, setMaterial] = useState('');
  const [cor, setCor] = useState('');

  const [canFilter, setCanFilter] = useState(false);

  const [processoSelected, setProcessoSelected] = useState();

  const [pecaModal, setPecaModal] = useState(false);
  const [grupoModal, setGrupoModal] = useState(false);

  const [pecas, setPecas] = useState([]);
  const [pecasFiltered, setPecasFiltered] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);

  const [amostra, setAmostra] = useState();
  const [amostraLote, setAmostraLote] = useState('default');
  const [lotesIncluidos, setLotesIncluidos] = useState([]);

  const [pecasSelecionadas, setPecasSelecionadas] = useState([]);
  const [pecasIncluidas, setPecasIncluidas] = useState([]);
  const [colabSelecionado, setColabSelecionado] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();

  const ChangeNF = useChange(setNF)
  const ChangeMat = useChange(setMaterial)
  const ChangeCorMat = useChange(setCor)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  };

  const quantityPerPage = 20;

  useEffect(() => {
    setCurrentData(processos.slice(
      ((currentPage - 1) * quantityPerPage), (currentPage * quantityPerPage)
    ));
  }, [currentPage, processos]);


  useEffect(() => {
    if (!load) {
      getProcessos();
      getColaboradores();
      setLoad(true);
    }
  }, [load]);

  async function IniciarProcesso() {
    if (colabSelecionado && colabSelecionado.length < 1) {
      toast.warning("É necessário escolher, ao menos, um colaborador.");
      return;
    }

    let c = window.confirm("Processo não iniciado! Deseja iniciar processo?");
    if (c) {

      const resp = CookiesService.getUserLogin();
      const p = processoSelected

      const iniciado = await api.post('MPprocesso/MPprocIniciarProcesso',
        {
          Responsavel: resp,
          NFEntrada: p.nota_fiscal,
          SerieNF: p.serie_nf,
          NomeCliFor: p.fornecedor,
          Material: p.material,
          CorMaterial: p.cor,
          QtdeEntrada: p.qtde_entrada,
          colaboradores: colabSelecionado.toString().replace(/^\,/, ''),
        })

      if (iniciado.data[0][""]) {
        setCanFilter(false);
        setProcessos([processoSelected]);
        closeGrupoModal();

        setPecaModal(true);

        const params = {
          NF: p.nota_fiscal,
          Serie: p.serie_nf,
          NomeCliFor: p.fornecedor,
          Material: p.material,
          CorMaterial: p.cor,
          Item: p.item
        };

        try {
          const pecasResponse = await api.get('MPprocesso/MPprocPeca', { params });
          setPecas(pecasResponse.data);

          const amostrasResponse = await api.get('MPprocesso/MPprocAmostra', { params });
          setAmostra(amostrasResponse.data);
        } catch (error) {
          toast.error("Houve um problema ao iniciar o processo.");
          console.error(`Erro na requisição - ${error}`);
        }
      } else {
        toast.error("Houve um problema ao iniciar o processo.");
      }
    }
  }

  async function getColaboradores() {
    let { data } = await api.get(`MPprocesso/ColabBuscar`);
    setColaboradores(data);
  }

  function SelectColab(id) {
    const sel = [...colabSelecionado];
    if (sel.indexOf(id) === -1) {
      sel.push(id);
    } else {
      sel.splice(sel.indexOf(id), 1);
    }
    setColabSelecionado(sel);
  }

  async function getProcessos() {
    let query = '';

    if (!!NF) {
      query += `NFEntrada=${NF}`;
    }
    if (!!material) {
      query += `Material=${material}`;
    }
    if (!!cor) {
      query += `CorMaterial=${cor}`;
    }

    const resp = CookiesService.getUserLogin();

    let { data } = await api.get(`MPprocesso/MPprocBuscar?Responsavel=${resp}&qtdeProcessos=1&${query}`);

    if (data.length === 0) {
      setCanFilter(true);
      let reqs = await api.get(`MPprocesso/MPprocBuscar?Responsavel=${resp}&${query}`);
      data = reqs.data;
      data = data.filter(v => {
        return v.ProcessoIniciado === 'NAO';
      });
    } else {
      setCanFilter(false);
    }

    data = [...data].map(v => {
      return {
        empresa: v.Empresa,
        pedido: v.Pedido,
        nota_fiscal: v.NFEntrada,
        serie_nf: v.SerieNF,
        item: v.Item,
        fornecedor: v.NomeCliFor,
        cod_forn: v.CodigoFornecedor,
        material: v.Material,
        desc_material: v.DescricaoMaterial,
        cor: v.CorMaterial,
        desc_cor: v.DescricaoCor,
        data_recebimento: v.DataRecebimento,
        responsavel_recebimento: v.NomeFuncionario,
        qtde_entrada: v.QtdeEntrada
      }
    })

    setProcessoSelected();
    setProcessos(data);
    setTotalRecords(data.length);
  }

  async function clickSelected(p) {
    setProcessoSelected(p);
    if (canFilter) {
      setGrupoModal(true);
    } else {
      setPecaModal(true);

      const params = {
        NF: p.nota_fiscal,
        Serie: p.serie_nf,
        NomeCliFor: p.fornecedor,
        Material: p.material,
        CorMaterial: p.cor,
        Item: p.item
      };
  
      try {
        const pecasResponse = await api.get('MPprocesso/MPprocPeca', { params });
        setPecas(pecasResponse.data);
  
        const amostrasResponse = await api.get('MPprocesso/MPprocAmostra', { params });
        setAmostra(amostrasResponse.data);
      } catch (error) {
        toast.error("Houve um problema ao iniciar o processo.");
        console.error(`Erro na requisição - ${error}`);
      }
    }
  }

  function closePecaModal() {
    setPecas([]);
    setPecasSelecionadas([]);
    setPecasIncluidas([]);
    setProcessoSelected();
    setPecaModal(false);
    setAmostraLote('default');
    setAmostra();
    setLotesIncluidos([]);
  }

  function closeGrupoModal() {
    setColabSelecionado([])
    setGrupoModal(false);
  }

  async function SelectPeca(peca) {
    const sel = [...pecasSelecionadas];
    if (pecasSelecionadas.indexOf(peca) === -1) {
      if (pecasSelecionadas.length < amostraLote[0].amostra) {
        sel.push(peca);
      } else {
        toast.warning("A quantidade máxima de peças foi selecionada.")
      }
    } else {
      sel.splice(pecasSelecionadas.indexOf(peca), 1);
    }
    setPecasSelecionadas(sel);
  }

  const IncluirLote = () => {
    if (pecasSelecionadas.length < amostraLote[0].amostra) {
      toast.warning("A quantidade de peças selecionadas é menor que a exigida.");
      return;
    } else if (amostra.length === 0) {
      toast.warning("Todos os Lotes ja foram incluidos.");
      return;
    } else {
      let c = window.confirm("Deseja incluir o lote?");
      if (c) {
        setPecasIncluidas([...pecasIncluidas, ...pecasSelecionadas]);
        setLotesIncluidos([...lotesIncluidos, ...amostraLote])
        setPecasSelecionadas([]);
        setAmostra(amostra.filter(amostra => amostra.PARTIDA != amostraLote[0].PARTIDA))
        setAmostraLote('default');
      }
    }
  }

  async function FinalizarProcesso() {
    if (amostra.length > 0) {
      toast.warning("Inclua todos os Lotes antes de continuar.");
      return;
    } else {
      let c = window.confirm("Deseja finalizar processo?");
      if (c) {
        const resp = CookiesService.getUserLogin();
        const p = processoSelected

        await api.post('MPprocesso/MPprocFinalizarProcesso', {
          Responsavel: resp,
          NFEntrada: p.nota_fiscal,
          SerieNF: p.serie_nf,
          NomeCliFor: p.fornecedor,
          Material: p.material,
          CorMaterial: p.cor,
          QtdeEntrada: p.qtde_entrada,
          pecas: pecasIncluidas.toString().replace(/^\,/, ''),
        })
          .then(res => {
            if (res.status === 200) {
              window.alert('Processo finalizado com sucesso.');
              closePecaModal();
              closeGrupoModal();
              window.location.reload();
            }
          }).catch(e => {
            toast.error('Houve um problema ao finalizar o processo.');
            console.error(`Erro na requisição - ${e}`);
          })
      }
    }
  }

  return (
    <div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        closeOnClick
        pauseOnFocusLoss={false}
      />

      <div className="table container mt-3">
        <h2>Processos</h2>
      </div>

      <div className="container mt-3">
        <input className="input-text" placeholder="Nota Fiscal" onChange={ChangeNF} />
        <input className="input-text" placeholder="Material" onChange={ChangeMat} />
        <input className="input-text" placeholder="Cor Material" onChange={ChangeCorMat} />
        <Button disabled={!canFilter} className="btn-send" onClick={getProcessos}>
          Filtrar
        </Button>
      </div>

      <CustomPagination
        totalRecords={totalRecords}
        quantityPerPage={quantityPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      ></CustomPagination>

      <div className="container mt-3 table-wrapper border border-dark rounded">
        <table className="table">
          <thead className="head-50">
            <tr className="t-header">
              <th scope="col" className="col">Empresa</th>
              <th scope="col" className="col">Pedido</th>
              <th scope="col" className="col">Nota Fiscal</th>
              <th scope="col" className="col">Serie NF</th>
              <th scope="col" className="col">Item</th>
              <th scope="col" className="col">Fornecedor</th>
              <th scope="col" className="col">Codigo Fornecedor</th>
              <th scope="col" className="col">Material</th>
              <th scope="col" className="col">Descrição Material</th>
              <th scope="col" className="col">Cor</th>
              <th scope="col" className="col">Descrição Cor</th>
              <th scope="col" className="col">Data Recebimento</th>
              <th scope="col" className="col">Resposável Recebimento</th>
            </tr>
          </thead>
          <tbody>
            {
              [...currentData].map((p) => {
                let s = processoSelected;
                let cName = 'pedido';
                if (s) {
                  if (p.nota_fiscal + p.cor + p.material + p.item === s.nota_fiscal + s.cor + s.material + s.item)
                    cName = 'pedido selected';
                }
                return (
                  <tr onClick={() => clickSelected(p)} key={p.nota_fiscal + p.cor + p.material + p.item}>
                    <th scope="col" className={`col ${cName}`}>{p.empresa}</th>
                    <th scope="col" className={`col ${cName}`}>{p.pedido}</th>
                    <th scope="col" className={`col ${cName}`}>{p.nota_fiscal}</th>
                    <th scope="col" className={`col ${cName}`}>{p.serie_nf}</th>
                    <th scope="col" className={`col ${cName}`}>{p.item}</th>
                    <th scope="col" className={`col ${cName}`}>{p.fornecedor}</th>
                    <th scope="col" className={`col ${cName}`}>{p.cod_forn}</th>
                    <th scope="col" className={`col ${cName}`}>{p.material}</th>
                    <th scope="col" className={`col ${cName}`}>{p.desc_material}</th>
                    <th scope="col" className={`col ${cName}`}>{p.cor}</th>
                    <th scope="col" className={`col ${cName}`}>{p.desc_cor}</th>
                    <th scope="col" className={`col ${cName}`}>{p.data_recebimento}</th>
                    <th scope="col" className={`col ${cName}`}>{p.responsavel_recebimento}</th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <ProcessoModal
        pAmount={amostra}
        pAmountLote={amostraLote}
        setAmostraLote={setAmostraLote}
        closeFn={closePecaModal}
        pSelecionadas={pecasSelecionadas}
        pIncluidas={pecasIncluidas}
        show={pecaModal}
        pecas={pecas}
        pecasFiltered={pecasFiltered}
        setPecasFiltered={setPecasFiltered}
        selectFn={SelectPeca}
        processarFn={FinalizarProcesso}
        incluirFn={IncluirLote}
        setPecasSelecionadas={setPecasSelecionadas}
        lotesIncluidos={lotesIncluidos}
      />

      <GrupoModal
        closeFn={closeGrupoModal}
        pSelecionadas={colabSelecionado}
        show={grupoModal}
        colaboradores={colaboradores}
        selectFn={SelectColab}
        iniciarProcessoFn={IniciarProcesso}
      />

    </div>
  );
};

export default Processos;