import React from "react";
import { Button, Modal, Table } from 'react-bootstrap';
import styles from '../../../styles/MP/RapportReprovado.module.scss';

const ModalHistorico = ({ showHistorico, handleCloseHistorico, historicoPedido, historicoData }) => {
    let menorPositivoPedido;
    let maiorPositivoPedido;
    let historicoDataFiltered = [];

    if (historicoPedido.length > 0) {
        menorPositivoPedido = historicoPedido[0].MENORLARGURAMEDIDA > historicoPedido[0].MEDIDAINFO 
        maiorPositivoPedido = historicoPedido[0].MAIORLARGURAMEDIDA > historicoPedido[0].MEDIDAINFO
    }
    if (historicoPedido.length > 0 && historicoData.length > 0)
        historicoDataFiltered = historicoData.filter(historico => historico.PEDIDO !== historicoPedido[0].PEDIDO)

    return (
        <Modal
            show={showHistorico}
            onHide={handleCloseHistorico}
            backdrop="static"
            keyboard={false}
            centered
            size="xl"
            >
            <Modal.Header>
                <Modal.Title>Histórico</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}>PEDIDO</th>
                            <th className={styles.blockTitle}>MEDIDA INFO</th>
                            <th className={styles.blockTitle}>MENOR RAPPORT</th>
                            <th className={styles.blockTitle}>% MENOR RAPPORT</th>
                            <th className={styles.blockTitle}>MAIOR RAPPORT</th>
                            <th className={styles.blockTitle}>% MAIOR RAPPORT</th>
                        </tr>
                    </thead>
                    <tbody>
                        { historicoPedido.length > 0 && 
                                <tr style={{ fontWeight: 'bold' }}>
                                <td className={styles.tableLines}>{historicoPedido[0].PEDIDO}</td>
                                <td className={styles.tableLines}>{historicoPedido[0].MEDIDAINFO}</td>
                                <td className={styles.tableLines}>{historicoPedido[0].MENORLARGURAMEDIDA}</td>
                                <td className={styles.tableLines}>{menorPositivoPedido?'+':null}{(((historicoPedido[0].MENORLARGURAMEDIDA/historicoPedido[0].MEDIDAINFO)-1)*100).toFixed(2)}%</td>
                                <td className={styles.tableLines}>{historicoPedido[0].MAIORLARGURAMEDIDA}</td>
                                <td className={styles.tableLines}>{maiorPositivoPedido?'+':null}{(((historicoPedido[0].MAIORLARGURAMEDIDA/historicoPedido[0].MEDIDAINFO)-1)*100).toFixed(2)}%</td>
                            </tr>
                        }

                        { historicoDataFiltered.length > 0 && historicoDataFiltered.map((item,index) => {
                        const menorPositivo = item.MENORLARGURAMEDIDA > item.MEDIDAINFO 
                        const maiorPositivo = item.MAIORLARGURAMEDIDA > item.MEDIDAINFO
                        return (
                            <tr key={index}>
                                <td className={styles.tableLines}>{item.PEDIDO}</td>
                                <td className={styles.tableLines}>{item.MEDIDAINFO}</td>
                                <td className={styles.tableLines}>{item.MENORLARGURAMEDIDA}</td>
                                <td className={styles.tableLines}>{menorPositivo?'+':null}{(((item.MENORLARGURAMEDIDA/item.MEDIDAINFO)-1)*100).toFixed(2)}%</td>
                                <td className={styles.tableLines}>{item.MAIORLARGURAMEDIDA}</td>
                                <td className={styles.tableLines}>{maiorPositivo?'+':null}{(((item.MAIORLARGURAMEDIDA/item.MEDIDAINFO)-1)*100).toFixed(2)}%</td>
                            </tr>
                        )})}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseHistorico}
                >
                    Voltar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalHistorico;
