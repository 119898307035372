import React, { useState } from "react";
import { Form, Table } from 'react-bootstrap';
import { api }  from "../../../services/api";
import { toast } from "react-toastify";
import AnalisePeca from './AnalisePeca';
import styles from '../../../styles/MP/LaboratorioCemPorcentoRetorno.module.scss';

const AnaliseTestes = ({ pecas
                       , setPecas
                       , pecaSelecionada
                       , setPecaSelecionada
                       , pecasIncluidas
                       , setPecasIncluidas
}) => {

  const [testes, setTestes] = useState([]);

  const [showAnalisePeca, setShowAnalisePeca] = useState(false);

  const handleTestes = async (peca) => {
    await api.get('MP/LaboratorioCemPorcentoRetorno/get-testes', { params: peca })
    .then(res => {
      setTestes(res.data)
    }).catch(e => {
      toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
      console.error(`Erro na requisição - ${e}`);
    })
  }
                
  const handleCheckPeca = (e, peca) => {
    const isChecked = e.target.checked;
    const pecaChecked = isChecked ? peca : null   
    setPecaSelecionada(pecaChecked)
  }

  const handleShowAnalisePeca = (peca) => {
    setShowAnalisePeca(true);
    handleTestes(peca);
  }

  const handleCloseAnalisePeca = () => {
    setPecaSelecionada({});
    setShowAnalisePeca(false);
  }

    return (
      <div>
        <article className={styles.incTableInsertWrapper}>
            <div className={styles.tablesRow}>
              
              <div className={styles.tablesColumn}>
                <Form>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className={styles.blockTitle}></th>
                        <th className={styles.blockTitle}>PEÇAS</th>
                      </tr>
                    </thead>
                    <tbody>
                      { pecas.length > 0 && pecas.map((item, index) => {
                        return (
                          <tr className={styles.tableLines} key={index}>
                              <td className={styles.tableLines}>
                              <Form.Check 
                                  type='radio'
                                  name='pec'
                                  onClick={() => handleShowAnalisePeca(item)}
                                  onChange={(e) => handleCheckPeca(e, item)}
                                  checked={pecaSelecionada === item}
                              />
                              </td>
                              <td className={styles.tableLines}>{item.peca}</td>
                          </tr>
                          )
                        }) 
                      }
                    </tbody>
                  </Table>
                </Form>
              </div>
            </div>
          </article>

          <AnalisePeca 
            pecaSelecionada={pecaSelecionada}
            showAnalisePeca={showAnalisePeca}
            handleCloseAnalisePeca={handleCloseAnalisePeca}
            testes={testes}
            setTestes={setTestes}
            pecasIncluidas={pecasIncluidas}
            setPecasIncluidas={setPecasIncluidas}
            pecas={pecas}
            setPecas={setPecas}
          />
        </div>
    )
}

export default AnaliseTestes;
