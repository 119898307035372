import React, { useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";

function ProcessoModal (props) {

  const handleChangeAmostra = (lote) => {
    props.setAmostraLote(props.pAmount.filter(amostra => amostra.PARTIDA == lote))
  }

  useEffect(() => {                 
    props.setPecasFiltered(props.pecas.filter(peca => peca.Lote == props.pAmountLote[0].PARTIDA))
    props.setPecasSelecionadas([])
  },[props.pAmountLote]);

    return (
      <Modal show={props.show}>
        <Modal.Header>
          <div className="d-block w-100 container">
            <div className="d-flex-b">
              <h4>Escolha {props.pAmountLote[0].amostra} peça(s)</h4>
              <h4 className="clickable" onClick={props.closeFn}>X</h4>
            </div>
            <h5>Quantidade escolhida: {props.pSelecionadas.length}</h5>
            <div>
              <Form.Select
                onChange={(e) => handleChangeAmostra(e.target.value)}
                value={props.pAmountLote[0].PARTIDA || props.pAmountLote}
              >
                <option 
                  disabled
                  value='default'
                >
                  Selecione o Lote
                </option>
                { props.pAmount && 
                  [...props?.pAmount].map((item, index) => {
                    return (
                      <option 
                        key={index}
                        value={item.PARTIDA}
                      >
                        {item.PARTIDA}
                      </option>
                    )
                  })
                }
              </Form.Select>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrapper border border-dark rounded w-100">
            <table className="table w-100">
              <thead className="head-50">
                <tr className="t-header">
                  <th scope="col" className="col">Peça</th>
                  <th scope="col" className="col">Lote</th>
                  <th scope="col" className="col">Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.pecasFiltered &&
                [...props?.pecasFiltered].map((p) => { 
                  let cName = "";
                  if(props.pSelecionadas.indexOf(p.Peca) !== -1){
                    cName = "selected";
                  }
                  return(
                    <tr onClick={() => props.selectFn(p.Peca)}key={p.Peca} className={"clickable peca"}>
                        <th scope="col" className={`col ${cName}`}>{p.Peca}</th>
                        <th scope="col" className={`col ${cName}`}>{p.Lote}</th>
                        <th scope="col" className={`col ${cName}`}>{p.Qtde}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            {
              props.pAmount ? <h5>Faltam {props.pAmount.length} lote(s)</h5> : null
            }

            <h5 style={{marginTop: '10px'}}>Lotes Incluidos:</h5>
            <div style={{display: 'flex', flexDirection:'column'}}>
              {
                props.lotesIncluidos && 
                [...props?.lotesIncluidos].map((lote, index) => {
                  return (
                    <span key={index}>{lote.PARTIDA}</span>
                  )
                })
              }
            </div> 
          </div>
        </Modal.Body>
        <Modal.Footer className="footer">
          <Button 
            variant="success"
            onClick={props.incluirFn}
          >
            Incluir Lote
          </Button>
          <Button onClick={props.processarFn}>
            Processar
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ProcessoModal;