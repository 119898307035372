import React, { useEffect, useState } from "react";
import { Button, Table, Form, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Select from 'react-select';
import styles from "../../../../styles/AdmImpressaoEtiquetas.module.scss"
import ImprimirEtiquetasPA from "../EtiquetasPA/ImprimirEtiquetaPA";
import { api }  from "../../../../services/api";
import Carregando from "../../../Carregando";

function ImpressaoEtiquetas() {
  const [formObj, setFormObj] = useState({});
  const [araraList, setAraraList] = useState({});
  const [estoqueList, setEstoqueList] = useState({});
  const [admEtiqueta, setAdmEtiqueta] = useState([]);
  const [listaIdCadastroParaImprimir, setListaIdCadastroParaImprimir] = useState([]);
  const [carregando, setCarregando] = useState(false);

  async function handleSearch() {
    const {arara, estoque, material, corMaterial} = formObj;
    const params = { idArara: arara?.value, idEstoque: estoque?.value, material, corMaterial };
    if (estoque && (!arara && !material)) {
      setCarregando(false);
      return toast.warn('Você deve selecionar uma arara ou buscar por um produto específico');
    }
    if (!estoque && !material) {
      setCarregando(false);
      return toast.warn('Você deve buscar por um(a) produto ou arara específico(a)');
    }

    await api.get('PAPecaPiloto/GetAmostraList', { params })
      .then(res => {
        if (res.data.length < 1) return toast.warning('Nenhum registro encontrato');
        setAdmEtiqueta(res.data);
        setListaIdCadastroParaImprimir([]);
      })
      .catch(e => {
        toast.warning('Ocorreu um erro durante a busca, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
        console.error(e);
      });
    setCarregando(false);
  }

  function handleSelect(id) {
    const index = listaIdCadastroParaImprimir.findIndex(obj => obj.idCadastro === id);
    index < 0 ? listaIdCadastroParaImprimir.push({ idCadastro: id }) : listaIdCadastroParaImprimir.splice(index, 1);
    setListaIdCadastroParaImprimir([...listaIdCadastroParaImprimir]);
  }

  useEffect(() => {
    (async () => {
      try {
        await api.get('PAPecaPiloto/CatalogoAraras', { params: { impressao: true } })
          .then(res => {
            setAraraList(res.data);
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });

        await api.get('PAPecaPiloto/GetDescricaoLocal')
          .then(res => {
            setEstoqueList(res.data);
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });
      } catch (e) {
        return toast.error('Ocorreu um erro no carregamento da interface, por favor recarregue a página.');
      }
    })();
  }, []);

  useEffect(() => {
    if (!formObj.estoque && formObj.arara) setFormObj({...formObj, arara: null});
  }, [formObj])

  return (
    <div>
      <article>
        <Form className={styles.form}>
          <Form.Group className={styles.formGroup}>
            <Form.Label>
              Estoque
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={estoqueList}
              value={formObj.estoque || null}
              onChange={opt => setFormObj({ ...formObj, estoque: opt, arara: null })}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label>
              Arara
            </Form.Label>
            <Select
              isClearable={true}
              backspaceRemovesValue={true}
              closeMenuOnSelect={true}
              options={araraList}
              value={formObj.arara || null}
              onChange={opt => setFormObj({ ...formObj, arara: opt })}
              filterOption={option => option.data.idLocal === formObj.estoque?.value}
              isDisabled={!formObj.estoque}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label>Produto</Form.Label>
            <Form.Control
              required
              type={'text'}
              placeholder={'Produto'}
              title={'Este campo é obrigatório'}
              value={formObj.material || ''}
              onChange={e => setFormObj({...formObj, material: e.target.value})}
            />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label>Cor Produto</Form.Label>
            <Form.Control
              required
              type={'text'}
              placeholder={'Cor Produto'}
              title={'Este campo é obrigatório'}
              value={formObj.corMaterial || ''}
              onChange={e => setFormObj({...formObj, corMaterial: e.target.value})}
            />
          </Form.Group>
          <Button
            className={styles.formGroup}
            variant="primary"
            type="button"
            onClick={() => { setCarregando(true); handleSearch(); }}
          >
            Buscar
          </Button>
          <div style={{marginLeft: '100px'}}>
            <div>
              <h5>Impressora</h5>
              <div>172.16.180.255\Generic</div>
            </div>
            <div>
              <ImprimirEtiquetasPA etiquetas={listaIdCadastroParaImprimir} />
            </div>
          </div>
        </Form>
      </article>

      <article className={styles.articleGray}>
        <div className="p-2 pb-0">
          <h5>Etiquetas a serem impressas</h5>
        </div>
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}>PRODUTO</th>
              <th className={styles.blockTitle}>COR</th>
              <th className={styles.blockTitle}>COD. FORN.</th>
              <th className={styles.blockTitle}>ESTOQUE</th>
              <th className={styles.blockTitle}>DATA CADASTRO</th>
              <th className={styles.blockTitle}>ID USUARIO</th>
            </tr>
          </thead>
          <tbody>
            {admEtiqueta.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={styles.tableLines}>
                    <Form>
                      <Form.Check key={index}
                        type='checkbox'
                        onChange={() => handleSelect(item.idCadastro)}
                        checked={listaIdCadastroParaImprimir.some(pos => pos.idCadastro === item.idCadastro)}
                      />
                    </Form>
                  </td>
                  <td className={styles.tableLines}>{item.material}</td>
                  <td className={styles.tableLines}>{item.corMaterial}</td>
                  <td className={styles.tableLines}>{item.codClifor}</td>
                  <td className={styles.tableLines}>{item.estoque}</td>
                  <td className={styles.tableLines}>{item.dataCadastro}</td>
                  <td className={styles.tableLines}>{item.idUsuarioCadastro}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </article>

      <Modal show={carregando} centered={true}>
        <Carregando />
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ImpressaoEtiquetas;
