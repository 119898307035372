import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext';

const DadosAccordion = () => {
    const { materialSelecionado, linhaSelecionada, showModalRiscos, showModalMateriais, qtdeLocalizacao, tipoCorteSelecionado, plantaCorteSelecionado } = useLancamentoCad();

    return (
        <section>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Dados da Linha Selecionada</Accordion.Header>
                    <Accordion.Body className={styles.infoBlockDisplay}>
                        { !showModalRiscos &&
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockColumm}>
                                    <span>Ordem Produção</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.OrdemProducao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Ordem Serviço</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.OrdemServico}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Produto</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.Produto}</span>
                                </div>
                            </div>
                        }
                        { showModalMateriais &&                    
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockColumm}>
                                    <span>Localizações</span>
                                    <span className={styles.fontBlue}>{qtdeLocalizacao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Tipo Corte</span>
                                    <span className={styles.fontBlue}>{tipoCorteSelecionado.desc_producao_tipo_corte}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Planta Corte</span>
                                    <span className={styles.fontBlue}>{plantaCorteSelecionado.DescricaoPlantaCorte}</span>
                                </div>
                            </div> 
                        }
                        { showModalRiscos &&                    
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlockColumm}>
                                    <span>Ordem Produção</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.OrdemProducao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Ordem Serviço</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.OrdemServico}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Produto</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.Produto}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Localizações</span>
                                    <span className={styles.fontBlue}>{qtdeLocalizacao}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Tipo Corte</span>
                                    <span className={styles.fontBlue}>{tipoCorteSelecionado.desc_producao_tipo_corte}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Planta Corte</span>
                                    <span className={styles.fontBlue}>{plantaCorteSelecionado.DescricaoPlantaCorte}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Material</span>
                                    <span className={styles.fontBlue}>{materialSelecionado.material}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Descrição</span>
                                    <span className={styles.fontBlue}>{materialSelecionado.desc_material}</span>
                                </div>
                                <div className={styles.infoBlockColumm}>
                                    <span>Cor</span>
                                    <span className={styles.fontBlue}>{materialSelecionado.cor_material}</span>
                                </div>
                            </div> 
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    );
};
export default DadosAccordion;