import React from "react";
import { Form } from 'react-bootstrap';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext'; 

const RadioRisco = () => {

    const { numRisco, riscoSelecionado, riscosInseridos, handleCheckRisco } = useLancamentoCad();

    const radioRiscoButtons = []
    for (let i = 1; i <= numRisco; i++) {
        radioRiscoButtons.push(
            <Form.Check
                key={i}
                type="checkbox"
                id={`option-${i}`}
                label={i}
                name="checkRisco"
                inline
                value={i}
                checked={riscoSelecionado.some(risco => risco === i)}
                onChange={e => handleCheckRisco(e, i)}
                disabled={riscosInseridos.some(risco => parseInt(risco.risco) === i)}
            />
        )
    }

    return (
        <>{ radioRiscoButtons.map((radio, index) => <div key={index}>{radio}</div>) }</>
    )
}

export default RadioRisco;
