import React from 'react';
import Tabela from '../../../Misc/Tabela/Tabela';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext'; 

const MainTable = () => {

    const { loading, mainData, checklistColumns, handleShowModalLocalTipoPlanta } = useLancamentoCad();

    return (
        <section style={{ margin: '20px 20px' }}>
            <Tabela
                columns={checklistColumns}
                data={mainData}
                pending={loading}
                rowClickedFn={handleShowModalLocalTipoPlanta}
            />
        </section>
    );
};

export default MainTable;
