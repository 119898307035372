import { Alert } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Form, Button, FormControl, FormGroup } from "react-bootstrap";

import Pedido from "../components/RecebimentoMP/Pedido";

import useChange from "../hooks/useChange";
import { api }  from "../services/api";

import "../styles/RecebimentoMp.css";
const RecebimentoMp = () => {
  const [load, setLoad] = useState(false);
  const [responsaveis, setResponsaveis] = useState([]);

  const [process, setProcess] = useState("");
  const [pedidos, setPedidos] = useState([]);
  const [pedidosPs, setPedidosPs] = useState([]);

  const [pedidosSelecionados, setPedidosSelecionados] = useState([]);

  const [nf, setNF] = useState("");
  const [serie, setSerie] = useState("");
  const [id_user_recebimento, setIdUserRecebimento] = useState(0);

  const setNumeroMp = useChange(setProcess, [
    (value, args = []) => value.toUpperCase(),
  ]);

  const setNFNumber = useChange(setNF, [
    (value, args = []) => value.replace(/[^0-9]*/g, ""),
  ]);

  const setNFSerie = useChange(setSerie, [
    (value, args = []) => value.replace(/[^0-9]*/g, ""),
  ]);

  const setResponsavelSelect = useChange(setIdUserRecebimento);

  useEffect(() => {
    if (!load) {
      LoadDataResources();
      setLoad(true);
    }
  });

  async function GetPedido() {
    try {
      let result = await api.get(
        `MPrecebimento/MPrecebimentoBuscar?Pedido=${process}`
      );
      let processed = await api.get(
        `MPrecebimento/MPrecebimentoBuscarProcessados?Pedido=${process}`
      );
      let oddResult = [];

      processed = processed.data;
      result = result.data;

      if (processed.length > 0) {
        result = result.filter((v) => {
          for (let i = 0; i < processed.length; i++) {
            let condition = v["CLIFOR"].trim() === processed[i]["CodCliFor"];
            condition =
              condition &&
              v["COR_MATERIAL"].trim() === processed[i]["CorMaterial"];
            condition =
              condition && v["MATERIAL"].trim() === processed[i]["Material"];
            condition =
              condition && v["PEDIDO"].trim() === processed[i]["Pedido"];

            if (condition) {
              oddResult.push(v);
              return false;
            }
          }
          return true;
        });
      }

      setPedidos(result);
      setPedidosPs(oddResult);
    } catch (e) {
      alert(`Error: Não foi possivel carregar. CODIGO ${e.code}`);
    }
  }

  async function LoadDataResources() {
    const loadErrors = [];
    const proms = [];

    proms.push(
      api.get("MPrecebimento/MPrecebimentoResponsavel").then((result) => {
        if (result.status === 200) {
          const responsaveis = [];
          result.data.forEach((val) => {
            responsaveis.push(val["LIST"]);
          });
          setResponsaveis(responsaveis);
        } else {
          loadErrors.push("RESPONSÁVEIS");
        }
      })
    );

    Promise.all(proms).finally(() => {
      if (loadErrors.length > 0) {
        alert(`Error: Não foi possivel carregar:\n${loadErrors}`);
      }
    });
  }

  function Send() {
    let pedidosSend = pedidos
      .filter((v, i) => {
        return pedidosSelecionados[i];
      })
      .map((val) => {
        return {
          Pedido: val["PEDIDO"],
          Empresa: val["MARCA_EMPRESA"],
          TipoCompra: val["TIPO_COMPRA"],
          Filial: val["FILIAL_A_ENTREGAR"],
          Emissao: new Date(val["EMISSAO"]),
          CodCliFor: val["CLIFOR"],
          Fornecedor: val["FORNECEDOR"],
          Material: val["MATERIAL"],
          DescricaoMaterial: val["DESC_MATERIAL"],
          CorMaterial: val["COR_MATERIAL"],
          DescricaoCor: val["DESC_COR_MATERIAL"],
          QtdeOriginal: val["QTDE_ORIGINAL"],
          ValorOriginal: val["VALOR_ORIGINAL"],
          QtdeEntregar: val["QTDE_ENTREGAR"],
          ValorEntregar: val["VALOR_ENTREGAR"],
          QtdeEntregue: val["QTDE_ENTREGUE"],
          ValorEntregue: val["VALOR_ENTREGUE"],
          RequeridoPor: val["REQUERIDO_POR"],
          Colecao: val["COLECAO"],
          IDMarca: val["IDMARCA"],
          Grupo: val["GRUPO"],
          SubGrupo: val["SUBGRUPO"],
        };
      });

    if (pedidosSend.length === 0) {
      alert("Nenhum pedido selecionado.");
      return;
    }

    if (!nf) {
      alert("Nota fiscal não preenchida.");
      return;
    }

    if (!serie) {
      alert("Serie não preenchida.");
      return;
    }

    if (!id_user_recebimento || id_user_recebimento === 0) {
      alert("Responsável não preenchido.");
      return;
    }

    const data = {
      pedidos: pedidosSend,
      nf,
      serie,
      id_user_recebimento: id_user_recebimento.split("-")[0].trim(),
      nome_user_recebimento: id_user_recebimento.split("-")[1].trim(),
      Volumes: 0,
      TransfEstoque: 0,
    };

    api.post("MPrecebimento/MPrecebimentoProcessar", data).then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        alert("Erro processando pedidos!");
      } else {
        alert("Pedido processado!");

        GetPedido();
        setPedidosSelecionados([]);
      }
    });
  }

  function SetSelected(index) {
    const pedidosS = [...pedidosSelecionados];
    if (pedidosS[index]) {
      pedidosS[index] = !pedidosS[index];
    } else {
      pedidosS[index] = true;
    }
    setPedidosSelecionados(pedidosS);
  }

  return (
    <div>
      <div className="table container mt-3">
        <h2>Recebimento MP</h2>
      </div>
      <div className="container mt-3">
        <input
          className="input-text"
          value={process}
          id="numero_mp"
          onChange={setNumeroMp}
          placeholder="Número"
        />
        <Button className="btn-send" onClick={GetPedido}>
          Enviar
        </Button>
      </div>
      <div className="container mt-3 table-wrapper border border-dark rounded">
        <table className="table">
          <thead className="head-50">
            <tr className="t-header">
              <th scope="col">#</th>
              <th scope="col">PEDIDO</th>
              <th scope="col">MARCA_EMPRESA</th>
              <th scope="col">TIPO_COMPRA</th>
              <th scope="col">FILIAL_A_ENTREGAR</th>
              <th scope="col">EMISSAO</th>
              <th scope="col">CLIFOR</th>
              <th scope="col">FORNECEDOR</th>
              <th scope="col">MATERIAL</th>
              <th scope="col">DESC_MATERIAL</th>
              <th scope="col">COR_MATERIAL</th>
              <th scope="col">DESC_COR_MATERIAL</th>
              <th scope="col">QTDE_ORIGINAL</th>
              <th scope="col">VALOR_ORIGINAL</th>
              <th scope="col">QTDE_ENTREGAR</th>
              <th scope="col">VALOR_ENTREGAR</th>
              <th scope="col">QTDE_ENTREGUE</th>
              <th scope="col">VALOR_ENTREGUE</th>
              <th scope="col">REQUERIDO_POR</th>
              <th scope="col">COLECAO</th>
              <th scope="col">IDMARCA</th>
              <th scope="col">GRUPO</th>
              <th scope="col">SUBGRUPO</th>
            </tr>
          </thead>
          <tbody>
            {pedidosPs.map((val, ind) => {
              return (
                <Pedido
                  key={val["PEDIDO"] + ind + "l"}
                  index={ind}
                  obj={val}
                  value={pedidosSelecionados[ind] || false}
                  setter={SetSelected}
                  disabled={true}
                />
              );
            })}
            {pedidos.map((val, ind) => {
              return (
                <Pedido
                  key={val["PEDIDO"] + ind}
                  index={ind}
                  obj={val}
                  value={pedidosSelecionados[ind] || false}
                  setter={SetSelected}
                />
              );
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
      <Form className="container mt-3 d-flex flex-bt">
        <FormGroup className="d-flex flex-bt">
          <FormControl
            value={nf}
            onChange={setNFNumber}
            className="w-10 "
            placeholder="Nota Fiscal"
          />
          <FormControl
            value={serie}
            onChange={setNFSerie}
            className="w-10"
            placeholder="Série"
          />
        </FormGroup>
        <Form.Select
          className="input-text w-75"
          value={id_user_recebimento}
          id="numero_mp"
          onChange={setResponsavelSelect}
          placeholder="Número"
        >
          <option key={0} value={0} defaultValue>
            Selecione um responsável
          </option>
          {responsaveis.map((val, ind) => {
            return (
              <option key={val.split("-")[0].trim()} value={val}>
                {val}
              </option>
            );
          })}
        </Form.Select>
        <Button className="btn-send" onClick={Send}>
          Enviar
        </Button>
      </Form>
    </div>
  );
};

export default RecebimentoMp;
