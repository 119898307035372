import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../services/api';
import { useMisc } from './MiscContext';
import { toast } from 'react-toastify';
import CookiesService from '../services/cookies';
import { toDatetime, toUTCDDMMYYYY } from "../utils/dateUtils";

const MPRapportEnvioCadContext = createContext();
export const useMPRapportEnvioCad =  () => useContext(MPRapportEnvioCadContext);

export const MPRapportEnviaCadProvider = ({ children }) =>{
    const {setShowLoading} = useMisc();
    
    const [mainData, setMainData] = useState([]);
    const [historicoData, setHistoricoData] = useState([]);
    const [historicoPedido, setHistoricoPedido] = useState([]);
    const [produtosConsumo, setProdutosConsumo] = useState([]);
    const [pedidoSelecionado, setPedidoSelecionado] = useState({});
    const [showHistorico, setShowHistorico] = useState(false);
    const [showReprovado, setShowReprovado] = useState(false);
    const [showCad, setShowCad] = useState(false);
    const [showConfirmacaoGrade, setShowConfirmacaoGrade] = useState(false);
    const [file, setFile] = useState(null);
    const [obsCad, setObsCad] = useState(''); // Novo estado para a observação
    

    const [filterObj, setFilterObj] = useState({
        nf_entrada: '',
        material: '',
        cor_material: ''
    });

    const handleSearch = async () => {
        setShowLoading(true);
        const params = { ...filterObj }
        await api.get('MPRapportEnvioCad/envio-cad-fetch-grid', { params })
          .then(res => {            
            setMainData(res.data);
            setShowLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    }


    const handleShowCad = (item) => {
        //vai abrir o modal
    }

    const handleShowHistorico = (item) => {
      setProdutosConsumo([]);
        Promise.all([handleGetHistorico(item), 
            handleGetProdutosConsumo(item)
        ])        
        setPedidoSelecionado(item);            
        setObsCad(item.ObsCad);     
        setShowHistorico(true);   
    }

    const handleCloseHistorico = () => {
        setPedidoSelecionado({});
        setFile(null);
        setObsCad(null);
        setHistoricoData([]);
        setHistoricoPedido({});
        setProdutosConsumo([]);
        setShowHistorico(false);
    }

    const handleGetHistorico = async (pedido) => {
        const params = { ...pedido }
        setShowLoading(true);
        await api.get('MPRapportEnvioCad/envio-cad-fetch-header', { params })
          .then(res => {            
            setHistoricoPedido(res.data);   
                                
            setShowLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    }

    const handleGetProdutosConsumo = async (pedido) => {
        const params = { ...pedido }
        setShowLoading(true);
        await api.get('MPRapportEnvioCad/envio-cad-fetch-consumo-produtos', { params })
          .then(res => {                
            setProdutosConsumo(res.data);            
            setShowLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    }

      const handleNovoConsumoChange = (index, value) => {
            const updatedProdutosConsumo = [...produtosConsumo];
            updatedProdutosConsumo[index].novoConsumo = value;
            setProdutosConsumo(updatedProdutosConsumo);
        };
    
      const calcularPercentualNovoConsumo = (index) => {
        const consumoAtual = produtosConsumo[index].CONSUMO;
        const novoConsumoValor = produtosConsumo[index].novoConsumo || 0;
        return ((novoConsumoValor - consumoAtual) / consumoAtual) * 100;
      };
    
      const handleStatusChange = (index, status) => {
        const updatedProdutosConsumo = [...produtosConsumo];
        updatedProdutosConsumo[index].IdStatusFinal = status;
        setProdutosConsumo(updatedProdutosConsumo);
      };

      const handleCloseConfirmacaoGrade = () =>{
        setShowConfirmacaoGrade(false);
      }

      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {          
            setFile(selectedFile);
        }
    };

      const handleProcessConsumo = async () => {

        const {IdAnaliseRapport } = pedidoSelecionado;

        setShowLoading(true);
        setShowConfirmacaoGrade(false);
        try {
          const formData = new FormData();
          formData.append('listProdutos', JSON.stringify(produtosConsumo));
          formData.append('IdAnaliseRapport', IdAnaliseRapport);
          formData.append('obsCad', obsCad);
          formData.append('usuario_responsavel', CookiesService.getUserLogin());
          //if (file) {
              formData.append('file', file);
          //}

          //{listProdutos:produtosConsumo,  IdAnaliseRapport, usuario_responsavel: CookiesService.getUserLogin(), }
          await api.post('/MPRapportEnvioCad/envio-cad-processar-consumo', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
          },
          });
          toast.success('Processamento completado com sucesso!');  
          setShowHistorico(false);   
          handleSearch();
        } catch (e) {
          setShowConfirmacaoGrade(true);
          toast.error('Houve um problema durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.');
        }
        setShowLoading(false);

      }

      const validateFields = () => {
        for (let produto of produtosConsumo) {
            if (!produto.novoConsumo || isNaN(produto.novoConsumo)) {
                toast.error('Preencha todos os novos consumos.');
                return false;
            }
            if (!produto.IdStatusFinal) {
                toast.error('Marque o status para todos os produtos.');
                return false;
            }
        }
        return true;
    };

    const handleSave = () => {
      if (validateFields()) {
          setShowConfirmacaoGrade(true);
      }
    };

    const handleShowPDF = async (fileName) => {      
        try {            
          const params = { nome: fileName  }
            const res = await api.get('/MPRapportEnvioCad/ler-arquivo-cad-envio/',{params} );
            if (res.data.statusCode && res.data.statusCode === 404) return toast.warning(res.data.message);
            window.open(res.data[0], '_blank');
        } catch (e) {
            toast.error('Ocorreu um erro ao baixar o arquivo.');
        }
    }

    return (
        <MPRapportEnvioCadContext.Provider
          value={{ setShowLoading, mainData, setMainData,filterObj, setFilterObj, handleSearch, handleShowCad, handleShowHistorico, handleCloseHistorico,showHistorico, historicoData, historicoPedido, pedidoSelecionado, produtosConsumo, handleNovoConsumoChange, calcularPercentualNovoConsumo, handleStatusChange, file, setFile, obsCad, setObsCad
          ,handleCloseConfirmacaoGrade
          ,handleProcessConsumo
          ,showConfirmacaoGrade
          ,setShowConfirmacaoGrade
          ,handleFileChange
          ,validateFields
          ,handleSave
          ,handleShowPDF
          }}
        >
          {children}
        </MPRapportEnvioCadContext.Provider>
      )

}
