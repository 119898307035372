import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/AcompanhamentoEnfesto.module.scss';
import { useAcompanhamentoEnfesto } from '../../../../contexts/Corte/ControleEnfesto/AcompanhamentoEnfestoContext'; 

const DadosAccordion = () => {

    const { linhaSelecionada } = useAcompanhamentoEnfesto();

    return (
        <section>
            <Accordion defaultActiveKey={['0']}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Dados da Linha Selecionada</Accordion.Header>
                    <Accordion.Body className={styles.infoBlockDisplay}>
                        <div className={styles.infoBlockColumm}>
                            <span>Ordem Serviço</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.OrdemServico}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Ordem Produção</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.OrdemProducao}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Material</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.Material}</span>
                        </div>
                        <div className={styles.infoBlockColumm}>
                            <span>Grade</span>
                            <span className={styles.fontBlue}>{linhaSelecionada.Grade}</span>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    );
};

export default DadosAccordion;
