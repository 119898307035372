import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { usePriorizacaoOps } from '../../../../contexts/Corte/ControleEnfesto/PriorizacaoOpsContext';
import styles from '../../../../styles/Corte/ControleEnfesto/PriorizacaoOps.module.scss';

const ModalConfirmacao = () => {

    const { handleProcessar, linhasCorteManual, dataPriorizacao, showConfirmacao, handleCloseConfirmacao, linhasSelecionadas, equipeCorteSelecionado, modoCorteSelecionado } = usePriorizacaoOps();

    return (
        <>
            <Modal
                show={showConfirmacao}
                onHide={handleCloseConfirmacao}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Tem certeza?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <span>
                            • <strong>{linhasSelecionadas.length}</strong> {linhasSelecionadas.length === 1 ? 'OP será priorizada': 'OPs serão priorizadas'} para a equipe <strong>{equipeCorteSelecionado && equipeCorteSelecionado.Equipe}</strong>.
                        </span>
                    </div>
                    { 
                        linhasCorteManual.length > 0 &&
                        <div>
                            <span>
                                • <strong>{linhasCorteManual.length}</strong> {linhasCorteManual.length === 1 ? 'OP receberá' : 'OPs receberão'} <strong>{modoCorteSelecionado && modoCorteSelecionado.DescricaoModoCorte}</strong> como modo de corte.
                            </span>
                        </div>
                    }
                    <div>
                        <span>
                            • A data será <strong>{dataPriorizacao.split("-").reverse().join("-")}</strong>
                        </span>
                    </div>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseConfirmacao}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtnEquipe}
                        onClick={handleProcessar}
                    >
                        Enviar Produção
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalConfirmacao;
