import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import styles from "../../styles/Pagination.module.scss";

const CustomPagination = ({
  totalRecords,
  quantityPerPage = 10,
  currentPage = 1,
  onPageChange,
  maxPageDisplay = 5,
  showFirstLastPages = true,
  showPageSizeOptions = false,
  pageSizeOptions = [10, 20, 50],
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(currentPage);
  const [pageSize, setPageSize] = useState(quantityPerPage);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageSize));
    setCurrentPageNumber(currentPage);
  }, [totalRecords, pageSize, currentPage]);

  const handlePageChange = (page) => {
    if (page !== currentPageNumber) {
      setCurrentPageNumber(page);
      onPageChange && onPageChange(page);
    }
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setTotalPages(Math.ceil(totalRecords / newPageSize));
    setCurrentPageNumber(1);
    onPageChange && onPageChange(1, newPageSize);
  };

  const renderPageNumbers = () => {
    const pageItems = [];
    const maxPage = Math.min(totalPages, maxPageDisplay);
    let startPage = 1;
    if (currentPageNumber > Math.floor(maxPage / 2)) {
      startPage = Math.min(currentPageNumber - Math.floor(maxPage / 2), totalPages - maxPage + 1);
    }
    for (let page = startPage; page < startPage + maxPage; page++) {
      pageItems.push(
        <Pagination.Item
          key={page}
          active={page === currentPageNumber}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const renderPageSizeOptions = () => {
    return pageSizeOptions.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };

  return (
    <Pagination className={styles.style}>
      {showFirstLastPages && (
        <>
          <Pagination.First disabled={currentPageNumber === 1} onClick={() => handlePageChange(1)} />
          <Pagination.Prev disabled={currentPageNumber === 1} onClick={() => handlePageChange(currentPageNumber - 1)} />
        </>
      )}
      {renderPageNumbers()}
      {showFirstLastPages && (
        <>
          <Pagination.Next
            disabled={currentPageNumber === totalPages}
            onClick={() => handlePageChange(currentPageNumber + 1)}
          />
          <Pagination.Last
            disabled={currentPageNumber === totalPages}
            onClick={() => handlePageChange(totalPages)}
          />
        </>
      )}
      {showPageSizeOptions && (
        <Pagination.Item className={styles.pageSize}>
          <span>Show</span>
          <select value={pageSize} onChange={handlePageSizeChange}>
            {renderPageSizeOptions()}
          </select>
          <span>per page</span>
        </Pagination.Item>
      )}
    </Pagination>
  );
};

export default CustomPagination;