import React from 'react';
import { usePriorizacaoOps } from '../../../contexts/Corte/ControleEnfesto/PriorizacaoOpsContext';
import styles from '../../../styles/Corte/ControleEnfesto/PriorizacaoOps.module.scss';
import MainTable from '../../../components/Corte/ControleEnfesto/PriorizacaoOps/MainTable';
import ModalModoCorte from '../../../components/Corte/ControleEnfesto/PriorizacaoOps/ModalModoCorte';
import ModalConfirmacao from '../../../components/Corte/ControleEnfesto/PriorizacaoOps/ModalConfirmacao';
import ModalSelecaoFiltro from '../../../components/Corte/ControleEnfesto/PriorizacaoOps/ModalSelecaoFiltro';
import { Button } from 'react-bootstrap';
import ModalFiltroData from '../../../components/Corte/ControleEnfesto/PriorizacaoOps/ModalFiltroData';

const PriorizacaoOps = () => {

    const { handleShowModal, handleShowSelecaoFiltro, clearAllFiltros } = usePriorizacaoOps();
    
    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">Corte - Priorização de OPs</h3>
            </article>

            <article className={styles.articleGray}>
            <span>
                    <Button
                        variant="outline-info"
                        className={styles.modalBtn}
                        style={{ margin: '20px 20px' }}
                        onClick={handleShowSelecaoFiltro}
                    >
                        Filtro
                    </Button>
                    <Button
                        variant="outline-warning"
                        className={styles.modalBtn}
                        style={{ margin: '20px 20px' }}
                        onClick={clearAllFiltros}
                    >
                        Limpar Filtro
                    </Button>
                </span>
                <span style={{ position: 'absolute', right: '20px' }}>
                    <Button
                        variant="primary"
                        className={styles.modalBtnEquipe}
                        onClick={handleShowModal}
                    >
                        Selecionar Equipe
                    </Button>
                </span>
                <MainTable/>
            </article>

            <ModalModoCorte/>
            <ModalConfirmacao/>
            <ModalSelecaoFiltro/>
            <ModalFiltroData/>
        </div>
    );
};

export default PriorizacaoOps;
