import { usePAReversa } from "../../../contexts/PAReversaContext";
import { Modal, Button } from "react-bootstrap";
import styles from './PAReversa.module.scss';
import { useEffect, useState } from "react";
import Tabela from "../../Misc/Tabela/Tabela";
import { toast } from "react-toastify";

const PAReversaModalConfirmacao = () => {
  const { showModalConfirmacao, setShowModalConfirmacao, romaneioFiltrado, processarReversa, listReversa } = usePAReversa();
  const [resumo, setResumo] = useState([]);
  const [produtosPendentes, setProdutosPendentes] = useState([]);

  useEffect(() => {
    const arr_pendencias = [];
    const arr_resumo = [];
    listReversa.forEach(item => {
      if (item.direcionamento_defeitos.length === 0 && !item.nao_possui_defeito) arr_pendencias.push(`${item.produto} - ${item.cor_produto}\n`);
      if (item.direcionamento_defeitos.length > 0) {
        item.direcionamento_defeitos.forEach(def => {
          const { grade, quantidade, defeito, direcionamento } = def;
          arr_resumo.push({
            produto: item.produto,
            cor_produto: item.cor_produto,
            tamanho: grade.tamanho,
            quantidade,
            defeito: defeito.map(d => d.label).join(' | '),
            direcionamento: direcionamento.label
          });
        });
      }
    });
    setProdutosPendentes([...arr_pendencias]);
    setResumo([...arr_resumo]);
  }, []);

  const resumo_columns = [
    { name: 'Produto', selector: row => row.produto },
    { name: 'Cor Produto', selector: row => row.cor_produto },
    { name: 'Tamanho', selector: row => row.tamanho },
    { name: 'Quantidade', selector: row => row.quantidade },
    { name: 'Defeitos', selector: row => row.defeito, width: '25%' },
    { name: 'Direcionamento', selector: row => row.direcionamento },
  ];

  return (
    <Modal
      show={showModalConfirmacao}
      centered
      size={'xl'}
      onHide={() => setShowModalConfirmacao(false)}
    >
      <Modal.Header style={{ fontWeight: '500' }} closeButton>Processando {romaneioFiltrado}</Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <section className={styles.warn_text} style={{ backgroundColor: produtosPendentes.length > 0 ? '#FFCCCC99' : '#CCFFDA99' }}>
          <div>
            {produtosPendentes.length > 0 ?
              `Ainda há pendências na revisão no romaneio ${romaneioFiltrado}. Por favor confirme se as referências a seguir realmente não possuem defeitos`
              :
              `Ao prosseguir você irá processar todas as referências presentes no romaneio ${romaneioFiltrado}. Deseja continuar?`
            }
          </div>
          <div>
            {produtosPendentes.length > 0 ?
              `${produtosPendentes.join(' | ')}` : null
            }
          </div>
        </section>
        <section>
          <label className={styles.form_label}>Resumo do direcionamento de peças defeituosas</label>
          <Tabela
            columns={resumo_columns}
            data={resumo}
            pagination={false}
          />
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ width: '300px' }}
          variant={'success'}
          onClick={processarReversa}
          disabled={produtosPendentes.length > 0}
        >
          Processar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PAReversaModalConfirmacao;