import React, { useState } from "react";
import { Button, Modal, Table, Accordion, FormControl, Form } from 'react-bootstrap';
import styles from '../../../styles/MP/RapportEnvioCad.module.scss';
import { useMPRapportEnvioCad } from "../../../contexts/MPRapportEnvioCadContext";
import ModalConfirmacaoGrade from "./ModalConfirmacaoGrade";
import MaterialIcon from 'material-icons-react';

const ModalCadConsumo = () => {
    const {
        showHistorico,
        handleCloseHistorico,
        historicoPedido,
        historicoData,
        pedidoSelecionado,
        produtosConsumo,
        handleNovoConsumoChange,
        calcularPercentualNovoConsumo,
        handleStatusChange,
        setShowConfirmacaoGrade,
        showConfirmacaoGrade,
        file,
        setFile,
        handleFileChange,
        obsCad, setObsCad,
        validateFields,
        handleSave,
        handleShowPDF
    } = useMPRapportEnvioCad();

    
    return (
        <>
            <Modal
                show={showHistorico}
                onHide={handleCloseHistorico}
                backdrop="static"
                keyboard={false}
                centered
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>Analise de Consumo - CAD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <article className={styles.articleSpacing}>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0" disabled>
                                <Accordion.Header>Dados do Pedido</Accordion.Header>
                                <Accordion.Body className={styles.infoBlockDisplay}>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Pedido:</span>
                                            <span className={styles.fontBlue}>{pedidoSelecionado.PEDIDO}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Nota Fiscal:</span>
                                            <span className={styles.fontBlue}>{pedidoSelecionado.NFENTRADA}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Fornecedor:</span>
                                            <span className={styles.fontBlue}>{pedidoSelecionado.FORNECEDOR}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Material:</span>
                                            <span className={styles.fontBlue}>{pedidoSelecionado.MATERIAL}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Descrição Material:</span>
                                            <span className={styles.fontBlue}>{pedidoSelecionado.DESCRICAOMATERIAL}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Cor Material:</span>
                                            <span className={styles.fontBlue}>{pedidoSelecionado.CORMATERIAL}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Descrição Cor:</span>
                                            <span className={styles.fontBlue}>{pedidoSelecionado.DESCRICAOCOR}</span>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </article>
                    {historicoPedido.length > 0 &&
                        <article className={styles.articleSpacing}>
                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Medidas</Accordion.Header>
                                    <Accordion.Body className={styles.infoBlockDisplay}>
                                        <div className={styles.infoBlock}>
                                            <div className={styles.infoBlockColumm}>
                                                <span>Largura Util:</span>
                                                <span className={styles.fontBlue} style={{ textAlign: 'center' }}>{historicoPedido[0].MENORLAGURAUTIL}</span>
                                            </div>
                                            <div className={styles.infoBlockColumm}>
                                                <span>Medida Info:</span>
                                                <span className={styles.fontBlue} style={{ textAlign: 'center' }}>{historicoPedido[0].MEDIDAINFO}</span>
                                            </div>
                                            <div className={styles.infoBlockColumm}>
                                                <span>Maior Rapport Encontrado:</span>
                                                <span className={styles.fontBlue} style={{ textAlign: 'center' }}>{historicoPedido[0].MAIORLARGURAMEDIDA}</span>
                                            </div>
                                            <div className={styles.infoBlockColumm}>
                                                <span>Menor Rapport Encontrado:</span>
                                                <span className={styles.fontBlue} style={{ textAlign: 'center' }}>{historicoPedido[0].MENORLARGURAMEDIDA}</span>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </article>
                    }

                    <Table responsive>
                        <thead>
                            <tr>
                                <th className={styles.blockTitle}>ID</th>
                                <th className={styles.blockTitle}>PRODUTO</th>
                                <th className={styles.blockTitle}>DESC. PRODUTO</th>                                
                                <th className={styles.blockTitle}>CONSUMO LINX</th>
                                <th className={styles.blockTitle}>NOVO CONSUMO</th>
                                <th className={styles.blockTitle}>% DO NOVO CONSUMO</th>
                                <th className={styles.blockTitle}>RETORNO ESTILO</th>
                                <th className={styles.blockTitle} style={{textAlign: 'center'}}>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {produtosConsumo.length > 0 &&
                                produtosConsumo.map((produto, index) => (
                                    <tr key={index}>
                                        <td className={styles.tableLines}>{produto.IdAnaliseConsumo}</td>
                                        <td className={styles.tableLines}>{produto.PRODUTO}</td>
                                        <td className={styles.tableLines}>{produto.DESC_PRODUTO}</td>                                        
                                        <td className={styles.tableLines}>{produto.CONSUMO}</td>
                                        <td className={styles.tableLines}>
                                            <FormControl
                                                type="number"
                                                value={produto.novoConsumo ? produto.novoConsumo : produto.CONSUMO}
                                                onChange={(e) => handleNovoConsumoChange(index, e.target.value)}
                                            />
                                        </td>
                                        <td className={styles.tableLines} style={{ color: calcularPercentualNovoConsumo(index) > 10 ? 'red' : 'inherit', fontWeight: calcularPercentualNovoConsumo(index) > 10 ? 'bold' : 'normal' }}>
                                            {produto.novoConsumo !== '' && !isNaN(produto.novoConsumo) && produto.CONSUMO > 0 ?
                                                `${calcularPercentualNovoConsumo(index).toFixed(2)}%` :
                                                'N/A'
                                            }
                                        </td>
                                        <td className={styles.tableLines}>
                                            {produto.StatusPlanner}
                                        </td>
                                        <td className={styles.tableLines}>
                                            <Button
                                                variant={produto.IdStatusFinal == '61' ? "warning" : "outline-warning"}
                                                className="m-1"
                                                onClick={() => handleStatusChange(index, '61')}
                                            >
                                                Pendente
                                            </Button>
                                            <Button
                                                variant={produto.IdStatusFinal == '4' ? "success" : "outline-success"}
                                                className="m-1"
                                                onClick={() => handleStatusChange(index, '4')}
                                            >
                                                Aprovado
                                            </Button>
                                            <Button
                                                variant={produto.IdStatusFinal == '8' ? "danger" : "outline-danger"}
                                                className="m-1"
                                                onClick={() => handleStatusChange(index, '8')}
                                            >
                                                Reprovado
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    <div className={styles.fileUpload}>
                        <Form.Label className={styles.fileUploadLabel}>
                            <Button as="span" variant="outline-primary">
                                Anexar Arquivo
                            </Button>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <span style={{padding: '5px'}}>{file && file.name}</span>
                            <Button className={styles.fileUploadLabel}
                            onClick={() => handleShowPDF(pedidoSelecionado.ArquivoAnaliseCad)}
                            >
                                            <i className={styles.icon}>
                                                <MaterialIcon 
                                                    icon="visibility" 
                                                    size={20} 
                                                    color='white'
                                                />
                                            </i>
                            </Button>
                        </Form.Label>
                    </div>                    
                    <div className={styles.obsField} style={{marginBottom:'25px'}}>
                        <Form.Label>Observação</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={obsCad}
                            onChange={(e) => setObsCad(e.target.value)}
                        />
                    </div>
                    <div className={styles.fileUpload} >
                        <Form.Label className={styles.fileUploadLabel}>                            
                        { pedidoSelecionado.ArquivoAnalisePlanner  && <><span style={{marginRight: '5px'}}>Arquivo Estilo</span>  <Button className={styles.fileUploadLabel}
                            onClick={() => handleShowPDF(pedidoSelecionado.ArquivoAnalisePlanner)}
                            title="Visualizar arquivo Estilo"
                            >
                                            <i className={styles.icon}>
                                                <MaterialIcon 
                                                    icon="visibility" 
                                                    size={20} 
                                                    color='white'
                                                />
                                            </i>
                            </Button>
                            </>
                        }
                        </Form.Label>
                    </div>
                    <div className={styles.obsField} style={{marginBottom:'25px'}}>
                        <Form.Label>Observação Estilo</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={pedidoSelecionado?.obsPlanner}
                            disabled={true}
                            readOnly={true}
                            
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseHistorico}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={handleSave}
                    >
                        Gravar
                    </Button>
                </Modal.Footer>
            </Modal>
            <ModalConfirmacaoGrade
                show={showConfirmacaoGrade}
            />
        </>
    );
};

export default ModalCadConsumo;
