
import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styles from './listar_imagens_modal.module.scss';
import MaterialIcon from 'material-icons-react';

const ListarImagemModal = (params) => {
  const {
    showModal,
    closeModal,
    imagens,
    direcionamentoIndex = null,
    adicionarImagens,
    removerImagem,
  } = params;

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
      size={'lg'}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header style={{ fontWeight: '500' }} closeButton>Editar Imagens</Modal.Header>
      <Modal.Body>
        {
          imagens.length === 0 &&
          <div style={{ fontWeight: '500', textAlign: 'center' }}>
            Você não adicionou nenhuma imagem a esse direcionamento
          </div>
        }
        <div className={styles.image_container}>
          {imagens.map((image, index) => (
            <div key={index} className={styles.image_wrapper}>
              <img src={URL.createObjectURL(image)} alt={`modal_${index}`} />
              <div className={styles.remove_btn} onClick={() => removerImagem(direcionamentoIndex, index)}>
                <MaterialIcon
                  icon={'cancel'}
                  size={28}
                  color='#DC3545'
                />
              </div>
            </div>
          ))}
          <input
            type="file"
            accept="image/*"
            id="imageInputModal"
            multiple
            style={{ display: 'none' }}
            onChange={e => adicionarImagens(e, direcionamentoIndex)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer_wrapper}>
        <Button
          variant={'success'}
          className={styles.footer_btn}
          onClick={() => document.getElementById('imageInputModal').click()}
        >
          Adicionar Imagens
        </Button>
        <Button
          variant={'success'}
          className={styles.footer_btn}
          onClick={closeModal}
        >
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ListarImagemModal;
