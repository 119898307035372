import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form, Row, Col } from 'react-bootstrap';
import { useFGTesteDeBase } from "../../../../contexts/FarmGlobal/TesteDeBaseContext.js";
import styles from '../../../../styles/FarmGlobal/TesteDeBase.module.scss';
import MaterialIcon from 'material-icons-react';
import ModalConfirmacao from './ModalConfirmacao.jsx';

const ModalTesteDeBaseAnalisar = () => {
    const {
        showCad,
        setShowCad,
        handleSave,        
        setMaterialSelecionado,
        filterObj,
        setFilterObj,
        handleSearch,
        handleSort,
        getSortIcon,
        mainData,
        handleShowPDF,
        removeAccents,
        setShowConfirmacao,        
        handleShowCadTeste,
        setTesteSelecionado,
        testeSelecionado,  
        handleListTestes,   
        listTestes,   
        handleListIndices,
        listIndices,
        handleSaveAnalise,
        agendamentos, 
        setAgendamentos,
        informacoesTeste, 
        setInformacoesTeste,
        observacao, 
        setObservacao,
        observacaoFinal, 
        setObservacaoFinal,
        handleValidaResultado,
        resetStatesProcessarTeste,
    } = useFGTesteDeBase();

 
    useEffect(() => {
        if (testeSelecionado?.IdTesteBase) {  
            handleListTestes(); 
            handleListIndices();   
            setObservacao(testeSelecionado?.Observacao);      
            setObservacaoFinal(testeSelecionado?.ObservacaoFinal);         
        }
    }, [testeSelecionado]);

    useEffect(() => {
        const processTestes = async () => {
            if (testeSelecionado?.IdTesteBase) {
                const processedTests = listTestes?.filter(teste => teste.IdStatusTeste !== 45) || [];                    
                
                const updates = [];
                const newAgendamentoSet = new Set(agendamentos); 

                for (const teste of processedTests) {                    
                    newAgendamentoSet.add(teste.DescricaoTeste); 
    
                    const informacoesAtualizadas = {};
                    Object.keys(teste).forEach(campo => {                        
                        informacoesAtualizadas[campo] = teste[campo];
                    });
                    updates.push({ descricao: teste.DescricaoTeste, informacoesAtualizadas });
                }
    
                setAgendamentos(Array.from(newAgendamentoSet));    
                
                updates.forEach(({ descricao, informacoesAtualizadas }) => {                    
                    Object.keys(informacoesAtualizadas).forEach(campo => {
                        handleInformacaoTesteChange(descricao, campo, informacoesAtualizadas[campo]);
                    });
                });                
            }
        };
    
        processTestes();
    }, [listTestes]);
    

    const handleAgendamentoChange = async (teste) => {
        const jaAgendado = agendamentos.includes(teste);
        if (jaAgendado) {
            setAgendamentos(agendamentos.filter(item => item !== teste));
            setInformacoesTeste(prev => {
                const { [teste]: _, ...rest } = prev;
                return rest;
            });
        } else {
            setAgendamentos([...agendamentos, teste]);
        }
    };

    const handleInformacaoTesteChange = (teste, campo, valor) => {
        setInformacoesTeste(prev => ({
            ...prev,
            [teste]: {
                ...prev[teste],
                [campo]: valor
            }
        }));
    };
    

    const renderCamposEspecificos = (teste) => {
        if (teste == 'GRAMATURA') {
            const { Gramatura = '', GramaturaFornecedor = '', percentualDif = '' } = informacoesTeste[teste] || {};
            const testeObjeto = listTestes.find(item => item.DescricaoTeste === teste);
            
            return (
                <>
                    
                        <Col>
                            <Form.Label>Gramatura(g/m²)</Form.Label>
                            <Form.Control
                                type="number"
                                style={{textAlign: 'right'}}
                                value={Gramatura !== undefined && Gramatura !== null ? Gramatura : testeObjeto?.Gramatura || ''}
                                onChange={(e) => handleInformacaoTesteChange(teste, 'Gramatura', e.target.value)}
                            />
                        </Col>
                                        
                        <Col>
                        <Form.Label>Gramat.Forn.(g/m²)</Form.Label>
                            <Form.Control
                                type="number"
                                style={{textAlign: 'right'}}
                                value={GramaturaFornecedor !== undefined && GramaturaFornecedor !== null ? GramaturaFornecedor : testeObjeto?.GramaturaFornecedor || ''}
                                onChange={(e) => handleInformacaoTesteChange(teste, 'GramaturaFornecedor', e.target.value)}
                            />
                        </Col>
                        <Col>
                        <Form.Label>%Dif.Gramatura <br/></Form.Label>
                            <Form.Control
                                type="text"
                                style={{textAlign: 'right'}}
                                readOnly
                                value={( GramaturaFornecedor ? (Gramatura - GramaturaFornecedor) / GramaturaFornecedor * 100 : 0).toFixed(2)}
                            />
                        </Col>
                    
                </>
            );
        }

        if (teste == 'LARGURA') {
            const { Largura= '', LarguraFornecedor = '', percentualDif = '' } = informacoesTeste[teste] || {};
            const testeObjeto = listTestes.find(item => item.DescricaoTeste === teste);
            return (
                <>
                    
                        <Col>
                        <Form.Label>Largura(g/m²)</Form.Label>
                            <Form.Control
                                type="number"
                                style={{textAlign: 'right'}}
                                value={Largura !== undefined && Largura !== null ? Largura : testeObjeto?.Largura || ''}
                                onChange={(e) => handleInformacaoTesteChange(teste, 'Largura', e.target.value)}
                            />
                        </Col>                                        
                        <Col>
                        <Form.Label>Largura Forn.(g/m²)</Form.Label>
                            <Form.Control
                                type="number"
                                style={{textAlign: 'right'}}
                                value={LarguraFornecedor !== undefined && LarguraFornecedor !== null ? LarguraFornecedor : testeObjeto?.LarguraFornecedor || ''}
                                onChange={(e) => handleInformacaoTesteChange(teste, 'LarguraFornecedor', e.target.value)}
                            />
                        </Col>
                        <Col>
                        <Form.Label>%Dif.Largura <br/></Form.Label>
                            <Form.Control
                                type="text"
                                style={{textAlign: 'right'}}
                                readOnly
                                value={((LarguraFornecedor || testeObjeto?.LarguraFornecedor) ? ((Largura || testeObjeto?.Largura) - (LarguraFornecedor || testeObjeto?.LarguraFornecedor)) / (LarguraFornecedor || testeObjeto?.LarguraFornecedor) * 100 : 0).toFixed(2)}
                            />
                        </Col>
                    
                </>
            );
        }

        return null;
    };

    return (
        <>
            <Modal
                show={showCad}
                onHide={() => setShowCad(false)}
                backdrop="static"
                keyboard={false}
                centered
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>Resultado de Teste Base</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <article>                        
                        {testeSelecionado && (
                            <Table className="m-2" responsive>
                <thead>
                    <tr>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            STATUS                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            LAUDO                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            FICHA TÉCNICA                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            DESCRIÇÃO MATERIAL                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            TIPO                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            FORNECEDOR MP                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            FORNECEDOR PA                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                            
                        >
                            CÓDIGO TECIDO
                            
                        </th>
                        <th
                            className={styles.blockTitle}
                            style={{ cursor: 'pointer' }}
                        >
                            Nº REQUISIÇÃO
                            
                        </th>                       
                    </tr>
                </thead>
                <tbody>                    
                        <tr key={testeSelecionado.id}>
                            <td className={styles.tableLines}>{testeSelecionado.DescricaoStatus}</td>
                            <td className={styles.tableLines}>
                                {testeSelecionado.ArquivoLaudo ? (
                                    <i onClick={() => handleShowPDF(testeSelecionado.ArquivoLaudo)}>
                                        <MaterialIcon icon="description" size={30} color='#0D6EFD' />
                                    </i>
                                ) : ''}
                            </td>
                            <td className={styles.tableLines}>
                                {testeSelecionado.Arquivo ? (
                                    <i onClick={() => handleShowPDF(testeSelecionado.Arquivo)}>
                                        <MaterialIcon icon="description" size={30} color='#0D6EFD' />
                                    </i>
                                ) : ''}
                            </td>
                            <td className={styles.tableLines}>{testeSelecionado.Descricao}</td>
                            <td className={styles.tableLines}>{testeSelecionado.Tipo}</td>
                            <td className={styles.tableLines}>{testeSelecionado.FornecedorMp}</td>
                            <td className={styles.tableLines}>{testeSelecionado.FornecedorPa}</td>
                            <td className={styles.tableLines}>{testeSelecionado.ChaveTipo}</td>
                            <td className={styles.tableLines}>{testeSelecionado.IdTesteBase}</td>                            
                        </tr>                    
                </tbody>
            </Table>
                        )}
                    </article>
                    <article className={styles.articleGray} style={{padding: '10px'}}>
                        <h5>Agendamento dos Testes</h5>                   
                        <Form>
                            <Row>
                                {listTestes.map((teste, index) => (
                                    <Col key={index} xs={12} md={6} lg={3}>
                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                label={teste.DescricaoTeste}
                                                checked={
                                                    agendamentos.includes(teste.DescricaoTeste) ||
                                                    teste.IDTeste === 7 || 
                                                    teste.IDTeste === 18 ||
                                                    teste.IDTeste === 13
                                                }
                                                onChange={() => handleAgendamentoChange(teste.DescricaoTeste)}
                                                disabled={teste.IDTeste === 7 || teste.IDTeste === 18 || teste.IDTeste === 13}
                                            />                                            
                                        </Form.Group>
                                    </Col>
                                ))}
                            </Row>
                        </Form>
                        {agendamentos?.map(teste =>  {
                            let indicesFiltrados = listIndices
                            .filter(indice => indice.DescricaoTeste === teste)
                            indicesFiltrados = indicesFiltrados.sort((a, b) => {
                                const parseIndice = (indice) => {
                                    // Remove o símbolo ">" e separa as faixas de percentuais
                                    const isGreaterThan = indice.includes('>');
                                    const cleanIndice = indice.replace(/[<>]/g, '');
                                    const [min, max] = cleanIndice.split(' a ').map(Number);

                                    // Se é um valor único com ">", atribuímos um valor alto ao max
                                    if (isGreaterThan) {
                                        return [min, Infinity];
                                    }
                                    return max != undefined ? [min, max] : [min, min];
                                };

                                const [aMin, aMax] = parseIndice(a.Indice);
                                const [bMin, bMax] = parseIndice(b.Indice);

                                // Primeiro, compare os valores mínimos
                                if (aMin != bMin) return aMin - bMin;

                                // Se os valores mínimos são iguais, compare os valores máximos
                                return aMax - bMax;
                            });

                            const testeObjeto = listTestes.find(item => item.DescricaoTeste === teste);                             
                            
                            return (
                            <div key={teste} className={styles.testeDetalhes}>                                
                                <Form.Group as={Row} style={{padding: '10px'}}>                         
                                    <Col style={{flex: '0 0 150px'}} className="d-flex align-items-center justify-content-center">
                                        {teste}
                                    </Col>           
                                    <Col style={{flex: '0 0 150px'}} >
                                        <Form.Label>Status<br/></Form.Label>
                                        <Form.Control
                                            as="select"
                                            style={{textAlign: 'center',  paddingLeft: '0'}}
                                            value={informacoesTeste[teste]?.IdStatusTeste || testeObjeto?.IdStatusTeste}
                                            onChange={(e) => {handleInformacaoTesteChange(teste, 'IdStatusTeste', e.target.value); 
                                                handleInformacaoTesteChange(teste, 'IDTeste',testeObjeto.IDTeste);}}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="4">Aprovado</option>
                                            <option value="8">Reprovado</option>                                           
                                        </Form.Control>
                                    </Col>                                
                                    {renderCamposEspecificos(teste)}                                                                 
                                    <Col style={{flex: '1', display: teste === 'GRAMATURA' || teste === 'LARGURA' ? 'none' : 'inline'}}>
                                        <Form.Label>Índice<br/></Form.Label>
                                        <Form.Control
                                        as="select"
                                        style={{textAlign: 'center',  margin: '0 auto'}}
                                        value = {informacoesTeste[teste]?.IdIndice != undefined && informacoesTeste[teste]?.IdIndice != null ?  informacoesTeste[teste]?.IdIndice : testeObjeto?.IdIndice || ''}
                                        onChange={(e) => {
                                            handleInformacaoTesteChange(teste, 'IdIndice', e.target.value);
                                            if (teste === 'DESVIO DA ESTAMPA' || teste === 'DESVIO DE TRAMA') {
                                            if (e.target.value != '0.1% a 1%' && e.target.value != '1.1% a 2%' && e.target.value != '') {
                                                handleInformacaoTesteChange(teste, 'IdStatusTeste', 8);
                                                handleInformacaoTesteChange(teste, 'IDTeste',testeObjeto.IDTeste);
                                            }
                                        }

                                        
                                        }}
                                        disabled = {teste === 'GRAMATURA' || teste === 'LARGURA'}
                                        >
                                        <option value="">Selecione</option>
                                        {indicesFiltrados.map(indice => (
                                            <option key={indice.Indice} value={indice.Indice}>{indice.Indice}</option>
                                        ))}

                                        </Form.Control>
                                    </Col> 
                                    <Col style={{flex: '1'}}>
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            placeholder="Observação"
                                            type="text"
                                            value={informacoesTeste[teste]?.Observacao || ''}
                                            onChange={(e) => handleInformacaoTesteChange(teste, 'Observacao', e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                            </div>
                        );
                    })}
                        
                    </article>


                    <article>
                        <Form.Group>
                            <Form.Label>Observação</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={observacao || ''}
                                onChange={(e) => setObservacao(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Observação Final</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={observacaoFinal || ''}
                                onChange={(e) => setObservacaoFinal(e.target.value)}
                            />
                        </Form.Group>
                    </article>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setShowCad(false); resetStatesProcessarTeste();}}>Cancelar</Button>
                    <Button variant="primary" onClick={handleValidaResultado}>Salvar</Button>
                </Modal.Footer>
            </Modal>

            <ModalConfirmacao />
        </>
    );
};

export default ModalTesteDeBaseAnalisar;
