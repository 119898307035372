import styles from './SelectInput.module.scss';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const SelectInput = (props) => {
  const { placeholder, label, style, value, onChange, options, isOptionDisabled = () => false, filterOption = null, isMulti = false, isDisabled = false } = props;
  
  return (
    <Form.Group className={styles.select_group} style={style}>
      <Form.Label className={styles.form_label}>{label}</Form.Label>
      <Select
        className={styles.select_input}
        placeholder={placeholder || 'Selecionar'}
        value={value || null}
        onChange={onChange}
        options={options}
        isOptionDisabled={isOptionDisabled}
        isClearable={true}
        closeMenuOnSelect={true}
        filterOption={filterOption}
        isMulti={isMulti}
        isDisabled={isDisabled}
      />
    </Form.Group>
  )
}

export default SelectInput;