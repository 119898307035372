import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form, Row, Col } from 'react-bootstrap';
import styles from '../../../../styles/FarmGlobal/MateriaisComposicao.module.scss';
import { useFGMateriaisComposicao } from "../../../../contexts/FarmGlobal/MateriaisComposicaoContext";
import { useFGTesteDeBase } from "../../../../contexts/FarmGlobal/TesteDeBaseContext";
import axios from 'axios';
import MaterialIcon from 'material-icons-react';
import ModalConfirmacaoNovaFicha from "./ModalConfirmacaoNovaFicha";
import ModalConfirmacaoNovaAmostra from "./ModalConfirmacaoNovaAmostra"

const ModalCadComposicaoEditar = ({ idMaterial, showCad, setShowCad }) => {
    const {
        materialAtivo,
        setMaterialAtivo,
        materialComposicaoAtivo,
        setmaterialComposicaoAtivo,       
        setFile,
        handleAddComposicao,
        handleRemoveComposicao,
        handleChangeComposicao,
        handleChange,
        handleFileChange,
        handleChangeTipo,
        handleSaveUpperCase,
        removeAccents,
        fetchMaterialInfo,
        isLoading, setIsLoading,
        materialSelecionado,
        handleSaveUpdateMaterial,
        handleChangeMaterialAtivo,
        handleShowPDF,
        file
        ,showConfirmacaoNovaFicha, setShowConfirmacaoNovaFicha
        ,showConfirmacaoNovaAmostra, setShowConfirmacaoNovaAmostra
    } = useFGTesteDeBase();

    const { showCadMaterial, setShowCadMaterial,
        tiposFibra,
        fornecedoresPa,
        fornecedoresMp
    } = useFGTesteDeBase();

    

    // Fetch material information when modal opens
    useEffect(() => {
        if (materialSelecionado?.IdMaterial) {
            fetchMaterialInfo(materialSelecionado.IdMaterial);            
        }
    }, [materialSelecionado]);

    
    return (
        <>
            <Modal
                show={showCadMaterial}
                onHide={() => setShowCadMaterial(false)}
                backdrop="static"
                keyboard={false}
                centered
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>Alterar Informações de Composição</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    {isLoading ? (
                        <div>Carregando...</div>
                    ) : (
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Descrição Material <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control 
                                            name="descricao" 
                                            value={removeAccents(materialAtivo?.Descricao || '')} 
                                            onChange={handleChangeMaterialAtivo}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Gramatura (g/m²) <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            name="Gramatura"
                                            type="number"
                                            value={materialAtivo?.Gramatura || ''}
                                            onChange={handleChangeMaterialAtivo}
                                            min="1"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Tipo <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="Tinto"
                                                type="checkbox"
                                                checked={materialAtivo?.Tipo === 'T'}                                                
                                                readOnly={true}
                                                disabled ={true}
                                            />
                                            <Form.Check
                                                inline
                                                label="Estampado Cilindro"
                                                type="checkbox"
                                                checked={materialAtivo?.Tipo === 'EC'}                                                
                                                readOnly={true}
                                                disabled ={true}
                                            />
                                            <Form.Check
                                                inline
                                                label="Estampado Digital"
                                                type="checkbox"
                                                checked={materialAtivo?.Tipo === 'ED'}                                                
                                                readOnly={true}
                                                disabled ={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Ref Linx</Form.Label>
                                        <Form.Control 
                                            name="refLinx" 
                                            value={materialAtivo?.RefLinx || ''} 
                                            onChange={handleChange} 
                                            readOnly={true}
                                            disabled ={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Fornecedor MP <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control as="select" name="idFornecedorMp" value={materialAtivo?.IdFornecedorMp} onChange={handleChange}
                                        readOnly={true}
                                        disabled ={true}
                                        >
                                            {fornecedoresMp.map((fornecedor) => (
                                                <option key={fornecedor.IdFornecedor} value={fornecedor.IdFornecedor}>
                                                    {fornecedor.Fornecedor}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Fornecedor PA <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control as="select" name="idFornecedorPa" value={materialAtivo?.IdFornecedorPa} onChange={handleChange}
                                        readOnly={true}
                                        disabled ={true}
                                        >
                                            {fornecedoresPa.map((fornecedor) => (
                                                <option key={fornecedor.IdFornecedor} value={fornecedor.IdFornecedor}>
                                                    {fornecedor.Fornecedor}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                            <Col md={6}>
                                <Form.Group>                                 
                                        {materialAtivo?.Arquivo && (
                                            <Button 
                                                variant="primary"
                                                onClick={() => handleShowPDF(materialAtivo?.Arquivo)} 
                                                title="Visualizar Ficha Técnica" 
                                            >
                                                Visualizar Ficha Técnica
                                            </Button>
                                        )}
                                        <Form.Label htmlFor="file-upload" className="btn btn-warning" style={{height: '30px', marginTop:'5px', marginLeft: '5px'}}>
                                            Selecionar Nova Ficha Técnica
                                        </Form.Label>
                                        <Form.Control 
                                            id="file-upload"
                                            type="file" 
                                            accept=".pdf" 
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }} // Esconde o input de arquivo padrão
                                        />
                                         {file && ` Arquivo: ${file.name}`}
                                    
                                   
                                </Form.Group>
                            </Col>


                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <h5>Composição</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>(%)</th>
                                                <th>Tipo de Fibra</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {materialComposicaoAtivo && materialComposicaoAtivo.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Form.Control
                                                            type="number"
                                                            value={item.PercFibra}
                                                            onChange={(e) => handleChangeComposicao(index, 'PercFibra', e.target.value)}
                                                            min="1"
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            as="select"
                                                            value={item.IdTipoFibra}
                                                            onChange={(e) => handleChangeComposicao(index, 'IdTipoFibra', e.target.value)}
                                                        >
                                                            {tiposFibra.map((tipo) => (
                                                                <option key={tipo.IdTipoFibra} value={tipo.IdTipoFibra}>
                                                                    {tipo.TipoFibra}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                        <Button variant="danger" onClick={() => handleRemoveComposicao(index)}>
                                                            Remover
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button onClick={handleAddComposicao}>Adicionar Composição</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setShowCadMaterial(false);}}>Cancelar</Button>
                    <Button variant="primary" onClick={() => setShowConfirmacaoNovaFicha(true)}>Enviar Nova Ficha Técnica</Button>
                    <Button variant="primary" onClick={() => setShowConfirmacaoNovaAmostra(true)}>Enviar Nova Amostra</Button>
                </Modal.Footer>
            </Modal>
            <ModalConfirmacaoNovaFicha />
            <ModalConfirmacaoNovaAmostra />
        </>
    );
}

export default ModalCadComposicaoEditar;
