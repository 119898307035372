import { FGTesteDeBaseProvider } from "../../../contexts/FarmGlobal/TesteDeBaseContext";
import TesteDeBaseMain from "../../../components/FarmGlobal/TesteDeBase/Agendar/TesteBaseAgendarMain";

const FGTesteDeBase = () => {    

    return (
        <FGTesteDeBaseProvider>
            <TesteDeBaseMain />
        </FGTesteDeBaseProvider>
    );

}
export default FGTesteDeBase;