import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { api } from "../../../services/api";
import { toast } from "react-toastify";

function UploadModal(props) {

  const [file, setFile] = useState();

  function closeModal() {
    setFile();
    props.handleClose();
  }

  async function submitForm(e) {
    e.preventDefault();
    if (!file) return toast.warning('Arquivo não selecionado!');

    try {
      const form = new FormData();
      const el = props.obj;

      form.append('file', file, 'x.pdf');
      form.append('name', `${el.NFEntrada}_${el.SerieNF}_${el.Origem.replace(/ /g, '_')}.pdf`);

      await api.post('/PAControleDevolucao/enviar-nota-fiscal/', form);
      toast.success('Arquivo enviado com sucesso!c');
      closeModal();
    } catch (e) {
      toast.error('Erro ao enviar o arquivo!');
    }
  }

  return (
    <Modal show={props.show}>
      <Modal.Header className="d-flex justify-content-between">
        <h4 className="mb-0">Enviar Nota Fiscal</h4>
        <Button onClick={closeModal}>X</Button>
      </Modal.Header>
      <Modal.Body>
        <Form encType="multipart/form-data" onSubmit={submitForm}>
          <Form.Label>Arquivo:</Form.Label>
          <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} accept={"application/pdf"} multiple={false} />

          <Button className="mt-3" type="submit">Enviar</Button>
        </Form>
      </Modal.Body>
    </Modal>
  )

}

export default UploadModal;