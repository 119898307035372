import React from "react";
import ControleDevolucao from "../../components/PA/ControleDevolucao/index";

const PAControleDevolucao = () => {
  return (
    <div>
      <div className="p-2">
        <h3>Controle Devolução</h3>
      </div>
      <ControleDevolucao />
    </div>
  );
};

export default PAControleDevolucao;