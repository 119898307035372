import React from 'react';
import { Button } from 'react-bootstrap';
import styles from '../../styles/AgendamentoRevisao.module.scss';

const TableRow = ({ rowData, handleShowModal, headerlength }) => {

  const arr = !!rowData ? rowData : Array.from('-'.repeat(headerlength)).map(v => v.repeat(3));
  
  return (
    <tr className={styles.tableLines}>
      <td className={styles.tableLinesCell}>
        <Button variant='primary' onClick={handleShowModal}>
          Agendar
        </Button>
      </td>
      {
        arr.map((l, i) => {
        return <td className={styles.tableLinesCell} key={i}>{l}</td>
        })
      }
    </tr>
  );
};

export default TableRow;