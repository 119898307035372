import React from "react";
import { Button, Modal } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext'; 

const ModalVoltar = () => {

    const { showModalVoltar, handleConfirmaVoltar, handleNaoConfirmaVoltar } = useLancamentoCad();

    return (
        <>
            <Modal
                show={showModalVoltar}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Tem certeza?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ao voltar, você perderá todas as informações não salvas.
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleNaoConfirmaVoltar}
                    >
                        Não
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={handleConfirmaVoltar}
                    >
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )


}

export default ModalVoltar;
