import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 
import DadosAccordion from './DadosAccordion';

const ModalGrade = () => {

    const { handleShowImagem, handleShowAlteraFolha, gradeData, showGrade, handleCloseGrade, handleShowEnfest } = useDistribuicaoDemanda();

    return (
        <>
            <Modal
                show={showGrade}
                onHide={handleCloseGrade}
                backdrop="static"
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header>
                    <Modal.Title>Selecione a grade:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DadosAccordion/>
                    <section>
                        <Button
                            variant='outline-secondary'
                            className={styles.btnEstampa}
                            onClick={handleShowImagem}
                        >
                            Visualizar Estampa
                        </Button>
                    </section>
                    <article className={styles.articleGrade}>
                        <Table 
                            className={styles.tableGrade}
                        >
                            <thead>
                                <tr>
                                    <th>Grade</th>
                                    <th>Quantidade de Folhas Totais</th>
                                    <th>Quantidade de Folhas Restantes</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { gradeData.map((item, index) => {
                                    const qtdeFolhasTotal = !item.QtdeFolhasEncarregado ? item.QtdeFolhas : item.QtdeFolhasEncarregado
                                    const qtdeAlocada = item.QtdeFolhasAlocada ? item.QtdeFolhasAlocada : 0
                                    const qtdeRestante = qtdeFolhasTotal - qtdeAlocada
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <em>{item.GRADE}</em>
                                            </td>
                                            <td>
                                                {qtdeFolhasTotal}
                                            </td>
                                            <td>
                                                {qtdeRestante}
                                            </td>
                                            <td>
                                                <Button
                                                    variant={item.QtdeFolhasEncarregado || item.QtdeFolhasAlocada ? 'secondary' : 'outline-info'}
                                                    className={styles.btnFolhas}
                                                    disabled={item.QtdeFolhasEncarregado || item.QtdeFolhasAlocada}
                                                    onClick={() => handleShowAlteraFolha(item)}
                                                >
                                                    Alterar Quantidade de Folhas
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    variant={qtdeRestante > 0 ? 'outline-primary' : 'secondary'}
                                                    disabled={qtdeRestante <= 0}
                                                    onClick={() => handleShowEnfest(item)}
                                                >
                                                    Selecionar Enfestadores
                                                </Button>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </Table>
                    </article>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseGrade}
                    >
                        Voltar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalGrade;
