import { Button, Modal } from "react-bootstrap";

function GrupoModal (props) {
    return (
      <Modal show={props.show}>
        <Modal.Header>
          <div className="d-block w-100 container">
            <div className="d-flex-b">
              <h4>Escolha os colaboradores</h4>
              <h4 className="clickable" onClick={props.closeFn}>X</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrapper border border-dark rounded w-100">
            <table className="table w-100">
              <thead className="head-50">
                <tr className="t-header">
                  <th scope="col" className="col">Nome</th>
                </tr>
              </thead>
              <tbody>
              { props &&
                [...props?.colaboradores].map((c) => {
                  let cName = "";
                  if(props.pSelecionadas.indexOf(c.id) !== -1){
                    cName = "selected";
                  }
                  return(
                    <tr onClick={() => props.selectFn(c.id)} key={c.id} className={"clickable colab"}>
                        <th scope="col" className={`col ${cName}`}>{c.nome}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer">
          <Button onClick={props.iniciarProcessoFn}>
            Iniciar Processo
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default GrupoModal;