import React from "react";
import { Button, Modal, Form, Table } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext';
import DadosAccordion from "./DadosAccordion";
import MaterialIconsReact from "material-icons-react";

const ModalMateriais = () => {

    const { handleShowModalConfirmacao, handleDeleteFinalizado, materiaisFinalizadosUnique, materiaisRestantes, materialSelecionado, handleShowModalRiscos, habilitaFinalizarProd, habilitaInserir, showModalMateriais, handleCloseModalMateriais, materialCorData, handleCheckMateriCor, numRisco, handleNumRisco } = useLancamentoCad();

    return (
        <>
            <Modal
                show={showModalMateriais}
                onHide={handleCloseModalMateriais}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Selecione o material e a quantidade de riscos:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DadosAccordion/>
                    { !(materiaisFinalizadosUnique.length === materialCorData.length) &&
                        <section>
                            <h4 style={{textAlign: 'center', margin: '10px auto'}}>Pendentes:</h4>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Material</th>
                                        <th>Descrição Material</th>
                                        <th>Cor Material</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { materiaisRestantes && materiaisRestantes.map((item, index) => {
                                        const disableMaterial = materiaisFinalizadosUnique.some(matUni => matUni.material === item.material && matUni.cor_material === item.cor_material)

                                        if (!disableMaterial){
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Form.Check
                                                            type="radio"
                                                            name="materialCor"
                                                            onChange={e => handleCheckMateriCor(e, item)}
                                                            checked={item === materialSelecionado}
                                                            disabled={disableMaterial}
                                                        />
                                                    </td>
                                                    <td>{item.material}</td>
                                                    <td>{item.desc_material}</td>
                                                    <td>{item.cor_material}</td>
                                                </tr>
                                            ) 
                                        } else return null
                                    })}
                                </tbody>
                            </Table>
                        </section>
                    }
                    <section>
                        <Form className={styles.formWrapperModal}>
                            <Form.Group className={styles.filterInputModal}>
                                <Form.Label><strong>Informe o número de Riscos:</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    className={styles.formControl}
                                    placeholder="Quantidade de Riscos"
                                    value={numRisco || ''}
                                    onChange={e => handleNumRisco(e)}
                                />
                            </Form.Group>
                        </Form> 
                    </section>
                    { materiaisFinalizadosUnique.length > 0 && 
                        <section>
                            <h4 style={{textAlign: 'center', margin: '10px auto'}}>Finalizados:</h4>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Material</th>
                                        <th>Descrição Material</th>
                                        <th>Cor Material</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { materiaisFinalizadosUnique && materiaisFinalizadosUnique.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <i 
                                                        style={{ 'cursor': 'pointer' }}
                                                        className={styles.icon}
                                                        onClick={() => handleDeleteFinalizado(item)}
                                                    >
                                                        <MaterialIconsReact
                                                            icon="clear"
                                                            size={30}
                                                            color='red'
                                                        />
                                                    </i>
                                                </td>
                                                <td>{item.material}</td>
                                                <td>{item.desc_material}</td>
                                                <td>{item.cor_material}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </section>
                    }
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseModalMateriais}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={habilitaInserir ? "outline-primary" : "secondary"}
                        className={styles.modalBtn}
                        disabled={!habilitaInserir}
                        onClick={handleShowModalRiscos}
                    >
                        Inserir Riscos
                    </Button>
                    <Button
                        variant={habilitaFinalizarProd ? "outline-success" : "secondary"}
                        className={styles.modalBtn}
                        disabled={!habilitaFinalizarProd}
                        onClick={handleShowModalConfirmacao}
                    >
                        Finalizar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )


}

export default ModalMateriais;
