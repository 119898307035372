import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CookiesService from "../services/cookies";
const isDebugMode = process.env.REACT_APP_IS_DEBUG_MODE;

function PrivateRoute({ areas, acesso }) {
  const userData = CookiesService.getUserData();
  const [allow, setAllow] = useState(false);

  useEffect(() => {
    if (isDebugMode) return setAllow(true);

    if (userData) {
      const userAreas = Object.getOwnPropertyNames(userData?.areas);
      if (userData.admin === 1) setAllow(true);
      if (areas && areas.some(area => userAreas.includes(area))) setAllow(true);
      if (acesso && userData.acessos[acesso] === true) setAllow(true);
      return;
    }
  }, []);

  return (
    allow ? <Outlet /> : null
  );
}

export default PrivateRoute;
