import React from "react";
import styles from '../../../styles/MP/LaboratorioCemPorcentoRetorno.module.scss';

const AccordionItems = ({ linhaSelecionada }) => {

    const titleCase = (s) => 
        s.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())
            .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())


    return (
        <>
            { Object.entries(linhaSelecionada).map((pair, index) => {
                return (
                    <div className={styles.infoBlockColumm} key={index}>
                        <span>{titleCase(pair[0])}:</span>
                        <span className={styles.fontBlue}>{pair[1]}</span>
                    </div>  
                )
            })}
        </>
    )
}

export default AccordionItems;
