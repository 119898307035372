import react, { useState } from "react";

function Pedido(props) {
  function Change(event) {
    props.setter(props.index);
  }

  return (
    <tr className="table-line">
      <td className="element" key={props.obj["PEDIDO"] + props.index + "0"}>
        <input
          className="checkbox"
          type="checkbox"
          onChange={Change}
          checked={props.disabled ? true : props.value}
          disabled={props.disabled}
        />
      </td>

      {Object.keys(props.obj).map((val, index) => {
        if (typeof props.obj[val] === "string") {
          props.obj[val] = props.obj[val].trim();
          props.obj[val] = props.obj[val].replace(/  /g, " ");
        }
        if (val === "EMISSAO") {
          props.obj[val] = props.obj[val].split("T")[0].replace(/\-/g, "/");
        }
        if (index === 0) {
          return (
            <td
              key={props.obj["PEDIDO"] + props.index + (index + 1)}
              scope="row"
              className="element"
            >
              {props.obj[val]}
            </td>
          );
        }
        if (index > 0) {
          return (
            <td
              key={props.obj["PEDIDO"] + props.index + (index + 1)}
              className="element"
            >
              {props.obj[val]}
            </td>
          );
        }
      })}
    </tr>
  );
}

export default Pedido;
