import React, { useState } from 'react';
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import styles from '../../../styles/PA/ADM/AlteracaoRevisaoChecklist.module.scss';
import { toDatetime } from '../../../utils/dateUtils';
import { api }  from '../../../services/api';
import Carregando from '../../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { useEffect } from 'react';
import CookiesService from "../../../services/cookies";

const PAAdmAlteracaoRevisaoChecklist = () => {
	const [mainData, setMainData] = useState([]);
	const [formObj, setFormObj] = useState({});
	const [formErrs, setFormErrs] = useState({});
	const [filterObj, setFilterObj] = useState({});
	const [activeObj, setActiveObj] = useState({});
	const [loading, setLoading] = useState(false);
	const [motivoAlteracaoLista, setMotivoAlteracaoLista] = useState([]);
	const [showWarningModal, setShowWarningModal] = useState(false);
	const userLogin = CookiesService.getUserLogin();
	const [showModal, setShowModal] = useState(false);

	const handleCloseModal = () => {
		setFormObj({});
		setFormErrs({});
		setActiveObj({});
		setShowModal(false);
		setShowWarningModal(false);
	}

	const handleShowModal = (el) => {
		if ((el.IDStatusChecklist === 6 || el.IDStatusChecklist === 7) && el.NFSaida !== null) {
			return toast.warn(`Você não pode alterar essa referência porque ela foi reprovada pela revisão checklist e a nota fiscal de saída já foi emitida (NFSaida ${el.NFSaida}).`);
		}
		setActiveObj(el)
		setShowModal(true);
	}

	const handleFiltering = async () => {
		setLoading(true);
		const params = {
			NFEntrada: filterObj.NFEntrada,
			Produto: filterObj.Produto,
			CorProduto: filterObj.CorProduto
		}

		await api.get('/PAAdmControle/RC_GetMainData', { params })
			.then(res => {
				if (res.status === 200) { setMainData(res.data) }
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao se preencher a lista de processos, por favor cheque sua conexão com a internet ou entre contato com o suporte');
				console.error(`Erro na requisição '/RC_GetMainData' - ${e}`);
			});
		setLoading(false);
	}

	const fetchMotivosList = async () => {
		await api.get('/PAAdmControle/RC_GetMotivoLista')
			.then(res => {
				if (res.status === 200) setMotivoAlteracaoLista(res.data)
			})
			.catch(e => console.error(`Erro na requisição '/PAAdmControle/RC_GetMotivoLista - ${e}`));
	}

	const handleProcessing = async () => {
		const params = {
			...activeObj,

			DataRegistro: toDatetime(new Date()),
			IDUsuario: userLogin,
			IDAutorizador: userLogin,

			Area: formObj.Acao,
			IDMotivo: formObj.MotivoAlteracao.value,
			Observacao: formObj.Observacao || null,
		}

		await api.post('/PAAdmControle/RC_PostProcess', params)
			.then(res => {
				if (res.status === 200 && res.data[0].Retorno === 'PROCESSO CONCLUIDO') {
					handleCloseModal();
					handleFiltering();
					toast.success('Dados alterados com sucesso');
				} else {
					toast.warn(res.data[0].Retorno)
				}
			})
			.catch(e => {
				toast.error('Ocorreu um erro ao alterar os dados, por favor entre em contato com o suporte');
				return console.error(`Erro na requisição '/RC_PostProcess' - ${e}`);
			});
	}

	const handleProcessValidation = () => {
		const { Acao, MotivoAlteracao } = formObj;
		const errs = {}
		if (!MotivoAlteracao) errs.MotivoAlteracao = 'Selecione um motivo';
		if (!Acao) errs.Acao = 'Selecione uma ação';

		if (Object.keys(errs).length > 0) return setFormErrs(errs);

		if (activeObj.TransferidoEstoque === 'SIM') return setShowWarningModal(true);
		else return handleProcessing();
	}

	const handleValueMask = (field, value) => {
		if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
		setFilterObj({ ...filterObj, [field]: value });
	}

	const clearErrs = (str) => {
		setFormErrs({ ...formErrs, [str]: null });
	}

	useEffect(() => {
		fetchMotivosList();
	}, []);

	return (
		<div>
			<h3 className="m-3">ADM PA - Alteração Revisão/Checklist</h3>

			<article className={styles.articleMargim}>
				<Form className={styles.filterRow}>
					<Form.Group className={styles.formInput}>
						<Form.Label className={styles.formLabel}>NF Entrada</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.NFEntrada || ''}
							onChange={e => handleValueMask('NFEntrada', e.target.value, true)}
							placeholder={'Número da NF'}
						/>
					</Form.Group>
					<Form.Group className={styles.formInput}>
						<Form.Label className={styles.formLabel}>Produto</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.Produto || ''}
							onChange={e => handleValueMask('Produto', e.target.value, true)}
							placeholder={'Produto'}
						/>
					</Form.Group>
					<Form.Group className={styles.formInput}>
						<Form.Label className={styles.formLabel}>Cor Produto</Form.Label>
						<Form.Control
							type="text"
							value={filterObj.CorProduto || ''}
							onChange={e => handleValueMask('CorProduto', e.target.value, true)}
							placeholder={'Cor Produto'}
						/>
					</Form.Group>
					<Button
						className={styles.formBtn}
						variant="primary"
						type="button"
						onClick={handleFiltering}
					>
						Buscar
					</Button>
				</Form>
			</article>

			<article className={styles.articleGray}>
				<Table className="m-2" responsive>
					<thead>
						<tr>
							<th></th>
							<th className={styles.blockTitle}>TRANSFERIDO ESTOQUE</th>
							<th className={styles.blockTitle}>STATUS FINAL</th>
							<th className={styles.blockTitle}>STATUS CHECKLIST</th>
							<th className={styles.blockTitle}>STATUS REVISÃO</th>
							<th className={styles.blockTitle}>NF ENTRADA</th>
							<th className={styles.blockTitle}>SERIE NF</th>
							<th className={styles.blockTitle}>ORIGEM</th>
							<th className={styles.blockTitle}>PRODUTO</th>
							<th className={styles.blockTitle}>COR PRODUTO</th>
							<th className={styles.blockTitle}>QTDE. ENTRADA</th>
							<th className={styles.blockTitle}>FILIAL REVISÃO</th>
						</tr>
					</thead>
					<tbody>
						{loading ? <tr><td><Carregando></Carregando></td></tr> :
							mainData.map((el, index) => {
								return (
									<tr className={styles.tableLines} key={index}>
										<td>
											<i
												className={styles.iconCursor}
												onClick={() => handleShowModal(el)}
												style={{ 'cursor': 'pointer' }}
											>
												<MaterialIcon
													icon="change_circle"
													size={30}
													color='#0D6EFD'
												/>
											</i>
										</td>
										<td className={styles.tableLines}>{el.TransferidoEstoque}</td>
										<td className={styles.tableLines}>{el.StatusFinal}</td>
										<td className={styles.tableLines}>{el.StatusChecklist}</td>
										<td className={styles.tableLines}>{el.StatusRevisao}</td>
										<td className={styles.tableLines}>{el.NFEntrada}</td>
										<td className={styles.tableLines}>{el.SerieNF}</td>
										<td className={styles.tableLines}>{el.Origem}</td>
										<td className={styles.tableLines}>{el.Produto}</td>
										<td className={styles.tableLines}>{el.CorProduto}</td>
										<td className={styles.tableLines}>{el.QtdeEntrada}</td>
										<td className={styles.tableLines}>{el.FilialRevisao}</td>
									</tr>
								)
							})}
					</tbody>
				</Table>
			</article>

			<Modal
				show={showModal}
				onHide={handleCloseModal}
				backdrop="static"
				keyboard={false}
				size="lg"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>ADM PA - Alteração Revisão/Checklist</Modal.Title>
				</Modal.Header>
				<Modal.Body className="m-3">
					{/* Accordion de Identificação do Produto */}
					<Accordion defaultActiveKey={['0']} alwaysOpen>
						<Accordion.Item eventKey="0">
							<Accordion.Header>Dados do Produto</Accordion.Header>
							<Accordion.Body>
								<article className={styles.monitorInfo}>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>NF Entrada:</span>
											<span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Status final:</span>
											<span className={styles.fontBlue}>{activeObj.StatusFinal}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Série NF:</span>
											<span className={styles.fontBlue}>{activeObj.SerieNF}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Status checklist:</span>
											<span className={styles.fontBlue}>{activeObj.StatusChecklist}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Origem:</span>
											<span className={styles.fontBlue}>{activeObj.Origem}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Status revisão:</span>
											<span className={styles.fontBlue}>{activeObj.StatusRevisao}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Produto:</span>
											<span className={styles.fontBlue}>{activeObj.Produto}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Qtde entrada:</span>
											<span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
										</div>
									</div>
									<div className={styles.infoBlock}>
										<div className={styles.infoBlockColumm}>
											<span>Cor Produto:</span>
											<span className={styles.fontBlue}>{activeObj.CorProduto}</span>
										</div>
										<div className={styles.infoBlockColumm}>
											<span>Filial revisão:</span>
											<span className={styles.fontBlue}>{activeObj.FilialRevisao}</span>
										</div>
									</div>
								</article>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>

					<article>
						<Form className={styles.modalForm}>
							<div>
								<Form.Group controlId={'Acao'}>
									<Form.Label className={styles.formLabel}>1. Ação</Form.Label>
									<div className={styles.displayFlex}>
										<Form.Check
											type='radio'
											label={
												activeObj.StatusChecklist && activeObj.StatusChecklist.includes('PENDENTE') ?
													'Voltar Laudo Revisão' : 'Voltar Laudo Revisão - Atenção! Checklist consta como Finalizado! Para voltar o Laudo da Revisão é necessário voltar o do Checklist antes'
											}
											name={'Acao'}
											id={'REVISAO'}
											onChange={() => {
												setFormObj({ ...formObj, Acao: 'REVISAO' });
												clearErrs('Acao')
											}}
											isInvalid={formErrs.Acao}
											checked={formObj.Acao === 'REVISAO'}
											disabled={activeObj.StatusChecklist && !activeObj.StatusChecklist.includes('PENDENTE')}
										/>
										<Form.Check
											type='radio'
											label={'Voltar Laudo Checklist'}
											name={'Acao'}
											id={'CHECKLIST'}
											onChange={() => {
												setFormObj({ ...formObj, Acao: 'CHECKLIST' });
												clearErrs('Acao')
											}}
											isInvalid={formErrs.Acao}
											checked={formObj.Acao === 'CHECKLIST'}
											disabled={activeObj.StatusChecklist && activeObj.StatusChecklist.includes('PENDENTE')}
										/>
									</div>
								</Form.Group>
								<div className={formErrs.Acao ? styles.showFeedback : styles.hidden}>{formErrs.Acao}</div>
							</div>
							<Form.Group className={styles.formGroupModal} controlId={'MotivoAlteracao'} style={{ 'width': '50%' }}>
								<Form.Label className={styles.formLabel}>2. Motivo:</Form.Label>
								<Select
									placeholder={'Selecionar'}
									options={motivoAlteracaoLista}
									value={formObj.MotivoAlteracao || null}
									onFocus={() => clearErrs('MotivoAlteracao')}
									onChange={e => setFormObj({ ...formObj, MotivoAlteracao: e })}
									isClearable={true}
									escapeClearsValue={true}
									styles={{
										control: (provided) => (
											formErrs.MotivoAlteracao ? { ...provided, borderColor: '#dc3545' } : { ...provided, borderColor: 'hsl(0, 0%, 80%)' }
										)
									}}
								/>
								<div className={formErrs.MotivoAlteracao ? styles.showFeedback : styles.hidden}>{formErrs.MotivoAlteracao}</div>
							</Form.Group>
							<Form.Group className={styles.formGroupModal} style={{ 'width': '50%' }}>
								<Form.Label className={styles.formLabel}>
									3. Observação: <span className={styles.spanOpcional}>(Opcional)</span>
								</Form.Label>
								<Form.Control
									className={styles.formControl}
									placeholder={'Observação'}
									as={'textarea'}
									rows={4}
									value={formObj.Observacao}
									onChange={e => setFormObj({ ...formObj, Observacao: e.target.value })}
								>
								</Form.Control>
							</Form.Group>
						</Form>
					</article>
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					<Button variant="outline-danger" onClick={handleCloseModal}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={handleProcessValidation}
					>
						Processar
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showWarningModal}
				onHide={() => setShowWarningModal(false)}
				backdrop="static"
				keyboard={false}
				size="lg"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Atenção! A produção consta como liberado para o estoque.
						Confirma retorno das Peças para a Filial Revisão?
					</Modal.Title>
				</Modal.Header>
				<Modal.Footer className={styles.modalFooter}>
					<Button variant="outline-danger" onClick={() => setShowWarningModal(false)}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={handleProcessing}
					>
						Processar
					</Button>
				</Modal.Footer>
			</Modal>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</div>
	)
}

export default PAAdmAlteracaoRevisaoChecklist;