import React from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext';

const ModalFolha = () => {

    const { folhas, handleFolhas, gradeFolha, handleFinalizaMaterial, habilitaFinalizarFolha, showModalFolha, handleCloseModalFolha } = useLancamentoCad();

    return (
        <>
            <Modal
                show={showModalFolha}
                onHide={handleCloseModalFolha}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title>Insira a quantidade de folhas:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group className={styles.formFolhas}>
                        { gradeFolha && gradeFolha.map((item, index) => {
                            return (
                                <div key={index} className={styles.divFolhas}>
                                    <Form.Label className={styles.labelFolhas}>
                                        <strong>{item}</strong>
                                    </Form.Label>
                                    <Form.Control 
                                        className={styles.formControlTamanhoComprimento}
                                        type="text"
                                        value={folhas[item] || ''}
                                        onChange = {e => handleFolhas(e, item)} 
                                    />
                                </div>
                            )})
                        }
                    </Form.Group>              
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseModalFolha}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={habilitaFinalizarFolha ? "outline-success" : 'secondary'}
                        className={styles.modalBtn}
                        onClick={handleFinalizaMaterial}
                        disabled={!habilitaFinalizarFolha}
                        style={{width: '120px'}}
                    >
                        Finalizar Material
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )


}

export default ModalFolha;
