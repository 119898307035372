import React from "react";

const CadastroTeste = () => {
  return (
    <div>
      <h1>QualidadeMp</h1>
      <h2>CQ PROCESSO MATERIA PRIMA</h2>
      <h3>Cadastro Teste</h3>
    </div>
  );
};

export default CadastroTeste;
