import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Accordion, Table, FormGroup } from 'react-bootstrap';
import { toast } from "react-toastify";

import styles from "../../../styles/MonitorChecklistIdentificacao.module.scss";
import MaterialIcon from 'material-icons-react';

import ProdutoInfo from "../MonitorRevisao/componentes/ProdutoInfo";
import { retryApi }  from "../../../services/api";
import RadioButtonList from "../../Utils/RadioButtonList"
import CookiesService from "../../../services/cookies";

const MedidasProducao = (props) => {
  const [gradeTamanho, setGradeTamanho] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [formObj, setFormObj] = useState({});
	const [formErrs, setFormErrs] = useState({});
  const [medidasCadastradas, setMedidasCadastradas] = useState([]);

  const listRef = useRef();

  useEffect(() => {
    carregaFiltros();
  }, []);

  async function carregaFiltros() {
    try {
      const resListaGradeTamanho = await retryApi('get', "/PAMonitorCheckList/getListaGradeTamanho")
        setGradeTamanho(resListaGradeTamanho.data.map((item, index) => {
          return { value: item.IDGradeTamanho, label: item.DescricaoGrade, checked: false };
        }))
  
      const resListaMedidas = await retryApi('get', "/PAMonitorCheckList/getListaMedidas")
      setMedidas(resListaMedidas.data.map((item, index) => {
        return { value: item.IDMedida, label: item.DescricaoMedida, checked: false };
      }));
  
      const params = {
        NFEntrada: props.item.NFEntrada,
        SerieNF: props.item.SerieNF,
        Origem: props.item.Origem,
        Produto: props.item.Produto,
        CorProduto: props.item.CorProduto,
        QtdeEntrada: props.item.QtdeEntrada,
        FilialRevisao: props.item.FilialRevisao,
        user: CookiesService.getUserLogin(),
      }
  
      const resListaMedidasCadastradas = await retryApi('get', "/PAMonitorCheckList/getMedidasCadastradas", { params });
      setMedidasCadastradas(resListaMedidasCadastradas.data);
    } catch (e) {
      console.error(e);
    }
  }

  const handleIncludeGrade = async () => {
    try {
      const errs = handleIncludeGradeValidation();
      if (Object.keys(errs).length > 0) return setFormErrs(errs);
      setFormErrs({});
      const params = {
        ...props.item,
        IDArea: 3,
  
        IDGrade: formObj.Grade.value,
        DescricaoGrade: formObj.Grade.label,
        IDMedida: formObj.Medida.value,
        MedidaProducao: parseFloat(formObj.MedidaProducao),
        MedidaPiloto: formObj.MedidaPiloto ? parseFloat(formObj.MedidaPiloto) : 0,
        Tolerancia: formObj.Tolerancia ? parseFloat(formObj.Tolerancia) : 0
      }
  
      const res = await retryApi('post', '/PAMonitorCheckList/GravaMedidas', params);
      if (res.status === 200 && res.data[0].mensagem === 1) {
        toast.success('Grade inserida com sucesso');
        listRef.current.clearChecks();
        carregaFiltros();
        setFormObj({});
      } else if (res.status === 200 && res.data[0].mensagem === 0) {
        toast.warn('Grade já inserida, por favor altere o tamanho ou a medida');
      }
    } catch (e) {
      toast.error('Ocorreu um erro ao se inserir a nova grade, cheque sua conexão com a internet e/ou entre em contato com o suporte');
      console.error(e);
    }
	}

  const handleIncludeGradeValidation = () => {
		const { MedidaProducao, Grade, Medida } = formObj;
		const errs = {}
		if (!Grade) errs.Grade = 'Selecione uma grade';
		if (!Medida) errs.Medida = 'Selecione uma medida';
		if (!MedidaProducao || MedidaProducao <= 0) errs.MedidaProducao = 'A medida piloto deve ser maior que zero';
		return errs;
	}

  const handleExcluir = async (e, item) => {
    e.preventDefault();
    try {
      const params = {
        idArea: '3',
        NFEntrada: props.item.NFEntrada,
        SerieNF: props.item.SerieNF,
        Origem: props.item.Origem,
        Produto: props.item.Produto,
        OrdemProducao: props.item.OrdemProducao,
        OrdemServico: props.item.OrdemServico,
        CorProduto: props.item.CorProduto,
        QtdeEntrada: props.item.QtdeEntrada,
        FilialRevisao: props.item.FilialRevisao,
        user: CookiesService.getUserLogin(),
  
        DecricaoMedida: item.Medida,
        DescricaoGrade: item.DescricaoGrade
      }

      await retryApi('post', "/PAMonitorCheckList/ExcluiMedidas", params);
      toast.success('Medida excluída com sucesso!');
      carregaFiltros();
    } catch (e) {
      console.error(e);
      toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
    }
  }

  const handleValueMask = (field, value) => {
		if (value && value !== undefined && value !== '') value = `${value.replace(/[^0-9]*/g, '')}`;
		else if ((!value || value === '')) value = 0;

		if (field !== 'Tolerancia') setFormObj({ ...formObj, [field]: (parseFloat(value) / 100).toFixed(2) });
		else setFormObj({ ...formObj, [field]: (parseFloat(value) / 1000).toFixed(3) });
	}

  const clearErrs = (str) => {
		setFormErrs({ ...formErrs, [str]: null });
	}

  return (
    <>
      <div>
        <h2>Cadastrar / Consultar Medidas</h2>

        <article style={{ margin: "10px", marginTop: "20px"}}>
          {/*Identificação do produto */}
          <Accordion style={{marginTop: "10px"}}>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Identificação do Produto</Accordion.Header>
              <Accordion.Body>
                <ProdutoInfo item={props.item}></ProdutoInfo>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </article>

        <article className={styles.articleGrayFlex}>
          <div className={styles.articleFlexRow}>
            <div
              className={styles.radioBtnListGrade}
              style={{border: formErrs.Grade ? '2px solid #dc3545' : '2px solid white'}}
            >
              <div>
                <h6>GRADE</h6>
                {gradeTamanho.length > 0 ?
                  <RadioButtonList options={gradeTamanho}
                    ref={listRef}
                    changeList={e => { setFormObj({ ...formObj, Grade: e }); clearErrs('Grade') }}
                  ></RadioButtonList>
                  : 'carregando'
                }
              </div>
            </div>

            <div
              className={styles.radioBtnListGrade}
              style={{border: formErrs.Medida ? '2px solid #dc3545' : '2px solid white'}}
            >
              <h6>MEDIDAS</h6>
              <div>
                {medidas.length > 0 ?
                  <RadioButtonList options={medidas}
                    ref={listRef}
                    changeList={e => { setFormObj({ ...formObj, Medida: e }); clearErrs('Medida') }}
                  ></RadioButtonList>
                  : 'carregando'
                }
              </div>
            </div>
          </div>

          <div className={styles.articleFlexRow}>
            <Form className={styles.formModal}>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Medidas Piloto (cm):</Form.Label>
                <Form.Control
                  type="text"
                  value={formObj.MedidaPiloto || ''}
                  onClick={() => clearErrs('MedidaPiloto')}
                  onChange={e => handleValueMask('MedidaPiloto', e.target.value, false)}
                  placeholder={'0.00'}
                  isInvalid={formErrs.MedidaPiloto}
                />
              </FormGroup>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Tolerância (cm):</Form.Label>
                <Form.Control
                  type="text"
                  value={formObj.Tolerancia || ''}
                  onClick={() => clearErrs('Tolerancia')}
                  onChange={e => handleValueMask('Tolerancia', e.target.value, false)}
                  placeholder={'0.000'}
                  isInvalid={formErrs.Tolerancia}
                />
              </FormGroup>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Medidas Produção (cm):</Form.Label>
                <Form.Control
                  type="text"
                  value={formObj.MedidaProducao || ''}
                  onClick={() => clearErrs('MedidaProducao')}
                  onChange={e => handleValueMask('MedidaProducao', e.target.value, false)}
                  placeholder={'0.00'}
                  isInvalid={formErrs.MedidaProducao}
                />
              </FormGroup>

              <Button
                variant="primary"
                type="button"
                onClick={() => handleIncludeGrade()}
              >
                Incluir Medida
              </Button>
            </Form>
          </div>
        </article>

        <article className="m-2">
          <Table className={styles.tableStyle} responsive>
            <thead>
              <tr>
                <th></th>
                <th className={styles.blockTitle}>Grade</th>
                <th className={styles.blockTitle}>Descrição Medida</th>
                <th className={styles.blockTitle}>Medida Peça</th>
                <th className={styles.blockTitle}>Medida Ficha Técnica</th>
                <th className={styles.blockTitle}>Tolerância</th>
              </tr>
            </thead>
            <tbody>
              {medidasCadastradas?.map((item, index) => {
                return (
                  <tr className={styles.tableLines} key={index}>
                    <td
                      onClick={(e) => { window.confirm('Deseja realmente excluir a medida?',) && handleExcluir(e, item); }}
                    >
                      <i className={styles.icon}>
                        <MaterialIcon
                          icon="delete"
                          size={20}
                          color='#BB271A'
                        />
                      </i>
                    </td>
                    <td className={styles.tableLines}>{item.DescricaoGrade}</td>
                    <td className={styles.tableLines}>{item.Medida}</td>
                    <td className={styles.tableLines}>{parseFloat(item.MedidaProducao).toFixed(2)}</td>
                    <td className={styles.tableLines}>{parseFloat(item.TamanhoFichaTecnica).toFixed(2)}</td>
                    <td className={styles.tableLines}>{parseFloat(item.Tolerancia).toFixed(3)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </article>
      </div>
    </>
  );

}

export default MedidasProducao;