import React, { useState } from "react";
import { Button, Table, Form } from 'react-bootstrap';
import "react-toastify/dist/ReactToastify.min.css";

import styles from "../../../styles/AdmImpressaoEtiquetas.module.scss";

import ImprimirEtiquetas from "../../ImprimirEtiquetas";
import Carregando from "../../Carregando"

import { api }  from "../../../services/api";

function ImpressaoEtiquetas() {
    const [codigo, setCodigo] = useState("");
    const [material, setMaterial] = useState("");
    const [corMaterial, setCorMaterial] = useState("");
    const [admEtiqueta, setAdmEtiqueta] = useState([]);
    const [etiquetaParaImprimir, setEtiquetaParaImprimir] = useState([]);
    const [carregando, setCarregando] = useState(false);
     
    async function handleForm() {
        const params = {
            Codigo: codigo, 
            Material: material, 
            CorMaterial: corMaterial 
        };

        setCarregando(true);
        const listaAdmEtiquetas = await api.get(
            "CatalogoADM/ADMetiqueta",
            { params }
        );
          // ------------------------------------------- //
          // --- LIMITANDO OS 100 PRIMEIROS RESULTADOS - // 
          // --- ATÉ A IMPLEMENTAÇÃO DE PAGINAÇÃO ------ //
          // ------------------------------------------- //              
        setAdmEtiqueta(listaAdmEtiquetas.data.slice(0, 100));
        setCarregando(false);
    }

    function handleSelect(locais) {

        let etiquetaAImprimir = {
            
            ID_CADASTRO: locais.idCadastro,
            MATERIAL: locais.material,
            COR_MATERIAL: locais.corMaterial,
            COD_CLIFOR: locais.codClifor,
            LOCALIZACAO: locais.idLocalizacao,
            tipo_material: locais.tipoMaterial,
            DATA_CADASTRO: locais.datacadastro,
            ARARA: locais.idArara,
            ANDAR: locais.andar,
            LADO: locais.lado,
            SALA_AMOSTRA: locais.SALA_AMOSTRA,
            FORNECEDOR: locais.NOME_CLIFOR,
            DESC_MATERIAL: locais.DESC_MATERIAL,
            DESC_COR_MATERIAL: locais.DESC_COR_MATERIAL,
            COLECAO: locais.colecao,
            USUARIO_CADASTRO: locais.idusuario,
            TEXTO: "CQ - CATALOGO MATERIA PRIMA",
        };

        let listEtiquetas = etiquetaParaImprimir
        listEtiquetas.push(etiquetaAImprimir);


        setEtiquetaParaImprimir(listEtiquetas);
    }

    return (
        <body>
            <article>
                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Código</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Código"
                            title="Este campo é obrigatório"
                            value={codigo}
                            onChange={(event) => setCodigo(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Material</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Material"
                            title="Este campo é obrigatório"
                            value={material}
                            onChange={(event) => setMaterial(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label>Cor Material</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Cor Material"
                            title="Este campo é obrigatório"
                            value={corMaterial}
                            onChange={(event) => setCorMaterial(event.target.value)}
                        />
                    </Form.Group>
                    <Button 
                        className={styles.formGroup} 
                        variant="primary"
                        type="button" 
                        onClick={handleForm}
                    >
                        Buscar
                    </Button>
                </Form>
            </article>

            <article className={styles.articleGray}>
                <div className="p-2 pb-0">
                    <h5>Etiquetas a serem impressas</h5>
                </div>
                {carregando ?
                    <Carregando></Carregando>
                : 
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>COR</th>
                            <th className={styles.blockTitle}>COD. FORN.</th>
                            <th className={styles.blockTitle}>LOCALIZACAO</th>
                            <th className={styles.blockTitle}>DATA CADASTRO</th>
                            <th className={styles.blockTitle}>ID USUARIO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admEtiqueta.map((locais,index) => {
                            return (
                                <tr>
                                    <td className={styles.tableLines}>
                                    <Form>
                                        <Form.Check key={index}
                                            type='checkbox'
                                            //id={'linha 1'}
                                            onClick={() => handleSelect(locais)}
                                        />
                                    </Form>
                                    </td>
                                    <td className={styles.tableLines}>{locais.material}</td>
                                    <td className={styles.tableLines}>{locais.corMaterial}</td>
                                    <td className={styles.tableLines}>{locais.codClifor}</td>
                                    <td className={styles.tableLines}>{locais.idLocalizacao}</td>
                                    <td className={styles.tableLines}>{locais.datacadastro}</td>
                                    <td className={styles.tableLines}>{locais.idusuario}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                }
            </article>

            <article>
                <div className="p-2 pb-0">
                    <h5>Impressora</h5>
                </div>
                <Table className="m-2" >
                    <tbody>
                        172.16.180.255\Generic
                    </tbody>
                </Table>
                <div className="m-2 pt-2">
                    <ImprimirEtiquetas etiquetas={etiquetaParaImprimir}/>
                </div>
            </article>
        </body>
    );
}

export default ImpressaoEtiquetas;
 