import React from "react";
import AprovarAmostraRedirect from "../components/AprovacaoEstilo/AprovarAmostra/index";

const AprovarAmostra = () => {
  return (
    <div className="m-4">
      <h3>Aprovação de Amostra</h3>
      <AprovarAmostraRedirect />
    </div>  
  );
};

export default AprovarAmostra;