import React from "react";
import { Button, Modal, Table, Form, Row, Col } from 'react-bootstrap';
import { useFGTesteDeBase } from "../../../../contexts/FarmGlobal/TesteDeBaseContext.js";
import styles from '../../../../styles/FarmGlobal/TesteDeBase.module.scss';
import MaterialIcon from 'material-icons-react';
import ModalConfirmacao from './ModalConfirmacao'


const ModalTesteDeBaseAgendar = () => {
    const {
        showCad,
        setShowCad,
        handleSave,
        materialSelecionado,
        setMaterialSelecionado,
        filterObj,
        setFilterObj,
        handleSearch,
        handleSort,
        getSortIcon,
        mainData,
        handleShowPDF,
        removeAccents,
        setShowConfirmacao,
    } = useFGTesteDeBase();
    

    return (
        <>
       
            <Modal
                show={showCad}
                onHide={() => setShowCad(false)}
                backdrop="static"
                keyboard={false}
                centered
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>Solicitação de um Novo Teste de Base</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <article>
                <Form className={styles.formWrapper}>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Desc. Material  <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Material'}
                            className={styles.formControl}
                            value={removeAccents(filterObj.descricao) || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, descricao: event.target.value })}
                            onKeyPress={(e) => {
                                             return removeAccents(e.key);
                                        }}
                        />
                    </Form.Group>
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Tipo</Form.Label>
                        <Form.Control
                            as="select"
                            className={styles.formControl}
                            value={filterObj.tipo || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, tipo: event.target.value })}
                        >
                            <option value="">Todos</option>
                            <option value="Tinto">Tinto</option>
                            <option value="Estampado Cilindro">Estampado Cilindro</option>
                            <option value="Estampado Digital">Estampado Digital</option>
                        </Form.Control>
                    </Form.Group>
                    {/* <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Ref. Linx</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Ref. Linx'}
                            className={styles.formControl}
                            value={filterObj.refLinx || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, refLinx: event.target.value })}
                        />
                    </Form.Group> */}
                    <Form.Group className={styles.filterInput}>
                        <Form.Label className={styles.formLabel}>Gramatura(g/m²)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Gramatura(g/m²)'}
                            className={styles.formControl}
                            value={filterObj.gramatura || ''}
                            onChange={(event) => setFilterObj({ ...filterObj, gramatura: event.target.value })}
                        />
                    </Form.Group>
                    <Button
                        type="button"
                        variant="primary"
                        className={styles.filterBtn}
                        onClick={handleSearch}
                    >
                        Buscar Bases
                    </Button>
                </Form>
                </article>
                <article className={styles.articleGray}>
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                        <th></th>
                        <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Arquivo')}
                            >
                                FICHA TÉCNICA
                                <MaterialIcon icon={getSortIcon('Arquivo')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Descricao')}
                            >
                                DESCRIÇÃO MATERIAL
                                <MaterialIcon icon={getSortIcon('Descricao')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Tipo')}
                            >
                                TIPO
                                <MaterialIcon icon={getSortIcon('Tipo')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('FornecedorMp')}
                            >
                                FORNECEDOR MP
                                <MaterialIcon icon={getSortIcon('FornecedorMp')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('FornecedorPa')}
                            >
                                FORNECEDOR PA
                                <MaterialIcon icon={getSortIcon('FornecedorPa')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('Gramatura')}
                            >
                                GRAMATURA
                                <MaterialIcon icon={getSortIcon('Gramatura')} size={16} />
                            </th>
                            <th
                                className={styles.blockTitle}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSort('ChaveTipo')}
                            >
                                CÓDIGO TECIDO
                                <MaterialIcon icon={getSortIcon('ChaveTipo')} size={16} />
                            </th>
                           
                            
                        </tr>
                    </thead>
                    <tbody>
                        {mainData.map((item, index) => (
                            <tr key={index}>
                                    <td className={styles.tableLines}>
                                <Form.Check 
                                    type="radio" 
                                    name="materialSelect" 
                                    value={item}
                                    checked={item === materialSelecionado}
                                    onChange={() => setMaterialSelecionado(item)}
                                />
                            </td>
                            <td className={styles.tableLines}>
                                    {item.Arquivo ? (
                                        <i onClick={() => handleShowPDF(item.Arquivo)}>
                                            <MaterialIcon
                                                icon="description"
                                                size={30}
                                                color='#0D6EFD'
                                            />
                                        </i>
                                    ) : ''}
                                </td>
                                <td className={styles.tableLines}>{item.Descricao}</td>                                
                                <td className={styles.tableLines}>{item.Tipo}</td>
                                <td className={styles.tableLines}>{item.FornecedorMp}</td>
                                <td className={styles.tableLines}>{item.FornecedorPa}</td>
                                <td className={styles.tableLines}>{item.Gramatura}</td>
                                <td className={styles.tableLines}>{item.ChaveTipo}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </article>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCad(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={()=>{setShowConfirmacao(true);}} disabled={!materialSelecionado}>Enviar Solicitação</Button>
                </Modal.Footer>
            </Modal>
            
            <ModalConfirmacao />
        </>
    );
}

export default ModalTesteDeBaseAgendar;
