import React, { useState } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import styles from '../../../../styles/FarmGlobal/TesteDeBase.module.scss';
import { useFGTesteDeBase } from "../../../../contexts/FarmGlobal/TesteDeBaseContext.js";

const ModalConfirmacaoRecebimento = () => {
    const { showConfirmacaoRecebimento, handleSaveRecebimento, setShowConfirmacaoRecebimento, testeSelecionado, showMotivoRecusaModal, setShowMotivoRecusaModal
    ,motivoRecusa, setMotivoRecusa, handleReprovarRecebimento, handleEnviarRecusa } = useFGTesteDeBase();

    return (
        <>
            {/* Modal de Confirmação de Recebimento */}
            <Modal
                show={showConfirmacaoRecebimento}
                onHide={() => { setShowConfirmacaoRecebimento(false); }}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Registro de Recebimento</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <span>
                        Tem certeza que deseja registrar o recebimento do teste do material <br /><strong>{testeSelecionado?.Descricao}</strong>?
                    </span>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="success"
                        className={styles.modalBtn}
                        onClick={() => { handleSaveRecebimento(45,''); }} 
                    >
                        Aprovar Recebimento
                    </Button>
                    <Button
                        variant="danger"
                        className={styles.modalBtn}
                        onClick={handleReprovarRecebimento}
                    >
                        Reprovar Recebimento
                    </Button>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={() => { setShowConfirmacaoRecebimento(false); }}
                    >
                        Voltar
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal
                show={showMotivoRecusaModal}
                onHide={() => { setShowMotivoRecusaModal(false); }}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Informe o motivo da Recusa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Motivo da Recusa</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            maxLength={4000}
                            placeholder="Informe o motivo pelo qual está reprovando o recebimento"
                            value={motivoRecusa}
                            onChange={(e) => setMotivoRecusa(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowMotivoRecusaModal(false)}>Voltar</Button>
                    <Button variant="danger" onClick={handleEnviarRecusa}>Enviar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalConfirmacaoRecebimento;
