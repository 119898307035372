import { usePAReversa } from "../../../contexts/PAReversaContext";
import { Modal, Button } from "react-bootstrap";

const PAReversaModalBusca = () => {
  const { showModalBusca, setShowModalBusca, fetchReversaGrid } = usePAReversa();

  return (
    <Modal show={showModalBusca.show} centered>
      <Modal.Body>
        <b>Ao prosseguir com a busca todos os defeitos e direcionamentos inseridos serão perdidos.</b>
        <br />
        <b>Tem certeza que deseja continuar?</b>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center', gap: '20px' }}>
        <Button style={{ width: '45%', margin: '0px' }} variant={'success'} onClick={() => setShowModalBusca({ show: false, filtro: {} })}>
          Voltar
        </Button>
        <Button style={{ width: '45%', margin: '0px' }} variant={'danger'} onClick={() => fetchReversaGrid(showModalBusca.filtro)}>
          Ignorar e continuar com a busca
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PAReversaModalBusca;