import React from "react";
import { Button, Modal, Accordion } from 'react-bootstrap';
import styles from '../../../styles/MP/AnaliseRapportReprovadoCad.module.scss';

const ModalCad = ({ showAnalisar, handleCloseAnalisar, handleShowConfirmacao, dataProduct, pedidoSelecionado }) => {
    const temImpacto = 1;
    const naoTemImpacto = 0;

    return (
        <Modal
            show={showAnalisar}
            onHide={handleCloseAnalisar}
            backdrop="static"
            keyboard={false}
            centered
            size='lg'
        >
            <Modal.Header>
                <Modal.Title>Análise Rapport Reprovado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <article className={styles.articleSpacing}>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" disabled>
                        <Accordion.Header>Dados do Pedido</Accordion.Header>
                        <Accordion.Body className={styles.infoBlockDisplay}>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockColumm}>
                                <span>Pedido:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.Pedido}</span>
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Material:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.Material}</span>
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Descrição Material:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.DescricaoMaterial}</span>
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Cor Material:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.CorMaterial}</span>
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Descrição Cor:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.DescricaoCor}</span>
                            </div>
                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </article>
                <article className={styles.articleSpacing}>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Medidas</Accordion.Header>
                        <Accordion.Body className={styles.infoBlockDisplay}>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockColumm}>
                                <span>Largura Util:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.MenorLarguraUtil}</span>
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Medida Info:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.MedidaInfo}</span>
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Maior Rapport Encontrado:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.MaiorLarguraMedida}</span>
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Menor Rapport Encontrado:</span>
                                <span className={styles.fontBlue}>{pedidoSelecionado.MenorLarguraMedida}</span>
                            </div>
                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </article>
                <article className={styles.articleSpacing}>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Produtos com Reservas</Accordion.Header>
                        <Accordion.Body className={styles.infoBlockDisplay}>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockColumm}>
                                <span>Produto:</span>
                                { dataProduct.length > 0 && 
                                    dataProduct.map((item, index) => {
                                        return (
                                            <span key={index} className={styles.fontBlue}>{item.PRODUTO}</span>
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.infoBlockColumm}>
                                <span>Descrição Produto:</span>
                                { dataProduct.length > 0 && 
                                    dataProduct.map((item, index) => {
                                        return (
                                            <span key={index} className={styles.fontBlue}>{item.DESC_PRODUTO}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </article>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <span><strong>Podem ser mantidas, as localizações do encaixe?</strong></span>
                <Button
                    variant="secondary"
                    className={styles.modalBtn}
                    onClick={() => handleShowConfirmacao(temImpacto)}
                >
                    Não
                </Button>
                <Button
                    variant="success"
                    className={styles.modalBtn}
                    onClick={() => handleShowConfirmacao(naoTemImpacto)}
                >
                    Sim
                </Button>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseAnalisar}
                >
                    Voltar
                </Button>
            </Modal.Footer>
        </Modal>
    ) 
}

export default ModalCad;
