import React from "react";
import SalaAmostra from "../components/SalaAmostras/index";

import styles from "../styles/SalaAmostras.module.scss";

export default function CadastroSalaAmostras() {
  return (
    <div className="p-2">
      <div>
        <h3>Sala de Amostras MP</h3>
      </div>
      <SalaAmostra />
    </div>
  );
}
