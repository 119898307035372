import React, { useState } from "react";
import { Button, Modal, Table, Accordion } from 'react-bootstrap';
import styles from '../../../../styles/ADM/Desconto/EditarPendenciar.module.scss';
import ModalStatus from "./ModalStatus";

const ModalEditar = ({ showEditar, setShowEditar, handleCloseEditar, linhaSelecionada, setLinhaSelecionada, motivoStatusData,
                       statusData, setStatusData, handleStatus }) => {

    const [showStatus, setShowStatus] = useState(false);
    const [motivoSelecionado, setMotivoSelecionado] = useState({});

    const handleShowStatus = (motivo) => {
        handleStatus()
        setMotivoSelecionado(motivo)
        setShowEditar(false)
        setShowStatus(true);
    }

    const handleCloseStatus = () => {
        setShowStatus(false)
        setShowEditar(true)
    }

    const handleResetEditar = () => {
        setShowEditar(false)
        setLinhaSelecionada({})
        setStatusData([])
        setShowStatus(false)
        setMotivoSelecionado({})
    }

    return (
        <>
            <Modal
                show={showEditar}
                onHide={handleCloseEditar}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
                >
                <Modal.Header>
                    <Modal.Title>Editar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <article>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Detalhes</Accordion.Header>
                                <Accordion.Body className={styles.infoBlockDisplay}>
                                <div className={styles.infoBlock}>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Nota Fiscal:</span>
                                        <span className={styles.fontBlue}>{linhaSelecionada.NfEntrada}</span>
                                    </div>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Material:</span>
                                        <span className={styles.fontBlue}>{linhaSelecionada.Material}</span>
                                    </div>
                                    <div className={styles.infoBlockColumm}>
                                        <span>Cor Material:</span>
                                        <span className={styles.fontBlue}>{linhaSelecionada.CorMaterial}</span>
                                    </div>
                                </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </article>
                    <Table className={styles.tableEditar} responsive>
                        <thead>
                            <tr>
                                <th className={styles.blockTitleEditar}>MOTIVO ENVIO</th>
                                <th className={styles.blockTitleEditar}>STATUS VALIDAÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                        { motivoStatusData.map((item,index) => {
                        return (
                            <tr key={index} onClick={() => handleShowStatus(item)}>
                                <td className={styles.tableLinesEditar}>{item.DescMotivoEnvio}</td>
                                <td className={styles.tableLinesEditar}>{item.DescStatusValidacao}</td>
                            </tr>
                        )})}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseEditar}
                    >
                        Voltar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalStatus 
                statusData={statusData}
                showStatus={showStatus}
                handleCloseStatus={handleCloseStatus}
                motivoSelecionado={motivoSelecionado}
                handleResetEditar={handleResetEditar}
            />
        </>
    )
}

export default ModalEditar;
