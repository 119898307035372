import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useEnfesto } from '../../../../contexts/Corte/ControleEnfesto/EnfestoContext'; 

import styles from '../../../../styles/Corte/ControleEnfesto/Enfesto.module.scss';

const ModalSetup = () => {

    const { handleCancelaSetup, showSetup, handleIniciaEnfesto, setupData } = useEnfesto();

    return (
        <>
            <Modal
                show={showSetup}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Setup Iniciado</Modal.Title>
                </Modal.Header>
                { setupData.length > 0 && 
                    <Modal.Body>
                        <div>
                            Ordem Produção: <strong>{setupData[0].OrdemProducao}</strong>
                        </div>
                        <div>
                            Ordem Serviço: <strong>{setupData[0].OrdemServico}</strong>
                        </div>
                        <div>
                            Material: <strong>{setupData[0].Material}</strong>
                        </div>
                        <div>
                            Cor Material: <strong>{setupData[0].CorMaterial}</strong>
                        </div>
                        <div>
                            Grade: <strong>{setupData[0].Grade}</strong>
                        </div>
                        <div>
                            Comprimento Risco: <strong>{setupData[0].ComprimentoRiscoM} m</strong>
                        </div>
                        <div>
                            Qtde Folhas Alocadas: <strong>{setupData[0].QtdeFolhasAlocada}</strong>
                        </div>
                        <div>
                            Tempo Enfesto: <strong>{setupData[0].TempoEnfesto}</strong>
                        </div>
                    </Modal.Body>
                }
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.setupBtn}
                        onClick={handleCancelaSetup}
                    >
                        Cancelar Setup
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.setupBtn}
                        onClick={handleIniciaEnfesto}
                    >
                        Iniciar Enfesto
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalSetup;
