import React, { useState } from "react";
import { Button, Form, FormGroup, Table, Modal } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import { toast, ToastContainer } from "react-toastify";

import styles from '../../styles/AdmMp/AlterarRevisao.module.scss';
import { api }  from "../../services/api";
import CookiesService from '../../services/cookies';

const AlterarRevisao = () => {
    const [showModal, setShowModal] = useState(false);
    const [filterObj, setFilterObj] = useState({});
    const [activeObj, setActiveObj] = useState({});
    const [mainData, setMainData] = useState([]);
    const userLogin = CookiesService.getUserLogin();

    const handleSearch = async (reload = null) => {
        if ((filterObj.NFEntrada && filterObj.NFEntrada !== '') || (filterObj.Material && filterObj.Material !== '') || (filterObj.CorMaterial && filterObj.CorMaterial !== '')) {
            let params = {...filterObj}
            
            await api.get('/MPAdm/GetMainData', {params})
                .then(res => {
                    if ((res.status === 200 && res.data.length > 0) || reload !== null) setMainData(res.data);
                    else if (res.status === 200 && res.data.length === 0) toast.warning('Não foram encontrados registros com esses parâmetros de filtro, tente novamente');
                }).catch(e => {
                    console.error(`Erro na requisição '/GetMainData' - ${e}`);
                    toast.error('Erro ao carregar os dados da página, por favor cheque sua conexão com a internet e/ou entre em contato com o suporte');
                });
        } else {
            return toast.warning('Por favor, preencha ao menos um campo de filtro.');
        }
    }

    const handleShowModal = (el) => {
        setShowModal(true);
        setActiveObj(el);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setActiveObj({});
    }

    const handleValueMask = (field, value) => {
        if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
        setFilterObj({...filterObj, [field]: value});
    }

    const handleProcessing = async () => {
        const params = {
            NFEntrada: activeObj.NFEntrada,
            SerieNF: activeObj.SerieNF,
            NomeClifor: activeObj.NomeClifor,
            Material: activeObj.Material,
            CorMaterial: activeObj.CorMaterial,
            Item: activeObj.Item,
            IdUsuario: userLogin
            // IdUsuario: userLogin || 'TESTE.HOMOLOG'
        }
        await api.get('/MPAdm/ExecAlteraRevisao', {params})
            .then(res => {
                if (res.status === 200 && res.data[0].MENSAGEM === 'PROCESSO EXECUTADO COM SUCESSO') {
                    toast.success('Dados alterados com sucesso!');
                    handleCloseModal();
                    handleSearch(params);
                } else {
                    toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
                }
            }).catch(e => {
                console.error(`Erro na requisição '/ExecAlteraRevisao' - ${e}`);
                toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`);
            });
    }

    return (
        <div className="p-3">
            <h3>ADM MP - Alterar Revisão</h3>
            <article className={styles.articleMargim}>
                <Form className={styles.form}>
                    <FormGroup className={styles.formGroup} controlId={'NFEntrada'}>
                        <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
                        <Form.Control
                            type="text"
                            value={filterObj.NFEntrada || ''}
                            onChange={e => handleValueMask('NFEntrada', e.target.value)}
                            placeholder={'Nota fiscal'}
                        />
                    </FormGroup>
                    <FormGroup className={styles.formGroup} controlId={'Material'}>
                        <Form.Label className={styles.formLabel}>Material</Form.Label>
                        <Form.Control
                            type="text"
                            value={filterObj.Material || ''}
                            onChange={e => handleValueMask('Material', e.target.value)}
                            placeholder={'Material'}
                        />
                    </FormGroup>
                    <FormGroup className={styles.formGroup} controlId={'CorMaterial'}>
                        <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                        <Form.Control
                            type="text"
                            value={filterObj.CorMaterial || ''}
                            onChange={e => handleValueMask('CorMaterial', e.target.value)}
                            placeholder={'Cor material'}
                        />
                    </FormGroup>
                    <Button 
                        type="button" 
                        variant="primary"
                        className={styles.formGroup} 
                        onClick={() => handleSearch(null)}
                    >
                        Buscar
                    </Button>
                </Form>
            </article>

            <article className={styles.articleGray}>
                <Table className="m-2" responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}>STATUS REVISÃO</th>
                            <th className={styles.blockTitle}>STATUS QUALIDADE</th>                            
                            <th className={styles.blockTitle}>NF ENTRADA</th>
                            <th className={styles.blockTitle}>SÉRIE NF</th>
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>COR MATERIAL</th>
                            <th className={styles.blockTitle}>QTDE ENTRADA</th>
                            <th className={styles.blockTitle}>DATA REVISÃO</th>
                            <th className={styles.blockTitle}>REVISOR</th>  
                            <th className={styles.blockTitle}>ITEM</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mainData.map((el, index) => {
                            return (
                                <tr key={index}>
                                    <td className={styles.tableLines}>
                                        <i onClick={() => handleShowModal(el)} style={{cursor: 'pointer'}}>
                                            <MaterialIcon 
                                                icon="undo" 
                                                size={20} 
                                                color='#0D6EFD'
                                            />
                                        </i>
                                    </td>
                                    <td className={styles.tableLines}>{el.StatusRevisao}</td>
                                    <td className={styles.tableLines}>{el.StatusQualidade}</td>
                                    <td className={styles.tableLines}>{el.NFEntrada}</td>
                                    <td className={styles.tableLines}>{el.SerieNF}</td>
                                    <td className={styles.tableLines}>{el.NomeClifor}</td>
                                    <td className={styles.tableLines}>{el.Material}</td>
                                    <td className={styles.tableLines}>{el.CorMaterial}</td>
                                    <td className={styles.tableLines}>{el.QtdeEntrada}</td>
                                    <td className={styles.tableLines}>{el.DataRevisao}</td>
                                    <td className={styles.tableLines}>{el.Revisor}</td>
                                    <td className={styles.tableLines}>{el.Item}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                />
            </article>

            <Modal
                show={showModal} 
                onHide={() => setShowModal(false)}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.modalBody}>
                        <p>Atenção! Deseja voltar o status da Nota Fiscal selecionada?</p>
                        <p>Essa ação irá excluir todos os dados lançados anteriormente SEM ALTERAR A PERDA DE METRAGEM NA PEÇA, CASO TENHA SIDO REALIZADA.</p> 
                    </div>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button variant="danger" onClick={() => setShowModal(false)}>
                        Não
                    </Button>
                    <Button variant="primary" onClick={() => handleProcessing(filterObj)}>
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AlterarRevisao;