import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import { useMisc } from '../../../contexts/MiscContext';
import PARevisaoSegundaQualidadeNVMain from './pa_revisao_sq_nv_main';
import CookiesService from '../../../services/cookies';

const PARevisaoSegundaQualidadeNVContext = createContext();
export const usePARevisaoSegundaQualidadeNV = () => useContext(PARevisaoSegundaQualidadeNVContext);

export const PARevisaoSegundaQualidadeNVProvider = () => {
  const { setShowLoading } = useMisc();
  const [listRevisao, setListRevisao] = useState([]);
  const [listAjuste, setListAjuste] = useState([]);
  const [listaDefeitos, setListaDefeitos] = useState([]);
  const [listaDirecionamentos, setListaDirecionamentos] = useState([]);
  const [modalRevisao, setModalRevisao] = useState(false);
  const [modalAjuste, setModalAjuste] = useState(false);
  const [analiseAjuste, setAnaliseAjuste] = useState({});
  const [direcionamentosAjuste, setDirecionamentosAjuste] = useState([]);
  const [modalDirecionamento, setModalDirecionamento] = useState(false);
  const [referenciaSelecionada, setReferenciaSelecionada] = useState({});
  const [gradeRevisao, setGradeRevisao] = useState([]);
  const [direcionamentoRevisao, setDirecionamentoRevisao] = useState([]);
  const [modalFinalizacao, setModalFinalizacao] = useState(false);

  useEffect(() => {
    fetchRevisaoSQNVInicializacao();
  }, []);

  const resetData = () => {
    setListRevisao([]);
    setModalRevisao(false);
    setModalAjuste(false)
    setAnaliseAjuste({})
    setDirecionamentosAjuste([])
    setReferenciaSelecionada({});
    setGradeRevisao([]);
    setDirecionamentoRevisao([]);
    setModalFinalizacao(false);
  }

  const fetchRevisaoSQNVGrid = async (filter) => {
    try {
      setShowLoading(true);
      const params = {
        ...filter,
        produto: filter.produto_AN,
        cor_produto: filter.cor_produto_AN
      }
      const res = await api.get('/pa-revisao-sq-nv/revisao-sq-nv-fetch-grid', { params });
      if (res.data.length === 0) return toast.warning(`Não foram encontradas entradas com as informações fornecidas.`);
      setListRevisao(res.data);
    } catch (e) {
      toast.error('Ocorreu um erro ao preencher a listagem\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte');
    } finally {
      setShowLoading(false);
    }
  }

  const fetchAjusteSQGrid = async (filter) => {
    try {
      setShowLoading(true);
      const params = {
        ...filter,
        produto: filter.produto_AN,
        cor_produto: filter.cor_produto_AN
      }
      const res = await api.get('/pa-ajustar-sq/ajuste-sq-fetch-grid', { params });
      if (res.data.length === 0) return toast.warning(`Não foram encontradas entradas com análises para as informações fornecidas.`);
      setListAjuste(res.data);
    } catch (e) {
      toast.error('Ocorreu um erro ao preencher a listagem\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte');
    } finally {
      setShowLoading(false);
    }
  }

  const fetchRevisaoSQNVInicializacao = async () => {
    try {
      setShowLoading(true);
      const res = await api.get('/pa-revisao-sq-nv/revisao-sq-nv-inicializacao');
      setListaDefeitos(res.data.resListaDefeitos);
      setListaDirecionamentos(res.data.resListaDirecionamentos);
    } catch (e) {
      toast.error('Ocorreu um erro ao inicializar a interface.\nPor favor cheque sua conexão com a internet ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const iniciarRevisaoSQNV = async (ref) => {
    try {
      setShowLoading(true);
      const params = { id_entrada_fiscal: ref.id_entrada_fiscal }
      const resGrade = await api.get('/pa-revisao-sq-nv/revisao-sq-nv-fetch-grade-by-id', { params });
      if (resGrade.length === 0) return toast.warn('A grade da referência selecionada parece estar vazia.\nPor favor entre em contato com o suporte.'); 
      
      setGradeRevisao(resGrade.data)
      setReferenciaSelecionada({ ...ref });
      setModalRevisao(true);
    } catch (e) {
      toast.error('Houve um problema para carregar a grade da referência selecionada.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally  {
      setShowLoading(false);
    }
  }

  const iniciarAjusteSQ = async (ref) => {
    try {
      setShowLoading(true);
      const params = { id_entrada_fiscal: ref.id_entrada_fiscal }
      const res = await api.get('/pa-ajustar-sq/ajuste-sq-iniciar-ajuste', { params });
      const { resGrade, resAnalise, resDirecionamentos } = res.data;
      if (resAnalise.length === 0) return toast.warn('Não foram encontradas análises para essa referência.\nPor favor entre em contato com o suporte.');
      if (resGrade.length === 0) return toast.warn('A grade da referência selecionada parece estar vazia.\nPor favor entre em contato com o suporte.');
      
      setGradeRevisao(resGrade);
      setAnaliseAjuste(resAnalise[0]);
      setDirecionamentosAjuste(resDirecionamentos);
      setReferenciaSelecionada({ ...ref });
      setModalAjuste(true);
    } catch (e) {
      toast.error('Houve um problema para carregar a grade da referência selecionada.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally  {
      setShowLoading(false);
    }
  }

  const checarSePossuiDefeitos = (id_entrada_fiscal_grade) => {
    const possuiDefeitos = direcionamentoRevisao.some(obj => {
      return obj.grade.id_entrada_fiscal_grade === id_entrada_fiscal_grade;
    });
    return possuiDefeitos;
  }

  const validarDirecionamentoSQNV = () => {
    for (let i = 0; i < gradeRevisao.length; i++) {
      const item = gradeRevisao[i];
      if (!item.sem_defeitos && !checarSePossuiDefeitos(item.id_entrada_fiscal_grade)) return false; 
    }
    return true;
  }

  const abrirModalProcessamento = () => {
    if (!validarDirecionamentoSQNV()) return toast.warning('Por favor confirme que as grade sem direcionamentos não possuem defeitos.');
    setModalFinalizacao(modalAjuste ? 'Ajuste' : 'Default');
  }

  const processarRevisaoSQNV = async () => {
    try {
      setShowLoading(true);
      const params = {
        id_entrada_fiscal: referenciaSelecionada.id_entrada_fiscal,
        gradeRevisao,
        direcionamentoRevisao,
        usuario_responsavel: CookiesService.getUserLogin(),
        propriedades_analise: {
          qtde_pecas_analisadas: referenciaSelecionada.qtde_entrada
        }
      }

      await api.post('/pa-revisao-sq-nv/revisao-sq-nv-processar', params);
      toast.success('Processamento completado com sucesso!');
      resetData();
    } catch (e) {
      toast.error('Houve um problema durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const processarAjusteSQNV = async () => {
    try {
      setShowLoading(true);

      const params = {
        id_analise: analiseAjuste.id_analise,
        gradeRevisao,
        direcionamentoRevisao,
        propriedades_analise: {
          ...JSON.parse(analiseAjuste.propriedades_analise),
          usuario_ultima_alteracao: CookiesService.getUserLogin()
        }
      }

      await api.post('/pa-ajustar-sq/ajuste-sq-processar', params);
      toast.success('Processamento completado com sucesso!');
      resetData();
    } catch (e) {
      toast.error('Houve um problema durante o processamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    } finally {
      setShowLoading(false);
    }
  }

  const toggleSemDefeitos = (index_grade) => {
    gradeRevisao[index_grade].sem_defeitos = !gradeRevisao[index_grade].sem_defeitos;
    setGradeRevisao([ ...gradeRevisao ]);
  }

  const handleRemoverDirecionamento = (row, index) => {
    const { quantidade, grade } = row;
    try {
      gradeRevisao[grade.index].quantidade_aprovada += parseInt(quantidade);
      gradeRevisao[grade.index].quantidade_defeituosa -= parseInt(quantidade);
      direcionamentoRevisao.splice(index, 1);

      setGradeRevisao([ ...gradeRevisao ]);
      setDirecionamentoRevisao([ ...direcionamentoRevisao ]);
    } catch (e) {
      toast.error('Encontramos um erro ao remover o direcionamento.\nPor favor tente novamente ou entre em contato com o suporte.');
    }
  }

  return (
    <PARevisaoSegundaQualidadeNVContext.Provider
      value={{ listaDefeitos, listaDirecionamentos, listRevisao, listAjuste, modalRevisao, setModalRevisao, modalDirecionamento, setModalDirecionamento, fetchRevisaoSQNVGrid, fetchAjusteSQGrid, fetchRevisaoSQNVInicializacao, iniciarRevisaoSQNV, processarRevisaoSQNV, referenciaSelecionada, gradeRevisao, setGradeRevisao, direcionamentoRevisao, setDirecionamentoRevisao, toggleSemDefeitos, handleRemoverDirecionamento, checarSePossuiDefeitos, modalAjuste, analiseAjuste, direcionamentosAjuste, iniciarAjusteSQ, processarAjusteSQNV, modalFinalizacao, setModalFinalizacao, abrirModalProcessamento }}
    >
      {<PARevisaoSegundaQualidadeNVMain />}
    </PARevisaoSegundaQualidadeNVContext.Provider>
  )
}