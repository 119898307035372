import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Form, FormGroup, Modal, Col, Container, Row, Accordion } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import MaterialIcon from 'material-icons-react';
import styles from "../../../../styles/MonitorChecklistAnaliseGrade.module.scss";
import ProdutoInfo from "../../MonitorRevisao/componentes/ProdutoInfo";
import { api, retryApi }  from "../../../../services/api";
import MonitorChecklistFinalizacao from "../Finalizacao"
import RadioButtonList from "../../../Utils/RadioButtonList"
import MedidasProducao from "../../MedidasProducao";


const MonitorChecklistAnaliseGrade = (props) => {
  const { precoIncorreto, setPrecoIncorreto } = props;
  const [showModalDefeito115, setShowModalDefeito115] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [gradeDirecionamentoQualidade, setGradeDirecionamentoQualidade] = useState([]);
  const [qtdPecasDefeito, setQtdPecasDefeito] = useState('');
  const [grupoAvaliacao, setGrupoAvaliacao] = useState([]);
  const [statusAvaliacao, setStatusAvaliacao] = useState([]);
  const [defeitosEncontrados, setDefeitosEncontrados] = useState([]);
  const [itemGradeDefeitoSelecionado, setItemGradeDefeitoSelecionado] = useState('');
  const [qdtPecasComDefeito, setQdtPecasComDefeito] = useState(0);
  const [percentPecasComDefeito, setPercentPecasComDefeito] = useState(0);
  const [carregandoDefeitos, setCarregandoDefeitos] = useState(false);
  const [areaEspecifica, setAreaEspecifica] = useState([]);
  const [localizacaoDefeito, setLocalizacaoDefeito] = useState([]);

  const [grupoAvaliacaoSelecionado, setGrupoAvaliacaoSelecionado] = useState({});
  const [statusAvaliacaoSelecionado, setStatusAvaliacaoSelecionado] = useState({});
  const [areaEspecificaSelecionada, setAreaEspecificaSelecionada] = useState({});
  const [localizacaoDefeitoSelecionada, setLocalizacaoDefeitoSelecionada] = useState({});
  const [defeitosEncontradosSelecionado, setDefeitosEncontradosSelecionado] = useState({});
  const [consideracoesSelecionada, setConsideracoesSelecionada] = useState('');
  const [gradeDefeitos, setGradeDefeitos] = useState([]);
  const [gradeDefeitosInseridos, setGradeDefeitosInseridos] = useState([]);
  const [gradeSemDefeito, setGradeSemDefeito] = useState([]);
  const [gradeAnaliseQualidadeTotalAprovada, setGradeAnaliseQualidadeTotalAprovada] = useState([]);
  const [gradeAnaliseQualidadeTotal, setGradeAnaliseQualidadeTotal] = useState([]);
  const [desabilitaFinalizar, setDesabilitaFinalizar] = useState(true);
  const [showModalFinalizacao, setShowModalFinalizacao] = useState(false);

  //Modal Medidas Produção
  const [showMedidasProducao, setShowMedidasProducao] = useState(false);
  const handleCloseMedidasProducao = () => setShowMedidasProducao(false);
  const handleShowMedidasProducao = () => setShowMedidasProducao(true);

  const qtdPecasAmostra = props.qtdPecasAmostra;
  const listRef = useRef();

  useEffect(() => {
    getGradeEncaminhamento();
    carregaFiltros();
  }, []);

  const handleShow = () => {
    let grade = gradeDefeitosInseridos.filter(defeito => defeito.tamanho === itemGradeDefeitoSelecionado.Tamanho);
    if (grade?.length > 0) {
      setGradeDefeitos(grade[0].grade);
    }
    setShow(true);
  }


  async function getGradeEncaminhamento() {
    try {
      const { Produto, CorProduto, NFEntrada, SerieNF, Origem, OrdemProducao, QtdeEntrada, FilialRevisao } = props.item;
      const params = {
        Produto: Produto,
        CorProduto: CorProduto,
        NFEntrada: NFEntrada,
        SerieNF: SerieNF,
        Origem: Origem,
        OrdemProducao: OrdemProducao,
        QtdeEntrada: QtdeEntrada,
        FilialRevisao: FilialRevisao,
        TipoAnalise: '01 - PRODUÇÃO'
      };
      const lista = await retryApi('get', "PAMonitorCheckList/ListaDirecionamento", { params });

      setGradeDirecionamentoQualidade(lista.data);
      setGradeAnaliseQualidadeTotal(lista.data);
    } catch (error) {
      toast.error('Ocorreu um erro para carregar a grade!');
      return false;
    }
  }

  async function carregaFiltros() {
    listaGrupoAvaliacao();
    listaStatusAvaliacao();
    listaAreaEspecifica();
    listaLocalizacaoDefeito();
  }

  async function listaGrupoAvaliacao() {
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/ListaGrupoAvaliacao");
      if (lista.data.length > 0) {
        const listaGrupo = lista.data.map((item, index) => {
          return { value: item.IDGrupoAnalise, label: item.GrupoAnalise, checked: false };
        });
        setGrupoAvaliacao(listaGrupo);


      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
  }

  async function listaStatusAvaliacao() {
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/ListaStatusAvaliacao");
      if (lista.data.length > 0) {
        const listaStatus = lista.data.map((item, index) => {
          return { value: item.IDStatus, label: item.LISTA_STATUS };
        });
        setStatusAvaliacao(listaStatus);

      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
  }

  async function listaDefeitosEncontrados(itemValue) {
    //alert(itemValue);
    setDefeitosEncontrados([]);
    setCarregandoDefeitos(true);
    try {
      const params = {
        idGrupoAnalise: itemValue
      }
      const lista = await retryApi('get', "PAMonitorCheckList/ListaDefeitosEncontrados", { params });
      if (lista.data.length > 0) {
        const listaDefeitos = lista.data.map((item, index) => {
          return { value: item.IDDefeito, label: item.GrupoDefeito };
        });
        setDefeitosEncontrados(listaDefeitos);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de defeitos!');
      return false;
    }
    setCarregandoDefeitos(false);
  }

  async function listaAreaEspecifica() {
    try {
      const lista = await retryApi('get', "PAMonitorCheckList/ListaAreaEspecifica");
      if (lista.data.length > 0) {
        const listaArea = lista.data.map((item, index) => {
          return { value: item.IDLOCDEFPA, label: item.DetalhamentoLocalizacaoDefeito };
        });
        setAreaEspecifica(listaArea);

      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de áreas!');
      return false;
    }
  }

  async function listaLocalizacaoDefeito(itemValue) {
    try {
      const params = {
        grupoProduto: props.item.GrupoProduto
      }
      const lista = await retryApi('get', "PAMonitorCheckList/ListaLocalizacaoDefeitos", { params });
      if (lista.data.length > 0) {
        const listaLocalizacao = lista.data.map((item, index) => {
          return { value: item.IDLocalizacao, label: item.DescricaoLocalizacao };
        });
        setLocalizacaoDefeito(listaLocalizacao);

      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de defeitos!');
      return false;
    }

  }

  const handleIncluiAvaliacao = async () => {

    const itemParaInserir = {
      tamanho: itemGradeDefeitoSelecionado.Tamanho,
      grupo: grupoAvaliacaoSelecionado,
      status: statusAvaliacaoSelecionado,
      defeito: defeitosEncontradosSelecionado,
      localizacao: localizacaoDefeitoSelecionada,
      areaEspecifica: areaEspecificaSelecionada,
      consideracoes: consideracoesSelecionada
    }


    if (grupoAvaliacaoSelecionado.value == null || statusAvaliacaoSelecionado.value == null ||
      defeitosEncontradosSelecionado.value == null || localizacaoDefeitoSelecionada.value == null ||
      areaEspecificaSelecionada.value == null
    ) {
      toast.error('Você deve informar Grupo, Status, Defeito, Localização e Área para incluir.');
      return false;
    }

    let grade = [];
    grade = grade.concat(gradeDefeitos?.filter(item => { return item.tamanho === itemGradeDefeitoSelecionado.Tamanho }));
    grade.push(itemParaInserir);
    setGradeDefeitos(grade);
    setGrupoAvaliacaoSelecionado({});
    setStatusAvaliacaoSelecionado({});
    setDefeitosEncontradosSelecionado({});
    setLocalizacaoDefeitoSelecionada({});
    setAreaEspecificaSelecionada({});
    setConsideracoesSelecionada('');
    limpaFiltros();


  }
  async function limpaFiltros() {
    listRef.current.clearChecks()
  }

  const handleExcluiItemDefeito = async (itemParaExcluir) => {
    setGradeDefeitos(gradeDefeitos.filter(el => el !== itemParaExcluir));
  }

  const handleItemGradeDefeitoQualidade = async (opcaoSelecionada) => {
    setItemGradeDefeitoSelecionado(opcaoSelecionada);
    setQdtPecasComDefeito(opcaoSelecionada.QtdePecas);
    setPercentPecasComDefeito((opcaoSelecionada.QtdePecas / opcaoSelecionada.Qtde) * 100)
  }


  const handleAvaliarGrade = () => {

    if (itemGradeDefeitoSelecionado.Tamanho === undefined) {
      toast.error('Favor selecionar uma grade.');
      return false;
    }
    if (parseInt(qtdPecasDefeito) < 0) {
      toast.error('Favor informar uma quantidade de peças válida.');
      return false;
    }
    if (qtdPecasDefeito > itemGradeDefeitoSelecionado.Qtde_Pendente) {
      toast.error(`A quatidade ${qtdPecasDefeito} não pode ser maior que a quantidade pendente de ${itemGradeDefeitoSelecionado.Qtde_Pendente}`);
      return false;
    }


    if (parseInt(qtdPecasDefeito) == 0 || !qtdPecasDefeito) {
      const itemParaInserir = {
        tamanho: itemGradeDefeitoSelecionado.Tamanho,
        grade: [],
      };

      let gradeAux = gradeDefeitos?.filter(item => { return item.tamanho !== itemParaInserir.Tamanho });

      setGradeDefeitos(gradeAux);
      let grade = [];
      grade = grade.concat(gradeSemDefeito.filter(item => { return item.tamanho !== itemGradeDefeitoSelecionado.Tamanho }));
      grade.push(itemParaInserir);
      setGradeSemDefeito(grade);
      setGradeDefeitosInseridos(gradeDefeitosInseridos.filter(item => { return item.tamanho !== itemGradeDefeitoSelecionado.Tamanho }))
      setGradeDefeitos(gradeDefeitos.filter(item => { return item.tamanho !== itemGradeDefeitoSelecionado.Tamanho }))
      toast.warning('Atenção! Grade inserida sem defeito.');
      setQtdPecasDefeito('');
      return false;
    }
    handleShow();
  }

  async function handleFinalizarGrade() {
    if (gradeDefeitos.filter(defeito => { return defeito.tamanho === itemGradeDefeitoSelecionado.Tamanho }).length < 1) {
      toast.error('Você deve incluir pelo menos 1 defeito.');
      return false;
    }

    const objInserir = {
      tamanho: itemGradeDefeitoSelecionado.Tamanho,
      qtdPecasDefeito: qtdPecasDefeito,
      grade: gradeDefeitos
    }

    let grade = [];
    grade = grade.concat(gradeDefeitosInseridos.filter(defeito => { return defeito.tamanho !== itemGradeDefeitoSelecionado.Tamanho }));
    grade.push(objInserir);
    setGradeDefeitosInseridos(grade);
    setShow(false);
    setQtdPecasDefeito('');
  }

  const handleAprovarGrade = () => {
    const gradeTotalGrade = gradeAnaliseQualidadeTotal.length;
    const gradeTotalOk = gradeAnaliseQualidadeTotal.filter((item) => {
      return gradeDefeitosInseridos.concat(gradeSemDefeito).filter(defeito => { return defeito.tamanho === item.Tamanho }).length > 0
    }).length;
    if (parseInt(gradeTotalOk) < parseInt(gradeTotalGrade)) {
      toast.error('Você deve inserir os defeitos de todas as grades antes de aprovar');
      return false;
    }
    let gradeAnaliseQualidadeTotalAux = []
    gradeAnaliseQualidadeTotalAux = gradeAnaliseQualidadeTotalAux.concat(gradeAnaliseQualidadeTotal);
    let gradeProducao = []
    gradeAnaliseQualidadeTotalAux.map((item, index) => {
      const gradeDefeitosInseridosAux = gradeDefeitosInseridos;
      const itemDefeito = gradeDefeitosInseridosAux.filter(defeito => { return defeito.tamanho === item.Tamanho });

      item.TipoAnalise = '01 - PRODUÇÃO'
      let itemGrade = { ...item }
      if (itemDefeito.length > 0) {
        itemGrade.QtdeDirecionada = itemDefeito[0].qtdPecasDefeito;
        itemGrade.grade = itemDefeito[0].grade;
        itemGrade.TipoAnalise = '01 - PRODUÇÃO';
      } else {
        itemGrade.QtdeDirecionada = 0;
        itemGrade.grade = [];
        itemGrade.TipoAnalise = '01 - PRODUÇÃO';
      }
      gradeProducao.push(itemGrade);
      return item;
    });

    setGradeAnaliseQualidadeTotalAprovada(gradeProducao);

    setDesabilitaFinalizar(false);
    setQtdPecasDefeito('');
    toast.success('Grades finalizadas. Favor clicar em finalizar análise para continuar.');
  }

  const handleDefeitoCasoEspecial = (defeito) => {
    if (defeito.value === 115) setShowModalDefeito115(true);
    setDefeitosEncontradosSelecionado(defeito); 
  }

  const handleValueMask = (value) => {
    if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
    setPrecoIncorreto(parseFloat(value / 100).toFixed(2));
  }

  return (
    <div className="m-3">
      <h2>Análise por Grade</h2>

      {/* Identificação Produto */}
      <Accordion style={{ marginTop: "10px" }}>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Identificação do Produto</Accordion.Header>
          <Accordion.Body>
            <ProdutoInfo item={props.item}></ProdutoInfo>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <article className={styles.articleBorder}>
        <h5>
          Tipo:
          <span className={styles.spanBlueBold}> PRODUÇÃO</span>
        </h5>
        <div className={styles.sectionContent} >
          <p style={{ marginBottom: 10 }}> 1. Grade</p>
          <Table bordered responsive="sm">
            <thead>
              <tr>
                <th></th>
                <th className={styles.blockTitle}>TAMANHO</th>
                <th className={styles.blockTitle}>Q. RECEBIDA</th>
                <th className={styles.blockTitle}>Q. DIRECIONADA</th>
                <th className={styles.blockTitle}>Q. PENDENTE</th>
                <th className={styles.blockTitle}>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {gradeDirecionamentoQualidade.map((item, index) => {
                return (
                  <tr
                    className={styles.tableLines}
                    key={`rowGradeSegundaQualidade` + index}
                    onClick={() => { handleItemGradeDefeitoQualidade(item) }}
                  >
                    <td>
                      <Form.Check
                        id={`itemGrade_` + item.Tamanho}
                        key={`itemGrade_` + item.Tamanho}
                        type={'radio'}
                        name={'gradeQualidade'}
                        value={item.Tamanho}
                        onChange={() => { handleItemGradeDefeitoQualidade(item) }}
                        checked={itemGradeDefeitoSelecionado === item}
                      />
                    </td>
                    <td className={styles.tableLine}>
                      {item.Tamanho}
                    </td>
                    <td className={styles.tableLine}>
                      {item.Qtde}
                    </td>
                    <td className={styles.tableLine}>
                      {item.QtdeDirecionada}
                    </td>
                    <td className={styles.tableLine}>
                      {item.Qtde_Pendente}
                    </td>
                    <td className={styles.tableLine}>
                      {gradeDefeitosInseridos.concat(gradeSemDefeito).filter(defeito => { return defeito.tamanho === item.Tamanho }).length > 0 ? 'OK' : 'PENDENTE'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className={styles.alignLeft}>
          <Form>
            <Form.Label>2. Qtde peças com defeito:</Form.Label>
            <Form.Control
              className="mb-3"
              type="number"
              min="0"
              value={qtdPecasDefeito}
              onChange={(e) => (setQtdPecasDefeito(e.target.value))}
            />
          </Form>
          <Form className={styles.formAlignLeft} style={{ marginTop: 2 }}>
            <Form.Label>
              Peças com defeito:
            </Form.Label>
            <span className={styles.spanGray}>
              {qtdPecasDefeito ? Math.round((parseInt(qtdPecasDefeito) / parseInt(qtdPecasAmostra === 0 || qtdPecasAmostra === null ? 1 : qtdPecasAmostra)) * 100, 2) : 0}
              %
            </span>
          </Form>
        </div>
        <div className={styles.buttonRight}>
          <Button
            className={styles.buttonMarginSide}
            variant="outline-primary"
            onClick={handleAvaliarGrade}
          //onClick={handleShow}
          >
            Avaliar Grade
          </Button>
          <Button
            className={styles.buttonMarginSide}
            variant="success"
            onClick={handleAprovarGrade}
          >
            Finalizar Grades
          </Button>
        </div>
      </article>

      {/* Footer */}
      <article className={styles.footer}>
        <Button
          className={styles.footerBtn}
          variant="outline-primary"
          onClick={handleShowMedidasProducao}
        >
          Medidas Produção
        </Button>
        <Button //Só habilita quando tudo acima estiver preenchido
          //variant={desabilitaProcessar ? "secondary" : "primary"} // desebilitado
          //variant="primary"   // habilitado
          disabled={desabilitaFinalizar}
          onClick={() => (setShowModalFinalizacao(true))}
        >
          Finalizar Analise por Grade
        </Button>
      </article>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen={true}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Avaliar Grade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Accordion Identificação Produto */}
          <Accordion style={{ marginTop: "10px" }}>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Identificação do Produto</Accordion.Header>
              <Accordion.Body>
                <ProdutoInfo item={props.item}></ProdutoInfo>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <article className={styles.sectionBorder}>
            <div className={styles.backgroundMiddleGray}>
              <div className={styles.secondQaInfo}>
                <Form className={styles.formSecondQaInfo}>
                  <Form.Label>Tamanho:</Form.Label>
                  <span className={styles.spanBold}>
                    {itemGradeDefeitoSelecionado.Tamanho}
                  </span>
                </Form>
                <Form className={styles.formSecondQaInfo}>
                  <Form.Label>Qtde Peças com Defeito:</Form.Label>
                  <span className={styles.spanBold}>
                    {qtdPecasDefeito}
                  </span>
                </Form>
              </div>
            </div>
            <div className={styles.sectionContent} >
              <div className={styles.alignLineFormDefeito}>
                <div className={styles.alignColummFormDefeito}>
                  <Form className="m-2">
                    <Form.Label className={styles.formLabel}>1. Grupo de Avaliação:</Form.Label>
                    {/* <Select 
                            closeMenuOnSelect={true} 
                            name={'listGrupo'}
                            onChange={(e)=>{setGrupoAvaliacaoSelecionado(e);listaDefeitosEncontrados(e?.value);}}
                            options={grupoAvaliacao} 
                            ref={selectListGrupo}                                          
                                                                                                              
                          /> */}
                    <RadioButtonList
                      options={grupoAvaliacao}
                      ref={listRef}
                      changeList={(e) => { listaDefeitosEncontrados(e.value); setGrupoAvaliacaoSelecionado(e); }}>
                    </RadioButtonList>
                  </Form>
                  <Form className="m-2">
                    <Form.Label className={styles.formLabel}>4. Localização do Defeito:</Form.Label>
                    {localizacaoDefeito.length > 0 ?
                      <RadioButtonList ref={listRef}
                        options={localizacaoDefeito} changeList={(e) => { setLocalizacaoDefeitoSelecionada(e); }}></RadioButtonList>
                      : ''
                    }
                  </Form>
                </div>
                <div className={styles.alignColummFormDefeito}>
                  <Form className="m-2">
                    <Form.Label className={styles.formLabel}>2. Status Avaliação:</Form.Label>
                    <RadioButtonList ref={listRef}
                      options={statusAvaliacao} changeList={(e) => { setStatusAvaliacaoSelecionado(e); }}></RadioButtonList>
                  </Form>
                  <Form className="m-2">
                    <Form.Label className={styles.formLabel}>5. Especifique a área mais próxima:</Form.Label>
                    <RadioButtonList options={areaEspecifica} changeList={(e) => { setAreaEspecificaSelecionada(e); }}></RadioButtonList>
                  </Form>
                </div>
                <div className={styles.alignColummFormDefeito}>
                  <Form className="m-2">
                    <Form.Label className={styles.formLabel}>3. Defeito Encontrado:</Form.Label>
                    {defeitosEncontrados.length > 0 ?
                      <RadioButtonList
                        options={defeitosEncontrados}
                        changeList={e => handleDefeitoCasoEspecial(e)}
                      ></RadioButtonList>
                      : ''
                    }
                  </Form>
                  <Form className="m-2">
                    <Form.Label className={styles.formLabel}>
                      6. Considerações
                      <span className={styles.spanOpcional}> (Opcional)</span>
                      :
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="2"
                      className={styles.formControl}
                      id="txtConsideracoes"
                      value={consideracoesSelecionada}
                      onChange={(e) => { setConsideracoesSelecionada(e.target.value); }}
                    >
                    </Form.Control>
                  </Form>
                </div>
              </div>
            </div>
            <div className={styles.buttonRight}>
              <Button
                variant="outline-success"
                //variant="success"
                onClick={handleIncluiAvaliacao}
              >
                Incluir Avaliação
              </Button>
            </div>
          </article>

          <h5 className={styles.fontBold}>Resumo Avaliação:</h5>
          <article className={styles.sectionBorder}>
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                  <th className={styles.blockTitle}>GRUPO</th>
                  <th className={styles.blockTitle}>STATUS</th>
                  <th className={styles.blockTitle}>DEFEITO ENCONTRATO</th>
                  <th className={styles.blockTitle}>LOCALIZAÇÃO</th>
                  <th className={styles.blockTitle}>ÁREA MAIS PRÓXIMA</th>
                  <th className={styles.blockTitle}>CONSIDERAÇÕES</th>
                </tr>
              </thead>
              <tbody>
                {gradeDefeitos.length > 0 ?
                  gradeDefeitos
                    .filter(item => {
                      return item.tamanho === itemGradeDefeitoSelecionado.Tamanho;
                    })
                    .map((item, index) => {
                      return (
                        <tr
                          className={styles.tableLines}
                          key={`rowGradeDefeitosSelecionada` + index}
                        >
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => handleExcluiItemDefeito(item)}
                            >
                              <span className="fa-trash icons-uniao">
                                <MaterialIcon
                                  icon="delete"
                                  size={20}
                                  color='white'
                                />
                              </span>
                            </Button>
                          </td>
                          <td className={styles.tableLine}>
                            {item.grupo.label}
                          </td>
                          <td className={styles.tableLine}>
                            {item.status.label}
                          </td>
                          <td className={styles.tableLine}>
                            {item.defeito.label}
                          </td>
                          <td className={styles.tableLine}>
                            {item.localizacao.label}
                          </td>
                          <td className={styles.tableLine}>
                            {item.areaEspecifica.label}
                          </td>
                          <td className={styles.tableLine}>
                            {item.consideracoes}
                          </td>
                        </tr>
                      );
                    })
                  : ''
                }

              </tbody>
            </Table>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="primary"  // quando desabilitado
            //variant="primary" // quando habilitado -- só habilita quando todas peças forem incluidas no direcionamento
            //onClick={handleClose}
            onClick={handleFinalizarGrade}
          >
            Finalizar Grade
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalFinalizacao}
        onHide={() => setShowModalFinalizacao(false)}
        backdrop="static"
        fullscreen={true}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Monitor Checklist PA - Finalização
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MonitorChecklistFinalizacao
            identificacao={props.identificacao}
            item={props.item}
            qtdPecasAmostra={props.qtdPecasAmostra}
            statusAnaliseSelecionada={props.statusAnaliseSelecionada}
            responsavelAnaliseSelecionado={props.responsavelAnaliseSelecionado}
            gradeAnaliseQualidadeTotalAprovada={gradeAnaliseQualidadeTotalAprovada}
            handleClose={props.handleClose}
            precoIncorreto={precoIncorreto}
            setPrecoIncorreto={setPrecoIncorreto}
          ></MonitorChecklistFinalizacao>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showMedidasProducao}
        onHide={handleCloseMedidasProducao}
        backdrop="static"
        fullscreen={true}
        centered
        keyboard={false}
        dialogClassName={styles.modalWith}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MedidasProducao item={props.item}></MedidasProducao>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalDefeito115}
        backdrop={'static'}
        keyboard={false}
        centered={true}
        style={{ backgroundColor: '#00000090' }}
      >
        <Modal.Header>
          <Modal.Title>
            Insira abaixo o valor do preço incorreto encontrado no código de barras
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            <Form.Group className={styles.formControl}>
              <Form.Label className={styles.formLabel}>
                Preço Incorreto:
              </Form.Label>
              <Form.Control
                type={'text'}
                value={precoIncorreto || ''}
                onChange={e => handleValueMask(e.target.value)}
                placeholder={'0.00'}
              >
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={styles.footerBtn}
            style={{ width: '40%' }}
            variant="primary"
            onClick={() => { setShowModalDefeito115(false); }}
            disabled={!precoIncorreto || precoIncorreto === ''}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      /> */}
    </div>
  );
};

export default MonitorChecklistAnaliseGrade;