const listaProcessos = [
  {
    label: 'Desfazer Revisão',
    value: 'revisao',
    endpointConsulta: 'consultarRevisao',
    endpointProc: 'desfazerRevisao',
    bodyMain: 'Ao prosseguir você concorda em desfazer a revisão desta nota',
    bodySub: 'Atenção, esta produção de ATACADO já foi aprovada, certifique-se que é correto desfaze-la antes de prosseguir!',
    btn: 'Confirmar e desfazer revisão',
    motivoEspecifico: [1, 2, 3, 4, 5, 6, 7],
    controleAcesso: 'pa_desfazer_revisao'
  },
  {
    label: 'Desfazer Checklist',
    value: 'revisaoChecklist',
    endpointConsulta: 'consultarRevisao',
    endpointProc: 'desfazerRevisao',
    bodyMain: 'Ao prosseguir você concorda em desfazer a revisão checklist desta nota',
    bodySub: 'Atenção, esta produção de ATACADO já foi aprovada pela equipe Checklist, certifique-se que é correto desfaze-la antes de prosseguir!',
    btn: 'Confirmar e desfazer revisão checklist',
    motivoEspecifico: [1, 2, 3, 4, 5, 6, 7],
    controleAcesso: 'pa_desfazer_checklist'
  },
  {
    label: 'Desfazer Revisão + Checklist',
    value: 'revisaoMaisRevisaoChecklist',
    endpointConsulta: 'consultarRevisao',
    endpointProc: 'desfazerRevisao',
    bodyMain: 'Ao prosseguir você concorda em desfazer AMBAS as revisões desta nota',
    bodySub: 'Atenção, esta produção de ATACADO já foi aprovada pela equipe Checklist, certifique-se que é correto desfaze-la antes de prosseguir!',
    btn: 'Confirmar e desfazer AMBAS as revisões',
    motivoEspecifico: [1, 2, 3, 4, 5, 6, 7],
    controleAcesso: 'pa_desfazer_checklist'
  },
  {
    label: 'Alterar Data Entrada',
    value: 'dataEntrada',
    endpointConsulta: 'consultarDataEntrada',
    endpointProc: 'alterarDataEntrada',
    bodyMain: 'Ao prosseguir você concorda em alterar a data de entrada desta nota',
    bodySub: null,
    btn: 'Confirmar e alterar a data de entrada',
    motivoEspecifico: [14, 15],
    controleAcesso: 'pa_desfazer_data_entrada'
  },
  {
    label: 'Voltar Status PCP',
    value: 'statusPCP',
    endpointConsulta: 'consultarStatusPCP',
    endpointProc: 'desfazerStatusPCP',
    bodyMain: 'Ao prosseguir você concorda em voltar o status PCP desta nota',
    bodySub: null,
    btn: 'Confirmar e voltar o status PCP',
    motivoEspecifico: [8, 9, 10],
    controleAcesso: 'pa_desfazer_status_pcp'
  },
  {
    label: 'Remover Análise Duplicada',
    value: 'analiseDuplicada',
    endpointConsulta: 'consultarAnaliseDuplicada',
    endpointProc: 'removerAnaliseDuplicada',
    bodyMain: 'Ao prosseguir você concorda em remover esta análise duplicada',
    bodySub: 'Atenção! Análises duplicadas somente poderão ser removidas do sistema depois que uma delas passar pela revisão.',
    btn: 'Confirmar e remover duplicata',
    motivoEspecifico: [11, 12, 13],
    controleAcesso: 'pa_desfazer_analise_duplicada'
  },
  {
    label: 'Remover Boleta',
    value: 'numeroAnalise',
    endpointConsulta: 'consultarNumeroAnalise',
    endpointProc: 'removerNumeroAnalise',
    bodyMain: 'Ao prosseguir você concorda em remover este boleta, baseando-se no número da análise deste produto',
    bodySub: null,
    btn: 'Confirmar e remover boleta',
    motivoEspecifico: [17],
    controleAcesso: 'pa_desfazer_boleta'
  },
  {
    label: 'Alterar Devolução',
    value: 'devolucao',
    endpointConsulta: 'consultarDevolucao',
    endpointProc: 'alterarDevolucao',
    bodyMain: 'Ao prosseguir você concorda em alterar os dados de devolução deste produto',
    bodySub: null,
    btn: 'Confirmar e alterar devolução',
    motivoEspecifico: [17, 18, 19],
    controleAcesso: 'pa_desfazer_devolucao'
  },
  {
    label: 'Alterar Grade Entrada',
    value: 'gradeEntrada',
    endpointConsulta: 'consultarGradeEntrada',
    endpointProc: 'alterarGradeEntrada',
    bodyMain: 'Ao prosseguir você concorda em alterar os dados das quantidades de entrada dessa produção',
    bodySub: null,
    btn: 'Confirmar e alterar grade',
    motivoEspecifico: [20, 21, 22],
    controleAcesso: 'pa_alterar_grade_entrada'
  }
];

export { listaProcessos }