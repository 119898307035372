import React from 'react';
import styles from '../../../styles/Corte/ControleEnfesto/AcompanhamentoEnfesto.module.scss';
import MainTable from '../../../components/Corte/ControleEnfesto/AcompanhamentoEnfesto/MainTable';
import ModalInfo from '../../../components/Corte/ControleEnfesto/AcompanhamentoEnfesto/ModalInfo';
import ModalPausas from '../../../components/Corte/ControleEnfesto/AcompanhamentoEnfesto/ModalPausas';
import FilterComponent from '../../../components/Corte/ControleEnfesto/AcompanhamentoEnfesto/FilterComponent';

const AcompanhamentoEnfesto = () => {
    
    return (
        <div>
            <article className={styles.articleTitle}>
                <h3 className="p-3">Corte - Acompanhamento dos Enfestos</h3>
            </article>

            <FilterComponent/>
            
            <article className={styles.articleGray}>
                <MainTable/>
                <ModalInfo/>
                <ModalPausas/>
            </article>
        </div>
    );
};

export default AcompanhamentoEnfesto;
