import React from 'react';
import { Button } from 'react-bootstrap';
import Tabela from '../../../Misc/Tabela/Tabela';
import styles from '../../../../styles/Corte/ControleEnfesto/Enfesto.module.scss';
import { useEnfesto } from '../../../../contexts/Corte/ControleEnfesto/EnfestoContext'; 

const ModalIniciado = () => {

    const { columns, iniciadoData, handleShowPausa, isPausado, handleFinalizaPausa, handleShowFolhas } = useEnfesto();

    return (
        <>
            <h4 style={{ margin: '0px 20px 20px' }}>Enfesto em Andamento</h4>
            <section style={{ margin: '0px 20px 20px' }}>
                <Tabela
                    columns={columns}
                    data={iniciadoData}
                />
            </section>
            <div className={styles.divBtnIniciado}>
                {
                    isPausado ?
                    <>
                        <Button
                            variant='outline-primary'
                            className={styles.btnIniciado}
                            onClick={handleFinalizaPausa}
                        >
                            Continuar
                        </Button>
                    </>
                    :
                    <>
                        <Button
                            variant='outline-secondary'
                            className={styles.btnIniciado}
                            onClick={handleShowPausa}
                        >
                            Pausar
                        </Button>
                        <Button
                            variant='outline-danger'
                            className={styles.btnIniciado}
                            onClick={handleShowFolhas}
                        >
                            Finalizar
                        </Button>
                    </>
                }

            </div>
        </>
    );
};

export default ModalIniciado;
