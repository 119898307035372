import React from "react";
import { Button, Form, Modal, InputGroup } from 'react-bootstrap';
import { api }  from "../../services/api";
import { toast } from "react-toastify";
import { toDatetime } from "../../utils/dateUtils";

import styles from "../../styles/Desconto.module.scss";

import "react-toastify/dist/ReactToastify.min.css";

const Acompanhamento = ({ showAcomp, activeObject, taxaImpostoAtual, handleCloseAcomp,
    formObject, setFields, formErrors, calcDiscountedValue, setLoading, handleFilter
}) => {

    const negociaDesconto = async() => {
        const params = {
            NF_ENTRADA: parseInt(activeObject.NFENTRADA),
            NF_SERIE: parseInt(activeObject.SERIENF),
            NOME_CLIFOR: activeObject.NOMECLIFOR,
            QTDE_DESCONTO: parseFloat(formObject.discountedMeters).toFixed(2),
            QTDE_METROS: parseFloat(activeObject.QTDEENTRADA).toFixed(2),
            PERC_DESC_SUGERIDO: parseInt(formObject.suggestedDiscPerc),
            PERC_DESC_NEGOCIADO: isNaN(formObject.negotiatedDiscPerc) || formObject.negotiatedDiscPerc === '' ? 0 : parseFloat(formObject.negotiatedDiscPerc).toFixed(2),
            VAL_DESCONTO: isNaN(formObject.negotiatedDiscPerc) ? 0 : parseFloat(calcDiscountedValue()).toFixed(2),
            VAL_NF: parseFloat(activeObject.VALOR).toFixed(2),
            LANC_CONTABIL: parseInt(activeObject.CTBLANCAMENTO),
            QTDE_NF: parseFloat(activeObject.QTDEENTRADA).toFixed(2),
            DATA_FIM_PROCESSO: toDatetime(Date.now())
          };
    
        await api.post('/GLDescontos/finaliza-processo', { params })
          .then(res => {
              if (res.status === 200) { 
                  toast.success('Dado inserido com sucesso!');
                  handleFilter()
                  handleCloseAcomp()
              } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
          }).catch(e => {
              toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
              console.error(`Erro na requisição - ${e}`);
        });      
        setLoading(false);
    }

    return (
        <>
            <Modal
                show={showAcomp}
                onHide={handleCloseAcomp}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Acompanhamento Descontos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <article className={styles.monitorInfo}>
                    <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                        <span>NF Entrada:</span>
                        <span className={styles.fontBlue}>{activeObject.NFENTRADA}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Material:</span>
                        <span className={styles.fontBlue}>{activeObject.MATERIAL}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Cor Material:</span>
                        <span className={styles.fontBlue}>{activeObject.CORMATERIAL}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Tipo Compra:</span>
                        <span className={styles.fontBlue}>{activeObject.TIPOCOMPRA}</span>
                    </div>
                    </div>
                    <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                        <span>Série:</span>
                        <span className={styles.fontBlue}>{activeObject.SERIENF}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Descrição Material:</span>
                        <span className={styles.fontBlue}>{activeObject.DESCRICAOMATERIAL}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Descrição Cor:</span>
                        <span className={styles.fontBlue}>{activeObject.DESCRICAOCOR}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Tipo Produção:</span>
                        <span className={styles.fontBlue}>{activeObject.TIPOPRODUCAO}</span>
                    </div>
                    </div>
                    <div className={styles.infoBlock}>
                    <div className={styles.infoBlockColumm}>
                        <span>Pedido:</span>
                        <span className={styles.fontBlue}>{activeObject.PEDIDO}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Marca:</span>
                        <span className={styles.fontBlue}>{activeObject.MARCA}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Qtde Entrada:</span>
                        <span className={styles.fontBlue}>{activeObject.QTDEENTRADA}</span>
                    </div>
                    <div className={styles.infoBlockColumm}>
                        <span>Valor NF:</span>
                        <span className={styles.fontBlue}>{activeObject.VALOR}</span>
                    </div>
                    </div>
                </article>
                <article>
                    <div className="mt-3">
                    <Form>
                        <Form.Group className={styles.formGroup} controlId="discountedMeters">
                        <Form.Label>1. Metragem descontada:</Form.Label>
                        <InputGroup className={styles.discountFormWidth}>
                            <Form.Control
                                type="text"
                                value={formObject.discountedMeters}
                                disabled
                            />
                            <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                        </Form.Group>

                        <Form.Group className={styles.formGroup} controlId="suggestedDiscPerc">
                        <Form.Label>2. Desconto sugerido:</Form.Label>
                        <InputGroup className={styles.discountFormWidth}>
                            <Form.Control
                                type="text"
                                value={formObject.suggestedDiscPerc}
                                disabled
                            >
                            </Form.Control>
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control.Feedback type='invalid'>{formErrors.suggestedDiscPerc}</Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>

                        <Form.Group className={styles.formGroup} controlId="negotiatedDiscPerc">
                        <Form.Label>3. Desconto negociado:</Form.Label>
                        <InputGroup className={styles.discountFormWidth}>
                            <Form.Control
                            required
                            type="text"
                            placeholder="Desconto negociado (%)"
                            value={formObject.negotiatedDiscPerc || ''}
                            onChange={(e) => setFields('negotiatedDiscPerc', e.target.value)}
                            isInvalid={formErrors.negotiatedDiscPerc}
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control.Feedback type='invalid'>{formErrors.negotiatedDiscPerc}</Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>

                        <Form.Group className={styles.formFlex}>
                        <Form.Label>4. Valor do desconto negociado:</Form.Label>
                        <InputGroup>
                            <span className={styles.discountAmount}>
                            {'R$ ' + calcDiscountedValue()}
                            <span style={{marginLeft: '10px', fontSize: '14px', opacity: '0.8'}}>
                                {'(' + taxaImpostoAtual.toFixed(2) + '% de ICMS)'}
                            </span>
                            </span>
                        </InputGroup>
                        </Form.Group>
                    </Form>
                    </div>
                </article>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                <Button
                    className={styles.iconCursor}
                    variant="outline-danger"
                    onClick={handleCloseAcomp}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={formObject.negotiatedDiscPerc < 0 || formObject.negotiatedDiscPerc === null || isNaN(formObject.negotiatedDiscPerc)}
                    className={styles.iconCursor}
                    variant="primary"
                    onClick={() => { setLoading(true); negociaDesconto(); }}
                >
                    Processar
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Acompanhamento;