import React, { useState } from "react";
import { Button, Modal, Table, Accordion, Form } from 'react-bootstrap';
import Select from 'react-select';
import ModalConfirmacaoPend from "./ModalConfirmacaoPend";
import styles from '../../../../styles/ADM/Desconto/EditarPendenciar.module.scss';
import { toast } from 'react-toastify';
import { api }  from "../../../../services/api";
import CookiesService from "../../../../services/cookies";
import { toDatetime } from "../../../../utils/dateUtils";

const ModalPendenciar = ({ showPendenciar, setShowPendenciar, handleClosePendenciar
                         , linhaSelecionada, statusData, defeitosData, setDefeitosData, setMainData }) => {

    const [consideracoes, setConsideracoes] = useState('')
    const [defeitoSelecionado, setDefeitoSelecionado] = useState(null)
    const [showConfirmacao, setShowConfirmacao] = useState(false)

    const [showModalPend, setShowModalPend] = useState(false)
    const [showEditPend, setShowEditPend] = useState(false)
    const [showDelPend, setShowDelPend] = useState(false)

    const handleShowModalPend = () => {
        setShowPendenciar(false)
        setShowModalPend(true)
    }

    const handleCloseModalPend = () => {
        setShowPendenciar(true)
        setShowModalPend(false)
    }

    const handleShowEditPend = () => {
    }

    const handleCloseEditPend = () => {
    }

    const handleShowDelPend = () => {
    }

    const handleCloseDelPend = () => {
    }

    const handleShowConfirmacao = () => {
        if (!defeitoSelecionado){
            toast.warning('Defeito precisa ser selecionado.'); 
            return
        } 
        setShowModalPend(false)
        setShowConfirmacao(true)
    }
    const handleCloseConfirmacao = () => {
        setShowConfirmacao(false)
        setShowModalPend(true)
        setDefeitoSelecionado('')
    }

    const handleResetPendenciar = () => {
        handleClosePendenciar()
        setShowModalPend(false)
        setShowConfirmacao(false)
        setDefeitoSelecionado(null)
        setConsideracoes('')
        setDefeitosData([])
        setMainData([])
    }

    const handleCriarPendencia = async () => {   
        const params = { log: {
                            NfEntrada: linhaSelecionada.NfEntrada,
                            Material: linhaSelecionada.Material,
                            CorMaterial: linhaSelecionada.CorMaterial,
                            Item: linhaSelecionada.Item,
                            IdTipoProcesso: 2,
                            Usuario: CookiesService.getUserLogin(),
                            DataProcesso: toDatetime(new Date())
                        },
                         defeito: {
                            NfEntrada: linhaSelecionada.NfEntrada,
                            SerieNf: linhaSelecionada.SerieNf,
                            Fornecedor: linhaSelecionada.Fornecedor,
                            Material: linhaSelecionada.Material,
                            CorMaterial: linhaSelecionada.CorMaterial,
                            QtdeEntrada: linhaSelecionada.QtdeEntrada,
                            Item: linhaSelecionada.Item,
                            IdDefeito: defeitoSelecionado,
                            Consideracao: consideracoes
                        } 
        }

        await api.post('ADM/Desconto/EditarPendenciar/pendenciar', { params })
            .then(res => {
                if (res.status === 200) { 
                    handleResetPendenciar();
                    toast.success('Dado inserido com sucesso!');
                } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
            }).catch(e => {
                toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
                console.error(`Erro na requisição - ${e}`);
        });      
    }

    return (
        <>

        <Modal
            show={showPendenciar}
            onHide={handleClosePendenciar}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Pendência</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.divButtons}>
                { !linhaSelecionada.IdDefeito ? 
                    <Button
                        type="button"
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={handleShowModalPend}
                    >
                        Pendenciar
                    </Button>
                    :
                    <Button
                        variant='secondary'
                        type="button"
                        disabled
                    >
                        Pendenciar
                    </Button>
                }

                { !!linhaSelecionada.IdDefeito ? 
                    <Button
                        type="button"
                        variant="outline-warning"
                        className={styles.modalBtn}
                        // onClick={}
                    >
                        Editar
                    </Button>
                    :
                    <Button
                        variant='secondary'
                        type="button"
                        disabled
                    >
                        Editar
                    </Button>
                }
                <Button
                    type="button"
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleClosePendenciar}
                >
                    Voltar
                </Button>
            </Modal.Body>       
        </Modal>

        <Modal
            show={showModalPend}
            onHide={handleCloseModalPend}
            backdrop="static"
            keyboard={false}
            centered
            fullscreen
        >
            <Modal.Header>
                <Modal.Title>Pendenciar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <article>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Detalhes</Accordion.Header>
                            <Accordion.Body className={styles.infoBlockDisplayPend}>
                            <div className={styles.infoBlockPend}>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Nota Fiscal:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.NfEntrada}</span>
                                </div>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Material:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.Material}</span>
                                </div>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Cor Material:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.CorMaterial}</span>
                                </div>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Item:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.Item}</span>
                                </div>
                            </div>
                            <div className={styles.infoBlockPend}>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Fornecedor:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.Fornecedor}</span>
                                </div>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Descrição Material:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.DescMat}</span>
                                </div>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Descrição Cor:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.DescCor}</span>
                                </div>
                                <div className={styles.infoBlockColummPend}>
                                    <span>Quantidade Entrada:</span>
                                    <span className={styles.fontBlue}>{linhaSelecionada.QtdeEntrada} m</span>
                                </div>
                            </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </article>
                    <Table className={styles.tablePendenciar} responsive>
                        <thead>
                            <tr>
                                <th className={styles.blockTitlePendenciaDefeitos}>DEFEITOS</th>
                                <th className={styles.blockTitleEditar}>CONSIDERAÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.tableLinesEditar}>
                                    <Form className={styles.form}>
                                        <Form.Group className={styles.formGroupDown}>
                                            <Select 
                                                closeMenuOnSelect={true}                                  
                                                options={defeitosData} 
                                                onChange={e=>(setDefeitoSelecionado(e.value))}
                                            />
                                        </Form.Group>
                                    </Form>
                                </td>
                                <td className={styles.tableLinesEditar}>
                                    <Form className={styles.form}>
                                        <Form.Group className={styles.formGroupDown}>
                                            <Form.Control type="text" onChange={e=>(setConsideracoes(e.target.value))}/>
                                        </Form.Group>     
                                    </Form>                                   
                                </td>
                            </tr>
                        </tbody>
                    </Table>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
                <Button
                    variant="outline-danger"
                    className={styles.modalBtn}
                    onClick={handleCloseModalPend}
                >
                    Voltar
                </Button>
                <Button
                    variant="outline-success"
                    className={styles.modalBtn}
                    onClick={handleShowConfirmacao}
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
        
        <ModalConfirmacaoPend 
            showConfirmacao={showConfirmacao}
            handleCloseConfirmacao={handleCloseConfirmacao}
            handleCriarPendencia={handleCriarPendencia}
            linhaSelecionada={linhaSelecionada}
            statusData={statusData}
            consideracoes={consideracoes}
            defeitosData={defeitosData}
            defeitoSelecionado={defeitoSelecionado}
        />
        </>
    )
}

export default ModalPendenciar;
