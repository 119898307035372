import React from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';
import styles from '../../../../styles/Corte/ControleEnfesto/LancamentoCad.module.scss';
import { useLancamentoCad } from '../../../../contexts/Corte/ControleEnfesto/LancamentoCadContext'; 
import DadosAccordion from "./DadosAccordion";

const ModalLocalTipoPlanta = () => {

    const { habilitaLocal, habilitaAvancar, handleShowModalMateriais, handleQtdeLocalizacao, qtdeLocalizacao, tipoCorteSelecionado, plantaCorteSelecionado, setTipoCorteSelecionado, setPlantaCorteSelecionado, showModalLocalTipoPlanta, handleCloseModalLocalTipoPlanta, tipoCorteData, plantaCorteData } = useLancamentoCad();

    return (
        <>
            <Modal
                show={showModalLocalTipoPlanta}
                onHide={handleCloseModalLocalTipoPlanta}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Insira as informações a seguir, por produto:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DadosAccordion/>

                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label><strong>Selecione o tipo de corte:</strong></Form.Label>
                            <Select
                                className={styles.formControl}
                                closeMenuOnSelect={true}
                                value={tipoCorteSelecionado || ''}
                                options={tipoCorteData}
                                getOptionLabel={(option)=>option.desc_producao_tipo_corte}
                                getOptionValue ={(option)=>option.id_producao_tipo_corte}
                                onChange={(e) => setTipoCorteSelecionado(e)}
                            />
                        </Form.Group>
                    </Form>
                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label><strong>Localizações:</strong></Form.Label>
                            <Form.Control
                                type="text"
                                className={styles.formControl}
                                placeholder={!habilitaLocal ? '0' : "Número de Localizações"}
                                value={qtdeLocalizacao || ''}
                                onChange={e => handleQtdeLocalizacao(e)}
                                disabled={!habilitaLocal}
                            />
                        </Form.Group>
                    </Form>
                    <Form className={styles.formWrapperModal}>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Label><strong>Selecione a planta de corte:</strong></Form.Label>
                            <Select
                                className={styles.formControl}
                                closeMenuOnSelect={true}
                                value={plantaCorteSelecionado || ''}
                                options={plantaCorteData}
                                getOptionLabel={(option)=>option.DescricaoPlantaCorte}
                                getOptionValue ={(option)=>option.IdPlantaCorte}
                                onChange={(e) => setPlantaCorteSelecionado(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseModalLocalTipoPlanta}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant={habilitaAvancar ? "outline-success" : 'secondary'}
                        className={styles.modalBtn}
                        onClick={habilitaAvancar ? handleShowModalMateriais : null}
                        disabled={!habilitaAvancar}
                    >
                        Avançar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )


}

export default ModalLocalTipoPlanta;
