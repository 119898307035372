import React, { useEffect, useState } from 'react';
import { Button, Table, Form, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import styles from '../styles/EditarExcluirRapport.module.scss';
import Carregando from '../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import OPInfoColumn from "../components/MedicaoRapport/OPInfoColumn";
import { api }  from '../services/api';
import { filterMask } from '../utils/maskUtils';
import CookiesService from '../services/cookies';
import { toDatetime } from '../utils/dateUtils';
import CustomPagination from '../components/Utils/CustomPagination';

const EditarExcluirRapport = () => {
  const [mainData, setMainData] = useState([]);
  const [activeProcess, setActiveProcess] = useState({});
  const [filterObj, setFilterObj] = useState({});
  const [selected, setSelected] = useState(0);
  const [lineSelected, setLineSelected] = useState({});
  const [showToggleRapportModal, setShowToggleRapportModal] = useState({ show: false, isValid: null });
  const [loading, setLoading] = useState(false);

  const [showModalPecas, setShowModalPecas] = useState(false);
  const [showModalEdicao, setShowModalEdicao] = useState(false);
  const [listaPecas, setListaPecas] = useState([]);
  const [pecasSelecionadas, setPecasSelecionadas] = useState([]);
  const [analisesInseridas, setAnalisesInseridas] = useState([]);
  const [analise, setAnalise] = useState([]);
  const [formPecaMedida, setFormPecaMedida] = useState({});
  const [formInfo, setFormInfo] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [flagSelectedAllPecasAnalisadas, setFlagSelectedAllPecasAnalisadas] = useState(false);
  const userLogin = CookiesService.getUserLogin();

  const [currentData, setCurrentData] = useState([]);
  const [quantityPerPage, setQuantityPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleCloseToggleModal = () => {
    setActiveProcess({});
    setShowToggleRapportModal({ show: false, isValid: null });
  }

  const handleCloseEditModal = () => {
    setShowModalPecas(false);
    setListaPecas([]); setAnalise([]);
    setFormInfo({});

    setFormPecaMedida({});
    setShowModalEdicao(false);
    setFlagSelectedAllPecasAnalisadas(false);
    setPecasSelecionadas([]);
    setAnalisesInseridas([]);
    setFormErrs({});
  }

  const handleBackToModalPecas = () => {
    setShowModalPecas(true);
    setFormInfo({ medidaInfoFixa: formInfo.medidaInfoFixa, medidaInfo: undefined, resultado: undefined });

    setFormPecaMedida({});
    setShowModalEdicao(false);
    setFlagSelectedAllPecasAnalisadas(false);
    setPecasSelecionadas([]);
    setAnalisesInseridas([]);
    setFormErrs({});
  }

  function splitObjectIntoColumns(header, value) {
    let obj = {}
    header.forEach((v, i) => {
      obj[v] = value[i];
    })

    const entries = Object.entries(obj);

    const numEntries = entries.length;

    const numCols = 4;
    const targetNumPerCol = Math.ceil(numEntries / numCols);

    const columns = Array.from({ length: numCols }, (_, i) => {
      const start = i * targetNumPerCol;
      const end = start + targetNumPerCol;
      return entries.slice(start, end);
    });

    return columns.map((col, i) => {
      return (
        <OPInfoColumn
          key={i}
          columns={col.map(([label, value]) => ({ label, value }))}
        />
      );
    });
  }

  const OpInfo = [
    "Pedido",
    "Fornecedor",
    "Nota Fiscal",
    "Série NF",
    "Tipo Material",
    "Item",
    "Material",
    "Cor Material",
    "Descrição Material",
    "Descrição cor material",
    "Qtde. peças",
  ]

  const handleSearchValidation = () => {
    const errs = {}
    if (!filterObj.NFEntrada || filterObj.NFEntrada === '') errs.NFEntrada = true;
    if (!filterObj.Material || filterObj.Material === '') errs.Material = true;
    if (!filterObj.CorMaterial || filterObj.CorMaterial === '') errs.CorMaterial = true;
    return errs;
  }

  const handleSearch = async (afterProcess = false) => {
    const errs = handleSearchValidation();
    if (Object.keys(errs).length > 2 && !afterProcess) return toast.warn('Por favor preencha ao menos um campo de filtro para continuar.');
    let params = {}
    if (!afterProcess) params = { ...filterObj }
    else params = { NFEntrada: activeProcess.NFEntrada, Material: activeProcess.Material, CorMaterial: activeProcess.CorMaterial }

    await api.get('/MPRapportUD/GetMainData', { params })
      .then(res => {
        setMainData(res.data);
        setCurrentPage(1);
      })
      .catch(e => {
        toast.error('Ocorreu um erro durante a busca dos pedidos, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
        console.error(e);
      });
  }

  const handleProcessingToggle = async () => {
    const params = {
      ...activeProcess,
      usuario: userLogin,
      dataRevisao: toDatetime(new Date())
    }
    await api.post('/MPRapportUD/ToggleActive', params)
      .then(() => {
        setShowToggleRapportModal({ show: false, isValid: null });
        toast.success('Alteração feita com sucesso!');
        handleSearch(true);
        setActiveProcess({});
      })
      .catch(e => {
        toast.error('Ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
        console.error(e);
      });
    setLoading(false);
  }

  const handleStartEditing = async (process) => {
    const params = { ...process }
    let resListaPecas; let resAnalise;
    try {
      await api.get('/MPRapportUD/GetPecas', { params })
        .then(res => resListaPecas = res.data)
        .catch(e => { throw e });

      await api.get('/MPRapportUD/GetAnalise', { params })
        .then(res => resAnalise = res.data)
        .catch(e => { throw e });

      // MARCA AS ANÁLISES JÁ EXISTENTES E ALTERA O VALOR DE FORMINFO.MEDIDAINFO PARA SER MOSTRADA PARA O USUÁRIO
      if (resAnalise.length > 0) {
        setFormInfo({ ...formInfo, medidaInfoFixa: resAnalise[0].MedidaInfo });
      } else if (resAnalise.length === 0) {
        return toast.warn('Esta nota não possui análises ainda e não pode ter suas informações editadas.');
      }
      setListaPecas(resListaPecas); setAnalise(resAnalise);
      setShowModalPecas(true);
    } catch (e) {
      console.error(e);
      toast.error('Ocorreu um erro durante a busca pelos dados desse pedido, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
    }
  }

  const handleSortPecasSelecionadas = (a, b) => {
    if (a.Peca < b.Peca) return -1;
    if (a.Peca > b.Peca) return 1;
    if (a.Peca === b.Peca) return 0;
  }

  const handleCheckPeca = (item) => {
    const index = pecasSelecionadas.findIndex(peca => peca.Peca === item.Peca);
    index >= 0 ? pecasSelecionadas.splice(index, 1) : pecasSelecionadas.push(item);
    pecasSelecionadas.sort(handleSortPecasSelecionadas);
    setPecasSelecionadas([...pecasSelecionadas]);
  }

  const handleCheckAll = () => {
    if (pecasSelecionadas.length === listaPecas.length) setPecasSelecionadas([]);
    else setPecasSelecionadas([...listaPecas]);
  }

  const handleGoToEditModal = () => {
    if (pecasSelecionadas.length === 0) return toast.warn('Por favor, selecione ao menos uma peça para edição ou adição');

    if (analise.length > 0) {
      const listaPecasSelecionadas = pecasSelecionadas.map(pecas => pecas.Peca);
      const pecasComAnalise = listaPecas.filter(peca => peca.IDAnalise).map(analise => analise.Peca);

      // CHECA SE TODAS AS PEÇAS COM ANÁLISE FORAM SELECIONADAS; 
      let checker = ((arr1, arr2) => arr2.every(val => arr1.includes(val)))(listaPecasSelecionadas, pecasComAnalise);
      if (!checker) setFormInfo({ ...formInfo, medidaInfo: formInfo.medidaInfoFixa });
      else setFlagSelectedAllPecasAnalisadas(true);
    }
    setShowModalPecas(false); setShowModalEdicao(true);
  }

  const handleInsertAnaliseValidation = () => {
    const errs = {}
    if (!formPecaMedida.peca || formPecaMedida.peca === '') errs.peca = true;
    if (!formPecaMedida.metragem || formPecaMedida.metragem === '') errs.metragem = true;
    if (!formPecaMedida.medidaUm || formPecaMedida.medidaUm === '' || formPecaMedida.medidaUm === '0.00') errs.medidaUm = true;
    if (!formPecaMedida.medidaDois || formPecaMedida.medidaDois === '' || formPecaMedida.medidaDois === '0.00') errs.medidaDois = true;
    if (!formPecaMedida.medidaTres || formPecaMedida.medidaTres === '' || formPecaMedida.medidaTres === '0.00') errs.medidaTres = true;
    if (!formInfo.medidaInfo || formInfo.medidaInfo === '' || formPecaMedida.medidaInfo === '0.00') errs.medidaInfo = true;
    return errs;
  }

  const handleInsertAnalise = () => {
    const errs = handleInsertAnaliseValidation();
    if (Object.keys(errs).length > 0) return setFormErrs(errs);
    setFormErrs({});

    const params = {
      peca: formPecaMedida.peca,
      metragem: formPecaMedida.metragem,
      medidaUm: formPecaMedida.medidaUm,
      medidaDois: formPecaMedida.medidaDois,
      medidaTres: formPecaMedida.medidaTres,
      medidaInfo: parseFloat(formInfo.medidaInfo).toFixed(2),
      tolerancia: (() => {
        if (activeProcess?.Tipo === 'MALHA') return 7;
        else if (activeProcess?.Tipo === 'TECIDO') return 5;
        else return 0;
      })(),
      idStatus: formInfo.idStatus,
      idAnalise: formPecaMedida.idAnalise
    }
    setPecasSelecionadas(pecasSelecionadas.filter(pecaSelecionada => pecaSelecionada.Peca !== params.peca));
    analisesInseridas.push(params);
    analisesInseridas.sort((a, b) => {
      if (a.peca < b.peca) return -1;
      if (a.peca > b.peca) return 1;
      if (a.peca === b.peca) return 0;
    });
    setAnalisesInseridas([...analisesInseridas]);
    setFormPecaMedida({});
  }

  const handleDeleteAnalise = (analise) => {
    pecasSelecionadas.push({ Peca: analise.peca, Metragem: analise.metragem, IDAnalise: analise.idAnalise });
    pecasSelecionadas.sort(handleSortPecasSelecionadas);
    setPecasSelecionadas([...pecasSelecionadas]);
    setAnalisesInseridas([...analisesInseridas.filter(item => item.peca !== analise.peca)]);
  }

  const handleProcessarDelecao = async (item) => {
    const params = { ...activeProcess }

    try {
      await api.post('/MPRapportUD/DeleteAnalise', { idAnalise: item.IDAnalise })
        .catch(e => { throw e });

      await api.get('/MPRapportUD/GetPecas', { params })
        .then(res => setListaPecas(res.data))
        .catch(e => { throw e });
      toast.success('Análise removida com sucesso!');
    } catch (e) {
      console.error(e);
      toast.error('Ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
      handleCloseEditModal();
    }

    setLoading(false);
  }

  const handleProcessarEdicao = async () => {
    let idStatusFinal;
    const ignorarIDs = analisesInseridas.filter(analise => analise.idAnalise).map(analise => analise.idAnalise);
    const params = { ...activeProcess, ignorarIDs }
    try {
      await api.get('/MPRapportUD/GetAnaliseMedidas', { params })
        .then(res => {
          const dadosAnalise = res.data.concat(analisesInseridas);
          idStatusFinal = dadosAnalise.some((analise) => analise.idStatus === 8) ? 8 : 4;
        })
        .catch(e => { throw e });

      const data = {
        NFEntrada: activeProcess.NFEntrada,
        SerieNF: activeProcess.SerieNF,
        Material: activeProcess.Material,
        CorMaterial: activeProcess.CorMaterial,
        Pedido: activeProcess.Pedido,
        Fornecedor: activeProcess.Fornecedor,
        QtdePecas: activeProcess.QtdePecas,
        TipoMaterial: activeProcess.Tipo,
        Item: activeProcess.Item,
        usuario: userLogin,
        dataRevisao: toDatetime(new Date()),
        idStatusFinal: idStatusFinal,
        medidaInfo: formInfo.medidaInfo
      }

      await api.post('/MPRapportUD/EditAnalise', { data, analisesInseridas })
        .then(() => {
          toast.success('Análise(s) atualizada(s) com sucesso!');
          handleSearch(true);
          handleCloseEditModal();
        })
        .catch(e => { throw e });
    } catch (e) {
      console.error(e);
      toast.error('Ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
    }

    setLoading(false);
  }

  const handleMedidaMask = (value, field) => {
    if (value && value !== undefined) value = `${value.replace(/[^0-9]*/g, '')}`;
    value = parseFloat(value / 100).toFixed(2);
    if (field === 'medidaInfo') setFormInfo({ ...formInfo, [field]: value });
    else setFormPecaMedida({ ...formPecaMedida, [field]: value });
  }

  useEffect(() => {
    let maxDiff = 0;
    if (activeProcess?.Tipo === 'MALHA') maxDiff = 0.07;
    else if (activeProcess?.Tipo === 'TECIDO') maxDiff = 0.05;
    if (
      Math.abs((formInfo?.medidaInfo - formPecaMedida?.medidaUm) / formInfo?.medidaInfo) > maxDiff ||
      Math.abs((formInfo?.medidaInfo - formPecaMedida?.medidaDois) / formInfo?.medidaInfo) > maxDiff ||
      Math.abs((formInfo?.medidaInfo - formPecaMedida?.medidaTres) / formInfo?.medidaInfo) > maxDiff
    ) {
      return setFormInfo({ ...formInfo, resultado: 'Reprovado', idStatus: 8 });
    } else return setFormInfo({ ...formInfo, resultado: 'Aprovado', idStatus: 4 });
  }, [formInfo.medidaInfo, formPecaMedida.medidaUm, formPecaMedida.medidaDois, formPecaMedida.medidaTres]);

  useEffect(() => {
    const start = (currentPage - 1) * quantityPerPage;
    const end = (currentPage * quantityPerPage);
    setCurrentData(mainData.slice(start, end));
  }, [currentPage, mainData]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <article>
        <h3 className="p-3">Editar/Excluir Rapport</h3>
        <Form className={styles.formWrapper}>
          <Form.Group className={styles.filterInput}>
            <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Nota fiscal'}
              className={styles.formControl}
              value={filterObj.NFEntrada || ''}
              onChange={(e) => filterMask('NFEntrada', e.target.value, filterObj, setFilterObj)}
            />
          </Form.Group>
          <Form.Group className={styles.filterInput}>
            <Form.Label className={styles.formLabel}>Material</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Material'}
              className={styles.formControl}
              value={filterObj.Material || ''}
              onChange={(e) => filterMask('Material', e.target.value, filterObj, setFilterObj)}
            />
          </Form.Group>
          <Form.Group className={styles.filterInput}>
            <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Cor Material'}
              className={styles.formControl}
              value={filterObj.CorMaterial || ''}
              onChange={(e) => filterMask('CorMaterial', e.target.value, filterObj, setFilterObj)}
            />
          </Form.Group>
          <Button
            type="button"
            variant="primary"
            className={styles.filterBtn}
            onClick={() => handleSearch()}
          >
            Buscar
          </Button>
        </Form>
      </article>
      <article>
        <CustomPagination
          totalRecords={mainData.length / quantityPerPage}
          quantityPerPage={3}
          currentPage={currentPage}
          onPageChange={n => setCurrentPage(n)}
        ></CustomPagination>
      </article>

      <article className={styles.articleGray}>
        <Table className="m-2" responsive>
          <thead>
            <tr>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}></th>
              <th className={styles.blockTitle}>PEDIDO</th>
              <th className={styles.blockTitle}>NOTA FISCAL</th>
              <th className={styles.blockTitle}>SERIE NF</th>
              <th className={styles.blockTitle}>ITEM</th>
              <th className={styles.blockTitle}>FORNECEDOR</th>
              <th className={styles.blockTitle}>MATERIAL</th>
              <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
              <th className={styles.blockTitle}>COR MATERIAL</th>
              <th className={styles.blockTitle}>DESCRIÇÃO COR MATERIAL</th>
              <th className={styles.blockTitle}>QTDE PEÇAS</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, index) => {
              return (
                <tr key={index}>
                  <td>
                    <i
                      style={{
                        cursor: row.Valido === true ? 'pointer' : 'default',
                        opacity: row.Valido === true ? '1' : '0.35'
                      }}
                      className={styles.icon}
                      onClick={() => {
                        setActiveProcess(row);
                        row.Valido === true ?
                          handleStartEditing(row)
                          :
                          toast.warn('É necessário reativar o processo de rapport para esse pedido antes de edita-lo!');
                      }
                      }
                    >
                      <MaterialIcon
                        icon="edit"
                        size={30}
                        color='#01A34C'
                      />
                    </i>
                  </td>
                  <td aria-disabled>
                    <i
                      style={{ cursor: 'pointer', display: row.Valido === true ? 'block' : 'none' }}
                      className={styles.icon}
                      onClick={() => {
                        setShowToggleRapportModal({ show: true, isValid: row.Valido });
                        setActiveProcess(row);
                      }}
                    >
                      <MaterialIcon
                        icon={'delete_forever'}
                        size={30}
                        color={'#E90101'}
                      />
                    </i>
                    <i
                      style={{ cursor: 'pointer', display: row.Valido === false ? 'block' : 'none' }}
                      className={styles.icon}
                      onClick={() => {
                        setShowToggleRapportModal({ show: true, isValid: row.Valido });
                        setActiveProcess(row);
                      }}
                    >
                      <MaterialIcon
                        icon={'add_circle'}
                        size={30}
                        color={'#01A34C'}
                      />
                    </i>
                  </td>
                  <td className={styles.tableLines}>{row.Pedido}</td>
                  <td className={styles.tableLines}>{row.NFEntrada}</td>
                  <td className={styles.tableLines}>{row.SerieNF}</td>
                  <td className={styles.tableLines}>{row.Item}</td>
                  <td className={styles.tableLines}>{row.Fornecedor}</td>
                  <td className={styles.tableLines}>{row.Material}</td>
                  <td className={styles.tableLines}>{row.DescricaoCor}</td>
                  <td className={styles.tableLines}>{row.CorMaterial}</td>
                  <td className={styles.tableLines}>{row.DescricaoMaterial}</td>
                  <td className={styles.tableLines}>{row.QtdePecas}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </article>

      {/* Modal - Escolher peças */}
      <Modal
        show={showModalPecas}
        onHide={handleCloseEditModal}
        fullscreen={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Escolha a(s) peça(s) para modificação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            As peças marcadas abaixo já foram analisadas usando a <b>medida info</b>&nbsp;
            <span style={{ color: 'red' }}>{formInfo?.medidaInfoFixa ? parseFloat(formInfo?.medidaInfoFixa).toFixed(2) : null}</span> m.
            <br />Para alterar a <b>medida info</b>, todas as análises anteriores deverão ser alteradas ou removidas.
          </h6>

          <article className={styles.articleGray}>
            <Table>
              <thead>
                <tr>
                  <th className={styles.blockTitle}>
                    <Form>
                      <Form.Check
                        type='checkbox'
                        onChange={handleCheckAll}
                        checked={pecasSelecionadas.length === listaPecas.length}
                      />
                    </Form>
                  </th>
                  <th className={styles.blockTitle}>PEÇA</th>
                  <th className={styles.blockTitle}>METRAGEM</th>
                  <th className={styles.blockTitle}></th>
                </tr>
              </thead>
              <tbody>
                {listaPecas.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: item.IDAnalise ? '#E9010130' : 'initial'
                      }}
                    >
                      <td className={styles.tableLines}>
                        <Form>
                          <Form.Check
                            type='checkbox'
                            onChange={() => handleCheckPeca(item)}
                            checked={pecasSelecionadas.includes(item)}
                          />
                        </Form>
                      </td>
                      <td className={styles.tableLines}>{item.Peca}</td>
                      <td className={styles.tableLines}>{item.Metragem}</td>
                      <td className={styles.tableLines}>
                        <i
                          style={{ display: item.IDAnalise ? 'block' : 'none', marginTop: '6px' }}
                          onClick={() => { setLoading(true); handleProcessarDelecao(item); }}
                        >
                          <MaterialIcon
                            icon={'delete_forever'}
                            size={30}
                            color={'#E9010197'}
                          />
                        </i>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </article>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseEditModal}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => handleGoToEditModal()}
          >
            Avançar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal - Medição Rapport */}
      <Modal
        show={showModalEdicao}
        onHide={handleCloseEditModal}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Medição de Rapport - Editar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Dados do pedido</Accordion.Header>
              <Accordion.Body className={styles.infoBlockDisplay}>
                {splitObjectIntoColumns(
                  OpInfo,
                  !!selected
                    ? lineSelected
                    : Array.from('-'.repeat(OpInfo.length)).map(v => v.repeat(3))
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <article className={styles.articleGrayRow}>
            <div className={styles.articleItemMarginPecas}>
              <h6 className={styles.formLabel}>Peças</h6>
              <div className={styles.articleItemWhite}
                style={{ border: formErrs.peca ? '1px solid red' : '1px solid white' }}
                onClick={() => setFormErrs({ ...formErrs, peca: false })}
              >
                <Table className={styles.tableStyle} responsive>
                  <tbody>
                    {pecasSelecionadas.map((item, index) => {
                      return (
                        <tr
                          className={styles.tableLines}
                          key={index}
                          onClick={() => setFormPecaMedida(
                            { ...formPecaMedida, peca: item.Peca, metragem: item.Metragem, idAnalise: item.IDAnalise }
                          )}
                          style={{
                            backgroundColor: item.IDAnalise ? '#E9010130' : 'initial'
                          }}
                        >
                          <td>
                            <Form.Check
                              type={'radio'}
                              name='radioPecas'
                              value={item.Peca}
                              onChange={e => setFormPecaMedida({ ...formPecaMedida, peca: e.target.value, metragem: item.Metragem, idAnalise: item.IDAnalise })}
                              checked={formPecaMedida?.peca === item.Peca}
                            />
                          </td>
                          <td className={styles.tableLines}>{item.Peca}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>

            <div className={styles.articleItemMargin}>
              <Form className={styles.formModal}>
                <Form.Group className={styles.filterInput}>
                  <Form.Label className={styles.formLabel}>Medidas da info:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={'0.00'}
                    value={formInfo.medidaInfo ? parseFloat(formInfo.medidaInfo).toFixed(2) : ''}
                    onChange={e => handleMedidaMask(e.target.value, 'medidaInfo')}
                    disabled={!flagSelectedAllPecasAnalisadas || analisesInseridas.length > 0}
                    isInvalid={formErrs.medidaInfo}
                    onFocus={() => setFormErrs({ ...formErrs, medidaInfo: false })}
                    required
                  />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                  <Form.Label className={styles.formLabel}>Resultado final:</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={formInfo.resultado || ''}
                  />
                </Form.Group>
              </Form>
            </div>

            <div className={styles.articleItemMargin}>
              <Form className={styles.formModal}>
                <Form.Group className={styles.filterInput}>
                  <Form.Label className={styles.formLabel}>Medida encontrada 1:</Form.Label>
                  <Form.Control
                    type="text"
                    value={formPecaMedida.medidaUm || ''}
                    onChange={e => handleMedidaMask(e.target.value, 'medidaUm')}
                    placeholder={'0.00'}
                    isInvalid={formErrs.medidaUm}
                    onFocus={() => setFormErrs({ ...formErrs, medidaUm: false })}
                    required
                  />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                  <Form.Label className={styles.formLabel}>Medida encontrada 2:</Form.Label>
                  <Form.Control
                    type="text"
                    value={formPecaMedida.medidaDois || ''}
                    onChange={e => handleMedidaMask(e.target.value, 'medidaDois')}
                    placeholder={'0.00'}
                    isInvalid={formErrs.medidaDois}
                    onFocus={() => setFormErrs({ ...formErrs, medidaDois: false })}
                    required
                  />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                  <Form.Label className={styles.formLabel}>Medida encontrada 3:</Form.Label>
                  <Form.Control
                    type="text"
                    value={formPecaMedida.medidaTres || ''}
                    onChange={e => handleMedidaMask(e.target.value, 'medidaTres')}
                    placeholder={'0.00'}
                    isInvalid={formErrs.medidaTres}
                    onFocus={() => setFormErrs({ ...formErrs, medidaTres: false })}
                    required
                  />
                </Form.Group>
              </Form>
            </div>
            <div className={styles.articleItemMarginBottom}>
              <Button
                className={styles.filterInput}
                variant="success"
                type="button"
                onClick={handleInsertAnalise}
              >
                Editar/Adicionar Medidas
              </Button>
            </div>
          </article>

          {/* tabela de medidas inseridas */}
          <article>
            <div className={styles.tableWrapper}>
              <Table className={styles.tableStyle} responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th className={styles.blockTitle}>PEÇAS</th>
                    <th className={styles.blockTitle}>MEDIDA ENCONTRADA 1</th>
                    <th className={styles.blockTitle}>MEDIDA ENCONTRADA 2</th>
                    <th className={styles.blockTitle}>MEDIDA ENCONTRADA 3</th>
                    <th className={styles.blockTitle}>RESULTADO</th>
                  </tr>
                </thead>
                <tbody>
                  {analisesInseridas.map((analise, index) => {
                    return (
                      <tr
                        className={styles.tableLines}
                        key={index}
                        style={{ backgroundColor: analise.idAnalise ? '#E9010130' : 'initial' }}
                      >
                        <td>
                          <i
                            className={styles.icon}
                            style={{ 'cursor': 'pointer' }}
                            onClick={() => handleDeleteAnalise(analise)}
                          >
                            <MaterialIcon
                              icon="delete"
                              size={20}
                              color='#BB271A'
                            />
                          </i>
                        </td>
                        <td className={styles.tableLines}>{analise.peca}</td>
                        <td className={styles.tableLines}>{analise.medidaUm}</td>
                        <td className={styles.tableLines}>{analise.medidaDois}</td>
                        <td className={styles.tableLines}>{analise.medidaTres}</td>
                        <td className={styles.tableLines}>{analise?.idStatus === 8 ? 'Reprovado' : 'Aprovado'}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleBackToModalPecas}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleProcessarEdicao}
            disabled={pecasSelecionadas.length > 0}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal - Desativação/Reativação Rapport */}
      <Modal
        show={showToggleRapportModal.show === true}
        onHide={handleCloseToggleModal}
        fullscreen={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {'Medição de Rapport - ' +
              (showToggleRapportModal.isValid === true ? 'Desativar' : 'Reativar')
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            showToggleRapportModal.isValid === true ?
              'Deseja remover este pedido do processo de análise de rapport?'
              :
              'Deseja reativar o processo de análise de rapport deste pedido?'
          }
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            variant="outline-danger"
            onClick={handleCloseToggleModal}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => { setLoading(true); handleProcessingToggle(); }}
          >
            {showToggleRapportModal.isValid === true ? 'Desativar' : 'Reativar'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={loading === true}
        fullscreen={false}
        centered={true}
      >
        <Carregando></Carregando>
      </Modal>
    </div>
  );
};

export default EditarExcluirRapport;