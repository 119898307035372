import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Table, Modal, Accordion } from 'react-bootstrap';
import MaterialIcon from 'material-icons-react';
import Select from 'react-select';

import { Link } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";


import styles from ".././../../styles/AprovacaoEstilo.module.scss";
import { api }  from "../../../services/api";
import { toast, ToastContainer } from "react-toastify";
import { toDatetime } from "../../../utils/dateUtils";
import CookiesService from "../../../services/cookies";
import Carregando from "../../Carregando";

function AprovacaoEstiloConsulta () {
    const [showModal, setShowModal] = useState(false);
    const [filterObj, setFilterObj] = useState({});
    const [mainData, setMainData] = useState([]);
    const [activeObj, setActiveObj] = useState({});
    const [formObj, setFormObj] = useState({});
    const [formErrs, setFormErrs] = useState({});
    const [statusOpts, setStatusOpts] = useState([]);
    const [loading, setLoading] = useState(false);
    const userLogin = CookiesService.getUserLogin();

    const handleFetchMainData = async () => {
        setLoading(true);
        await api.get('/MPAprovacaoEstilo/GetMainData')
            .then(res => {
                if (res.status === 200) setMainData(res.data);
            })
            .catch(e => {
                toast.error('Ocorreu um erro ao se preencher a lista de processos, por favor cheque sua conexão com a internet ou entre contato com o suporte');
                console.error(`Erro na requisição '/MPAprovacaoEstilo/GetMainData' - ${e}`);
            });
        setLoading(false);
    }

    const handleFetchStatusOptions = async () => {
        await api.get('/MPAprovacaoEstilo/GetStatusRecebimento')
            .then(res => { if (res.status === 200) setStatusOpts(res.data) })
            .catch(e => console.error(`Erro na requisição '/MPAprovacaoEstilo/GetMainData' - ${e}`));
    }

    const handleSearch = async () => {
        setLoading(true);
        const params = {
            Lacre: filterObj.Lacre,
            Material: filterObj.Material,
            CorMaterial: filterObj.CorMaterial,
            Pedido: filterObj.Pedido,
            NFEntrada: filterObj.NFEntrada
        }
        await api.get('/MPAprovacaoEstilo/GetMainData', {params})
            .then(res => {
                if (res.status === 200) setMainData(res.data);
            })
            .catch(e => {
                toast.error('Ocorreu um erro ao se preencher a lista de processo, por favor cheque sua conexão com a internet ou entre contato com o suporte');
                console.error(`Erro na requisição '/MPAprovacaoEstilo/GetMainData' - ${e}`);
            });
            setLoading(false);
    }

    const handleOpenModal = (index) => {
        setActiveObj(mainData[index]);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setFormObj({});
        setFormErrs({});
        setActiveObj({});
    }

    const handleProcess = async () => {
        const errs = handleValidation();
        if (Object.keys(errs).length > 0) {
            setFormErrs(errs);
        } else {
            const params = {
                ConfirmaRecebimento: formObj.StatusRecebimento.label.includes('RECEBIDO') ? 1 : 0,
                IDStatusRecebimento: formObj.StatusRecebimento.value,
    
                NFEntrada: activeObj.NFEntrada,
                SerieNF: activeObj.SerieNF,
                NomeCliFor: activeObj.NomeCliFor,
                Material: activeObj.Material,
                CorMaterial: activeObj.CorMaterial,
                QtdeEntrada: activeObj.QtdeEntrada,
    
                Item: null,
                ObservacaoRecebimento: formObj.ObservacaoRecebimento,
                DataRegistro: toDatetime(new Date()),
                IDUsuario: userLogin ? userLogin : 'TESTE.LOGIN',
            }
    
            const validation = {
                TipoEnvio: activeObj.TipoEnvio,
    
                Pedido: activeObj.Pedido,
                NomeCliFor: activeObj.NomeCliFor,
                Material: activeObj.Material,
                CorMaterial: activeObj.CorMaterial,
                DataAlteracaoPedido: activeObj.DataAlteracaoPedido,
                IDMotivoEnvio: activeObj.IDMotivoEnvio,
                IDAreaAprovacao: activeObj.IDAreaAprovacao,
    
                NFEntrada: activeObj.NFEntrada,
                SerieNF: activeObj.SerieNF,
                QtdeEntrada: activeObj.QtdeEntrada
            }
            await api.post('/MPAprovacaoEstilo/PostTransacao', {params, validation})
                .then(res => {
                    if (res.status === 200) {
                        handleFetchMainData();
                        setFilterObj({});
                        handleCloseModal();
                        toast.success('Os dados foram atualizados com sucesso!');
                    }
                })
                .catch(e => {
                    toast.error('Ocorreu um erro ao atualizar os dados, por favor entre em contato com o suporte.');
                    console.error(`Erro na requisição '/MPAprovacaoEstilo/PostTransacao' - ${e}`);
                });
        }
    }

    const handleValidation = () => {
        const {StatusRecebimento} = formObj;
        const errs = {}
        if (!StatusRecebimento) errs.StatusRecebimento = 'Selecione uma opção';
        // if (!ObservacaoRecebimento) errs.ObservacaoRecebimento = 'Insira uma observação';
        return errs;
    }

    useEffect(() => {
        handleFetchMainData();
        handleFetchStatusOptions();
    }, []);

    return (
        <div>
            <article className={styles.articleMargim}>
                <Form className={styles.form}>
                    <FormGroup className={styles.formGroup}>
                        <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Nota fiscal'}
                            className={styles.formControl}
                            value={filterObj.NFEntrada || ''}
                            onChange={(event) => setFilterObj({...filterObj, NFEntrada: event.target.value})}
                        />
                    </FormGroup>
                    <FormGroup className={styles.formGroup}>
                        <Form.Label className={styles.formLabel}>Lacre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Lacre'}
                            className={styles.formControl}
                            value={filterObj.Lacre || ''}
                            onChange={(event) => setFilterObj({...filterObj, Lacre: event.target.value})}
                        />
                    </FormGroup>
                    <FormGroup className={styles.formGroup}>
                        <Form.Label className={styles.formLabel}>Material</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Material'}
                            className={styles.formControl}
                            value={filterObj.Material || ''}
                            onChange={(event) => setFilterObj({...filterObj, Material: event.target.value})}
                        />
                    </FormGroup>
                    <FormGroup className={styles.formGroup}>
                        <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Cor material'}
                            className={styles.formControl}
                            value={filterObj.CorMaterial || ''}
                            onChange={(event) => setFilterObj({...filterObj, CorMaterial: event.target.value})}
                        />
                    </FormGroup>
                    <FormGroup className={styles.formGroup}>
                        <Form.Label className={styles.formLabel}>Pedido</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Pedido'}
                            className={styles.formControl}
                            value={filterObj.Pedido || ''}
                            onChange={(event) => setFilterObj({...filterObj, Pedido: event.target.value})}
                        />
                    </FormGroup>
                    <Button 
                        type="button" 
                        variant="primary"
                        className={styles.formGroup} 
                        onClick={handleSearch}
                    >
                        Buscar
                    </Button>
                </Form>
            </article>

            <article className={styles.articleGray}>
                {loading ? <Carregando></Carregando> :
                    <Table className="m-2" responsive="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th className={styles.blockTitle}>CONFIRMA RECEBIMENTO</th>
                                <th className={styles.blockTitle}>MATERIAL</th>
                                <th className={styles.blockTitle}>COR MATERIAL</th>   
                                <th className={styles.blockTitle}>PEDIDO</th>                         
                                <th className={styles.blockTitle}>MOTIVO ENVIO</th>
                                <th className={styles.blockTitle}>LACRE</th>
                                <th className={styles.blockTitle}>DATA ENVIO APROVAÇÃO</th>
                                <th className={styles.blockTitle}>DATA RECEBIMENTO</th>
                                <th className={styles.blockTitle}>RESPONSÁVEL</th>
                                <th className={styles.blockTitle}>VALIDADO</th>
                                <th className={styles.blockTitle}>PRIORIDADE</th>  
                                <th className={styles.blockTitle}>EMPRESA</th>
                                <th className={styles.blockTitle}>NF ENTRADA</th>
                                <th className={styles.blockTitle}>SÉRIE NF</th>
                                <th className={styles.blockTitle}>COD. FORN.</th>
                                <th className={styles.blockTitle}>FORNECEDOR</th>
                                <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                                <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                                <th className={styles.blockTitle}>QTDE ENTRADA</th>
                                <th className={styles.blockTitle}>MARCA</th>
                                <th className={styles.blockTitle}>COLEÇÃO</th>
                                <th className={styles.blockTitle}>TIPO MATERIAL</th>
                                <th className={styles.blockTitle}>GRUPO MATERIAL</th>
                                <th className={styles.blockTitle}>COMPOSIÇÃO</th>
                                <th className={styles.blockTitle}>TIPO COMPRA</th>
                                <th className={styles.blockTitle}>FILIAL</th>
                                <th className={styles.blockTitle}>REQUERIDO POR</th>
                                <th className={styles.blockTitle}>DATA LIBERADO REVISÃO</th>
                                <th className={styles.blockTitle}>RESPONSÁVEL LIBERAÇÃO</th>
                                <th className={styles.blockTitle}>DATA REVISÃO</th>
                                <th className={styles.blockTitle}>REVISOR</th>
                                <th className={styles.blockTitle}>DATA VALIDAÇÃO</th>
                                <th className={styles.blockTitle}>TABELA</th>
                                <th className={styles.blockTitle}>ÁREA APROVAÇÃO</th>
                                <th className={styles.blockTitle}>TIPO ENVIO</th>
                                <th className={styles.blockTitle}>DATA ÚLTIMA ALTERAÇÃO PEDIDO</th>
                                <th className={styles.blockTitle}>ID ÁREA DE APROVAÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mainData.map((el, index) => {
                                return (
                                    <tr className={styles.tableLines} key={index}>
                                        <td>
                                            <i className={styles.hover} hidden={el.ConfirmaRecebimento.trim() === 'NAO'}>
                                                <Link to={'/aprovaramostra'} state={{activeObj: {...el}}}>
                                                    <MaterialIcon
                                                        icon="thumb_up"  // Este ícone é para quando CONFIRMA RECEBIMENTO === SIM
                                                        size={25}
                                                        color='#0D6EFD'
                                                    />
                                                </Link>
                                            </i>
                                            <i 
                                                className={styles.hover}
                                                hidden={el.ConfirmaRecebimento.trim() === 'SIM'}
                                                onClick={() => handleOpenModal(index)} 
                                            >
                                                <MaterialIcon
                                                    icon="task_alt" // Este ícone é para quando CONFIRMA RECEBIMENTO === NAO
                                                    size={30}
                                                    color='#BB271A'
                                                />
                                            </i>
                                        </td>
                                        <td className={styles.tableLine}>{el.ConfirmaRecebimento}</td>
                                        <td className={styles.tableLine}>{el.Material}</td>
                                        <td className={styles.tableLine}>{el.CorMaterial}</td>   
                                        <td className={styles.tableLine}>{el.Pedido}</td>                         
                                        <td className={styles.tableLine}>{el.MotivoEnvio}</td>
                                        <td className={styles.tableLine}>{el.NumeroLacreEnvio}</td>
                                        <td className={styles.tableLine}>{el.DataEnvioAprovacao}</td>
                                        <td className={styles.tableLine}>{el.DataRecebimento}</td>
                                        <td className={styles.tableLine}>{el.ResponsavelRevisao}</td>
                                        <td className={styles.tableLine}>{el.ValidaEstilo}</td>
                                        <td className={styles.tableLine}>{el.Prioridade}</td>  
                                        <td className={styles.tableLine}>{el.Empresa}</td>
                                        <td className={styles.tableLine}>{el.NFEntrada}</td>
                                        <td className={styles.tableLine}>{el.SerieNF}</td>
                                        <td className={styles.tableLine}>{el.CodigoFornecedor}</td>
                                        <td className={styles.tableLine}>{el.NomeCliFor}</td>
                                        <td className={styles.tableLine}>{el.DescricaoMaterial}</td>
                                        <td className={styles.tableLine}>{el.DescricaoCor}</td>
                                        <td className={styles.tableLine}>{el.QtdeEntrada}</td>
                                        <td className={styles.tableLine}>{el.Marca}</td>
                                        <td className={styles.tableLine}>{el.ColecaoCompra}</td>
                                        <td className={styles.tableLine}>{el.Tipo}</td>
                                        <td className={styles.tableLine}>{el.Grupo}</td>
                                        <td className={styles.tableLine}>{el.Composicao}</td>
                                        <td className={styles.tableLine}>{el.TipoCompra}</td>
                                        <td className={styles.tableLine}>{el.Filial}</td>
                                        <td className={styles.tableLine}>{el.RequeridoPor}</td>
                                        <td className={styles.tableLine}>{el.DataLiberadoRevisao}</td>
                                        <td className={styles.tableLine}>{el.FuncionarioLiberacaoRevisao}</td>
                                        <td className={styles.tableLine}>{el.DataRevisao}</td>
                                        <td className={styles.tableLine}>{el.FuncionarioRecebimentoAmostra}</td>
                                        <td className={styles.tableLine}>{el.DataValidacao}</td>
                                        <td className={styles.tableLine}>{el.TABELA}</td>
                                        <td className={styles.tableLine}>{el.AREAAPROVACAO}</td>
                                        <td className={styles.tableLine}>{el.TipoEnvio}</td>
                                        <td className={styles.tableLine}>{el.DataAlteracaoPedido.slice(0, 10)}</td>
                                        <td className={styles.tableLine}>{el.IDAreaAprovacao}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                }
            </article>  

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
                fullscreen={false}
                keyboard={false}
                dialogClassName={styles.widthModal}
                contentClassName={styles.widthModal}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Confirmar recebimento de amostra</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <article>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Dados do Pedido</Accordion.Header>
                                <Accordion.Body className={styles.infoBlockDisplay}>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.infoBlockColumm}>
                                            <span>NF Entrada:</span>
                                            <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Série NF:</span>
                                            <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Empresa:</span>
                                            <span className={styles.fontBlue}>{activeObj.Empresa}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Qtde. Entrada:</span>
                                            <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
                                        </div>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Fornecedor:</span>
                                            <span className={styles.fontBlue}>{activeObj.NomeCliFor}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Material:</span>
                                            <span className={styles.fontBlue}>{activeObj.Material}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Cor Material:</span>
                                            <span className={styles.fontBlue}>{activeObj.CorMaterial}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Pedido:</span>
                                            <span className={styles.fontBlue}>{activeObj.Pedido}</span>
                                        </div>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Cod. Fornecedor:</span>
                                            <span className={styles.fontBlue}>{activeObj.CodigoFornecedor}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Descrição Material:</span>
                                            <span className={styles.fontBlue}>{activeObj.DescricaoMaterial}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Descrição Cor:</span>
                                            <span className={styles.fontBlue}>{activeObj.DescricaoCor}</span>
                                        </div>
                                        <div className={styles.infoBlockColumm}>
                                            <span>Motivo Envio:</span>
                                            <span className={styles.fontRed}>{activeObj.MotivoEnvio}</span>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </article>

                    <article>
                        <div className={styles.marginTop}>
                            <Form className="m-2">
                                <Form.Group className={styles.formControl} style={{marginBottom: '20px'}}>
                                    <Form.Label className={styles.formLabel}>Status Recebimento:</Form.Label>
                                    <Select
                                        className={styles.formSelectModal}
                                        placeholder={'Selecionar'}
                                        closeMenuOnSelect={true}
                                        value={formObj.StatusRecebimento}
                                        onChange={e => setFormObj({...formObj, StatusRecebimento: e})}
                                        options={statusOpts}
                                        styles={{
                                            control: (provided) => (
                                                formErrs.StatusRecebimento ? {...provided, borderColor: '#dc3545'} : {...provided, borderColor: 'hsl(0, 0%, 80%)'}
                                            )
                                        }}
                                    />
                                    <div className={formErrs.StatusRecebimento ? styles.showFeedback : styles.hidden}>{formErrs.StatusRecebimento}</div>
                                </Form.Group>
                                <Form.Group className={styles.formControlModal}>
                                    <Form.Label className={styles.formLabel}>
                                        Considerações:
                                        <span className={styles.spanOpcional}>(Opcional)</span>
                                    </Form.Label>
                                    <Form.Control
                                        className={styles.formControl}
                                        placeholder={'Considerações'}
                                        as={'textarea'}
                                        rows={2}
                                        value={formObj.ObservacaoRecebimento}
                                        onChange={e => setFormObj({...formObj, ObservacaoRecebimento: e.target.value})}
                                    >
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </div>
                    </article>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button 
                        variant="outline-danger"
                        onClick={handleCloseModal}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        variant="primary"
                        onClick={handleProcess}
                    >
                        Processar
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default AprovacaoEstiloConsulta;
 