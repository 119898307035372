import { useEffect, useState, useCallback } from 'react';
import { api }  from '../services/api'

import { toast } from "react-toastify";

import { objetoXLSX } from '../utils/csvUtils'

const PLANEJAMENTO_CORTE__BASE_URL = '/planejamento-diario-corte/'

const useDiarioCorte = () => {
  const [data, setData] = useState([]);
	const [filtrado, setFiltrado] = useState();
  const [loading, setLoading] = useState(true);
  const [agendamentos, setAgendamentos] = useState([]);
  const [error, setError] = useState(null);
	const [somaGradePlansPorData, setSomaGradePlansPorData] = useState([]);

  const [filtroSelecionado, setFiltroSelecionado] = useState({
    nome: '',
    dados: [],
    estado: { propName: '', values: [] },
    historico: [],
  });

  const selecionarFiltro = useCallback((nome, propName, callback) => {
    const existeFiltro = filtroSelecionado.estado.nome === nome
    if (!existeFiltro) {
      const novoFiltro = {
        ...filtroSelecionado,
        nome: nome,
        estado: { propName, values: [] },
      };
  
      setFiltroSelecionado(novoFiltro);
      callback();
    }
  }, [filtroSelecionado.estado.nome])
  
  const obterValoresUnicos = (diarioCortes, propName) => {
    return diarioCortes
      .filter((value) => value[propName])
      .map((value) => value[propName])
      .filter((value, index, self) => self.indexOf(value) === index);
  };
  
  useEffect(() => {
    if (!data || !data.diarioCortes) return;
  
    const valoresUnicos = obterValoresUnicos(data.diarioCortes, filtroSelecionado.estado.propName);
  
    setFiltroSelecionado((prevFiltro) => ({
      ...prevFiltro,
      estado: {
        ...prevFiltro.estado,
        values: valoresUnicos,
      },
    }));
  }, [filtroSelecionado.estado.propName, data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(PLANEJAMENTO_CORTE__BASE_URL + 'diario_corte');
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    setSomaGradePlansPorData(somaGradePlansPorDataFn());
  }, [agendamentos, data]);


	const limpaFiltros = async () => {
		await setFiltrado()
    await setFiltroSelecionado({ 
      nome: '',
      historico: { propName: '', values: [] },
      estado: { propName: '', values: [] }
    })
	}

  const limpaEsteFiltro = (propName) => {
    setFiltrado((prevFiltrado) => ({
      ...prevFiltrado,
      diarioCortes: data.diarioCortes.filter((item) => {
        const hasOtherRestrictions = filtroSelecionado.historico.every((filtro) => {
          if (filtro.propName === propName) {
            return true;
          }
          return filtro.values.includes(item[filtro.propName]);
        });
        return hasOtherRestrictions;
      }),
    }));
  
    setFiltroSelecionado((prevFiltro) => ({
      ...prevFiltro,
      historico: prevFiltro.historico.filter((item) => item.propName !== propName),
    }));
  };

  const limparSelecionado = async () => {
    setFiltroSelecionado({ 
      nome: '',
      dados: [],
      historico: { propName: '', values: [] },
      estado: { propName:'', values: [] }
    })
  }

  const processarFiltro = (props) => {
    let dataSource = data
    let test = null

    if (filtrado) { dataSource = filtrado }
    
    const filteredData = dataSource.diarioCortes.filter(item => {
      if (item.hasOwnProperty(filtroSelecionado.estado.propName) && props.values.includes(item[filtroSelecionado.estado.propName])) {
        return item.status === props.status;
      }
      return false;
    });

    if (filtrado && filteredData.length === 0) { 
      toast.warn('O(s) filtros selecionados nao deram match em nenhum item. Favor tentar uma combinacao válida.')
      limpaFiltros()
      return
    }
   
    setFiltroSelecionado((prev) => ({ 
      ...prev, 
      historico: filtroSelecionado.historico.concat({ propName: filtroSelecionado.estado.propName, values: props.values }) 
    }))

    setFiltrado({ ...data, diarioCortes: filteredData })
  }

  const handleAgendamentos = async (agendamento) => {
    const agendamentoIndex = agendamentos.findIndex(
      (ag) => ag.OrdemProducao === agendamento.OrdemProducao && ag.OrdemServico === agendamento.OrdemServico
    );
  
    if (agendamentoIndex !== -1) {
      setAgendamentos((agendamentosAntigos) => {
        const novosAgendamentos = [...agendamentosAntigos];
        novosAgendamentos[agendamentoIndex] = agendamento;
        return novosAgendamentos;
      });
    } else {
      setAgendamentos((agendamentosAntigos) => agendamentosAntigos.concat(agendamento));
    }
  };

  const fazerAgendamentos = async () => {
		try {
			setLoading(true)
      await api.post(PLANEJAMENTO_CORTE__BASE_URL + 'agendar', { agendamentos })
		  setLoading(false)
      setAgendamentos([])
		} catch (error) {
			setLoading(false)
		}
    setLoading(false)
	}


  function definerFiltrado (diarioCortes) {
    if (!data || !data.diarioCortes) {
      console.error('Erro: O objeto "data" ou "data.diarioCortes" é indefinido.');
      setLoading(false);
      return;
    }

    const copiaDados = { ...data, diarioCortes: diarioCortes };
    setFiltrado(copiaDados);
  }

  const buscar = async ({ ordemServico, ordemProducao, offset, limit }) => {
    const params = `?OP=${ordemProducao}&OS=${ordemServico}&OFFSET=${offset}&LIMIT=${limit}`
    try {
        const response = await api.get(PLANEJAMENTO_CORTE__BASE_URL + 'buscar' + params);
        definerFiltrado(response.data.diarioCortes)
				
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
  };


	const atualizarBD = async () => {
		try {
      setLoading(true);
		  const resultado = await api.post(PLANEJAMENTO_CORTE__BASE_URL + 'atualizar')
	  
      if (resultado.data && resultado.data.length > 0) {
       await setData(resultado.data)
      } 

      setAgendamentos([])
		
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
	}

  function somaGradePlansPorDataFn() {
		const sums = {};
			
		agendamentos.forEach(item => {
			const { dataAtual, GradePlan } = item;
	
			if (sums[dataAtual]) {
				sums[dataAtual] += GradePlan;
			} else {
				sums[dataAtual] = GradePlan;
			}
		});
	  
		return [sums];
	}

  const definirTipoCorte = (Tipo, valor, dados) => {
    let updatedDiario;

    if (filtrado && filtrado.diarioCortes.length > 0) {
      updatedDiario = filtrado.diarioCortes.map((item) =>
        item.OrdemProducao === dados.OrdemProducao && item.OrdemServico === dados.OrdemServico
          ? { ...item, [Tipo]: valor }
          : item
      );
      setFiltrado({ ...filtrado, diarioCortes: updatedDiario });
    } 

    updatedDiario = data.diarioCortes.map((item) =>
        item.OrdemProducao === dados.OrdemProducao && item.OrdemServico === dados.OrdemServico
          ? { ...item, [Tipo]: valor }
          : item
      );
      
    const diarioCortesAg = { ...data, diarioCortes: updatedDiario }
    setData(diarioCortesAg);
  
    setTimeout(() => atualizarAgendamento(updatedDiario, dados), 500)
  }

    const atualizarAgendamento = (updatedDiario, dados) => {
      const indice  = updatedDiario.findIndex((item) => item.OrdemProducao === dados.OrdemProducao && item.OrdemServico === dados.OrdemServico)
      const encontrado = agendamentos.findIndex((item) => item.OrdemProducao === dados.OrdemProducao && item.OrdemServico === dados.OrdemServico)
      
      if (encontrado >= 0 && indice >= 0) {
        setAgendamentos((agendamentosAntigos) => {
          return agendamentosAntigos.map((agendamento) => {
            if (agendamento.OrdemProducao === dados.OrdemProducao && agendamento.OrdemServico === dados.OrdemServico) {
              return { ...agendamento, ...updatedDiario[indice] };
            }
            return agendamento;
          })
        })
      } else {
        setAgendamentos(agendamentos.concat(updatedDiario[indice]))
      }
  }

  const criarCSV = () => {
    if (!data || data.diarioCortes.length === 0) {
      toast.error("Erro na exportação. Certifique-se que há items na tabela principal e tente novamente.");
      return;
    }
    objetoXLSX(data, 'DiarioCorte');
  }

  return { 
    data, 
    loading, 
    error, 
    buscar, 
    handleAgendamentos, 
    limpaEsteFiltro,
    limparSelecionado,
    criarCSV,
    selecionarFiltro,
    fazerAgendamentos,
    filtroSelecionado,
    definirTipoCorte,
    processarFiltro,
		filtrado,
		limpaFiltros,
		somaGradePlansPorData,
		atualizarBD,
    agendamentos 
  };
};

export default useDiarioCorte;