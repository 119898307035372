import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { usePriorizacaoOps } from '../../../../contexts/Corte/ControleEnfesto/PriorizacaoOpsContext';
import styles from '../../../../styles/Corte/ControleEnfesto/PriorizacaoOps.module.scss';

const ModalSelecaoFiltro = () => {

    const { showSelecaoFiltro, handleCloseSelecaoFiltro, checklistColumns, handleShowFiltroData } = usePriorizacaoOps();

    const filterColumns = checklistColumns.sort((a, b) => a.name.localeCompare(b.name))

    return (
        <>
            <Modal
                show={showSelecaoFiltro}
                onHide={handleCloseSelecaoFiltro}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Selecione o Filtro</Modal.Title>
                </Modal.Header>

                <Modal.Body
                    className={styles.modalBodyFilter}
                >
                {filterColumns.map((item, index) => (
                    item.filterable === true ?
                    <Button
                        key={index}
                        variant="outline-primary"
                        className={styles.btnFiltro}
                        onClick={() => handleShowFiltroData(item)}
                    >
                        {item.name}
                    </Button>
                    :
                    null
                ))}
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseSelecaoFiltro}
                    >
                        Voltar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalSelecaoFiltro;
