import React, { useState, useEffect } from "react";
import { Button, Table, Form, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import MaterialIcon from 'material-icons-react';

import Identificacao from "../../components/PA/MonitorChecklist/Identificacao/index";
import CustomPagination from "../../components/Utils/CustomPagination";

import styles from "../../styles/MonitorChecklist.module.scss";

import { api, retryApi }  from "../../services/api";
import Carregando from "../../components/Carregando"
import {toUTCDDMMYYYY} from "../../utils/dateUtils"

import { usePAChecklist } from "../../contexts/PAChecklistContext";

const MonitorChecklist = () => {
    const { iniciarChecklistOld, itemSelecionado, showModal, setShowModal } = usePAChecklist();

    const handleClose = () => setShowModal(false);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    };

    const [produto, setProduto] = useState("");
    const [corProduto, setCorProduto] = useState("");
    const [nfEntrada, setNfEntrada] = useState("");
    const [itens, setItens] = useState([]);
    const [carregando, setCarregando] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    // const [itemSelecionado, setItemSelecionado] = useState('');
    const [totalRecords, setTotalRecords] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);

    const quantityPerPage = 20;

    const [listaDefeitos, setListaDefeitos] = useState([]);

    useEffect(() => {                 
        handleForm();           
      },[]);

    useEffect(() => {                 
        setCurrentData(itens.slice(
            ((currentPage-1) * quantityPerPage), (currentPage * quantityPerPage)
          ));
    },[currentPage, itens]); 
   
    async function handleForm() {
        const params = {
            Produto: produto,
            ProdutoCor: corProduto,
            NFEntrada: nfEntrada
        };

        try {
            setCarregando(true);
            const lista = await retryApi('get', "PAMonitorCheckList/MonitorChecklist", { params });
            if (lista.data.length > 0) {
                setItens(lista.data);                
                setTotalRecords(lista.data.length);
            } else {
                toast.warning('Nenhum registro encontrado para os parâmetros de busca utilizados.');
            }
            setCarregando(false);
        } catch (e) {
            toast.error('Ocorreu um erro ao carregar a listagem principal - por favor recarregue a página.');            
            return false;
        }
    }

    // async function handleRevisar(item) {
    //     if(item.StatusRevisao.trim() === "DEVOLUCAO TOTAL" || item.StatusRevisao.trim() === "DEVOLUCAO PARCIAL"){
    //         const params = {...item}
    //         await api.get("PAMonitorCheckList/GetDefeitosProcessos", {params})
    //         .then(res => {
    //             setListaDefeitos(res.data);
    //         })
    //         .catch(e => {
    //             console.error(e);
    //         })
    //     }
        
    //     setItemSelecionado(item);
    //     setShowModal(true);
    // }

    function handleCloseAll(){
        setProduto('');
        setCorProduto('');
        setNfEntrada('');
        setListaDefeitos([]);
        handleClose();
        handleForm();
    }

    return (
        <div>   
            <article className={styles.articleMargim}>
                <Form className={styles.form}>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label className="form-label-input">Produto</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Produto"
                            value={produto}
                            onChange={(event) => setProduto(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label className="form-label-input">
                            Cor Produto
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={corProduto}
                            placeholder="Cor Produto"
                            onChange={(event) => setCorProduto(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={styles.formGroup}>
                        <Form.Label className="form-label-input">
                            NF Entrada
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="NF Entrada"
                            value={nfEntrada}
                            onChange={(event) => setNfEntrada(event.target.value)}
                        />
                    </Form.Group>
                    <Button 
                        className={styles.formGroup} 
                        variant="primary"
                        type="button" 
                        onClick={handleForm}
                    >
                        Buscar                        
                    </Button>
                </Form>
            </article>       

            <CustomPagination 
                totalRecords={totalRecords}
                quantityPerPage={quantityPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            ></CustomPagination> 

            <article className={styles.articleGray}>
                {carregando ?
                    <Carregando></Carregando>
                : 
                <Table className="m-2" >
                    <thead>
                        <tr>
                            <th></th>
                            <th className={styles.blockTitle}>REVISÃO OK</th>
                            <th className={styles.blockTitle}>PRODUTO</th>
                            <th className={styles.blockTitle}>COR PRODUTO</th>
                            <th className={styles.blockTitle}>NF ENTRADA</th>   
                            <th className={styles.blockTitle}>EMPRESA</th>                         
                            <th className={styles.blockTitle}>SERIE_NF</th>
                            <th className={styles.blockTitle}>DESTINO</th>
                            <th className={styles.blockTitle}>ORIGEM</th>
                            <th className={styles.blockTitle}>ORDEM PRODUÇÃO</th>
                            <th className={styles.blockTitle}>ORDEM SERVIÇO</th>
                            <th className={styles.blockTitle}>RECEBIMENTO</th>
                            <th className={styles.blockTitle}>DIGITAÇÃO</th>  
                            <th className={styles.blockTitle}>QTDE. ENTRADA</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO PRODUTO</th>
                            <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                            <th className={styles.blockTitle}>COLEÇÃO</th>
                            <th className={styles.blockTitle}>GRIFFE</th>
                            <th className={styles.blockTitle}>COD. MARCA</th>
                            <th className={styles.blockTitle}>MARCA</th>
                            <th className={styles.blockTitle}>LINHA</th>
                            <th className={styles.blockTitle}>TIPO PRODUTO</th>
                            <th className={styles.blockTitle}>GRUPO PRODUTO</th>
                            <th className={styles.blockTitle}>FABRICANTE</th>
                            <th className={styles.blockTitle}>ENTRADA REVISÃO</th>
                            <th className={styles.blockTitle}>FILIAL REVISÃO</th>
                            <th className={styles.blockTitle}>RESPONSÁVEL ENTRADA</th>
                            <th className={styles.blockTitle}>CHECKLIST OK</th>
                            <th className={styles.blockTitle}>STATUS QUALIDADE</th>
                            <th className={styles.blockTitle}>SLA</th>
                            <th className={styles.blockTitle}>PREÇO</th>
                            <th className={styles.blockTitle}>COMPOSIÇÃO</th>
                            <th className={styles.blockTitle}>RECEBIDO</th>
                            <th className={styles.blockTitle}>INDICA PENDÊNCIA</th>
                            <th className={styles.blockTitle}>INDICA PENDÊNCIA CHECKLIST</th>
                        </tr>
                    </thead>
                    <tbody>
                    {currentData.map((item,index) => {
                        return (
                        <tr key={index} className={styles.tableLines}>
                            <td>
                                <Button 
                                    className={styles.btnList}
                                    // onClick={() => handleRevisar(item)}
                                    onClick={() => iniciarChecklistOld(item)}
                                >                                
                                     <MaterialIcon 
                                        icon="fact_check" 
                                        size={30} 
                                        color='#0d6efd' 
                                    />
                                </Button>
                            </td>
                                <td className={styles.tableLines}>{item.StatusRevisao}</td>
                                <td className={styles.tableLines}>{item.Produto}</td>
                                <td className={styles.tableLines}>{item.CorProduto}</td>
                                <td className={styles.tableLines}>{item.NFEntrada}</td>   
                                <td className={styles.tableLines}>{item.Empresa}</td>                         
                                <td className={styles.tableLines}>{item.SerieNF}</td>
                                <td className={styles.tableLines}>{item.Destino}</td>
                                <td className={styles.tableLines}>{item.Origem}</td>
                                <td className={styles.tableLines}>{item.OrdemProducao}</td>
                                <td className={styles.tableLines}>{item.OrdemServico}</td>
                                <td className={styles.tableLines}>{toUTCDDMMYYYY(item.Recebimento)}</td>
                                <td className={styles.tableLines}>{toUTCDDMMYYYY(item.DataDigitacao)}</td>                                                           
                                <td className={styles.tableLines}>{item.QtdeEntrada}</td>
                                <td className={styles.tableLines}>{item.DescricaoProduto}</td>
                                <td className={styles.tableLines}>{item.DescricaoCor}</td>
                                <td className={styles.tableLines}>{item.Colecao}</td>
                                <td className={styles.tableLines}>{item.Griffe}</td>
                                <td className={styles.tableLines}>{item.CodMarca}</td>
                                <td className={styles.tableLines}>{item.Marca}</td>
                                <td className={styles.tableLines}>{item.Linha}</td>
                                <td className={styles.tableLines}>{item.TipoProduto}</td>
                                <td className={styles.tableLines}>{item.GrupoProduto}</td>
                                <td className={styles.tableLines}>{item.Fabricante}</td>
                                <td className={styles.tableLines}>{toUTCDDMMYYYY(item.DataEntradaRevisao)}</td>
                                <td className={styles.tableLines}>{item.FilialRevisao}</td>
                                <td className={styles.tableLines}>{item.ResponsavelEntradaFiscal}</td>
                                <td className={styles.tableLines}>{item.StatusChecklist}</td>
                                <td className={styles.tableLines}>{item.StatusADMCQ}</td>
                                <td className={styles.tableLines}>{item.StatusSLA}</td>
                                <td className={styles.tableLines}>{item.Preco}</td>
                                <td className={styles.tableLines}>{item.Composicao}</td>
                                <td className={styles.tableLines}>{item.RecPiloto}</td>
                                <td className={styles.tableLines}>{item.IndicaPendenciaRevisao}</td>
                                <td className={styles.tableLines}>{item.IndicaPendenciaChecklist}</td>                              
                         </tr>
                         );
                        })}
                    </tbody>
                </Table>
            }
            </article>  

            <Modal show={showModal}
                onHide={handleCloseAll}
                backdrop="static"
                fullscreen={true}
                keyboard={false}
            >    
                <Modal.Header closeButton>
                    <Modal.Title>Identificação</Modal.Title>
                </Modal.Header>            
                <Modal.Body>
                    <Identificacao item={itemSelecionado} handleClose={handleCloseAll} listaDefeitos={listaDefeitos}></Identificacao>
                </Modal.Body>                
            </Modal>

        </div>
    );
};

export default MonitorChecklist;