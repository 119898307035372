import React from "react";
import Spinner from 'react-bootstrap/Spinner';

export default () => {
    return (
    <div style={{padding: '10px', width: '100%', textAlign: 'center'}}>
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
        </Spinner>
    </div>
    )
}

