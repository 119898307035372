import React from "react";
import MonitorRevisaoConsulta from "../../components/PA/MonitorRevisao/Consulta/index";

const MonitorRevisao = () => {
    return (
        <div className="p-2">
          <h2 className="ms-3">Monitor Revisão PA</h2>
          <MonitorRevisaoConsulta />
        </div>
    );
};

export default MonitorRevisao;