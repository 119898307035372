import { Modal, Button, Accordion } from "react-bootstrap";
import Tabela from "../../../Misc/Tabela/Tabela";
import { usePARevisaoSegundaQualidadeNV } from "../pa_revisao_sq_nv_context";
import MaterialIcon from 'material-icons-react';
import styles from '../pa_revisao_sq_nv.module.scss'

const PAAjusteSegundaQualidadeGrade = () => {
  const { modalAjuste, setModalDirecionamento, referenciaSelecionada, gradeRevisao, direcionamentoRevisao, toggleSemDefeitos, abrirModalProcessamento, handleRemoverDirecionamento, checarSePossuiDefeitos, analiseAjuste, direcionamentosAjuste } = usePARevisaoSegundaQualidadeNV();

  const grade_columns = [
    {
      cell: row => {
        const possuiDefeitos = checarSePossuiDefeitos(row.id_entrada_fiscal_grade);
        return (
          <Button
            variant={possuiDefeitos ? 'danger' : row.sem_defeitos ? 'success' : 'outline-primary'}
            onClick={() => toggleSemDefeitos(row.index_grade)}
            disabled={possuiDefeitos}
          >
            {possuiDefeitos ? 'Defeitos Encontrados' : row.sem_defeitos ? 'Sem Defeitos' : 'Confirmar Sem Defeitos'}
          </Button>
        )
      }, width: '200px'
    },
    { name: 'Tamanho', selector: row => row.tamanho },
    { name: 'Quantidade Total', selector: row => row.quantidade_total },
    { name: 'Quantidade Direcionada', selector: row => row.quantidade_defeituosa },
  ];

  const grade_columns_conditional = [
    {
      when: row => checarSePossuiDefeitos(row.id_entrada_fiscal_grade),
      style: row => ({ backgroundColor: row.index_grade % 2 === 0 ? '#FFCCCC80' : '#FFCCCC50' })
    },
    {
      when: row => !checarSePossuiDefeitos(row.id_entrada_fiscal_grade) && row.sem_defeitos,
      style: row => ({ backgroundColor: row.index_grade % 2 === 0 ? '#CCFFDA80' : '#CCFFDA50' })
    }
  ];

  const old_direcionamento_columns = [
    { name: 'Tamanho', selector: row => row.tamanho },
    { name: 'Qtde. Direcionada', selector: row => row.qtde_direcionada },
    { name: 'Direcionamento', selector: row => row.destino },
    { name: 'Defeitos', selector: row => row.defeitos }
  ];

  const direcionamento_columns = [
    {
      cell: (row, index) => {
        return (
          <div onClick={() => handleRemoverDirecionamento(row, index)}>
            <MaterialIcon icon="delete" size={20} color='#BB271A' />
          </div>
        )
      }, width: '60px'
    },
    { name: 'Tamanho', selector: row => row.grade.tamanho },
    { name: 'Quantidade', selector: row => row.quantidade },
    { name: 'Defeitos', selector: row => row.defeito.map(item => item.label).join(' | '), width: '45%' },
    { name: 'Direcionamento', selector: row => row.direcionamento.label }
  ];

  return (
    <Modal
      show={modalAjuste}
      centered
      fullscreen={true}
    >
      <Modal.Header style={{ fontWeight: '500' }}>
        Ajuste Segunda Qualidade | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>Dados Entrada Fiscal</Accordion.Header>
            <Accordion.Body className={styles.accordion_body}>
              <span className={styles.accordion_item}>Nota Fiscal:<div className={styles.font_blue}>{referenciaSelecionada.nf_entrada}</div></span>
              <span className={styles.accordion_item}>Série:<div className={styles.font_blue}>{referenciaSelecionada.serie_nf}</div></span>
              <span className={styles.accordion_item}>Origem:<div className={styles.font_blue}>{referenciaSelecionada.origem}</div></span>
              <span className={styles.accordion_item}>Produto:<div className={styles.font_blue}>{referenciaSelecionada.produto}</div></span>
              <span className={styles.accordion_item}>Descrição Produto:<div className={styles.font_blue}>{referenciaSelecionada.descricao_produto}</div></span>
              <span className={styles.accordion_item}>Cor Produto:<div className={styles.font_blue}>{referenciaSelecionada.cor_produto}</div></span>
              <span className={styles.accordion_item}>Descrição Cor:<div className={styles.font_blue}>{referenciaSelecionada.descricao_cor}</div></span>
              <span className={styles.accordion_item}>Filial Revisão:<div className={styles.font_blue}>{referenciaSelecionada.filial_revisao}</div></span>
              <span className={styles.accordion_item}>Qtde. Entrada:<div className={styles.font_blue}>{referenciaSelecionada.qtde_entrada}</div></span>
              <span className={styles.accordion_item}>Data Entrada Revisão:<div className={styles.font_blue}>{referenciaSelecionada.data_entrada_revisao}</div></span>
              <span className={styles.accordion_item}>Ordem Produção:<div className={styles.font_blue}>{referenciaSelecionada.ordem_producao}</div></span>
              <span className={styles.accordion_item}><div className={styles.font_blue}></div></span>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion>
          <Accordion.Item>
            <Accordion.Header>Dados de Análise e Direcionamento anteriores</Accordion.Header>
            <Accordion.Body className={styles.accordion_body2}>
              <section className={styles.accordion_section}>
                <span className={styles.accordion_item}>Data Análise:<div className={styles.font_blue}>{analiseAjuste.data_registro}</div></span>
                <span className={styles.accordion_item}>Qtde. Peças Analisadas:<div className={styles.font_blue}>{analiseAjuste.qtde_pecas_analisadas}</div></span>
                <span className={styles.accordion_item}>Usuário Análise:<div className={styles.font_blue}>{analiseAjuste.usuario_analise}</div></span>
                <span className={styles.accordion_item}>Data da Última Alteraçao:<div className={styles.font_blue}>{analiseAjuste.data_ultima_alteracao}</div></span>
                <span className={styles.accordion_item}>Usuário Última Alteraçao:<div className={styles.font_blue}>{analiseAjuste.usuario_ultima_alteracao}</div></span>
                <span className={styles.accordion_item}><div className={styles.font_blue}></div></span>
              </section>
              <div className={styles.separator}/>
              <section>
                <label className={styles.accordion_item}>Tabela de Direcionamentos</label>
                <Tabela
                  columns={old_direcionamento_columns}
                  data={direcionamentosAjuste}
                  pagination={false}
                />
              </section>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <section>
          <label className={styles.form_label}>Grade</label>
          <Tabela
            columns={grade_columns}
            data={gradeRevisao}
            pagination={false}
            conditionalRowStyles={grade_columns_conditional}
          />
        </section>

        <section>
          <label className={styles.form_label}>Direcionamento</label>
          <Tabela
            columns={direcionamento_columns}
            data={direcionamentoRevisao}
            pagination={false}
          />
        </section>
      </Modal.Body>
      <Modal.Footer className={styles.modal_footer}>
        <Button
          variant={'warning'}
          className={styles.footer_btn_direcionamento}
          onClick={() => setModalDirecionamento(true)}
        >
          Direcionar Peças Defeituosas
        </Button>
        <Button
          variant={'success'}
          className={styles.footer_btn_processamento}
          onClick={abrirModalProcessamento}
        >
          Finalização do Ajuste
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PAAjusteSegundaQualidadeGrade;