import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useEnfesto } from '../../../../contexts/Corte/ControleEnfesto/EnfestoContext'; 

import styles from '../../../../styles/Corte/ControleEnfesto/Enfesto.module.scss';

const ModalInicia = () => {

    const { handleCloseInicia, showInicia, linhaSelecionada, handleIniciaSetup } = useEnfesto();

    return (
        <>
            <Modal
                show={showInicia}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Iniciar Setup?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Ordem Produção: <strong>{linhaSelecionada.OrdemProducao}</strong>
                    </div>
                    <div>
                        Ordem Serviço: <strong>{linhaSelecionada.OrdemServico}</strong>
                    </div>
                    <div>
                        Material: <strong>{linhaSelecionada.Material}</strong>
                    </div>
                    <div>
                        Cor Material: <strong>{linhaSelecionada.CorMaterial}</strong>
                    </div>
                    <div>
                        Grade: <strong>{linhaSelecionada.Grade}</strong>
                    </div>
                    <div>
                        Comprimento Risco: <strong>{linhaSelecionada.ComprimentoRiscoM} m</strong>
                    </div>
                    <div>
                        Qtde Folhas Alocadas: <strong>{linhaSelecionada.QtdeFolhasAlocada}</strong>
                    </div>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseInicia}
                    >
                        Não
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={handleIniciaSetup}
                    >
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalInicia;
