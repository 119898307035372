import React, {useState, useEffect} from "react";
import { api }  from '../services/api';
import { Button, Table, Form, FormGroup, Modal, Accordion } from 'react-bootstrap';
import Select from 'react-select';

import MaterialIcon from 'material-icons-react';

import styles from '../styles/Adm.module.scss';
import { toYYYYMMDD, toUTCDDMMYYYY, toDatetime, toUTCDatetime } from "../utils/dateUtils";
import { toast, ToastContainer } from "react-toastify";
import CookiesService from '../services/cookies';
import Carregando from '../components/Carregando';
import CustomPagination from "../components/Utils/CustomPagination";

const Adm = () => {
  const [mainData, setMainData] = useState([]);
  const [showModalEnvio, setShowModalEnvio] = useState(false);
  const [showModalRecebimento, setShowModalRecebimento] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [formObj, setFormObj] = useState({});
  const [formErrs, setFormErrs] = useState({});
  const [activeObj, setActiveObj] = useState({});
  const [optionsObj, setOptionsObj] = useState({MotivoEnvioLista: []});
  const [loading, setLoading] = useState(false);
  const [responsavelAprovacaoLista, setResponsavelAprovacaoLista] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([])
  const [totalRecords, setTotalRecords] = useState([])

  const today = toYYYYMMDD(new Date());
  const userLogin = CookiesService.getUserLogin();

  const quantityPerPage = 20;	
  
  const handleShowModal = async (el) => {
    setActiveObj(el);
    if (el.ValidaEstilo === 'NAO' && el.NumeroLacreEnvio !== '-') return toast.warn('Processo pendente de aprovação do Estilo');
    else if (el.DataEnvioAprovacao === '-') setShowModalEnvio(true);
    else if (el.ValidaEstilo === 'SIM' && el.ConfirmaEnvioAmostra === 'SIM') setShowModalRecebimento(true);

    await api.get('/MPAdmControle/GetResponsavelAprovacaoLista', {params: {Empresa: el.Empresa}})
      .then(res => { if (res.status === 200) setResponsavelAprovacaoLista(res.data)})
      .catch(e => console.error(`Erro na requisição '/GetResponsavelAprovacaoLista' - ${e}`));
  }
  
  const handleCloseModal = () => {
    setShowModalEnvio(false);
    setShowModalRecebimento(false);
    setActiveObj({});
    setFormObj({});
    setFormErrs({});
    setResponsavelAprovacaoLista([]);
  }

  const handleFetchMainData = async () => {
    setLoading(true);
    await api.get('/MPAdmControle/GetMainData')
      .then(res => { setMainData(res.data.slice(0, 100)); setTotalRecords(res.data.length); setLoading(false); })
      .catch(e => console.error(e));
  }

	const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  };

  const FilterOptions = [
    {label: 'Pendente CQ', value: 'Pendente CQ'},
    {label: 'Pendente Estilo', value: 'Pendente Estilo'},
    {label: 'Pendente Gestao', value: 'Pendente Gestao'},
    {label: 'Exibir Tudo', value: ''}
  ];

  const handleSearch = async () => {
    await api.get('/MPAdmControle/GetMainData', {params: { ...filterObj, FilterOpt: formObj.filterBy && formObj.filterBy.value ? formObj.filterBy.value : '' }})
      .then(res => { setMainData(res.data.slice(0, 100)); setTotalRecords(res.data.length);})
      .catch(e => console.error(e));
  }

  const handleFetchOptions = async () => {
    // let { MotivoEnvioLista } = optionsObj;
    // await api.get('/MPAdmControle/GetResponsavelRecebimentoLista')
    //   .then(res => { if (res.status === 200) ResponsavelRecebimentoLista = res.data })
    //   .catch(e => console.error(`Erro na requisição '/GetResponsavelRecebimentoLista' - ${e}`));
    await api.get('/MPAdmControle/GetMotivoEnvioLista')
      .then(res => { if (res.status === 200) setOptionsObj({MotivoEnvioLista: res.data}) })
      // .then(res => { if (res.status === 200) MotivoEnvioLista = res.data })
      .catch(e => console.error(`Erro na requisição '/GetMotivoEnvioLista' - ${e}`));
    // if (MotivoEnvioLista.length > 0) {
    //   setOptionsObj({MotivoEnvioLista});
    // } else {
    //   toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
    // }
  }

  const handleEnvioProcessing = async () => {
    const errs = handleEnvioValidation();
    if (Object.keys(errs).length > 0) {
      setFormErrs(errs);
    } else {
      const params = {
        DataRegistroEnvio: toDatetime(new Date()),
        DataEnvio: toUTCDatetime(formObj.DataEnvioAprovacao),
        IDUsuarioEnvio: userLogin,
        IDMotivoEnvio: activeObj.MotivoEnvio.match(/^\d+/),
        IDResponsavelAprovacao: formObj.ResponsavelAprovacao.value,
        Lacre: formObj.LacreEnvio
      }
      const validation = {
        Tabela: activeObj.TABELA,
        Pedido: activeObj.Pedido,
        NomeCliFor: activeObj.NomeCliFor,
        Material: activeObj.Material,
        CorMaterial: activeObj.CorMaterial,
        DataAlteracaoPedido: toUTCDatetime(activeObj.DataAlteracaoPedido),
        IDAreaAprovacao: activeObj.IDAreaAprovacao,
        NFEntrada: activeObj.NFEntrada,
        SerieNF: activeObj.SerieNF,
        QtdeEntrada: activeObj.QtdeEntrada
      }

      await api.post('/MPAdmControle/PostProcessarEnvio', {params, validation})
        .then(res => {
          if (res.status === 200) {
            handleFetchMainData();
            setFilterObj({});
            handleCloseModal();
            toast.success('Os dados foram atualizados com sucesso!');
          }
        })
        .catch(e => {
          toast.error('Ocorreu um erro ao atualizar os dados, por favor entre em contato com o suporte.');
          console.error(`Erro na requisição '/MPAdmControle/PostProcessarEnvio' - ${e}`);
        });
    }
  }

  const handleEnvioValidation = () => {
    const { MotivoEnvio, DataEnvioAprovacao, LacreEnvio} = formObj;
    const errs = {}
    // if (!formObj.ResponsavelAprovacao) errs.ResponsavelAprovacao = 'Selecione uma opção';
    // if (!MotivoEnvio) errs.MotivoEnvio = 'Selecione uma opção';
    if (!DataEnvioAprovacao || new Date(DataEnvioAprovacao).getTime() <= 0 || new Date(DataEnvioAprovacao) < new Date(today)) errs.DataEnvioAprovacao = 'Insira uma data de envio válida e maior ou igual a hoje';
    if (!LacreEnvio) errs.LacreEnvio = 'Insira um nº de lacre válido';
    return errs;
  }

  const handleRecebimentoProcessing = async () => {
    const errs = handleRecebimentoValidation();
    if (Object.keys(errs).length > 0) {
      setFormErrs(errs);
    } else {
      const params = {
        ConfirmaRecAmostra: 1,
        DataRecAmostra: toUTCDatetime(formObj.DataRecebimentoAmostra),
        IDUsuarioRecAmostra: userLogin
        // IDUsuarioRecAmostra: userLogin || 'TESTE.HOMOLOG'
      }
      const validation = {
        Tabela: activeObj.TABELA,
        Pedido: activeObj.Pedido,
        NomeCliFor: activeObj.NomeCliFor,
        Material: activeObj.Material,
        CorMaterial: activeObj.CorMaterial,
        DataAlteracaoPedido: toUTCDatetime(activeObj.DataAlteracaoPedido),
        IDAreaAprovacao: activeObj.IDAreaAprovacao,
        NFEntrada: activeObj.NFEntrada,
        SerieNF: activeObj.SerieNF,
        QtdeEntrada: activeObj.QtdeEntrada
      }
      await api.post('/MPAdmControle/PostProcessarRecebimento', {params, validation})
        .then(res => {
          if (res.status === 200) {
              handleFetchMainData();
              setFilterObj({});
              handleCloseModal();
              toast.success('Os dados foram atualizados com sucesso!');
          }
        })
        .catch(e => {
            toast.error('Ocorreu um erro ao atualizar os dados, por favor entre em contato com o suporte.');
            console.error(`Erro na requisição '/MPAdmControle/PostProcessarEnvio' - ${e}`);
        });
    }
  }

  const handleRecebimentoValidation = () => {
    const {DataRecebimentoAmostra} = formObj;
    const errs = {}
    if (!DataRecebimentoAmostra || new Date(DataRecebimentoAmostra).getTime() <= 0 || new Date(DataRecebimentoAmostra) < new Date(today)) errs.DataRecebimentoAmostra = 'Insira uma data de envio válida e maior ou igual a hoje';
    // if (!ResponsavelRecebimento) errs.ResponsavelRecebimento = 'Selecione uma opção';
    return errs;
  }

  const handleValueMask = (field, value, filter = false) => {
    if (value && value !== undefined) value = `${value.replace(/[^.0-9]*/g, '')}`;
    if (filter === true) setFilterObj({...filterObj, [field]: value}); 
    else setFormObj({...formObj, [field]: value});
  }

  const clearErrs = (str) => {
    setFormErrs({...formErrs, [str]: null});
  }

  useEffect(() => {
    handleFetchOptions();
    handleFetchMainData();
  }, [])

  useEffect(() => {                 
    setCurrentData(mainData.slice(
        ((currentPage-1) * quantityPerPage), (currentPage * quantityPerPage)
      ));
  },[currentPage, mainData]); 
  return (
    <div>
      <div className="p-3">
        <div>
          <h3>ADM</h3>
          
          {/* Forms busca */}
          <article>
            <Form className={styles.form}>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Filtrar por</Form.Label>
                <Select
                  options={FilterOptions}
                  isClearable={true}
                  backspaceRemovesValue={true}
                  // onClick={}
                  onChange={e => setFormObj({...filterObj, filterBy: e})}
                />
                  
              </FormGroup>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>NF Entrada</Form.Label>
                <Form.Control
                  type="text"
                  value={filterObj.NFEntrada || ''}
                  onChange={e => handleValueMask('NFEntrada', e.target.value, true)}
                  placeholder={'Número da NF'}
                />
              </FormGroup>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Material</Form.Label>
                <Form.Control
                  type="text"
                  value={filterObj.Material || ''}
                  onChange={e => handleValueMask('Material', e.target.value, true)}
                  placeholder={'Desc. do material'}
                />
              </FormGroup>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                <Form.Control
                  type="text"
                  value={filterObj.CorMaterial || ''}
                  onChange={e => handleValueMask('CorMaterial', e.target.value, true)}
                  placeholder={'Cod. de cor do material'}
                />
              </FormGroup>
              <FormGroup className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>Pedido</Form.Label>
                <Form.Control
                  type="text"
                  value={filterObj.Pedido || ''}
                  onChange={e => handleValueMask('Pedido', e.target.value, true)}
                  placeholder={'Número do Pedido'}
                />
              </FormGroup>
              <Button 
                className={styles.formBtn} 
                variant="primary"
                type="button" 
                onClick={handleSearch}
              >
                Buscar
              </Button>
            </Form>
          </article>

          {/* Accordion Dados do Pedido */}
          <article className="p-2 mt-2">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dados do pedido</Accordion.Header>
                <Accordion.Body>
                  <article className={styles.monitorInfo}>
                    <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Pedido:</span>
                          <span className={styles.fontBlue}>{activeObj.Pedido || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Material:</span>
                          <span className={styles.fontBlue}>{activeObj.Material || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Cód. Fornecedor:</span>
                          <span className={styles.fontBlue}>{activeObj.CodigoFornecedor || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Fornecedor:</span>
                          <span className={styles.fontBlue}>{activeObj.NomeCliFor || '-'}</span>
                      </div>
                    </div>
                    <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Nota Fiscal:</span>
                          <span className={styles.fontBlue}>{activeObj.NFEntrada || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição Material:</span>
                          <span className={styles.fontBlue}>{activeObj.DescricaoMaterial || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Tipo:</span>
                          <span className={styles.fontBlue}>{activeObj.Tipo || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Requerido por:</span>
                          <span className={styles.fontBlue}>{activeObj.RequeridoPor || '-'}</span>
                      </div>
                    </div>
                    <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Série NF:</span>
                          <span className={styles.fontBlue}>{activeObj.SerieNF || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Cor Material:</span>
                          <span className={styles.fontBlue}>{activeObj.CorMaterial || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Qtde Recebida:</span>
                          <span className={styles.fontBlue}>{activeObj.QtdeEntrada || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Qtde Peças:</span>
                          <span className={styles.fontBlue}>{activeObj.QtdePecas || '-'}</span>
                      </div>
                    </div>
                    <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Data Entrada NF:</span>
                          <span className={styles.fontBlue}>{activeObj.recebimento || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Descrição Cor:</span>
                          <span className={styles.fontBlue}>{activeObj.DescricaoCor || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Data Revisão:</span>
                          <span className={styles.fontBlue}>{activeObj.DataRevisao || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Marca:</span>
                          <span className={styles.fontBlue}>{activeObj.Marca || '-'}</span>
                      </div>
                    </div>
                    <div className={styles.infoBlock}>
                      <div className={styles.infoBlockColumm}>
                          <span>Prioridade:</span>
                          <span className={styles.fontBlue}>{activeObj.Prioridade || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Responsável Revisão:</span>
                          <span className={styles.fontBlue}>{activeObj.ResponsavelRevisao || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Grupo:</span>
                          <span className={styles.fontBlue}>{activeObj.Grupo || '-'}</span>
                      </div>
                      <div className={styles.infoBlockColumm}>
                          <span>Tipo Compra:</span>
                          <span className={styles.fontBlue}>{activeObj.TipoCompra || '-'}</span>
                      </div>
                    </div>
                  </article>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </article>

          <CustomPagination 
                totalRecords={totalRecords}
                quantityPerPage={quantityPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
          ></CustomPagination>

          {/* Table Monitor */}
          <article className={styles.articleGray}>
            {loading ? <Carregando></Carregando> :
              <Table className="m-2" responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th className={styles.blockTitle}>DATA RECEBIMENTO</th>
                    <th className={styles.blockTitle}>PEDIDO</th>
                    <th className={styles.blockTitle}>NF ENTRADA</th>
                    <th className={styles.blockTitle}>SERIE NF</th>
                    <th className={styles.blockTitle}>CÓD. FORNECEDOR</th>
                    <th className={styles.blockTitle}>FORNECEDOR</th>
                     <th className={styles.blockTitle}>AREA APROVAÇÃO</th>
                    <th className={styles.blockTitle}>MATERIAL</th>
                    <th className={styles.blockTitle}>DESCRIÇÃO MATERIAL</th>
                    <th className={styles.blockTitle}>DESCRIÇÃO COR</th>
                    <th className={styles.blockTitle}>QTDE ENTRADA</th>
                    <th className={styles.blockTitle}>TIPO MATERIAL</th>
                    <th className={styles.blockTitle}>REQUERIDO POR</th>
                    <th className={styles.blockTitle}>DATA REVISÃO</th>
                    <th className={styles.blockTitle}>STATUS REVISÃO</th>
                    <th className={styles.blockTitle}>ENVIADO APROVAÇÃO</th>
                    <th className={styles.blockTitle}>MOTIVO ENVIO</th>
                    <th className={styles.blockTitle}>DATA ENVIO</th>
                    <th className={styles.blockTitle}>NÚMERO LACRE ENVIO</th>
                    <th className={styles.blockTitle}>CONFIRMA RECEBIMENTO</th>
                    <th className={styles.blockTitle}>DATA RECEBIMENTO</th>
                    <th className={styles.blockTitle}>RESPONSÁVEL RECEBIMENTO AMOSTRA</th>
                    <th className={styles.blockTitle}>VALIDADO ESTILO</th>
                    {/* <th className={styles.blockTitle}>DATA VALIDAÇÃO</th> */}
                    <th className={styles.blockTitle}>FUNCIONÁRIO VALIDAÇÃO</th>
                    <th className={styles.blockTitle}>STATUS ESTILO</th>
                    <th className={styles.blockTitle}>CONFIRMA ENVIO AMOSTRA</th>
                    <th className={styles.blockTitle}>CONFIRMA RETORNO AMOSTRA</th>
                    <th className={styles.blockTitle}>NÚMERO LACRE</th>
                    <th className={styles.blockTitle}>STATUS CQ</th>
                    <th className={styles.blockTitle}>DESTINO ANÁLISE</th>
                    <th className={styles.blockTitle}>DIRECIONAMENTO</th>
                  </tr>
                </thead>
                <tbody> 
                    {currentData.map((el, index) => {
                      return (
                        <tr 
                          className={styles.tableLines}
                          key={index}
                          onClick={() => setActiveObj(el)}
                          style={{'cursor': 'pointer'}}
                        >
                          <td>
                            <i 
                              className={styles.iconCursor}
                              onClick={() => handleShowModal(el)}
                            >
                              <MaterialIcon
                                icon={el.ValidaEstilo === 'NAO' && el.NumeroLacreEnvio !== '-' ? 'hide_source' : 'task_alt'}
                                size={25}
                                color={el.ValidaEstilo === 'NAO' && el.NumeroLacreEnvio !== '-' ? '#BB271A' : '#0D6EFD'}
                              />
                            </i>
                          </td>
                          <td className={styles.tableLines}>{toUTCDDMMYYYY(el.recebimento)}</td>
                          <td className={styles.tableLines}>{el.Pedido}</td>
                          <td className={styles.tableLines}>{el.NFEntrada}</td>
                          <td className={styles.tableLines}>{el.SerieNF}</td>
                          <td className={styles.tableLines}>{el.CodigoFornecedor}</td>
                          <td className={styles.tableLines}>{el.NomeCliFor}</td>
                          <td className={styles.tableLines}>{el.AREAAPROVACAO}</td>
                          <td className={styles.tableLines}>{el.Material}</td>
                          <td className={styles.tableLines}>{el.DescricaoMaterial}</td>
                          <td className={styles.tableLines}>{el.DescricaoCor}</td>
                          <td className={styles.tableLines}>{el.QtdeEntrada}</td>
                          <td className={styles.tableLines}>{el.Tipo}</td>
                          <td className={styles.tableLines}>{el.RequeridoPor}</td>
                          <td className={styles.tableLines}>{el.DataRevisao}</td>
                          <td className={styles.tableLines}>{el.StatusRevisao}</td>
                          <td className={styles.tableLines}>{el.EnviadoAprovacao}</td>
                          <td className={styles.tableLines}>{el.MotivoEnvio}</td>
                          <td className={styles.tableLines}>{el.DataEnvioAprovacao}</td>
                          <td className={styles.tableLines}>{el.NumeroLacreEnvio}</td>
                          <td className={styles.tableLines}>{el.ConfirmaRecebimento}</td>
                          <td className={styles.tableLines}>{el.DataRecebimento}</td>
                          <td className={styles.tableLines}>{el.FuncionarioRecebimentoAmostra}</td>
                          <td className={styles.tableLines}>{el.ValidaEstilo}</td>
                          {/* <td className={styles.tableLines}>{el.DataValidacao}</td> */}
                          <td className={styles.tableLines}>{el.FuncionarioValidacao}</td>
                          <td className={styles.tableLines}>{el.StatusEstilo}</td>
                          <td className={styles.tableLines}>{el.ConfirmaEnvioAmostra}</td>
                          <td className={styles.tableLines}>{el.ConfirmaRetornoAmostra}</td>
                          <td className={styles.tableLines}>{el.LacreRetornoAmostra}</td>
                          <td className={styles.tableLines}>{el.StatusCQ}</td>
                          <td className={styles.tableLines}>{el.DestinoAnalise}</td>
                          <td className={styles.tableLines}>{el.Direcionamento === '' ? '-' : el.Direcionamento}</td>
                        </tr>
                      )
                  })}                
                </tbody>
              </Table>
            }
          </article>

          {/* Modal Envio Amostra Aprovação Estilo */}
          <Modal
            show={showModalEnvio} 
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName={styles.modalWidth}
          >
            <Modal.Header closeButton>
                <Modal.Title>Envio de Amostra para Aprovação Estilo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Informações sobre o material */}
              <article className="p-2 mt-2">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Dados do pedido</Accordion.Header>
                    <Accordion.Body>
                      <article className={styles.monitorInfo}>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Pedido:</span>
                            <span className={styles.fontBlue}>{activeObj.Pedido}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Material:</span>
                            <span className={styles.fontBlue}>{activeObj.Material}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Cód. Fornecedor:</span>
                            <span className={styles.fontBlue}>{activeObj.CodigoFornecedor}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Fornecedor:</span>
                            <span className={styles.fontBlue}>{activeObj.NomeCliFor}</span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Nota Fiscal:</span>
                            <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Descrição Material:</span>
                            <span className={styles.fontBlue}>{activeObj.DescricaoMaterial}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Tipo:</span>
                            <span className={styles.fontBlue}>{activeObj.Tipo}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Requerido por:</span>
                            <span className={styles.fontBlue}>{activeObj.RequeridoPor}</span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Série NF:</span>
                            <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Cor Material:</span>
                            <span className={styles.fontBlue}>{activeObj.CorMaterial}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Qtde Recebida:</span>
                            <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Qtde Peças:</span>
                            <span className={styles.fontBlue}>{activeObj.x} - </span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Data Entrada NF:</span>
                            <span className={styles.fontBlue}>{toUTCDDMMYYYY(activeObj.recebimento)}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Descrição Cor:</span>
                            <span className={styles.fontBlue}>{activeObj.DescricaoCor}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Data Revisão:</span>
                            <span className={styles.fontBlue}>{activeObj.DataRevisao}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Marca:</span>
                            <span className={styles.fontBlue}>{activeObj.Marca}</span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Prioridade:</span>
                            <span className={styles.fontBlue}>{activeObj.Prioridade}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Responsável Revisão:</span>
                            <span className={styles.fontBlue}>{activeObj.ResponsavelRevisao}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Grupo:</span>
                            <span className={styles.fontBlue}>{activeObj.Grupo}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Tipo Compra:</span>
                            <span className={styles.fontBlue}>{activeObj.TipoCompra}</span>
                          </div>
                        </div>
                      </article>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </article>

              {/* Formulário de inserção */}
              <article>
                <Form className={styles.form}>
                  <FormGroup className={styles.formGroupModal} controlId={'ResponsavelAprovacao'}>
                    <Form.Label className={styles.formLabel}>Responsável Aprovação/Ponto Focal:</Form.Label>
                    <Select
                      placeholder={'Selecionar'}
                      options={responsavelAprovacaoLista}
                      value={formObj.ResponsavelAprovacao || null}
                      onFocus={() => clearErrs('ResponsavelAprovacao')}
                      onChange={e => setFormObj({...formObj, ResponsavelAprovacao: e})}
                      isClearable={true}
                      escapeClearsValue={true}
                      styles={{
                        control: (provided) => (
                          formErrs.ResponsavelAprovacao ? {...provided, borderColor: '#dc3545'} : {...provided, borderColor: 'hsl(0, 0%, 80%)'}
                        )
                      }}
                    />
                    <div className={formErrs.ResponsavelAprovacao ? styles.showFeedback : styles.hidden}>{formErrs.ResponsavelAprovacao}</div>
                  </FormGroup>
                  <FormGroup className={styles.formGroupModal} controlId={'MotivoEnvio'}>
                    <Form.Label className={styles.formLabel}>Motivo do Envio:</Form.Label>
                    <Form.Control
                      value={activeObj.MotivoEnvio || null}
                      onClick={() => clearErrs('LacreEnvio')}
                      onFocus={() => clearErrs('MotivoEnvio')}
                      onChange={e => setFormObj({...formObj, MotivoEnvio: e})}
                      disabled={true}
                      isClearable={true}
                      escapeClearsValue={true}
                      styles={{
                        control: (provided) => (
                          formErrs.MotivoEnvio ? {...provided, borderColor: '#dc3545'} : {...provided, borderColor: 'hsl(0, 0%, 80%)'}
                        )
                      }}
                    />
                    <div className={formErrs.MotivoEnvio ? styles.showFeedback : styles.hidden}>{formErrs.MotivoEnvio}</div>
                  </FormGroup>
                </Form>
                <Form className={styles.form}>
                  <FormGroup className={styles.formGroupModal} controlId={'DataEnvioAprovacao'}>
                    <Form.Label className={styles.formLabel}>Data Envio Aprovação:</Form.Label>
                    <Form.Control
                      type="date"
                      style={{"cursor": "text"}}
                      value={formObj.DataEnvioAprovacao || ''}
                      onClick={() => clearErrs('DataEnvioAprovacao')}
                      onChange={e => setFormObj({...formObj, DataEnvioAprovacao: e.target.value})}
                      min={today}
                      max={'3000-01-01'}
                      isInvalid={formErrs.DataEnvioAprovacao}
                    />
							      <Form.Control.Feedback type='invalid'>{formErrs.DataEnvioAprovacao}</Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className={styles.formGroupModal} controlId={'LacreEnvio'}>
                    <Form.Label className={styles.formLabel}>Número do Lacre:</Form.Label>
                    <Form.Control
                      value={formObj.LacreEnvio || ''}
                      onClick={() => clearErrs('LacreEnvio')}
                      onChange={e => handleValueMask('LacreEnvio', e.target.value)}
                      isInvalid={formErrs.LacreEnvio}
                    />
							    <Form.Control.Feedback type='invalid'>{formErrs.LacreEnvio}</Form.Control.Feedback>
                  </FormGroup>
                </Form>
              </article>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
              <Button 
                className={styles.iconCursor}
                variant="outline-danger"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button 
                className={styles.iconCursor}
                variant="primary"
                onClick={handleEnvioProcessing}
              >
                Enviar Amostra
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal Confirmar Amostr Enviada Aprovação Estilo */}
          <Modal
            show={showModalRecebimento} 
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName={styles.modalWidth}
          >
            <Modal.Header closeButton>
                <Modal.Title>Confirmar Recebimento / Retorno de Amostra Enviada para Aprovação Estilo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Informações sobre o material */}
              <article className="p-2 mt-2">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Dados do pedido</Accordion.Header>
                    <Accordion.Body>
                      <article className={styles.monitorInfo}>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Pedido:</span>
                            <span className={styles.fontBlue}>{activeObj.Pedido}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Material:</span>
                            <span className={styles.fontBlue}>{activeObj.Material}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Cód. Fornecedor:</span>
                            <span className={styles.fontBlue}>{activeObj.CodigoFornecedor}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Fornecedor:</span>
                            <span className={styles.fontBlue}>{activeObj.NomeCliFor}</span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Nota Fiscal:</span>
                            <span className={styles.fontBlue}>{activeObj.NFEntrada}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Descrição Material:</span>
                            <span className={styles.fontBlue}>{activeObj.DescricaoMaterial}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Tipo:</span>
                            <span className={styles.fontBlue}>{activeObj.Tipo}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Requerido por:</span>
                            <span className={styles.fontBlue}>{activeObj.RequeridoPor}</span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Série NF:</span>
                            <span className={styles.fontBlue}>{activeObj.SerieNF}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Cor Material:</span>
                            <span className={styles.fontBlue}>{activeObj.CorMaterial}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Qtde Recebida:</span>
                            <span className={styles.fontBlue}>{activeObj.QtdeEntrada}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Qtde Peças:</span>
                            <span className={styles.fontBlue}>{activeObj.QtdePecas || '-'}</span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Data Entrada NF:</span>
                            <span className={styles.fontBlue}>{toUTCDDMMYYYY(activeObj.recebimento)}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Descrição Cor:</span>
                            <span className={styles.fontBlue}>{activeObj.DescricaoCor}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Data Revisão:</span>
                            <span className={styles.fontBlue}>{activeObj.DataRevisao}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Marca:</span>
                            <span className={styles.fontBlue}>{activeObj.Marca}</span>
                          </div>
                        </div>
                        <div className={styles.infoBlock}>
                          <div className={styles.infoBlockColumm}>
                            <span>Prioridade:</span>
                            <span className={styles.fontBlue}>{activeObj.Prioridade}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Responsável Revisão:</span>
                            <span className={styles.fontBlue}>{activeObj.ResponsavelRevisao}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Grupo:</span>
                            <span className={styles.fontBlue}>{activeObj.Grupo}</span>
                          </div>
                          <div className={styles.infoBlockColumm}>
                            <span>Tipo Compra:</span>
                            <span className={styles.fontBlue}>{activeObj.TipoCompra}</span>
                          </div>
                        </div>
                      </article>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </article>

              {/* Formulário de inserção */}
              <article>
                <Form className={styles.form}>
                  <FormGroup className={styles.formGroupModal}>
                    <Form.Label className={styles.formLabel}>Responsável Aprovação/Ponto Focal:</Form.Label>
                    <span className={styles.fontBlueModal}>{activeObj.ResponsavelAprovacao}</span>
                  </FormGroup>
                  <FormGroup className={styles.formGroupModal}>
                    <Form.Label className={styles.formLabel}>Data Recebimento Amostra:</Form.Label>
                    <Form.Control
                      type="date"
                      style={{"cursor": "text"}}
                      value={formObj.DataRecebimentoAmostra || ''}
                      onClick={() => clearErrs('DataRecebimentoAmostra')}
                      onChange={e => setFormObj({...formObj, DataRecebimentoAmostra: e.target.value})}
                      min={today}
                      max={'3000-01-01'}
                      isInvalid={formErrs.DataRecebimentoAmostra}
                    />
							      <Form.Control.Feedback type='invalid'>{formErrs.DataRecebimentoAmostra}</Form.Control.Feedback>
                  </FormGroup>
                  {/* <FormGroup className={styles.formGroupModal}>
                    <Form.Label className={styles.formLabel}>Responsável Recebimento:</Form.Label>
                    <Select
                      placeholder={'Selecionar'}
                      options={optionsObj.ResponsavelRecebimentoLista}
                      value={formObj.ResponsavelRecebimento || null}
                      onFocus={() => clearErrs('ResponsavelRecebimento')}
                      onChange={e => setFormObj({...formObj, ResponsavelRecebimento: e})}
                      isClearable={true}
                      escapeClearsValue={true}
                      styles={{
                        control: (provided) => (
                          formErrs.ResponsavelRecebimento ? {...provided, borderColor: '#dc3545'} : {...provided, borderColor: 'hsl(0, 0%, 80%)'}
                        )
                      }}
                    />
                    <div className={formErrs.ResponsavelRecebimento ? styles.showFeedback : styles.hidden}>{formErrs.ResponsavelRecebimento}</div>
                  </FormGroup> */}
                </Form>
                <Form className={styles.form}>
                  <FormGroup className={styles.formGroupModal}>
                    <Form.Label className={styles.formLabel}>Confirmado Envio de Amostra?</Form.Label>
                    <span className={styles.fontBlueModal}>{activeObj.ConfirmaEnvioAmostra}</span>
                  </FormGroup>
                  <FormGroup className={styles.formGroupModal}>
                    <Form.Label className={styles.formLabel}>Confirmado Retorno de Amostra?</Form.Label>
                    <span className={styles.fontBlueModal}>{activeObj.ConfirmaRetornoAmostra}</span>
                  </FormGroup>
                  <FormGroup className={styles.formGroupModal}>
                    <Form.Label className={styles.formLabel}>Lacre Retorno de Amostra:</Form.Label>
                    <span className={styles.fontBlueModal}>{activeObj.LacreRetornoAmostra}</span>
                  </FormGroup>
                </Form>
              </article>
              <CustomPagination></CustomPagination>

            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
              <Button 
                className={styles.iconCursor}
                variant="outline-danger"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button 
                className={styles.iconCursor}
                variant="primary"
                onClick={handleRecebimentoProcessing}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
      />
    </div>
  );
};

export default Adm;
