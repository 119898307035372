import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../services/api';
import { useMisc } from './MiscContext';
import { toast } from 'react-toastify';
import CookiesService from '../services/cookies';
import { toDatetime, toUTCDDMMYYYY } from "../utils/dateUtils";

const MPCadastroAmostraContext = createContext();
export const useMPCadastroAmostra = () => useContext(MPCadastroAmostraContext);



export const MPCadastroAmostraProvider = ({ children }) => {
    const { setShowLoading } = useMisc();

    const [material, setMaterial] = useState('');
    const [cor, setCor] = useState("");
    const [itemFiltrado, setItemFiltrado] = useState([]);
    const [tipoMaterial, setTipoMaterial] = useState([]);    
    const [arara, setArara] = useState([]);
    const [andar, setAndar] = useState([]);
    const [lado, setLado] = useState([]);
    const [itensCadastrados, setItensCadastrados] = useState([]);
    const [itensCadastradosParaImprimir, setItensCadastradosParaImprimir] = useState([]);
    const [tipoMaterialSelecionado, setTipoMaterialSelecionado] = useState(null);    
    const [araraSelecionada, setAraraSelecionada] = useState(null);
    const [andarSelecionado, setAndarSelecionado] = useState(null);    
    const [itensACadastrar, setItensACadastrar] = useState([]);
    const [itensParaImprimir, setItensParaImprimir] = useState([]);
    const [processando, setProcessando] = useState(false);
    const userLogin = CookiesService.getUserLogin();

    const handleResetForm = () => {
        setAraraSelecionada(null);        
        setAndarSelecionado(null);        
        setTipoMaterialSelecionado(null);        
      }

    const handleSearch = async () => {
        const params = { material, cor }        
        setItemFiltrado([]);
        if (!material) return toast.warn('Você deve informar o Material!');
        if (!cor) return toast.warn('Você deve informar a Cor do Material!');
        setShowLoading(true)
        try {
          await api.get('Catalogo/CatalogoListarDisponiveis', { params })
            .then(res => {
              if (res.data.length === 0) return toast.warning('Material não disponível para cadastro.');          
              handleResetForm();          
              setItemFiltrado(res.data[0]);
            })
            .catch(e => {
              return console.error(`Erro na requisição: ${e}`);
            });
    
          handleListarCadastrados({ material, cor });
        } catch (e) {
          toast.error('Ocorreu um erro, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
          console.error(`Erro na requisição: ${e}`);
        }
        setShowLoading(false)
      }

      const handleListarCadastrados = async (params) => {
        await api.get('Catalogo/CatalogoListarCadastradosV2', { params })
          .then(res => {
            if (res.data.length > 0) {              
              setItensCadastrados(res.data);
              let newItensCadastradosParaImprimir = [];
              for (let i = 0; i < res.data.length; i++) {
                newItensCadastradosParaImprimir.push({ idCadastro: res.data[i].idCadastro });
              }
              setItensCadastradosParaImprimir([...newItensCadastradosParaImprimir]);
            } else {
              setItensCadastrados([]);
              setItensCadastradosParaImprimir([]);
            }
          })
          .catch(e => {
            return console.error(`Erro na requisição: ${e}`);
          });
      }

      const handleAraraClick = async (event) => {    
        if (!event) { return (setAndarSelecionado(null)) }
        const params = { idarara: event.value };    
        try {
          await api.get("Catalogo/CatalogoListarAndar", { params })
            .then(res => {
              const updatedData = res.data.map(item => ({
                value: item.andar,                
                label: 'ANDAR ' + item.andar + ' - Disponível ' + item.qtdeDisponivel
              }));          
              setAndar(updatedData);
            }).catch(e => console.error(`Erro na requisição: ${e}`));
    
        } catch (e) {
          toast.error('Ocorreu um erro, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
          console.error(`Erro na requisição: ${e}`);
        }    
        setAndarSelecionado(null);
      }
      function handleInserirItemCadastro() {
        setItensACadastrar([
          ...itensACadastrar,
          {
            idArara: araraSelecionada.value,
            idSalaAmostra: araraSelecionada.idSalaAmostra,
            material: itemFiltrado.MATERIAL,
            corMaterial: itemFiltrado.COR_MATERIAL,
            codClifor: itemFiltrado.COD_CLIFOR,
            idUsuario: userLogin,
            idTipoMaterial: tipoMaterialSelecionado.value,
            andar: andarSelecionado.value,        
            dataCadastro: toDatetime(new Date()),        
            indicaRetorno: 1,
          }
        ]);
        handleResetForm();
      }
    
      const handleProcessar = async () => {
        setShowLoading(true);
        await api.post(`Catalogo/CatalogoProcessarV2`, itensACadastrar)
          .then(res => {
            toast.success('Dados processados com sucesso!');
            setItensParaImprimir([...itensParaImprimir.concat(res.data)]);
            setItensACadastrar([]);
            handleListarCadastrados({ material: itemFiltrado.MATERIAL, cor: itemFiltrado.COR_MATERIAL });
          })
          .catch(e => {
            toast.error('Ocorreu um erro durante o processamento, por favor cheque sua conexão com a internet ou entre em contato com o suporte.');
            return console.error(e);
          })
          .finally(() => {
            setProcessando(false);
          });
          setShowLoading(false);
      }
    
      const handleCheckForTypes = (option) => {
        for (let i = 0; i < itensCadastrados.length; i++) {
          const item = itensCadastrados[i];
          if (item.tipoMaterial === option.value && item.Material === itemFiltrado.material) return true;
        }
        for (let i = 0; i < itensACadastrar.length; i++) {
          const item = itensACadastrar[i];
          if (item.idTipoMaterial === option.value && item.material === itemFiltrado.material) return true;
        }
        return false;
      }

      const handleDelete = (index) => {
        const updatedItems = itensACadastrar.filter((item, i) => i !== index);
        setItensACadastrar(updatedItems);
      };

    return (
        <MPCadastroAmostraContext.Provider
          value={{ setShowLoading, handleInserirItemCadastro, handleProcessar, handleAraraClick, handleCheckForTypes, handleListarCadastrados, handleSearch, handleResetForm, material, setMaterial,cor, setCor,itemFiltrado, setItemFiltrado,tipoMaterial, setTipoMaterial,arara, setArara,andar, setAndar,lado, setLado,itensCadastrados, setItensCadastrados,itensCadastradosParaImprimir, setItensCadastradosParaImprimir,tipoMaterialSelecionado, setTipoMaterialSelecionado,araraSelecionada, setAraraSelecionada,andarSelecionado, setAndarSelecionado,itensACadastrar, setItensACadastrar, itensParaImprimir, setItensParaImprimir,processando, setProcessando,userLogin, handleDelete}}
        >
          {children}
        </MPCadastroAmostraContext.Provider>
      )
}