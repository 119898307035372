import React from "react";
import { Button, Modal, Form, Table } from 'react-bootstrap';
import styles from '../../../styles/MP/RapportAnalisePlanner.module.scss';
import {useMPRapportAnalisePlanner} from "../../../contexts/MPRapportAnalisePlannerContext"

const ModalConfirmacaoGrade = () => {

    const { showConfirmacaoGrade, handleCloseConfirmacaoGrade, handleProcessConsumo } = useMPRapportAnalisePlanner();

    return (
        <Modal
            show={showConfirmacaoGrade}
            onHide={handleCloseConfirmacaoGrade}
            backdrop="static"
            keyboard={false}
            centered
        >
        <Modal.Header>
            <Modal.Title>Essas informações serão salvas:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h5>Deseja realmente salvar as informações de novo consumo?</h5>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
            <Button
                variant="success"
                className={styles.modalBtn}
                onClick={handleProcessConsumo}
            >
                Confirmar
            </Button>
            <Button
                variant="outline-danger"
                className={styles.modalBtn}
                onClick={handleCloseConfirmacaoGrade}
            >
                Voltar
            </Button>
        </Modal.Footer>
      </Modal>
    ) 
}

export default ModalConfirmacaoGrade;
