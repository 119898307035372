// export function toUTCYYYYMMDD (timestamp) {
//     if (!new Date(timestamp).getTime() > 0) return '-';
//     return `${timestamp.getUTCFullYear()}-${('0' + (timestamp.getUTCMonth() + 1)).slice(-2)}-${('0' + timestamp.getUTCDate()).slice(-2)}`;
// }

export function toUTCDDMMYYYY(timestamp) {
  if (!new Date(timestamp).getTime() > 0) return '-';
  const date = new Date(timestamp);
  return `${('0' + date.getUTCDate()).slice(-2)}/${('0' + (date.getUTCMonth() + 1)).slice(-2)}/${date.getUTCFullYear()}`;
}

export function ToBrDateWithTime(timestamp) {
  if (!new Date(timestamp).getTime() > 0) return '-'; const date = new Date(timestamp);
  const formattedDate = `${('0' + date.getUTCDate()).slice(-2)}/${('0' + (date.getUTCMonth() + 1)).slice(-2)}/${date.getUTCFullYear()}`;
  const formattedTime = `${('0' + date.getUTCHours()).slice(-2)}:${('0' + date.getUTCMinutes()).slice(-2)}:${('0' + date.getUTCSeconds()).slice(-2)}`;
  return `${formattedDate} ${formattedTime}`;
}

export function toUTCDatetime(timestamp) {
  if (!new Date(timestamp).getTime() > 0) return '-';
  const date = new Date(timestamp);
  return `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)} ${('0' + date.getUTCHours()).slice(-2)}:${('0' + date.getUTCMinutes()).slice(-2)}:${('0' + date.getUTCSeconds()).slice(-2)}.${('00' + date.getUTCMilliseconds()).slice(-3)}`;
}

export function toUTCDatetimeWithoutMs(timestamp) {
  if (!new Date(timestamp).getTime() > 0) return '-';
  const date = new Date(timestamp);
  return `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)} ${('0' + date.getUTCHours()).slice(-2)}:${('0' + date.getUTCMinutes()).slice(-2)}:${('0' + date.getUTCSeconds()).slice(-2)}`;
}

export function toYYYYMMDD(timestamp) {
  if (!new Date(timestamp).getTime() > 0) return '-';
  return `${timestamp.getFullYear()}-${('0' + (timestamp.getMonth() + 1)).slice(-2)}-${('0' + timestamp.getDate()).slice(-2)}`;
}

export function toDatetime(timestamp) {
  if (!new Date(timestamp).getTime() > 0) return '-';
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}.${('00' + date.getMilliseconds()).slice(-3)}`;
}

export function jSDateToSqlDatetime(timestamp) {
  if (!new Date(timestamp).getTime() > 0) return '-';
  const date = new Date(timestamp);
  return date.toLocaleString().replace("T", " ").replace("Z", "").slice(0, 19);
}

export function formatarDataBR(dateString) {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = date.getDate() + 1;
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month
    }/${year}`;
  return formattedDate;
}

export function obterDataAtual(diasAdicionais = 0) {
  const dataAtual = new Date();
  dataAtual.setDate(dataAtual.getDate() + diasAdicionais);

  const ano = dataAtual.getFullYear();
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
  const dia = String(dataAtual.getDate()).padStart(2, '0');

  const dataFormatada = `${ano}-${mes}-${dia}`;
  return dataFormatada;
}

export function fromDDMMYYYYToNewDate(timestamp) {
  var dateParts = timestamp.split("/");
  var date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  return date;
}

export function isValorNaN(valor) {
  return typeof valor === 'number' && isNaN(valor);
}

export function getDatetime() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function fifteenDaysAgoSimple() {
  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
  const formattedFifteenDaysAgo = fifteenDaysAgo.toISOString().split('T')[0];
  return formattedFifteenDaysAgo;
}

export function todaySimple() {
  const today = new Date().toISOString().split('T')[0];
  return today;
}

