import React, { useState } from "react";
import AccordionItems from "./AccordionItems"
import { Modal, Accordion, Button } from 'react-bootstrap';
import { api }  from "../../../services/api";
import { toast } from "react-toastify";
import styles from '../../../styles/MP/LaboratorioCemPorcentoRetorno.module.scss';
import AnaliseTestes from "./AnaliseTestes";
import PecasIncluidas from "./PecasIncluidas";
import { useMisc } from "../../../contexts/MiscContext";

const ResultadoTestes = ({ acaoSelecionada
                         , showResultadoTestes
                         , setShowResultadoTestes
                         , linhaSelecionada
                         , setLinhaSelecionada
                         , pecas
                         , setPecas
                         , setBusca
}) => {

    const acao = acaoSelecionada.value === 'cemPorcento' ? 0 : 1

    const [pecaSelecionada, setPecaSelecionada] = useState({});
    const [pecasIncluidas, setPecasIncluidas] = useState([]);
    const { setShowLoading, showLoading } = useMisc();
    
    const handleCloseResultadoTestes = () => {
        setShowResultadoTestes(false);
        setPecas([]);
        setLinhaSelecionada({});
        setPecaSelecionada({});
        setPecasIncluidas([]);
    }

    const handleProcessLab = async () => {
        if (pecasIncluidas.length === 0 || pecas.length > 0) {
          toast.warning('É necessário analisar todas as peças antes de continuar.');
          return;
        }
    
        setShowLoading(true); // Desativa o botão
    
        if (acaoSelecionada.value === 'cemPorcento') {
          const send = {
            acao: 0,
            cemPorcento: {
              nf_entrada: linhaSelecionada.nf_entrada,
              material: linhaSelecionada.material,
              cor_material: linhaSelecionada.cor_material,
              pedido: linhaSelecionada.pedido
            },
            cemPorcentoLab: pecasIncluidas
          };
    
          try {
            const res = await api.post('MP/LaboratorioCemPorcentoRetorno/process-labCemPorcentoRetorno', { send });
            if (res.status === 200) {
              handleCloseResultadoTestes();
              setBusca([]);
              toast.success('Dados inseridos com sucesso!');
            } else {
              toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
            }
          } catch (e) {
            toast.error(`Entre em contato com o suporte. ${e}`);
            console.error(e);
          } finally {
            setShowLoading(false); // Reativa o botão
          }
        }
      };
            
        //   } else if (acaoSelecionada.value === 'retorno') {
        //     const send = { acao: 1, 
        //                    retorno: { ordem_producao: linhaSelecionada.ordem_producao, ordem_servico: linhaSelecionada.ordem_servico}, 
        //                    retornoRevisao: pecasAnalisadas, defeitos: defeitosIncluidos }
        //     await api.post('MPRevisaoCemPorcentoRetorno/process-revisaoCemPorcentoRetorno', { send })
        //     .then(res => {
        //       if (res.status === 200) { 
        //         toast.success('Dados inseridos com sucesso!'); 
        //         resetAll();
        //       } else toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        //     }).catch(e => {
        //       toast.error(`Entre em contato com o suporte. ${e}`);
        //       console.error(e)
        //     });
        //   }
    

    return (
        <Modal 
            show={showResultadoTestes}
            onHide={handleCloseResultadoTestes}
            backdrop="static"
            keyboard={false}
            centered
            fullscreen={true}
        >
            <Modal.Header>
                <Modal.Title>Resultado Testes - {acao === 0 ? '100%' : 'Retorno'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.container}>
                    <article className={styles.articleSpacing}>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" disabled>
                            <Accordion.Header>Entrada Fiscal</Accordion.Header>
                            <Accordion.Body className={styles.infoBlockDisplay}>
                            <div className={styles.infoBlockInline}>
                                
                                <AccordionItems 
                                    linhaSelecionada={linhaSelecionada}
                                />

                            </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </article>
                    <article>

                        <AnaliseTestes 
                            pecas={pecas}
                            setPecas={setPecas}
                            pecaSelecionada={pecaSelecionada}
                            setPecaSelecionada={setPecaSelecionada}
                            pecasIncluidas={pecasIncluidas}
                            setPecasIncluidas={setPecasIncluidas}
                        />
                        
                    </article>
                    <article>

                        <PecasIncluidas 
                            pecas={pecas}
                            setPecas={setPecas}
                            pecasIncluidas={pecasIncluidas}
                            setPecasIncluidas={setPecasIncluidas}
                            linhaSelecionada={linhaSelecionada}
                        />

                    </article>
                    <div className={styles.divBtn}>
                        <Button
                            variant="outline-danger"
                            className={styles.buttonsFinalizar}
                            onClick={handleCloseResultadoTestes}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="primary"
                            className={styles.buttonsFinalizar}
                            onClick={() => handleProcessLab()}
                            disabled={showLoading}
                        >
                            Processar
                        </Button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default ResultadoTestes;
