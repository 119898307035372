import React, { useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import styles from '../../../styles/ADM/Desconto/EditarPendenciar.module.scss';
import { formatarDataBR } from '../../../utils/dateUtils'
import Carregando from '../../../components/Carregando';
import { toast, ToastContainer } from 'react-toastify';
import { api }  from "../../../services/api";
import ModalEditar from '../../../components/Adm/Desconto/EditarPendenciar/ModalEditar';
import ModalPendenciar from '../../../components/Adm/Desconto/EditarPendenciar/ModalPendenciar';


const EditarPendenciar = () => {

    const [mainData, setMainData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [defeitosData, setDefeitosData] = useState([]);
    const [motivoStatusData, setMotivoStatusData] = useState([]);

    const [filterObj, setFilterObj] = useState({
        nf_entrada: '',
        material: '',
        cor_material: ''
    });

    const [showEditar, setShowEditar] = useState(false);
    const [showPendenciar, setShowPendenciar] = useState(false);

    const [linhaSelecionada, setLinhaSelecionada] = useState({});

    const [loading, setLoading] = useState(false);

    const handleShowEditar = () => {
        setShowEditar(true)
    }

    const handleCloseEditar = () => {
        setLinhaSelecionada({});
        setShowEditar(false)
    }

    const handleShowPendenciar = (item) => {
        handleDefeitos()
        handleStatus()
        setLinhaSelecionada(item);
        setShowPendenciar(true)
    }

    const handleClosePendenciar = () => {
        setLinhaSelecionada({})
        setShowPendenciar(false)
        setStatusData([])
        setDefeitosData([])
    }

    const handleSearch = async () => {
        setLoading(true);
        const params = { ...filterObj }
        await api.get('ADM/Desconto/EditarPendenciar/get-rows', { params })
          .then(res => {
            setMainData(res.data);
            setLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    };

    const handleMotivoStatus = async (item) => {
        const params = { ...item }

        setLinhaSelecionada(item);
        handleShowEditar();

        await api.get('ADM/Desconto/EditarPendenciar/get-motivo-status', { params })
          .then(res => {
            setMotivoStatusData(res.data);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    };

    const handleStatus = async () => {
        await api.get('ADM/Desconto/EditarPendenciar/get-status', { })
          .then(res => {
            setStatusData(res.data);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    };

    const handleDefeitos = async () => {
        await api.get('ADM/Desconto/EditarPendenciar/get-defeitos', { })
          .then(res => {
            setDefeitosData(res.data);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
          });
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <article>
                <h3 className="p-3">Descontos - Editar/Pendenciar</h3>
                <Form className={styles.formWrapper}>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Nota Fiscal</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Nota Fiscal'}
                        className={styles.formControl}
                        value={filterObj.nf_entrada || ''}
                        onChange={(event) => setFilterObj({...filterObj, nf_entrada: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Material'}
                        className={styles.formControl}
                        value={filterObj.material || ''}
                        onChange={(event) => setFilterObj({...filterObj, material: event.target.value})}
                    />
                </Form.Group>
                <Form.Group className={styles.filterInput}>
                    <Form.Label className={styles.formLabel}>Cor Material</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Cor Material'}
                        className={styles.formControl}
                        value={filterObj.cor_material || ''}
                        onChange={(event) => setFilterObj({...filterObj, cor_material: event.target.value})}
                    />
                </Form.Group>
                <Button
                    type="button"
                    variant="primary"
                    className={styles.filterBtn}
                    onClick={handleSearch}
                >
                    Buscar
                </Button>
                </Form>
            </article>

            <article className={styles.articleGray}>
                { loading ?
                    <Carregando></Carregando>
                : 
                <Table responsive>
                    <thead>
                        <tr>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}></th>
                            <th className={styles.blockTitle}>DATA RECEBIMENTO</th>
                            <th className={styles.blockTitle}>NOTA FISCAL</th>
                            <th className={styles.blockTitle}>MATERIAL</th>
                            <th className={styles.blockTitle}>COR MATERIAL</th>
                            <th className={styles.blockTitle}>ITEM</th>
                            <th className={styles.blockTitle}>FORNECEDOR</th>
                            <th className={styles.blockTitle}>QUANTIDADE ENTRADA</th>
                        </tr>
                    </thead>
                    <tbody>
                        { mainData.map((item,index) => {
                        const statusValidacao = item.CountStatusValidacao > 0
                        const IdDefeito = item.IdDefeito !== null
                        return (
                        <tr key={index}>
                            <td className={styles.mainTdBtn}>
                                {!statusValidacao ?
                                    <Button
                                        variant='secondary'
                                        type="button"
                                        disabled
                                        className={styles.mainBtnEnv}
                                    >
                                        Editar Motivo Envio
                                    </Button>
                                    :
                                    <Button
                                        variant="outline-warning"
                                        type="button"
                                        onClick={() => handleMotivoStatus(item)}
                                        className={styles.mainBtnEnv}
                                    >
                                        Editar Motivo Envio
                                    </Button>                                
                                }
                            </td>
                            <td className={styles.mainTdBtn}>
                                {IdDefeito ?
                                        <Button
                                            variant="outline-primary"
                                            type="button"
                                            onClick={() => handleShowPendenciar(item)}
                                            className={styles.mainBtn}
                                        >
                                            Pendência
                                        </Button>
                                        :
                                        <Button
                                            variant="outline-danger"
                                            type="button"
                                            onClick={() => handleShowPendenciar(item)}
                                            className={styles.mainBtn}
                                        >
                                            Pendência
                                        </Button>
                                    }
                            </td> 
                            <td className={styles.tableLines}>{formatarDataBR(item.DataRecebimento)}</td>
                            <td className={styles.tableLines}>{item.NfEntrada}</td>
                            <td className={styles.tableLines}>{item.Material}</td>
                            <td className={styles.tableLines}>{item.CorMaterial}</td>
                            <td className={styles.tableLines}>{item.Item}</td>
                            <td className={styles.tableLines}>{item.Fornecedor}</td>
                            <td className={styles.tableLines}>{item.QtdeEntrada} m</td>
                        </tr>
                        )})}
                    </tbody>
                </Table>
                }
            </article>

            <ModalEditar 
                showEditar={showEditar}
                setShowEditar={setShowEditar}
                handleCloseEditar={handleCloseEditar}
                linhaSelecionada={linhaSelecionada}
                setLinhaSelecionada={setLinhaSelecionada}
                motivoStatusData={motivoStatusData}
                statusData={statusData}
                setStatusData={setStatusData}
                handleStatus={handleStatus}
            />

            <ModalPendenciar 
                showPendenciar={showPendenciar}
                setShowPendenciar={setShowPendenciar}
                handleClosePendenciar={handleClosePendenciar}
                setMainData={setMainData}
                linhaSelecionada={linhaSelecionada}
                statusData={statusData}
                setStatusData={setStatusData}
                defeitosData={defeitosData}
                setDefeitosData={setDefeitosData}
            />
        </div>
        
    );
};

export default EditarPendenciar;