const Main = () => {
  return (
    <div
      style={{
        margin: '40vh auto 0',
        textAlign: 'center'
      }}
    >
      <img
        className="img-responsive p-2 "
        src="img/soma_trans.png"
        alt="Grupo Soma"
        width="200"
      />
    </div>
  );
};

export default Main;
